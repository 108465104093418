import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { passwordValidate } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  designerId: any;
  modalOpen: boolean;
  promoteType: any;
  handleModalClose: () => void;
  currentImage: string;
  currentMediaType?: string;
  promoteCollectionId: number;
  designId: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  impressions: number;
  duration: number;
  step: number;
  totalAmount: number;
  promoteType: string;
  editable: boolean;
  currentImage: string;
  loading: boolean;
  cardNumber: string;
  expirationDate: string;
  cvv: string;
  validationStep3Errors: any;
  loadingApi: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignerHomePopUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  checkPromotedId: string;
  promoteDesignerId: string;
  validateCardApi: string;
  marks: any = [
    {
      value: 10000,
      label: "10,000",
    },
    {
      value: 100000,
      label: "1,00,000 impressions",
    },
  ];
  marksMonth: any = [
    {
      value: 1,
      label: "1 Month",
    },
    {
      value: 12,
      label: "12 Months",
    },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.backButton = this.backButton.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handlePromoteType = this.handlePromoteType.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.checkIfDesignerIsAlreadyPromoted = this.checkIfDesignerIsAlreadyPromoted.bind(
      this
    );

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      impressions: 10000,
      duration: 12,
      step: 1,
      totalAmount: 1000,
      promoteType: props.promoteType,
      editable: false,
      currentImage: "",
      loading: false,
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      validationStep3Errors: {},
      loadingApi: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    console.log(this.props.designerId);
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const DesignPublish = window.sessionStorage.getItem("DesignPublish");
    if (DesignPublish === "true") {
      toast.success("Design Published Successfull");
      window.sessionStorage.removeItem("DesignPublish");
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        //CHECK PROMOTE
        if (apiRequestCallId === this.checkPromotedId) {
          if (responseJson && !responseJson.errors) {
            this.setState({
              loading: false,
              step: 2,
            });
          } else {
            this.setState({
              loading: false,
              step: 1
            });
            this.props.handleModalClose();
            if (this.props.promoteType === 'Designer') {
              toast.error("Designer is already promoted");
            } else if (this.props.promoteType === 'Collection') {
              toast.error("Collection is already promoted");
            } else if (this.props.promoteType === 'Design') {
              toast.error("Design is already promoted");
            }
          }
        }

        //PROMOTE DESIGNER API RESPONSE
        if (apiRequestCallId === this.promoteDesignerId) {
          if (responseJson && !responseJson.errors) {
            toast.success("Promotion Successful");
            this.setState({ validationStep3Errors: {}, loadingApi: false });
            this.props.handleModalClose();
          } else {
            this.setState({ loadingApi: false });
            toast.error("Promotion Unsucessful");
          }
        }

        if (apiRequestCallId === this.validateCardApi) {
          if (responseJson && !responseJson.errors) {
            this.promoteDesigner();
          } else {
            let errors = {};
            if ('cvv' in responseJson.errors[0]) {
              errors = {
                ...errors,
                cvc: responseJson.errors[0].cvv
              }
            }
            if ('number' in responseJson.errors[0]) {
              errors = {
                ...errors,
                cardNumber: responseJson.errors[0].number
              }
            }
            if ('exp_month' in responseJson.errors[0]) {
              errors = {
                ...errors,
                expirationDate: responseJson.errors[0].exp_month
              }
            }
            if ('exp_year' in responseJson.errors[0]) {
              errors = {
                ...errors,
                expirationDate: responseJson.errors[0].exp_year
              }
            }
            this.setState({
              validationStep3Errors: errors,
              loadingApi: false
            });
          }
        }

      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleSliderChange(event: any, newValue: any) {
    let customAmount = (newValue / 10000) * 500;
    if (this.state.promoteType === "Designer") {
      customAmount = (newValue / 10000) * 1000;
    } else if (this.state.promoteType === "Collection") {
      customAmount = (newValue / 10000) * 500 + 250;
    } else if (this.state.promoteType === "Group/Club") {
      customAmount = (this.state.impressions / 10000) * 1500;
    }
    if (this.state.duration < 12) {
      const newCustomAmount = (12 - this.state.duration) * 5;
      customAmount += (customAmount * newCustomAmount) / 100;
    }
    this.setState({ impressions: newValue, totalAmount: customAmount });
  }

  handleMonthChange(event: any, newValue: any) {
    let customAmount = (this.state.impressions / 10000) * 500;
    if (this.state.promoteType === "Designer") {
      customAmount = (this.state.impressions / 10000) * 1000;
    } else if (this.state.promoteType === "Collection") {
      customAmount = (this.state.impressions / 10000) * 500 + 250;
    } else if (this.state.promoteType === "Group/Club") {
      customAmount = (this.state.impressions / 10000) * 1500;
    }
    if (newValue < 12) {
      const newCustomAmount = (12 - newValue) * 5;
      customAmount += (customAmount * newCustomAmount) / 100;
    }
    this.setState({ duration: newValue, totalAmount: customAmount });
  }

  handlePromoteType(event: any) {
    let customAmount = 0;
    if (event.target.value === "Designer") {
      customAmount = (this.state.impressions / 10000) * 1000;
    } else if (event.target.value === "Group/Club") {
      customAmount = (this.state.impressions / 10000) * 1500;
    } else if (event.target.value === "Collection") {
      customAmount = (this.state.impressions / 10000) * 500 + 250;
    } else {
      customAmount = (this.state.impressions / 10000) * 500;
    }
    if (this.state.duration < 12) {
      const newCustomAmount = (12 - this.state.duration) * 5;
      customAmount += (customAmount * newCustomAmount) / 100;
    }
    this.setState({
      totalAmount: customAmount,
      editable: false,
      promoteType: event.target.value,
    });
  }

  handleCardNumberInputChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      this.setState({ cardNumber: e.target.value });
    }
  };

  handleExpirationDateInputChange = (e: any) => {
    const regex = /^[0-9\//]+$/;
    let textTemp = e.target.value;
    const regexValid = /^[0-9]+$/;
    let valid = false;
    if (textTemp.length === 0) {
      valid = true
    } else if (textTemp.length === 1 && regexValid.test(textTemp)) {
      valid = true;
    } else if (textTemp.length === 2 && textTemp[1] !== '/' && regexValid.test(textTemp)) {
      valid = true;
    } else if (textTemp.length === 3 && textTemp[2] === '/') {
      valid = true;
    } else if (textTemp.length === 4 &&
      textTemp[2] === '/' &&
      textTemp[3] !== '/' &&
      regex.test(textTemp)) 
    {
      valid = true;
    } else if (
      textTemp.length === 5 &&
      textTemp[2] === '/' &&
      textTemp[3] !== '/' &&
      textTemp[4] !== '/' &&
      regex.test(textTemp))
    {
      valid = true;
    }
    if(valid) {
      if ((textTemp[0] === '1' || textTemp[0] === '0') && regex.test(textTemp) && textTemp.length <= 5) {
        if (textTemp.length < this.state.expirationDate.length) {
          this.setState({ expirationDate: textTemp });
        } else {
          if (textTemp.length === 2) {
            if (parseInt(textTemp.substring(0, 2)) > 12 || parseInt(textTemp.substring(0, 2)) == 0) {
              textTemp = textTemp[0];
            }
            textTemp += '/';
          }
          this.setState({ expirationDate: textTemp });
        }
      } else if (textTemp === '') {
        this.setState({ expirationDate: textTemp });
      }
    }
  };

  handleCVVInputChange = (e: any) => {
    this.setState({
      cvv: e.target.value,
    });
  };

  backButton() {
    if (this.state.step === 1) {
      this.props.handleModalClose();
    } else if (this.state.step === 3) {
      this.setState({ step: 2 });
    } else {
      this.setState({ step: 1 });
    }
  }

  getDate(): { month: number; year: number } {
    var date = new Date();
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  validateBankCard = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const { expirationDate } = this.state;
    const arrExpirationDate = expirationDate.split('/');
    const strMonth = arrExpirationDate[0];
    const month = parseInt(strMonth);
    const strYear = expirationDate.substring(3);
    const year = parseInt(strYear);
    const data = {
      card: {
        card_number: this.state.cardNumber,
        exp_month: month,
        exp_year: year,
        cvc: this.state.cvv
      }
    };
    const httpBody = data;
    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.validateCardDetails,
    });

    this.validateCardApi = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
    // const { cardNumber } = this.state;
    // const americalExpressRegex = /^(?:3[47][0-9]{13})$/;
    // const visaRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    // const mastercardRegex = /^(?:5[1-5][0-9]{14})$/;
    // const discoverCardRegex = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    // const dinersClubRegex = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/;
    // const jcbRegex = /^(?:(?:2131|1800|35\d{3})\d{11})$/;
    // if (americalExpressRegex.test(cardNumber) || visaRegex.test(cardNumber) || mastercardRegex.test(cardNumber) || discoverCardRegex.test(cardNumber) || dinersClubRegex.test(cardNumber) || jcbRegex.test(cardNumber)) {
    //   return true;
    // }
    // return false;
  }

  validationModalStep3 = () => {
    const { cardNumber, expirationDate, cvv } = this.state;
    const errors = {
      cardNumber: "",
      expirationDate: "",
      cvv: "",
    };
    const arrExpirationDate = expirationDate.split('/');
    const strMonth = arrExpirationDate[0];
    const month = parseInt(strMonth);
    const strYear = expirationDate.substring(3);
    const year = parseInt(strYear);
    const date = this.getDate();
    let flag = false;
    if (cardNumber === "") {
      errors.cardNumber = "Card Number is required";
      flag = true;
    }
    // } else if (!this.validateBankCard()) {
    //   errors.cardNumber = "Please enter a valid card number";
    //   flag = true;
    // }
    if (expirationDate === "") {
      errors.expirationDate = "Expiration Date is required";
      flag = true;
    } else if (
      expirationDate.trim().length !== 5 ||
      month < 0 || month > 12
    ) {
      errors.expirationDate = "Please enter a valid expiration date";
      flag = true;
    } else if (expirationDate.trim().length === 5) {
      if (year < parseInt(date.year.toString().substring(2,4)) || (year === parseInt(date.year.toString().substring(2,4)) && month < date.month)) {
        errors.expirationDate = "Your card has been expired";
        flag = true;
      }
    }
    if (cvv === "") {
      errors.cvv = "CVV is required";
      flag = true;
    } else if (cvv.trim().length > 4 || cvv.trim().length < 3) {
      errors.cvv = "Please enter a valid CVV";
      flag = true;
    }
    this.setState({
      validationStep3Errors: errors,
    });
    return flag;
  };

  valuetext(value: any) {
    return `${value}`;
  }
  valuetextMonth(value: any) {
    return `${value} Month`;
  }

  checkIfDesignerIsAlreadyPromoted = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    let data;
    if (this.props.promoteType === 'Collection') {
      data = {
        promote: {
          promotable_id: this.props.promoteCollectionId, // This is collection id
          promotable_type: "AccountBlock::Collection"
        }
      }
    } else if (this.props.promoteType === 'Designer') {
      data = {
        promote: {
          promotable_id: this.props.designerId, // This is account id only designer accepted.
          promotable_type: "AccountBlock::Account",
        },
      };
    } else if (this.props.promoteType === 'Design') {
      data = {
        promote: {
          promotable_id: this.props.designId, // This is account id only design accepted.
          promotable_type: "BxBlockAttachment::Design",
        },
      };
    }
    const httpBody = data;
    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.checkIfPromoted,
    });

    this.checkPromotedId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  };

  promoteDesigner = () => {
    this.setState({
      loading: true,
    });
    const { expirationDate } = this.state;
    // const month = parseInt(expirationDate.split("/")[0]);
    // const year = parseInt(expirationDate.split("/")[1]);
    const arrExpirationDate = expirationDate.split('/');
    const strMonth = arrExpirationDate[0];
    const month = parseInt(strMonth);
    const strYear = expirationDate.substring(3);
    const year = parseInt(strYear);
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    let data;
    if (this.props.promoteType === 'Designer') {
      data = {
        promote: {
          promotable_id: this.props.designerId, // This is account id only designer accepted.
          promotable_type: "AccountBlock::Account",
          interval_count: this.state.duration,
          interval: "month",
          impression: this.state.impressions,
          amount: this.state.totalAmount,
        },
        card: {
          card_number: this.state.cardNumber,
          exp_month: month,
          exp_year: year,
          cvc: this.state.cvv,
        },
      };
    } else if (this.props.promoteType === 'Collection') {
      data = {
        promote: {
          promotable_id: this.props.promoteCollectionId, // This is collection id
          promotable_type: "AccountBlock::Collection",
          interval_count: this.state.duration,
          interval: "month",
          impression: this.state.impressions,
          amount: this.state.totalAmount,
        },
        card: {
          card_number: this.state.cardNumber,
          exp_month: month,
          exp_year: year,
          cvc: this.state.cvv,
        }
      } 
    } else if (this.props.promoteType === 'Design') {
      data = {
        promote: {
          promotable_id: this.props.designId, // This is collection id
          promotable_type: "BxBlockAttachment::Design",
          interval_count: this.state.duration,
          interval: "month",
          impression: this.state.impressions,
          amount: this.state.totalAmount,
        },
        card: {
          card_number: this.state.cardNumber,
          exp_month: month,
          exp_year: year,
          cvc: this.state.cvv,
        }
      }
    }


    const httpBody = data;
    const promoteDesign = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.promoteApi,
    });
    this.promoteDesignerId = promoteDesign.messageId;
    runEngine.sendMessage(promoteDesign.id, promoteDesign);
    return true;
  };

  nextButton() {
    if (this.state.step === 1) {
      this.checkIfDesignerIsAlreadyPromoted();
    } else if (this.state.step === 2) {
      this.setState({ step: 3 });
    } else {
      this.setState({ validationStep3Errors: {}, loadingApi: true });
      if (this.validationModalStep3()) {
        this.setState({ loadingApi: false });
      } else {
        this.validateBankCard();
      }
    }
  }

  // Customizable Area End
}
