import React, { useState } from 'react';
import "../../catalogue/src/style.css";
import "./CommunityForum.web.css";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from '@material-ui/core/styles'
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import moment from 'moment';
import DezinerButton from '../../../components/src/Button.web';
import clsx from 'clsx';
import DezinerCard from '../../../components/src/Card.web';
import DezinerMedia from '../../../components/src/DezinerImage.web';
import { Forum } from '../../../components/src/api-types';
import { ProfileAvatarUI } from '../../../components/src/ProfileAvatar.web';
import { useNavigate } from '../../../components/src/hooks';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as R from 'ramda';


function calculateDuration(time: any) {

  if (!time) return '';

  var a = moment(); //now
  var b = moment(time);

  if (a.diff(b, "days") === 1) {
    return "Yesterday";
  } else if (a.diff(b, "days") > 1) {
    return moment(b).format("MMM DD, YYYY");
  } else if (a.diff(b, "hours") > 0) {
    return `${a.diff(b, "hours")} hours ago`;
  } else if (a.diff(b, "minutes") > 0) {
    return `${a.diff(b, "minutes")} min ago`;
  } else if (a.diff(b, "seconds") > 0) {
    return `${a.diff(b, "seconds")}s`;
  }

}




const useStyles = makeStyles({
  title: {
    cursor: "pointer",
    fontSize: '1.5rem',
    marginBottom: '6px',
    fontWeight: 'bold',
  },
  asked: {
    fontSize: '1rem',
    color: '#909193',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  postedAt: {
    fontSize: '1rem',
    color: '#909193',
    whiteSpace: 'nowrap',
  },
  description: {
    fontSize: '1rem',
    color: "#909193",
    marginTop: '5px',
  },
  mainImage: {
    cursor: "pointer",
    maxWidth: '100%',
    width: '100%',
    maxHeight: '300px',
    objectFit: 'cover',
    borderRadius: '10px',
    margin: '20px 0',
  },
  profileImage: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    marginRight: '8px'
  },
  postedBy: {
    color: '#757678',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  grayColor: {
    color: '#757678'
  },
  userName: {
    fontWeight: 'bold',
    padding: '0 5px',
    whiteSpace: 'nowrap',
  },
  cardActions: {
    justifyContent: 'flex-end',
    flexGrow: 1,
    maxWidth: '300px'
  },
  grayIcon: {
    color: "rgba(0,0,0,0.54)",
  },
  blackColor: {
    color: 'black'
  },
  cardActionsContainer: {
    ['@media screen and (max-width: 768px)']: {
      flexDirection: 'column',
      alignItems: 'stretch',
    }
  },
  postDateContainer: {
    display: 'flex', alignItems: 'center', flexWrap: 'nowrap'
  },

});


const THRESHOLD = 22;

function DeleteDialog({
  open,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
}) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Typography>{'Are you sure you want to delete this post?'}</Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Typography>
          {'Please make sure you want to delete this forum because once deleted you can\'t restore it.'}
        </Typography>
      </DialogContent>
      <DialogActions>

        <DezinerButton onClick={onClose}>
          {'Cancel'}
        </DezinerButton>

        <DezinerButton
          variant="contained"
          color="primary"
        >
          {'Delete'}
        </DezinerButton>

      </DialogActions>
    </Dialog>
  );
}

function processDescription({
  description, showFullDescription,
}: { description: string, showFullDescription: boolean }) {

  const isLongDescription = description.split(' ').length > THRESHOLD;

  const shortDescription = description.split(' ').slice(0, THRESHOLD).join(' ').concat(' ...');

  if (isLongDescription && !showFullDescription)
    return shortDescription;

  return description;
}

function ReadMoreButton({
  hide,
  onClick,
  showFullDescription,
}: {
  hide: boolean,
  onClick: () => void,
  showFullDescription: boolean,
}) {

  if (hide)
    return null;

  return (
    <DezinerButton
      variant='text'
      color="default"
      size="small"
      onClick={onClick}
    >
      {
        !showFullDescription ? 'Read more' : 'Read Less'
      }
    </DezinerButton>
  );
}

function ForumMedia({ forum }: { forum?: Forum }) {

  const classes = useStyles();

  const forumAttributes = forum?.attributes;

  if (!forumAttributes?.media)
    return null;

  return (
    <DezinerMedia
      src={forumAttributes?.media}
      mediaType={forumAttributes?.media_type?.includes('video') ? 'video' : 'image'}
      className={classes.mainImage}
      controls
    />
  );

}

const DEFAULT_ATTRIBUTE: Forum['attributes'] = {
  "id": 0,
  "title": '',
  "description": '',
  "media": '',
  "liked": false,
  "disliked": false,
  "like_count": 0,
  "dislike_count": 0,
  "view_count": 0,
  "comment_count": 0,
  "posted_at": '',
  "posted_by": {
    data: {
      id: '0',
      type: 'posted_by',
      attributes: {
        "id": 0,
        "first_name": '',
        "profile_url": '',
        "color": '',
        "followed": false,
        "subscribed": false,
      },
    }
  },
  media_type: '',
}


const withDefaultAttributes = R.mergeRight(DEFAULT_ATTRIBUTE);
const removeNilValues = R.filter(R.compose(R.not, R.isNil));
// @ts-ignore
const withDefaultForumAttributes = R.compose(withDefaultAttributes, removeNilValues);


export default function ForumCard({
  forum,
  onClick,
  likeHandler,
  dislikeHandler,
  singlePageMode,
}: {
  forum?: Forum,
  onClick: () => void,
  likeHandler: (id: number) => void,
  dislikeHandler: (id: number) => void,
  singlePageMode?: boolean,
}) {

  const classes = useStyles();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const forumAttributes = withDefaultForumAttributes(forum?.attributes ?? {});
  const postedBy = forumAttributes?.posted_by?.data;

  const description = forumAttributes?.description;
  const isLongDescription = description.split(' ').length > THRESHOLD;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const navigate = useNavigate();

  const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
  const openMoreMenu = Boolean(moreMenuAnchor);


  const deleteHandler = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setMoreMenuAnchor(null);
    setOpenDeleteDialog(true);
  };

  const editHandler = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setMoreMenuAnchor(null);
    navigate(`ForumEdit/${forum?.id}`);
  };


  return (
    <>

      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => { setOpenDeleteDialog(false); }}
      />

      <DezinerCard
        onClick={onClick}
        style={{
          borderWidth: singlePageMode ? '0px' : '1px'
        }}>
        <CardContent>

          <Grid container justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
            <Typography
              variant='h4'
              onClick={onClick}
              className={classes.title}
            >
              {forumAttributes?.title}
            </Typography>

            <div className={classes.postDateContainer}>

              {
                singlePageMode ?
                  <Typography variant='caption'
                    className={classes.asked}
                  >
                    {'Asked: '}
                  </Typography>
                  :
                  null
              }

              <Typography variant='caption'
                className={clsx(classes.postedAt, {
                  [classes.blackColor]: singlePageMode
                })}
              >
                {calculateDuration(forumAttributes?.posted_at)}
              </Typography>

              {/*
                isLoggedIn && isMyProfile ?
                  <IconButton
                    aria-label="more"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  :
                  null
              */}
              <Menu
                anchorEl={moreMenuAnchor}
                keepMounted
                open={openMoreMenu}
                onClose={editHandler}
                PaperProps={{
                  style: {
                    maxHeight: 200,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem selected={false} onClick={editHandler}>
                  {'Edit'}
                </MenuItem>

                <MenuItem selected={false} onClick={deleteHandler}>
                  {'Delete'}
                </MenuItem>
              </Menu>


            </div>
          </Grid>

          <Typography className={classes.description}>
            {processDescription({ description, showFullDescription })}
          </Typography>

          <ReadMoreButton
            hide={!isLongDescription}
            onClick={() => setShowFullDescription(x => !x)}
            showFullDescription={showFullDescription}
          />


          <ForumMedia forum={forum} />

          <Divider />
          <VerticalSpacer />

          <Grid
            className={classes.cardActionsContainer}
            container alignItems='center' justifyContent='space-between' wrap='wrap'>

            <Grid item container alignItems='center' wrap='nowrap' style={{ width: 'max-content' }}>

              <span style={{ marginRight: '8px' }}>
                <ProfileAvatarUI
                  profile={postedBy}
                  size="small"
                />
              </span>

              {
                !singlePageMode ?
                  <Typography
                    className={classes.postedBy}
                  >
                    {'Posted by '}
                  </Typography>
                  :
                  null
              }

              <Typography className={classes.userName}>
                {postedBy?.attributes?.first_name}
              </Typography>
            </Grid>


            <CardActions disableSpacing className={classes.cardActions}>

              <Grid container wrap="nowrap" alignItems='center'>
                <IconButton
                  aria-label="likes"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    likeHandler(forumAttributes?.id)
                  }}
                >
                  <ThumbUpAltIcon fontSize='small'
                    className={clsx({
                      [classes.grayIcon]: !forumAttributes?.liked,
                      [classes.blackColor]: forumAttributes?.liked,
                    })}
                  />
                </IconButton>
                <Typography className={classes.grayColor}>
                  {forumAttributes?.like_count}
                </Typography>
              </Grid>


              <Grid container wrap="nowrap" alignItems='center'>
                <IconButton
                  aria-label="dislikes"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    dislikeHandler(forumAttributes?.id)
                  }}
                >
                  <ThumbDownAltIcon fontSize='small'
                    className={clsx({
                      [classes.grayIcon]: !forumAttributes?.disliked,
                      [classes.blackColor]: forumAttributes?.disliked,
                    })}
                  />
                </IconButton>
                <Typography className={classes.grayColor}>
                  {forumAttributes?.dislike_count}
                </Typography>
              </Grid>


              <Grid container wrap="nowrap" alignItems='center'>
                <IconButton aria-label="views" onClick={onClick}>
                  <VisibilityIcon fontSize='small' />
                </IconButton>
                <Typography className={classes.grayColor}>
                  {forumAttributes?.view_count}
                </Typography>
              </Grid>


              <Grid container wrap="nowrap" alignItems='center'>
                <IconButton aria-label="comments" onClick={onClick}>
                  <ChatBubbleIcon fontSize='small' />
                </IconButton>
                <Typography className={classes.grayColor}>
                  {forumAttributes?.comment_count}
                </Typography>
              </Grid>


            </CardActions>

          </Grid>


        </CardContent>

      </DezinerCard>
    </>
  );
}
