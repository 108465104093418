import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  currentClub: any;
  anchorEl: any;
  loading: boolean;
  currentTab: number;
  deletePopup: boolean;
  deleteMember: string;
  deleteType: string;
  deleteId: number;
  clubMembers: any;
  memberId: string;
  role: string;
  contribution: number;
  memberList: any;
  userRoles: any;
  adminCounter: number;
  ownerCounter: number;
  contributionError: boolean;
  contributionHelperText: string;
  memberError: boolean;
  memberHelperText: string;
  roleError: boolean;
  roleHelperText: string;
  editableRevenue: boolean;
  equalContri: boolean;
  revenues: any;
  errRevenue: boolean;
  currentUserRoleClub: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddClubMemberController extends BlockComponent<
  Props,
  S,
  SS
> {
  clubApiMessageId: string;
  removeMessageId: string;
  clubMemberApi: string;
  memberListApi: string;
  addMemberApi: string;
  userRolesApi: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickDeleteOpen = this.handleClickDeleteOpen.bind(this);
    this.handleClickDeleteClose = this.handleClickDeleteClose.bind(this);
    this.handleClickDeleteMember = this.handleClickDeleteMember.bind(this);
    this.addMember = this.addMember.bind(this);
    this.updateRevenue = this.updateRevenue.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      currentClub: {},
      anchorEl: null,
      loading: true,
      currentTab: 0,
      deletePopup: false,
      deleteMember: "",
      deleteType: "",
      deleteId: 0,
      clubMembers: {},
      memberId: "",
      role: "",
      contribution: 0,
      memberList: {},
      userRoles: {},
      adminCounter: 0,
      ownerCounter: 0,
      contributionError: false,
      contributionHelperText: '',
      memberError: false,
      memberHelperText: '',
      roleError: false,
      roleHelperText: '',
      editableRevenue: false,
      equalContri: false,
      revenues: {},
      errRevenue: false,
      currentUserRoleClub: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getClubs();
    // this.getMembers();
    // this.getAllMembers();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.clubApiMessageId != null &&
      this.clubApiMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ currentClub: responseJson.data }, () => {
          this.getMembers();
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.clubMemberApi != null &&
      this.clubMemberApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ clubMembers: responseJson.data });
        responseJson.data.map((member: any) => {
          if (member.attributes.user_type === 'Owner') {
            this.setState({ ownerCounter: this.state.ownerCounter + 1 });
          } else if (member.attributes.user_type === 'Admin') {
            this.setState({ adminCounter: this.state.adminCounter + 1 });
          } else {}
          if (member.attributes.account_id.toString() === localStorage.getItem('cuid')) {
            this.setState({ currentUserRoleClub: member.attributes.user_type });
          }
          if (Object.keys(responseJson.data).length > 0) {
            Object.keys(responseJson.data).map((key, index) => {
              this.setState({
                revenues: {
                  ...this.state.revenues,
                  [responseJson.data[key].id]: responseJson.data[key].attributes.contribution !== '' ?
                    responseJson.data[key].attributes.contribution.replace('%', '') :
                    0
                }
              })
            });
            this.setState({ loading: false });
          }
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.getAllRoles();
      this.getAllMembers();
    }


    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeMessageId != null &&
      this.removeMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success('Removed Successfully');
        // this.setState({ loading: true });
        this.getMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.memberListApi != null &&
      this.memberListApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ memberList: responseJson.data });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userRolesApi != null &&
      this.userRolesApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        let roles: Array<string> = [];
        responseJson.user_types.map((role: string) => {
          if (role === 'Owner') {
            if (this.state.ownerCounter === 0) {
              roles.push(role);
            }
          } else {
            roles.push(role);
          }
        });
        this.setState({ userRoles: roles });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addMemberApi != null &&
      this.addMemberApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success("Member Added Successfully");
        this.setState({ memberId: "", role: "", contribution: 0 });
        this.getMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        Object.keys(responseJson.errors[0]).map((msg: any) => {
          toast.error(responseJson.errors[0][msg]);
        });
      }
    }

    // Customizable Area End
  }

  getClubs(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.getClubDetails}/${this.props.navigation.getParam("id", 0)}`,
    });

    this.clubApiMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  getMembers(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.getClubDetails}/${this.props.navigation.getParam("id", 0)}/club_members`,
    });

    this.clubMemberApi = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  handleClick(event: any): void {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose(): void{
    this.setState({ anchorEl: null });
  };

  handleChange(event:any, newValue: number) {
    this.setState({ currentTab: newValue });
  };

  handleClickDeleteOpen(): void {
    this.setState({ deletePopup: true });
  };

  handleClickDeleteClose(): void {
    this.setState({ deletePopup: false });
  };

  handleClickDeleteMember(): void {
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: localStorage.getItem("token"),
    };

    const httpBody = {
        data: {
            attributes: {
                account_id: this.state.deleteId
            }
        }
    };

    const getClubData = apiCall({
        header: header,
        httpBody: httpBody,
        httpMethod: configJSON.deleteMemberRequestType,
        url: `${configJSON.deleteMember}/${this.props.navigation.getParam("id", 0)}/remove_member`,
    });

    this.removeMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    this.setState({ deletePopup: false });
  }

  addMember(): void {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
  };

  let error = false;

  if (this.state.memberId === '') {
    this.setState({ memberError: true, memberHelperText: 'Select Member' });
    error = true;
  } else {
    this.setState({ memberError: false, memberHelperText: '' });
  }

  if (this.state.role === '') {
    this.setState({ roleError: true, roleHelperText: 'Select Role' });
    error = true;
  } else {
    this.setState({ roleError: false, roleHelperText: '' });
  }

  if (this.state.contribution < 0) {
    this.setState({ contributionError: true, contributionHelperText: 'Add contribution' });
    error = true;
  } else {
    this.setState({ contributionError: false, contributionHelperText: '' });
  }

  if (error) {
    return;
  }

  const httpBody = {
      data: {
          attributes: {
            account_id: this.state.memberId,
            user_type: this.state.role,
            contribution: this.state.contribution
          }
      }
  };

  const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.addMember}/${this.props.navigation.getParam("id", 0)}/add_member`,
  });

  this.addMemberApi = getClubData.messageId;
  runEngine.sendMessage(getClubData.id, getClubData);
  }

  getAllMembers() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.designerList,
    });

    this.memberListApi = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  getAllRoles() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.clubRoles,
    });

    this.userRolesApi = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  updateRevenue(): void {
    let totalRevenue = 0;
    Object.keys(this.state.revenues).map((key) => {
      totalRevenue += parseFloat(this.state.revenues[key]);
    });
    totalRevenue = parseFloat(totalRevenue.toFixed(2));
    if (totalRevenue != 100) {
      this.setState({ errRevenue: true });
    } else {
      this.setState({ editableRevenue: false, errRevenue: false });
    }
  }

  // Customizable Area End
}
