import React from "react";
// Customizable Area Start
import CreateClubForm from "./CreateClubForm.web";
import LogoTitleHeader from "./LogoTitleHeader.web";
// Customizable Area End
import CreateClubController, { Props } from "./CreateClubController.web";



class CreateClub extends CreateClubController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const listOfCategories = this.state.categories
      ?.map((item: any) => (
        item.options.map((option: any) => ({ ...option, parentLabel: item.label }))
      ))
      ?.flat();


    return (
      <>

        <LogoTitleHeader
          title={"Create your club"}
          onLogoClick={() => {
            this.props.navigation.navigate("DesignClubPageView");
          }}
        />

        <CreateClubForm
          onSubmit={(values) => {
            this.createClub({
              name: values.clubName,
              description: values.clubDescription,
              email: values.email,
              organization_name: values.orgName,
              organization_pan_id: values.orgPanId,
              bank_account_name: values.bankAccountName,
              bank_account_number: values.bankAccountNumber,
              ifsc_code: values.bankIFSC,
              pan_photo: values.panPhoto,
              cheque: values.cancelledCheque,
              image: values.profileImage,
              cover: values.coverImage,
              user_type: values.typeofUser == 'Admin' ? 'Admin' : 'Owner',
              account_ids: values.clubMembers.map((x: any) => String(x.value)),
              clubs_sub_categories_attributes: values.categories.map((x: any) => ({ sub_category_id: x.value })),
            })
          }}
          members={this.state.members}
          categories={listOfCategories}
          loading={this.state.submitLoading}
        />

      </>
    );
  }
}

// Customizable Area Start
// Customizable Area End
export default CreateClub;
// Customizable Area End
