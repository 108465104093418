import { compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from './effects/root-saga';
import { rootReducer } from './reducers/root-reducer';
import { routerMiddleware } from 'connected-react-router'
// @ts-ignore
import { createBrowserHistory } from 'history'

declare var window: any;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware()

export const dezinerHistory = createBrowserHistory();

const _rootReducer = rootReducer(dezinerHistory);

export const dezinerReduxStore = createStore(
  _rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware,routerMiddleware(dezinerHistory))),
)


export interface DezinerActionCreator<Action> {
  (params: Omit<Action, "type">): Action
}

export type RootState = ReturnType<typeof _rootReducer>;

sagaMiddleware.run(rootSaga)
