import React, { useRef, useState } from "react";
import { cameraPlusIcon } from "./assets";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import DezinerButton from "../../../components/src/Button.web";
import FormikTextInput from "../../../components/src/FormikTextField.web";
import TextInput from "../../../components/src/TextField.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import { DezinerChipVariant } from "../../../components/src/Chip.web";
import AutocompleteSelectorInput from "../../../components/src/AutocompleteSelector.web";
import DezinerAlert from "../../../components/src/Alert.web";
import { DezinerLabel } from "../../../components/src/TextField.web";
import { close } from './assets';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useClubsAndGroupCreateFromStyles } from './CreateFormStyles.web';


async function encodeImageFileAsURL(file: any) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onloadend = function() {
      let imagebase64 = reader.result;
      resolve(imagebase64);
    };
    reader.readAsDataURL(file);
  });
}


const initValues = {
  clubName: '',
  clubDescription: '',
  categories: [],
  clubMembers: [],
  profileImage: '',
  email: '',
  typeofUser: '',

  coverImage: '',
  orgName: '',
  orgPanId: '',
  panPhoto: '',
  bankAccountName: '',
  bankAccountNumber: '',
  bankIFSC: '',
  cancelledCheque: '',
}

const validationSchema1 = yup.object().shape({
  clubName: yup.string().required('Please enter Club Name')
    .test('special-chars-only', 'Please Don\'t write sepcial chars only', isSpecialCharsOnly),
  clubDescription: yup.string().required('Please enter club description')
    .test('special-chars-only', 'Please Don\'t write sepcial chars only', isSpecialCharsOnly),
  categories: yup.array().of(yup.mixed()).min(1, 'Please chooes at lease one category'),
  clubMembers: yup.array().of(yup.mixed()).min(1, 'Please Choose at least one club member'),
  profileImage: yup.string().required('Please upload profile image'),
  coverImage: yup.string().required('Please upload a cover photo'),
  email: yup.string().email().required(),
  typeofUser: yup.string().required('Please choose type of user'),
});

function isSpecialCharsOnly(value: any) {
  if (!value) return true;
  return !/^[^a-zA-Z0-9]+$/g.test(value);
}
function isValidPanId(value: any) {
  if (!value) return true;
  return /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value);
}
function isValidBankNumber(value: any) {
  if (!value) return true;
  return /^[0-9]{9,18}$/.test(value);
}
function isValidIFSC(value: any) {
  if (!value) return true;
  return /^[A-Z]{4}0[A-Z0-9]{6}$/.test(value);
}


const validationSchema2 = yup.object().shape({
  orgName: yup.string().required('Please enter organization name')
    .test('special-chars-only', 'Please Don\'t write sepcial chars only', isSpecialCharsOnly),
  orgPanId: yup.string().required('Please enter organization Pan ID')
    .test('valid-pan-id', 'Please enter a valid PAN Id', isValidPanId),
  panPhoto: yup.string().required('Please Upload PAN Image'),
  bankAccountName: yup.string().required('Please enter the name of bank account'),
  bankAccountNumber: yup.string().required('Please enter Bank account number')
    .test('valid-bank-number', 'Please enter a valid bank number', isValidBankNumber),
  bankIFSC: yup.string().required('Please enter IFSC')
    .length(11, 'Please enter a valid IFSC')
    .test('valid-ifsc', 'Please enter a valid IFSC', isValidIFSC),
  cancelledCheque: yup.string().required('Cancelled Cheque Image is required'),
});





export default function CreateClubForm({
  categories,
  members,
  onSubmit,
  loading,
}: {
  categories: any[],
  members: any[],
  onSubmit: (values: typeof initValues) => void,
  loading: boolean,
}) {

  const [step, setStep] = useState(1);

  const classes = useClubsAndGroupCreateFromStyles();

  const coverImageRef = useRef<any>();
  const profileImageRef = useRef<any>();
  const [isValidProfileImage, setIsValidProfileImage] = useState(true);
  const [isValidCoverImage, setIsValidCoverImage] = useState(true);

  const panPhotoRef = useRef<any>();
  const cancelledChequeRef = useRef<any>();
  const [panPhotoName, setPanPhotoName] = useState('');
  const [cancelledCheckqueName, setCancelledCheckqueName] = useState('');

  return (
    <div>
      <Formik
        initialValues={initValues}
        validationSchema={step == 1 ? validationSchema1 : validationSchema2}
        onSubmit={(values, formik) => {
          if (!isValidProfileImage || !isValidCoverImage)
            return;

          if (step == 1) {
            setStep(2);
          } else {
            onSubmit(values);
          }
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {
          formik => <Form translate={""}>


            <div className={clsx(classes.coverImageContainer, {
              [classes.trasparentBackground]: formik.values.coverImage
            })}>
              <DezinerButton
                onClick={() => {
                  if (coverImageRef && coverImageRef.current)
                    coverImageRef.current.click();
                }}
                className={classes.uploadBtn}
                color={formik.values.coverImage ? "primary" : "default"}
                variant={formik.values.coverImage ? "contained" : "text"}
                endIcon={
                  <img src={cameraPlusIcon}
                    alt="Upload cover image"
                    className={clsx('gray-svg', classes.camerIcon)}
                  />
                }
              >
                {'Upload Cover Image'}
              </DezinerButton>
              {
                formik.values.coverImage ?
                  <img
                    src={formik.values.coverImage}
                    alt="cover image"
                  />
                  :
                  null
              }
            </div>

            <div className={classes.profileImageContainer}>
              {
                formik.values.profileImage ?
                  <img
                    onClick={() => {
                      if (profileImageRef && profileImageRef.current)
                        profileImageRef.current.click();
                    }}
                    src={formik.values.profileImage}
                    alt="profile"
                  />
                  :
                  <div
                    onClick={() => {
                      if (profileImageRef && profileImageRef.current)
                        profileImageRef.current.click();
                    }}>
                    <img
                      src={cameraPlusIcon}
                      alt="Upload cover image"
                      className={clsx('gray-svg', classes.camerIcon)}
                    />
                    <Typography className={classes.uploadBtn}>
                      {'Upload Porfile'}
                    </Typography>
                  </div>
              }
            </div>


            <input
              type="file"
              ref={profileImageRef}
              onChange={async (event) => {
                let file = event.target.files?.[0];
                if (!file) return;

                let size = file?.size;
                const MAX_FILE_SIZE_IN_MB = 2;

                if (size && (size / (1024 ** 2) > MAX_FILE_SIZE_IN_MB)) {
                  // setIsValidImage(false);
                  formik.setStatus({ ...formik.status, profileImage: `Max allowed size is ${MAX_FILE_SIZE_IN_MB}MB for profile image` });
                  setIsValidProfileImage(false);
                } else {
                  formik.setStatus({ ...formik.status, profileImage: '' })
                  setIsValidProfileImage(true);
                }
                let base64 = await encodeImageFileAsURL(file);
                formik.setFieldValue('profileImage', base64);
              }}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              style={{ display: 'none' }}
            />

            <input
              type="file"
              ref={coverImageRef}
              onChange={async (event) => {
                let file = event.target.files?.[0];
                if (!file) return;

                let size = file?.size;
                const MAX_FILE_SIZE_IN_MB = 3;

                if (size && (size / (1024 ** 2) > MAX_FILE_SIZE_IN_MB)) {
                  formik.setStatus({ ...formik.status, coverImage: `Max allowed size is ${MAX_FILE_SIZE_IN_MB}MB for cover image` });
                  setIsValidCoverImage(false);
                } else {
                  formik.setStatus({ ...formik.status, coverImage: '' })
                  setIsValidCoverImage(true);
                }
                let base64 = await encodeImageFileAsURL(file);
                formik.setFieldValue('coverImage', base64);
              }}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              style={{ display: 'none' }}
            />

            <input
              type="file"
              ref={panPhotoRef}
              onChange={async (event) => {
                let file = event.target.files?.[0];
                if (!file) return;
                setPanPhotoName(file.name);
                let base64 = await encodeImageFileAsURL(file);
                formik.setFieldValue('panPhoto', base64);
              }}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              style={{ display: 'none' }}
            />

            <input
              type="file"
              ref={cancelledChequeRef}
              onChange={async (event) => {
                let file = event.target.files?.[0];
                if (!file) return;
                setCancelledCheckqueName(file.name);
                let base64 = await encodeImageFileAsURL(file);
                formik.setFieldValue('cancelledCheque', base64);
              }}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              style={{ display: 'none' }}
            />

            {
              step == 1 ?
                <>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    className={classes.formItemsContainer}
                  >
                    {
                      formik?.errors?.profileImage ?
                        <DezinerAlert>
                          {formik?.errors?.profileImage}
                        </DezinerAlert>
                        :
                        null
                    }
                    {
                      formik?.status?.profileImage ?
                        <DezinerAlert>
                          {formik?.status?.profileImage}
                        </DezinerAlert>
                        :
                        null
                    }
                    {
                      formik?.errors?.coverImage ?
                        <DezinerAlert>
                          {formik?.errors?.coverImage}
                        </DezinerAlert>
                        :
                        null
                    }
                    {
                      formik?.status?.coverImage ?
                        <DezinerAlert>
                          {formik?.status?.coverImage}
                        </DezinerAlert>
                        :
                        null
                    }
                    <VerticalSpacer />


                    <FormikTextInput
                      fullWidth
                      name="clubName"
                      label="Club Name"
                      variant="outlined"
                      placeholder="Enter your club name"
                    />

                    <VerticalSpacer />

                    <FormikTextInput
                      fullWidth
                      multiline
                      minRows={4}
                      autoHeight
                      name="clubDescription"
                      label="Club Description"
                      variant="outlined"
                    />

                    <VerticalSpacer />

                    <AutocompleteSelectorInput
                      placeholder="Add Categries"
                      autocompleteProps={{
                        groupBy: (option: any) => option?.parentLabel,
                      }}
                      inputValue={formik.values.categories}
                      onChange={(value) => formik.setFieldValue('categories', value)}
                      options={categories}
                    />
                    {
                      formik?.errors?.categories ?
                        <DezinerAlert>
                          {formik?.errors?.categories}
                        </DezinerAlert>
                        :
                        null
                    }
                    <div>
                      {
                        formik.values.categories?.map((cat: any) => (
                          <DezinerChipVariant
                            onDelete={() => {
                              formik.setFieldValue('categories', formik.values.categories.slice().filter((_cat: any) => _cat.value != cat.value))
                            }}
                            label={cat.label}
                            key={cat.value}
                          />
                        ))
                      }
                    </div>


                    <VerticalSpacer />

                    <FormControl variant="outlined" fullWidth>
                      <DezinerLabel>
                        {'Type of User'}
                      </DezinerLabel>
                      <Select
                        style={{ maxHeight: '40px' }}
                        fullWidth
                        labelId="type of user"
                        value={formik.values.typeofUser}
                        onChange={(event) => formik.setFieldValue('typeofUser', event.target.value)}
                      >
                        <MenuItem value={'admin'}>{'Club Admin'}</MenuItem>
                        <MenuItem value={'owner'}>{'Club Owner'}</MenuItem>
                      </Select>
                    </FormControl>

                    {
                      formik?.errors?.typeofUser ?
                        <DezinerAlert>
                          {formik?.errors?.typeofUser}
                        </DezinerAlert>
                        :
                        null
                    }

                    <VerticalSpacer />

                    <FormikTextInput
                      fullWidth
                      name="email"
                      label="Email ID"
                      variant="outlined"
                    />

                    <VerticalSpacer />

                    <AutocompleteSelectorInput
                      placeholder="Add members"
                      inputValue={formik.values.clubMembers}
                      onChange={(value) => formik.setFieldValue('clubMembers', value)}
                      options={members}
                    />

                    {
                      formik?.errors?.clubMembers ?
                        <DezinerAlert>
                          {formik?.errors?.clubMembers}
                        </DezinerAlert>
                        :
                        null
                    }

                    <div>
                      {
                        formik.values.clubMembers?.map((member: any) => (
                          <DezinerChipVariant
                            onDelete={() => {
                              formik.setFieldValue('clubMembers', formik.values.clubMembers.slice().filter((_member: any) => _member.value != member.value))
                            }}
                            label={member.label}
                            key={member.value}
                          />
                        ))
                      }
                    </div>

                  </Grid>

                </>
                :
                <>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    className={classes.formItemsContainer}
                  >

                    <FormikTextInput
                      fullWidth
                      name="orgName"
                      label="Organization Name"
                      variant="outlined"
                    />

                    <VerticalSpacer />

                    <TextInput
                      fullWidth
                      name="orgPanId"
                      label="Organization PAN ID"
                      variant="outlined"
                      value={formik.values.orgPanId}
                      onChange={(event) => formik.setFieldValue('orgPanId', event.target.value.toUpperCase())}
                    />
                    {
                      formik.errors?.orgPanId ?
                        <DezinerAlert>
                          {formik.errors?.orgPanId}
                        </DezinerAlert>
                        :
                        null
                    }

                    <VerticalSpacer />

                    <>
                      <DezinerLabel>
                        {'Upload PAN Photo'}
                      </DezinerLabel>
                      <Grid container alignItems="center">
                        <DezinerButton
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            if (panPhotoRef && panPhotoRef.current)
                              panPhotoRef.current.click();
                          }}>
                          {'Choose'}
                        </DezinerButton>
                        {
                          panPhotoName ?
                            <Grid container alignItems="center">
                              <Typography>
                                {panPhotoName}
                              </Typography>
                              <IconButton onClick={() => {
                                formik.setFieldValue('panPhoto', '');
                                setPanPhotoName('');
                              }}>
                                <img src={close} alt="delete" />
                              </IconButton>
                            </Grid>
                            :
                            null
                        }
                      </Grid>
                      {
                        formik.errors.panPhoto ?
                          <DezinerAlert>
                            {formik.errors.panPhoto}
                          </DezinerAlert>
                          :
                          null
                      }
                    </>

                    <VerticalSpacer />

                    <FormikTextInput
                      fullWidth
                      name="bankAccountName"
                      label="Name in Bank Account"
                      variant="outlined"
                    />

                    <VerticalSpacer />

                    <FormikTextInput
                      fullWidth
                      type="number"
                      name="bankAccountNumber"
                      label="Bank Account Number"
                      variant="outlined"
                    />

                    <VerticalSpacer />

                    <TextInput
                      fullWidth
                      name="bankIFSC"
                      label="Bank IFSC Code"
                      variant="outlined"
                      value={formik.values.bankIFSC}
                      onChange={(event: any) => {
                        var regexp = /^\S*$/;
                        if (regexp.test(event.target.value)) {
                          formik.setFieldValue('bankIFSC', event.target.value.toUpperCase())
                        }
                      }}
                    />

                    {
                      formik.errors?.bankIFSC ?
                        <DezinerAlert>
                          {formik.errors?.bankIFSC}
                        </DezinerAlert>
                        :
                        null
                    }

                    <VerticalSpacer />

                    <>
                      <DezinerLabel>
                        {'Upload Cancelled Cheque'}
                      </DezinerLabel>
                      <Grid container alignItems="center">
                        <DezinerButton
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            if (cancelledChequeRef && cancelledChequeRef.current)
                              cancelledChequeRef.current.click();
                          }}>
                          {'Choose'}
                        </DezinerButton>
                        {
                          cancelledCheckqueName ?
                            <Grid container alignItems="center">
                              <Typography>
                                {cancelledCheckqueName}
                              </Typography>
                              <IconButton onClick={() => {
                                formik.setFieldValue('cancelledCheque', '');
                                setCancelledCheckqueName('');
                              }}>
                                <img src={close} alt="delete" />
                              </IconButton>
                            </Grid>
                            :
                            null
                        }
                      </Grid>
                      {
                        formik.errors.cancelledCheque ?
                          <DezinerAlert>
                            {formik.errors.cancelledCheque}
                          </DezinerAlert>
                          :
                          null
                      }
                    </>

                  </Grid>
                </>
            }

            <Grid container justifyContent="center" alignItems="center" className={classes.submitContainer}>
              <DezinerButton disabled={loading} variant="contained" color="primary" type="submit">
                {step == 1 ? 'Next' : 'Create'}
                {
                  loading ?
                    <CircularProgress color="inherit" size={20} style={{ margin: '0 5px' }} />
                    :
                    null
                }
              </DezinerButton>
            </Grid>

          </Form>
        }
      </Formik>
    </div>
  );
}
