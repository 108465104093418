import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserEditAboutController, {
  Props,
} from "./UserEditAboutController.web";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import { plusIcon, markerIcon, personIcon, checkIcon } from './assets';
import { useDecodedToken, useMyProfile, useUpdateBioAndSkills } from "../../../components/src/hooks";
import { EditableAreaOnHover, EditableAreaOnHoverActive, EditableAreaOnHoverInActive } from "../../../components/src/EditableAreaOnHover.web";
import DezinerTextField from "../../../components/src/TextField.web";


const useUserAboutStyles = makeStyles({
  noData: {
    color: '#939393',
    fontSize: '1rem',
  },
  aboutText: {
    fontWeight: 'bold',
    fontSize: '2.2rem',
    padding: '0 10px'
  },
  btnIcon: {
    width: '20px',
    maxWidth: '20px',
  },
  textIcon: {
    width: '20px',
    maxWidth: '20px',
    margin: '0 10px',
    '&~*': {
      color: '#444',
      whiteSpace: 'nowrap',
    }
  },
  grayContainer: {
    background: "#f5f5f5",
    padding: "10px 5px",
    borderRadius: "8px",
    '&>div': {
      margin: '5px'
    }
  },
  grayContainerOnHover: {
    background: "#f5f5f5",
    padding: "10px 20px",
    borderRadius: "8px",
    '&:hover': {
      background: 'transparent',
    },
    '&>div': {
      margin: '5px'
    }
  },
  pageContainer: {
    padding: "40px 20px",
    maxWidth: "1110px",
    margin: "0 auto 40px",
  },


});


const CustomizedDezinerTextInput = withStyles({
  root: {
    '& .Mui-disabled': {
      color: 'black'
    },
    '& .MuiOutlinedInput-root': {
      padding: '10px 0',
      '& fieldset': {
        border: '0px solid gray'
      },
      '&:hover fieldset': {
        border: '0px solid gray'
      },
      '&.Mui-focused fieldset': {
        border: '0px solid gray'
      },
    },
  }
})(DezinerTextField);



function UserAbout() {

  const classes = useUserAboutStyles();

  const { id } = useDecodedToken();

  const profileQuery = useMyProfile();

  const profileAttributes = profileQuery?.data?.data?.attributes;

  const allowEdit = Boolean(profileAttributes?.id == id);

  const { mutate: updateAbout } = useUpdateBioAndSkills({ designerId: id }); //@fix
  const [bio, setBio] = useState('');

  useEffect(() => {
    setBio(profileAttributes?.bio ?? '');
  }, [profileAttributes]);



  return (
    <Grid
      container
      className={classes.pageContainer}
      justifyContent="space-between"
      alignItems="flex-start">

      <Grid item xs={12} sm={7}>

        <Typography variant="h3" className={classes.aboutText}>
          {'About'}
        </Typography>

        <VerticalSpacer />

        <EditableAreaOnHover
          title="Bio"
          allowEdit={allowEdit}
          onSave={() => {
            updateAbout({
              bio,
              skills: [],
            });
          }}
          saveButtonTitle="Save Bio"
          editButtonTitle="Edit Bio"
        >

          <EditableAreaOnHoverActive>
            <CustomizedDezinerTextInput
              placeholder={"Write your bio here"}
              autoHeight
              value={bio}
              onChange={(e:any) => {
                setBio(e.target.value);
              }}
              multiline
              minRows={bio?.split(' ')?.length < 20 ? 2 : 6}
              fullWidth
              variant="outlined"
            />
          </EditableAreaOnHoverActive>

          <EditableAreaOnHoverInActive>
            {
              bio ?
                <Typography variant="caption" className={classes.noData}>
                  {bio}
                </Typography>
                :
                <Typography variant="caption" className={classes.noData}>
                  {
                    allowEdit ?
                      'No Bio. You can write your bio by clicking on (Edit Bio)'
                      :
                      'No Bio.'
                  }
                </Typography>
            }

          </EditableAreaOnHoverInActive>


        </EditableAreaOnHover>



        <VerticalSpacer />



      </Grid>
      <Grid item xs={12} sm={1} />
      <Grid item xs={12} sm={4}>


        <VerticalSpacer />
        <VerticalSpacer />

        <Grid container className={classes.grayContainer}>
          <Grid item xs container alignItems="center" wrap="nowrap">
            <img src={plusIcon} alt="followers" className={classes.textIcon} />
            <Typography>
              {`${profileAttributes?.follower_count ?? 0} Followers`}
            </Typography>
          </Grid>
          <Grid item xs container alignItems="center" wrap="nowrap">
            <img src={checkIcon} alt="following" className={classes.textIcon} />
            <Typography>
              {`${profileAttributes?.following_count ?? 0} Following`}
            </Typography>
          </Grid>
        </Grid>

        <VerticalSpacer />
        <VerticalSpacer />

        <Grid container direction="column" className={classes.grayContainer}>
          <Grid item xs={12} container alignItems="center">
            <img src={markerIcon} alt="location" className={classes.textIcon} />
            <Typography>
              {profileAttributes?.location ?? ''}
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <img src={personIcon} alt="person" className={classes.textIcon} />
            <Typography>
              {`Member Since ${profileAttributes?.created_at ?? ''}`}
            </Typography>
          </Grid>
        </Grid>

        <VerticalSpacer />
        <VerticalSpacer />


      </Grid>

    </Grid>
  );
}




class UserEditAbout extends UserEditAboutController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      <>
        <UserAbout />
      </>
    );
  }
}


export default UserEditAbout;
