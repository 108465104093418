import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  profileImgUrl: string;
  designData: any;
  loading: boolean;
  currentImage: string;
  designImages: Array<string>;
  comment: string;
  currentDesign: number;
  comments: any;
  moreDesigns: any;
  dialogStatus: boolean;
  collections: Array<String>;
  selectedCollection: any;
  collectionForm: boolean;
  collectionName: string;
  privacy: boolean;
  showRating: boolean;
  userRating: number;
  inspireAccounts: any;
  profileRole: string;
  shareDialogStatus: boolean;
  shareLink: string;
  shareDesignLink: string;
  loadingShare: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GroupDesignViewByDesignerController extends BlockComponent<
  Props,
  S,
  SS
> {
  profileMessageId: string;
  designDataMessageId: string;
  commentMessageId: string;
  collectionsMessageId: string;
  saveDesignMessageId: string;
  saveRatingMessageId: string;
  inspiredMessageId: string;
  likeDesignMessageId: string;
  shareDesignMsgApi: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.postComment = this.postComment.bind(this);
    this.viewDesign = this.viewDesign.bind(this);
    this.saveDesign = this.saveDesign.bind(this);
    this.saveInNewCollection = this.saveInNewCollection.bind(this);
    this.saveRating = this.saveRating.bind(this);
    this.likeDesign = this.likeDesign.bind(this);
    this.shareDesign = this.shareDesign.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      profileImgUrl:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      designData: {},
      loading: true,
      currentImage: "",
      designImages: [],
      comment: "",
      currentDesign: this.props.navigation.getParam("id", 0),
      comments: [],
      moreDesigns: [],
      dialogStatus: false,
      collections: [],
      selectedCollection: '',
      collectionForm: false,
      collectionName: '',
      privacy: false,
      showRating: false,
      userRating: 0,
      inspireAccounts: [],
      profileRole: "",
      shareDialogStatus: false,
      shareLink: '',
      shareDesignLink: '',
      loadingShare: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getProfile();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          profileImgUrl: responseJson.data.attributes.profile_url,
          profileRole: responseJson.data.attributes.role,
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // DESIGN DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designDataMessageId != null &&
      this.designDataMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        // DESIGN DATA
        this.setState({ designData: responseJson.design.data.attributes });
        // CURRENT IMAGE
        this.setState({
          currentImage: responseJson.design.data.attributes.image_url,
        });
        // SUB IMAGES
        const arrImages = [];
        arrImages.push(responseJson.design.data.attributes.image_url);
        if (responseJson.design.data.attributes.sub_designs.length > 0) {
          responseJson.design.data.attributes.sub_designs.map((item: any) => {
            arrImages.push(item.url);
          });
        }
        this.setState({ designImages: arrImages });

        // COMMENTS
        const arrComments: any = [];
        if (responseJson.design.data.attributes.comment_count > 0) {
          for (
            let i = 0;
            i < responseJson.design.data.attributes.comment_count;
            i++
          ) {
            if (i >= 3) break;
            arrComments.push(
              responseJson.design.data.attributes.comments.data[i].attributes
            );
          }
        }
        this.setState({ comments: arrComments });

        // MORE DESIGNS
        const arrMoreDesigns: any = [];
        if (
          "data" in responseJson.design.data.attributes.more_designs &&
          responseJson.design.data.attributes.more_designs.data.length > 0
        ) {
          for (
            let i = 0;
            i < responseJson.design.data.attributes.more_designs.data.length;
            i++
          ) {
            if (i >= 4) break;
            arrMoreDesigns.push(
              responseJson.design.data.attributes.more_designs.data[i]
                .attributes
            );
          }
        }
        this.setState({ moreDesigns: arrMoreDesigns });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading: false });
    }

    // POST COMMENT API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.commentMessageId != null &&
      this.commentMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ comments: [responseJson.data.attributes, ...this.state.comments] });
        this.setState({ comment: "" });
        toast.success("Comment Posted Successfull");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(errorReponse);
      }
    }

    // GET COLLECTIONS
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.collectionsMessageId != null &&
      this.collectionsMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ collections: responseJson.data });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveDesignMessageId != null &&
      this.saveDesignMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ dialogStatus: false });
      this.setState({ collectionForm: false });
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const designData = this.state.designData;
        designData.saved = true;
        this.setState({ designData: designData });
        toast.success("Saved Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        const msg = responseJson.errors[0].message;
        toast.error(msg);
      }
    }

    // Save Rating
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveRatingMessageId != null &&
      this.saveRatingMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ showRating: false });
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.state.designData.rating_count += this.state.userRating;
        toast.success("Submitted Successfully");
      } else {
        var errorReponse: any = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        const msg = responseJson.errors[0].message;
        toast.error(msg);
      }
    }

    // GET INSPIRED ACCOUNT
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.inspiredMessageId != null &&
      this.inspiredMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ inspireAccounts: responseJson.data });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // LIKE DESIGN
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.likeDesignMessageId != null &&
      this.likeDesignMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const designData = this.state.designData;
        designData.liked = responseJson.message === "Like";
        this.setState({ designData: designData });
        toast.success(`${responseJson.message} Successfully`);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        const msg = responseJson.errors[0].message;
        toast.error(msg);
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.shareDesignMsgApi != null &&
      this.shareDesignMsgApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          shareDesignLink: responseJson.data.attributes.attachment_url,
          shareLink: responseJson.meta.share_url,
          shareDialogStatus: true,
          loadingShare: false
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    this.getDesignData();
    // this.getCollections();
    // this.getInspired();
    return true;
  }

  // Customizable Area Start
  getDesignData(): boolean {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    let url = `${configJSON.designApi}/${this.state.currentDesign}`;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });

    this.designDataMessageId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  }

  handleImageClick(e: any) {
    this.setState({ currentImage: e.target.src });
    const collection: any = document.getElementsByClassName("subImages");
    for (let i = 0; i < collection.length; i++) {
      collection[i].classList.remove("active");
    }
    e.target.classList.add("active");
  }

  postComment() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          comment: this.state.comment,
          commentable_type: "BxBlockAttachment::Design",
          commentable_id: this.state.currentDesign,
        },
      },
    };

    const postComment = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.postComment,
    });

    this.commentMessageId = postComment.messageId;
    runEngine.sendMessage(postComment.id, postComment);
    return true;
  }

  viewDesign(id: number) {
    this.setState({ currentDesign: id });
    this.getDesignData();
  }

  getCollections() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getCollectionsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.collectionsApi,
    });

    this.collectionsMessageId = getCollectionsData.messageId;
    runEngine.sendMessage(getCollectionsData.id, getCollectionsData);
    return true;
  }

  saveDesign() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          design_id: this.state.currentDesign,
          collection_id: this.state.selectedCollection,
          collection_name: ""
        }
      }
    };

    const saveDesignData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.saveDesign,
    });

    this.saveDesignMessageId = saveDesignData.messageId;
    runEngine.sendMessage(saveDesignData.id, saveDesignData);
    return true;    
  }

  saveInNewCollection() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          design_id: this.state.currentDesign,
          collection_id: "",
          collection_name: this.state.collectionName,
          privacy: this.state.privacy ? "Private" : "Public"
        }
      }
    };

    const saveDesignData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.saveDesign,
    });

    this.saveDesignMessageId = saveDesignData.messageId;
    runEngine.sendMessage(saveDesignData.id, saveDesignData);
    return true;
  }

  saveRating() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          design_id: this.state.currentDesign,
          point: this.state.userRating
        }
      }
    };

    const submitRatingData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.submitRating,
    });

    runEngine.sendMessage(submitRatingData.id, submitRatingData);
    this.saveRatingMessageId = submitRatingData.messageId;
    return true;
  }

  getInspired() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const url = `${configJSON.inspiredAccs}/${this.state.currentDesign}/inspire_accounts`;
    const inspiredData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });
    

    this.inspiredMessageId = inspiredData.messageId;
    runEngine.sendMessage(inspiredData.id, inspiredData);
    return true;    
  }

  likeDesign() {    
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          likeable_type: "BxBlockAttachment::Design",
          likeable_id: this.state.currentDesign
        }
      }
    };

    const submitRatingData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.likeDesign,
    });

    runEngine.sendMessage(submitRatingData.id, submitRatingData);
    this.likeDesignMessageId = submitRatingData.messageId;
    return true;
  }

  shareDesign() {
    this.setState({ loadingShare: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const designId = this.props.navigation.getParam("id" , "");
    const httpBody = {
      data: {
        attributes: {
          sharable_type: "BxBlockAttachment::Design",
          sharable_id: designId
        }
      }
    };
    let url = configJSON.designSharingLink;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: url,
    });

    this.shareDesignMsgApi = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
  }

  // Customizable Area End
}
