import React, { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MicIcon from '@material-ui/icons/Mic';
import MicNoneIcon from '@material-ui/icons/MicNone';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseCircleIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import SendIcon from '@material-ui/icons/Send';
import DezinerTextField from "../../../components/src/TextField.web";
import DezinerAudio from "../../../components/src/DezinerAudio.web";

const useCommentStyles = makeStyles({
  pulse: {
    margin: "0px",
    display: "block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: "rgba(221, 15, 15, 0.6)",
    cursor: "pointer",
    boxShadow: "0 0 0 rgba(221, 15, 15, 0.6)",
    animation: "pulse 2s infinite",
    position: 'absolute',
    top: 0,
    right: '2px'
  },
  root: {
    margin: '10px 0', overflow: 'hidden',
  },
  inputAdornment: {
    position: 'absolute',
    right: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '10px',
  },
  inputAdornmentSingleLine: {
    bottom: '15px',
    right: '10px',
  },
  recordIcon: {
    color: 'red',
  },
  audioContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px'
  },
  fileLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px'
  },
  cancelBtn: {
    backgroundColor: "#e1e1e1",
    color: "#242424",
    textTransform: 'none',
    margin: '0 20px'
  },
  postBtn: {
    backgroundColor: "black",
    color: "white",
    textTransform: 'none'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
  },
  attachementContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  extraInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 2px',
    flexWrap: 'wrap'
  },
  fileIconBtn: {
    transform: 'rotate(45deg)'
  },
  recordIconBtn: {
    position: 'relative'
  },
  closeIcon: {
    color: '#e1e1e1'
  },
  hidden: {
    display: 'none'
  }
})



function fileToBase64(file: any): Promise<any> {
  return new Promise((resolve, reject) => {
    if (file) {
      var reader = new FileReader();
      reader.onloadend = function() {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      reader.readAsDataURL(file);
    } else {
      reject(null);
    }
  });
};

interface OnCommentParams {
  commentText: string,
  base64Image: string,
  base64Audio: string,
}
interface CommentInputProps {
  singleLine?: boolean,
  onComment: (params: OnCommentParams) => void,
  hideVoiceRecorder?: boolean,
  hideFileAttachements?: boolean,
  placeholder?: string,
}

function CommentAudio({
  base64Audio,
  onDelete,
}: {
  base64Audio: string,
  onDelete: () => void,
}) {

  const classes = useCommentStyles();

  if (!base64Audio)
    return null;

  return (
    <div className={classes.audioContainer}>
      <DezinerAudio
        src={base64Audio}
      />
      <IconButton size="small" onClick={onDelete}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

function CommentImage({
  base64Image,
  onDelete,
  fileName,
}: {
  base64Image: string,
  onDelete: () => void,
  fileName: string,
}) {

  const classes = useCommentStyles();

  if (!base64Image)
    return null;

  return (
    <div className={classes.fileLinkContainer}>
      <a
        target="_blank"
        href={base64Image}
        download={fileName}
      >
        {fileName}
      </a>
      <IconButton size="small" onClick={onDelete}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

function CommentPostButtons({
  singleLine,
  hasNoUserInput,
  onCancel,
  onPost,
}: {
  singleLine?: boolean,
  hasNoUserInput: boolean,
  onCancel: () => void,
  onPost: () => void,
}) {

  const classes = useCommentStyles();

  if (singleLine)
    return null;

  return (
    <div className={classes.buttonsContainer}>
      <Button
        className={classes.cancelBtn}
        onClick={onCancel}
        disabled={hasNoUserInput}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.postBtn}
        variant="contained"
        onClick={onPost}>
        {'Post'}
      </Button>
    </div>
  );
}


function CommentCloseIcon({
  onClick,
  hide,
}: {
  onClick: () => void,
  hide: boolean,
}) {

  const classes = useCommentStyles();

  if (hide)
    return null;

  return (
    <IconButton onClick={onClick} size="small">
      <CloseCircleIcon fontSize="small" className={classes.closeIcon} />
    </IconButton>
  );
}

function CommentAttachIcon({
  onClick,
  hide,
}: {
  onClick: () => void,
  hide?: boolean,
}) {

  const classes = useCommentStyles();

  if (hide)
    return null;

  return (
    <IconButton data-testid="file-upload-btn" onClick={onClick} size="small" className={classes.fileIconBtn}>
      <AttachFileIcon fontSize="small" />
    </IconButton>
  );

}

function CommentRecordingIcon({
  onClick,
  hide,
  isRecording,
}: {
  onClick: () => void,
  hide?: boolean,
  isRecording: boolean,
}) {

  const classes = useCommentStyles();

  if (hide)
    return null;

  return (
    <IconButton data-testid="record-btn" onClick={onClick} size="small" className={classes.recordIconBtn}>
      {
        isRecording ?
          <>
            <MicIcon className={classes.recordIcon} fontSize="small" />
            <span className={classes.pulse}></span>
          </>
          :
          <MicNoneIcon fontSize="small" />
      }
    </IconButton>
  );

}



function CommentSendIcon({
  onClick,
  hide,
  hideDivider,
}: {
  onClick: () => void,
  hide?: boolean,
  hideDivider?: boolean,
}) {


  if (hide)
    return null;

  return (
    <>
      {
        hideDivider ?
          null
          :
          <Divider style={{
            height: "20px",
            margin: "0px 8px",
            background: "#757575",
            width: "2px"
          }} />
      }
      <IconButton data-testid="send-btn" size="small" onClick={onClick}>
        <SendIcon fontSize="small" />
      </IconButton>
    </>
  );

}




export default function CommentInput(props: CommentInputProps) {

  const {
    singleLine,
    onComment,
    hideVoiceRecorder,
    hideFileAttachements,
    placeholder,
  } = props;


  const classes = useCommentStyles();
  const fileInputRef = useRef<any>();
  const streamRef = useRef<any>();

  const [commentText, setCommentText] = useState('');
  const [base64Image, setBase64Image] = useState('');
  const [recorder, setRecorder] = useState<any>(null);
  const [base64Audio, setBase64Audio] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [fileName, setFileName] = useState('');

  const [commentTextRequired, setCommentTextRequired] = useState(false);

  const commentTextChangeHandler = useCallback((event: any) => {
    let value = event.target.value;
    setCommentText(value);
    if (value.trim()) {
      setCommentTextRequired(false);
    }
  }, []);

  async function handleAudioData(event: any) {
    event.preventDefault();
    const result = await fileToBase64(event.data)
    setBase64Audio(result);
  }

  function reset() {
    setCommentText('');
    setBase64Image('');
    setBase64Audio('');
    setIsRecording(false);
    setFileName('');
  }

  useEffect(function onMount() {
    return function onUnMount() {
      if (recorder)
        recorder.removeEventListener("dataavailable", handleAudioData);
    }
  }, []);


  function cancelHandler() {
    reset();
  }

  function postHandler() {
    let text = commentText.trim();
    if (text) {
      setCommentTextRequired(false);
      onComment({ commentText, base64Image, base64Audio });
      reset();
    } else {
      setCommentTextRequired(true);
    }
  }

  async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    streamRef.current = stream;
    // @ts-ignore: Unreachable code error
    return new MediaRecorder(stream);
  }

  async function startRecording() {
    setIsRecording(true);
    if (!recorder) {
      let _recorder = await requestRecorder();
      setRecorder(_recorder);
      _recorder.start();
      _recorder.addEventListener("dataavailable", handleAudioData);
    } else {
      recorder.start();
    }
  };

  function stopRecording(): void {
    setIsRecording(false);
    recorder.stop();
    if (streamRef && streamRef.current) {
      if (streamRef.current.getTracks().length)
        streamRef.current.getTracks()?.[0]?.stop()
    }
    setRecorder(null);
  }

  async function fileInputChangeHandler(event: any) {
    let file = event.target.files?.[0];
    let fileName = file?.name;
    if (fileName)
      setFileName(fileName);
    let _base64Image = await fileToBase64(file);
    setBase64Image(_base64Image);
  }

  function fileUploadHandler() {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  function recordToggleHandler() {
    if (!isRecording)
      startRecording();
    else
      stopRecording();
  }

  function deleteAudioHandler() {
    setBase64Audio('');
  }

  function deleteImageHandler() {
    setBase64Image('');
    setFileName('');
  }

  function keyUpHandler(event: any) {
    if (event.key == 'Enter' && singleLine) {
      postHandler();
    }
  }


  const hasUserInput = commentText || base64Image || base64Audio;
  const hasNoUserInput = !hasUserInput;
  const displayCloseButton = singleLine && hasUserInput;


  return (
    <>
      <input
        accept="image/*"
        ref={fileInputRef}
        type="file"
        className={classes.hidden}
        onChange={fileInputChangeHandler}
      />


      <div className={classes.root}>

        <div>

          <DezinerTextField
            data-testid="comment-input"
            autoHeight={true}
            multiline={!singleLine}
            minRows={5}
            placeholder={placeholder || "Write your comment..."}
            value={commentText}
            onChange={commentTextChangeHandler}
            variant="outlined"
            fullWidth
            onKeyUp={keyUpHandler}
            error={commentTextRequired}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    className={clsx(classes.inputAdornment, {
                      [classes.inputAdornmentSingleLine]: singleLine
                    })}
                  >

                    <CommentCloseIcon
                      hide={!displayCloseButton}
                      onClick={reset}
                    />
                    <CommentAttachIcon
                      onClick={fileUploadHandler}
                      hide={hideFileAttachements}
                    />
                    <CommentRecordingIcon
                      hide={hideVoiceRecorder}
                      isRecording={isRecording}
                      onClick={recordToggleHandler}
                    />

                    <CommentSendIcon
                      hide={!singleLine}
                      hideDivider={hideVoiceRecorder && hideFileAttachements}
                      onClick={postHandler}
                    />
                  </div>
                </InputAdornment>
              )
            }}
          />

        </div>


        <div className={classes.extraInfoContainer}>
          <div className={classes.attachementContainer}>
            <CommentAudio
              base64Audio={base64Audio}
              onDelete={deleteAudioHandler}
            />
            <CommentImage
              base64Image={base64Image}
              onDelete={deleteImageHandler}
              fileName={fileName}
            />
          </div>

          <CommentPostButtons
            singleLine={singleLine}
            hasNoUserInput={hasNoUserInput}
            onCancel={cancelHandler}
            onPost={postHandler}
          />
        </div>

      </div>
    </>
  );
}
