import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import CfWatermarkImages2 from "../../CfWatermarkImages2/src/CfWatermarkImages2";
import { decode as base64_decode, encode as base64_encode } from "base-64";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  collectionId: any;
  labelId: any;
  type: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  title: string;
  tags: any;
  privacy: string;
  price: number;
  description: string;
  setting: boolean;
  watermarks: any[];
  selectedWatermark: any;
  subDesign: any;
  mainImage: any;
  mainImageBoolean: boolean;
  previewMainImage: any;
  previewSubDesigns: any;
  errors: any;
  categories: any;
  audioURL: string;
  isRecording: boolean;
  recorder: any;
  attachment: any;
  publishLoading: boolean;
  waterMarkWidth: string;
  waterMarkHeight: string;
  step: number;
  valueTab: number;
  sourceLink: string;
  sourceFiles: any;
  time_period_type: string;
  time_periods: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignerDesignUploadController extends BlockComponent<
  Props,
  S,
  SS
  > {
  getProductApiCallId: any;
  getWatermarksApiCallId: any;
  publishDesignCallId: any;
  getCategoriesId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.onUploadSubDesign = this.onUploadSubDesign.bind(this);
    this.handleData = this.handleData.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.onDropSource = this.onDropSource.bind(this);
    this.uploadData = this.uploadData.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      title: "",
      tags: [],
      privacy: "",
      description: "",
      setting: false,
      price: 0,
      watermarks: [],
      selectedWatermark: null,
      subDesign: [],
      mainImage: null,
      mainImageBoolean: false,
      previewMainImage: null,
      previewSubDesigns: [],
      errors: {},
      categories: [],
      audioURL: "",
      isRecording: false,
      recorder: null,
      attachment: null,
      publishLoading: false,
      waterMarkWidth: "",
      waterMarkHeight: "",
      step: 2,
      valueTab: 0,
      sourceLink: "",
      sourceFiles: [],
      time_period_type: "Auto",
      time_periods: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getCategories();
    this.getWatermarks();
  }
  componentWillUnmount = (): any => {
    if (this.state.recorder) {
      this.state.recorder.removeEventListener("dataavailable", this.handleData);
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getListRequest(token);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getWatermarksApiCallId != null &&
      this.getWatermarksApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ watermarks: responseJson.data });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.publishDesignCallId != null &&
      this.publishDesignCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        const type = this.props.navigation.getParam("type");
        if (type === 'designer') {
          this.props.navigation.navigate("Catalogue", { designerId: localStorage.getItem('cuid') });
        }
        else if (type === 'club') {
          this.props.navigation.navigate("ClubCreateCollection", {
            id: this.props.navigation.getParam("clubId", "")
          });
        }
        else if (type === 'group') {
          this.props.navigation.navigate("GroupCreateCollection", {
            id: this.props.navigation.getParam("clubId", "")
          });
        }
      } else {
        this.setState({ publishLoading: false });
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoriesId != null &&
      this.getCategoriesId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        console.log(responseJson.data)
        const categories = responseJson.data.map((category: any) => {
          const subCategories = category.attributes.sub_categories.data.map((subCategory: any) => {
            return {
              value: subCategory.attributes.id,
              label: subCategory.attributes.name,
            };
          })
          return {
            label: category.attributes.name,
            options: subCategories,
          }
        })
        this.setState({ categories });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  getWatermarks = () => {
    const token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWatermarksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.watermarksApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategories = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.getCategoriesApi,
    });

    this.getCategoriesId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
  }

  validation = () => {
    let errors = {
      title: "",
      tags: "",
      privacy: "",
      description: "",
      price: "",
      watermark: "",
      mainImage: "",
      subDesign: "",
    };
    let flag = false;
    if (!this.state.title || this.state.title.trim().length === 0) {
      errors.title = "Title is required";
      flag = true;
    } else if (this.state.title.length < 3) {
      errors.title = "Title must be at least 3 characters";
      flag = true;
    }
    if (this.state.tags.length === 0) {
      errors.tags = "Tags is required";
      flag = true;
    }
    if (!this.state.privacy) {
      errors.privacy = "Privacy is required";
      flag = true;
    }
    if (this.state.description === "") {
      errors.description = "Description is required";
      flag = true;
    }
    if (
      this.state.privacy === "Private" &&
      (!this.state.price || this.state.price <= 0)
    ) {
      errors.price = "Price is required";
      flag = true;
    }
    if (!this.state.mainImageBoolean) {
      errors.mainImage = "Please upload a main image";
      flag = true;
    }
    if (this.state.selectedWatermark === null) {
      errors.watermark = "Please select a watermark";
      flag = true;
    }
    this.setState({ errors: errors });
    return flag;
  }

  handleChange = (selectedOption: any) => {
    this.setState({ tags: selectedOption });
  };

  getDataAccordingToType = () => {
    const {
      title,
      tags,
      description,
      price,
      privacy,
      mainImage,
      selectedWatermark,
      setting,
      subDesign,
      audioURL,
      waterMarkWidth,
      waterMarkHeight,
      sourceLink,
      sourceFiles,
      time_period_type,
      time_periods,
    } = this.state;
    const labelId = this.props.navigation.getParam("labelId", "");
    const collectionId = this.props.navigation.getParam("collectionId", "");
    const clubId = this.props.navigation.getParam("clubId", "");
    const type = this.props.navigation.getParam("type", "");
    const sub_design = subDesign.map((design: any, index: number) => {
      return { data: design.src };
    });
    const newTags = tags.map((tag: any) => {
      return { sub_category_id: tag.value };
    });
    const watermark_id_number = Number(selectedWatermark.id);

    if (type === 'designer') {
      let data = {
        data: {
          attributes: {
            collection_id: collectionId,
            watermark_id: watermark_id_number,
            label_id: labelId,
            design_type: "Designer",
            title: title,
            designs_sub_categories_attributes: newTags,
            privacy: privacy,
            description: description,
            price: price.toString(),
            width: waterMarkWidth,
            height: waterMarkHeight,
            is_thumbnail: setting,
            image: {
              data: mainImage.src,
            },
            sub_designs: sub_design,
            audios: [{
              data: ""
            }],
            source_link: sourceLink,
            source_files: sourceFiles,
            time_period_type,
            time_periods,
          },
        },
      };
      if (audioURL !== "") {
        data = {
          data: {
            attributes: {
              ...data.data.attributes,
              audios: [{
                data: audioURL
              }]
            }
          }
        };
      } else {
        data = {
          data: {
            attributes: {
              ...data.data.attributes,
              audios: []
            }
          }
        };
      }
      return data;
    }
    else if (type === 'club') {
      let data = {
        data: {
          attributes: {
            club_id: clubId,
            collection_id: collectionId,
            watermark_id: watermark_id_number,
            label_id: labelId,
            title: title,
            designs_sub_categories_attributes: newTags,
            privacy: privacy,
            description: description,
            price: price.toString(),
            width: waterMarkWidth,
            height: waterMarkHeight,
            is_thumbnail: setting,
            design_type: "Club",
            image: {
              data: mainImage.src,
            },
            sub_designs: sub_design,
            audios: [{
              data: ""
            }],
            source_link: sourceLink,
            source_files: sourceFiles,
            time_period_type,
            time_periods,
          },
        },
      };
      if (audioURL !== "") {
        data = {
          data: {
            attributes: {
              ...data.data.attributes,
              audios: [{
                data: audioURL
              }]
            }
          }
        };
      } else {
        data = {
          data: {
            attributes: {
              ...data.data.attributes,
              audios: []
            }
          }
        };
      }
      return data;
    }
    else if (type === 'group') {
      let data = {
        data: {
          attributes: {
            group_id: clubId,
            collection_id: collectionId,
            watermark_id: watermark_id_number,
            label_id: labelId,
            title: title,
            designs_sub_categories_attributes: newTags,
            privacy: privacy,
            description: description,
            price: price.toString(),
            width: waterMarkWidth,
            height: waterMarkHeight,
            is_thumbnail: setting,
            design_type: "Group",
            image: {
              data: mainImage.src,
            },
            sub_designs: sub_design,
            audios: [{
              data: ""
            }],
            source_link: sourceLink,
            source_files: sourceFiles,
            time_period_type,
            time_periods,
          },
        },
      };
      if (audioURL !== "") {
        data = {
          data: {
            attributes: {
              ...data.data.attributes,
              audios: [{
                data: audioURL
              }]
            }
          }
        };
      } else {
        data = {
          data: {
            attributes: {
              ...data.data.attributes,
              audios: []
            }
          }
        };
      }
      return data;
    }
  }

  onPublish = () => {
    this.setState({
      errors: {},
      publishLoading: true
    })
    if (this.validation()) {
      this.setState({
        publishLoading: false,
      });
      return;
    }

    this.setState({
      step: 2
    });
  };

  onUploadSubDesign = (e: any) => {
    e.preventDefault();
    if (this.state.subDesign.length + 2 <= 5) {
      if (!this.fileValidationSubDesign(e.target)) return;
      let filePath = e.target.value;
      var idxDot = filePath.lastIndexOf(".") + 1;
      var extFile = filePath.substr(idxDot, filePath.length).toLowerCase();
      this.encodeImageFileAsURLSubDesign(e.target).then((res: any) => {
        // Allowing file type
        if (!this.state.mainImageBoolean) {
          this.setState({
            mainImage: {
              type: ["jpg", "jpeg", "png"].indexOf(extFile) != -1 ? 'img' : 'video',
              src: res
            },
            mainImageBoolean: true,
          }, () => {
            console.log('spec_mainImage', this.state.mainImage);
          });
        } else {
          this.setState({
            subDesign: [...this.state.subDesign, {
              type: ["jpg", "jpeg", "png"].indexOf(extFile) != -1 ? 'img' : 'video',
              src: res
            }],
          });
        }
      });
    } else {
      toast.error("Max 5 Media files can be uploaded");
    }
  };


  encodeImageFileAsURLSubDesign(element: any) {
    return new Promise((resolve, reject) => {
      var file = element.files[0];
      var reader = new FileReader();
      reader.onloadend = function() {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      console.log(file);
      reader.readAsDataURL(file);
    });
  }

  encodeImageFileAsURL(file: any) {
    console.log('hey')
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onloadend = function() {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      console.log(file);
      reader.readAsDataURL(file);
    });
  }

  onDrop = (file: any) => {
    const baseImgs: any = [];
    let validation = true;
    if (
      file.length <= 5 &&
      parseInt(this.state.subDesign.length + 1) <= 5
    ) {
      let currentCount = this.state.subDesign.length;
      if (this.state.mainImageBoolean) {
        currentCount += 1;
      }
      if (file.length <= (5 - currentCount)) {
        file.map((img: any) => {
          if (!this.fileValidation(img)) {
            validation = false;
          }
        });
        if (validation) {
          new Promise((resolve, reject) => {
            file.map((img: any) => {
              this.encodeImageFileAsURL(img).then((res) => {
                let filePath = img.path;
                // Allowing file type
                var idxDot = filePath.lastIndexOf(".") + 1;
                var extFile = filePath.substr(idxDot, filePath.length).toLowerCase();
                baseImgs.push({
                  src: res,
                  type: ["jpg", "jpeg", "png"].indexOf(extFile) != -1 ? 'img' : 'video',
                });
                if (baseImgs.length === file.length) {
                  resolve(baseImgs);
                }
              });
            });
          }).then(() => {
            if (!this.state.mainImageBoolean) {
              const mainImg = baseImgs[0];
              baseImgs.splice(0, 1);
              const subImgs = [...this.state.subDesign, ...baseImgs];
              this.setState({
                mainImageBoolean: true,
                mainImage: mainImg,
                subDesign: subImgs
              });
            } else {
              const subImgs = [...this.state.subDesign, ...baseImgs];
              this.setState({
                subDesign: subImgs
              });
            }
          });
        } else {
          toast.error("Only Image (MAX 10MB) and Video (MAX 20MB) files are allowed!");
        }
      } else {
        toast.error("Only 5 media is allowed to upload");
      }
    } else {
      toast.error("Only 5 media is allowed to upload");
    }
  };

  fileValidation(file: any) {
    let filePath = file.path;
    // Allowing file type
    var idxDot = filePath.lastIndexOf(".") + 1;
    var extFile = filePath.substr(idxDot, filePath.length).toLowerCase();
    const imgExts = ["jpg", "jpeg", "png"];
    const videoExts = ["mp4", "mov", "mkv"];
    const fileSize = this.bytesToMegaBytes(file.size);
    if (imgExts.indexOf(extFile) != -1 && fileSize > 10) {
      return false;
    }
    if (videoExts.indexOf(extFile) != -1 && fileSize > 20) {
      return false;
    }
    if (videoExts.indexOf(extFile) != -1 || imgExts.indexOf(extFile) != -1) {
      return true;
    } else {
      toast.error("Only image and video files are allowed!");
    }
  }

  fileValidationSubDesign(file: any) {
    let filePath = file.value;
    console.log('spec_eTrget', file.value);
    // Allowing file type
    var idxDot = filePath.lastIndexOf(".") + 1;
    var extFile = filePath.substr(idxDot, filePath.length).toLowerCase();
    const imgExts = ["jpg", "jpeg", "png"];
    const videoExts = ["mp4", "mov", "mkv"];
    const fileSize = this.bytesToMegaBytes(file.size);
    if (imgExts.indexOf(extFile) != -1 && fileSize > 10) {
      return false;
    }
    if (videoExts.indexOf(extFile) != -1 && fileSize > 20) {
      return false;
    }
    if (videoExts.indexOf(extFile) != -1 || imgExts.indexOf(extFile) != -1) {
      return true;
    } else {
      toast.error("Only image and video files are allowed!");
    }
  }

  bytesToMegaBytes = (bytes: any) => bytes / 1024 ** 2;

  validateFileSize(
    inputId: string,
    MAX_FILE_SIZE: any,
  ): boolean {
    const input = document.getElementById(inputId) as HTMLInputElement | null;
    if (input && input.files) {
      const file = input?.files[0];
      const fileSize = this.bytesToMegaBytes(file?.size);
      if (fileSize > MAX_FILE_SIZE) {
        toast.error(`File size should be less than ${MAX_FILE_SIZE} MB`);
        return false;
      }
      return true;
    }
    else {
      return false;
    }
  }

  // Audio Recording

  handleData(e: any) {
    e.preventDefault();
    this.encodeAudioAsURL(e).then((res: any) => {
      console.log(res);
      this.setState({
        audioURL: res,
      })
    });
  };

  startRecording(): void {
    this.setState({ isRecording: true });
    if (this.state.recorder === null) {
      this.requestRecorder()
        .then((res: any) => this.setState({ recorder: res }))
        .then(() => this.state.recorder.start())
        .then(() => this.state.recorder.addEventListener("dataavailable", this.handleData));
    } else {
      this.state.recorder.start();
    }
  };

  stopRecording(): void {
    this.state.recorder.stop();
    this.setState({ isRecording: false });
  };

  async requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    // @ts-ignore: Unreachable code error
    return new MediaRecorder(stream);
  }

  encodeAudioAsURL(element: any) {
    return new Promise((resolve, reject) => {
      var file = element.data;
      if (file) {
        var reader = new FileReader();
        reader.onloadend = function() {
          let imagebase64 = reader.result;
          resolve(imagebase64);
        };
        reader.readAsDataURL(file);
      } else {
        reject(null);
      }
    });
  };

  handleChangeTab(event: any, newValue: any) {
    this.setState({
      valueTab: newValue
    });
  };

  onDropSource = (files: any) => {
    new Promise((resolve, reject) => {
      const baseImgs: any = [];
      files.map((img: any) => {
        this.encodeImageFileAsURL(img).then((res) => {
          let filePath = img.path;
          // Allowing file type
          var idxDot = filePath.lastIndexOf(".") + 1;
          var extFile = filePath.substr(idxDot, filePath.length).toLowerCase();
          return {
            data: res,
            type: img.type,
            name: img.name,
            fileSize: img.size / 1024,
          };
        }).then((file) => {
          if (file) {
            console.log('spec_files', file);
            const sourceArr = [...this.state.sourceFiles, file];
            this.setState({
              sourceFiles: sourceArr
            });
          }
        });
      });
    })
  }

  uploadDesign = (payload: any) => {

    this.setState({
      publishLoading: true
    });

    const data = payload;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.publishDesignCallId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.publishDesignApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadData = () => {
    this.setState({
      errors: {},
      publishLoading: true
    })
    if (this.validationSource()) {
      this.setState({
        publishLoading: false,
      });
      return;
    }

    const data = this.getDataAccordingToType();
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.publishDesignCallId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.publishDesignApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validationSource = () => {
    let errors = {
      sourceFile: '',
      price: ''
    };
    let flag = false;
    if (this.state.sourceLink === '' && this.state.sourceFiles.length <= 0) {
      errors.sourceFile = "Please upload source file from local or add a link";
      flag = true;
    }
    if (this.state.time_period_type === '') {
      errors.price = 'Please select pricing type';
      flag = true;
    }
    if (this.state.time_periods.length <= 0) {
      errors.price = 'Please add pricing';
      flag = true;
    }
    this.setState({ errors: errors });
    return flag;
  }
  // Customizable Area End
}
