import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  profileImg: string;
  designsData: any;
  filter: string;
  subFilter: string;
  subTitle: string;
  profileData: any;
  arrLabels: any;
  loading: boolean;
  arrSubFilters: any;
  meta: any;
  isOpen: boolean;
  modalStep: number;
  newCollectionName: string;
  newCollectionPrivate: boolean;
  modalWidth: number;
  subDesign: any;
  mainImage: any;
  newCollectionNameError: string;
  modalStep3Collections: any;
  selectedCollectionId: any;
  modalStep3Errors: any;
  savedDesigns: any;
  modalLoading: boolean;
  newCollectionId: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserProfileCollectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  profileApiMessageId: string;
  getCollectionId: string;
  filterMessageApiId: string;
  newCollectionNameRef: any;
  titleRef: any;
  descriptionRef: any;
  userUploadDesign: any;
  createCollectionUserId: any;
  getSavedDesignsId: any;
  saveDesignId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getCollection = this.getCollection.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.uploadUserDesign = this.uploadUserDesign.bind(this);
    this.changeCategory = this.changeCategory.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      profileImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      designsData: {},
      filter: "Saved",
      subFilter: "",
      subTitle: "",
      profileData: {},
      arrLabels: [
        {
          id: 1,
          name: "Designs",
        },
        {
          id: 2,
          name: "Edit Profile",
        },
        {
          id: 3,
          name: "Account Settings",
        },
        {
          id: 4,
          name: "Password",
        },
        {
          id: 5,
          name: "About",
        },
        {
          id: 6,
          name: "Logout",
        },
      ],
      loading: true,
      arrSubFilters: {
        All: {
          all_designs_count: "All",
          uploaded_designs_count: "Uploaded Designs",
          save_designs_count: "Saved Design",
        },
        Liked: {
          all: "All",
          clubs: "Club",
          groups: "Group",
          designers: "Designer",
        },
        Inspired: {
          all: "All",
          clubs: "Club",
          groups: "Group",
          designers: "Designer",
        },
        Rated: {
          all: "All",
          clubs: "Club",
          groups: "Group",
          designers: "Designer",
        },
      },
      meta: {},
      isOpen: false,
      modalStep: 1,
      newCollectionName: "",
      newCollectionPrivate: false,
      modalWidth: 400,
      subDesign: [],
      mainImage: null,
      newCollectionNameError: "",
      modalStep3Collections: [],
      selectedCollectionId: null,
      modalStep3Errors: {},
      savedDesigns: [],
      modalLoading: false,
      newCollectionId: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.newCollectionNameRef = React.createRef();
    this.titleRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.getProfile();
    this.getCollection();
    this.getSavedDesign();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileApiMessageId != null &&
      this.profileApiMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ profileData: responseJson.data });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userUploadDesign != null &&
      this.userUploadDesign ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState(
          {
            modalStep: 1,
            isOpen: false,
            modalWidth: 400,
            mainImage: "",
            subDesign: "",
            selectedCollectionId: null,
            loading: false,
          },
          () => {
            this.getCollection();
          }
        );
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createCollectionUserId != null &&
      this.createCollectionUserId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        console.log("🚀 ~ file: UserProfileCollectionController.web.tsx ~ line 255 ~ receive ~ responseJson", responseJson)
        this.setState({
          modalStep: 2,
          newCollectionName: this.newCollectionNameRef.current.value,
          newCollectionId: responseJson.data.attributes.id,
        });
        this.setModalWidth(2);
        this.getCollection();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveDesignId != null &&
      this.saveDesignId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success('Design Saved Successfully');
        const id = responseJson.data.attributes.design.id;
        const {savedDesigns} = this.state;
        const newSavedDesigns = savedDesigns.filter((item: any) => {
          console.log(item.id);
          return item.id != id;
        })
        this.setState({
          savedDesigns: newSavedDesigns,
          modalLoading: false,
        })
      } else {
        toast.error(responseJson.errors[0].message);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSavedDesignsId != null &&
      this.getSavedDesignsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        console.log("🚀 ~ file: UserProfileCollectionController.web.tsx ~ line 273 ~ receive ~ responseJson", responseJson)
        this.setState({
          savedDesigns: responseJson.data,
        })
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCollectionId != null &&
      this.getCollectionId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        console.log(responseJson);
        const modalCollections = responseJson.data.map((item: any) => {
          return {
            label: item.attributes.name,
            value: item.attributes.id,
          };
        });
        this.setState(
          {
            designsData: responseJson.data,
            meta: responseJson.meta,
            modalStep3Collections: modalCollections,
          },
          () => {
            this.setState({ loading: false });
          }
        );
        console.log(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      }
    }

    // Customizable Area End
  }

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const profileApiData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });
    this.profileApiMessageId = profileApiData.messageId;
    runEngine.sendMessage(profileApiData.id, profileApiData);
    return true;
  }

  saveDesignToCollection(itemId: number) {
    this.setState({
      modalLoading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const data = {
      data: {
        attributes: {
          design_id: itemId,
          collection_id: this.state.newCollectionId
        }
      }
    }
    const httpBody = data;
    const profileApiData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.saveDesignAfterCreateCollectionApi,
    });
    this.saveDesignId = profileApiData.messageId;
    runEngine.sendMessage(profileApiData.id, profileApiData);
    return true;
  }

  getCollection(): void {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const updateData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.userGetCollectionApi,
    });

    this.getCollectionId = updateData.messageId;
    runEngine.sendMessage(updateData.id, updateData);
  }

  getSavedDesign(): void {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const updateData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.userSavedDesignsApi,
    });

    this.getSavedDesignsId = updateData.messageId;
    runEngine.sendMessage(updateData.id, updateData);
  }

  modalStep3Validation = () => {
    const errors = {
      mainImage: "",
      selectedCollectionId: "",
      title: "",
      description: "",
    };
    const { mainImage, selectedCollectionId } = this.state;
    const title = this.titleRef.current.value;
    const description = this.descriptionRef.current.value;
    let error = false;
    if (title.length < 3) {
      errors.title = "Title must be at least 3 characters";
      error = true;
    }
    if (description.length < 3) {
      errors.description = "Description must be at least 3 characters";
      error = true;
    }
    if (mainImage == "") {
      toast.error("Please select a main image");
      error = true;
    }
    if (!selectedCollectionId) {
      errors.selectedCollectionId = "Please select a collection";
      error = true;
    }
    this.setState({ modalStep3Errors: errors });
    return error;
  };

  uploadUserDesign = (e: any) => {
    e.preventDefault();
    this.setState({
      modalStep3Errors: {},
    });
    if (this.modalStep3Validation()) return;
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const { mainImage, subDesign, selectedCollectionId } = this.state;
    const sub__designs = subDesign.map((item: any) => {
      return {
        data: item,
      };
    });
    const data = {
      data: {
        attributes: {
          collection_id: selectedCollectionId.value,
          design_type: "User",
          title: this.titleRef.current.value,
          description: this.descriptionRef.current.value,
          image: {
            data: mainImage,
          },
          sub_designs: sub__designs,
        },
      },
    };
    console.log(data);
    const httpBody = data;
    const updateData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.userUploadDesignApi,
    });

    this.userUploadDesign = updateData.messageId;
    runEngine.sendMessage(updateData.id, updateData);
  };

  createCollectionUser = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const data = {
      data: {
        attributes: {
          name: this.newCollectionNameRef.current.value.trim(),
          privacy: this.state.newCollectionPrivate === true ? 'Private' : 'Public', // If you can keep this collection private, replace "Private"
          collection_type: "User",
        },
      },
    };
    const httpBody = data;
    const updateData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.userCollectionCreation,
    });

    this.createCollectionUserId = updateData.messageId;
    runEngine.sendMessage(updateData.id, updateData);
  };

  handleClose() {
    this.setState({ isOpen: false, modalStep: 1 });
  }
  handleOpen() {
    this.setState({ isOpen: true, modalStep: 1 });
    this.setModalWidth(1);
  }

  focusInput() {
    this.newCollectionNameRef.current.focus();
  }

  setModalWidth(step: number) {
    if (step === 1) {
      this.setState({
        modalWidth: 400,
      });
      return;
    }
    if (step === 2) {
      this.setState({
        modalWidth: 900,
      });
      return;
    }
  }

  encodeImageFileAsURL(element: any) {
    return new Promise((resolve, reject) => {
      var file = element.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      reader.readAsDataURL(file);
    });
  }

  onUploadSubDesign = (e: any) => {
    e.preventDefault();
    this.encodeImageFileAsURL(e.target).then((res: any) => {
      this.setState({
        subDesign: [...this.state.subDesign, res],
      });
    });
  };

  changeCategory(filterName: string) {
    this.props.navigation.navigate('UserDesignList', {
      filterName: filterName,
    });
  }

  // Customizable Area End
}
