import React from "react";
import { withStyles, } from "@material-ui/core/styles";
import TextField from "../../../components/src/TextField.web";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { searchIcon } from './assets';
import "./styles.css";
import DezinerButton from "../../../components/src/Button.web";


const SearchTextField = withStyles({
	root: {
		'& .MuiInputBase-root': {
			maxHeight: '46px',
		}
	}
})(TextField);



export default function GroupAndClubSearchHeader({
	query,
	setQuery,
	btnTitle,
	onClick,
}: {
	query: string,
	setQuery: (v: string) => void,
	btnTitle: string,
	onClick: () => void,
}) {

	return (
		<Grid container justifyContent="space-between" alignItems="center">
			<Grid item xs={12} sm={2}></Grid>
			<Grid item xs={8} sm={7} container>
				<SearchTextField
					fullWidth
					placeholder="Search"
					autoHeight
					variant="outlined"
					value={query}
					onChange={(e) => setQuery(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<img src={searchIcon} alt="search" style={{ width: '30px' }} />
							</InputAdornment>
						)
					}}
				/>
			</Grid>
			<Grid item xs={3} sm={3} container justifyContent="flex-end">
				<DezinerButton
					style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
					color="primary"
					variant="contained"
					size="large"
					onClick={onClick}
				>
					{btnTitle}
				</DezinerButton>
			</Grid>
		</Grid>
	)
}