import { Label } from "../../api-types";
import { DesignsOwner } from "../designs/designs.action";
import { DezinerActionCreator } from "../store";

export const FETCH_LABELS = 'FETCH_LABELS';

interface LabelsFetchingProperties {
  clubId?: number,
  groupId?: number,
  userId?: number,
  designerId?: number,
}

export interface FetchLabelsAction extends LabelsFetchingProperties {
  type: typeof FETCH_LABELS,
}

export const fetchLabels: DezinerActionCreator<FetchLabelsAction> =
  (params) => ({
    type: FETCH_LABELS,
    ...params,
  });

//////////// 

export const SET_LABELS = 'SET_LABELS';

export interface SetLabelsAction extends LabelsFetchingProperties {
  type: typeof SET_LABELS,
  labels: Label[],
}

export const setLabels: DezinerActionCreator<SetLabelsAction> =
  (params) => ({
    type: SET_LABELS,
    ...params,
  });

////////////// 

export const CHANGE_LABEL = 'CHANGE_LABEL';

export interface ChangeLabelAction {
  type: typeof CHANGE_LABEL,
  currentDesignerLabel?: number,
  currentGroupLabel?: number,
  currentClubLabel?: number,
  currentUserLabel?: number,
  designsOwner?: DesignsOwner,
}

export const changeLabel: DezinerActionCreator<ChangeLabelAction> =
  (params) => ({
    type: CHANGE_LABEL,
    ...params,
  });

