export const logo = require("../assets/darkLogo.png");
export const searchIcon = require("../assets/searchIcon.png");
export const noti = require("../assets/noti.png");
export const chat = require("../assets/chat.png");
export const UserBackground = require("../assets/UserBackground.png");
export const cloud = require("../assets/cloud-arrow-up-solid.svg");
export const plusButton = require("../assets/plus-solid.svg");
export const trending_up = require("../assets/promote.png");
export const ruppee = require("../assets/ruppee.svg");
export const designs = require("../assets/designs.png");
export const whiteBackground = require("../assets/whiteBackground.png");
export const aboutIcon = require("../assets/clarity_help-info-solid.svg");
export const urlLinkIcon = require("../assets/url-link.svg");
export const privacyIcon = require("../assets/privacy-icon.svg");
export const localUploadIcon = require("../assets/local-upload.svg");
export const headerIcon = require("../assets/header.svg");
export const uploadToCloudIcon = require("../assets/upload-to-cloud.svg");
export const tagIcon = require("../assets/tag.svg");
export const settingsGearIcon = require("../assets/settings-gears.svg");
export const imageExtensionIcon = require("../assets/image-extension.svg");
export const videoExtensionIcon = require("../assets/video-extension.svg");
export const descriptionIcon = require("../assets/video-extension.svg");
export const zipFileExtensionIcon = require("../assets/zip-file-extension.svg");
