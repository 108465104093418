import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profileData: any;
  selectedCategories: any;
  selectedMembers: any;
  clubName: string;
  clubDescription: string;
  profilePhoto: any;
  coverPhoto: any;
  memberId: any;
  categoryName: string;
  coverPhotoBoolean: boolean;
  profilePhotoBoolean: boolean;
  typeOfUser: string;
  email: string;
  organizationName: string;
  organizationPanId: string;
  panPicture: any;
  nameBankAccount: string;
  bankAccountNumber: string;
  bankIFSCCode: string;
  cancelledCheque: any;
  step: number;
  errors1: any;
  errors2: any;
  members: any;
  categories: any;
  categoryId: any;
  disableCreateButton: boolean;
  tags: any;
  title: string;
  description: string;
  panPhotoName: string;
  cancelledChequeName: string;
  submitLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreateClubController extends BlockComponent<Props, S, SS> {
  landingPageData: string;
  profileMessageId: any;
  overallDataId: string;
  createClubId: string;
  emailReg: RegExp;
  showMemberId: string;
  getMembersId: string;
  getCategoriesId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.deleteSelectedMember = this.deleteSelectedMember.bind(this);
    this.addSelectedMember = this.addSelectedMember.bind(this);
    this.createClub = this.createClub.bind(this);
    this.removeCategory = this.removeCategory.bind(this);
    this.handleUserInputChange = this.handleUserInputChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.fileValidation = this.fileValidation.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      profileData: {},
      selectedCategories: [],
      selectedMembers: [],
      members: [],
      tags: [],
      clubName: "",
      clubDescription: "",
      profilePhoto: "",
      coverPhoto: "",
      memberId: {},
      categoryName: "",
      coverPhotoBoolean: false,
      profilePhotoBoolean: false,
      typeOfUser: "",
      email: "",
      organizationName: "",
      organizationPanId: "",
      panPicture: "",
      nameBankAccount: "",
      bankAccountNumber: "",
      bankIFSCCode: "",
      cancelledCheque: "",
      step: 1,
      errors1: {
        selectedMembers: "",
      },
      errors2: {},
      categoryId: {},
      categories: [],
      disableCreateButton: false,
      title: "",
      description: "",
      panPhotoName: "",
      cancelledChequeName: '',
      submitLoading: false,
    };
    this.emailReg = new RegExp("\\w+");

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        // CREATE  CLUB API RESPONSE
        if (apiRequestCallId === this.createClubId) {

          this.setState({ submitLoading: false });

          if (responseJson && !responseJson.errors) {
            console.log(responseJson);
            this.props.navigation.navigate("DesignClubPageView");
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
          }
        } else if (apiRequestCallId === this.getMembersId) {
          if (responseJson && !responseJson.errors) {
            const members = responseJson.data.map(
              (member: any, index: number) => {
                return {
                  label: member.attributes.first_name,
                  value: member.id,
                };
              }
            );
            this.setState({
              members: members,
            });
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
          }
        } else if (apiRequestCallId === this.getCategoriesId) {
          if (responseJson && !responseJson.errors) {
            console.log(responseJson.data)
            const categories = responseJson.data.map((category: any) => {
              const subCategories = category.attributes.sub_categories.data.map((subCategory: any) => {
                return {
                  value: subCategory.attributes.id,
                  label: subCategory.attributes.name,
                };
              })
              return {
                label: category.attributes.name,
                options: subCategories,
              }
            })
            this.setState({ categories });
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
          }
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getMembers();
    this.getCategories();
  }

  // Customizable Area Start
  /**
    * @deprecated
    */
  encodeImageFileAsURL(element: any) {
    return new Promise((resolve, reject) => {
      var file = element.files[0];
      var reader = new FileReader();
      reader.onloadend = function() {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      reader.readAsDataURL(file);
    });
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMembers = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.getMembers,
    });

    this.getMembersId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  };

  getCategories = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.getCategories,
    });

    this.getCategoriesId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  };
  /**
    * @deprecated
    */
  hasOnlySpecialCharater(val: string) {
    var pattern = /^[^a-zA-Z0-9]+$/;
    return pattern.test(val);
  }
  /**
    * @deprecated
    */
  deleteSelectedMember(member: any) {
    const newArray = this.state.selectedMembers.filter((item: any) => {
      return item.value !== member.value;
    });
    const errors = { ...this.state.errors1 };
    errors.selectedMembers = "";
    this.setState({
      selectedMembers: newArray,
      errors1: errors,
    });
  }
  /**
    * @deprecated
    */
  searchCategory(category: any) {
    const newArray = this.state.selectedCategories.filter((item: any) => {
      return item.value === category.value;
    });

    if (newArray.length === 0) {
      return false;
    }
    return true;
  }

  /**
  * @deprecated
  */
  handleChange = (selectedOption: any) => {
    const errors = { ...this.state.errors1 };
    errors.selectedCategories = "";
    this.setState({
      errors1: errors
    })
    const { selectedCategories } = this.state;
    if (this.searchCategory(selectedOption)) {
      const errors = { ...this.state.errors1 };
      errors.selectedCategories = "You have already selected this category";
      this.setState({
        errors1: errors,
      })
      return;
    }
    const newCategories = [...selectedCategories, selectedOption];

    this.setState({
      selectedCategories: newCategories,
    })
  };

  /**
   * @deprecated
   */
  validate1 = () => {
    const errors = {
      clubName: "",
      clubDescription: "",
      profilePhoto: "",
      coverPhoto: "",
      typeOfUser: "",
      selectedMembers: "",
      selectedCategories: "",
      email: "",
    };
    let flag = false;
    this.emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const {
      clubName,
      clubDescription,
      profilePhoto,
      coverPhoto,
      selectedMembers,
      selectedCategories,
      email,
      typeOfUser,
    } = this.state;
    if (clubName === "") {
      errors.clubName = "Please enter club name";
      flag = true;
    } else if (
      clubName.trim().length === 0 ||
      this.hasOnlySpecialCharater(clubName)
    ) {
      errors.clubName = "Please enter a valid club name";
      flag = true;
    }
    if (clubDescription === "") {
      errors.clubDescription = "Please enter club description";
      flag = true;
    } else if (
      clubDescription.trim().length === 0 ||
      this.hasOnlySpecialCharater(clubDescription)
    ) {
      errors.clubDescription = "Please enter a valid club description";
      flag = true;
    }
    if (profilePhoto === "") {
      errors.profilePhoto = "Please select profile photo and cover photo";
      flag = true;
    }
    if (coverPhoto === "") {
      errors.profilePhoto = "Please select profile photo and cover photo";
      flag = true;
    }
    if (selectedMembers.length === 0) {
      errors.selectedMembers = "Please select atleast one member";
      flag = true;
    }
    if (selectedCategories.length === 0) {
      errors.selectedCategories = "Please select atleast one category";
      flag = true;
    }
    if (typeOfUser === "") {
      errors.typeOfUser = "Please select type of user";
      flag = true;
    }
    if (email !== "" && !this.emailReg.test(email)) {
      errors.email = "Please enter valid email";
      flag = true;
    }
    this.setState({
      errors1: errors,
    });
    return flag;
  };

  /**
   * @deprecated
   */
  nextStep = () => {
    if (this.validate1()) return;
    else {
      this.setState({
        step: 2,
      });
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /**
   * @deprecated
   */
  validate2 = () => {
    const {
      organizationName,
      organizationPanId,
      panPicture,
      nameBankAccount,
      bankAccountNumber,
      bankIFSCCode,
      cancelledCheque,
    } = this.state;
    const errors = {
      organizationName: "",
      organizationPanId: "",
      panPicture: "",
      nameBankAccount: "",
      bankAccountNumber: "",
      bankIFSCCode: "",
      cancelledCheque: "",
    };
    let flag = false;
    if (organizationName === "") {
      (errors.organizationName = "Please enter your organization name"),
        (flag = true);
    } else if (
      this.hasOnlySpecialCharater(organizationName) ||
      organizationName.trim().length === 0
    ) {
      (errors.organizationName = "Please enter a valid organization name"),
        (flag = true);
    }
    if (organizationPanId === "") {
      (errors.organizationPanId = "Please enter your PAN ID"), (flag = true);
    } else if (
      !this.validatePAN(organizationPanId) ||
      organizationPanId.length < 10
    ) {
      errors.organizationPanId = "Please enter valid PAN ID";
      flag = true;
    }
    if (panPicture === "") {
      (errors.panPicture = "Please upload your PAN picture"), (flag = true);
    }
    if (nameBankAccount === "") {
      errors.nameBankAccount = "Please enter your name in bank account";
      flag = true;
    } else if (
      this.hasOnlySpecialCharater(nameBankAccount) ||
      nameBankAccount.trim().length === 0
    ) {
      errors.nameBankAccount = "Please enter valid name in bank account";
      flag = true;
    }
    if (bankAccountNumber === "") {
      errors.bankAccountNumber = "Please enter your bank account number";
      flag = true;
    } else if (
      this.hasOnlySpecialCharater(bankAccountNumber) ||
      bankAccountNumber.trim().length === 0
    ) {
      errors.bankAccountNumber = "Please enter valid bank account number";
      flag = true;
    } else if (!this.validationBankAccountNumber(bankAccountNumber)) {
      errors.bankAccountNumber = "Please enter valid bank account number";
      flag = true;
    }
    if (bankIFSCCode === "") {
      errors.bankIFSCCode = "Please enter your bank IFSC code";
      flag = true;
    } else if (
      this.hasOnlySpecialCharater(bankIFSCCode) ||
      bankIFSCCode.trim().length === 0 ||
      bankIFSCCode.trim().length !== 11
    ) {
      errors.bankIFSCCode = "Please enter valid bank IFSC code";
      flag = true;
    } else if (this.validationBankIFSCCode(bankIFSCCode)) {
      errors.bankIFSCCode = "Please enter valid bank IFSC code";
      flag = true;
    }
    if (cancelledCheque === "") {
      errors.cancelledCheque = "Please upload your cancelled cheque";
      flag = true;
    }
    this.setState({
      errors2: errors,
    });
    return flag;
  };

  createClub = ({
    name,
    description,
    email,
    organization_name,
    organization_pan_id,
    bank_account_name,
    bank_account_number,
    ifsc_code,
    pan_photo,
    cheque,
    image,
    cover,
    user_type,
    account_ids,
    clubs_sub_categories_attributes,
  }: {
    name: string,
    description: string,
    email: string,
    organization_name: string,
    organization_pan_id: string,
    bank_account_name: string,
    bank_account_number: string,
    ifsc_code: string,
    pan_photo: string,
    cheque: string,
    image: string,
    cover: string,
    user_type: 'Admin' | 'Owner',
    account_ids: string[],
    clubs_sub_categories_attributes: { sub_category_id: number }[],
  }) => {

    this.setState({ submitLoading: true });

    let payload = {
      "club": {
        name: name,
        description: description,
        email: email,
        organization_name: organization_name,
        organization_pan_id: organization_pan_id,
        bank_account_name: bank_account_name,
        bank_account_number: bank_account_number,
        ifsc_code: ifsc_code,
        "pan_photo": {
          data: pan_photo,
        },
        "cheque": {
          data: cheque,
        },
        "image": {
          data: image,
        },
        "cover": {
          data: cover,
        }
      },
      "member": {
        "user_type": user_type,
        "account_ids": account_ids
      },
      "clubs_sub_categories_attributes": clubs_sub_categories_attributes
    }


    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    console.log(
      "🚀 ~ file: CreateClubController.web.tsx ~ line 489 ~ CreateClubController ~ data",
      payload
    );
    const httpBody = payload;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.createClubApi,
    });

    this.createClubId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  };

  /**
   * @deprecated
   */
  removeCategory(category: string) {
    const newArray = this.state.selectedCategories.filter((item: any) => {
      return item !== category;
    });
    this.setState({
      selectedCategories: newArray,
      categoryName: "",
    });
  }


  /**
    * @deprecated
    */
  searchSelectedMember(member: any) {
    const newArray = this.state.selectedMembers.filter((item: any) => {
      return item.value === member.value;
    });

    if (newArray.length === 0) {
      return false;
    }
    return true;
  }

  /**
    * @deprecated
    */
  validationBankIFSCCode = (bankIFSCCode: string) => {
    const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return !regex.test(bankIFSCCode);
  };

  /**
  * @deprecated
  */
  validationBankAccountNumber = (bankAccountNumber: string) => {
    let regex = /^[0-9]{9,18}$/;
    return regex.test(bankAccountNumber);
  };

  /**
  * @deprecated
  */
  validatePAN(organizationPanId: string) {
    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (regex.test(organizationPanId)) {
      return true;
    }

    return false;
  }


  /**
    * @deprecated
    */
  addSelectedMember() {
    const { selectedMembers } = this.state;
    if (this.searchSelectedMember(this.state.memberId)) {
      const errors = { ...this.state.errors1 };
      errors.selectedMembers = "Member already selected";
      this.setState({
        errors1: errors,
      });
      return;
    }
    const errors = { ...this.state.errors1 };
    errors.selectedMembers = "";
    const newArray = [...selectedMembers];
    newArray.push(this.state.memberId);

    this.setState({
      selectedMembers: newArray,
      errors1: errors,
    });
    console.log(newArray);
  }

  // addCategory() {
  //   const { selectedCategories } = this.state;
  //   console.log(selectedCategories)
  //   // if (this.searchCategory(this.state.categoryId)) {
  //   //   const errors = { ...this.state.errors1 };
  //   //   errors.selectedCategories = "Category already selected";
  //   //   this.setState({
  //   //     errors1: errors,
  //   //   });
  //   //   return;
  //   // }
  //   // const errors = { ...this.state.errors1 };
  //   // errors.selectedCategories = "";
  //   // const newArray = [...selectedCategories];
  //   // newArray.push(this.state.categoryId);

  //   // this.setState({
  //   //   selectedCategories: newArray,
  //   //   errors1: errors,
  //   // });
  //   // console.log(newArray);
  // }

  /**
  * @deprecated
  */
  handleUserInputChange(value: any) {
    const errors = { ...this.state.errors1 };
    errors.typeOfUser = "";
    this.setState({ typeOfUser: value.value, errors1: errors });
  }

  /**
  * @deprecated
  */
  fileValidation(file: any) {
    let filePath = file.value;
    // Allowing file type
    var idxDot = filePath.lastIndexOf(".") + 1;
    var extFile = filePath.substr(idxDot, filePath.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      return true;
    } else {
      const errors = {
        ...this.state.errors1,
        profilePhoto: "Only jpg/jpeg and png files are allowed!",
      };
      console.log(errors);
      this.setState({
        errors1: errors,
      });
      return false;
    }
  }

  /**
  * @deprecated
  */
  fileValidationForStep2(file: any) {
    let filePath = file.value;
    // Allowing file type
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|)$/i;
    if (!allowedExtensions.exec(filePath)) {
      return false;
    }
    return true;
  }

  /**
  * @deprecated
  */
  bytesToMegaBytes = (bytes: any) => bytes / 1024 ** 2;

  /**
  * @deprecated
  */
  validateFileSize(
    inputId: string,
    MAX_FILE_SIZE: any,
    imageName: string
  ): boolean {
    const input = document.getElementById(inputId) as HTMLInputElement | null;
    if (input && input.files) {
      const file = input?.files[0];
      const fileSize = this.bytesToMegaBytes(file?.size);

      if (fileSize > MAX_FILE_SIZE) {
        const { errors1 } = this.state;
        const newErrors = {
          ...errors1,
          profilePhoto: `${imageName} size should be less than ${MAX_FILE_SIZE} MB`,
        };
        this.setState({
          errors1: newErrors,
        })
        return false;
      }

      return true;
    }
    else {
      return false;
    }
  }

  // Customizable Area End
}
