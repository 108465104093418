import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChangeCoverWeb from '../../blocks/catalogue/src/ChangeCover.web';
import { BlockComponent } from "../../framework/src/BlockComponent";
import { IBlock } from '../../framework/src/IBlock';
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';
import { Profile, Club, DesignCategory, Label, ClubMember, CollectionHome } from './api-types';
import DezinerButton from './Button.web';
import { apiCall } from './Common';
import DezinerHeader from './DezinerHeader.web';
import HeaderControls from './DezinerHeaderControls.web';
import DezinerTopNav from './DezinerTopNav.web';
import { HorizontalSpacer } from './Spacer.web';
import { infoIcon } from '../../blocks/Groups/src/assets';
import LabelListing, { LabelListingProps } from '../../blocks/catalogue/src/LabelListing.web';
import PeopleIcon from "@material-ui/icons/People";
import CreateLabelDialog from '../../blocks/landingpage/src/CreateLabelDialog.web';
import MembersDialog from './MembersDialog.web';
import DeleteMemberDialog from './DeleteMemberDialog.web';
import { appendJsonContentType, appendToken, defineMessageHandlers } from './utils';
import { toast } from "react-toastify";
import { DesignsDataByCategory } from './store/designs/designs.reducer';

const configJSON = require('./config.js');


interface Props {
  navigation: any;
  id: string;
  children: any,
}

interface S {
  profileData: Partial<Profile['attributes']>,
  profileLoading: boolean,
  clubData: Partial<Club['attributes']>,
  activeCategory: DesignCategory;
  loadingDesignData: boolean,
  currentLabel: number,

  showMemberPopup: boolean,
  arrLabels: Label[];

  clubMembers: ClubMember[];

  addLabelDialogOpen: boolean,
  newLabel: string,

  designsData: DesignsDataByCategory,


  deleteMemberDialogOpen: boolean;
  deleteMember: string;
  deleteType: string;
  deleteId: number;
  memberId: number;

  isLoggedIn: boolean,
}

interface SS {

}

const All_LABEL = {
  id: "0",
  type: 'labels',
  attributes: {
    id: 0,
    name: "All",
    club_id: 0,
    group_id: 0,
    created_at: "",
    label_type: "Club",
    updated_at: ""
  }
}

// it's important to lazy load calculate this, because the routing is done client side
let inAboutPage = () => window.location.pathname.includes('ClubAboutPage');
let inCreatePage = () => window.location.pathname.includes('ClubCreateCollection');

const needRedirection = () => inAboutPage() || inCreatePage();




class ClubLayoutController extends BlockComponent<Props, S, SS>{

  createCollectionMsgId: string;

  removeMemberMsgId: string;
  profileMessageId: string;
  fetchClubMsgId: string;
  fetchLabelsMessageId: string;
  addNewLabelMessageId: string;

  addAsAdminMsgId: string;

  fetchMembersMsgId: string;

  fetchCollectionsMsgId: string;
  fetchDesignsMsgId: string;
  fetchLikedDesignsMsgId: string;
  fetchPromotedDesignsMsgId: string;
  fetchRatedDesignsMsgId: string;
  fetchInspiredDesignsMsgId: string;
  fetchFreeDesignsMsgId: string;
  fetchPaidDesignsMsgId: string;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      profileData: {},
      profileLoading: false,
      clubData: {},
      currentLabel: 0,
      showMemberPopup: false,
      arrLabels: [],

      addLabelDialogOpen: false,
      newLabel: '',
      clubMembers: [],

      designsData: {
        collections: [],
        designs: [],
        liked: [],
        promoted: [],
        inspired: [],
        rated: [],
        free: [],
        paid: [],
      },
      activeCategory: 'collections',
      loadingDesignData: false,

      deleteMember: '',
      deleteMemberDialogOpen: false,
      deleteType: '',
      deleteId: 0,
      memberId: 0,

      isLoggedIn: false,
    }

  }

  async componentDidMount() {
    super.componentDidMount();

    const storedLabel = parseInt(localStorage.getItem("changeFilterLabel_club") || '');

    const initState: Pick<S, "currentLabel"> = {
      currentLabel: 0,
    }


    if (inAboutPage()) {
      initState.currentLabel = -1;
    }
    else if (storedLabel) {
      initState.currentLabel = storedLabel;
    }

    this.setState({ ...initState }, () => {

      this.fetchProfile();
      this.fetchClub();
      this.fetchLabels();
      this.fetchMembers();

      this.fetchDesignsByCategory('collections');
    });
  }


  updateDesignCategoryState = (category: DesignCategory, data: any[]) => {
    this.setState(oldState => ({
      designsData: { ...oldState.designsData, [category]: data },
      loadingDesignData: false,
    }))
  }

  onDesignFetchSuccess = (category: DesignCategory) => {
    return (responseJson: any) => {
      this.updateDesignCategoryState(category, responseJson.data);
    }
  }

  async receive(from: string, message: Message) {


    defineMessageHandlers({
      message,
      handlers: {

        [this.createCollectionMsgId]: {
          onSuccess: (responseJson) => {

            this.setState(oldState => ({
              designsData: {
                ...oldState.designsData,
                collections: (oldState?.designsData?.collections??[])?.concat(responseJson.data)
              }
            }));

            toast.success("Collection Created Successfully");
          }
        },




        [this.profileMessageId]: {
          onSuccess: (responseJson) => {
            this.setState({ profileData: responseJson.data.attributes, isLoggedIn: true });
          },
          onError: (errors) => { },
          afterAll: () => {
            this.setState({ profileLoading: false });
          }
        },
        [this.fetchClubMsgId]: {
          onSuccess: (responseJson) => {
            this.setState({ clubData: responseJson.data.attributes });
          },
        },
        [this.fetchMembersMsgId]: {
          onSuccess: (responseJson) => {
            this.setState({ clubMembers: responseJson.data });
          }
        },
        [this.addNewLabelMessageId]: {
          onSuccess: () => {
            this.fetchLabels();
          },
          afterAll: () => {
            this.setState({ addLabelDialogOpen: false });
          },
        },
        [this.removeMemberMsgId]: {
          onSuccess: () => {
            this.fetchMembers();
          }
        },
        [this.fetchLabelsMessageId]: {
          onSuccess: (responseJson) => {
            this.setState({ arrLabels: [All_LABEL, ...responseJson.data] });
            const storageLocalId = localStorage.getItem("changeFilterLabel_club");

            if (storageLocalId) {
              this.setState({ currentLabel: Number(storageLocalId) });
            }
          }
        },
        [this.addAsAdminMsgId]: {
          onSuccess: () => {
            this.fetchMembers();
          }
        },

        [this.fetchCollectionsMsgId]: {
          onSuccess: this.onDesignFetchSuccess('collections'),
        },
        [this.fetchDesignsMsgId]: {
          onSuccess: this.onDesignFetchSuccess('designs')
        },
        [this.fetchLikedDesignsMsgId]: {
          onSuccess: this.onDesignFetchSuccess('liked')
        },
        [this.fetchPromotedDesignsMsgId]: {
          onSuccess: this.onDesignFetchSuccess('promoted')
        },
        [this.fetchInspiredDesignsMsgId]: {
          onSuccess: this.onDesignFetchSuccess('inspired')
        },
        [this.fetchRatedDesignsMsgId]: {
          onSuccess: this.onDesignFetchSuccess('rated'),
        },
        [this.fetchFreeDesignsMsgId]: {
          onSuccess: this.onDesignFetchSuccess('free'),
        },
        [this.fetchPaidDesignsMsgId]: {
          onSuccess: this.onDesignFetchSuccess('paid'),
        },



      }
    });

  }


  fetchLabels = () => {
    const header = {
      "Content-Type": configJSON.JsonContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const apiUrl =
      configJSON.fetchClubsLabelsAPI +
      "?club_id=" +
      this.props.navigation.getParam("id", "");

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.GetMethod,
      url: apiUrl,
    });

    this.fetchLabelsMessageId = getLabelsData.messageId;
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
  }

  fetchDesignsByCategory = (category: DesignCategory) => {

    if (needRedirection() && category != 'collections')
      this.props.navigation.navigate("ClubHomePage", { id: this.props.navigation.getParam("id") });

    this.setState({ activeCategory: category, loadingDesignData: true });

    let { currentLabel } = this.state;
    let labelParam = currentLabel > 0 ? `?label_id=${currentLabel}` : '';

    switch (category) {
      case 'collections': {
        this.fetchCollectionsMsgId = this.fetchCollections(currentLabel > 0 ? currentLabel : undefined);
        break;
      }
      case 'designs': {
        this.fetchDesignsMsgId = this.fetchDesignsData({ endpoint: 'all_designs' + labelParam });
        break;
      }
      case 'liked': {
        this.fetchLikedDesignsMsgId = this.fetchDesignsData({ endpoint: 'like_designs' + labelParam });
        break;
      }
      case 'promoted': {
        this.fetchPromotedDesignsMsgId = this.fetchDesignsData({ endpoint: 'promoted_designs' + labelParam });
        break;
      }
      case 'rated': {
        this.fetchRatedDesignsMsgId = this.fetchDesignsData({ endpoint: 'raterd_designs' + labelParam });
        break;
      }
      case 'inspired': {
        this.fetchInspiredDesignsMsgId = this.fetchDesignsData({ endpoint: 'inspired_designs' + labelParam });
        break;
      }
      case 'free': {
        this.fetchFreeDesignsMsgId = this.fetchDesignsData({ endpoint: 'free_designs' + labelParam });
        break;
      }
      case 'paid': {
        this.fetchPaidDesignsMsgId = this.fetchDesignsData({ endpoint: 'paid_designs' + labelParam });
        break;
      }
    }

  }

  fetchCollections = (labelId?: number) => {

    let id = this.props.navigation.getParam("id", "");

    const header = {
      "Content-Type": configJSON.JsonContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {}

    let url = `${configJSON.fetchCollectionsAPI}?club_id=${id}${labelId ? `&label_id=${labelId}` : ''}`;

    const apiCallData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.GetMethod,
      url,
    });

    runEngine.sendMessage(apiCallData.id, apiCallData);
    return apiCallData.messageId;
  }

  fetchDesignsData = ({ endpoint }: { endpoint: string }) => {

    let id = this.props.navigation.getParam("id", "");

    let url = `${configJSON.fetchDesignsAPI}/${id}/${endpoint}`;

    const apiCallData = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url,
    });

    runEngine.sendMessage(apiCallData.id, apiCallData);
    return apiCallData.messageId;
  }


  fetchClub = () => {

    const apiUrl = configJSON.fetchClubAPI + "/" + this.props.navigation.getParam("id", "");

    const getClubData = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: apiUrl,
    });

    this.fetchClubMsgId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }


  fetchProfile = () => {

    this.setState({ profileLoading: true });

    const getProfileData = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: configJSON.fetchProfileAPI,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
  }

  createLabel = () => {

    if (this.state.newLabel === "") {
      alert("Please enter name of Label");
      return false;
    }

    const httpBody = {
      data: {
        attributes: {
          name: this.state.newLabel,
          club_id: this.props.navigation.getParam("id", ""),
          label_type: "Club",
        },
      },
    };

    const addNewLabel = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.addLabelAPI,
    });

    this.addNewLabelMessageId = addNewLabel.messageId;
    runEngine.sendMessage(addNewLabel.id, addNewLabel);
  }


  changeLabel = (labelId: number) => {

    localStorage.setItem('changeFilterLabel_club', String(labelId));

    if (inAboutPage())
      this.props.navigation.navigate("ClubHomePage", { id: this.props.navigation.getParam("id") });

    this.setState({ currentLabel: labelId }, () => {
      this.fetchDesignsByCategory(this.state.activeCategory);
    });
  }


  fetchMembers = () => {

    const getClubData = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJSON.GetMethod,
      url: configJSON.fetchClubMembersAPI.replace(
        ":id",
        this.props.navigation.getParam("id", 0)
      ),
    });

    this.fetchMembersMsgId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
  }


  addAsAdmin = () => {

    this.setState({ showMemberPopup: false });

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.memberId,
        },
      },
    };

    const addAsAdminReq = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.addAsAdminAPI.replace(
        ":id",
        this.props.navigation.getParam("id", 0)
      )
    });

    this.addAsAdminMsgId = addAsAdminReq.messageId;
    runEngine.sendMessage(addAsAdminReq.id, addAsAdminReq);
  }

  handleClickDeleteMember = () => {

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.deleteId,
        },
      },
    };

    const deleteMemberReq = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod:
        this.state.deleteType === "remove_member"
          ? configJSON.DeleteMethod
          : configJSON.PostMethod,
      url: `${configJSON.deleteMemberAPI}/${this.props.navigation.getParam("id", 0)}/${this.state.deleteType}`,
    });

    this.removeMemberMsgId = deleteMemberReq.messageId;
    runEngine.sendMessage(deleteMemberReq.id, deleteMemberReq);
    this.setState({ deleteMemberDialogOpen: false });
  }


  createCollection = ({ collectionName, isPrivate }: { collectionName: string, isPrivate: boolean }) => {
    if (!collectionName) {
      alert("Please enter name of collection");
      return;
    }


    const httpBody = {
      data: {
        attributes: {
          name: collectionName,
          label_id: this.state.currentLabel,
          privacy: isPrivate ? "Private" : "Public",
          club_id: this.props.navigation.getParam("id", ""),
          collection_type: "Club",
        },
      },
    };

    const addNewCollection = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJSON.PostMethod,
      url: configJSON.createCollectionAPI,
    });

    this.createCollectionMsgId = addNewCollection.messageId;
    runEngine.sendMessage(addNewCollection.id, addNewCollection);
  }


}



class ClubLayout extends ClubLayoutController {

  render() {

    const { profileData, profileLoading, clubData, activeCategory, arrLabels, currentLabel, isLoggedIn } = this.state;
    const { navigate } = this.props.navigation;
    const { classes } = this.props as any;


    const currentUserRole = localStorage.getItem('currentUserRole');

    let list: LabelListingProps['list'] = [];
    try {

      let labelsList: LabelListingProps['list'] = arrLabels.map((item) => ({
        id: Number(item.id),
        label: item.attributes.name,
        hide: false,
        // @ts-ignore
        active: item.id == currentLabel,
        onClick: (id: number) => { this.changeLabel(id); }
      }));


      let createGroupItem: LabelListingProps['list'][number] = {
        id: -1,
        label: '+',
        hide: currentUserRole === 'User',
        pin: 'start',
        onClick: (id) => {
          this.props.navigation.navigate("ClubCreateCollection", {
            id: this.props.navigation.getParam("id", "")
          });
        }
      }

      let handleOpenItem: LabelListingProps['list'][number] = {
        id: list.length + 1,
        label: '+',
        pin: 'end',
        hide: currentUserRole === 'User',
        onClick: () => this.setState({ addLabelDialogOpen: true })
      }

      list = [createGroupItem, ...labelsList, handleOpenItem];

    } catch (error) {
      console.error(error);
    }




    return (
      <div>


        <DezinerTopNav
          isLoggedIn={isLoggedIn}
          onSignInAsDesigner={()=>navigate("EmailAccountLoginBlock")}
          onSignInAsUser={()=>navigate("EmailAccountLoginBlock")}
          profileImage={profileData?.profile_thumbnail_url ?? ''}
          color={profileData?.color ?? ''}
          designerInfo={{
            logoutHandler: () => {
              window.localStorage.clear();
              navigate("EmailAccountLoginBlock");
            },
            promoteHandler: () => {
              //this.promoteProfile(clubData?.image_url, "Club");
            },
            currentClubName: clubData?.name ?? '',
            editProfileHandler: () => navigate('DesignerEditProfile'),
            groupClickHandler: () => navigate("DesignerGroupPageView"),
            clubClickHandler: () => navigate("DesignClubPageView"),
            onMySelfClickHandler: () => {
              if (localStorage.getItem("currentUserRole") == "Designer")
                navigate("DesignerHome");
              else
                navigate("UserHome");
            }
          }}
          onLogoClick={() => navigate("LandingPage")}
          onNotificationClick={() => navigate("NotificationsWeb")}
        />


        <DezinerHeader
          onClick={this.fetchDesignsByCategory as any}
          title={clubData?.name ?? ''}
          profileImageURL={clubData?.image_url ?? ''}
          coverImageURL={clubData?.cover_url ?? ''}
          profileStatistics={[
            { name: 'collections', value: clubData?.totel_collections ?? 0, isActive: activeCategory == 'collections' },
            { name: 'designs', value: clubData?.totel_designs ?? 0, isActive: activeCategory == 'designs' },
            { name: 'liked', value: clubData?.totel_like_designs ?? 0, isActive: activeCategory == 'liked' },
            { name: 'promoted', value: clubData?.totel_promoted_designs ?? 0, isActive: activeCategory == 'promoted' },
            { name: 'inspired', value: clubData?.totel_inspired_designs ?? 0, isActive: activeCategory == 'inspired' },
            { name: 'rated', value: clubData?.totel_rated_designs ?? 0, isActive: activeCategory == 'rated' },
            { name: 'free', value: clubData?.totel_free_designs ?? 0, isActive: activeCategory == 'free' },
            { name: 'paid', value: clubData?.totel_paid_designs ?? 0, isActive: activeCategory == 'paid' },
            { name: 'following', value: clubData?.following_count ?? 0, },
            { name: 'followers', value: clubData?.follower_count ?? 0, },
          ]}
          loading={profileLoading}
          variant="club"
        />

        <HeaderControls>
          <HeaderControls.SideSection flexStart>

            {
              currentUserRole?.toLowerCase() != "user" ? (
                <ChangeCoverWeb
                  changeCurrentBg={() => { this.fetchClub(); }}
                  id={this.props.navigation.getParam("id", 0)}
                  type="club"
                />
              )
                :
                null
            }

            <HorizontalSpacer />

            <DezinerButton
              variant="contained"
              onClick={() => {
                this.props.navigation.navigate("ClubAboutPage", {
                  id: this.props.navigation.getParam("id", ""),
                })
              }}
              startIcon={<img src={infoIcon} />}
              className={classes.headerFloatingBtn}
            >
              {'About'}
            </DezinerButton>


          </HeaderControls.SideSection>


          <HeaderControls.MiddleSection>
            <LabelListing list={list} />
          </HeaderControls.MiddleSection>


          <HeaderControls.SideSection flexEnd>
            <DezinerButton
              style={{ justifySelf: 'flex-end' }}
              variant="contained"
              className={classes.headerFloatingBtn}
              onClick={() => {
                this.setState({ showMemberPopup: true });
              }}
              startIcon={<PeopleIcon fontSize="large" />}
            >
              {'Members'}
            </DezinerButton>
          </HeaderControls.SideSection>

        </HeaderControls>


        <CreateLabelDialog
          open={this.state.addLabelDialogOpen}
          onClose={() => this.setState({ addLabelDialogOpen: false })}
          onCreate={() => this.createLabel()}
        />

        <DeleteMemberDialog
          open={this.state.deleteMemberDialogOpen}
          memberName={this.state.deleteMember}
          onAccept={this.handleClickDeleteMember}
          onClose={() => this.setState({ deleteMemberDialogOpen: false })}
        />

        <MembersDialog
          open={this.state.showMemberPopup}
          onClose={() => this.setState({ showMemberPopup: false })}
          profileImage={this.state.clubData?.image_url ?? ''}
          title={clubData?.name ?? ''}
          onAddMember={() =>
            this.props.navigation.navigate("AddClubMember", {
              id:
                Object.keys(this.state.clubData).length > 0
                  ? this.state.clubData.id
                  : 0,
            })
          }
          currentRole={this.state.clubMembers
            ?.find((member) =>
              member?.attributes?.account_id == Number(localStorage.getItem('cuid') || ''))
            ?.attributes?.user_type
            ?? ''
          }
          members={this.state.clubMembers}
          onDelete={({ deleteType, memberId, memberName }) => {
            this.setState({
              deleteMember: memberName,
              deleteType: deleteType,
              deleteId: memberId,
              deleteMemberDialogOpen: true
            });
          }}
          addAsAdmin={({ memberId }) => {
            this.setState({ memberId }, () => {
              this.addAsAdmin();
            });
          }}
        />



        {
          React.cloneElement(
            this.props?.children,
            {
              layoutProps: {
                designsData: this.state.designsData,
                activeCategory: this.state.activeCategory,
                arrLabels: this.state.arrLabels,
                changeLabel: this.changeLabel.bind(this),
                currentLabel: this.state.currentLabel,
                loadingDesignData: this.state.loadingDesignData,
                createCollection: this.createCollection.bind(this),
              } as ClubLayoutProps
            })
        }

      </div>
    );

  }
}

export type ClubLayoutProps = Pick<S, "designsData" | "activeCategory" | "arrLabels" | "currentLabel" | "loadingDesignData"> & {
  changeLabel: (labelId: number) => void,
  createCollection: (params: { collectionName: string, isPrivate: boolean }) => void,
}

const styles: any = {
  headerFloatingBtn: {
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
  },
};

export default withStyles(styles)(ClubLayout);


