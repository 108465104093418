import React, { useState } from 'react';
import MaterialButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  rounded: {
    borderRadius: '40px !important',
  },
  bold: {
    fontWeight: 600,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  }
})

const ModifiedButton = withStyles({
  root: {
    borderRadius: '8px',
    textTransform: 'none',
    padding: '8px 25px',
    '&.MuiButton-text': {
      padding: '4px'
    },
    '&.MuiButton-contained': {
      boxShadow: 'none'
    },
  },
})(MaterialButton);

type Props = React.ComponentProps<typeof ModifiedButton> & {
  rounded?: boolean,
  bold?: boolean,
  noWrap?: boolean,
  loading?: boolean,
}

export default function DezinerButton(props: Props) {

  const { rounded, bold, noWrap, loading, ...buttonProps } = props;

  const classes = useStyles();

  const [isHoverd, setIsHoverd] = useState(false);

  return (
    <ModifiedButton
      {...buttonProps}
      onMouseEnter={() => {
        setIsHoverd(true);
      }}
      onMouseLeave={() => {
        setIsHoverd(false);
      }}
      startIcon={props.startIcon ? typeof props?.startIcon == 'function' ? props.startIcon(isHoverd) : props.startIcon : null}
      children={<>
        {buttonProps?.children}
        {
          loading ?
            <CircularProgress size={20} style={{ margin: '0 5px' }} color='inherit' />
            :
            null
        }
      </>}
      className={clsx(props.className, {
        [classes.rounded]: rounded,
        [classes.bold]: bold,
        [classes.noWrap]: noWrap,
      })}
    />
  );
}
