import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from './Button.web';
import Tooltip from "@material-ui/core/Tooltip";
import Grid from '@material-ui/core/Grid';
import {
  Reply,
  Info,
} from "@material-ui/icons";
import { promote } from '../../blocks/landingpage/src/assets';
import DesignInfoDialog from '../../blocks/landingpage/src/InfoDialog';
import ShareDesignDialog from '../../blocks/landingpage/src/ShareDesignDialog';

import { useDesignShareLinks, useIsMyProfile, useSingleDesign } from './hooks';

const useDesignMetaButtonStyles = makeStyles({
  tooltipButton: {
    margin: '5px', width: '40px', height: '40px', minWidth: '40px', border: '1px solid #e0e0e0', borderRadius: '4px'
  }
});

export default function DesignMetaButtons({
  designId,
}: {
  designId: number,
}) {

  const classes = useDesignMetaButtonStyles();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const shareQuery = useDesignShareLinks({ designId });

  const designQuery = useSingleDesign({ designId });

  const designAttributes = designQuery?.data?.design?.data?.attributes;

  function onShare() { setShareDialogOpen(true); }
  function onInfo() { setInfoDialogOpen(true); }
  function onPromote() { }

  const shareLink = shareQuery?.data?.meta?.share_url ?? '';

  const isMyProfile = useIsMyProfile({ id: designQuery?.data?.design?.data?.attributes?.designer?.data?.attributes?.id ?? 0 });


  return (
    <>

      <DesignInfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        data={designAttributes}
        currentImageUrl={designAttributes?.image_url ?? ''}
      />


      <ShareDesignDialog
        open={shareDialogOpen && Boolean(shareLink)}
        designLink={shareQuery?.data?.data?.attributes?.attachment_url ?? ''}
        shareLink={shareLink}
        onClose={() => setShareDialogOpen(false)}
        onCopy={() => { }}
      />

      <Grid container>

        <Grid item xs={4} container justifyContent="flex-end" sm={12}>
          <Tooltip title="Share" arrow placement="left">
            <Button
              className={classes.tooltipButton}
              onClick={onShare}>
              <Reply style={{ transform: "scaleX(-1)" }} />
            </Button>
          </Tooltip>
        </Grid>

        <Grid item xs={4} container justifyContent="flex-end" sm={12}>
          <Tooltip title="Info" arrow placement="left">
            <Button
              className={classes.tooltipButton}
              onClick={onInfo}>
              <Info />
            </Button>
          </Tooltip>
        </Grid>

        {
          isMyProfile ?
            <Grid item xs={4} container justifyContent="flex-end" sm={12}>
              <Tooltip title="Promote" arrow placement="left">
                <Button
                  className={classes.tooltipButton}
                  onClick={onPromote}
                >
                  <img
                    src={promote}
                    alt="promote"
                    className="black-svg"
                    style={{
                      maxWidth: '20px'
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            :
            null
        }

      </Grid>
    </>
  );
}
