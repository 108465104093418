import React from "react";
// Customizable Area Start
import { dezinerKnotLogo } from './../../landingpage/src/assets';
import { withStyles } from "@material-ui/core/styles";
import FooterController, { Props } from "./FooterController.web";
import FacebookIcon from "@material-ui/icons/Facebook";
import PinterestIcon from "@material-ui/icons/Pinterest";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import "./style.css";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
// Customizable Area End
// import { getCountry } from "react-native-localize";


const FOOTER_LISTS = {
  DESIGNER: [
    { title: "Buy Pro", link: "" },
    { title: "Forum", link: "/CommunityForum" },
    { title: "Promote Design", link: "" },
    { title: "How it work", link: "" },
    { title: "Category", link: "" },
  ],
  USER: [
    { title: "Buy Pro", link: "" },
    { title: "Buy Design", link: "" },
    { title: "Search Designer", link: "" },
    { title: "Explore", link: "" },
    { title: "Category", link: "" },
  ],
  COMPANY: [
    { title: "About us", link: "" },
    { title: "Advertise with us", link: "" },
    { title: "Use design", link: "" },
    { title: "Contact us", link: "" },
    { title: "Press / Media", link: "" },
    { title: "Careers", link: "" },
  ],
  INFORMATION: [
    { title: "FAQ", link: "/FAQ" },
    { title: "Design blog", link: "" },
    { title: "Testimonials", link: "" },
    { title: "Terms & use", link: "/TermsOfService" },
    { title: "Privacy policy", link: "/PrivacyPolicy" },
    { title: "License agreement", link: "" },
    { title: "Help center", link: "" },
    { title: "Code of Conduct", link: "/CodeOfConduct" },
    { title: "Cookie Policy", link: "/CookiePolicy" },
    { title: "Copyright Policy", link: "/CopyrightPolicy" },
  ],
  SOCIAL: [
    { icon: <FacebookIcon />, link: "https://www.facebook.com/dezinersknot" },
    { icon: <PinterestIcon />, link: "https://in.pinterest.com/dezinersknot/" },
    { icon: <TwitterIcon />, link: "https://twitter.com/deziners_knot" },
    { icon: <InstagramIcon />, link: "https://www.instagram.com/dezinersknot/" },
  ],
}

function DezinerLink(props: React.HTMLProps<HTMLAnchorElement>) {

  if (props.href && !props.href?.startsWith('http'))
    return <Link to={props.href}>{props?.children}</Link>

  return (
    <a {...props} />
  );
}


class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;


    return (
      <>
        <footer className={classes.footer}>

          <Grid container spacing={2}>

            <Grid item sm lg xs={12}>
              <img
                src={dezinerKnotLogo}
                alt="Deziner Knot"
                className={classes.footerLogo} />

              <Typography variant="body1" className={classes.footerTitle}>
                {'DEZINER\'S KNOT'}
                <Typography variant="caption" className={classes.footerSubtitle}>
                  {'is the world'}
                </Typography>
              </Typography>
              <Typography variant="body1" className={classes.footerSubtitle}>
                {'leading design source'}
              </Typography>

            </Grid>
            <Grid item sm lg={2} xs={12}>
              <Typography className={classes.listTitle}>{'DESIGNER'}</Typography>
              <ul className={classes.listUL}>
                {FOOTER_LISTS.DESIGNER.map(({ title, link }) => (
                  <li key={title}><DezinerLink href={link || '#'}>{title}</DezinerLink></li>
                ))}
              </ul>

              <Typography className={classes.listTitle}>{'USER'}</Typography>
              <ul className={classes.listUL}>
                {FOOTER_LISTS.USER.map(({ title, link }) => (
                  <li key={title}><DezinerLink href={link || '#'}>{title}</DezinerLink></li>
                ))}
              </ul>
            </Grid>
            <Grid item sm lg={2} xs={12}>
              <Typography className={classes.listTitle}>{'COMPANY'}</Typography>
              <ul className={classes.listUL}>
                {FOOTER_LISTS.COMPANY.map(({ title, link }) => (
                  <li key={title}><DezinerLink href={link || '#'}>{title}</DezinerLink></li>
                ))}
              </ul>
            </Grid>
            <Grid item sm lg={2} xs={12}>
              <Typography className={classes.listTitle}>{'INFORMATION'}</Typography>
              <ul className={classes.listUL}>
                {FOOTER_LISTS.INFORMATION.map(({ title, link }) => (
                  <li key={title}><DezinerLink href={link || '#'} target="_blank">{title}</DezinerLink></li>
                ))}
              </ul>
            </Grid>
            <Grid item sm lg={2} xs={12}>
              <Typography className={classes.listTitle}>{'SOCIAL'}</Typography>
              <ul className={classes.socialListUL}>
                {FOOTER_LISTS.SOCIAL.map(({ icon, link }) => (
                  <li key={link}><DezinerLink href={link || '#'} target="_blank" >{icon}</DezinerLink></li>
                ))}
              </ul>
            </Grid>

          </Grid>



        </footer>

        <Grid container justifyContent="space-between" alignItems="center" className={classes.subFooter}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.grayColor}>
              &#169;
              {` ${new Date().getFullYear()} | DEZINER'S KNOT`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Typography variant="caption" className={classes.whiteColor}>{this.state.totalDesigns}</Typography>
            <Typography variant="caption" className={classes.grayColor}>{'Designs Knotted'}</Typography>
          </Grid>
        </Grid>
      </>
    );

  }
};

const useStyles: any = {
  footer: {
    boxShadow: '0 -8px 10px #f6f6f6',
    background: '#f8fcfd',
    borderTop: '2px solid #f2f6f7',
    padding: '60px 5vw 20px',
  },
  footerLogo: {
    maxWidth: '300px',
  },
  footerTitle: {
    fontSize: '1.6rem',
    color: 'black',
    fontWeight: 'bold',
  },
  footerSubtitle: {
    fontSize: '1.1rem',
    color: '#797a7c',
    padding: '0 5px',
  },
  listTitle: {
    color: '#2c2b30',
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "5px"
  },
  listUL: {
    listStyle: "none",
    padding: "0",
    marginBottom: '20px',
    '& li:hover': {
      color: 'black',
    },
    '& li': {
      color: '#838486',
      marginBottom: '10px',
      '& *': {
        textDecoration: 'none',
        color: 'inherit',
        fontSize: "1.1rem",
      },
    },
  },
  socialListUL: {
    listStyle: "none",
    display: "flex",
    padding: "0",
    '& li': {
      marginRight: '10px',
      '& *': {
        textDecoration: 'none',
        color: 'inherit',
      },
    },
  },
  subFooter: {
    background: 'black',
    padding: '20px 5vw',
  },
  whiteColor: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: '1.1rem',
    padding: '0 5px',
  },
  grayColor: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: '#515254',
  },
};

export default withStyles(useStyles)(Footer);
