import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  aboutClub: string;
  currentClub: number;
  newAbout: string;
  aboutEditable: boolean;
  skillsEditable: boolean;
  skillsList: any;
  listOfSkills:  any;
  popupStatus: boolean;
  newLabel: string;
  currentLabel: any;
  anchorEl: any;
  loading: boolean;
  showMemberPopup: boolean;
  memberId: number;
  deletePopup: boolean;
  deleteId: number;
  deleteType: string;
  profileData: any;
  clubData: any;
  arrLabels: any;
  clubMembers: any;
  currentMemberId: number;
  deleteMember: number;
  adminCounter: number;
  ownerCounter: number;
  currentUserRoleClub: string;
  shareDialogStatus: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClubAboutPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  clubApiMessageId: string;
  newAboutMessageId: string;
  newLabelMessageId: string;
  skillsMessageId: string;
  memberClubApi: string;
  addMemberMsgId: string;
  removeMessageId: string;
  profileMessageId: string;
  labelsMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.updateAbout = this.updateAbout.bind(this);
    this.addSkill = this.addSkill.bind(this);
    this.removeSkill = this.removeSkill.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.createLabel = this.createLabel.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.addAsAdmin = this.addAsAdmin.bind(this);
    this.handleClickDeleteOpen = this.handleClickDeleteOpen.bind(this);
    this.handleClickDeleteClose = this.handleClickDeleteClose.bind(this);
    this.handleClickDeleteMember = this.handleClickDeleteMember.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      aboutClub: "",
      currentClub: this.props.navigation.getParam("id", ""),
      newAbout: "",
      aboutEditable: false,
      skillsEditable: false,
      skillsList: [
      ],
      listOfSkills: [
      ],
      popupStatus: false,
      newLabel: '',
      currentLabel: 0,
      anchorEl: {},
      loading: false,
      showMemberPopup: false,
      memberId: 0,
      currentMemberId: 0,
      deletePopup: false,
      deleteMember: 0,
      deleteId: 0,
      deleteType: '',
      profileData: {},
      clubData: {},
      arrLabels: [{
        id: null,
        attributes: {
          name: 'All'
        }
      }],
      clubMembers: [],
      adminCounter: 0,
      ownerCounter: 0,
      currentUserRoleClub: '',
      shareDialogStatus: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getSkills();
    this.getClub();
    this.getProfile();
    this.getLabels();
    this.getClubMembers();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.clubApiMessageId != null &&
      this.clubApiMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const skillsListData:any = [];
        if (responseJson.data.attributes.skills.data.length > 0) {
          responseJson.data.attributes.skills.data.map((item:any) => {
            skillsListData.push(item.attributes);
          });
        }
        const date = responseJson.data.attributes.member_since.split('T')[0];
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        responseJson.data.attributes.member_since = [day, month, year].join('-');
        this.setState({
          clubData: responseJson.data.attributes,
          aboutClub: responseJson.data.attributes.about,
          newAbout: responseJson.data.attributes.about,
          skillsList: skillsListData,

        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.skillsMessageId != null &&
      this.skillsMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const lists: any = [];
        if (responseJson.data.length > 0) {
          responseJson.data.map((item: any) => {
            lists.push(item.attributes);
          });
        }
        this.setState({ listOfSkills: lists });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(responseJson.errors[0].message);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeMessageId != null &&
      this.removeMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success("Removed Successfully");
        this.setState({ loading: true });
        this.getClubMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error("Removed Unsuccessfully");
      }
    }

    // LABEL LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.labelsMessageId != null &&
      this.labelsMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelsData = this.state.arrLabels;
        this.setState({ arrLabels: [ ...arrLabelsData, ...responseJson.data] });
        // const storageLocalId = localStorage.getItem("changeFilterLabel");
        // if (storageLocalId) {
        //   this.setState({ currentLabel: storageLocalId });
        // }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newAboutMessageId != null &&
      this.newAboutMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ aboutClub: responseJson.data.attributes.about });
        this.setState({ newAbout: responseJson.data.attributes.about });
        toast.success("Updated Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(responseJson.errors[0].message);
      }
    }

    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          profileData: responseJson.data.attributes
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // ADD NEW LABEL API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newLabelMessageId != null &&
      this.newLabelMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelData = this.state.arrLabels;
        arrLabelData.push(responseJson.data);
        this.setState({ arrLabels: arrLabelData });
        this.setState({
          currentLabel: arrLabelData[arrLabelData.length - 1].id,
          newLabel: ""
        });
        this.handleClose();
        toast.success("Label Created Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.memberClubApi != null &&
      this.memberClubApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ clubMembers: responseJson.data });
        responseJson.data.map((member: any) => {
          if (member.attributes.role === 'Owner') {
            this.setState({ ownerCounter: this.state.ownerCounter + 1 });
          } else if (member.attributes.role === 'Admin') {
            this.setState({ adminCounter: this.state.adminCounter + 1 });
          } else {}
          if (member.attributes.account_id.toString() === localStorage.getItem('cuid')) {
            this.setState({ currentUserRoleClub: member.attributes.role });
          }
          if (Object.keys(responseJson.data).length > 0) {
            Object.keys(responseJson.data).map((key, index) => {
              this.setState({
                anchorEl: { ...this.state.anchorEl, [index]: null },
              });
            });
            this.setState({ loading: false });
          }
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addMemberMsgId != null &&
      this.addMemberMsgId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success("Added as Admin Successfully");
        this.setState({ loading: true });
        this.getClubMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error("Something went wrong!");
      }
    }

    // Customizable Area End
  }

  getClub(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.groupsList}/${this.state.currentClub}`,
    });

    this.clubApiMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  updateAbout() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const skillsLists: any = [];
    if (this.state.skillsList.length > 0) {
      this.state.skillsList.map((itemSkill: any) => {
        skillsLists.push({ skill_id: itemSkill.id });
      });
    }
    const httpBody = {
      data: {
        attributes: {
          description: this.state.aboutClub,
          clubs_skills_attributes: skillsLists
        },
      },
    };
    const addNewAbout = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethodPut,
      url: `${configJSON.groupsList}/${this.state.currentClub}/update_about`,
    });

    this.newAboutMessageId = addNewAbout.messageId;
    runEngine.sendMessage(addNewAbout.id, addNewAbout);
    return true;
  }

  addSkill(skills: any) {
    this.setState({ skillsList: skills });
  }

  removeSkill(id: number) {
    const skills = this.state.skillsList.filter((item: any) => item.id !== id);
    this.setState({ skillsList: skills });
  }

  handleOpen(): void {
    this.setState({ popupStatus: true });
  }

  handleClose(): void {
    this.setState({ popupStatus: false });
  }

  createLabel(): boolean {
    if (this.state.newLabel === "") {
      alert("Please enter name of Label");
      return false;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token")
    };
    const httpBody = {
      data: {
        attributes: {
          name: this.state.newLabel,
          group_id: this.props.navigation.getParam("id", ""),
          label_type: "Group"
        }
      }
    };
    // const httpBody = {};
    const addNewLabel = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.createClubLabelApi
    });

    this.newLabelMessageId = addNewLabel.messageId;
    runEngine.sendMessage(addNewLabel.id, addNewLabel);
    return true;
  }

  //   getDesigns(): boolean {
  //     this.setState({
  //       loading: true,
  //     });
  //     const header = {
  //       "Content-Type": configJSON.exampleApiContentType,
  //       token: localStorage.getItem("token"),
  //     };
  //     const httpBody = {};

  //     const getProfileData = apiCall({
  //       header: header,
  //       httpBody: httpBody,
  //       httpMethod: configJSON.validationApiMethodType,
  //       url: configJSON.designerDesigns,
  //     });

  //     this.designerDesignsId = getProfileData.messageId;
  //     runEngine.sendMessage(getProfileData.id, getProfileData);
  //     return true;
  //   }

  getClubMembers(): void {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.groupsList}/${this.props.navigation.getParam(
        "id",
        0
      )}/group_members`
    });

    this.memberClubApi = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
  }

  handleClick(event: any, index: number): void {
    let newEl = {};
    Object.keys(this.state.anchorEl).map((key) => {
      if (key === index.toString()) {
        this.state.anchorEl[key] = event.currentTarget;
      }
      newEl = {...newEl, [key]: this.state.anchorEl[key]};
    });
    this.setState({ anchorEl: newEl });
  };
  
  handleCloseMenu(index: number): void{
    let newEl = {};
    Object.keys(this.state.anchorEl).map((key) => {
      if (key === index.toString()) {
        this.state.anchorEl[key] = null;
      }
      newEl = {...newEl, [key]: this.state.anchorEl[key]};
    });
    this.setState({ anchorEl: newEl });
  };

  addAsAdmin(): void{
    this.setState({ loading: true, showMemberPopup: false });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.memberId
        }
      }
    };

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.groupsList}/${this.props.navigation.getParam("id", 0)}/add_as_admin`,
    });

    this.addMemberMsgId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
  }

  handleClickDeleteOpen(): void {
    this.setState({ deletePopup: true });
  };

  handleClickDeleteClose(): void {
    this.setState({ deletePopup: false });
  };

  handleClickDeleteMember(): void {
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: localStorage.getItem("token"),
    };

    const httpBody = {
        data: {
            attributes: {
                account_id: this.state.deleteId
            }
        }
    };

    const getClubData = apiCall({
        header: header,
        httpBody: httpBody,
        httpMethod: this.state.deleteType === 'remove_member' ? configJSON.deleteMemberRequestType : configJSON.exampleAPiMethod,
        url: `${configJSON.groupsList}/${this.props.navigation.getParam("id", 0)}/${this.state.deleteType}`,
    });

    this.removeMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    this.setState({ deletePopup: false });
  }

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getLabels(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token")
    };

    const httpBody = {};

    const apiUrl =
      configJSON.getGroupLabelsApi +
      "?group_id=" +
      this.props.navigation.getParam("id", "");

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl
    });

    this.labelsMessageId = getLabelsData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;
  }

  getSkills() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.skillsList,
    });

    this.skillsMessageId = getLabelsData.messageId;
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
  }

  // Customizable Area End
}
