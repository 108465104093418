import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Profile } from './api-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { VerticalSpacer } from './Spacer.web';

const useGroupListStyles = makeStyles({
  groupContainer: {
    padding: '10px',
    marginBottom: '10px',
  },
  groupItemTitle: {
    color: '#444444',
    fontWeight: 600,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  profileImg: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '10px'
  },
});

export default function GroupList({
  groups,
  title,
}: {
  groups: Profile['attributes']['groups']['data'] | Profile['attributes']['clubs']['data'],
  title: string,
}) {

  const classes = useGroupListStyles();

  return (
    <div className={classes.groupContainer}>
      <Typography variant="body1" className={classes.sectionTitle}>
        {title}
      </Typography>

      <VerticalSpacer />

      <Grid container spacing={2}>
        {
          groups?.map((group) => (
            <Grid key={group.attributes.name} item xs={6} container alignItems="center" wrap="nowrap">
              <img src={group.attributes.image_url} alt={group.attributes.name} className={classes.profileImg} />
              <Typography variant="body2" className={classes.groupItemTitle}>
                {group.attributes.name}
              </Typography>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
}

