import { APIResponse, SkillItem } from "../api-types";
import { skillsMessages } from "../messages-generator";
import { skillsKeys } from "../query-keys";
import { useDezinerQuery } from "../ReactQuery.web";

export function useSkills({ designerId }: { designerId: number }) {

  return useDezinerQuery<{ data: APIResponse<SkillItem>[] }>({
    message: skillsMessages.fetchSkills(),
    queryKey: skillsKeys.fetchSkills({ designerId })
  });
}
