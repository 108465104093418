import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import DesignerGroupPageViewController, {
  Props
} from "./DesignerGroupPageViewController.web";
import HeaderWeb from "./Header.web";
import "./styles.css";
import FooterWeb from "../../catalogue/src/Footer.web";
import GroupAndClubSearchHeader from "./SearchHeader.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import "./styles.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import DezinerTopNav from "../../../components/src/DezinerTopNav.web";
import DezinerHeader from "../../../components/src/DezinerHeader.web";
import HeaderControls from "../../../components/src/DezinerHeaderControls.web";
import LabelListing, { LabelListingProps } from "../../catalogue/src/LabelListing.web";

import { clubIcon, group, passwordIcon, editProfileIcon, accountSettingsIcon, socialProfilesIcon, logoutIcon, } from '../../landingpage/src/assets';


const PAGE_SIZE = 10;

class DesignerGroupPageView extends DesignerGroupPageViewController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;


    const {
      searchQuery,
      currentPage,
      arrClubs,
      loading,
      profileData,
    } = this.state;
    const { navigate } = this.props.navigation;
    // page number that starts from zero
    const page = currentPage - 1;



    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="body1" style={{
          fontSize: '2rem',
          color: '#4a4a4a',
          padding: '100px 0',
        }}>
          {'Something exciting coming up 🎉'}
        </Typography>
      </Grid>
    );



    return (
      <>


        {/* @ts-ignore */}
        {/*<HeaderWeb navigation={this.props.navigation} />*/}


        <Grid container>
          <Grid item sm={2}></Grid>
          <Grid item xs={12} sm>

            <div className={classes.container}>

              <GroupAndClubSearchHeader
                query={searchQuery}
                setQuery={(searchQuery) => this.setState({ searchQuery })}
                btnTitle={'Create Group'}
                onClick={() => {
                  this.props.navigation.navigate("CreateGroup", {
                    id: profileData.id,
                  })
                }}
              />

              <VerticalSpacer />
              <VerticalSpacer />

              {
                loading ?
                  <Grid container justifyContent="center">
                    <CircularProgress color="primary" />
                  </Grid>
                  :
                  arrClubs.length > 0 ?
                    arrClubs
                      ?.slice(
                        // only filter if search query is not filled
                        searchQuery ? 0 : page * PAGE_SIZE,
                        searchQuery ? arrClubs.length : (page * PAGE_SIZE) + PAGE_SIZE,
                      )
                      ?.filter((item: any) => {
                        if (searchQuery)
                          return item.attributes.name.toLowerCase().includes(searchQuery.toLowerCase());
                        else
                          return true;
                      })
                      .map((item: any) => (
                        <GroupAndClubHorizontalCard
                          key={item.id}
                          item={item}
                          onClick={(item) => {
                            this.manageClub(item.attributes.id)
                          }}
                          link={(item) => (`GroupHomePage/${item.id}`)}
                          buttonTitle={"Manage Group"}
                          tagName={'Admin'}
                        />
                      ))
                    :
                    <Typography className={classes.noData}>
                      {'No Groups'}
                    </Typography>
              }

              <Grid container justifyContent="flex-end">
                {
                  arrClubs.length > 0 ?
                    <Pagination
                      disabled={Boolean(searchQuery)}
                      color="primary"
                      count={Math.ceil(arrClubs.length / PAGE_SIZE)}
                      page={currentPage}
                      onChange={this.handleChangePagination}
                    />
                    :
                    null
                }
              </Grid>
            </div>

          </Grid>
          <Grid item sm={2}></Grid>
        </Grid>



        {/*<FooterWeb
          navigation={this.props.navigation}
        />*/}
      </>
    );
  }
}

const useStyles: any = {
  container: {
    padding: '40px 10px',
    maxWidth: '650px',
    margin: '0 auto',
  },
  noData: {
    fontSize: '1.4rem',
    textAlign: 'center',
    color: '#97989A'
  },
};

export default withStyles(useStyles)(DesignerGroupPageView);
