import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  newCollectionName: string;
  privateCollection: boolean;
  currentLabel: any;
  arrLabels: any;
  arrCollection: any;
  profileData: any;
  popupStatus: boolean;
  newLabel: string;
  loading: boolean;
  designerDesigns: any;
  designerScreen: string;
  selectedFilter: string;
  clubData: any;
  clubMembers: any;
  showMemberPopup: boolean;
  deletePopup: boolean;
  deleteMember: string;
  deleteType: string;
  deleteId: number;
  memberId: number;
  currentMemberId: any;
  adminCounter: number;
  ownerCounter: number;
  anchorEl: any;
  modalOpen: boolean;
  currentImage: string;
  designerId: any;
  promoteType: string;
  currentUserRoleClub: string;
  flag: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GroupHomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  labelsMessageId: string;
  collectionMessageId: string;
  profileMessageId: string;
  newCollectionMessageId: string;
  newLabelMessageId: string;
  clubMessageId: string;
  memberClubApi: string;
  addMemberMsgId: string;
  removeMessageId: string;
  pinMessageId: string;
  //   designerDesignsId: string;
  //   designerLikedDesignsId: string;
  //   designerPromotedDesignsId: string;
  //   designerInspiredDesignsId: string;
  //   designerRatedDesignsId: string;
  //   designerFreeDesignsId: string;
  //   designerPaidDesignsId: string;

  marks: any = [
    {
      value: 10000,
      label: "10,000",
    },
    {
      value: 100000,
      label: "1,00,000 impressions",
    },
  ];
  marksMonth: any = [
    {
      value: 1,
      label: "1 Month",
    },
    {
      value: 12,
      label: "12 Months",
    },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onLabelNameChange = this.onLabelNameChange.bind(this);
    this.createPost = this.createPost.bind(this);
    this.changeLabel = this.changeLabel.bind(this);
    this.goToCollectionView = this.goToCollectionView.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.onTrendingButtonClick = this.onTrendingButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.addAsAdmin = this.addAsAdmin.bind(this);
    this.handleClickDeleteOpen = this.handleClickDeleteOpen.bind(this);
    this.handleClickDeleteClose = this.handleClickDeleteClose.bind(this);
    this.handleClickDeleteMember = this.handleClickDeleteMember.bind(this);
    this.promoteProfile = this.promoteProfile.bind(this);
    this.pinCollection = this.pinCollection.bind(this);
    // this.setWhichScreen = this.setWhichScreen.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      newCollectionName: "",
      privateCollection: false,
      currentLabel: 0,
      arrLabels: [
        {
          id: 0,
          attributes: {
            name: "All",
          },
        },
      ],
      arrCollection: {},
      profileData: {},
      popupStatus: false,
      newLabel: "",
      loading: false,
      designerDesigns: [],
      designerScreen: "CollectionView",
      selectedFilter: "All",
      clubData: {},
      clubMembers: {},
      showMemberPopup: false,
      memberId: 0,
      currentMemberId: localStorage.getItem("cuid"),
      adminCounter: 0,
      ownerCounter: 0,
      deletePopup: false,
      deleteMember: "",
      deleteType: "",
      deleteId: 0,
      anchorEl: {},
      modalOpen: false,
      currentImage: "",
      designerId: 0,
      promoteType: "Club",
      currentUserRoleClub: '',
      flag: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const DesignPublish = window.sessionStorage.getItem("DesignPublish");
    if (DesignPublish === "true") {
      toast.success("Design Published Successfull");
      window.sessionStorage.removeItem("DesignPublish");
    }
    this.getLabels();
    this.getCollections(0);
    this.getClub();
    this.getProfile();
    this.getClubMembers();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // LABEL LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.labelsMessageId != null &&
      this.labelsMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelData = this.state.arrLabels;
        this.setState({ arrLabels: [...arrLabelData, ...responseJson.data] });
        const storageLocalId = localStorage.getItem("changeFilterLabel");
        if (storageLocalId) {
          this.setState({ currentLabel: storageLocalId });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    // ADD NEW LABEL API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newLabelMessageId != null &&
      this.newLabelMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        let arrLabelData = this.state.arrLabels;
        arrLabelData = [...arrLabelData, responseJson.data];
        this.setState({ arrLabels: arrLabelData });
        this.setState({ currentLabel: arrLabelData[0].id });
        this.handleClose();
        toast.success("Label Created Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // COLLECTION LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.collectionMessageId != null &&
      this.collectionMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          arrCollection: responseJson.data,
          loading: false,
          designerScreen: "CollectionView",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading: false });
    }

    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          profileData: responseJson.data.attributes,
          designerId: responseJson.data.id,
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // CLUB DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.clubMessageId != null &&
      this.clubMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ clubData: responseJson.data.attributes });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // ADD NEW COLLECTION API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newCollectionMessageId != null &&
      this.newCollectionMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        let arrCollectionData = [];
        arrCollectionData.push(responseJson.data);
        arrCollectionData = arrCollectionData.concat(this.state.arrCollection);
        this.setState({ arrCollection: arrCollectionData });
        toast.success("Collection Created Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.memberClubApi != null &&
      this.memberClubApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ clubMembers: responseJson.data });
        responseJson.data.map((member: any) => {
          if (member.attributes.role === "Owner") {
            this.setState({ ownerCounter: this.state.ownerCounter + 1 });
          } else if (member.attributes.role === "Admin") {
            this.setState({ adminCounter: this.state.adminCounter + 1 });
          } else {
          }
          if (member.attributes.account_id.toString() === localStorage.getItem('cuid')) {
            this.setState({ currentUserRoleClub: member.attributes.role });
          }
        });
        if (Object.keys(responseJson.data).length > 0) {
          Object.keys(responseJson.data).map((key, index) => {
            this.setState({
              anchorEl: { ...this.state.anchorEl, [index]: null },
            });
          });
          this.setState({ loading: false });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeMessageId != null &&
      this.removeMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success("Removed Successfully");
        this.setState({ loading: true });
        this.getClubMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error("Removed Unsuccessfully");
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addMemberMsgId != null &&
      this.addMemberMsgId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success("Added as Admin Successfully");
        this.getClubMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error("Something went wrong!");
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.pinMessageId != null &&
      this.pinMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const msg = this.state.flag ? "Pinned Successfully" : "Unpinned Successfully";
        toast.success(msg);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading:false });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getLabels(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const apiUrl =
      configJSON.getGroupLabelsApi +
      "?group_id=" +
      this.props.navigation.getParam("id", "");

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.labelsMessageId = getLabelsData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;
  }

  getCollections(labelId: number): boolean {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const clubId = this.props.navigation.getParam("id", "");

    const httpBody = {};
    let apiUrl = `${configJSON.getClubCollections}?group_id=${clubId}`;
    if (labelId > 0) {
      apiUrl = `${
        configJSON.getClubCollections
      }?group_id=${clubId}&label_id=${labelId}`;
    }
    const storageLocalId = localStorage.getItem("changeFilterLabel");
    if (storageLocalId && labelId < 0) {
      apiUrl = `${
        configJSON.getClubCollections
      }?group_id=${clubId}&label_id=${storageLocalId}`;
      this.setState({ currentLabel: storageLocalId });
    }
    const getCollectionData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.collectionMessageId = getCollectionData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getCollectionData.id, getCollectionData);
    return true;
  }

  createCollection(): boolean {
    if (this.state.newCollectionName === "") {
      alert("Please enter name of collection");
      return false;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          name: this.state.newCollectionName,
          label_id: this.state.currentLabel,
          privacy: this.state.privateCollection ? "Private" : "Public",
        },
      },
    };
    // const httpBody = {};
    const addNewCollection = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.addCollectionApi,
    });

    this.newCollectionMessageId = addNewCollection.messageId;
    runEngine.sendMessage(addNewCollection.id, addNewCollection);
    return true;
  }

  goToCollectionView(collectionID: any) {
    this.props.navigation.navigate("CollectionViewByDesigner", {
      collectionId: collectionID,
      labelId: this.state.currentLabel,
    });
  }

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getClub(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const apiUrl =
      configJSON.groupsList + "/" + this.props.navigation.getParam("id", "");

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.clubMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  handleOpen(): void {
    this.setState({ popupStatus: true });
  }

  handleClose(): void {
    this.setState({ popupStatus: false });
  }

  createLabel(): boolean {
    if (this.state.newLabel === "") {
      alert("Please enter name of Label");
      return false;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          name: this.state.newLabel,
          group_id: this.props.navigation.getParam("id", ""),
          label_type: "Group",
        },
      },
    };
    // const httpBody = {};
    const addNewLabel = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.createClubLabelApi,
    });

    this.newLabelMessageId = addNewLabel.messageId;
    runEngine.sendMessage(addNewLabel.id, addNewLabel);
    return true;
  }

  onLabelNameChange(e: any): void {
    this.setState({ newLabel: e.target.value });
  }

  createPost(collectionId: number): any {
    this.props.navigation.navigate("DesignerDesignUpload", {
      collectionId: collectionId,
      labelId: this.state.currentLabel,
    });
  }

  changeLabel(labelId: number): any {
    this.setState({ currentLabel: labelId });
    this.getCollections(labelId);
  }

  //   getDesigns(): boolean {
  //     this.setState({
  //       loading: true,
  //     });
  //     const header = {
  //       "Content-Type": configJSON.exampleApiContentType,
  //       token: localStorage.getItem("token"),
  //     };
  //     const httpBody = {};

  //     const getProfileData = apiCall({
  //       header: header,
  //       httpBody: httpBody,
  //       httpMethod: configJSON.validationApiMethodType,
  //       url: configJSON.designerDesigns,
  //     });

  //     this.designerDesignsId = getProfileData.messageId;
  //     runEngine.sendMessage(getProfileData.id, getProfileData);
  //     return true;
  //   }

  handleFilterInput(event: any): boolean {
    let value = event.target.options;
    this.setState({
      selectedFilter: value,
    });
    return true;
  }

  onTrendingButtonClick(item: any) {
    console.log(item);
  }

  onEditButtonClick(item: any) {
    console.log(item);
  }

  getClubMembers(): void {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.groupsList}/${this.props.navigation.getParam(
        "id",
        0
      )}/group_members`,
    });

    this.memberClubApi = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
  }

  handleClick(event: any, index: number): void {
    let newEl = {};
    Object.keys(this.state.anchorEl).map((key) => {
      if (key === index.toString()) {
        this.state.anchorEl[key] = event.currentTarget;
      }
      newEl = { ...newEl, [key]: this.state.anchorEl[key] };
    });
    this.setState({ anchorEl: newEl });
  }

  handleCloseMenu(index: number): void {
    let newEl = {};
    Object.keys(this.state.anchorEl).map((key) => {
      if (key === index.toString()) {
        this.state.anchorEl[key] = null;
      }
      newEl = { ...newEl, [key]: this.state.anchorEl[key] };
    });
    this.setState({ anchorEl: newEl });
  }

  addAsAdmin(): void {
    this.setState({ loading: true, showMemberPopup: false });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.memberId,
        },
      },
    };

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.groupsList}/${this.props.navigation.getParam(
        "id",
        0
      )}/add_as_admin`,
    });

    this.addMemberMsgId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
  }

  handleClickDeleteOpen(): void {
    this.setState({ deletePopup: true });
  }

  handleClickDeleteClose(): void {
    this.setState({ deletePopup: false });
  }

  handleClickDeleteMember(): void {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.deleteId,
        },
      },
    };

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod:
        this.state.deleteType === "remove_member"
          ? configJSON.deleteMemberRequestType
          : configJSON.exampleAPiMethod,
      url: `${configJSON.groupsList}/${this.props.navigation.getParam(
        "id",
        0
      )}/${this.state.deleteType}`,
    });

    this.removeMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    this.setState({ deletePopup: false });
  }

  valuetext(value: any) {
    return `${value}`;
  }
  valuetextMonth(value: any) {
    return `${value} Month`;
  }

  handleModalOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };
  promoteProfile(img: string, type: string) {
    this.setState({
      currentImage: img,
      modalOpen: true,
      promoteType: type,
    });
  }

  pinCollection(collectionId: number, flag: boolean): any {
    console.log('spec_props_checKed2', {collectionId,flag});
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {
      data: {
        attributes: {
          collection_id: collectionId
        }
      }
    };

    this.setState({
      flag: flag
    });
    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: flag ? configJSON.pinCollection : configJSON.unPinCollection,
    });

    this.pinMessageId = getLabelsData.messageId;
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;    
  }

  // Customizable Area End
}

//   getLikedDesigns(): boolean {
//     this.setState({
//       loading: true,
//     });
//     const header = {
//       "Content-Type": configJSON.exampleApiContentType,
//       token: localStorage.getItem("token"),
//     };
//     const httpBody = {};

//     const getProfileData = apiCall({
//       header: header,
//       httpBody: httpBody,
//       httpMethod: configJSON.validationApiMethodType,
//       url: configJSON.designerLikedDesigns,
//     });

//     this.designerLikedDesignsId = getProfileData.messageId;
//     runEngine.sendMessage(getProfileData.id, getProfileData);
//     return true;
//   }

//   getPromotedDesigns(): boolean {
//     this.setState({
//       loading: true,
//     });
//     const header = {
//       "Content-Type": configJSON.exampleApiContentType,
//       token: localStorage.getItem("token"),
//     };
//     const httpBody = {};

//     const getProfileData = apiCall({
//       header: header,
//       httpBody: httpBody,
//       httpMethod: configJSON.validationApiMethodType,
//       url: configJSON.designerPromotedDesigns,
//     });

//     this.designerPromotedDesignsId = getProfileData.messageId;
//     runEngine.sendMessage(getProfileData.id, getProfileData);
//     return true;
//   }

//   getInspiredDesigns(): boolean {
//     this.setState({
//       loading: true,
//     });
//     const header = {
//       "Content-Type": configJSON.exampleApiContentType,
//       token: localStorage.getItem("token"),
//     };
//     const httpBody = {};

//     const getProfileData = apiCall({
//       header: header,
//       httpBody: httpBody,
//       httpMethod: configJSON.validationApiMethodType,
//       url: configJSON.designerInspiredDesigns,
//     });

//     this.designerInspiredDesignsId = getProfileData.messageId;
//     runEngine.sendMessage(getProfileData.id, getProfileData);
//     return true;
//   }

//   getRatedDesigns(): boolean {
//     this.setState({
//       loading: true,
//     });
//     const header = {
//       "Content-Type": configJSON.exampleApiContentType,
//       token: localStorage.getItem("token"),
//     };
//     const httpBody = {};

//     const getProfileData = apiCall({
//       header: header,
//       httpBody: httpBody,
//       httpMethod: configJSON.validationApiMethodType,
//       url: configJSON.designerRatedDesigns,
//     });

//     this.designerRatedDesignsId = getProfileData.messageId;
//     runEngine.sendMessage(getProfileData.id, getProfileData);
//     return true;
//   }

//   getFreeDesigns(): boolean {
//     this.setState({
//       loading: true,
//     });
//     const header = {
//       "Content-Type": configJSON.exampleApiContentType,
//       token: localStorage.getItem("token"),
//     };
//     const httpBody = {};

//     const getProfileData = apiCall({
//       header: header,
//       httpBody: httpBody,
//       httpMethod: configJSON.validationApiMethodType,
//       url: configJSON.designerFreeDesigns,
//     });

//     this.designerFreeDesignsId = getProfileData.messageId;
//     runEngine.sendMessage(getProfileData.id, getProfileData);
//     return true;
//   }

//   getPaidDesigns(): boolean {
//     this.setState({
//       loading: true,
//     });
//     const header = {
//       "Content-Type": configJSON.exampleApiContentType,
//       token: localStorage.getItem("token"),
//     };
//     const httpBody = {};

//     const getProfileData = apiCall({
//       header: header,
//       httpBody: httpBody,
//       httpMethod: configJSON.validationApiMethodType,
//       url: configJSON.designerPaidDesigns,
//     });

//     this.designerPaidDesignsId = getProfileData.messageId;
//     runEngine.sendMessage(getProfileData.id, getProfileData);
//     return true;
//   }

// // GET DESIGNS API RESPONSE
// if (
//     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
//     this.designerDesignsId != null &&
//     this.designerDesignsId ===
//       message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
//   ) {
//     var responseJson = message.getData(
//       getName(MessageEnum.RestAPIResponceSuccessMessage)
//     );
//     if (responseJson && !responseJson.errors) {
//       this.setState({
//         loading: false,
//         designerDesigns: responseJson.data,
//         designerScreen: "designerhomedesign",
//       });
//     } else {
//       var errorReponse = message.getData(
//         getName(MessageEnum.RestAPIResponceErrorMessage)
//       );
//     }
//   }
//   // GET LIKED DESIGNS API RESPONSE
//   if (
//     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
//     this.designerLikedDesignsId != null &&
//     this.designerLikedDesignsId ===
//       message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
//   ) {
//     var responseJson = message.getData(
//       getName(MessageEnum.RestAPIResponceSuccessMessage)
//     );
//     if (responseJson && !responseJson.errors) {
//       this.setState({
//         loading: false,
//         designerDesigns: responseJson.data,
//         designerScreen: "designerhomelikeddesign",
//       });
//     } else {
//       var errorReponse = message.getData(
//         getName(MessageEnum.RestAPIResponceErrorMessage)
//       );
//     }
//   }
//   // GET DESIGNS API RESPONSE
//   if (
//     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
//     this.designerPromotedDesignsId != null &&
//     this.designerPromotedDesignsId ===
//       message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
//   ) {
//     var responseJson = message.getData(
//       getName(MessageEnum.RestAPIResponceSuccessMessage)
//     );
//     if (responseJson && !responseJson.errors) {
//       this.setState({
//         loading: false,
//         designerDesigns: responseJson.data,
//         designerScreen: "designerhomepromoteddesign",
//       });
//     } else {
//       var errorReponse = message.getData(
//         getName(MessageEnum.RestAPIResponceErrorMessage)
//       );
//     }
//   }
//   // GET DESIGNS API RESPONSE
//   if (
//     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
//     this.designerInspiredDesignsId != null &&
//     this.designerInspiredDesignsId ===
//       message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
//   ) {
//     var responseJson = message.getData(
//       getName(MessageEnum.RestAPIResponceSuccessMessage)
//     );
//     if (responseJson && !responseJson.errors) {
//       this.setState({
//         loading: false,
//         designerDesigns: responseJson.data,
//         designerScreen: "designerhomeinspireddesign",
//       });
//     } else {
//       var errorReponse = message.getData(
//         getName(MessageEnum.RestAPIResponceErrorMessage)
//       );
//     }
//   }
//   // GET DESIGNS API RESPONSE
//   if (
//     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
//     this.designerRatedDesignsId != null &&
//     this.designerRatedDesignsId ===
//       message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
//   ) {
//     var responseJson = message.getData(
//       getName(MessageEnum.RestAPIResponceSuccessMessage)
//     );
//     if (responseJson && !responseJson.errors) {
//       this.setState({
//         loading: false,
//         designerDesigns: responseJson.data,
//         designerScreen: "designerhomerateddesign",
//       });
//     } else {
//       var errorReponse = message.getData(
//         getName(MessageEnum.RestAPIResponceErrorMessage)
//       );
//     }
//   }
//   // GET DESIGNS API RESPONSE
//   if (
//     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
//     this.designerFreeDesignsId != null &&
//     this.designerFreeDesignsId ===
//       message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
//   ) {
//     var responseJson = message.getData(
//       getName(MessageEnum.RestAPIResponceSuccessMessage)
//     );
//     if (responseJson && !responseJson.errors) {
//       this.setState({
//         loading: false,
//         designerDesigns: responseJson.data,
//         designerScreen: "designerhomefreedesign",
//       });
//     } else {
//       var errorReponse = message.getData(
//         getName(MessageEnum.RestAPIResponceErrorMessage)
//       );
//     }
//   }
//   // GET DESIGNS API RESPONSE
//   if (
//     getName(MessageEnum.RestAPIResponceMessage) === message.id &&
//     this.designerPaidDesignsId != null &&
//     this.designerPaidDesignsId ===
//       message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
//   ) {
//     var responseJson = message.getData(
//       getName(MessageEnum.RestAPIResponceSuccessMessage)
//     );
//     if (responseJson && !responseJson.errors) {
//       this.setState({
//         loading: false,
//         designerDesigns: responseJson.data,
//         designerScreen: "designerhomepaiddesign",
//       });
//     } else {
//       var errorReponse = message.getData(
//         getName(MessageEnum.RestAPIResponceErrorMessage)
//       );
//     }
//   }
