// @ts-nocheck
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReplyIcon from '@material-ui/icons/Reply';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Button from "@material-ui/core/Button";
import Person from "@material-ui/icons/Person";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DialogContentText from "@material-ui/core/DialogContentText";
import Box from "@material-ui/core/Box";
import PeopleIcon from "@material-ui/icons/People";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import GroupIcon from "@material-ui/icons/Group";
import Divider from "@material-ui/core/Divider";
import { Close as CloseIcon } from "@material-ui/icons";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DesignerPromotePopUpWeb from "../../landingpage/src/DesignerPromotePopUp.web";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Chip from "@material-ui/core/Chip";
import GroupAboutPageController, { Props } from "./GroupAboutPageController.web";
import HeaderWeb from "./Header.web";
import "./styles.css";
import FooterWeb from "../../catalogue/src/Footer.web";
import ClubHeader from "./ClubHeader.web";
import TwitterIcon from '@material-ui/icons/Twitter';
import { toast } from "react-toastify";

class GroupAboutPage extends GroupAboutPageController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    let allowEdit = true;
    if (localStorage.getItem('currentUserRole') === 'User') {
      allowEdit = false;
    } else {
      const adminUser = this.state.clubMembers.filter((item) => {
        return ((item.attributes.account_id.toString() === localStorage.getItem('cuid')) && (item.attributes.role === 'Owner' || item.attributes.role === 'Admin'))
      });
      if (adminUser.length === 0) {
        allowEdit = false;
      }
    }
    return (
      <>
        <ClubHeader
          profileData={this.state.profileData}
          clubData={this.state.clubData}
          handleOpen={this.handleOpen}
          arrLabels={this.state.arrLabels}
          currentLabel=''
          navigation={this.props.navigation}
          changeLabel={this.changeLabel}
          openMemberPopup={() => this.setState({ showMemberPopup: true })}
          aboutPage={true}
        />
        <div className="main-container">
          <div className={classes.clubAbout}>
            <div />
            <div>
              <h3><b>About</b></h3>
              <div className={classes.mainSection}>
                <div>
                  <div
                    style={{
                      marginBottom: '20px',
                      border: '1px solid',
                      borderColor: this.state.aboutEditable ? 'lightgrey' : 'white',
                    }}
                    className={allowEdit ? classes.sectionContainer : ''}
                  >
                    <p className={classes.subTitle}>
                      <span>Bio</span>
                      <span className="edit-button"
                        style={{
                          display: this.state.aboutEditable ? 'block' : 'none'
                        }}
                        onClick={() => {
                          if (this.state.aboutEditable) {
                            this.updateAbout();
                          }
                          this.setState({ aboutEditable: !this.state.aboutEditable })
                        }}
                      >{this.state.aboutEditable ? 'Save Bio' : 'Edit Bio'}</span>
                    </p>
                    <div>
                      {
                        this.state.aboutEditable ?
                        (
                          <TextField
                            value={this.state.aboutClub}
                            onChange={(e) => {this.setState({ aboutClub: e.target.value })}}
                            multiline
                            minRows={6}
                            fullWidth
                            variant="outlined"
                          />
                          ) :
                          <p>{this.state.aboutClub}</p>
                        }
                    </div>
                  </div>
                  <div
                    className={allowEdit ? classes.sectionContainer : ''}
                    style={{
                      marginBottom: '15px',
                      border: '1px solid',
                      borderColor: this.state.skillsEditable ? 'lightgrey' : 'white'
                    }}
                  >
                    <p className={classes.subTitle}>
                      <span>Skills</span>
                      <span className="edit-button"
                        style={{
                          display: this.state.skillsEditable ? 'block' : 'none'
                        }}
                        onClick={() => {
                          if (this.state.skillsEditable) {
                            this.updateAbout();
                          }
                          this.setState({ skillsEditable: !this.state.skillsEditable })
                        }}
                      >{this.state.skillsEditable ? 'Save Skills' : 'Edit Skills'}</span>
                    </p>
                    <div>
                      {this.state.skillsEditable ? (
                        <div className={classes.listSelection}>
                          <Autocomplete
                            fullWidth
                            id="combo-box-demo"
                            options={this.state.listOfSkills}
                            getOptionLabel={(option) => option.name}
                            defaultValue={[...this.state.skillsList]}
                            filterSelectedOptions
                            multiple
                            onChange={
                              (event, value) => {
                                if (value) {
                                  this.addSkill(value);
                                }
                              }
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                      ) : (
                        <div className={classes.skillList}>
                          {this.state.skillsList.length > 0 ?
                          this.state.skillsList.map((item) => (
                            <span>
                              {item.name}
                            </span>
                          )) : null}
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.clubMembers.length > 0 && (
                    <div style={{ marginBottom: '15px' }} className={allowEdit ? classes.sectionContainer : ''}>
                      <p className={classes.subTitle}>Members</p>
                      <div className={classes.memberList}>
                        {this.state.clubMembers.map(item => (
                          <div
                            // onClick={() => this.props.navigation.navigate('DesignerProfileByUser', {
                            //   id: item.attributes.account_id
                            // }) }
                            style={{ display: 'flex', columnGap: '15px', alignItems: 'center' }}>
                            <img src={
                              item.attributes.profile_url === '' ?
                              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" :
                              item.attributes.profile_url
                            } />
                            <p>{item.attributes.first_name}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div style={{ display: 'flex', marginBottom: '15px', columnGap: '15px' }}>
                    <Button
                      variant="outlined"
                      onClick={() => this.setState({ shareDialogStatus: true })}
                    > <ReplyIcon /> Share</Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        toast.success("Link Copied");
                      }}
                    > <FileCopyIcon /> Copy</Button>
                  </div>
                  <div style={{
                    display: 'flex',
                    columnGap: '10px',
                    marginBottom: '15px',
                    backgroundColor: 'lightgrey',
                    color: 'black',
                    width: '100%',
                    padding: '5px 10px',
                    justifyContent: 'space-around',
                    borderRadius: '5px'
                  }}>
                    <p style={{ display: 'flex', alignItems: 'center' }}> <AddIcon style={{ marginRight: '8px' }} />
                      {this.state.clubData.follower_count} followers
                    </p>
                    <p style={{ display: 'flex', alignItems: 'center' }}> <CheckIcon style={{ marginRight: '8px' }} />
                      {this.state.clubData.following_count} following
                    </p>
                  </div>
                  <div style={{
                    marginBottom: '15px',
                    backgroundColor: 'lightgrey',
                    color: 'black',
                    width: '100%',
                    padding: '5px 10px',
                    borderRadius: '5px'
                  }}>
                    <p style={{ display: 'flex', alignItems: 'center' }}> <LocationOnIcon style={{ marginRight: '8px' }} /> {this.state.clubData.location} </p>
                    <p style={{ display: 'flex', alignItems: 'center' }}> <PersonIcon style={{ marginRight: '8px' }} /> Member since {this.state.clubData.member_since} </p>
                  </div>
                  <div
                    style={{
                      marginBottom: '15px',
                      backgroundColor: 'lightgrey',
                      color: 'black',
                      width: '100%',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      border: '1px solid lightgrey',
                    }}
                    className={allowEdit ? classes.rightBlock : ''}>
                    <p style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                      <span>Social</span>
                      <span className="edit-button" style={{ display: 'none' }}>Edit Social</span>
                    </p>
                    <p style={{ display: 'flex', alignItems: 'center' }}> <LaptopWindowsIcon style={{ marginRight: '8px' }} /> Microsoft </p>
                    <p style={{ display: 'flex', alignItems: 'center' }}> <FacebookIcon style={{ marginRight: '8px' }} /> Facebook </p>
                    <p style={{ display: 'flex', alignItems: 'center' }}> <InstagramIcon style={{ marginRight: '8px' }} /> Instagram </p>
                  </div>
                </div>
              </div>
              {/* <TextField
                id="about-club"
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                onChange={(e: any) => this.setState({ newAbout: e.target.value === "" ? null : e.target.value })}
                defaultValue={this.state.aboutClub}
              />
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                  color: this.state.aboutClub === this.state.newAbout ? "black" : "white",
                  backgroundColor: this.state.aboutClub === this.state.newAbout ? "lightgrey" : "black",
                }}
                onClick={this.updateAbout}
                disabled={this.state.aboutClub === this.state.newAbout}
                variant="contained"
              >
                Update
              </Button> */}

            </div>
            <div />
          </div>
        </div>
        <Dialog
            scroll="paper"
            open={this.state.showMemberPopup}
            onClose={() => this.setState({ showMemberPopup: false })}
            aria-labelledby="Cform-dialog-title"
          >
            <Box className="Cdialog_wrapper">
              <Box className="Cdialog_main_box">
                <Box className="Cdialog_box">
                  <Typography variant="h5">
                    {Object.keys(this.state.clubData).length > 0
                      ? this.state.clubData.name.substring(0, 3)
                      : ""}
                  </Typography>
                </Box>
                <DialogTitle id="Cform-dialog-title">
                  {this.state.clubData.name}
                  <CloseIcon
                    className="Cclose_icon"
                    onClick={() => this.setState({ showMemberPopup: false })}
                  />
                </DialogTitle>
              </Box>
              <DialogContent>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography className="Cmember_title">
                    <PeopleIcon style={{ marginRight: "10px" }} />
                    Members
                  </Typography>
                  {localStorage.getItem('currentUserRole') !== 'User' && (
                    <Typography
                      className="Cmember_title"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.props.navigation.navigate("AddGroupMember", {
                          id:
                            Object.keys(this.state.clubData).length > 0
                              ? this.state.clubData.id
                              : 0,
                        })
                      }
                    >
                      <AddIcon style={{ marginRight: "10px" }} />
                      Add Member
                    </Typography>
                  )}
                </div>
                <hr />
                <div style={{ overflowY: 'scroll', maxHeight:'450px', height: '50vh' }}>
                {this.state.clubMembers.length > 0
                    ? this.state.clubMembers.map((val: any, index: number) => (
                      <Box className="Cdialog_content_main" key={val.id}>
                        <div className="Cdialog_content_left">
                          <img
                            src={
                              val.attributes.profile_url === ""
                                ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                : val.attributes.profile_url
                            }
                            alt="image"
                          />
                          <div>
                            <Typography
                              id="Cmember_name"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {val.attributes.first_name}
                            </Typography>
                            <Typography id="Cmember_designation">
                              {val.attributes.role}
                            </Typography>
                          </div>
                        </div>
                        {/* <div className="Cdialog_content_right">
                        {this.user_tpye}
                      </div> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "10px",
                          }}
                        >
                          {val.attributes.role === "Admin" ? (
                            <button className="adminLabel">
                              <span>
                                <Person />
                              </span>
                              <span>Group Admin</span>
                            </button>
                          ) : null}
                          {val.attributes.role !== 'Owner' && 
                          localStorage.getItem('currentUserRole') !== 'User' && (
                              <>
                              {(
                                (
                                  this.state.currentUserRoleClub === 'Admin' ||
                                  this.state.currentUserRoleClub === 'Owner'
                                ) &&
                                (
                                  this.state.adminCounter > 1 ||
                                  this.state.ownerCounter > 0
                                )
                              ) && (
                                <>
                                  <IconButton
                                    aria-label="more"
                                    aria-controls={`long-menu-${index}`}
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                      this.handleClick(e, index)
                                    }}
                                  >
                                    <MoreHorizIcon fontSize="small" />
                                  </IconButton>
                                  <Menu
                                    id={`long-menu-${index}`}
                                    anchorEl={this.state.anchorEl[index]}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl[index])}
                                    onClose={() => this.handleCloseMenu(index)}
                                    PaperProps={{
                                      style: {
                                        maxHeight: 40 * 4.5,
                                        width: "20ch"
                                      }
                                    }}
                                  >
                                    {(val.attributes.role === 'Admin' &&
                                      (this.state.ownerCounter !== 0 ||
                                      this.state.adminCounter > 1)) && (
                                      <MenuItem
                                        key={`RemoveAsAdmin-${index}`}
                                        onClick={() => {
                                          this.setState({
                                            deleteMember:
                                              val.attributes.first_name,
                                            deleteType: "remove_as_admin",
                                            deleteId: val.attributes.account_id,
                                            deletePopup: true
                                          });
                                        }}
                                      >
                                        { localStorage.getItem('cuid') === val.attributes.account_id.toString() ? 'Leave as admin' : 'Remove as admin' }
                                      </MenuItem>
                                    )}
                                    {(val.attributes.role !== 'Admin' && val.attributes.role !== 'Owner') ?
                                      ( <MenuItem
                                          key={`AddAsAdmin-${index}`}
                                          onClick={() => {
                                            this.setState({ memberId: val.attributes.account_id }, () => {
                                              this.addAsAdmin();
                                            });
                                          }}
                                        >
                                          Add as admin
                                        </MenuItem>
                                      ): null
                                    }
                                    {val.attributes.role === 'Owner' ||
                                    (
                                      this.state.ownerCounter === 0 ||
                                      (val.attributes.role === 'Admin' &&
                                      this.state.adminCounter > 1)
                                    ) ?
                                    null :
                                    (
                                      <MenuItem
                                        key={`Remove-${index}`}
                                        onClick={() => {
                                          this.setState({
                                            deleteMember: val.attributes.first_name,
                                            deleteType: "remove_member",
                                            deleteId: val.attributes.account_id,
                                            deletePopup: true
                                          });
                                        }}
                                      >
                                        { localStorage.getItem('cuid') === val.attributes.account_id.toString() ? 'Exit' : 'Remove' }
                                      </MenuItem>
                                    )}
                                  </Menu>
                                </>
                              )}
                              {(
                                this.state.currentUserRoleClub === 'Pattern designer' &&
                                val.attributes.account_id.toString() === localStorage.getItem('cuid')
                              ) && (
                                <>
                                  <IconButton
                                    aria-label="more"
                                    aria-controls={`long-menu-${index}`}
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                      this.handleClick(e, index)
                                    }}
                                  >
                                    <MoreHorizIcon fontSize="small" />
                                  </IconButton>
                                  <Menu
                                    id={`long-menu-${index}`}
                                    anchorEl={this.state.anchorEl[index]}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl[index])}
                                    onClose={() => this.handleCloseMenu(index)}
                                    PaperProps={{
                                      style: {
                                        maxHeight: 40 * 4.5,
                                        width: "20ch"
                                      }
                                    }}
                                  >
                                    <MenuItem
                                      key={`Remove-${index}`}
                                      onClick={() => {
                                        this.setState({
                                          deleteMember: val.attributes.first_name,
                                          deleteType: "remove_member",
                                          deleteId: val.attributes.account_id,
                                          deletePopup: true
                                        });
                                      }}
                                    >
                                      { localStorage.getItem('cuid') === val.attributes.account_id.toString() ? 'Exit' : 'Remove' }
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                              </>
                            )}
                        </div>
                      </Box>
                    ))
                    : null}
                </div>
              </DialogContent>
            </Box>
        </Dialog>
        <Dialog
          open={this.state.deletePopup}
          onClose={this.handleClickDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Member</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure, you want to remove{" "}
              <b>{this.state.deleteMember}</b> from this group?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickDeleteClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDeleteMember}
              color="primary"
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
        <div
          className="promotePopup"
          style={{ display: this.state.shareDialogStatus ? "flex" : "none" }}
        >
          <span
            className="promotePopupCloseIcon"
            onClick={() => this.setState({ shareDialogStatus: false })}
          >
            <CloseIcon />
          </span>
          <div className="promoteContainer">
            <div className="promoteTitle imgtitle">
              <img src={
                this.state.clubData.image_url === '' ?
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' :
                this.state.clubData.image_url
              } />
            </div>
            <>
              <div className="promoteMainContainer">
                <p className="containerTitle">Share this with your social Community</p>
                <div className="">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <a
                      target="_blank"
                      href="#"
                      className="shareSocialIcons">D</a>
                    <a
                      target="_blank"
                      href={`https://twitter.com/share?url=${window.location.href}`}
                      className="shareSocialIcons">
                        <TwitterIcon />
                    </a>
                    <a
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                      className="shareSocialIcons"
                    >
                      <FacebookIcon />
                    </a>
                  </div>
                  <div style={{ textAlign: 'center', margin:'20px auto' }}>
                    Or Copy link
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <input
                      disabled
                      value={window.location.href}
                      style={{ padding: '10px', paddingRight: '100px', width: '200px' }}
                    />
                    <button
                      style={{
                        marginLeft: '-100px',
                        width: '100px',
                        padding: '10px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        toast.success("Link Copied");
                      }}
                    >Copy</button>
                  </div>
                </div>
              </div>
              </>
          </div>
        </div>
        <FooterWeb
          navigation={this.props.navigation}
        />
      </>
    );
  }
}

const useStyles = {
  clubAbout: {
    display: "grid",
    gridTemplateColumns: "0.2fr auto 0.2fr",
    padding: "10px 0px",
    marginBottom: "15px",
    columnGap: "15px",
  },
  mainSection: {
    display: 'grid',
    gridTemplateColumns: '0.6fr 0.3fr',
    justifyContent: 'space-between'
  },
  subTitle: {
    fontWeight: 'bold',
    color: 'darkgrey',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px'
  },
  skillList: {
    display: 'flex',
    columnGap: '10px',
    '& > span': {
      padding: '5px 10px',
      backgroundColor: 'lightgrey',
      color: 'black',
      borderRadius: '5px'
    }
  },
  listSelection: {
    display: 'flex',
    columnGap: '10px',
    flexWrap: 'wrap',
    '& > p': {
      padding: '5px 10px',
      backgroundColor: 'lightgrey',
      color: 'black',
      borderRadius: '5px'
    },
  },
  memberList: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '0.5fr 0.5fr',
    rowGap: '30px',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div > img': {
      height: '50px',
      width: '50px',
      borderRadius: '50%'
    }
  },
  sectionContainer: {
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'lightgrey !important',
    },
    '&:hover .edit-button': {
      display: 'block !important'
    }
  },
  rightBlock: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'white',
      borderColor: 'black'
    },
    '&:hover .edit-button': {
      display: 'block !important'
    }
  },
};

export default withStyles(useStyles)(GroupAboutPage);
