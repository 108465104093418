import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { DezinerLabel } from '../../../components/src/TextField.web';
import DezinerButton from '../../../components/src/Button.web';
import FormikTextInput from '../../../components/src/FormikTextField.web';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DezinerAlert from '../../../components/src/Alert.web';

function isValidBankNumber(value: any) {
  if (!value) return true;
  return /^[0-9]{9,18}$/.test(value);
}
function isValidIFSC(value: any) {
  if (!value) return true;
  return /^[A-Z]{4}0[A-Z0-9]{6}$/.test(value);
}



const initValues = {
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  bankIFSC: '',
  branch: '',
}

const validationSchema = yup.object().shape({
  bankName: yup.string().required('Please enter the name of bank'),
  bankAccountName: yup.string().required('Please enter the name of bank account'),
  bankAccountNumber: yup.string().required('Please enter Bank account number')
    .test('valid-bank-number', 'Please enter a valid bank number', isValidBankNumber),
  bankIFSC: yup.string().required('Please enter IFSC')
    .length(11, 'Please enter a valid IFSC')
    .test('valid-ifsc', 'Please enter a valid IFSC', isValidIFSC),
  branch: yup.string(),
});


export function UpdateBankAccountDialog({
  open,
  onClose,
  onSubmit,
  loading,
}: {
  open: boolean,
  onClose: () => void,
  onSubmit: (values: typeof initValues) => void,
  loading: boolean,
}) {

  const [showBranchInput, setShowBranchInput] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >

      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {
          formik => <Form translate="">
            <div style={{ padding: '40px', position: 'relative' }}>


              <IconButton
                style={{ position: 'absolute', top: '5px', right: '5px' }}
                onClick={onClose}>
                <CloseIcon />
              </IconButton>

              <DialogTitle>
                <DezinerLabel>
                  {'Bank Account Details'}
                </DezinerLabel>
              </DialogTitle>
              <DialogContent>

                <FormikTextInput
                  variant="outlined"
                  label="Bank Name*"
                  name="bankName"
                  fullWidth
                />

                <VerticalSpacer />

                <FormikTextInput
                  variant="outlined"
                  label="Account Number*"
                  name="bankAccountNumber"
                  fullWidth
                />

                <VerticalSpacer />

                <FormikTextInput
                  variant="outlined"
                  label="Account Name*"
                  name="bankAccountName"
                  fullWidth
                />

                <VerticalSpacer />

                <FormikTextInput
                  variant="outlined"
                  label="IFSC Code*"
                  name="bankIFSC"
                  fullWidth
                />

                <Grid container justifyContent="flex-start">
                  <DezinerButton
                    variant="text"
                    color="secondary"
                    onClick={() => {
                      setShowBranchInput(true);
                    }}>
                    {'Find IFSC code'}
                  </DezinerButton>
                </Grid>

                <VerticalSpacer />

                {
                  showBranchInput ?
                    <>
                      <FormControl variant="outlined" fullWidth>
                        {
                          formik.values.branch ? null :
                            <InputLabel id="select-branch">{'Select Branch'}</InputLabel>
                        }

                        <Select
                          //style={{ maxHeight: '40px' }}
                          fullWidth
                          labelId="select-branch"
                          value={formik.values.branch}
                          onChange={(event) => formik.setFieldValue('branch', event.target.value)}
                        >
                          <MenuItem value={'branchA'}>{'Branch A'}</MenuItem>
                          <MenuItem value={'branchB'}>{'Branch B'}</MenuItem>
                          <MenuItem value={'branchC'}>{'Branch C'}</MenuItem>
                        </Select>
                      </FormControl>
                      {
                        formik.errors.branch ?
                          <DezinerAlert>
                            {formik.errors.branch}
                          </DezinerAlert>
                          :
                          null
                      }
                    </>
                    :
                    null
                }

                <VerticalSpacer />

                <Grid container justifyContent="flex-end">
                  <DezinerButton loading={loading} type="submit" color="primary" variant="contained">
                    {'Save'}
                  </DezinerButton>
                </Grid>

              </DialogContent>
            </div>

          </Form>
        }
      </Formik>
    </Dialog>
  );
}
