import React from "react";
import { withStyles } from "@material-ui/core/styles";
//Customizable Area Start

import ForgotPasswordControllerWeb, {
  Props,
} from "./ForgotPasswordControllerWeb";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import RegisterationLayout from "../../email-account-registration/src/RegisterationLayout.web";
import Button from "../../../components/src/Button.web";
import Alert from "@material-ui/lab/Alert";
import CirclularProgress from '@material-ui/core/CircularProgress';
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormikTextInput from "../../../components/src/FormikTextField.web";
import DezinerAlert from "../../../components/src/Alert.web";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DezinerButton from "../../../components/src/Button.web";
//Customizable Area End

const emailValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const otpValidationSchema = yup.object().shape({
  otp: yup.string().length(4).required()
});

function hasNoSpaces(value?: string) {
  if (!value) return true;

  return /^\S$|^\S[\s\S]*\S$/.test(value);
}

const resetPasswordValidationSchema = yup.object().shape({
  password: yup.string()
    .min(8)
    .required()
    .test('no-white-space', 'White space are not allowed', hasNoSpaces),

  confirmPassword: yup.string()
    .min(8)
    .required('confirm password is a required field')
    .test('no-white-space', 'White space are not allowed', hasNoSpaces)
    .oneOf([yup.ref('password')], 'Password should match'),
});

function ChangePasswordMessageDialog({
  open,
  onClose,
  message,
}: {
  open: boolean,
  onClose: () => void,
  message: string,
}) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {'Password Change Details'}
      </DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <DezinerButton onClick={onClose}>
          {'Close'}
        </DezinerButton>
      </DialogActions>
    </Dialog>
  );
}


class ForgotPasswordWeb extends ForgotPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start

    //Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const modalStyle = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      height: 200,
      bgcolor: "background.paper",
    };

    return (
      <RegisterationLayout>
        {
          (!this.state.enterOtpScreen && !this.state.passwordScreen) ? (
            <Formik
              initialValues={{ email: '' }}
              onSubmit={this.forgotpasswordSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={emailValidationSchema}
            >
              {
                formik => <Form translate="">
                  <div style={{ maxWidth: '400px' }}>

                    <Grid container justifyContent="center" direction="column" alignItems="center">
                      <Typography gutterBottom variant="h1" className={classes.formTitle}>
                        {'FORGOT PASSWORD?'}
                      </Typography>
                      <Typography variant="body1" className={classes.formSubtitle}>
                        {'Enter the email address registered with us and we will send you instructions to reset your password'}
                      </Typography>

                      <VerticalSpacer />

                      <FormikTextInput
                        name="email"
                        variant="outlined"
                        label={'Email'}
                        fullWidth
                      />
                      {
                        this.state.errors.email ?
                          <Alert severity="error">
                            {this.state.errors.email}
                          </Alert>
                          :
                          null
                      }

                      <VerticalSpacer />

                      <Button
                        type="submit"
                        color="primary"
                        rounded
                        variant="contained"
                        disabled={this.state.loading}
                      >
                        {'Send Reset Instructions'}
                        {
                          this.state.loading ?
                            <CirclularProgress
                              size={20}
                              color="inherit"
                              style={{ margin: '0 10px' }}
                            />
                            :
                            null
                        }
                      </Button>

                      <VerticalSpacer />

                      <Grid container justifyContent="center" alignItems="center">
                        <Typography style={{ fontWeight: 'bold' }}>
                          {'Not a member?'}
                        </Typography>
                        <Button bold color="secondary" variant="text"
                          onClick={(e: any) => {
                            e.preventDefault();
                            this.goToSignUpPage();
                          }}
                        >
                          {'Sign Up Now'}
                        </Button>
                      </Grid>

                    </Grid>

                  </div>
                </Form>
              }
            </Formik>
          )
            :
            null
        }

        {
          (this.state.enterOtpScreen && !this.state.passwordScreen) ? (
            <Formik
              initialValues={{ otp: '' as any }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={this.onOtpVerification}
              validationSchema={otpValidationSchema}
            >
              {
                formik => <Form translate="">
                  <div>

                    <Grid container justifyContent="center" direction="column" alignItems="center">
                      <Typography gutterBottom variant="h1" className={classes.formTitle}>
                        {'VERIFY YOUR OTP'}
                      </Typography>
                      <Typography variant="body1" className={classes.formSubtitle}>
                        {`We sent a confirmation code to the following email: ${this.state.email}`}
                      </Typography>

                      <VerticalSpacer />


                      <Grid
                        style={{ maxWidth: '200px' }}
                        container justifyContent="center" direction="column" alignItems="center">


                        <FormikTextInput
                          type="number"
                          variant="outlined"
                          label="OTP"
                          name="otp"
                          fullWidth
                        />
                        {
                          this.state.errors.pin ?
                            <DezinerAlert>
                              {this.state.errors.pin}
                            </DezinerAlert>
                            :
                            null
                        }
                        {
                          this.state.errors.otp ?
                            <DezinerAlert>
                              {this.state.errors.otp}
                            </DezinerAlert>
                            :
                            null
                        }

                        <VerticalSpacer />

                        <Button
                          type="submit"
                          color="primary"
                          rounded
                          variant="contained"
                          disabled={this.state.loading}
                        >
                          {'Verify OTP'}
                          {
                            this.state.loading ?
                              <CirclularProgress
                                size={20}
                                color="inherit"
                                style={{ margin: '0 10px' }}
                              />
                              :
                              null
                          }
                        </Button>

                      </Grid>


                    </Grid>

                  </div>
                </Form>
              }
            </Formik>
          )
            :
            null
        }
        {
          (this.state.passwordScreen && this.state.enterOtpScreen) ? (
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={this.onPasswordReset}
              validationSchema={resetPasswordValidationSchema}
            >
              {
                formik => <Form translate="">
                  <div style={{ width: '300px', maxWidth: '300px' }}>

                    <Grid container justifyContent="center" direction="column" alignItems="center">
                      <Typography gutterBottom variant="h1" className={classes.formTitle}>
                        {'RESET YOUR PASSWORD'}
                      </Typography>
                      <Typography variant="body1" className={classes.formSubtitle}>
                        {`Please enter your new password.`}
                      </Typography>

                      <VerticalSpacer />


                      <Grid container justifyContent="center" direction="column" alignItems="center">

                        <FormikTextInput
                          fullWidth
                          variant="outlined"
                          label={'New Password'}
                          type="password"
                          name="password"
                        />

                        <VerticalSpacer />

                        <FormikTextInput
                          fullWidth
                          variant="outlined"
                          label="Confirm Password"
                          type="password"
                          name="confirmPassword"
                        />
                        {
                          this.state.errors.password ?
                            <Alert severity="error">
                              {this.state.errors.password}
                            </Alert>
                            :
                            null
                        }

                        <VerticalSpacer />

                        <Button
                          type="submit"
                          color="primary"
                          rounded
                          variant="contained"
                          disabled={this.state.loading}
                        >
                          {'Submit'}
                          {
                            this.state.loading ?
                              <CirclularProgress
                                size={20}
                                color="inherit"
                                style={{ margin: '0 10px' }}
                              />
                              :
                              null
                          }
                        </Button>

                      </Grid>


                    </Grid>

                  </div>
                </Form>
              }
            </Formik>
          )
            :
            null
        }


        <ChangePasswordMessageDialog
          open={this.state.changePasswordOpen}
          onClose={() => {
            this.setState({ changePasswordOpen: false });
            if (this.state.changePasswordIsSuccessful)
              this.props.navigation.navigate("EmailAccountLoginBlockWeb");
          }}
          message={"Password changed successfully"}
        />

      </RegisterationLayout>
    );
  }
}

// Customizable Area Start

const useStyles: any = {
  formTitle: {
    fontWeight: 900, fontSize: '1.3rem', textAlign: 'center',
  },
  formSubtitle: {
    fontWeight: 600, fontSize: '1rem', color: '#a9a9a9', textAlign: 'center',
  },
};

export default withStyles(useStyles)(ForgotPasswordWeb);
