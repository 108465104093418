import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { boolean } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  profileImg: string;
  designsData: any;
  filter: string;
  subFilter: string;
  subTitle: string;
  profileData: any;
  arrLabels: any;
  loading: boolean;
  arrSubFilters: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserProfileByOthersRatedController extends BlockComponent<
  Props,
  S,
  SS
> {
  profileApiMessageId: string;
  designDataMsgId: string;
  filterMessageApiId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.designData = this.designData.bind(this);
    this.handleSubFilterInput = this.handleSubFilterInput.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      profileImg: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      designsData: {},
      filter: 'Rated',
      subFilter: '',
      subTitle: '',
      profileData: {},
      arrLabels: [
        {
        	id: 7,
        	name: "Designs",
        },
        {
          id: 8,
          name: "About",
        },
      ],
      loading: true,
      arrSubFilters: {
        Rated: {
          all: 'All',
          Club: 'Club',
          Group: 'Group',
          Designer: 'Designer'
        }
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getProfile();
    this.designData();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileApiMessageId != null &&
      this.profileApiMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ profileData: responseJson.data });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designDataMsgId != null &&
      this.designDataMsgId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ designsData: responseJson.data },() => {
          this.setState({ loading: false });
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      }
    }

    // Customizable Area End
  }
  
  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const profileApiData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileApiMessageId = profileApiData.messageId;
    runEngine.sendMessage(profileApiData.id, profileApiData);
    return true;
  }

  designData(): void {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const url = `${configJSON.otherUserRatedDesigns}/${this.props.navigation.getParam("id" , 0)}/other_user_rated_designs`;
    const httpBody = {};
    const updateData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });

    this.designDataMsgId = updateData.messageId;
    runEngine.sendMessage(updateData.id, updateData);
  }

  handleSubFilterInput(e: any) {
    this.setState({ loading: true, subFilter: e.target.value });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const url = `${configJSON.otherUserRatedDesigns}/${this.props.navigation.getParam("id" , 0)}/other_user_rated_designs`;
    const httpBody = {};
    const updateData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${url}?filter=${e.target.value}`,
    });
    

    this.designDataMsgId = updateData.messageId;
    runEngine.sendMessage(updateData.id, updateData);
  }

  changeCategory(filterName: string) {
    this.setState({ loading: true, filter: filterName });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const url = `${configJSON.otherUserRatedDesigns}/${this.props.navigation.getParam("id" , 0)}/other_user_rated_designs`;
    const updateData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });

    this.designDataMsgId = updateData.messageId;
    runEngine.sendMessage(updateData.id, updateData);
  }

  // Customizable Area End
}
