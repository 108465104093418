import React, { useRef, useState } from "react";
import { useUploadDesignFormStyles } from './UploadDesignFormStyle.web';
import { encodeFile, statisticsFormatter } from '../../../components/src/utils';
import clsx from 'clsx';
import {
  privacyIcon,
  localUploadIcon,
  uploadToCloudIcon,
  headerIcon,
  settingsGearIcon,
  urlLinkIcon,
  tagIcon,
  imageExtensionIcon,
  videoExtensionIcon,
  descriptionIcon,
  zipFileExtensionIcon,
} from "./assets";
import {
  uploadDesignValidationSchema1,
  uploadDesignValidationSchema2,
} from './UploadDesignFormValidationSchema.web';
import MicIcon from '@material-ui/icons/Mic';
import MicNoneIcon from '@material-ui/icons/MicNone';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { toast, } from "react-toastify";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Dropzone from "react-dropzone";
import { Rnd } from "react-rnd";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, FieldArray } from 'formik';
import DezinerButton from "../../../components/src/Button.web";
import FormikTextInput from "../../../components/src/FormikTextField.web";
import AutocompleteSelectorInput, { AutocompleteSelectorInputVariant } from "../../../components/src/AutocompleteSelector.web";
import DezinerAlert from "../../../components/src/Alert.web";
import DezinerChip from "../../../components/src/Chip.web";
import PlusIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DezinerTextField, { DezinerLabel } from "../../../components/src/TextField.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import DezinerAudio from "../../../components/src/DezinerAudio.web";
import {
  TabPanel,
  a11yProps,
  Tab,
  Tabs,
  MainMediaPlaceholder,
  SubDesign,
  UploadSourceFilesPlaceholder,
  UploadedSourceFile,
} from './UploadDesignFormComponents.web';

const MAX_SOURCE_FILE_SIZE = 500 * 1024 * 1024; // 500MB
const MAX_IMAGE_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const MAX_VIDEO_FILE_SIZE = 20 * 1024 * 1024; // 20MB


const isImageBelowLimits = (file: any) => file.size < MAX_IMAGE_FILE_SIZE;
const isVideoBelowLimits = (file: any) => file.size < MAX_VIDEO_FILE_SIZE;
const isImageFile = (file: any) => file.type?.includes('image');
const isVideoFile = (file: any) => file.type?.includes('video');

const not = (fn: any) => (...args: any) => !fn(...args);


interface FormMedia { file?: File, name: string, file_size: number, data: string, design_type: string, }

interface InitValuesType {
  // stored as "percent"
  waterMarkWidth: number,
  // stored as "percent"
  waterMarkHeight: number,
  media: FormMedia[],
  watermarkId: number,
  title: string,
  tags: {
    "value": number,
    "label": string,
    "parentLabel": string
  }[],
  privacy: string,
  isThumbnail: boolean,
  description: string,
  descriptionAudio: string,
  price: number,
  sourceLink: string,
  sourceFiles: FormMedia[],
  timePeriod: string,
  priceConditions: { year: number, month: number, price: number }[],
}

const internalInitialValues: InitValuesType = {
  waterMarkWidth: 150,
  waterMarkHeight: 150,
  media: [],
  watermarkId: -1,
  title: '',
  tags: [],
  privacy: '',
  isThumbnail: false,
  description: '',
  descriptionAudio: '',
  price: 0,
  sourceFiles: [],
  sourceLink: '',
  timePeriod: 'Auto',
  priceConditions: [],
}

function clapOn100(value: number) {
  return Math.min(100, value);
}

/*
* UploadDesignForm
*
* Note: watermarkHeight, watermarkWidth are stored on the API in percent (e.g: 40, which means 40% of the media width) 
* so whenever you need to render the watermark you calculate the media offset width and multiply by this percent to know the watermark width/height
* and that's why on form initialization we convert these percents to pixel values, and on submit we convert pixels values to percent values
*
*/
export default function UploadDesignForm({
  initialFormValues,
  categories,
  watermarks,
  onSubmit,
  loading,
}: {
  initialFormValues?: Partial<InitValuesType>,
  categories: any[],
  watermarks: any[],
  onSubmit: (values: InitValuesType) => void,
  loading: boolean,
}) {

  const sourceFilesRef = useRef<any>();
  const mediaFilesRef = useRef<any>();
  const subDesignsRef = useRef<any>();

  /* main media file width on screen */
  const [mediaOffsetWidth, setMediaOffsetWidth] = useState(0);

  /* main media file height on screen */
  const [mediaOffsetHeight, setMediaOffsetHeight] = useState(0);

  const classes = useUploadDesignFormStyles();

  const [step, setStep] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);



  const listOfCategories = categories
    ?.map((item: any) => (
      item.options.map((option: any) => ({ ...option, parentLabel: item.label }))
    ))
    ?.flat();

  const inEditMode = Boolean(initialFormValues);

  const initValuesProps = inEditMode ? {
    ...initialFormValues,
    // calculate the watermark width/height in **pixel** based on a percentage of its offset width/height
    waterMarkWidth: mediaOffsetWidth && initialFormValues?.waterMarkWidth ? initialFormValues.waterMarkWidth * mediaOffsetWidth : 150,
    waterMarkHeight: mediaOffsetHeight && initialFormValues?.waterMarkHeight ? initialFormValues.waterMarkHeight * mediaOffsetHeight : 150,
  } : {}

  return (
    <Formik
      initialValues={Object.assign({}, internalInitialValues, initValuesProps) as InitValuesType}
      onSubmit={(values) => {
        if (step == 1)
          setStep(2);
        else
          onSubmit({
            ...values,
            // calculate watermark width/height in **percent**, we use current media offset width/height to calculate the percentage of watermark 
            // height/width and then we store the **percentage** in the API
            waterMarkWidth: clapOn100(mediaOffsetWidth ? values.waterMarkWidth / mediaOffsetWidth : values.waterMarkWidth),
            waterMarkHeight: clapOn100(mediaOffsetHeight ? values.waterMarkHeight / mediaOffsetHeight : values.waterMarkHeight),
          });
      }}
      validationSchema={step == 1 ? uploadDesignValidationSchema1 : uploadDesignValidationSchema2}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={inEditMode}
    >
      {
        formik => <Form translate="" style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
          flexGrow: 1,
        }}>


          <div className={classes.formContentContainer}>
            {
              step == 1 ?
                <Grid container>
                  <Grid item sm={8} xs={12} style={{ padding: '0 80px 0 0' }}>

                    <Dropzone
                      multiple
                      accept={{
                        'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif'],
                        'video/*': ['.mp4', '.mkv', '.mov', '.wmv', '.avi']
                      }}
                      maxFiles={5 - formik.values.media.length}
                      onDropRejected={(filesRejected) => {
                        let rejectedLength = filesRejected.length;
                        let extraFilesLength = Math.abs(5 - (formik.values.media.length + rejectedLength));
                        toast.error(`You can\'t upload more than 5 files, you uploaded ${extraFilesLength} more files `);
                      }}
                      onDrop={async (files: any[]) => {
                        try {
                          if (!files.length) return;

                          let imagesHasExceededLimits = files.filter(isImageFile).some(not(isImageBelowLimits));
                          let videosHasExceededLimits = files.filter(isVideoFile).some(not(isVideoBelowLimits));

                          if (imagesHasExceededLimits || videosHasExceededLimits)
                            formik.setErrors({ 'media': 'Can not have images over 10MB or videos over 20MB' })
                          else
                            formik.setErrors({ 'media': '' })

                          const filteredFiles =
                            files
                              .filter(file => {
                                if (isImageFile(file))
                                  return isImageBelowLimits(file);
                                else if (isVideoFile(file))
                                  return isVideoBelowLimits(file);
                              })

                          let filesBase64: string[] = await Promise.all(
                            filteredFiles.map(encodeFile)
                          );

                          let newFiles: FormMedia[] = filteredFiles.map((file, index) => ({
                            file,
                            data: filesBase64[index],
                            design_type: file?.type,
                            name: file?.name,
                            file_size: file?.size,
                          }));

                          formik.setFieldValue('media', [...newFiles, ...formik.values.media]);

                          // default select the first watermark
                          if (watermarks.length && formik.values.watermarkId < 0) {
                            formik.setFieldValue('watermarkId', watermarks?.[0]?.id);
                          }
                        } catch (error) {
                          console.error(error);
                        }
                      }}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          onClick={() => {
                            if (!formik.values.media[0] && mediaFilesRef && mediaFilesRef.current)
                              mediaFilesRef.current.click();
                          }}
                        >

                          <div className={classes.dropzoneArea}>

                            {
                              formik.values.media?.[0] ? null :
                                <MainMediaPlaceholder />
                            }

                            {
                              formik.values.watermarkId >= 0 && formik.values.media[0] ?
                                <div className={clsx(classes.watermarkOverlay, {
                                  [classes.watermarkOverlayForVideos]: formik.values.media?.[0]?.design_type?.startsWith('video') ?? false,
                                })}>
                                  <Rnd
                                    lockAspectRatio={1 / 1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      border: "4px solid white",
                                      position: "relative",
                                    }}
                                    maxHeight='100%'
                                    maxWidth='100%'
                                    position={{ x: '50%', y: '50%' } as any}
                                    default={{ width: '50%', height: '50%' } as any}
                                    size={{ height: formik.values.waterMarkHeight, width: formik.values.waterMarkWidth }}
                                    minWidth={150}
                                    minHeight={150}
                                    onResizeStop={(e: any, direction: any, ref: any, delta: any, position: any) => {

                                      let waterMarkWidth = ref.style.width?.replace('px', '');
                                      let waterMarkHeight = ref.style.height?.replace('px', '');

                                      formik.setFieldValue('waterMarkWidth', parseInt(waterMarkWidth));
                                      formik.setFieldValue('waterMarkHeight', parseInt(waterMarkHeight));

                                    }}
                                    disableDragging={true}
                                  >

                                    <div className={classes.rndBorder1} />
                                    <div className={classes.rndBorder2} />
                                    <div className={classes.rndBorder3} />
                                    <div className={classes.rndBorder4} />

                                    <img
                                      src={
                                        watermarks
                                          ?.find(item => item.attributes.id == formik.values.watermarkId)
                                          ?.attributes
                                          ?.watermark_url
                                        ?? ''
                                      }
                                      style={{
                                        width: '90%',
                                        height: '90%',
                                        opacity: '0.4',
                                      }}
                                    />
                                  </Rnd>
                                </div>
                                :
                                null
                            }

                            {
                              formik.values.media?.[0]?.data ?
                                formik.values.media?.[0]?.design_type?.startsWith('image') ?
                                  <img
                                    src={formik.values.media?.[0]?.data}
                                    className={classes.mainImg}
                                    alt="main image"
                                    onLoad={({ target }: any) => {
                                      setMediaOffsetWidth(target.offsetWidth);
                                      setMediaOffsetHeight(target.offsetHeight);
                                    }}
                                  />
                                  :
                                  formik.values.media?.[0]?.design_type?.startsWith('video') ?
                                    <video
                                      controls
                                      className={classes.video}
                                      onLoadedData={({ target }: any) => {
                                        setMediaOffsetWidth(target.offsetWidth);
                                        setMediaOffsetHeight(target.offsetHeight);
                                      }}
                                    >
                                      <source src={formik.values.media?.[0]?.data} type={'video/mp4'} />
                                    </video>
                                    :
                                    null
                                :
                                null
                            }
                          </div>



                          <input
                            type="file"
                            {...getInputProps()}
                            ref={mediaFilesRef}
                          />
                        </div>
                      )}
                    </Dropzone>

                    <Typography style={{ textAlign: 'right', color: '#ababab' }}>
                      <span style={{ color: 'black' }}>{'*'}</span>
                      {' Only upload media you own the rights to'}
                    </Typography>

                    <VerticalSpacer />

                    <div>
                      <DezinerLabel>
                        {'Add Subdesigns'}
                      </DezinerLabel>
                      <Grid container>
                        {
                          formik.values.media
                            ?.slice(1) // first media file is skipped
                            ?.map((item, index) => {

                              const isNewFile = Boolean(item.file);

                              const type = isNewFile ? item?.file?.type : item.design_type;
                              const name = isNewFile ? (item?.file?.name ?? '') : (item?.name ?? '');

                              const clickHandler = () => {
                                formik.setFieldValue('media', [item, ...formik.values.media.slice().filter((x, i) => i != index + 1)]);
                              }
                              const removeHandler = () => {
                                formik.setFieldValue('media', formik.values.media.slice().filter((x, i) => i != index + 1));
                              }

                              return (<SubDesign
                                key={`${name}_${index}_${type}`}
                                data={item?.data}
                                type={type || 'image'}
                                clickHandler={clickHandler}
                                removeHandler={removeHandler}
                              />);

                            })
                        }

                        {
                          formik.values.media.length < 5 ?
                            <Dropzone
                              multiple
                              accept={{
                                'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif'],
                                'video/*': ['.mp4', '.mkv', '.mov']
                              }}
                              maxFiles={5 - formik.values.media.length}
                              onDropRejected={(filesRejected) => {
                                let rejectedLength = filesRejected.length;
                                let extraFilesLength = Math.abs(5 - (formik.values.media.length + rejectedLength));
                                toast.error(`You can\'t upload more than 5 files, you uploaded ${extraFilesLength} more files `);
                              }}
                              onDrop={async (files: any[], fileRejected: any) => {
                                try {
                                  if (!files.length) return;


                                  let imagesHasExceededLimits = files.filter(isImageFile).some(not(isImageBelowLimits));
                                  let videosHasExceededLimits = files.filter(isVideoFile).some(not(isVideoBelowLimits));

                                  if (imagesHasExceededLimits || videosHasExceededLimits)
                                    formik.setErrors({ 'media': 'Can not have images over 10MB or videos over 20MB' })
                                  else
                                    formik.setErrors({ 'media': '' })

                                  const filteredFiles =
                                    files
                                      .filter(file => {
                                        if (isImageFile(file))
                                          return isImageBelowLimits(file);
                                        else if (isVideoFile(file))
                                          return isVideoBelowLimits(file);
                                      })


                                  let filesBase64 = await Promise.all(filteredFiles.map(encodeFile));

                                  let newFiles: FormMedia[] = filteredFiles.map((file, index) => ({
                                    file,
                                    data: filesBase64[index],
                                    design_type: file?.type,
                                    file_size: file?.size,
                                    name: file?.name,
                                  }));

                                  formik.setFieldValue('media', [...formik.values.media, ...newFiles]);
                                } catch (error) {
                                  console.error(error);
                                }
                              }}>
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                  onClick={() => {
                                    if (subDesignsRef && subDesignsRef.current)
                                      subDesignsRef.current.click();
                                  }}
                                >
                                  <div className={classes.subDesignAddContainer}>
                                    <PlusIcon fontSize="large" style={{ color: '#aaaaac' }} />
                                  </div>
                                  <input
                                    {...getInputProps()}
                                    ref={subDesignsRef}
                                    type="file"
                                  />
                                </div>
                              )}
                            </Dropzone>
                            :
                            null
                        }
                      </Grid>

                    </div>

                    {
                      formik.errors.media ?
                        <DezinerAlert>
                          {formik.errors.media}
                        </DezinerAlert>
                        :
                        null
                    }

                  </Grid>
                  <Grid item sm={4} xs={12} container direction="column">

                    <Grid container alignItems="center">
                      <img src={headerIcon} alt="" className={classes.smallIcon} style={{ maxWidth: '14px' }} />
                      <DezinerLabel>
                        {'Title'}
                      </DezinerLabel>
                    </Grid>
                    <FormikTextInput
                      name="title"
                      variant="outlined"
                    />

                    <VerticalSpacer />


                    <Grid container alignItems="center">
                      <img src={tagIcon} alt="" className={classes.smallIcon} />
                      <DezinerLabel>
                        {'Tags*'}
                      </DezinerLabel>
                    </Grid>
                    <VerticalSpacer value={4} />
                    <AutocompleteSelectorInputVariant
                      placeholder=""
                      autocompleteProps={{
                        groupBy: (option: any) => option?.parentLabel,
                      }}
                      inputValue={formik.values.tags}
                      onChange={(value) => formik.setFieldValue('tags', value)}
                      options={listOfCategories}
                    />

                    {
                      formik?.errors?.tags ?
                        <DezinerAlert>
                          {formik?.errors?.tags}
                        </DezinerAlert>
                        :
                        null
                    }

                    <VerticalSpacer />


                    <FormControl component="fieldset">
                      <Grid container alignItems="center">
                        <img src={privacyIcon} alt="" className={classes.smallIcon} />
                        <DezinerLabel>
                          {'Privacy*'}
                        </DezinerLabel>
                      </Grid>

                      <RadioGroup
                        aria-label="privacy"
                        color="primary"
                        name="privacy" value={formik.values.privacy} onChange={(event) => {
                          formik.setFieldValue('privacy', event.target.value);
                        }}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <FormControlLabel value="Public" control={<Radio color="primary" />} label="Public" />
                        <FormControlLabel value="Private" control={<Radio color="primary" />} label="Private" />
                      </RadioGroup>
                    </FormControl>

                    <VerticalSpacer />

                    {
                      formik.values.privacy?.toLowerCase() == 'private' ?
                        <>
                          <FormikTextInput
                            name="price"
                            label="₹ Price*"
                            variant="outlined"
                            type="number"
                          />
                          <VerticalSpacer />
                        </>
                        :
                        null
                    }


                    {
                      formik?.errors?.privacy ?
                        <DezinerAlert>
                          {formik?.errors?.privacy}
                        </DezinerAlert>
                        :
                        null
                    }


                    <DescriptionInput
                      value={formik.values.description}
                      onChange={(v) => formik.setFieldValue('description', v)}
                      audio={formik.values.descriptionAudio}
                      onAudioChange={(v) => formik.setFieldValue('descriptionAudio', v)}
                      error={formik.errors.description || ''}
                    />

                    <VerticalSpacer />

                    <Grid container alignItems="center">
                      <img src={settingsGearIcon} alt="" className={classes.smallIcon} />
                      <DezinerLabel>
                        {'Settings'}
                      </DezinerLabel>
                    </Grid>

                    <div style={{ paddingLeft: '20px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.isThumbnail}
                            onChange={(event) => { formik.setFieldValue('isThumbnail', event.target.checked) }}
                            name="isThumbnail"
                            color="primary"
                          />
                        }
                        label="Set this image as collection thumbnail"
                      />

                      <VerticalSpacer />

                      <div>
                        <DezinerLabel>
                          {'Choose watermark*'}
                        </DezinerLabel>
                        <Grid container wrap="nowrap">
                          {
                            watermarks?.map((item) => (
                              <img
                                key={item?.attributes?.id}
                                className={clsx(classes.watermarkImg, {
                                  [classes.watermarkImgSelected]: item?.attributes?.id == formik.values.watermarkId
                                })}
                                src={item?.attributes?.watermark_url}
                                onClick={() => {
                                  formik.setFieldValue('watermarkId', item?.attributes?.id);
                                }}
                              />
                            ))
                          }
                          <div
                            className={clsx(classes.watermarkImg, classes.noWatermark, {
                              [classes.watermarkImgSelected]: -1 == formik.values.watermarkId
                            })}
                            onClick={() => {
                              formik.setFieldValue('watermarkId', -1);
                            }}
                          >
                            {'No Watermark'}
                          </div>
                        </Grid>

                      </div>


                      {
                        formik?.errors?.watermarkId ?
                          <DezinerAlert>
                            {formik?.errors?.watermarkId}
                          </DezinerAlert>
                          :
                          null
                      }
                    </div>
                  </Grid>
                </Grid>
                :
                <Grid container justifyContent="center">

                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabIndex}
                    onChange={(e, tabIndex) => setTabIndex(tabIndex)}
                    aria-label="Vertical tabs example"
                  >
                    <Tab
                      label="Upload your source file here*"
                      style={{ color: formik.errors.sourceFiles || formik.errors.sourceLink ? 'red' : 'inherit', }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={`Price conditions ${formik.values.privacy?.toLowerCase() == 'public' ? '(Private Designs Only)' : ''}`}
                      {...a11yProps(1)}
                      style={{ color: formik.values.privacy?.toLowerCase() == 'private' && !formik.values.priceConditions.length ? 'red' : 'inherit', }}
                      disabled={formik.values.privacy == 'Public'}
                    />
                  </Tabs>
                  <TabPanel value={tabIndex} index={0}>


                    <Typography className={classes.titleText}>
                      {'Upload Source File'}
                    </Typography>

                    <VerticalSpacer />

                    <Grid container alignItems="center">
                      <img src={urlLinkIcon} className={clsx(classes.smallIcon, "gray-svg")} alt="link" />
                      <Typography className={classes.sectionTitleText}>
                        {'Upload from a URL'}
                      </Typography>
                    </Grid>

                    <FormikTextInput
                      autoHeight
                      name="sourceLink"
                      variant="outlined"
                      fullWidth
                    />

                    <VerticalSpacer />

                    <Grid container alignItems="center">
                      <img src={localUploadIcon} className={clsx(classes.smallIcon, "gray-svg")} alt="upload" />
                      <Typography className={classes.sectionTitleText}>
                        {'Upload from local'}
                      </Typography>
                    </Grid>

                    <Dropzone
                      multiple
                      accept={{
                        'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif'],
                        'video/*': ['.mp4', '.mkv', '.mov', '.wmv', '.avi'],
                        'application/*': ['.zip'],
                      }}
                      onDrop={async (files: any[]) => {

                        try {

                          const isBelowLimits = (file: any) => file.size < MAX_SOURCE_FILE_SIZE
                          const not = (fn: any) => (...args: any) => !fn(...args);
                          let hasExceededLimits = files.some(not(isBelowLimits));

                          if (hasExceededLimits)
                            formik.setErrors({ 'sourceFiles': 'Can not have files over 500 MB' })
                          else
                            formik.setErrors({ 'sourceFiles': '' })

                          let filesBase64 = await Promise.all(files.filter(isBelowLimits).map(encodeFile));
                          let newFiles: FormMedia[] = files.filter(isBelowLimits).map((file, index) => ({
                            file,
                            data: filesBase64[index],
                            file_size: file?.size,
                            name: file?.name,
                            design_type: file?.type,
                          }));

                          formik.setFieldValue('sourceFiles', [...newFiles, ...formik.values.sourceFiles.slice()]);
                        } catch (error) {
                          console.error(error);
                        }
                      }}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          onClick={(event) => {
                            event.preventDefault();
                            if (sourceFilesRef && sourceFilesRef.current)
                              sourceFilesRef.current.click();
                          }}
                        >

                          <UploadSourceFilesPlaceholder />

                          <input
                            id="uploadFile"
                            type="file"
                            {...getInputProps()}
                            ref={sourceFilesRef}
                          />
                        </div>
                      )}
                    </Dropzone>

                    {
                      formik.errors.sourceFiles ?
                        <DezinerAlert>
                          {formik.errors.sourceFiles}
                        </DezinerAlert>
                        :
                        null
                    }

                    <VerticalSpacer />

                    {
                      formik.values.sourceFiles?.length ?
                        <Typography className={classes.sectionTitleText}>
                          {'Uploaded files'}
                        </Typography>
                        :
                        null
                    }

                    {
                      formik.values.sourceFiles
                        ?.map((item, index: any) => {
                          const isNewFile = Boolean(item.file);

                          const type = isNewFile ? item?.file?.type : (item?.design_type ?? 'image');
                          console.log({ type });

                          const iconImage =
                            type == "application/zip" ?
                              zipFileExtensionIcon
                              :
                              type?.startsWith('video') ? videoExtensionIcon : imageExtensionIcon

                          const name = isNewFile ? item.file?.name : item.name;

                          const sizeInBytes = isNewFile ? (item?.file?.size ?? 1) : item.file_size;
                          const size = statisticsFormatter(sizeInBytes);

                          const removeHandler = () => {
                            formik.setFieldValue('sourceFiles', formik.values.sourceFiles.slice().filter((x, i) => i != index));
                          }

                          return (
                            <UploadedSourceFile
                              key={item?.name || item?.file?.name}
                              removeHandler={removeHandler}
                              name={name || ''}
                              size={size}
                              title={name || ''}
                              iconImage={iconImage}
                            />
                          );
                        })
                    }

                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>

                    <Typography className={classes.titleText}>
                      {'Price Conditions'}
                    </Typography>

                    <Typography className={classes.sectionTitleText}>
                      {`Lifetime price:       Rs. ${formik.values.price}`}
                    </Typography>

                    <VerticalSpacer />

                    <Typography className={classes.sectionTitleText2}>
                      {'Time Period Price'}
                    </Typography>

                    <VerticalSpacer />

                    <Grid container alignItems="center">
                      <Typography className={classes.sectionTitleText2}>
                        {'Choose Time Period'}
                      </Typography>
                      <RadioGroup
                        aria-label="choose time period"
                        color="primary"
                        name="timePeriod"
                        value={formik.values.timePeriod}
                        onChange={(event) => {
                          let timePeriod = event.target.value;
                          formik.setFieldValue('timePeriod', timePeriod);
                          if (formik.values.priceConditions?.length) {
                            formik.setFieldValue('priceConditions', []);
                          }
                        }}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px' }}
                      >
                        <FormControlLabel value="Auto" control={<Radio color="primary" />} label="Auto" />
                        <FormControlLabel value="Manual" control={<Radio color="primary" />} label="Manual" />
                      </RadioGroup>
                    </Grid>



                    <div>
                      {
                        formik.values.timePeriod ?
                          <div>
                            <FieldArray
                              name="priceConditions"
                              render={(arrayHelpers) => (
                                <div>

                                  <Grid container direction="column">
                                    {
                                      formik.values.priceConditions
                                        ?.map((_condition, index) => {

                                          const isAuto = formik.values.timePeriod?.toLowerCase() == 'auto';

                                          return (
                                            <Grid key={index} container style={{
                                              padding: '5px 0',
                                            }}>

                                              <Grid item xs container alignItems="flex-start" style={{
                                                background: isAuto ? 'white' : '#f1f1f1',
                                                padding: isAuto ? '0px' : '10px',
                                                borderRadius: '10px',
                                              }}>
                                                {
                                                  formik.values.timePeriod?.toLowerCase() == 'auto' ?
                                                    null
                                                    :
                                                    <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
                                                      <div>
                                                        <Grid container direction="column">
                                                          <FormikTextInput
                                                            hideErrors
                                                            type="number"
                                                            variant="outlined"
                                                            name={`priceConditions[${index}].year`}
                                                            className={classes.yearMonthInput}
                                                          />
                                                        </Grid>
                                                      </div>
                                                      <Typography className={classes.priceInputLabel}>
                                                        {'Year'}
                                                      </Typography>
                                                    </Grid>
                                                }

                                                <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
                                                  {
                                                    formik.values.timePeriod.toLowerCase() == 'auto' ?
                                                      <Select
                                                        variant="outlined"
                                                        style={{ maxHeight: '30px', background: 'white', marginRight: '10px', position: 'relative', top: '5px' }}
                                                        fullWidth
                                                        labelId="type of user"
                                                        value={formik.values.priceConditions[index].month}
                                                        defaultValue={3}
                                                        onChange={(event) => {
                                                          let newMonths = event.target.value;
                                                          formik.setFieldValue('priceConditions', formik.values.priceConditions.slice().map((_item, _index) => ({
                                                            ..._item,
                                                            month: _index == index ? newMonths : _item.month
                                                          })))
                                                        }}
                                                      >
                                                        {
                                                          [3, 6, 9, 12]
                                                            .filter(monthOption =>
                                                              formik.values.priceConditions.length && _condition.month != monthOption ?
                                                                !formik.values.priceConditions.map(x => x.month).includes(monthOption)
                                                                :
                                                                true
                                                            )
                                                            .map((months) => (
                                                              <MenuItem value={months} key={months}>{`${months} Months`}</MenuItem>
                                                            ))
                                                        }
                                                      </Select>
                                                      :
                                                      <>
                                                        <div>
                                                          <Grid container direction="column">
                                                            <FormikTextInput
                                                              hideErrors
                                                              type="number"
                                                              variant="outlined"
                                                              name={`priceConditions[${index}].month`}
                                                              className={classes.yearMonthInput}
                                                            />
                                                          </Grid>
                                                        </div>
                                                        <Typography className={classes.priceInputLabel}>
                                                          {'Month'}
                                                        </Typography>
                                                      </>
                                                  }
                                                </Grid>


                                                <Grid item xs={4}>
                                                  <FormikTextInput
                                                    style={{ background: 'white', maxWidth: '100px' }}
                                                    type="number"
                                                    variant="outlined"
                                                    hideErrors
                                                    name={`priceConditions[${index}].price`}
                                                    InputProps={{
                                                      startAdornment: <span>{'₹'}</span>
                                                    }}
                                                  />
                                                </Grid>

                                                <Grid item xs={12}>
                                                  {
                                                    Array.isArray(formik.errors?.priceConditions) &&
                                                    Object.values(formik.errors?.priceConditions?.[index] ?? {})
                                                      ?.map(error =>
                                                        <DezinerAlert>
                                                          {error}
                                                        </DezinerAlert>
                                                      )
                                                  }
                                                </Grid>
                                              </Grid>


                                              <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
                                                <IconButton
                                                  onClick={() => {
                                                    arrayHelpers.remove(index);
                                                  }}>
                                                  <CancelIcon />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          )
                                        })
                                    }
                                  </Grid>


                                  <DezinerButton
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                      if (formik.values.timePeriod?.toLowerCase() == 'auto') {
                                        if (formik.values.priceConditions.length < 4) {
                                          let nextMonthOption = [3, 6, 9, 12].filter(option => !formik.values.priceConditions.map(x => x.month).includes(option));
                                          arrayHelpers.push({ year: 0, month: nextMonthOption[0], price: 100 });
                                        }
                                      }
                                      else
                                        arrayHelpers.push({ year: 0, month: 3, price: 100 });
                                    }}>
                                    {'Add'}
                                  </DezinerButton>
                                </div>
                              )}
                            />

                          </div>
                          :
                          null
                      }
                    </div>

                    {
                      formik.values.privacy?.toLowerCase() == 'private' && !formik.values.priceConditions.length ?
                        <DezinerAlert>
                          {'Please add one price condition at least.'}
                        </DezinerAlert>
                        :
                        null
                    }
                  </TabPanel>

                </Grid>
            }
          </div>

          <VerticalSpacer />
          <VerticalSpacer />
          <VerticalSpacer />

          <div className={classes.footerContainer}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.footer}
            >
              {
                step == 2 ?
                  <DezinerButton disabled={loading} variant="outlined" color="primary"
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    {'Back'}
                  </DezinerButton>
                  :
                  <span />
              }

              <DezinerButton
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading ||
                  (
                    step == 2 ?
                      formik.values.privacy?.toLowerCase() == 'private' ? !Boolean(formik.values.priceConditions.length) : false
                      :
                      false
                  )
                }
              >
                {step == 1 ? 'Next' : 'Publish'}
                {
                  loading ?
                    <CircularProgress color="inherit" style={{ margin: '0 10px' }} size={20} />
                    :
                    null
                }
              </DezinerButton>
            </Grid>
          </div>

        </Form>
      }
    </Formik>
  );
}



/**
  * We need to isolate description because it contains recording logic, that can affect parent component render and thus 
  * cause forms values to be reset
  */
function DescriptionInput({
  value,
  onChange,
  audio,
  onAudioChange,
  error,
}: {
  value: string,
  onChange: (v: string) => void,
  audio: string,
  onAudioChange: (v: string) => void,
  error: string,
}) {

  const [recorder, setRecorder] = useState<any>(null);
  const [isRecording, setIsRecording] = useState(false);

  const streamRef = useRef<any>();

  async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    streamRef.current = stream;
    // @ts-ignore: Unreachable code error
    return new MediaRecorder(stream);
  }

  async function handleAudioData(event: any) {
    event.preventDefault();
    const result: any = await encodeFile(event.data)
    onAudioChange(result);
  }

  async function startRecording() {
    setIsRecording(true);
    if (!recorder) {
      let _recorder = await requestRecorder();
      setRecorder(_recorder);
      _recorder.start();
      _recorder.addEventListener("dataavailable", handleAudioData);
    } else {
      recorder.start();
    }
  };

  function stopRecording(): void {
    setIsRecording(false);
    recorder.stop();
    if (streamRef && streamRef.current) {
      if (streamRef.current.getTracks().length)
        streamRef.current.getTracks()?.[0]?.stop()
    }
    setRecorder(null);
  }



  function recordToggleHandler(event: any) {
    event.preventDefault();
    if (!isRecording)
      startRecording();
    else
      stopRecording();
  }


  const classes = useUploadDesignFormStyles();

  return (
    <>
      <Grid container alignItems="center">
        <img src={descriptionIcon} alt="" className={classes.smallIcon} />
        <DezinerLabel>
          {'Description'}
        </DezinerLabel>
      </Grid>
      <div style={{ position: 'relative' }}>
        <DezinerTextField
          placeholder="about your design"
          name="description"
          variant="outlined"
          multiline
          minRows={5}
          autoHeight
          fullWidth
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
        <div style={{ position: 'absolute', bottom: '15px', right: '15px' }}>
          <IconButton onClick={recordToggleHandler} size="small" className={classes.recordIconBtn}>
            {
              isRecording ?
                <>
                  <MicIcon className={classes.recordIcon} fontSize="small" />
                  <span className={classes.pulse}></span>
                </>
                :
                <MicNoneIcon fontSize="small" />
            }
          </IconButton>
        </div>
      </div>
      {
        error ?
          <DezinerAlert>
            {error}
          </DezinerAlert>
          :
          null
      }
      {
        audio ?
          <Grid container alignItems="center">
            <DezinerAudio
              src={audio}
            />
            <IconButton onClick={() => {
              onAudioChange('');
            }}>
              <CancelIcon />
            </IconButton>
          </Grid>
          :
          null
      }
    </>
  );


}

