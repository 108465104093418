import React from "react";
import { CollectionHome } from "../../../components/src/api-types";
import "./CollectionView.web.css";
import DesignCollectionGroupCard from "./DesignCollectionCard.web";


const DesignsCollectionsGroupsList = (props: {collection:CollectionHome[], onClick: (collectionId:number) => void}) => {


  return (
    <div className="design-collection-container">
      {
        props?.collection?.length ?
          props?.collection?.map((item: any) => (
            <DesignCollectionGroupCard
              key={item.id}
              {...item}
              onClick={() => props.onClick(item.attributes.id)}
            />
          ))
          :
          null
      }
    </div>
  );
}






export default DesignsCollectionsGroupsList;
