import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSingleDesign } from './hooks';
import DezinerMedia from './DezinerImage.web';
import clsx from 'clsx';


const useDesignSubDesignsStyles = makeStyles({
  card: {
    widht: "100px",
    minWidth: "100px",
    maxWidth: "100px",
    height: "75px",
    minHeight: "75px",
    maxHeight: "75px",
    objectFit: "cover",
    borderRadius: "20px",
    cursor: "pointer",
  },
  active: {
    border: '1px solid black',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '& img': {
      cursor: "pointer"
    },
    ['@media screen and (max-width: 600px)']: {
      flexDirection: 'row',
    }
  },
  horizontalContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default function DesignSubDesignsCards({
  onClick,
  designId,
  selectedDesignIndex,
  horizontal,
}: {
  onClick: (designIndex: number) => void,
  designId: number,
  selectedDesignIndex: number,
  horizontal?: boolean,
}) {

  const classes = useDesignSubDesignsStyles();
  const designQuery = useSingleDesign({ designId });

  const designAttributes = designQuery?.data?.design?.data?.attributes;

  const designImages = [{
    url: designAttributes?.image_url,
    type: designAttributes?.main_image_type,
  }]
    .concat(designAttributes?.sub_designs ?? [])
    .filter(({ url, type }) => Boolean(url && type));

  if (!designImages.length)
    return null;


  return (
    <div className={clsx(classes.container, {
      [classes.horizontalContainer]: horizontal
    })}>
      {
        designImages
          .map((media, index) => {

            const isImage = media?.type?.includes("image/") ?? true;

            return (
              <div
                key={media.url}
                style={{ margin: '10px', position: 'relative' }}
                onClick={(_e) => onClick(index)}
              >
                <DezinerMedia
                  mediaType={isImage ? 'image' : 'video'}
                  watermarkURL={designAttributes?.watermark_url}
                  watermarkWidth={parseFloat(designAttributes?.width ?? '100')}
                  watermarkHeight={parseFloat(designAttributes?.height ?? '100')}
                  src={media.url}
                  className={clsx(classes.card, {
                    [classes.active]: selectedDesignIndex == index
                  })}
                />
              </div>
            );
          })

      }
    </div>
  );
}
