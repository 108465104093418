import React from 'react';
import MaterialAutocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from '@material-ui/core/styles';

const DezinerAutocomplete = withStyles({
    root: {
        '& .MuiAutocomplete-tag': {
            background: '#e8e8e8',
            borderRadius: '6px',
            position: 'relative',
            '& .MuiChip-deleteIcon': {
                position: "absolute",
                top: "-8px",
                right: "-14px",
                color: "black"
            }
        },
        '& .MuiAutocomplete-endAdornment':{
            display: 'flex',
            flexWrap: 'nowrap'
        }
    }
})(MaterialAutocomplete);

export default DezinerAutocomplete;