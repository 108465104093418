
exports.DeleteMethod = 'DELETE';
exports.GetMethod = 'GET';
exports.PostMethod = 'POST';
exports.PutMethod = 'PUT';

exports.JsonContentType = 'application/json';

exports.fetchProfileAPI = 'account_block/accounts/profile';

exports.fetchClubsLabelsAPI = "bx_block_categories/labels";

exports.fetchCollectionsAPI = "account_block/collections";

exports.fetchDesignsAPI = "bx_block_groups/clubs";

exports.fetchClubAPI = "bx_block_groups/clubs";

exports.addLabelAPI = "bx_block_categories/labels";

exports.fetchClubMembersAPI = "bx_block_groups/clubs/:id/club_members";

exports.addAsAdminAPI = "bx_block_groups/clubs/:id/add_as_admin";

exports.deleteMemberAPI = 'bx_block_groups/clubs';

exports.fetchLabelsAPI = "bx_block_categories/labels";
exports.fetchCollectionsAPI = "account_block/collections";
exports.fetchDesignsBaseAPI = "account_block/accounts";
exports.fetchDesignerDesignsAPI = "bx_block_attachment/designs";

exports.addNewLabelAPI = "bx_block_categories/labels";

exports.createCollectionAPI = 'account_block/collections';

exports.fetchDesignerProfileByUserAPI = "account_block/accounts/:id/designer_profile_by_users";
exports.fetchDesignerLabelsByUserAPI = "account_block/accounts/:id/designer_labels_by_users";
exports.fetchDesignerCollectionsByUserAPI = "account_block/accounts/:id/designer_collections_by_users";
exports.fetchDesignerDesignsByUserBaseAPI = "account_block/accounts/:id";



exports.pinCollectionAPI = "bx_block_cfpindesigns2/pins";
exports.unpinCollectionAPI = "bx_block_cfpindesigns2/pins/unpin";



exports.userDesignsBaseAPI = "account_block/accounts";



exports.changeCoverImageAPI = "account_block/accounts/change_cover";

exports.updateProfileAPI = "account_block/accounts/update_profile";


exports.uploadProfilePictureAPI = "account_block/accounts/upload_profile";

exports.closeAccountAPI = "account_block/accounts/close_account";
exports.updateAccountSettingsAPI = "account_block/accounts/account_settings";

exports.changePasswordAPI = "account_block/accounts/change_password";

exports.updateAboutAPI = "account_block/accounts/update_about";


exports.categoriesListAPI = "bx_block_categories/categories";

exports.landingPageDesignsAPI = "account_block/landings/landing_page";

exports.skillsAPI = "bx_block_categories/skills";
exports.updateSocialMedialAPI = "account_block/accounts/social_profile";
exports.updateAboutAPI = "account_block/accounts/update_about";

exports.updateBankDetailsAPI = "account_block/bank_details";

exports.verifyAadharOtpAPI = "account_block/accounts/aadhaar_otp_varification";

exports.verifyAadharAPI = "account_block/accounts/aadhaar_ekyc";


exports.fetchSingleDesignAPI = 'bx_block_attachment/designs';

exports.fetchDesignShareLinkAPI = "bx_block_share/share";

exports.fetchMoreDesignsAPI = "bx_block_attachment/designs/:id/more_designs";

exports.postCommentAPI = 'bx_block_comments/comments';
exports.fetchCommentsAPI = "bx_block_attachment/designs/:id/design_comments";

exports.fetchCollectionAPI = "account_block/collections";

exports.inspiredUsersAPI = "bx_block_attachment/designs/:id/inspire_accounts";

exports.submitRatingAPI = 'bx_block_catalogue/ratings';

exports.fetchWatermarksAPI = 'bx_block_attachment/watermarks';
exports.uploadDesignAPI = 'bx_block_attachment/designs'



exports.fetchSourceFilesAPI = 'bx_block_attachment/designs/:id/source_designs';

exports.createForumAPI = 'bx_block_communityforum/forums';
exports.fetchForumsAPI = "bx_block_communityforum/forums";
exports.likeDesignAPI = 'bx_block_like/likes';
exports.inspireDesignAPI = 'bx_block_catalogue/inspires';

exports.followDesignerAPI = "bx_block_followers/follows";
exports.createDesignPaymentIntentAPI = "bx_block_attachment/buy_designs";

