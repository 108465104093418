import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { APIArrayData, Forum } from "../../../components/src/api-types";
import { appendJsonContentType, appendToken, defineMessageHandlers } from "../../../components/src/utils";
// Customizable Area Start
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  forumData: Forum[];
  profileData: any;
  showForm: boolean;
  title: string;
  description: string;
  showError: boolean;
  postMedia: any;
  currentForum: number;
  submitFormLoading: boolean;
  isLoggedIn: any;
  needLoginOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
  > {
  profileMessageId: string;
  forumListMessageId: string;
  postForumMessageId: string;
  likePostMessageId: string;
  dislikePostMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.onSave = this.onSave.bind(this);
    this.likePost = this.likePost.bind(this);
    this.dislikePost = this.dislikePost.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      loading: false,
      forumData: [],
      showForm: false,
      title: "",
      description: "",
      showError: false,
      profileData: {},
      postMedia: "",
      currentForum: 0,
      submitFormLoading: false,
      isLoggedIn: localStorage.getItem("role"),
      needLoginOpen: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    if (this.state.isLoggedIn) {
      this.fetchProfile();
    }
    this.fetchForums();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    defineMessageHandlers({
      message,
      handlers: {
        [this.profileMessageId]: {
          onSuccess: (responseJson) => {
            this.setState({ profileData: responseJson.data.attributes });
          },
          onError: () => { },
        },
        [this.forumListMessageId]: {
          onSuccess: (responseJson) => {
            this.setState({ forumData: responseJson.data });
          },
          onError: () => { },
          afterAll: () => {
            this.setState({ loading: false });
          }
        },
        [this.postForumMessageId]: {
          onSuccess: () => {

            this.setState({ submitFormLoading: false });
            this.setState({
              title: "",
              description: "",
              postMedia: "",
              showForm: false,
              showError: false
            });
            this.fetchForums();
          },
          onError: () => { },
        },
        [this.likePostMessageId]: {
          onSuccess: () => {

            const forumData = this.state.forumData.map((item) => {
              // @ts-ignore
              if (item.id == this.state.currentForum) {
                if (item.attributes.liked) {
                  item.attributes.like_count -= 1;
                  item.attributes.liked = false;
                } else {
                  toast.success("Liked Successfully");
                  item.attributes.like_count += 1;
                  item.attributes.liked = true;
                  if (item.attributes.disliked) {
                    item.attributes.dislike_count -= 1;
                    item.attributes.disliked = false;
                  }
                }
              }
              return item;
            });
            this.setState({
              forumData: forumData,
              currentForum: 0
            });
          },
          onError: () => { },
        },
        [this.dislikePostMessageId]: {
          onSuccess: () => {

            const forumData = this.state.forumData.map((item: any) => {
              if (item.id === this.state.currentForum) {
                if (item.attributes.disliked) {
                  item.attributes.dislike_count -= 1;
                  item.attributes.disliked = false;
                } else {
                  toast.success("Disliked Successfully");
                  item.attributes.dislike_count += 1;
                  item.attributes.disliked = true;
                  if (item.attributes.liked) {
                    item.attributes.like_count -= 1;
                    item.attributes.liked = false;
                  }
                }
              }
              return item;
            });
            this.setState({
              forumData: forumData,
              currentForum: 0
            });
          },
          onError: () => { },
        }
      },
    });


    // Customizable Area End
  }

  // Customizable Area Start

  //open create topic form
  topicForm = () => {
    if (this.state.isLoggedIn)
      this.setState({ showForm: true });
    else
      this.setState({ needLoginOpen: true });
  };

  //on click of cancel in create topic form
  onCancel = () => {
    this.setState({ showForm: false });
    this.setState({ showError: false });
  };

  //on click of save in create topic form
  onSave({ title, description, media }: { title: string, description: string, media: string }) {

    this.setState({ submitFormLoading: true });

    const httpBody = {
      data: {
        "attributes": {
          title: title,
          description: description,
          media: media ? ({
            data: media
          }) : ""
        },

      }
    };

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.saveForum,
    });

    this.postForumMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  fetchProfile() {

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  fetchForums() {

    this.setState({ loading: true });

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.forums,
    });

    this.forumListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  encodeImageFileAsURL(element: any) {
    return new Promise((resolve, reject) => {
      var file = element.files[0];
      if (file) {
        var reader = new FileReader();
        reader.onloadend = function() {
          let imagebase64 = reader.result;
          resolve(imagebase64);
        };
        reader.readAsDataURL(file);
      } else {
        reject(null);
      }
    });
  };

  likePost(postId: number) {

    if (!this.state.isLoggedIn) {
      this.setState({ needLoginOpen: true });
      return;
    }

    this.setState({ currentForum: postId });

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.saveForum}/${postId}/like`,
    });

    this.likePostMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  dislikePost(postId: number) {
    if (!this.state.isLoggedIn) {
      this.setState({ needLoginOpen: true });
    }
    this.setState({ currentForum: postId });
    const httpBody = {};

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.saveForum}/${postId}/unlike`,
    });

    this.dislikePostMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  // Customizable Area End
}
