import React from 'react';
import {
  likeGray,
  starGray,
  inspireGray,
  shareGray,
  privateMarkIcon
} from "./assets";
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Design, LandingPageDesign } from '../../../components/src/api-types';
import { DEFAULT_PROFILE_IMAGE } from '../../../components/src/defaults';
import DezinerMedia from '../../../components/src/DezinerImage.web';


const useStyles = makeStyles({
  designCard: {
    borderRadius: "5%",
    cursor: "pointer",
    transition: "all 0.5 ease-in-out",
  },
  designCardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    borderRadius: "5%",
    position: "relative",
    "&:hover>div": { opacity: 1 },
  },
  media: {
    objectFit: "cover",
    maxWidth: "100%",
    height: "182px",
    borderRadius: "5%"
  },
  designCardOverlay: {
    borderRadius: "5%",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "#0000005e",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    opacity: 0,
    "& button": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      background: "white",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": { background: "#ddd" },
      "&  img": {
        maxWidth: "20px",
        width: "20px",
        height: "20px"
      },
    },
  },
  designCardDetails: {
    display: 'grid',
    gridTemplateColumns: '1.6rem 80px 58px 1fr 28px 28px',
    overflow: 'hidden',
    alignItems: "center", gridGap: "8px", paddingTop: "5px"
  },
  designCardIcons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "&>div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 5px",
      "&>span": { padding: "0 4px", color: "#929292" },
    },
    "& img": { maxWidth: "1rem" }
  },
  priceTag: {
    background: "#1a1b1c",
    color: "white",
    borderRadius: "30px",
    fontSize: "0.9rem",
    marginLeft: "4px",
    padding: "1px 12px",
    textAlign: "center"
  },
  imageDescription: {
    fontSize: '1rem',
    color: 'white',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '8rem',
    textTransform: 'capitalize',
  },
  iconsButtonsContainer: {
    minWidth: '70px'
  },
  addIcon: {
    color: '#929292'
  },
  overlayGrid: {
    height: '100%',
  },
  overlayContentGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 62px 30px 30px",
    gridGap: "10px",
    alignItems: 'center',
  },
  accountProfileImg: {
    borderRadius: '40%',
    maxWidth: '100%',
    width: '25px',
    height: '25px',
    objectFit: 'cover'
  },
  accountName: {
    color: "#2A2B2D",
    fontWeight: "bold",
    fontSize: "1rem",
    maxWidth: "80px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: '1.2'
  },
  tagItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 4px"
  },
  groupTag: {
    color: "black",
    background: "white",
    border: "1px solid black",
  },
  clubTag: {
    color: "white",
    background: "black",
  },
  designerTag: {
    color: "black",
  },
  tagText: {
    fontSize: '0.8rem',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  detailsIconContainer: {
    justifyContent: "flex-end", flexWrap: "nowrap",
    '& img': {
      maxWidth: '14px',
    },
  },
  detailsIconText: {
    padding: "0 4px", color: "#929292"
  },
});


function ProfileAvatar({
  profilePicture,
  color,
  name,
}: {
  profilePicture?: string,
  color: string | null,
  name?: string,
}) {

  const classes = useStyles();

  if (profilePicture)
    return (
      <img
        src={profilePicture}
        alt={name}
        className={classes.accountProfileImg}
      />
    );

  if (color)
    return (
      <div
        className={classes.accountProfileImg}
        style={{ background: color }}
      />
    );

  return (
    <img
      src={DEFAULT_PROFILE_IMAGE}
      alt={name}
      className={classes.accountProfileImg}
    />
  );

}





export default function DesignCardWithProfile({
  item,
  onClick,
  onShareClick,
  onInspireClick,
  onSaveClick,
  imgHeight,
  onAccountNameClick,
}: {
  item: LandingPageDesign,
  onClick: (item: LandingPageDesign) => void,
  onShareClick: (item: LandingPageDesign) => void,
  onSaveClick: (item: LandingPageDesign) => void,
  onInspireClick: (item: LandingPageDesign) => void,
  onAccountNameClick: (item: LandingPageDesign) => void,
  imgHeight: number,
}) {

  const classes = useStyles();

  return (
    <div
      className={classes.designCard}
      onClick={() => onClick(item)}
      data-testid="design-card-with-profile"
    >
      <div className={classes.designCardContent}>

        <DezinerMedia
          mediaType={item?.attributes?.main_image_type?.includes('video') ? 'video' : 'image'}
          style={{ height: `${imgHeight}px` }}
          src={item?.attributes?.image_url}
          className={classes.media}
          watermarkURL={item?.attributes?.watermark_url ?? ''}
          watermarkWidth={100}
          watermarkHeight={100}
        />

        <div className={classes.designCardOverlay}>

          <Grid
            container
            alignItems='stretch'
            justifyContent='space-between'
            direction='column'
            className={classes.overlayGrid}
            wrap="nowrap"
          >
            <Grid container justifyContent='space-between'>
              {
                item?.attributes?.privacy == "Private" ?
                  <img src={privateMarkIcon} alt="private" style={{ width: '20px', height: '20px' }} />
                  :
                  <span />
              }
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onInspireClick(item);
                }}
              >
                <img src={inspireGray} alt="inspire" />
              </button>
            </Grid>

            <div className={classes.overlayContentGrid}>
              <Typography variant='caption' className={classes.imageDescription}>
                {item?.attributes?.title}
              </Typography>

              {
                item?.attributes?.privacy === "Private" ?
                  <Typography variant="caption" className={classes.priceTag}>
                    {`₹ ${item?.attributes?.price}`}
                  </Typography>
                  :
                  <span />
              }

              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onSaveClick(item);
                }}
              >
                <AddIcon className={classes.addIcon} />
              </button>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onShareClick(item);
                }}
              >
                <img src={shareGray} alt="share" />
              </button>
            </div>

          </Grid>

        </div>

      </div>


      <div className={classes.designCardDetails}>

        <ProfileAvatar
          profilePicture={item?.attributes?.account_profile}
          color={item?.attributes?.account_color}
          name={item?.attributes?.account_name}
        />

        <Typography
          className={classes.accountName}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onAccountNameClick(item);
          }}
        >
          {item?.attributes?.account_name}
        </Typography>

        <div className={clsx(classes.tagItem, {
          [classes.clubTag]: item?.attributes?.account_type?.toLowerCase() == 'club',
          [classes.groupTag]: item?.attributes?.account_type?.toLowerCase() == 'group',
        })}>
          <Typography className={classes.tagText}>
            {item?.attributes?.account_type}
          </Typography>
        </div>

        <span aria-describedby='spacer' />

        <Grid container alignItems='center' className={classes.detailsIconContainer}>
          <img alt="rating" src={starGray} />
          <Typography className={classes.detailsIconText}> {item?.attributes?.rating_count} </Typography>
        </Grid>

        <Grid container alignItems='center' className={classes.detailsIconContainer}>
          <img alt="liked" src={likeGray} />
          <Typography className={classes.detailsIconText}> {item?.attributes?.like_count} </Typography>
        </Grid>

      </div>
    </div>
  );
}

export function DesignCardWithProfileVariant({
  item,
  onClick,
  onShareClick,
  onInspireClick,
  onSaveClick,
  imgHeight,
  onAccountNameClick,
}: {
  item: Design,
  onClick: (item: Design) => void,
  onShareClick: (item: Design) => void,
  onSaveClick: (item: Design) => void,
  onInspireClick: (item: Design) => void,
  onAccountNameClick: (item: Design) => void,
  imgHeight: number,
}) {

  const classes = useStyles();

  return (
    <div
      className={classes.designCard}
      onClick={() => onClick(item)}
    >
      <div className={classes.designCardContent}>
        <img style={{ height: `${imgHeight}px` }} src={item?.attributes?.image_url} />

        <div className={classes.designCardOverlay}>

          <Grid
            container
            alignItems='stretch'
            justifyContent='space-between'
            direction='column'
            className={classes.overlayGrid}
            wrap="nowrap"
          >
            <Grid container justifyContent='space-between'>
              {
                item?.attributes?.privacy == "Private" ?
                  <img src={privateMarkIcon} alt="private" style={{ width: '20px', height: '20px' }} />
                  :
                  <span />
              }
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onInspireClick(item);
                }}
              >
                <img src={inspireGray} alt="inspire" />
              </button>
            </Grid>

            <div className={classes.overlayContentGrid}>
              <Typography variant='caption' className={classes.imageDescription}>
                {item?.attributes?.title}
              </Typography>

              {
                item?.attributes?.privacy === "Private" ?
                  <Typography variant="caption" className={classes.priceTag}>
                    {`₹ ${item?.attributes?.price}`}
                  </Typography>
                  :
                  <span />
              }

              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onSaveClick(item);
                }}
              >
                <AddIcon className={classes.addIcon} />
              </button>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onShareClick(item);
                }}
              >
                <img src={shareGray} alt="share" />
              </button>
            </div>

          </Grid>

        </div>

      </div>


      <div className={classes.designCardDetails}>


        <ProfileAvatar
          profilePicture={item?.attributes?.designer?.data?.attributes?.profile_thumbnail_url}
          color={item?.attributes?.designer?.data?.attributes?.color}
          name={item?.attributes?.designer?.data?.attributes?.first_name}
        />


        <Typography
          className={classes.accountName}
          onClick={(event) => {
            event.preventDefault();
            onAccountNameClick(item);
          }}
        >
          {item?.attributes?.designer?.data?.attributes?.first_name}
        </Typography>

        <div className={clsx(classes.tagItem, {
          [classes.clubTag]: item?.attributes?.design_type?.toLowerCase() == 'club',
          [classes.groupTag]: item?.attributes?.design_type?.toLowerCase() == 'group',
        })}>
          <Typography className={classes.tagText}>
            {item?.attributes?.design_type}
          </Typography>
        </div>

        <span aria-describedby='spacer' />

        <Grid container alignItems='center' className={classes.detailsIconContainer}>
          <img alt="rating" src={starGray} />
          <Typography className={classes.detailsIconText}> {item?.attributes?.rating_count} </Typography>
        </Grid>

        <Grid container alignItems='center' className={classes.detailsIconContainer}>
          <img alt="liked" src={likeGray} />
          <Typography className={classes.detailsIconText}> {item?.attributes?.like_count} </Typography>
        </Grid>

      </div>
    </div>
  );
}
