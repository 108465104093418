import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import Button from "../../../components/src/Button.web";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Tooltip from "@material-ui/core/Tooltip";
import DezinerAudio from '../../../components/src/DezinerAudio.web';
import clsx from 'clsx';
import {
  Reply,
  Info,
  Star,
} from "@material-ui/icons";
import { promote, inspireBlackIcon, saveIcon, chatBulbIcon, eyeIcon, likes, } from './assets';
import "../../catalogue/src/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import DesignInfoDialog from './InfoDialog';
// Customizable Area End

import DesignerDesignViewController, {
  Props,
} from "./DesignerDesignViewController.web";
//import Footer from "../../catalogue/src/Footer.web";
import CircularProgress from "@material-ui/core/CircularProgress";
//import TopHeaderWeb from "../../catalogue/src/TopHeader.web";
import "./DesignerDesignView.css";
import { toast } from "react-toastify";
import DesignerPromotePopUpWeb from "./DesignerPromotePopUp.web";
import ShareDesignDialog from "./ShareDesignDialog";
import Comment from "./Comment.web";
import CommentInput from "./CommentInput.web";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/src/Spacer.web";
import DeleteDialog from "./DeleteDialog.web";
import DesignCard from "./DesignCard.web";
import ResponsiveCardsContainer from "./ResponsiveCardsContainer.web";
import DezinerMedia from "../../../components/src/DezinerImage.web";
import { DEFAULT_PROFILE_IMAGE } from "../../../components/src/defaults";
import DezinerSpinner from "../../../components/src/DezinerSpinner.web";





const HORIZONTAL_SLIDER_SETTINGS: Settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  vertical: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  verticalSwiping: false,
  arrows: true,
  centerMode: false
}
const VERTICAL_SLIDER_SETTINGS: Settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  vertical: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  verticalSwiping: true,
  arrows: false,
  centerMode: false,
}

const SLIDER_SETTINGS: Settings = {
  ...VERTICAL_SLIDER_SETTINGS,
  responsive: [
    {
      breakpoint: 588,
      settings: HORIZONTAL_SLIDER_SETTINGS
    }
  ]
}

const tooltipButtonStyle = { margin: '5px', width: '40px', height: '40px', minWidth: '40px', border: '1px solid #e0e0e0', borderRadius: '4px' }


function ProfileAvatar({
  image,
  color,
  name,
}: {
  image?: string,
  color?: string,
  name?: string,
}) {

  if (image) {
    return (<img
      style={{
        width: '80px',
        height: '80px',
        borderRadius: '40%',
      }}
      src={image}
      alt={name}
    />)
  }

  if (color) {
    return (
      <div
        style={{
          width: '80px',
          height: '80px',
          borderRadius: '40%',
          backgroundColor: color,
        }}
      />
    );
  }

  return (<img
    style={{
      width: '80px',
      height: '80px',
      borderRadius: '40%',
    }}
    src={DEFAULT_PROFILE_IMAGE}
    alt={name}
  />
  );

}


function DesignerName({ title, name }: { title?: string, name?: string }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">
          {title || ''}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {name || ''}
        </Typography>
      </Grid>
    </Grid>
  );
}

function MetaButtons({
  onShare,
  onInfo,
  onPromote,
}: {
  onShare: () => void,
  onInfo: () => void,
  onPromote: () => void,
}) {

  return (
    <Grid container>

      <Grid item xs={4} container justifyContent="flex-end" sm={12}>
        <Tooltip title="Share" arrow placement="left">
          <Button
            style={tooltipButtonStyle}
            onClick={onShare}>
            <Reply style={{ transform: "scaleX(-1)" }} />
          </Button>
        </Tooltip>
      </Grid>

      <Grid item xs={4} container justifyContent="flex-end" sm={12}>
        <Tooltip title="Info" arrow placement="left">
          <Button
            style={tooltipButtonStyle}
            onClick={onInfo}>
            <Info />
          </Button>
        </Tooltip>
      </Grid>

      <Grid item xs={4} container justifyContent="flex-end" sm={12}>
        <Tooltip title="Promote" arrow placement="left">
          <Button
            style={tooltipButtonStyle}
            onClick={onPromote}
          >
            {/* <TrendingUp /> */}
            <img
              src={promote}
              alt="promote"
              className="black-svg"
              style={{
                maxWidth: '20px'
              }}
            />
          </Button>
        </Tooltip>
      </Grid>

    </Grid>
  );
}

class DesignerDesignView extends DesignerDesignViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;

    const { designData } = this.state;

    let designImages = [{
      url: designData?.image_url,
      type: designData?.main_image_type,
    }]
      .concat(designData?.sub_designs ?? [])
      .filter(({ url, type }) => Boolean(url && type));




    const profileAttributes = this.state.designData?.designer?.data?.attributes;
    const designAttributes = this.state.designData;

    const avatar = (
      <ProfileAvatar
        image={profileAttributes?.profile_url}
        name={profileAttributes?.first_name}
        color={profileAttributes?.color}
      />
    );

    const promoteButton = (<Button
      size="small"
      variant="contained"
      style={{
        backgroundColor: "black",
        color: "white",
        textTransform: 'none'
      }}
      onClick={() => {
        if (!this.state.designData.prmoted) {
          this.promoteDesign(
            this.state.designData?.image_url ?? '',
            "Collection"
          )
        }
      }}
    >
      <img
        src={promote}
        alt="promote"
        className="white-svg"
        style={{ maxWidth: '20px', marginRight: '10px' }}
      />
      <Typography variant="caption">
        {this.state.designData.prmoted ? "Promoted" : "Promote"}
      </Typography>
    </Button>);

    const designerName = (
      <DesignerName
        title={designAttributes?.title}
        name={profileAttributes?.first_name}
      />
    );


    const tooltips = (
      <MetaButtons
        onPromote={() => {
          this.promoteDesign(this.state.selectedMediaURL, "Design")
        }}
        onShare={() => {
          this.shareDesign()
        }}
        onInfo={() => {
          this.setState({ infoDialogStatus: true })
        }}
      />
    );


    const mainImage = (
      <div className="design-main-image-container" style={{ position: 'relative' }}>
        <DezinerMedia
          mediaType={!this.state.selectedMediaType.includes('image/') ? 'video' : 'image'}
          src={this.state.selectedMediaURL}
          watermarkURL={this.state.designData?.watermark_url}
          watermarkWidth={parseFloat(this.state.designData?.width ?? '100')}
          watermarkHeight={parseFloat(this.state.designData?.height ?? '100')}
          controls={true}
        />
      </div>
    );


    const subImagesContent =
      designImages.length > 0
        ? designImages
          .map((media: any) => {

            const isImage = media.type.includes("image/");

            return (
              <div
                style={{ margin: '10px', position: 'relative' }}
                onClick={(e) => this.handleImageClick(e, media.type, media.url)}
              >
                <DezinerMedia
                  mediaType={isImage ? 'image' : 'video'}
                  watermarkURL={this.state.designData?.watermark_url}
                  watermarkWidth={parseFloat(this.state.designData?.width ?? '100')}
                  watermarkHeight={parseFloat(this.state.designData?.height ?? '100')}
                  src={media.url}
                  className={clsx("design-item-card", {
                    'design-item-card-active': this.state.selectedMediaURL == media.url
                  })}
                />
              </div>
            );
          })
        :
        null;


    const subImages = false && designImages.length > 3 ? (
      <div style={{ width: '100%', }}>
        <Slider {...SLIDER_SETTINGS}>
          {subImagesContent}
        </Slider>
      </div>
    )
      :
      <div className={classes.subImagesContainer}>
        {subImagesContent}
      </div>;

    const designStats = (
      <Grid container alignItems="center" >

        <Grid item container sm={9} xs={12}>

          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={eyeIcon} style={{ width: '20px', }} alt="eyviews" className="black-svg" />
            <Typography className={classes.statsText}>{this.state.designData?.view_count ?? 0}</Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>

          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={likes} style={{ width: '20px', }} alt="like" className="black-svg" />
            <Typography className={classes.statsText}>{this.state.designData?.like_count ?? 0}</Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>

          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={inspireBlackIcon} style={{ width: '25px', }} alt="inspire" className="black-svg" />
            <Typography className={classes.statsText}>{this.state.designData?.inspired_count ?? 0}</Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>

          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={chatBulbIcon} style={{ width: '18px', }} alt="chat" className="black-svg" />
            <Typography className={classes.statsText}>{this.state.commentsCount || 0}</Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>

          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <img src={saveIcon} style={{ width: '22px', }} alt="eyviews" className="black-svg" />
            <Typography className={classes.statsText}>{'0'}</Typography>
          </Grid>

          <Grid item xs="auto">
            <Divider className={classes.statsDivider} />
          </Grid>

          <Grid item xs container alignItems="center" justifyContent="center" wrap="nowrap">
            <Star />
            <Typography className={classes.statsText}>{this.state.designData?.rating_count ?? 0}</Typography>
          </Grid>

        </Grid>

        <Grid item container sm={3} xs={12} justifyContent="flex-end" wrap="nowrap" style={{ padding: '10px 0' }}>
          <Button size="small" variant="outlined"
            onClick={() => {
              let params: any = {
                designId: this.props.navigation.getParam("id"),
              }
              let type = this.props.navigation.getParam("type");
              let clubOrGroupId = this.props.navigation.getParam("clubOrGroupId");

              if (type) params.type = type;

              if (clubOrGroupId) params.clubOrGroupId = clubOrGroupId;

              this.props.navigation.navigate("DesignerDesignEdit", params)
            }}>
            {'Edit'}
          </Button>
          <HorizontalSpacer />
          <Button size="small" variant="outlined" onClick={() => this.setState({ deleteDialogOpen: true })}>{'Delete'}</Button>
        </Grid>

      </Grid>
    );

    const description = (
      <Grid container justifyContent="space-between">
        <Grid item sm={6} xs={12}>
          <Typography>{this.state.designData?.description}</Typography>
        </Grid>
        <Grid item sm={6} xs={12} container justifyContent="flex-end">
          {
            this.state.designData?.audio_urls?.[0]?.url ?
              <DezinerAudio src={this.state.designData?.audio_urls?.[0]?.url} />
              :
              null
          }
        </Grid>
      </Grid>
    );


    const verticalAvatar = (
      <div className="designer-vertical-avatar">
        <Grid container>
          <Grid xs={12} item container justifyContent="center">
            {
              this.state.designData?.designer?.data?.attributes?.profile_url ?
                <img
                  style={{ width: '100px', height: '100px', borderRadius: '40%' }}
                  src={this.state.designData?.designer?.data?.attributes?.profile_url}
                  alt={this.state.designData?.designer?.data?.attributes?.first_name}
                />
                :
                this.state.designData?.designer?.data?.attributes?.color ?
                  <div
                    style={{
                      width: '100px', height: '100px', borderRadius: '40%',
                      backgroundColor: this.state.designData?.designer?.data?.attributes?.color
                    }}
                  />
                  :
                  <img
                    style={{ width: '100px', height: '100px', borderRadius: '40%' }}
                    src={DEFAULT_PROFILE_IMAGE}
                    alt={this.state.designData?.designer?.data?.attributes?.first_name}
                  />
            }
          </Grid>

          <Grid xs={12} item container justifyContent="center">
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {this.state.designData?.designer?.data?.attributes?.first_name}
            </Typography>
          </Grid>

          <Grid xs={12} item container justifyContent="center">
            <Typography variant="body1" style={{ fontWeight: 500 }}>{'Designer'}</Typography>
          </Grid>
        </Grid>
      </div>
    );


    const moreDesigns = (
      <div>
        <Typography className={classes.moreByText}>
          {`More by ${this.state.designData?.designer?.data?.attributes?.first_name}`}
        </Typography>

        <div>
          {
            this.state?.loadingMoreDesigns ?
              <DezinerSpinner />
              :
              this.state?.moreDesigns?.length ?
                <ResponsiveCardsContainer>
                  {({ itemHeight }) =>
                    this.state.moreDesigns?.slice(0, 4)?.map((item: any) => (
                      <DesignCard
                        item={item}
                        onClick={() => {
                          this.props.navigation.navigate("DesignerDesignView", {
                            id: item?.id,
                          });
                        }}
                        onTrendingClick={() => { }}
                        onEditClick={(item) => {
                          this.props.navigation.navigate("DesignerDesignEdit", {
                            designId: item?.id,
                          });
                        }}
                        imgHeight={itemHeight}
                      />
                    ))}
                </ResponsiveCardsContainer>
                :
                <Typography className={classes.noMoreDesigns}>
                  {`${this.state.designData?.designer?.data?.attributes?.first_name} is working hard on new designs, Please check again later.`}
                </Typography>
          }
        </div>
      </div>
    );



    return (
      <>

        {
          this.state.loading ?
            <DezinerSpinner />
            :
            <>
              <div className="design-view-grid-container">

                <Grid container spacing={3}>

                  <Grid item container xs={12}>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={8} xs={12}>

                      <Grid container>
                        <Grid item sm xs={12} container >
                          <Grid>{avatar}</Grid>
                          <Grid item xs container alignItems="center" style={{ paddingLeft: '10px' }}>
                            {designerName}
                          </Grid>
                        </Grid>
                        <Grid item
                          sm={5}
                          xs={12}
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                          style={{ padding: '0 20px' }}
                        >
                          {promoteButton}
                        </Grid>
                      </Grid>

                    </Grid>
                    <Grid item sm={2}></Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} sm={2} container alignItems="center">
                      {tooltips}
                    </Grid>
                    <Grid item xs={12} sm={8}>{mainImage}</Grid>
                    <Grid item xs={12} sm={2}
                      container
                      style={{}}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {subImages}
                    </Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={8} xs={12}>{designStats}</Grid>
                    <Grid item sm={2}></Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={8} xs={12}>{description}</Grid>
                    <Grid item sm={2}></Grid>
                  </Grid>

                </Grid>




                <Grid item container xs={12}>
                  <Grid item sm={2}></Grid>
                  <Grid item sm={8} xs={12}>

                    <VerticalSpacer />
                    <Divider />
                    <VerticalSpacer />

                    <Typography style={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem'
                    }}>
                      {'Comments'}
                    </Typography>

                    <CommentInput
                      onComment={({ commentText, base64Image, base64Audio }) => {
                        this.postComment({
                          comment: commentText,
                          attachment: base64Image,
                          audioURL: base64Audio
                        })
                      }}
                    />

                    {
                      this.state.comments?.map((comment: any) =>
                        <Comment
                          key={comment.id}
                          comment={comment}
                          onReply={({ replyText, parentCommentId, base64Audio, base64Image }) => {
                            this.postReplyComment({
                              reply: replyText,
                              parentCommentId,
                              replyAudio: base64Audio,
                              replyAttachment: base64Image,
                            })
                          }}
                        />
                      )
                    }

                    {verticalAvatar}



                  </Grid>
                  <Grid item sm={2}></Grid>
                </Grid>

                {moreDesigns}

              </div>
            </>
        }





        <DesignerPromotePopUpWeb
          designerId={0}
          currentImage={this.state.selectedMediaURL}
          currentMediaType={this.state.selectedMediaType}
          modalOpen={this.state.dialogStatus}
          handleModalClose={() => this.setState({ dialogStatus: false })}
          navigation={this.props.navigation}
          promoteType={'Design'}
          id={""}
          designId={this.props.navigation.getParam("id", 0)}
          promoteCollectionId={0}
        />


        <ShareDesignDialog
          open={this.state.shareDialogStatus}
          designLink={this.state.shareDesignLink}
          shareLink={this.state.shareLink}
          onClose={() => this.setState({ shareDialogStatus: false })}
          onCopy={() => toast.success("Link Copied")}
        />

        <DesignInfoDialog
          open={this.state.infoDialogStatus}
          onClose={() => this.setState({ infoDialogStatus: false })}
          data={this.state.designData as any}
          currentImageUrl={this.state.selectedMediaURL}
        />


        <DeleteDialog
          open={this.state.deleteDialogOpen}
          onClose={() => {
            this.setState({ deleteDialogOpen: false });
          }}
          onAccept={() => {
            this.setState({ deleteDialogOpen: false });
            this.props.navigation.navigate("DesignerHome", {})
          }}
        />

      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = {
  formControl: {
    margin: "10px",
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: "20px",
  },
  statsText: {
    padding: '0 10px'
  },
  statsDivider: {
    height: '20px',
    width: '2px',
    margin: '0 10px'
  },
  subImagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ['@media screen and (max-width: 600px)']: {
      flexDirection: 'row',
    }
  },
  moreByText: {
    fontSize: '1.4rem',
    marginBottom: '20px',
  },
  noMoreDesigns: {
    color: "#9f9f9f",
    fontStyle: "italic",
    fontSize: "1.5rem"
  },
};
export default withStyles(useStyles)(DesignerDesignView);
// Customizable Area End
