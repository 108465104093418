import produce from "immer";
import { SetCreateDialogStateAction, SET_CREATE_DIALOG_STATE } from "./ui.actions";

export interface UIState {
  dialogs: {
    createLabelDialog: {
      open: boolean,
      errorMessage: string,
    }
  },
}

const initState: UIState = {
  dialogs: {
    createLabelDialog: {
      open: false,
      errorMessage: '',
    },
  },
}

export type UIActions = SetCreateDialogStateAction;

export const uiReducer = produce((state: UIState, action: UIActions) => {

  switch (action.type) {
    case SET_CREATE_DIALOG_STATE: {
      state.dialogs.createLabelDialog.errorMessage = action.errorMessage;
      state.dialogs.createLabelDialog.open = action.open;
      break;
    }
  }

}, initState);
