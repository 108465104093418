
import { Message } from "../../../framework/src/Message";
import { apiCall } from "../Common";
import { appendJsonContentType, appendToken } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;

export const labelsMessages = {

  fetchDesignerLabelsByUser({ designerId }: { designerId: number }) {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url: configJson.fetchDesignerLabelsByUserAPI.replace(':id', designerId),
    });
  },
  fetchMyLabels() {

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpMethod: configJson.GetMethod,
      url: configJson.fetchLabelsAPI,
    });

  },

  createLabel({ labelName }: { labelName: string }) {

    const httpBody = {
      data: {
        attributes: {
          name: labelName,
        },
      },
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJson.PostMethod,
      url: configJson.addNewLabelAPI,
    });

  },
}
