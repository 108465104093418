import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DezinerButton from "../../../components/src/Button.web";
import DezinerChip from "../../../components/src/Chip.web";
import clsx from 'clsx';

const useStyles = makeStyles({
  container: {
    width: 'auto',
    padding: '20px 0',
    marginBottom: '10px',
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '92%',
      height: '1px',
      background: '#d5d5d5'
    },
  },
  hideBorder: {
    '&::after': {
      background: 'transparent'
    }
  },
  img: {
    width: '60px', height: '60px', borderRadius: '40%',
  },
  textContentContainer: {
    padding: '0 12px'
  },
  linkStyle: {
    color: 'black', textDecoration: 'none'
  },
  nameStyle: {
    color: '#27282A', fontSize: '1.4rem', fontWeight: 'bold'
  },
  tag: {
    fontSize: "1rem",
    background: "black",
    color: "white",
    height: "26px",
    padding: "12px 0px",
    margin: "0 10px",
    fontWeight: "bold"
  },
  members: {
    color: '#97989A', fontSize: '1rem', fontWeight: 'bold',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '5px 0'
  },
  btn: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    padding: '6px 16px',
  }
});



export default function GroupAndClubHorizontalCard({
  item,
  onClick,
  link,
  buttonTitle,
  tagName,
  hideMembers,
  hideButton,
  hideBorder,
}: {
  item: any,
  onClick?: (item: any) => void,
  link?: (item: any) => string,
  buttonTitle?: string,
  tagName?: string,
  hideMembers?: boolean,
  hideButton?: boolean,
  hideBorder?: boolean,
}) {

  const classes = useStyles();


  return (
    <Grid container
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.container, {
        [classes.hideBorder]: hideBorder
      })}
    >

      <Grid item xs={2} sm={2} container justifyContent="flex-end">
        <img
          className={classes.img}
          src={item?.attributes?.image_url}
          alt={item?.attributes?.name}
        />
      </Grid>

      <Grid item xs sm container direction="column" className={classes.textContentContainer}>

        <Grid container alignItems="center">
          <Link
            to={link ? link(item) : '#'}
            className={classes.linkStyle}
          >
            <Typography variant="h4" className={classes.nameStyle}>
              {item?.attributes?.name}
            </Typography>
          </Link>
          {
            item?.attributes?.account_type === "Owner" ?
              <DezinerChip label={item?.attributes?.account_type  || "Owner"} className={classes.tag} />
              :
              null
          }
        </Grid>
        {
          hideMembers ?
            null
            :
            <Typography variant="body2" className={classes.members}>
              {
                item?.attributes?.members?.data
                  ?.map((member: any) => (member?.attributes?.first_name))
                  .join(" / ")
              }
            </Typography>
        }
      </Grid>

      {
        hideButton ?
          null
          :
          <Grid item xs={12} sm={3} container justifyContent="flex-end">
            <DezinerButton
              color="primary"
              variant="outlined"
              size="small"
              className={classes.btn}
              onClick={() =>
                onClick && onClick(item)
              }
            >
              {buttonTitle}
            </DezinerButton>
          </Grid>
      }

    </Grid>
  )
}
