import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMoreDesigns, useSingleDesign } from './hooks';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import DezinerSpinner from './DezinerSpinner.web';
import DesignCard from '../../blocks/landingpage/src/DesignCard.web';
import { navigateTo } from './store/navigation/navigation.action';
import ResponsiveCardsContainer from '../../blocks/landingpage/src/ResponsiveCardsContainer.web';

const useMoreDesignsSection = makeStyles({
  moreByText: {
    fontSize: '1.4rem',
    marginBottom: '20px',
  },
  noMoreDesigns: {
    color: "#9f9f9f",
    fontStyle: "italic",
    fontSize: "1.5rem"
  },
});

export default function MoreDesignsSection({ designId }: { designId: number }) {

  const classes = useMoreDesignsSection();

  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;
  const designerAttributes = designAttributes?.designer?.data?.attributes;

  const dispatch = useDispatch();
  const moreDesignsQuery = useMoreDesigns({ designId });

  if (moreDesignsQuery?.isLoading)
    return (<DezinerSpinner />);

  if (!moreDesignsQuery?.data?.data?.length)
    return null

  return (
    <div>
      <Typography className={classes.moreByText}>
        {`More by ${designerAttributes?.first_name ?? ''}`}
      </Typography>

      <div>
        {
          moreDesignsQuery?.data?.data?.length ?
            <ResponsiveCardsContainer>
              {({ itemHeight }) =>
                (moreDesignsQuery?.data?.data ?? [])
                  ?.slice(0, 4)?.map((item) => (
                    <DesignCard
                      item={item}
                      onClick={() => {
                        dispatch(navigateTo({ url: `DesignView/${item.id}` }));
                      }}
                      onTrendingClick={() => { }}
                      onEditClick={(item) => {
                      }}
                      imgHeight={itemHeight}
                    />
                  ))}
            </ResponsiveCardsContainer>
            :
            <Typography className={classes.noMoreDesigns}>
              {`${designerAttributes?.first_name} is working hard on new designs, Please check again later.`}
            </Typography>
        }
      </div>
    </div>
  );
}
