import { Profile } from "../../api-types";
import { DezinerActionCreator } from "../store";

export const FETCH_PROFILE = 'FETCH_PROFILE';

export interface FetchProfileAction {
  type: typeof FETCH_PROFILE,
}

export const fetchProfile: DezinerActionCreator<FetchProfileAction> =
  () => ({
    type: FETCH_PROFILE,
  });

//////////// 

export const SET_PROFILE = 'SET_PROFILE';

export interface SetProfileAction {
  type: typeof SET_PROFILE,
  profile: Profile,
}

export const setProfile: DezinerActionCreator<SetProfileAction> =
  (params) => ({
    type: SET_PROFILE,
    ...params,
  });

