import reduxDezinerMiddlewareWeb from "../redux-deziner-middleware.web";
import { put } from 'redux-saga/effects';
import { takeHttpResponse } from "../effects/effect-utils";
import { FetchDesignAction, setDesigns } from "./designs.action";

const configJson = require('../../config.js');



export function* fetchDesignsSaga(action: FetchDesignAction) {
  try {

    let endpoint = '';
    const { filter, designerId, category, userId, labelId, } = action;

    if (designerId) {
      switch (category) {
        case 'liked': { endpoint = 'like_designs'; break; }
        case 'promoted': { endpoint = 'promoted_designs'; break; }
        case 'rated': { endpoint = 'raterd_designs'; break; }
        case 'inspired': { endpoint = 'inspired_designs'; break; }
        case 'free': { endpoint = 'free_designs'; break; }
        case 'paid': { endpoint = 'paid_designs'; break; }
      }
    }

    if (userId) {
      switch (category) {
        case 'liked': { endpoint = 'user_like_designs'; break; }
        case 'all': { endpoint = 'user_designs'; break; }
        case 'rated': { endpoint = 'user_raterd_designs'; break; }
        case 'inspired': { endpoint = 'user_inspired_designs'; break; }
        case 'saved': { endpoint = 'user_saved_designs'; break; }
        case 'shared': { endpoint = 'user_shared_designs'; break; }
      }
    }

    let requestParams: any = {}

    if (designerId) {
      requestParams.label_id = labelId ? `?label_id=${action.labelId}` : '';
    }

    if (filter) {
      requestParams.filter = filter;
    }

    let baseURL = '';

    if (action.userId)
      baseURL = configJson.userDesignsBaseAPI;

    if (action.designerId)
      baseURL = action.category == 'collections' ?
        configJson.fetchCollectionsAPI :
        action.category == 'designs' ?
          configJson.fetchDesignerDesignsAPI :
          configJson.fetchDesignsBaseAPI


    const sentMessageId = reduxDezinerMiddlewareWeb.sendHttpRequest({
      header: {},
      url: `${baseURL}/${endpoint}`,
      httpMethod: configJson.GetMethod,
      params: requestParams
    });

    const response = yield takeHttpResponse(sentMessageId);

    const { type, ...actionParams } = action;

    if (response) {
      yield put(setDesigns({
        designs: response.data,
        ...actionParams,
        labelId: action.labelId && action.labelId != "0" ? action.labelId : '',
      }));
    }

  } catch (error) {
    console.error(error);
  }
}
