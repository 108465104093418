import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';
import { changeLabel, fetchLabels } from '../../../components/src/store/labels/labels.action';
import { navigateTo } from '../../../components/src/store/navigation/navigation.action';

const useStyles = makeStyles({
  labelListing: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    margin: "10px auto",
    padding: "0 2px",
    maxWidth: "max-content",

    ['@media screen and (max-width: 650px)']: {
      marginTop: "10px",
      width: "100%",
      //maxWidth: "100%",
      //justifyContent: "center"
    },
  },
  labelListingWithScrollBar: {
    //top: '10px',
  },
  span: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    height: "50px",
    border: "1px solid black",
    cursor: "pointer",
    backgroundColor: "white",
    fontWeight: 700,
    fontSize: "18px",

    '& img': {
      width: "20px", maxWidth: "20px", margin: "0 4px"
    },
    ['@media screen and (max-width: 650px)']: {
      padding: "6px 15px",
      fontSize: "14px !important"
    },
  },

  pin: {
    position: "sticky",
    top: "0",
    height: "50px",
    minWidth: "50px",
    maxWidth: "50px",
  },
  pinStart: {
    left: '-2px',
  },
  pinEnd: {
    right: '-2px',
  },
  activeLabel: {
    backgroundColor: '#ecedef',
  }
});



export interface LabelListingProps {
  list: {
    onClick: (id: number) => void,
    label: string,
    hide?: boolean,
    active?: boolean,
    id: number,
    icon?: any,
    pin?: 'start' | 'end',
    alt?: string,
    iconStyle?: CSSProperties,
  }[],
}

const LabelListing = (props: LabelListingProps) => {

  const { list } = props;
  const classes = useStyles();

  const [hasScrollBar, setHasScrollBar] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(function checkScrollBarExist() {
    if (containerRef && containerRef.current) {

      const scrollWidth = containerRef?.current?.scrollWidth;
      const clientWidth = containerRef?.current?.clientWidth;

      if (scrollWidth > clientWidth)
        setHasScrollBar(true);
    }
  }, [containerRef, list.length]);


  return (
    <div
      ref={containerRef}
      className={clsx("deziner-scroll-bar", classes.labelListing, {
        [classes.labelListingWithScrollBar]: hasScrollBar
      })}>

      {
        list
          .filter(({ hide }) => !hide)
          .map(({ id, label, onClick, active, icon, pin, alt, iconStyle }) => (
            <Tooltip 
              title={alt || label}
              placement="top"
              key={id + label}
            >
              <span
                className={clsx(classes.span, {
                  [classes.activeLabel]: active,
                  [classes.pinStart]: pin == 'start',
                  [classes.pinEnd]: pin == 'end',
                  [classes.pin]: Boolean(pin),
                })}
                onClick={() => onClick(id)}
              >
                {
                  icon ?
                    <img src={icon} style={iconStyle}/>
                    :
                    null
                }
                {label}
              </span>
            </Tooltip>
          ))}
    </div>
  );
}

export default LabelListing;



