import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { DezinerLabel } from '../../../components/src/TextField.web';
import DezinerButton from '../../../components/src/Button.web';
import FormikTextInput from '../../../components/src/FormikTextField.web';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DezinerAlert from '../../../components/src/Alert.web';


const useStyles = makeStyles({
  otpText: {
    color: '#666666'
  }
});

const initValues = {
  aadhaarNumber: '',
  otp: '',
}

const validationSchema1 = yup.object().shape({
  aadhaarNumber: yup.string().required(),
  otp: yup.string(),
});

const validationSchema2 = yup.object().shape({
  aadhaarNumber: yup.string().required(),
  otp: yup.string().required(),
});

export function UpdateEKYCDialog({
  open,
  onClose,
  onSubmit,
  onVerify,
  isInvalidOTP,
}: {
  open: boolean,
  onClose: () => void,
  onSubmit: (values: typeof initValues) => void,
  onVerify: (values: any) => Promise<boolean>,
  isInvalidOTP: boolean,
}) {

  const [openVerify, setOpenVerify] = useState(false);
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const classes = useStyles();


  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >

      <Formik
        initialValues={initValues}
        validationSchema={openVerify ? validationSchema2 : validationSchema1}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {
          formik => <Form translate="">
            <div style={{ padding: '40px', position: 'relative' }}>


              <IconButton
                style={{ position: 'absolute', top: '5px', right: '5px' }}
                onClick={onClose}>
                <CloseIcon />
              </IconButton>

              <DialogTitle>
                <DezinerLabel>
                  {'E-KYC'}
                </DezinerLabel>
              </DialogTitle>
              <DialogContent>

                <FormikTextInput
                  variant="outlined"
                  label="Aadhaar Number*"
                  name="aadhaarNumber"
                  fullWidth
                />

                <Grid container justifyContent="flex-end">
                  <DezinerButton
                    variant="text"
                    color="secondary"
                    onClick={async () => {
                      if (formik.values.aadhaarNumber !== '') {
                        var value = await onVerify(formik.values.aadhaarNumber);
                        if (value) {
                          setOpenVerify(true);
                        }
                      }
                    }}>
                    {'verify'}
                  </DezinerButton>
                </Grid>

                <VerticalSpacer />

                {
                  openVerify ?
                    <>
                      <Typography className={classes.otpText}>
                        {'Enter the OTP that sent to your registered mobile number'}
                      </Typography>
                      <FormikTextInput
                        variant="outlined"
                        label="OTP"
                        name="otp"
                        fullWidth
                      />
                      {
                        isInvalidOTP ?
                          <DezinerAlert>
                            {'OTP is invalid'}
                          </DezinerAlert>
                          :
                          null
                      }
                    </>
                    :
                    null
                }
                <VerticalSpacer />



                <Grid container justifyContent="flex-end">
                  <DezinerButton type="submit" color="primary" variant="contained">
                    {'Save'}
                  </DezinerButton>
                </Grid>

              </DialogContent>
            </div>

          </Form>
        }
      </Formik>
    </Dialog>
  );
}
