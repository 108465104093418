import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Rating from "@material-ui/lab/Rating";
import FacebookIcon from "@material-ui/icons/Facebook";
import PinterestIcon from "@material-ui/icons/Pinterest";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import MicIcon from "@material-ui/icons/Mic";
import MicNoneIcon from "@material-ui/icons/MicNone";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
  Reply,
  Info,
  Favorite,
  WbIncandescent,
  Visibility,
  Add,
  Star,
  ChatBubble,
  Close as CloseIcon,
  Save,
} from "@material-ui/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
// Customizable Area End

import DesignViewByUserController, {
  Props,
} from "./DesignViewByUserController.web";
import Footer from "../../catalogue/src/Footer.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import TopHeaderUserWeb from "./TopHeaderUser.web";
import "./DesignViewByUser.css";
import { toast } from "react-toastify";

class DesignViewByUser extends DesignViewByUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <>
{/*
        <TopHeaderUserWeb
          navigation={this.props.navigation}
          userRole={this.state.profileRole}
          userProfileImg={this.state.profileImgUrl}
          clubName=""
          groupName=""
        />
*/}
        <div className="main-container">
          {!this.state.loading ? (
            <>
              <div className="userData">
                <div />
                <div className="userDataInfo">
                  <div className="userDataImg">
                    <img
                      src={
                        this.state.designData.designer.data.attributes
                          .profile_url === ""
                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                          : this.state.designData.designer.data.attributes
                              .profile_url
                      }
                      alt={
                        this.state.designData.designer.data.attributes
                          .first_name
                      }
                    />
                  </div>
                  <div className="userDataName">
                    <h4
                      style={{
                        margin: 0,
                      }}
                    >
                      <b>{this.state.designData.title}</b>
                    </h4>
                    <span>
                      {
                        this.state.designData.designer.data.attributes
                          .first_name
                      }
                      <ul
                        style={{
                          display: "inline-flex",
                          columnGap: "20px",
                          paddingLeft: "25px",
                        }}
                      >
                        <li>{
                          this.state.designData.designer.data.attributes.followed ? 'Following' : 'follow'
                        }</li>
                        <li>{
                          this.state.designData.designer.data.attributes.subscribed ? 'Subscribed' : 'Subscribe'
                        }</li>
                      </ul>
                    </span>
                  </div>
                  <div className="DesignPromoteBtn">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        this.state.designData.saved
                          ? null
                          : this.setState({ dialogStatus: true })
                      }
                    >
                      <Save />{" "}
                      <span style={{ marginLeft: "10px" }}>
                        {this.state.saved ? "Saved" : "Save"}
                      </span>
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "black", color: "white" }}
                      onClick={this.likeDesign}
                    >
                      <Favorite />{" "}
                      <span style={{ marginLeft: "10px" }}>
                        {this.state.liked ? "Liked" : "Like"}
                      </span>
                    </Button>
                  </div>
                </div>
                <div />
              </div>
              <div className="designerDesign">
                <div className="designerDesignOptions">
                  <Tooltip title="Share" arrow placement="left">
                    <Button onClick={() => this.shareDesign()}>
                      <Reply style={{ transform: "scaleX(-1)" }} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Info" arrow placement="left">
                    <Button>
                      <Info />
                    </Button>
                  </Tooltip>
                </div>
                <div className="designerDesignMainImage">
                  {this.state.currentImageType === 'image' ? (
                    <img src={this.state.currentImage} />
                  ) : (
                    <video
                      autoPlay
                      controls
                    >
                      <source src={this.state.currentImage} />
                    </video>
                  )}
                </div>
                <div className="desingerDesignSubImage">
                  {this.state.designImages.length > 0
                    ? this.state.designImages.map((img: any, index: number) => {
                        const currentClass =
                          index === 0 ? "subImages active" : "subImages";
                        return (
                          img.type.includes("image/") ? (
                            <img
                              src={img.url}
                              className={currentClass}
                              onClick={(e) => this.handleImageClick(e, 'image', img.url)}
                            />
                          ) : (
                            <video
                              className={currentClass}
                              onClick={(e) => this.handleImageClick(e, 'video', img.url)}
                            >
                              <source src={img.url} type={img.type} />
                            </video>
                          )
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="designerDesingData">
                <div />
                <div className="DesignDataAttr">
                  <p
                    className={
                      this.state.totalInspires > 0
                        ? "DesignerInspired"
                        : ""
                    }
                    onClick={() => this.postInspire()}
                    style={{ cursor: 'pointer' }}
                  >
                    <WbIncandescent />{" "}
                    <span>{this.state.totalInspires}</span>
                    <ul className="DesignerInspiredul">
                      {this.state.inspireAccounts.length > 0
                        ? this.state.inspireAccounts.map((item: any) => (
                            <li>
                              <img
                                src={item.attributes.profile_url}
                                alt="profileImg"
                              />
                              <span>{item.attributes.first_name}</span>
                            </li>
                          ))
                        : null}
                    </ul>
                  </p>
                  <p>
                    <ChatBubble />{" "}
                    <span>{this.state.commentCount}</span>
                  </p>
                  <p>
                    <Favorite /> <span>{this.state.likesCount}</span>
                  </p>
                  <p>
                    <Visibility />{" "}
                    <span>{this.state.designData.view_count}</span>
                  </p>
                  <p>
                    <Add /> <span>{this.state.savedCount}</span>
                  </p>
                  <p
                    onClick={() => this.setState({ showRating: true })}
                    style={{ cursor: "pointer" }}
                  >
                    <Star /> <span>{this.state.ratingCount}</span>
                  </p>
                </div>
                <div />
              </div>
              <div className="designDescription2">
                <div />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p>{this.state.designData.description}</p>
                  {this.state.designData.audio_urls.length > 0 ? (
                    <audio className="audioStyle" src={this.state.designData.audio_urls[0].url} controls />
                  ) : null}
                </div>
                <div />
              </div>
              <div className="designDescription2">
                <div />
                <div className="postComment">
                  <h3>
                    <b>Comments</b>
                  </h3>
                  <div className={classes.textareaInput}>
                    <textarea
                      className={classes.customTextarea}
                      placeholder="Write your comment..."
                      onChange={(e) =>
                        this.setState({ comment: e.target.value })
                      }
                      value={this.state.comment}
                    />
                    <div className={classes.attachmentRow}>
                      <div className={classes.attachments}>
                        <audio className="audioStyle" src={this.state.audioURL} controls />
                        <span
                          style={{
                            display: this.state.attachment ? "block" : "none",
                          }}
                        >
                          1 attachment
                        </span>
                      </div>
                      <div className={`${classes.attachmentActions} attachmentStyles`}>
                        <span>
                          <input
                            style={{ display: "none" }}
                            id="addAttachment"
                            type="file"
                            onChange={(e) => {
                              e.preventDefault();
                              this.encodeImageFileAsURL(e.target).then(
                                (res: any) => {
                                  this.setState({ attachment: res });
                                }
                              );
                            }}
                          />
                          <AttachFileIcon
                            onClick={() => {
                              const attachment = document.getElementById(
                                "addAttachment"
                              );
                              if (attachment) attachment.click();
                              return;
                            }}
                          />
                        </span>
                        <span>
                          {this.state.isRecording ? (
                            <MicIcon onClick={() => this.stopRecording()} />
                          ) : (
                            <MicNoneIcon
                              onClick={() => this.startRecording()}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="submitPost">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() =>
                        this.setState({
                          comment: "",
                          audioURL: "",
                          attachment: null,
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={this.postComment}
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Post
                    </Button>
                  </div>
                </div>
                <div />
              </div>
              <div className="designDescription2">
                <div />
                <div className="commentListing">
                  {this.state.comments.length > 0 ? (
                    <>
                      {this.state.comments.map((item: any) => (
                        <>
                          <div className={classes.commentsList}>
                            <div className="userDataImg">
                              <img
                                src={
                                  item.comment_by.data.attributes.profile_url
                                }
                              />
                            </div>
                            <div>
                              <div className={classes.commentData}>
                                <div
                                  className="userDataName"
                                  style={{
                                    fontWeight: 500,
                                  }}
                                >
                                  <span style={{ marginBottom: "10px" }}>
                                    <b>
                                      {
                                        item.comment_by.data.attributes
                                          .first_name
                                      }
                                    </b>
                                  </span>
                                  <br />
                                  <span>{item.comment}</span>
                                  <br />
                                  <div className={classes.attachments}>
                                    {item.attachments_url.length > 0 && (
                                      <>
                                        <a
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          href={item.attachments_url[0].url}
                                          download
                                        >
                                          <AttachFileIcon />{" "}
                                          <span>1 attachment</span>
                                        </a>
                                      </>
                                    )}
                                    {item.audio_urls.length > 0 && (
                                      <audio className="audioStyle"
                                        src={item.audio_urls[0].url}
                                        controls
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="DesignPromoteBtn">
                                  <Button
                                    size="small"
                                    onClick={() => {
                                        if (this.state.addReply === item.id) {
                                          this.setState({ addReply: 0 });
                                        }
                                        else {
                                          this.setState({ addReply: item.id })
                                        }
                                      }
                                    }
                                  >
                                    Reply
                                  </Button>
                                  <Button size="small">Like</Button>
                                </div>
                              </div>
                              <div className={classes.commentReplies}>
                                <div
                                  className={classes.addReply}
                                  style={{
                                    display:
                                      this.state.addReply === item.id
                                        ? "block"
                                        : "none",
                                    margin: "20px 0",
                                  }}
                                >
                                  <div className={classes.textareaInput}>
                                    <textarea
                                      className={classes.customTextarea}
                                      placeholder="Write your comment..."
                                      onChange={(e) =>
                                        this.setState({
                                          replyComment: e.target.value,
                                        })
                                      }
                                      value={this.state.replyComment}
                                    />
                                    <div className={classes.attachmentRow}>
                                      <div className={classes.attachments}>
                                        <audio className="audioStyle"
                                          src={this.state.replyCommentAudio}
                                          controls
                                        />
                                        <span
                                          style={{
                                            display: this.state
                                              .replyCommentAttachment
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          1 attachment
                                        </span>
                                      </div>
                                      <div
                                        className={classes.attachmentActions}
                                      >
                                        <span>
                                          <input
                                            style={{ display: "none" }}
                                            id={`addAttachment-${item.id}`}
                                            type="file"
                                            onChange={(e) => {
                                              e.preventDefault();
                                              this.encodeImageFileAsURL(
                                                e.target
                                              ).then((res: any) => {
                                                this.setState({
                                                  replyCommentAttachment: res,
                                                });
                                              });
                                            }}
                                          />
                                          <AttachFileIcon
                                            onClick={() => {
                                              const attachment = document.getElementById(
                                                `addAttachment-${item.id}`
                                              );
                                              if (attachment)
                                                attachment.click();
                                              return;
                                            }}
                                          />
                                        </span>
                                        <span>
                                          {this.state.isRecording ? (
                                            <MicIcon
                                              onClick={() =>
                                                this.stopRecording()
                                              }
                                            />
                                          ) : (
                                            <MicNoneIcon
                                              onClick={() =>
                                                this.startRecording()
                                              }
                                            />
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="submitPost">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        this.setState({
                                          replyComment: "",
                                          replyCommentAudio: "",
                                          replyCommentAttachment: null,
                                        })
                                      }
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() => this.postReplyComment()}
                                      style={{
                                        backgroundColor: "black",
                                        color: "white",
                                      }}
                                    >
                                      Post
                                    </Button>
                                  </div>
                                </div>
                                {item.replies.data.length > 0
                                  ? item.replies.data.map((replyItem: any) => {
                                    const replyData = replyItem.attributes;
                                      return (
                                        <div className={classes.commentsList}>
                                          <div className="userDataImg">
                                            <img
                                              src={
                                                replyData.repled_by.data.attributes.profile_url
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div
                                              className={classes.commentData}
                                              style={{ gridTemplateColumns: 'auto' }}
                                            >
                                              <div
                                                className="userDataName"
                                                style={{
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <span style={{ marginBottom: "10px" }}>
                                                  <b>
                                                    {
                                                      replyData.repled_by.data.attributes
                                                        .first_name
                                                    }
                                                  </b>
                                                </span>
                                                <br />
                                                <span>{replyData.comment}</span>
                                                <br />
                                                <div className={classes.attachments}>
                                                  {replyData.attachments_url.length > 0 && (
                                                    <>
                                                      <a
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent: "center",
                                                        }}
                                                        href={replyData.attachments_url[0].url}
                                                        download
                                                      >
                                                        <AttachFileIcon />{" "}
                                                        <span>1 attachment</span>
                                                      </a>
                                                    </>
                                                  )}
                                                  {replyData.audio_urls.length > 0 && (
                                                    <audio className="audioStyle"
                                                      src={replyData.audio_urls[0].url}
                                                      controls
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </>
                      ))}
                      <p>View All Comments</p>
                    </>
                  ) : null}
                </div>
                <div />
              </div>
              <div className="designDescription2">
                <div />
                <div className="designerData">
                  <img
                    src={
                      this.state.designData.designer.data.attributes
                        .profile_url === ""
                        ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                        : this.state.designData.designer.data.attributes
                            .profile_url
                    }
                  />
                  <p>
                    {this.state.designData.designer.data.attributes.first_name}
                    <br />
                    <span>Designer</span>
                    <br />
                    <br />
                    <Button variant="outlined">
                      {this.state.designData.designer.data.attributes.subscribed ? 'Subscribed' : 'Subscribe'}
                    </Button>
                  </p>
                </div>
                <div />
              </div>
              {this.state.moreDesigns.length > 0 ? (
                <div className="moreDesigns">
                  <div className="heading">
                    <p>
                      More by{" "}
                      {
                        this.state.designData.designer.data.attributes
                          .first_name
                      }
                    </p>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "black", color: "white" }}
                      onClick={() =>
                        this.props.navigation.navigate("DesignerProfileByUser",{
                          id: this.state.designData.designer.id,
                          screenState: "home"
                        })
                      }
                    >
                      View Profile
                    </Button>
                  </div>
                  <div className="designsList">
                    {this.state.moreDesigns.map((item: any) => (
                      <div
                        className="moreDesignData"
                        onClick={() => this.viewDesign(item.id)}
                      >
                        <img src={item.image_url} />
                        <div>
                          <p>{item.title}</p>
                          <p>
                            <span>
                              <WbIncandescent /> {item.inspired_count}
                            </span>
                            <span>
                              <Star /> {item.rating_count}
                            </span>
                            <span>
                              <Favorite /> {item.like_count}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
{/*
        <Footer
          navigation={this.props.navigation}
        />
*/}
        <div
          className="promotePopup"
          style={{ display: this.state.dialogStatus ? "flex" : "none" }}
        >
          <span
            className="promotePopupCloseIcon"
            onClick={() =>
              this.setState({ dialogStatus: false, collectionForm: false })
            }
          >
            <CloseIcon />
          </span>
          <div className="promoteContainer">
            <div className="promoteTitle imgtitle">
              <img src={this.state.mainImage} />
            </div>
            <div className="promoteMainContainer">
              <h3 className="containerTitle">
                Add this design to a collection
              </h3>
              <FormControl fullWidth>
                <InputLabel id="select-collection">
                  Select Collection
                </InputLabel>
                <Select
                  labelId="select-collection"
                  id="collections"
                  value={this.state.selectedCollection}
                  label="Select"
                  onChange={(e: any) =>
                    this.setState({ selectedCollection: e.target.value })
                  }
                >
                  {this.state.collections.length > 0
                    ? this.state.collections.map((item: any) => (
                        <MenuItem value={item.attributes.id}>{`${
                          item.attributes.name
                        } (${item.attributes.privacy})`}</MenuItem>
                      ))
                    : (
                      <MenuItem value="">
                        <em>No Colection</em>
                      </MenuItem>
                    )}
                </Select>
              </FormControl>
            </div>
            <div
              className="promoteFooter"
              style={{
                justifyContent: "end",
              }}
            >
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: this.state.collectionForm ? "none" : "block",
                }}
                variant="contained"
                disabled={this.state.selectedCollection === ""}
                onClick={this.saveDesign}
              >
                Save
              </Button>
            </div>
            <Divider />
            <div style={{ marginTop: "20px" }}>
              <Button
                style={{
                  backgroundColor: "lightgrey",
                  color: "black",
                  float: "right",
                  display: this.state.collectionForm ? "none" : "block",
                }}
                onClick={() => this.setState({ collectionForm: true })}
                variant="contained"
              >
                Create Collection
              </Button>
              <div
                style={{
                  display: this.state.collectionForm ? "block" : "none",
                  marginTop: "20px",
                }}
              >
                <TextField
                  id="collectionName"
                  label="Collection"
                  fullWidth
                  variant="outlined"
                  value={this.state.collectionName}
                  placeholder="Enter Collection Name"
                  onChange={(e) =>
                    this.setState({ collectionName: e.target.value })
                  }
                />
                <div
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.privacy}
                          onChange={(e) =>
                            this.setState({ privacy: e.target.checked })
                          }
                        />
                      }
                      label="keep this collection private"
                    />
                  </FormGroup>
                  <Button
                    style={{
                      backgroundColor: "black",
                      color: "white",
                    }}
                    variant="contained"
                    onClick={this.saveInNewCollection}
                    disabled={this.state.collectionName === ""}
                  >
                    Create and Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="promotePopup"
          style={{ display: this.state.showRating ? "flex" : "none" }}
        >
          <span
            className="promotePopupCloseIcon"
            onClick={() => this.setState({ showRating: false })}
          >
            <CloseIcon />
          </span>
          <div
            className="promoteContainer"
            style={{
              borderRadius: "20px",
              textAlign: "center",
              width: "25vw",
              padding: "0",
            }}
          >
            <h3
              className="PopupTitle"
              style={{
                borderBottom: "1px solid lightgrey",
                paddingBottom: "15px",
              }}
            >
              Rate the Design
            </h3>
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                display: "grid",
                justifyContent: "center",
                rowGap: "15px",
              }}
            >
              <Rating
                name="design_rating"
                size="large"
                precision={0.5}
                onChange={(e: any) =>
                  this.setState({ userRating: e.target.value })
                }
              />
              <sub
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "darkgrey",
                }}
              >
                Tab a Star to Rate
              </sub>
            </div>
            <div
              style={{ width: "100%", display: "flex", alignItems: "stretch" }}
            >
              <Button
                variant="outlined"
                size="small"
                style={{ width: "100%", borderRadius: "0" }}
                onClick={() => this.setState({ showRating: false })}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "100%", borderRadius: "0" }}
                onClick={this.saveRating}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        <div
          className="promotePopup"
          style={{ display: this.state.shareDialogStatus ? "flex" : "none" }}
        >
          <span
            className="promotePopupCloseIcon"
            onClick={() => this.setState({ shareDialogStatus: false })}
          >
            <CloseIcon />
          </span>
          <div className="promoteContainer">
            <div className="promoteTitle imgtitle">
              <img src={this.state.shareDesignLink} />
            </div>
            <>
              <div className="promoteMainContainer">
                <p className="containerTitle">
                  Share this with your social Community
                </p>
                <div className="">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <a target="_blank" href="#" className="shareSocialIcons">
                      D
                    </a>
                    <a
                      target="_blank"
                      href={`https://pinterest.com/pin/create/link/?url=${
                        this.state.shareLink
                      }&media_uri=${
                        this.state.shareLink
                      }`}
                      className="shareSocialIcons"
                    >
                      <PinterestIcon />
                    </a>
                    <a
                      target="_blank"
                      href={`https://twitter.com/share?url=${
                        this.state.shareLink
                      }`}
                      className="shareSocialIcons"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${
                        this.state.shareLink
                      }`}
                      className="shareSocialIcons"
                    >
                      <FacebookIcon />
                    </a>
                    <a target="_blank" href="#" className="shareSocialIcons">
                      <InstagramIcon />
                    </a>
                  </div>
                  <div style={{ textAlign: "center", margin: "20px auto" }}>
                    Or Copy link
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <input
                      disabled
                      value={this.state.shareLink}
                      style={{
                        padding: "10px",
                        paddingRight: "100px",
                        width: "200px",
                      }}
                    />
                    <button
                      style={{
                        marginLeft: "-100px",
                        width: "100px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(this.state.shareLink);
                        toast.success("Link Copied");
                      }}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = {
  textareaInput: {
    border: "1px solid black",
    padding: "10px",
    borderRadius: "5px",
  },
  customTextarea: {
    border: "none",
    resize: "none",
    outline: 0,
    minHeight: "120px",
    width: "100%",
  },
  attachmentRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ["@media screen and (max-width: 460px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "inline",
    }
  },
  attachments: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    columnGap: "15px",
    marginTop: "15px",
    ["@media screen and (max-width: 460px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "inline-flex",
      flexFlow: "row wrap",
    }
    },
  
  commentsList: {
    display: "grid",
    gridTemplateColumns: "0.2fr auto",
    alignItems: "start",
    columnGap: "10px",
    margin: "20px 0",
    "& > .userDataImg img": {
      height: "60px",
      width: "60px",
      borderRadius: "40px",
    },
    ["@media screen and (max-width:620px)"]: {
      display: "inline-flex",
      flexFlow: "row wrap"
    },
  },
  commentData: {
    display: "grid",
    gridTemplateColumns: "auto 0.2fr",
    alignItems: "start",
    ["@media screen and (max-width: 600px)"]:{
      display: "inline",
    }
  },
  audioStyle: {
    width: "50%",
    ["@media screen and (max-width:460px)"]:{
      width: "100%"
    }
  }
  
};
export default withStyles(useStyles)(DesignViewByUser);
// Customizable Area End
