import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider, { Settings } from "react-slick";
import AddIcon from "@material-ui/icons/Add";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import StarIcon from "@material-ui/icons/Star";
import FavoriteIcon from "@material-ui/icons/Favorite";
//import { ApprovalFilterByCurrentUserOptions } from "@microsoft/microsoft-graph-types";
import './DesignerListingCollection.web.css';
import clsx from 'clsx';
import DezinerMedia from "../../../components/src/DezinerImage.web";
import { Design } from "../../../components/src/api-types";
import { statisticsFormatter } from "../../../components/src/utils";

declare var window: any;

interface props {
  createPost?: any;
  collectionId?: number;
  navigation?: any;
  designs?: Design[];
  allowAddition?: boolean;
}

interface state {
  width?: any;
  height?: any;
  slidesToShow?: any;
  imageLength?: any;
  slideImgWidth: number,
  slideImgHeight: number,
}



const SLIDER_SETTINGS: Settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  vertical: false,
  speed: 500,
  //slidesToShow: 4, // taken from state
  slidesToScroll: 1,
}

const ASPECT_RATIO = 200 / 150;

class DesignListingCollection extends React.Component<props, state> {

  constructor(props: any) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      slidesToShow: 4,
      imageLength: 0,
      slideImgWidth: 0,
      slideImgHeight: 0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {

    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    setTimeout(() => {
      let imgElemnts: HTMLCollectionOf<any> = document.getElementsByClassName('deziner-img');
      let imgWidth = imgElemnts?.[0]?.offsetWidth ?? 0;
      if (imgWidth) {
        let slideImgHeight = imgWidth * 1 / ASPECT_RATIO;
        this.setState({ slideImgWidth: imgWidth, slideImgHeight });
      }
    }, 50);

    let slidesToShow = 4;

    // desktop
    if (screenWidth >= 1490) {
      slidesToShow = 5;
    } else if (screenWidth >= 1220) {
      slidesToShow = 4;
    } else if (screenWidth >= 1010) {
      slidesToShow = 3;
    } else {
      slidesToShow = 2;
    }

    // tab
    if (screenWidth < 768) {
      if (screenWidth >= 745) {
        slidesToShow = 3;
      } else if (screenWidth >= 550) {
        slidesToShow = 2;
      } else {
        slidesToShow = 1;
      }
    }

    this.setState({
      slidesToShow,
      imageLength: slidesToShow,
      width: screenWidth,
      height: screenHeight
    });
    // this.state.width >= 1420
    //   ? this.setState({ slidesToShow: 4, imageLength: 4 })
    //   : this.state.width >= 1080
    //     ? this.setState({ slidesToShow: 3, imageLength: 2 })
    //     : this.state.width >= 820
    //       ? this.setState({ slidesToShow: 2, imageLength: 1 })
    //       : this.state.width >= 320
    //         ? this.setState({ slidesToShow: 2, imageLength: 0 })
    //         : null;
  }


  render() {

    const { designs, navigation, collectionId, createPost, allowAddition } = this.props;
    const { slideImgWidth, slideImgHeight, width } = this.state;


    let posts: any[] = [];

    const EMPTY_CARD = <PostImageCard
      // @ts-ignore
      designData={{ id: 0 }}
      navigate={navigation.navigate}
      collectionId={collectionId}
      createPost={createPost}
      imgHeight={slideImgHeight}
    />;

    posts = allowAddition ? [EMPTY_CARD] : []

    posts = posts.concat(designs?.map(({ id, attributes }: Design) => (
      <PostImageCard
        key={id}
        designData={attributes}
        navigate={navigation.navigate}
        collectionId={collectionId}
        createPost={createPost}
        imgHeight={slideImgHeight}
      />
    )));

    if (allowAddition) {
      let missingCards = 5 - posts.length;
      if (missingCards > 0) {
        posts = posts.concat(Array(missingCards).fill(0).map(() => EMPTY_CARD));
      }
    }


    const coverMediaURL = designs?.[0]?.attributes?.image_url ?? '';
    const coverMediaType = designs?.[0]?.attributes?.main_image_type ?? 'image/';
    const coverImageAlt = designs?.[0]?.attributes?.title ?? '';
    const coverWatermark = designs?.[0]?.attributes?.watermark_url ?? '';

    const watermarkHeight = parseFloat(designs?.[0]?.attributes?.height ?? '0');
    const watermarkWidth = parseFloat(designs?.[0]?.attributes?.width ?? '0');


    let collectonCoverHeight = slideImgHeight || 150;

    return (
      <div
        className="collection-container"
        style={{
          gridTemplateColumns: `${slideImgWidth ? slideImgWidth + 3 : 250}px calc(100vw - 380px)`,
        }}
      >

        <CollectionCover
          height={collectonCoverHeight}
          watermarkWidth={watermarkWidth}
          watermarkHeight={watermarkHeight}
          mediaWaterMark={coverWatermark}
          mediaURL={coverMediaURL}
          mediaType={coverMediaType}
          alt={coverImageAlt}
        />


        <div
          className="collection-slider"
        >
          <Slider {...SLIDER_SETTINGS} slidesToShow={this.state.slidesToShow}>
            {posts}
          </Slider>
        </div>

      </div>
    );
  }
}


//////////////// 

const useCoverStyles = makeStyles({
  collectionCover: {
    position: 'relative',
    background: "white",
    height: "150px",
    width: "100%",
    borderRadius: "15px",
    cursor: "pointer",
    border: "2px solid black",
    ["@media screen and (max-width: 768px)"]: {
      width: "50vw !important",
      height: "calc(50vw * 0.75) !important"
    },
  },
  collectionCoverEmpty: {
    background: "#F3F4F6",
    borderRadius: "15px",
    height: "150px",
    width: "100%",
    position: "relative",
    top: "-10px",
    right: "-10px",
    ["@media screen and (max-width: 768px)"]: {
      width: "50vw !important",
      height: "calc(50vw * 0.75) !important"
    },
  },
  collectionCoverMedia: {
    height: "150px",
    width: "100%",
    borderRadius: "15px",

    ["@media screen and (max-width: 768px)"]: {
      width: "50vw !important",
      height: "calc(50vw * 0.75) !important"
    },

  },
  collectionCoverMediaContainer: {
    position: "relative",
    top: "-10px",
    right: "-10px",
    borderRadius: "15px",

    height: "150px",
    width: "100%",

    ["@media screen and (max-width: 768px)"]: {
      width: "50vw !important",
      height: "calc(50vw * 0.75) !important"
    },
  },
});

function CollectionCover({
  height,
  mediaURL,
  mediaType,
  mediaWaterMark,
  alt,
  watermarkWidth,
  watermarkHeight,
}: {
  height: number,
  mediaURL: string,
  mediaWaterMark: string,
  mediaType: string,
  alt: string,
  watermarkWidth: number,
  watermarkHeight: number,
}) {

  const classes = useCoverStyles();

  return (
    <div
      className={classes.collectionCover}
      style={{ height: `${height}px`, }}
    >

      {
        mediaURL ?
          <DezinerMedia
            mediaType={mediaType?.includes('video/') ? 'video' : 'image'}
            className={classes.collectionCoverMedia}
            containerClassName={classes.collectionCoverMediaContainer}
            style={{ height: `${height}px` }}
            containerStyles={{ height: `${height}px` }}
            src={mediaURL}
            alt={alt}
            watermarkURL={mediaWaterMark}
            watermarkWidth={watermarkWidth}
            watermarkHeight={watermarkHeight}
          />
          :
          <div
            style={{ height: `${height}px` }}
            className={classes.collectionCoverEmpty}
          />
      }
    </div>
  );
}












function PostImageCard({
  designData,
  createPost,
  collectionId,
  navigate,
  imgHeight,
}: {
  designData: Design['attributes'],
  createPost: any,
  collectionId: any,
  navigate: any,
  imgHeight: number,
}) {


  const {
    id, title, like_count,
    inspired_count, rating_count, image_url, privacy, main_image_type,
    watermark_url, width, height,
  } = designData;

  function clickHandler() {

    if (!image_url && collectionId > 0) {
      createPost(collectionId);
    } else {
      if (localStorage.getItem("currentUserRole") === "User") {
        navigate("DesignViewByUser", {
          id,
        });
      } else {
        navigate("DesignerDesignView", {
          id,
        });
      }
    }
  }



  return (
    <div
      className="post-image-card"
      style={{ position: "relative", height: `${imgHeight || 150}px`,  }}
      onClick={clickHandler}
    >

      {
        image_url ?
          <DezinerMedia
            mediaType={main_image_type?.includes('video/') ? 'video' : 'image'}
            style={{ height: `${imgHeight || 150}px` }}
            className="deziner-img"
            src={image_url}
            alt={title}
            watermarkURL={watermark_url}
            watermarkWidth={Number(width || 100)}
            watermarkHeight={Number(height || 100)}
          />
          :
          <AddIcon style={{ color: '#CBCBCB' }} fontSize="large" />
      }

      {
        image_url && title && privacy ?
          <div className="post-image-card-overlay">
            <h4>{title}</h4>
            <div className="post-image-card-details">
              <p>{privacy}</p>
              <div>
                <EmojiObjectsIcon style={{ fontSize: 14 }} />
                <span> {statisticsFormatter(inspired_count)} </span>
              </div>
              <div>
                <StarIcon style={{ fontSize: 14 }} />
                <span> {statisticsFormatter(rating_count)} </span>
              </div>
              <div>
                <FavoriteIcon style={{ fontSize: 14 }} />{" "}
                <span> {statisticsFormatter(like_count)} </span>
              </div>
            </div>
          </div>
          :
          null
      }

    </div>
  );
}





export default DesignListingCollection;
