import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import NotificationsIcon from "@material-ui/icons/Notifications";
import Select from "@material-ui/core/Select";
import Hidden from '@material-ui/core/Hidden';
import ChatIcon from "@material-ui/icons/Chat";
import SearchIcon from "@material-ui/icons/Search";
import { dezinerKnotLogo } from '../../blocks/landingpage/src/assets';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider, Popover as MaterialPopover, Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { trending_up } from "../../blocks/catalogue/src/assets";
import DezinerButton from './Button.web';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import { navigateTo } from './store/navigation/navigation.action';
import { fetchProfile } from './store/profile/profile.action';
import { useIsLoggedIn, useIsUser, useMyProfile, useNavigate } from './hooks';
import { navigateToSaga } from './store/navigation/navigation.effec';

const DEFAULT_PROFILE_IMAGE = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png';


const useTopNavStyles = makeStyles({
  selectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSelect-icon': {
      color: 'white !important',
    }
  },
});



const Popover = withStyles({
  root: {

  },
  paper: {

  },
})(MaterialPopover);

const StyledMenuItem = withStyles({
  root: {
    color: '#363636',
    '&:focus': {
      backgroundColor: '#ddd',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#363636'
      },
    },
  },
})(MenuItem);


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

interface DesignerInfo {
  currentClubName?: string,
  currentGroupName?: string,
  clubClickHandler?: () => void,
  groupClickHandler?: () => void,
  promoteHandler?: () => void,
  logoutHandler?: () => void,
  editProfileHandler?: () => void,
  onMySelfClickHandler?: () => void,
}


interface DezinerTopNavProps {
  profileImage?: string,
  color?: string,

  onLogoClick?: () => void,
  onNotificationClick?: () => void,
  onChatClick?: () => void,
  onSearchClick?: () => void,

  hideAvatarMenu?: boolean,

  userInfo?: {
    onProfileClick?: () => void,
  },

  designerInfo?: DesignerInfo,

  isLoggedIn?: boolean,
  onSignInAsDesigner?: () => void,
  onSignInAsUser?: () => void,

  role?: "User" | "Designer",
}

const EMPTY_FUNC = () => { };

export function ForumTopNav() {

  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  const profileQuery = useMyProfile();
  const profileAttribues = profileQuery?.data?.data?.attributes;
  const isUser = useIsUser();

  if (!isLoggedIn) {
    return (<NotLoggedInHeader
      onLogoClick={() => navigate('LandingPage')}
      onSignInAsDesigner={() => navigate('EmailAccountLoginBlock')}
      onSignInAsUser={() => navigate('EmailAccountLoginBlock')}
    />);
  }

  return (
    <AppBar position="static" color="primary">
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <Grid container>
          <Link to="/CommunityForum" style={{ color: 'inherit' }}>
            <Typography style={{ fontWeight: 'bold', marginRight: '20px' }}>
              {'Forum'}
            </Typography>
          </Link>
          <Typography style={{ fontWeight: 'bold', marginRight: '20px' }}>
            {'Community'}
          </Typography>
        </Grid>

        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className="white-svg"
          style={{
            maxWidth: '160px',
            cursor: 'pointer',
          }}
          onClick={() => navigate('LandingPage')}
        />

        <Grid container justifyContent="flex-end">

          <Hidden xsDown>
            <IconButton onClick={EMPTY_FUNC} color="inherit">
              <SearchIcon />
            </IconButton>

            <IconButton onClick={EMPTY_FUNC} color="inherit">
              <NotificationsIcon />
            </IconButton>

            <IconButton onClick={EMPTY_FUNC} color="inherit">
              <ChatIcon />
            </IconButton>
          </Hidden>


          <ProfileAvatar
            image={profileAttribues?.profile_thumbnail_url ?? ''}
            color={profileAttribues?.color ?? ''}
            designerInfo={{

            }}
            onClick={() => {
              if (isUser)
                navigate('UserHome')
              else
                navigate(`DesignerHome/${profileAttribues?.id}`)

            }}
            hideMenu={isUser}
          />

        </Grid>

      </Toolbar>
    </AppBar>
  );
}

function NotLoggedInHeader({
  onLogoClick,
  onSignInAsUser,
  onSignInAsDesigner,
}: {
  onLogoClick?: () => void,
  onSignInAsDesigner?: () => void,
  onSignInAsUser?: () => void,
}) {

  return (
    <AppBar position="static" color="primary">
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <DezinerButton
          variant="outlined"
          color="primary"
          rounded
          style={{
            color: 'white',
            borderColor: 'white',
            width: '140px',
            padding: '5px 0px',
          }}
          onClick={onSignInAsDesigner || EMPTY_FUNC}
        >
          {'Sign In | Designer'}
        </DezinerButton>


        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className="white-svg"
          style={{
            maxWidth: '160px',
            cursor: 'pointer',
          }}
          onClick={onLogoClick || EMPTY_FUNC}
        />

        <DezinerButton
          variant="outlined"
          color="primary"
          rounded
          style={{
            color: 'white',
            borderColor: 'white',
            width: '140px',
            padding: '5px 0px',
          }}
          onClick={onSignInAsUser || EMPTY_FUNC}
        >
          {'Sign In | User'}
        </DezinerButton>


      </Toolbar>
    </AppBar>
  );
}



export default function DezinerTopNav(props: DezinerTopNavProps) {

  const {
    profileImage,
    color,
    onLogoClick,
    userInfo,
    designerInfo,
    onChatClick,
    onNotificationClick,
    onSearchClick,
    hideAvatarMenu,
    isLoggedIn,
    onSignInAsDesigner,
    onSignInAsUser,
    role,
  } = props;

  const {
    currentClubName,
    currentGroupName,
    clubClickHandler,
    groupClickHandler,
    onMySelfClickHandler,
  } = designerInfo || {};

  const classes = useTopNavStyles();

  let hasToken = localStorage.getItem('token');

  if (!hasToken && !isLoggedIn) {
    return (
      <NotLoggedInHeader
        onLogoClick={onLogoClick}
        onSignInAsDesigner={onSignInAsDesigner}
        onSignInAsUser={onSignInAsUser}
      />
    );

  }


  return (
    <AppBar position="static" color="primary">
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className="white-svg"
          style={{
            maxWidth: '160px',
            cursor: 'pointer',
          }}
          onClick={onLogoClick || EMPTY_FUNC}
        />

        <Grid container justifyContent="flex-end">

          <Hidden xsDown>
            <IconButton onClick={onSearchClick || EMPTY_FUNC} color="inherit">
              <SearchIcon />
            </IconButton>

            <IconButton onClick={EMPTY_FUNC} color="inherit">
              <NotificationsIcon />
            </IconButton>

            <IconButton onClick={onChatClick || EMPTY_FUNC} color="inherit">
              <ChatIcon />
            </IconButton>
          </Hidden>

          {
            role != "User" ?
              <div className={classes.selectContainer}>
                <MenuItem
                  value="Myself"
                  onClick={onMySelfClickHandler || EMPTY_FUNC}
                >
                  {'Myself'}
                </MenuItem>
              </div>
              :
              null
          }

          {/*
            role != "User" ?
              <div className={classes.selectContainer}>
                <Select
                  style={{ backgroundColor: "black", color: "white" }}
                  value={currentClubName ? "Club" : currentGroupName ? "Group" : "Myself"}
                >
                  <MenuItem
                    value="Myself"
                    onClick={onMySelfClickHandler || EMPTY_FUNC}
                  >
                    {'Myself'}
                  </MenuItem>
                  <MenuItem
                    value="Club"
                    //onClick={clubClickHandler || EMPTY_FUNC}
                  >
                    {currentClubName || "Club"}
                  </MenuItem>
                  <MenuItem
                    value="Group"
                    //onClick={groupClickHandler || EMPTY_FUNC}
                  >
                    {currentGroupName || "Group"}
                  </MenuItem>
                </Select>
              </div>
              :
              null
          */}

          <ProfileAvatar
            image={profileImage}
            color={color}
            designerInfo={designerInfo}
            onClick={role == "User" && userInfo?.onProfileClick ? userInfo?.onProfileClick : EMPTY_FUNC}
            hideMenu={hideAvatarMenu}
          />

        </Grid>

      </Toolbar>
    </AppBar>
  );
}


/////////////


const useProfileAvatarStyles = makeStyles({
  profileImage: {
    width: '45px',
    height: '45px',
    borderRadius: '40%',
    objectFit: 'cover',
  },
  colorCircle: {
    height: "45px",
    width: "45px",
    borderRadius: "40%",
  },
});




function ProfileAvatar({
  image,
  color,
  designerInfo,
  onClick,
  hideMenu,
}: {
  image: any,
  color: any,
  onClick?: () => void,
  hideMenu?: boolean,
  designerInfo?: DesignerInfo,
}) {

  const {
    currentGroupName,
    currentClubName,
    promoteHandler,
    logoutHandler,
    editProfileHandler,
  } = designerInfo || {};

  const classes = useProfileAvatarStyles();

  const [popoverElement, setPopoverElement] = React.useState<HTMLButtonElement | null>(null);

  function handlePopoverClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (!hideMenu)
      setPopoverElement(event.currentTarget);

    onClick && onClick();
  };

  function handlePopoverClose() {
    setPopoverElement(null);
  };

  const isPopoverOpen = Boolean(popoverElement);
  const popoverId = isPopoverOpen ? 'avatar-popover' : undefined;


  const logoutClickHandler = (e: any) => {
    e.preventDefault();
    logoutHandler && logoutHandler();
  };




  return (
    <div>
      <IconButton onClick={handlePopoverClick}>
        {
          image ?
            <img
              className={classes.profileImage}
              src={image}
              alt="profile image"
            />
            : color ?
              <div
                className={classes.colorCircle}
                style={{
                  background: color || '#e1e1e1',
                }}
              />
              :
              <img
                className={classes.profileImage}
                src={DEFAULT_PROFILE_IMAGE}
                alt="profile image"
              />
        }
      </IconButton>

      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledMenu
          id={popoverId}
          anchorEl={popoverElement}
          keepMounted
          open={isPopoverOpen}
          onClose={handlePopoverClose}
        >


          <StyledMenuItem>
            <ListItemText
              onClick={editProfileHandler || EMPTY_FUNC}
              primary={`Edit Profile`} />
          </StyledMenuItem>


          <Divider style={{ margin: '0' }} variant="inset" component="li" />

          <StyledMenuItem onClick={promoteHandler || EMPTY_FUNC}>
            <ListItemIcon style={{ minWidth: '32px' }}>
              <img
                className="icon-img"
                alt="Promote collection"
                src={trending_up}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                currentGroupName || currentClubName
                  ? `Promote ${currentClubName ? "Club" : "Group"}`
                  : "Promote Profile"

              } />
          </StyledMenuItem>

          <Divider style={{ margin: '0' }} variant="inset" component="li" />

          <StyledMenuItem onClick={logoutClickHandler}>
            <ListItemText
              primary={`Logout`} />
          </StyledMenuItem>


        </StyledMenu>


      </Popover>

    </div>
  );

}






