import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Design } from "../../../components/src/api-types";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  usersList: any;
  posts: any;
  profileData: any;
  designerDesigns: any;
  loadingCollections: boolean;
  arrCollection: any;
  profileImgUrl: string;
  loadingDesigns: boolean;
  collectionId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CollectionViewController extends BlockComponent<
  Props,
  S,
  SS
  > {
  landingPageData: string;
  profileMessageId: any;
  designerDesignsId: any;
  collectionMessageId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.onTrendingButtonClick = this.onTrendingButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.addDesign = this.addDesign.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      usersList: {},
      posts: {},
      profileData: {},
      designerDesigns: {
        attributes: {
          name: "",
          design_count: "",
          account: {
            data: {
              attributes: {
                first_name: "",
                profile_thumbnail_url: "",
              },
            },
          },
          designs: {
            data: [],
          },
        },
      },
      collectionId: 0,
      loadingCollections: false,
      loadingDesigns: false,
      arrCollection: {},
      profileImgUrl:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        // PROFILE DATA API RESPONSE
        if (apiRequestCallId === this.profileMessageId) {
          if (responseJson && !responseJson.errors) {
            this.setState({
              profileData: responseJson.data.attributes,
              profileImgUrl: responseJson.data.attributes.profile_url,
            });
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
          }
        }

        // GET DESIGNS API RESPONSE
        if (apiRequestCallId === this.designerDesignsId) {
          if (responseJson && !responseJson.errors) {
            this.setState({
              loadingDesigns: false,
              designerDesigns: responseJson.data,
            });

            const labelId = this.props.navigation.getParam("labelId", "");
            this.getCollections(labelId);

            console.log(responseJson.data);
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
          }
        }

        // COLLECTION LISTING API RESPONSE
        if (apiRequestCallId === this.collectionMessageId) {
          if (responseJson && !responseJson.errors) {
            this.setState({ arrCollection: responseJson.data, loadingCollections: false });
          } else {
            this.setState({ loadingCollections: false });
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
          }
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDesigns();
    this.getProfile();
  }


  componentDidUpdate(prevProps: Props, prevState: S) {

    const prevCollectionId = prevProps.navigation.getParam("collectionId", "");
    const newCollectionId = this.props.navigation.getParam("collectionId", "");

    if (prevCollectionId != newCollectionId) {
      this.getDesigns();
    }
  }

  // Customizable Area Start

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getDesigns(): boolean {
    this.setState({
      loadingDesigns: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};

    const collectionId = this.props.navigation.getParam("collectionId", "");

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.designerGetDesignsApi + "/" + collectionId,
    });

    this.designerDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  addDesign() {
    const labelId = this.state.designerDesigns.attributes.label_id;
    const collectionId = this.props.navigation.getParam("collectionId", "");
    this.props.navigation.navigate("DesignerDesignUpload", {
      collectionId: collectionId,
      labelId: labelId,
      type: "Designer",
      clubId: 0
    });
  }

  onTrendingButtonClick(item: any) {
    console.log(item);
  }

  onEditButtonClick(item: any) {
    console.log(item);
  }

  // HEADER RELATED FUNCTIONS

  getCollections(labelId: number): boolean {
    this.setState({ loadingCollections: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    let apiUrl = configJSON.collectionsApi;
    if (labelId > 0) {
      apiUrl = `${configJSON.collectionsApi}?label_id=${labelId}`;
    }
    const getCollectionData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.collectionMessageId = getCollectionData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getCollectionData.id, getCollectionData);
    return true;
  }

  // Customizable Area End
}
