import React from 'react';
import { CollectionHome, Design } from './api-types';
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withStyles } from "@material-ui/core/styles";
import { CollectionListCard, CollectionListEmptyCard } from './DezinerCollectionListCard.web';
import { CollectionCover } from './DezinerCollectionCover.web';

interface DezinerCollectionListProps {
  collection: CollectionHome,
  onCreate: (params: { collectionId: number }) => void,
  onPin: (params: { collectionId: number }) => void,
  onPromote: (params: { collectionId: number }) => void,
  showAddSlots?: boolean,
  classes?: any,
  onDesignClick?: (design: Design) => void,
  hideCollectionOptions?: boolean,
}

interface DezinerCollectionListState {
  width?: any;
  height?: any;
  slidesToShow?: any;
  imageLength?: any;
  slideImgWidth: number,
  slideImgHeight: number,
}



const SLIDER_SETTINGS: Settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  vertical: false,
  speed: 500,
  //slidesToShow: 4, // taken from state
  slidesToScroll: 1,
}

const ASPECT_RATIO = 200 / 150;


const dezinerCollectionListStyles = {

  collectionContainer: {
    display: "grid",
    alignItems: "end",
    marginBottom: "40px",
    gridGap: "20px",
    ['@media screen and (max-width: 768px)']: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },

  collectionSlider: {
    padding: '0 20px',
    ['@media screen and (max-width: 768px)']: {
      margin: "10px 0",
      maxWidth: "calc(100vw - 100px)",
      width: "calc(100vw - 100px)"
    }
  },

}


class DezinerCollectionListComponent extends React.Component<DezinerCollectionListProps, DezinerCollectionListState>  {


  constructor(props: DezinerCollectionListProps) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      slidesToShow: 4,
      imageLength: 0,
      slideImgWidth: 0,
      slideImgHeight: 0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {

    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    setTimeout(() => {
      let imgElemnts: HTMLCollectionOf<any> = document.getElementsByClassName('deziner-img');
      let imgWidth = imgElemnts?.[0]?.offsetWidth ?? 0;
      if (imgWidth) {
        let slideImgHeight = imgWidth * 1 / ASPECT_RATIO;
        this.setState({ slideImgWidth: imgWidth, slideImgHeight });
      }
    }, 50);

    let slidesToShow = 4;

    // desktop
    if (screenWidth >= 1490) {
      slidesToShow = 5;
    } else if (screenWidth >= 1220) {
      slidesToShow = 4;
    } else if (screenWidth >= 1010) {
      slidesToShow = 3;
    } else {
      slidesToShow = 2;
    }

    // tab
    if (screenWidth < 768) {
      if (screenWidth >= 745) {
        slidesToShow = 3;
      } else if (screenWidth >= 550) {
        slidesToShow = 2;
      } else {
        slidesToShow = 1;
      }
    }

    this.setState({
      slidesToShow,
      imageLength: slidesToShow,
      width: screenWidth,
      height: screenHeight
    });
  }


  render() {

    const {
      onPin,
      onPromote,
      showAddSlots,
      collection,
      onCreate,
      classes,
      onDesignClick,
      hideCollectionOptions,
    } = this.props;
    const { slideImgWidth, slideImgHeight, } = this.state;


    let posts: any[] = [];

    const EMPTY_CARD = <CollectionListEmptyCard
      imgHeight={slideImgHeight}
      onClick={() => onCreate({ collectionId: collection.attributes.id })}
    />;

    posts = showAddSlots ? [EMPTY_CARD] : []

    posts = posts.concat(collection
      ?.attributes
      ?.designs
      ?.data
      ?.map((design: Design) => (
        <CollectionListCard
          key={design.id}
          designData={design}
          collectionId={collection.attributes.id}
          imgHeight={slideImgHeight}
          onClick={() => {
            onDesignClick && onDesignClick(design);
          }}
        />
      )));

    if (showAddSlots) {
      let missingCards = 5 - posts.length;
      if (missingCards > 0) {
        posts = posts.concat(Array(missingCards).fill(0).map(() => EMPTY_CARD));
      }
    }

    const firstDesign = collection?.attributes?.designs?.data?.[0]?.attributes;

    const coverMediaURL = firstDesign?.image_url ?? '';
    const coverMediaType = firstDesign?.main_image_type ?? 'image/';
    const coverImageAlt = firstDesign?.title ?? '';
    const coverWatermark = firstDesign?.watermark_url ?? '';

    const watermarkHeight = parseFloat(firstDesign?.height ?? '0');
    const watermarkWidth = parseFloat(firstDesign?.width ?? '0');


    let collectonCoverHeight = slideImgHeight || 150;

    return (
      <div
        className={classes.collectionContainer}
        style={{
          gridTemplateColumns: `${slideImgWidth ? slideImgWidth + 3 : 250}px calc(100vw - 380px)`,
        }}
      >

        <CollectionCover
          hideOptions={hideCollectionOptions}
          height={collectonCoverHeight}
          watermarkWidth={watermarkWidth}
          watermarkHeight={watermarkHeight}
          mediaWaterMark={coverWatermark}
          mediaURL={coverMediaURL}
          mediaType={coverMediaType}
          alt={coverImageAlt}
          collection={collection}
          onPin={onPin}
          onPromote={onPromote}
        />


        <div
          className={classes.collectionSlider}
        >
          <Slider {...SLIDER_SETTINGS} slidesToShow={this.state.slidesToShow}>
            {posts}
          </Slider>
        </div>

      </div>
    );
  }
}


export const DezinerCollectionList = withStyles(dezinerCollectionListStyles)(DezinerCollectionListComponent);

