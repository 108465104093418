import React from "react";
// Customizable Area Start
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController.web";
import { DezinerCollectionList } from "../../../components/src/DezinerCollectionList.web";
import { CollectionHome } from "../../../components/src/api-types";
import { useDesignerCollections, useNavigate, usePinCollection } from "../../../components/src/hooks";
import { useParams } from "../../../components/src/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../components/src/store/store";
import DezinerSpinner from "../../../components/src/DezinerSpinner.web";
import CreateCollectionForm from "../../../components/src/CreateCollectionForm.web";


class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End


  render() {

    return (
      <div style={{ padding: '0 40px', minHeight: '50vh', }}>

        <CreateCollectionForm />

        <CatalogueContent />

      </div>
    );
  }
}




function CatalogueContent() {

  const params = useParams();
  const designerId = parseInt(params.designerId);

  const { label_id }: { label_id?: string } = useSelector((state: RootState) => state.router.location.query);
  const labelId = parseInt(label_id || '0');

  const collectionsQuery = useDesignerCollections({ labelId, designerId, category: 'collections' });

  const loading = collectionsQuery?.isLoading ?? false;
  const designs = collectionsQuery?.data?.data ?? [];

  const navigate = useNavigate();
  const { mutate: pinCollection } = usePinCollection({ designerId, labelId });


  return (
    <div>
      <div className="collectionComponent">
        {
          !loading ?
            (designs?.length ?? 0) > 0 ? (
              (designs as CollectionHome[])?.map((item) =>
                <DezinerCollectionList
                  key={item.id}
                  onDesignClick={(design) => {
                    navigate(`DesignView/${design.id}`);
                  }}
                  collection={item}
                  onCreate={({ collectionId }) => {
                    navigate(`DesignerDesignUpload/${labelId}/${item.attributes.id}/designer/0`);
                  }}
                  onPin={() => pinCollection({ pin: !item.attributes.pined, collectionId: item.attributes.id })}
                  onPromote={() => { }}
                  showAddSlots
                />
              )
            ) :
              (
                <div
                  style={{
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h2>No Data</h2>
                </div>
              ) : (
              <DezinerSpinner />
            )}
      </div>
    </div>
  );
}



// Customizable Area Start
export default Catalogue;
// Customizable Area End
