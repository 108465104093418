import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MaterialAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';

const Accordion = withStyles({
  root: {
    maxWidth: "100%",
    boxShadow: "none",
    border: "1px solid #e8e8e8",
    borderRadius: "10px !important",
    marginBottom: '20px',
    padding: '10px 20px',
    '&::before': {
      display: 'none',
    }
  }
})(MaterialAccordion);

export default function DezinerAccordion({
  title,
  description,
  renderMarkdown,
}: {
  title: string,
  description: string,
  renderMarkdown?: boolean,
}) {

  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(x => !x)}>
      <AccordionSummary
        expandIcon={expanded ? <MinusIcon color="primary" /> : <AddIcon color="primary" />}
      >
        <Typography variant="body1" style={{ fontSize: '1.1rem', color: '#101010' }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ color: '#979797' }}>
        <div>
          {
            renderMarkdown ?
              <ReactMarkdown components={{
                img: (props: any) => <img {...props} style={{ ...props.style, maxWidth: '20px' }} />
              }}>
                {description}
              </ReactMarkdown>
              :
              description
          }
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
