import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { HorizontalSpacer } from './Spacer.web';
import {
  colBlack as collectionsIcon,
  likes as likedIcon,
  promoteBlack as promotedIcon,
  inspireBlackIcon as inspiredIcon,
  star as ratedIcon,
  designsBlack as designsIcon
} from "../../blocks/landingpage/src/assets";
import { DesignCategory } from './api-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import { fetchProfile } from './store/profile/profile.action';
import { setActiveCategory } from './store/designs/designs.action';

const DEFAULT_PROFILE_IMAGE = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '360px',
    maxHeight: '360px',
    overflow: 'hidden',
  },
  patterBackground: {
    opacity: 0.1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -11,

    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(30deg, #303030 12%, transparent 12.5%, transparent 87%, #303030 87.5%, #303030), linear-gradient(150deg, #303030 12%, transparent 12.5%, transparent 87%, #303030 87.5%, #303030), linear-gradient(30deg, #303030 12%, transparent 12.5%, transparent 87%, #303030 87.5%, #303030), linear-gradient(150deg, #303030 12%, transparent 12.5%, transparent 87%, #303030 87.5%, #303030), linear-gradient(60deg, #30303077 25%, transparent 25.5%, transparent 75%, #30303077 75%, #30303077), linear-gradient(60deg, #30303077 25%, transparent 25.5%, transparent 75%, #30303077 75%, #30303077)',
    backgroundSize: '68px 119px',
    backgroundPosition: '0 0, 0 0, 34px 60px, 34px 60px, 0 0, 34px 60px',
  },
  coverImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    zIndex: -10,
  },
  contentContainer: {
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '60%',
    margin: '0 auto',
    ['@media screen and (max-width: 1100px)']: {
      maxWidth: '80%',
    },
    ['@media screen and (max-width: 768px)']: {
      maxWidth: '80%',
    },
    ['@media screen and (max-width: 400px)']: {
      maxWidth: '90%',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  circleContainer: {
    position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
  },
  circle1: {
    background: 'white',
    //width: '50%',
    //height: '160%',
    borderRadius: '50%',
    width: '670px',
    height: '570px',
    minWidth: '670px',
    minHeight: '570px',
  },
  circle2: {
    background: '#fafafa',
    //width: '60%',
    //height: '168%',
    borderRadius: '50%',
    opacity: 0.8,
    width: '835px',
    height: '610px',
    minWidth: '835px',
    minHeight: '610px',
  },
  circle3: {
    background: '#f3f3f3',
    //width: '70%',
    //height: '180%',
    //position: 'absolute',
    //top: '-50%',
    //left: '-15%',
    borderRadius: '50%',
    zIndex: -9,
    opacity: 0.6,
    width: '955px',
    height: '670px',
    minWidth: '955px',
    minHeight: '670px',
  },
  iconBtn: {

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    maxHeight: '40px',
  },
  iconImg: {
    //width: '25px',
  },
  statsValue: {
    margin: '0 10px',
    fontWeight: 'bold',
  },
  divider: {
    height: '25px'
  },
  profileImg: {
    width: '140px',
    height: '140px',
    borderRadius: '40%',
    maxWidth: '140px',
    objectFit: 'cover',
  },
  loadingText: {
    width: '100%', height: '45px'
  },
  titleText: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  clubTitle: {
    background: 'black',
    color: 'white',
    borderRadius: '30px',
    padding: '0 40px',
  },
  groupTitle: {
    border: '2px solid black',
    borderRadius: '30px',
    padding: '0 40px',
  },
  subTitleText: {
    fontSize: '1.4rem', flexGrow: 1,
  },
  followersText: {
    fontSize: '1.1rem',
  },
  activeText: {
    fontWeight: 'bold',
    color: 'black',
  },
});


interface StatisticsOptions { name: DesignCategory | 'following' | 'followers', isActive?: boolean, value: number, hide?: boolean, }


const iconsMapper: { [key in DesignCategory | any]: any } = {
  collections: collectionsIcon,
  designs: designsIcon,
  liked: likedIcon,
  rated: ratedIcon,
  promoted: promotedIcon,
  inspired: inspiredIcon,
  free: 'FD:',
  paid: 'PD:',
  following: '',
  followers: '',
}

interface DezinerHeaderProps {
  profileImageURL: string,
  coverImageURL: string,
  title: string,
  subTitle?: string,
  profileStatistics?: StatisticsOptions[],
  vertical?: boolean,
  loading?: boolean,
  variant?: 'group' | 'club',
  onClick?: (key: DesignCategory) => void,
  fallbackColor?: string | null,
}



export default function DezinerHeader(props: DezinerHeaderProps) {

  const {
    profileImageURL,
    coverImageURL,
    title,
    subTitle,
    profileStatistics,
    vertical,
    loading,
    variant,
    onClick,
    fallbackColor,
  } = props;


  const classes = useStyles();

  let filteredStats = profileStatistics
    ?.filter(({ name, hide }) => name != 'followers' && name != 'following' && !hide)
    ?? [];

  let followers = profileStatistics?.find(({ name }) => name == 'followers');
  let following = profileStatistics?.find(({ name }) => name == 'following');


  const HeaderContent = (
    <div className={classes.content}>

      <Grid container
        direction={vertical ? 'column' : 'row'}
        wrap="nowrap"
        style={{
          maxWidth: '440px',
          margin: '0 auto',
        }}
      >
        <Grid item xs={vertical ? 12 : 5} container justifyContent="center" alignItems="center">
          {
            loading ?
              <Skeleton variant="circle" width={150} height={150} style={{ borderRadius: '40%' }} />
              :
              profileImageURL ?
                <img src={profileImageURL} alt={title} className={classes.profileImg} />
                :
                fallbackColor ?
                  <div style={{ background: fallbackColor }} className={classes.profileImg} />
                  :
                  <img src={DEFAULT_PROFILE_IMAGE} alt={title} className={classes.profileImg} />

          }
        </Grid>
        <Grid item xs={vertical ? 12 : 7}
          container
          direction="column"
          justifyContent={subTitle ? "flex-start" : "center"}
          alignItems={vertical ? "center" : "flex-start"}>

          {
            loading ?
              <Skeleton variant="text" className={classes.loadingText} />
              :
              <Typography className={clsx(classes.titleText, {
                [classes.clubTitle]: variant == 'club',
                [classes.groupTitle]: variant == 'group',
              })}>
                {title}
              </Typography>
          }
          {
            loading ?
              <Skeleton variant="text" className={classes.loadingText} />
              :
              subTitle ?
                <Typography
                  className={classes.subTitleText}
                  style={{ alignSelf: vertical ? "center" : 'flex-start' }}
                >
                  {subTitle}
                </Typography>
                :
                null
          }

          {
            following || followers ?
              loading ?
                <Skeleton variant="text" className={classes.loadingText} />
                :
                <Grid container justifyContent={vertical ? "center" : "flex-start"}>
                  <Typography className={classes.followersText}> {`${followers?.value ?? 0} Followers`} </Typography>
                  <HorizontalSpacer />
                  <Typography className={classes.followersText}> {`${following?.value ?? 0} Following`} </Typography>
                </Grid>
              :
              null
          }

        </Grid>
      </Grid>

      {
        !loading && filteredStats.length ?
          <Grid container justifyContent="center" alignItems="center" style={{ margin: '20px 0' }}>
            {
              filteredStats
                ?.map(({ name, value, isActive }, index) => (
                  <Fragment key={name}>
                    <IconButton title={name} onClick={onClick ? () => onClick(name as DesignCategory) : () => { }} className={classes.iconBtn}>
                      {
                        name == 'free' || name == 'paid' ?
                          <Typography variant="body1" className={clsx({
                            [classes.activeText]: isActive
                          })}>
                            <b>{iconsMapper?.[name] ?? ''}</b>
                          </Typography>
                          :
                          <img src={iconsMapper?.[name] ?? ''}
                            alt={name}
                            className={clsx(classes.iconImg, {
                              'gray-svg': !isActive,
                              'black-svg': isActive
                            })}
                          />
                      }
                      <Typography className={clsx(classes.statsValue, {
                        'gray-svg': !isActive,
                        'black-svg': isActive
                      })}>
                        {value}
                      </Typography>
                    </IconButton>
                    {
                      index < (filteredStats.length - 1) ?
                        <Divider orientation="vertical" className={classes.divider} />
                        :
                        null
                    }
                  </Fragment>
                ))
            }
          </Grid>
          :
          null
      }

    </div>
  );




  return (
    <div className={classes.root}>

      <div className={classes.patterBackground} />

      {
        coverImageURL ?
          <img src={coverImageURL} className={classes.coverImage} alt="" />
          :
          null
      }

      <div
        className={classes.circleContainer}
        style={{ zIndex: -7 }}>
        <div className={classes.circle1} />
      </div>
      <div
        className={classes.circleContainer}
        style={{ zIndex: -8 }}>
        <div className={classes.circle2} />
      </div>
      <div
        className={classes.circleContainer}
        style={{ zIndex: -9 }}>
        <div className={classes.circle3} />
      </div>

      <div className={classes.contentContainer}>


        {HeaderContent}

      </div>

    </div>
  );
}



