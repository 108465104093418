import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Button from "./Button.web";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DesignerProfilesSlider from "./DesignersProfilesSlider.web";
import CategoriesFilterButton from "./CategoriesFilterButton.web";
import DezinerButtonGroup from "./DesignsButtonGroupFilters.web";
import DesignCardsWithProfilesResponsiveContainer from "./DesignsCardsContainer.web";
import DezinerSpinner from './DezinerSpinner.web';
import DezinerDropdown from './DezinerDropdown.web';
import { useDispatch } from "react-redux";
import { navigateTo } from "./store/navigation/navigation.action";
import { LandingPageDesign } from "./api-types";
import { useCategories, useIsLoggedIn, useIsUser, useLandingPageData } from "./hooks";
import { clubIcon, groupIcon, penIcon, forum, } from '../../blocks/landingpage/src/assets';




function sortDesigns(designs: LandingPageDesign[], sortProperty: keyof LandingPageDesign['attributes'] | undefined) {

  if (sortProperty) {
    let designsCopy = designs.slice();
    designsCopy.sort((a, b) => {

      let _a = a?.attributes?.[sortProperty as keyof LandingPageDesign['attributes']] ?? '';
      let _b = b?.attributes?.[sortProperty as keyof LandingPageDesign['attributes']] ?? '';

      if ((typeof a == 'string' && _a == '') || (typeof b == 'string' && _b == '')) return 0;
      if (_a == _b) return 0;

      return _a < _b ? 1 : -1;
    });

    return designsCopy;
  }


  return designs;
}




const useStyles = makeStyles({
  filtersContainer: {
    padding: '40px 50px',
    ['@media screen and (max-width: 900px)']: {
      padding: '10px',
      flexDirection: 'column',
      '& > *': {
        margin: '10px 0'
      }
    }
  }
});



const SortPropertyMapper: {
  [key: string]: keyof LandingPageDesign['attributes']
} = {
  "most_liked": "like_count",
  "most_viewed": "view_count",
  "most_recent": "created_at",
}




export default function LandingPageContent() {

  const classes = useStyles();

  const [selectedSortOption, setSelectedSortOption] = useState('');

  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedFilter, setSelectedFilter] = useState('All');

  const categoriesQuery = useCategories();

  const landingPageQuery = useLandingPageData({
    selectedFilter,
    selectedCategories,
  });


  const isLoggedIn = useIsLoggedIn();
  const isUser = useIsUser();

  const selectedGroupOrClub = (
    selectedFilter.toLowerCase() == 'club' ||
    selectedFilter.toLowerCase() == 'group'
  );


  const dispatch = useDispatch();


  function categoryToggleHandler(id: number) {
    let newSubCategories = selectedCategories.slice();

    let exist = selectedCategories.includes(id);
    if (exist)
      newSubCategories = newSubCategories.filter(x => x != id);
    else
      newSubCategories = newSubCategories.concat(id);

    setSelectedCategories(newSubCategories);
  }



  const allDesigns = sortDesigns(
    (landingPageQuery?.data?.pages ?? [])?.map(group => group.data?.designes?.data)?.flat() ?? [],
    SortPropertyMapper[selectedSortOption]
  );

  const allDesigners = (landingPageQuery?.data?.pages ?? [])?.map(group => group?.data?.designers?.data ?? [])?.flat() ?? [];



  return (
    <div>

      <Grid
        className={classes.filtersContainer}
        container
        justifyContent="space-between"
        alignItems="center">

        <DezinerDropdown
          position="left"
          title={"Sort"}
          options={[
            { key: 'most_viewed', title: 'Most Viewed' },
            { key: 'most_inspired', title: 'Most Inspired' },
            { key: 'most_liked', title: 'Most Liked' },
            { key: 'most_recent', title: 'Most Recent' },
          ]}
          selectedOption={selectedSortOption}
          onClick={setSelectedSortOption}
        />

        <DezinerButtonGroup
          noBorders
          options={[
            {
              key: 'Forum',
              icon: forum,
              active: selectedFilter.toLowerCase() == 'forum',
              title: 'Forum'
            },
            {
              key: 'Designer',
              icon: penIcon,
              active: selectedFilter.toLowerCase() == 'designer',
              title: 'Designer'
            },
            {
              key: 'Group',
              icon: groupIcon,
              active: selectedFilter.toLowerCase() == 'group',
              title: 'Group'
            },
            {
              key: 'Club',
              icon: clubIcon,
              active: selectedFilter.toLowerCase() == 'club',
              title: 'Club'
            },
            {
              key: 'All',
              icon: null,
              active: selectedFilter.toLowerCase() == 'all',
              title: 'All'
            },
          ]}
          onClick={(filter) => {
            if (filter == 'Forum') {
              dispatch(navigateTo({
                url: 'CommunityForum'
              }));
            }
            else
              setSelectedFilter(filter);

          }}
        />



        <CategoriesFilterButton
          categories={categoriesQuery?.data?.data ?? []}
          onToggle={categoryToggleHandler}
          selectedCategoriesIds={selectedCategories}
          onClear={() => {
            setSelectedCategories([]);
          }}
        />

      </Grid>

      <DesignerProfilesSlider
        members={allDesigners}
        onClick={(id: any) => {

          if (isLoggedIn)
            dispatch(navigateTo({ url: `DesignerHome/${id}` }));
        }}
      />

      {
        selectedGroupOrClub ?
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="body1" style={{
              fontSize: '2rem',
              color: '#4a4a4a',
              padding: '100px 0',
            }}>
              {'Something exciting coming up 🎉'}
            </Typography>
          </Grid>
          :
          <DesignCardsWithProfilesResponsiveContainer
            navigate={(url) => dispatch(navigateTo({ url }))}
            data={allDesigns}
            onShareClick={() => { }}
            onInspireClick={() => { }}
            onSaveClick={() => { }}
          />
      }

      {
        !selectedCategories.length && !landingPageQuery.isFetching && !allDesigns?.length ?
          <Typography style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            padding: '40px 0',
            color: 'gray',
          }}>
            {'Sorry, No data found.'}
          </Typography>
          :
          null
      }

      {
        selectedCategories.length && !landingPageQuery.isFetching && !allDesigns?.length ?
          <Typography style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            padding: '40px 0',
            color: 'gray',
          }}>
            {'Sorry, No data found for the selected filters. Please change the selected filter to search for new designs.'}
          </Typography>
          :
          null
      }

      {
        selectedGroupOrClub ? null :
          landingPageQuery?.isFetching ?
            <DezinerSpinner />
            :
            !landingPageQuery.hasNextPage ? null :
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    landingPageQuery?.fetchNextPage()
                  }}
                  rounded
                  style={{ margin: '50px 0' }}
                >
                  {"Load More"}
                </Button>
              </Grid>
      }


    </div>
  );
}


