import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
// Customizable Area End

import ClubCreateCollectionController, {
  Props,
} from "./ClubCreateCollectionController.web";
import CollectionListing from "./CollectionListing.web";
import CreateLabelDialog from "../../landingpage/src/CreateLabelDialog.web";

class ClubCreateCollection extends ClubCreateCollectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const {
      designsData,
      loadingDesignData,
      createCollection,
    } = this.props.layoutProps || {};

    return (
      <>
        <div className="main-container">
          <div className='collectionCreationForm'>
            <div className='formIputLeft'>
              <input type="text" value={this.state.newCollectionName} placeholder="Your collection name" onChange={(e) => this.setState({ newCollectionName: e.target.value })} name="collectionName" className='inputField' />
              <Button variant="contained" className='customButton'
                onClick={() => createCollection({ collectionName: this.state.newCollectionName, isPrivate: this.state.privateCollection })}>
                Create
              </Button>
            </div>
            <div className='formInputRight'>
              <FormControlLabel
                labelPlacement="end"
                control={<Checkbox name="private" color="primary" />}
                label="Keep this collection private"
                onChange={(e, checked) => this.setState({ privateCollection: checked })}
              />
            </div>
          </div>
          <div className="collectionComponent">
            {
              !loadingDesignData ?
                (designsData?.collections?.length ?? 0) > 0 ? (
                  designsData?.collections?.map((item) => {
                    return <CollectionListing
                      collection={item.attributes}
                      createPost={this.createPost}
                      navigation={this.props.navigation}
                      allowAddition
                      promoteCollection={() => { }}
                      pinCollection={(collectionId: number, flag: boolean) => this.pinCollection(collectionId, flag)}
                    />
                  })
                ) :
                  (
                    <div
                      style={{
                        display: 'grid',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <h2>No Data</h2>
                    </div>
                  ) : (
                  <div
                    style={{
                      display: 'grid',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
          </div>


        </div>
      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = {};
export default withStyles(useStyles)(ClubCreateCollection);
// Customizable Area End
