import { runInNewContext } from "vm";
import { Profile } from "../../../components/src/api-types";
import { apiCall } from "../../../components/src/Common";
import { appendJsonContentType, defineMessageHandlers } from "../../../components/src/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

const configJson = require('./config');

interface Props {
  navigation: any,
}
interface S {
  verifyFailed: boolean,
}
interface SS { }

export default class VerifyEmailPageController extends BlockComponent<Props, S, SS>{

  verifyTokenMsgId: string;

  constructor(props: Props) {
    super(props);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      verifyFailed: false,
    }
  }

  async componentDidMount() {

    let params = new URLSearchParams(window.location.search)
    let token = params.get('token');

    if (token) {
      this.verifyToken(token)
    } else {
      console.error('we don\'t have a token');
      this.props.navigation.navigate('EmailAccountLoginBlock');
    }
  }

  verifyToken(verifyToken: string) {

    let message = apiCall({
      header: appendJsonContentType({}),
      httpBody: {
        data: { attributes: { token: verifyToken } }
      },
      url: configJson.verifyTokenAPI,
      httpMethod: 'POST',
    });

    this.verifyTokenMsgId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }



  receive(from: string, message: Message) {
    defineMessageHandlers({
      message,
      handlers: {
        [this.verifyTokenMsgId]: {
          onSuccess: (response: { data: Profile, meta: { token: string } }) => {

            let role = response.data.attributes.role || '';

            localStorage.setItem("token", response.meta.token);
            localStorage.setItem("cuid", response.data.id);

            localStorage.setItem("role", role);
            localStorage.setItem("currentUserRole", role);

            if (role.toLowerCase() == 'designer')
              this.props.navigation.navigate('DesignerHome');
            else if (role.toLowerCase() == 'user')
              this.props.navigation.navigate('UserHome');

          },
          onError: (error) => {
            console.error(error);
            this.setState({ verifyFailed: true });
          },
        }
      },
    });
  }


}
