// @ts-nocheck
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import { col, close, cloud, plus, star, wishlist } from "./assets";
import UserProfileCollectionController, {
  Props,
} from "./UserProfileCollectionController.web";
import HeaderWeb from "./Header.web";
// import "./styles.css";
import FooterWeb from "../../catalogue/src/Footer.web";
import HeaderUser from "./HeaderUser.web";
import { toast } from "react-toastify";
import Select from "react-select";
import Slider from 'react-slick'

class UserProfileCollection extends UserProfileCollectionController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
    };

    // const PostDiv = (imageUrl: string, title: string) => {
    //   return (
    //     <div>
    //       <div className="addNewPost" onClick={() => imageUrl === "" && props.collectionId > 0 ? props.createPost(props.collectionId) : null}>
    //         {imageUrl !== "" ? <img src={imageUrl} alt={title} /> : <AddIcon />}
    //       </div>
    //     </div>
    //   );
    // };

    // const renderDesigns = (designs: any) => {
    //   const minDesigns = 5;
    //   const loopCount =
    //     minDesigns >= designs.length ? 5 : designs.length;
    //   var posts = [];
    //   posts.push(PostDiv("", "addImage"));
    //   for (let i = 0; i < loopCount; i += 1) {
    //     if (i in designs) {
    //       posts.push(
    //         PostDiv(designs[i].attributes.image_url, designs[i].attributes.title)
    //       );
    //     } else {
    //       posts.push(PostDiv("", "addImage"));
    //     }
    //   }
    //   return <Slider {...settings}>{posts}</Slider>;
    // };
    const { classes } = this.props;

    const CardContainer = (props: any) => {
      if (this.state.designsData.length === 0) return null;
      return (
        <>
          <div className={classes.card__container}>
            {this.state.designsData.map((collection) => {
              return (
                <div className={classes.collection__container}>
                  <h4>
                    {collection.attributes.name}(
                    {collection.attributes.design_count})
                  </h4>
                  <div className={classes.collection__design__container}>
                    <div
                      onClick={() => {
                        this.setState({
                          isOpen: true,
                          modalStep: 3,
                        });
                        this.setModalWidth(2);
                      }}
                      className={classes.add__design}
                    >
                      <img src={plus} />
                    </div>
                    {collection.attributes.designs.data.map((design) => {
                      // design.attributes.design_type = "Club";
                      return (
                        <div className={classes.designs}>
                          <img
                            src={design.attributes.image_url}
                            className={classes.designs__image}
                          />
                          <div className={classes.designs__details}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  border: "none",
                                  borderRadius: "40%",
                                  backgroundColor: `${design.attributes.designer.data.attributes
                                    .color
                                    }`,
                                  marginRight: "5px",
                                }}
                              />
                              <span>{design.attributes.designer_name}</span>
                            </div>
                            {design.attributes.design_type === "User" ||
                              design.attributes.design_type === "Designer" ? (
                              <span>
                                <b>{design.attributes.design_type}</b>
                              </span>
                            ) : (
                              <span
                                style={
                                  design.attributes.design_type === "Group"
                                    ? {
                                      color: "black",
                                      border: "1px solid black",
                                      backgroundColor: "white",
                                      padding: "3px 8px",
                                    }
                                    : {
                                      color: "white",
                                      backgroundColor: "black",
                                      border: "none",
                                      padding: "3px 8px",
                                    }
                                }
                              >
                                <b>{design.attributes.design_type}</b>
                              </span>
                            )}
                            <div className={classes.designs__ratings}>
                              <div>
                                <img src={star} />
                                {design.attributes.rating_count}
                              </div>
                              <div>
                                <img src={wishlist} />
                                {design.attributes.like_count}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    };

    const FiltersButton = (props: any) => {
      return (
        <button
          className={
            this.state.filter === `${props.filterName}`
              ? "main-header-community-button-selected"
              : "main-header-community-button"
          }
          onClick={() => this.changeCategory(props.filterName)}
        >
          {props.filterName}
        </button>
      );
    };

    const ModalStep1 = () => {
      return (
        <div className={classes.modal__step1}>
          <div className={classes.modal__step1___cross__button}>
            <img src={close} onClick={this.handleClose} />
          </div>
          <div className={classes.modal__step1__header}>
            <b>Create Collection</b>
          </div>
          <div className={classes.modal__step1__input}>
            <div>
              <input
                className={classes.modal__step1__collection__name__input}
                type="text"
                ref={this.newCollectionNameRef}
                defaultValue={this.state.newCollectionName}
              />
              <p className={classes.inputValidation}>
                {this.state.newCollectionNameError}
              </p>
            </div>
            <div className={classes.modal__step1__collection__checkbox__input}>
              <input
                type="checkbox"
                checked={this.state.newCollectionPrivate}
                onChange={() => {
                  this.setState({
                    newCollectionPrivate: !this.state.newCollectionPrivate,
                    newCollectionName: this.newCollectionNameRef.current.value,
                  });
                }}
              />
              <span>Keep this collection private</span>
            </div>
          </div>

          <button
            onClick={() => {
              if (this.newCollectionNameRef.current.value.trim().length === 0) {
                this.setState({
                  newCollectionNameError:
                    "Please enter a name for your collection",
                });
                return;
              }

              this.createCollectionUser();
            }}
            className={classes.modal__step1__createButton}
          >
            Create
          </button>
        </div>
      );
    };

    const ModalStep2Card = () => {
      return (
        <div className={classes.modal__step2__saved__designs}>
          {this.state.savedDesigns.map((item: any, index: number) => {
            return (
              <div key={index} className={classes.modal__step2__card}>
                <div className={classes.modal__step2__card__image__div}>
                  <img src={item.attributes.image_url} />
                </div>
                <button onClick={() => {
                  this.saveDesignToCollection(item.id);
                }} className={classes.modal__step2__card__image__button}>Save</button>
                <div className={classes.modal__step2__card__details}>
                  <span>{item.attributes.name}</span>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    const ModalStep2 = () => {
      return (
        <>
          {!this.state.modalLoading ? <div className={classes.modal__step2}>
            <div className={classes.modal__step2___cross__button}>
              <img src={close} onClick={this.handleClose} />
            </div>

            <div className={classes.modal__step2__header}>
              <b>
                Save Designs to your new collections "
                {this.state.newCollectionName}"
              </b>
            </div>

            <ModalStep2Card />
            <div className={classes.modal__step2__uploadDesignButton}>
              <button
                onClick={() => {
                  this.setState({
                    modalStep: 3,
                  });
                }}
              >
                Upload Your Design
              </button>
            </div>
          </div> : <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </div>}
        </>
      );
    };



    const ModalStep3 = () => {
      return (
        <div className={classes.modal__step3}>
          <div className={classes.modal__step2___cross__button}>
            <img src={close} onClick={this.handleClose} />
          </div>
          <div className={classes.modal__step3__header}>
            <b>Upload Design</b>
          </div>
          <div className={classes.modal__step3__main}>
            <div>
              {this.state.mainImage === null ? (
                <div className={classes.upload__box}>
                  <div className={classes.upload__box__icon}>
                    <img
                      src={cloud}
                      className={classes.upload__box__icon__image}
                    />
                    <h2 className={classes.upload__box__paragraph}>
                      Drag and Drop an image
                    </h2>
                    <p className={classes.upload__box__paragraph}>
                      or{" "}
                      <b>
                        <label
                          style={{
                            cursor: "pointer",
                          }}
                          htmlFor="uploadFile"
                        >
                          browse
                        </label>
                      </b>{" "}
                      to choose a file
                    </p>
                    <p
                      style={{ color: "grey" }}
                      className={classes.upload__box__paragraph}
                    >
                      (1600x1200 or larger recommeneded, upto 10MB)
                    </p>
                  </div>

                  <input
                    id="uploadFile"
                    style={{
                      display: "none",
                    }}
                    type="file"
                    onChange={(event) => {
                      event.preventDefault();
                      this.encodeImageFileAsURL(event.target).then((res) => {
                        this.setState({
                          mainImage: res,
                        });
                      });
                    }}
                  />
                </div>
              ) : (
                <div className={classes.upload__box}>
                  <img
                    src={this.state.mainImage}
                    className={classes.upload__main__image}
                  />
                  <label
                    style={{
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    htmlFor="reuploadFile"
                  >
                    {" "}
                    ReUpload{" "}
                  </label>
                  <input
                    style={{
                      display: "none",
                    }}
                    id="reuploadFile"
                    type="file"
                    onChange={(event) => {
                      event.preventDefault();
                      this.encodeImageFileAsURL(event.target).then((res) => {
                        this.setState({
                          mainImage: res,
                        });
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className={classes.modal__step3__formDetails}>
              <div>
                <div className={classes.modal__step3__inputDiv}>
                  <label>Title</label>
                  <input
                    ref={this.titleRef}
                    type="text"
                  />
                  <p className={classes.input__validation}>
                    {this.state.modalStep3Errors.title}
                  </p>
                </div>
                <div className={classes.modal__step3__inputDiv}>
                  <label>Description</label>
                  <textarea
                    placeholder="about your design"
                    ref={this.descriptionRef}
                  />
                  <p className={classes.input__validation}>
                    {this.state.modalStep3Errors.description}
                  </p>
                </div>
              </div>
              <div className={classes.save__design}>
                <div>
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={col}
                  />
                  Collections
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                    }}
                  >
                    <Select
                      isSearchable={true}
                      placeholder={"Select a category"}
                      options={this.state.modalStep3Collections}
                      value={this.state.selectedCollectionId}
                      onChange={(value: any) => {
                        const errors = { ...this.state.modalStep3Errors };
                        errors.selectedCategory = "";
                        this.setState({
                          selectedCollectionId: value,
                          modalStep3Errors: errors,
                        });
                      }}
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      this.uploadUserDesign(e);
                    }}
                  >
                    Save
                  </button>
                </div>
                <p className={classes.input__validation}>
                  {this.state.modalStep3Errors.selectedCollectionId}
                </p>
              </div>
            </div>
          </div>
          <div className={classes.modal__step3__subDesign}>
            <div className={classes.subdesigns__box}>
              <p>
                <b>Add Subdesigns</b>
              </p>
              <div className={classes.image__subdesigns}>
                {this.state.subDesign.map((subdesign, index) => (
                  <div key={index}>
                    <img
                      className={classes.image__subdesign__image}
                      src={subdesign}
                    />
                  </div>
                ))}

                <label
                  style={{
                    cursor: "pointer",
                  }}
                  htmlFor="upload-subdesign"
                >
                  <div
                    style={{
                      width: "60px",
                      height: "40px",
                      backgroundColor: "#ccc",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <img src={plus} className={classes.plusButton} />
                  </div>
                </label>
                <input
                  style={{
                    display: "none",
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    this.encodeImageFileAsURL(e.target).then((res: any) => {
                      this.setState({
                        subDesign: [...this.state.subDesign, res],
                      });
                    });
                  }}
                  onChange={this.onUploadSubDesign}
                  type="file"
                  id="upload-subdesign"
                />
              </div>
            </div>
          </div>
        </div>
      );
    };

    const modalStyle = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: this.state.modalWidth,
      backgroundColor: "white",
      border: "none",
    };

    return (
      <>
        {this.state.loading ? (
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <HeaderUser
              currentLabel={1}
              navigation={this.props.navigation}
              profileData={this.state.profileData.attributes}
              arrLabels={this.state.arrLabels}
            />
            <div className="main-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <div className="main-header-community">
                  <FiltersButton filterName={"Saved"} />
                  <FiltersButton filterName={"Liked"} />
                  <FiltersButton filterName={"Shared"} />
                  <FiltersButton filterName={"Inspired"} />
                  <FiltersButton filterName={"Rated"} />
                  <FiltersButton filterName={"All"} />
                </div>
              </div>
              <div className="main-sub-header">
                <div className="main-filters">
                  <div className={classes.subHeader}>
                    <img src={col} />
                    <p>COLLECTIONS</p>
                  </div>

                  <div className="main-header-filters">
                    <button
                      onClick={this.handleOpen}
                      className={classes.createCollectionButton}
                    >
                      Create Collection
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                }}
              >
                <CardContainer
                  navigation={this.props.navigation}
                  data={this.state.designsData}
                />
              </div>
            </div>
            <FooterWeb
              navigation={this.props.navigation}
            />
            {/* MODAL */}
            <Modal
              keepMounted
              disableEnforceFocus
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              open={this.state.isOpen}
              onClose={this.handleClose}
              disableAutoFocus
            >
              <div style={modalStyle}>
                {this.state.modalStep === 1 ? <ModalStep1 /> : null}
                {this.state.modalStep === 2 ? <ModalStep2 /> : null}
                {this.state.modalStep === 3 ? <ModalStep3 /> : null}
              </div>
            </Modal>
          </>
        )}
      </>
    );
  }
}

const useStyles = {
  clubAbout: {
    display: "grid",
    gridTemplateColumns: "0.2fr auto 0.2fr",
    padding: "10px 0px",
    marginBottom: "15px",
    columnGap: "15px",
  },
  userClubName: {
    display: "grid",
    justifyContent: "center",
  },
  subHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      marginRight: "10px",
      width: "20px",
      height: "20px",
    },
  },
  createCollectionButton: {
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "12px",
    cursor: "pointer",
    border: "none",
  },
  modal__step1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  modal__step1___cross__button: {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    width: "100%",
    "& img": {
      width: "15px",
      height: "15px",
      cursor: "pointer",
      marginRight: "10px",
      marginTop: "10px",
    },
  },
  modal__step1__header: {
    marginTop: "10px",
    fontSize: "18px",
  },
  modal__step1__input: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width: "70%",

    marginTop: "30px",
    marginBottom: "30px",
  },
  modal__step1__collection__name__input: {
    width: "100%",
    height: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px",
  },
  modal__step1__collection__checkbox__input: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    "& input": {
      marginRight: "10px",
    },
  },
  modal__step1__createButton: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 30px",
    borderRadius: "5px",
    border: "none",
    marginBottom: "30px",
    marginLeft: "200px",
    cursor: "pointer",
  },

  modal__step2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  modal__step2___cross__button: {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    width: "100%",
    "& img": {
      width: "15px",
      height: "15px",
      cursor: "pointer",
      marginRight: "10px",
      marginTop: "10px",
    },
  },
  modal__step2__header: {
    fontSize: "18px",
    marginTop: "10px",
    marginBottom: "30px",
  },

  modal__step2__saved__designs: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "center",
    width: "100%",
    // marginLeft: "50px",
    width: "90%",
    marginBottom: "50px",
    maxHeight: "420px",
    overflowY: "scroll",
  },

  modal__step2__card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    marginRight: "20px",
    marginBottom: "10px",
    justifyContent: "left",

  },
  modal__step2__card__image__div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    "&:hover ~ $modal__step2__card__image__button": {
      display: "flex",
      position: 'absolute',
    },
    "&:hover": {
      opacity: "0.5",
    },
    borderRadius: "5px",
    "& img": {
      width: "180px",
      height: "180px",
      borderRadius: "10px",
    },
  },
  modal__step2__card__image__button: {
    display: "none",
    color: 'white',
    backgroundColor: 'black',
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    alignItems: 'right',
    // marginLeft: '130px',
    // marginTop: '10px',
    "&:hover ~ $modal__step2__card__image__button": {
      display: "flex",
      position: 'absolute',
    },
  },

  modal__step2__card__details: {
    marginTop: "5px",
  },

  modal__step2__uploadDesignButton: {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    width: "90%",
    "& button": {
      backgroundColor: "black",
      color: "white",
      padding: "10px",
      borderRadius: "5px",
      fontSize: "12px",
      cursor: "pointer",
      border: "none",
    },
    marginBottom: "30px",
  },
  modal__step3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  modal__step3__header: {
    fontSize: "18px",
  },
  modal__step3__main: {
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    width: "95%",
    marginTop: "40px",
  },
  modal__step3__formDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "space-between",
    marginLeft: "20px",
    width: "100%",
  },
  modal__step3__inputDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width: "100%",
    marginBottom: "20px",
    "& input": {
      border: "1px solid #ccc",
      borderRadius: "5px",
      height: "20px",
    },
    "& label": {
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "14px",
    },
    "& textarea": {
      height: "100px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
  },
  modal__step3__subDesign: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "left",
    width: "95%",
    marginBottom: "50px",
  },
  upload__box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "500px",
    height: "400px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  upload__main__image: {
    width: "100%",
    height: "100%",
  },
  upload__box__icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  upload__box__icon__image: {
    width: "100px",
    height: "100px",
  },
  upload__box__paragraph: {
    margin: "0px",
  },
  plusButton: {
    width: "20px",
    height: "20px",
    marginTop: "10px",
  },
  image__subdesigns: {
    display: "flex",
    flexDirection: "row",
  },
  image__subdesign__image: {
    width: "60px",
    height: "40px",
    marginRight: "10px",
  },
  inputValidation: {
    color: "red",
    fontSize: "12px",
    margin: "0px",
    marginTop: "5px",
    marginBottom: "30px",
  },
  card__container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    marginLeft: "30px",
    marginBottom: "30px",
  },
  collection__container: {
    display: "flex",
    flexDirection: "column",
  },
  collection__design__container: {
    display: "flex",
    flexDirection: "row",
    gap: "25px",
    flexWrap: 'wrap'
  },
  add__design: {
    width: "300px",
    height: "200px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  designs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    fontSize: "12px",
    fontWeight: "bold",
  },
  designs__image: {
    width: "300px",
    height: "200px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  designs__details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "5px",
  },
  designs__ratings: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#ccc",
    "& div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "10px",
      "& img": {
        width: "20px",
        height: "20px",
        marginRight: "5px",
      },
    },
  },
  save__design: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& button": {
      backgroundColor: "black",
      color: "white",
      padding: "10px",
      borderRadius: "5px",
      fontSize: "14px",
      cursor: "pointer",
      border: "none",
      width: "20%",
    },
  },
  input__validation: {
    color: "red",
    fontSize: "12px",
    margin: "0px",
    marginTop: "5px",
  },
};

export default withStyles(useStyles)(UserProfileCollection);

const fake_data = [
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
  {
    name: "Sajal",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
  },
];
