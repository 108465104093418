import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import FormikTextInput from '../../../components/src/FormikTextField.web';
import DezinerButton from '../../../components/src/Button.web';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import DezinerAlert from '../../../components/src/Alert.web';
import { passwordPolicyValidationSchema } from '../../../components/src/validation/password-validation';

const initValues = {
  oldPassword: '',
  newPassword: '',
}




const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('please insert your old password'),
  newPassword: passwordPolicyValidationSchema('new password'),
});



const useChangePasswordFormStyles = makeStyles({
  container: {
    margin: '50px auto',
    width: '400px',
  },
});

export default function ChangePasswordForm({
  onSubmit,
  loading,
  errors,
}: {
  onSubmit: (params: { newPassword: string, oldPassword: string }) => void,
  loading: boolean,
  errors: { [key: string]: string },
}) {

  const classes = useChangePasswordFormStyles();

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {
        formik => <Form translate={""}>

          <Grid direction='column' alignItems="center" className={classes.container}>


            <FormikTextInput
              fullWidth
              variant='outlined'
              name="oldPassword"
              label="Old Password"
              type="password"
            />

            {
              errors?.old_password ?
                <DezinerAlert>
                  {errors?.old_password}
                </DezinerAlert>
                :
                null
            }
            <VerticalSpacer />


            <FormikTextInput
              fullWidth
              variant='outlined'
              name="newPassword"
              label="New Password"
              type="password"
            />
            {
              errors?.password ?
                <DezinerAlert>
                  {errors?.password}
                </DezinerAlert>
                :
                null
            }
            <VerticalSpacer />


            <VerticalSpacer />

            <Grid container justifyContent='flex-end'>
              <DezinerButton
                color="primary"
                variant='contained'
                type='submit'
                disabled={loading}
              >
                {'Change'}
                {
                  loading ?
                    <CircularProgress size={20} style={{ margin: '0 5px' }} color='inherit' />
                    :
                    null
                }
              </DezinerButton>
            </Grid>

          </Grid>

        </Form>
      }
    </Formik>
  );
}

