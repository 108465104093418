import React from 'react';
import { useProfile, useSingleDesign } from './hooks';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function DesignTitle({ profileId, designId }: { profileId: number, designId: number }) {

  const profileQuery = useProfile({ id: profileId || 0 }, { enabled: Boolean(profileId) });
  const profileAttributes = profileQuery?.data?.data?.attributes;

  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;


  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">
          {designAttributes?.title ?? ''}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {profileAttributes?.first_name ?? ''}
        </Typography>
      </Grid>
    </Grid>
  );
}
