import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";

// Customizable Area End

import UserHeaderActionController, { Props } from "./UserHeaderActionsController.web";

class UserHeaderAction extends UserHeaderActionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
        <div style={{
            // right: "100px",
            padding: "15px",
            display: 'flex',
            // position: 'absolute'
          }}
        >
            <div
                style={{
                    background: this.state.following ? 'black' : 'white',
                    color: this.state.following ? 'white' : 'black'
                }}
                className={`${classes.floatingRightBtn} ${classes.followingBtn}`}
                onClick={() => this.state.following ? this.unFollowAccount() : this.followAccount()}
            >
                <Tooltip
                    disableFocusListener
                    title={`${this.state.followerCount} followers`}
                    arrow
                    placement="top"
                >
                    <span style={{ marginRight: "5px" }}> {this.state.following ? 'Following' : 'Follow'} </span>
                </Tooltip>
            </div>
            <div
                style={{
                    background: this.state.subscribed ? 'black' : 'white',
                    color: this.state.subscribed ? 'white' : 'black'
                }}
                className={`${classes.floatingRightBtn}`}
            >
                <span style={{ marginRight: "5px" }}> {this.state.subscribed ? 'Subscribed' : 'Subscribe'}  </span>{" "}
            </div>
        </div>
    );
  }
}

// Customizable Area Start
const useStyles: any = {
    floatingRightBtn : {
        padding: '5px 15px',
        marginRight: '20px',
        border: '1px solid lightgrey',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer'
    }
};
export default withStyles(useStyles)(UserHeaderAction);
// Customizable Area End
