import React from "react";
// Customizable Area Start
import "../../catalogue/src/style.css";
import "./NotificationsWeb.web.css";
import Divider from "@material-ui/core/Divider";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
// Customizable Area End

import NotificationsWebController, {
  Props,
} from "./NotificationsWebController.web";
import Footer from "../../catalogue/src/Footer.web";
import TopHeaderWeb from "../../catalogue/src/TopHeader.web";

class NotificationsWeb extends NotificationsWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const profileData = this.state.profileData;
    const userProfileImg =
      profileData.profile_url === ""
        ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
        : profileData.profile_url;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
   
    return (
      <>
        <TopHeaderWeb
          navigation={this.props.navigation}
          userProfileImg={userProfileImg}
          clubName=''
          groupName=''
        />
        <div className="notify_main">
          <div className="notify_header">
            <div className="notify_header_inner">
              <div
                style={{ display: "flex", gap: "20px", alignItems: "baseline" }}
              >
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => this.handleChecked(e)}
                  />
                  <label htmlFor="selectAll"> Select All</label>
                </div>

                {this.state.showunRead && (
                  <div style={{ marginTop: "2px" }}>
                    <p className={this.state.showunRead ? "mark_read" : ""}>
                      Mark as read
                    </p>
                  </div>
                )}
                {this.state.showRead && (
                  <div style={{ marginTop: "2px" }}>
                    <p className={this.state.showRead ? "mark_read" : ""}>
                      Mark as unread
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div>
              <h1>Notifications</h1>
            </div>
            <div>
              <select
                name="dropdown"
                id="dropdown"
                onChange={(e) => this.selectOptionChange(e)}
              >
                <option value="all">All</option>
                <option value="read">Read</option>
                <option value="unread">Un Read</option>
              </select>
            </div>
          </div>

          <div className="notify_content">
            <div>
              {this.state.notifyArray.map((notifyData: any) => (
                <div key={notifyData.id}>
                  <input type="checkbox" className="div_check" />
                  {this.state.showAll && (
                    <label htmlFor="all">
                      <div className="notify_content_inner">
                        <div className="n_type">
                          {notifyData.notification_type}
                        </div>
                        <h3>{notifyData.notification_title}</h3>
                        <p className="n_des">{notifyData.notification_desc}</p>
                        <div className="notify_inner_div">
                          <p className="n_user">{notifyData.user_name}</p>
                          <p className="n_date">
                            <AccessTimeIcon style={{ marginRight: "8px" }} />
                            <span>
                              {notifyData.date} at {notifyData.time}
                            </span>
                          </p>
                        </div>
                        <Divider />
                      </div>
                    </label>
                  )}

                  {this.state.showRead && notifyData.status === "read" && (
                    <label htmlFor="read">
                      <div className="notify_content_inner">
                        <div className="n_type">
                          {notifyData.notification_type}
                        </div>
                        <h3>{notifyData.notification_title}</h3>
                        <p className="n_des">{notifyData.notification_desc}</p>
                        <div className="notify_inner_div">
                          <p className="n_user">{notifyData.user_name}</p>
                          <p className="n_date">
                            <AccessTimeIcon style={{ marginRight: "8px" }} />
                            <span>
                              {notifyData.date} at {notifyData.time}
                            </span>
                          </p>
                        </div>
                        <Divider />
                      </div>
                    </label>
                  )}

                  {this.state.showunRead && notifyData.status === "unread" && (
                    <label htmlFor="unread">
                      <div className="notify_content_inner">
                        <div className="n_type">
                          {notifyData.notification_type}
                        </div>
                        <h3>{notifyData.notification_title}</h3>
                        <p className="n_des">{notifyData.notification_desc}</p>
                        <div className="notify_inner_div">
                          <p className="n_user">{notifyData.user_name}</p>
                          <p className="n_date">
                            <AccessTimeIcon style={{ marginRight: "8px" }} />
                            <span>
                              {notifyData.date} at {notifyData.time}
                            </span>
                          </p>
                        </div>
                        <Divider />
                      </div>
                    </label>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <Footer
          navigation={this.props.navigation}
        />
      </>
    );
  }
}

// Customizable Area Start
export default NotificationsWeb;
// Customizable Area End
