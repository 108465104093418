import { combineReducers } from 'redux';
import { designsReducer } from '../designs/designs.reducer';
import { labelsReducer } from '../labels/labels.reducer';
import { profileReducer } from '../profile/profile.reducer';
import { connectRouter } from 'connected-react-router'
import { uiReducer } from '../ui/ui.reducer';

export const rootReducer = (history: any) => combineReducers({
  profile: profileReducer,
  labels: labelsReducer,
  designs: designsReducer,
  ui: uiReducer,

  router: connectRouter(history),
});
