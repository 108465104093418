
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { Design } from '../../../components/src/api-types'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  designData: Partial<Design['attributes']>;
  loading: boolean;
  selectedMediaURL: string;
  selectedMediaType: string;
  dialogStatus: boolean;
  impressions: number;
  duration: number;
  step: number;
  totalAmount: number;
  promoteType: string;
  editable: boolean;
  expirationDate: string;
  cardNumber: string;
  cvcNumber: string;
  shareDialogStatus: boolean;
  infoDialogStatus: boolean,
  shareLink: string;
  shareDesignLink: string;
  loadingShare: boolean;
  comments: any[],
  commentsCount: number,
  parentCommentId: number,
  deleteDialogOpen: boolean,
  loadingComments: boolean,
  moreDesigns: [],
  loadingMoreDesigns: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}


export default class DesignerDesignViewController extends BlockComponent<Props, S, SS> {

  designDataMessageId: string;
  shareDesignMsgApi: string;
  commentMessageId: string;
  postReplyCommentMessageId: string;
  fetchCommentsMsgId: string;
  fetchMoreDesignsMsgId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.promoteDesign = this.promoteDesign.bind(this);
    this.shareDesign = this.shareDesign.bind(this);
    this.fetchComments = this.fetchComments.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      designData: {},
      loading: true,
      selectedMediaURL: '',
      selectedMediaType: '',
      dialogStatus: false,
      impressions: 10000,
      duration: 12,
      step: 1,
      totalAmount: 500,
      promoteType: 'Design',
      editable: false,
      expirationDate: '',
      cardNumber: '',
      cvcNumber: '',
      shareDialogStatus: false,
      infoDialogStatus: false,
      shareLink: '',
      shareDesignLink: '',
      loadingShare: false,
      comments: [],
      commentsCount: 0,
      parentCommentId: 0,
      deleteDialogOpen: false,
      loadingComments: false,
      moreDesigns: [],
      loadingMoreDesigns: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getDesignData();
  }

  componentDidUpdate(prevProps: Props, prevState: S) {

    let prevId = prevProps.navigation.getParam("id");
    let currentId = this.props.navigation.getParam("id");

    if (prevId != currentId) {

      this.getDesignData();

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })

    }

  }




  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.fetchMoreDesignsMsgId != null &&
      this.fetchMoreDesignsMsgId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {

        this.setState({
          loadingMoreDesigns: false,
          moreDesigns: responseJson?.data ?? [],
        });

      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.setState({
          loadingMoreDesigns: false,
          moreDesigns: []
        });
      }
    }


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.fetchCommentsMsgId != null &&
      this.fetchCommentsMsgId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {

        let comments = responseJson?.data?.map((item: any) => item.attributes);
        this.setState({ comments, commentsCount: comments.length });

      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }



    // DESIGN DATA API RESPONSE
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designDataMessageId != null &&
      this.designDataMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {

        let designData = responseJson.design.data.attributes;

        this.setState({
          designData,
          selectedMediaURL: designData.image_url,
          selectedMediaType: designData.main_image_type,
        })


        this.fetchComments();
        this.fetchMoreDesigns();

      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading: false });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.shareDesignMsgApi != null &&
      this.shareDesignMsgApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          shareDesignLink: responseJson.data.attributes.attachment_url,
          shareLink: responseJson.meta.share_url,
          shareDialogStatus: true,
          loadingShare: false
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // POST COMMENT API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.commentMessageId != null &&
      this.commentMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ comments: [responseJson.data.attributes, ...this.state.comments] });
        this.setState({ commentsCount: this.state.commentsCount + 1 });
        toast.success("Comment Posted Successfull");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(errorReponse);
      }
    }


    // POST REPLY COMMENT API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postReplyCommentMessageId != null &&
      this.postReplyCommentMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {

        let comments = this.state.comments.map((item: any) => {
          if (item.id === this.state.parentCommentId) {
            return {
              ...item,
              replies: {
                ...item.replies,
                data: item.replies.data.concat(responseJson.data)
              }
            }
          } else {
            return item;
          }
        });

        this.setState({
          parentCommentId: 0,
          comments,
          commentsCount: this.state.commentsCount + 1
        });

        toast.success("Reply Posted Successfull");

      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(errorReponse);
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  getDesignData(): boolean {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {};
    const designId = this.props.navigation.getParam("id", "");
    let url = `${configJSON.designApi}/${designId}`;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });

    this.designDataMessageId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  }

  handleImageClick(e: any, type: string, url: string) {
    this.setState({
      selectedMediaURL: url,
      selectedMediaType: type
    });
  }

  promoteDesign(img: string, type: string) {
    let customAmount = 500;
    switch (type) {
      case "Collection":
        customAmount = 750;
        break;
      case "Designer":
        customAmount = 1000;
        break;
      case "Group/Club":
        customAmount = 1500;
        break;
      default:
        break;
    }
    this.setState({
      selectedMediaURL: img,
      promoteType: type,
      dialogStatus: true,
      totalAmount: customAmount,
    });
  }


  shareDesign() {
    this.setState({ loadingShare: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const designId = this.props.navigation.getParam("id", "");
    const httpBody = {
      data: {
        attributes: {
          sharable_type: "BxBlockAttachment::Design",
          sharable_id: designId
        }
      }
    };
    let url = configJSON.designSharingLink;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: url,
    });

    this.shareDesignMsgApi = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
  }

  postComment({ comment, audioURL, attachment }: any) {
    const designId = this.props.navigation.getParam("id", "");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          comment: comment,
          commentable_type: "BxBlockAttachment::Design",
          commentable_id: designId,
          audios: audioURL ? [{ data: audioURL }] : [],
          attachments: attachment ? [{ data: attachment }] : []
        },
      },
    };

    const postComment = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.postComment,
    });

    this.commentMessageId = postComment.messageId;
    runEngine.sendMessage(postComment.id, postComment);
    return true;
  }

  postReplyComment({ reply, replyAudio, replyAttachment, parentCommentId }: any) {

    this.setState({ parentCommentId });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          comment: reply,
          audios: replyAudio ? [{ data: replyAudio }] : [],
          attachments: replyAttachment ? [{ data: replyAttachment }] : []
        },
      },
    };

    const postComment = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.postComment}/${parentCommentId}/reply`,
    });

    this.postReplyCommentMessageId = postComment.messageId;
    runEngine.sendMessage(postComment.id, postComment);
  }

  fetchComments(): boolean {
    this.setState({ loadingComments: true });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {};
    const designId = this.props.navigation.getParam("id", "");

    let url = configJSON.fetchCommentsAPI.replace(':id', designId);

    const fetchCommentsCall = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.fetchCommentsMethod,
      url: url,
    });


    this.fetchCommentsMsgId = fetchCommentsCall.messageId;
    runEngine.sendMessage(fetchCommentsCall.id, fetchCommentsCall);
    return true;
  }

  fetchMoreDesigns(): boolean {
    this.setState({ loadingMoreDesigns: true });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {};
    const designId = this.props.navigation.getParam("id", "");

    let url = configJSON.fetchMoreDesignsAPI.replace(':id', designId);

    const fetchMoreDesignsCall = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.fetchMoreDesignsMethod,
      url: url,
    });


    this.fetchMoreDesignsMsgId = fetchMoreDesignsCall.messageId;
    runEngine.sendMessage(fetchMoreDesignsCall.id, fetchMoreDesignsCall);
    return true;
  }
  // Customizable Area End
}
