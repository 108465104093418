import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
import { DesignerHomeLayoutProps } from '../../../components/src/DesignerHomeLayout.web';
// Customizable Area End


export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  layoutProps: DesignerHomeLayoutProps
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  promoteCollectionModal: boolean;
  promoteCollectionId: number;
  currentImage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}




export default class DesingerHomeController extends BlockComponent<
  Props,
  S,
  SS
  > {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.promoteCollection = this.promoteCollection.bind(this);
    this.handlePromoteCollectionModalOpen = this.handlePromoteCollectionModalOpen.bind(this);
    this.handlePromoteCollectionModalClose = this.handlePromoteCollectionModalClose.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      promoteCollectionModal: false,
      promoteCollectionId: 0,
      currentImage: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }



  guardRoute = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
  }

  async componentDidMount() {
    super.componentDidMount();

    this.guardRoute();

    const DesignPublish = window.sessionStorage.getItem("DesignPublish");

    if (DesignPublish === "true") {
      toast.success("Design Published Successfull");
      window.sessionStorage.removeItem("DesignPublish");
    }
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  promoteCollection(img: string, id: number) {
    this.setState({
      currentImage: img,
      promoteCollectionModal: true,
      promoteCollectionId: id
    })
  }
  handlePromoteCollectionModalClose = () => {
    this.setState({
      promoteCollectionModal: false,
    });
  }
  handlePromoteCollectionModalOpen = () => {
    this.setState({
      promoteCollectionModal: true,
    });
  }

  // Customizable Area End
}
