import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
// Customizable Area Start
export const configJSON = require("./config");
import { toast } from "react-toastify";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  currentLabel: any;
  arrLabels: any;
  profileData: any;
  followersData: any;
  loading: boolean;
  selectedFilter: string;
  designerId: number;
  selfProfileData: any;
  currentFollower: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignerFollowerPageByUserController extends BlockComponent<
  Props,
  S,
  SS
> {
  profileMessageId: string;
  labelsMessageId: string;
  followerMessageId: string;
  selfProfileMessageId: string;
  followMessageApi: string;
  unFollowMessageApi: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.filterFollowers = this.filterFollowers.bind(this);
    this.followAccount = this.followAccount.bind(this);
    this.unFollowAccount = this.unFollowAccount.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    // const followerCount = this.props.navigation.getParam("followers", "");

    this.state = {
      token: "",
      currentLabel: 0,
      arrLabels: [{
        id: 0,
        attributes: {
          name: 'All'
        }
      }],
      profileData: {},
      followersData: [],
      loading: false,
      selectedFilter: "All",
      designerId: this.props.navigation.getParam("id", 0),
      selfProfileData: {},
      currentFollower: 0
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getSelfProfile();
    this.getProfile();
    this.getLabels();
    this.getFollowers();
  }

  //get token
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          profileData: responseJson.data.attributes
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.selfProfileMessageId != null &&
      this.selfProfileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          selfProfileData: responseJson.data.attributes
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // LABEL LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.labelsMessageId != null &&
      this.labelsMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelData = this.state.arrLabels;
        this.setState({ arrLabels: [...arrLabelData,...responseJson.data] });
        const storageLocalId = localStorage.getItem("changeFilterLabel");
        if (storageLocalId) {
          this.setState({ currentLabel: storageLocalId });
        }
        // if (responseJson.data.length > 0) {
        //   this.setState({ currentLabel: responseJson.data[0].id });
        // }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.followerMessageId != null &&
      this.followerMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          followersData: responseJson.data
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.followMessageApi != null &&
      this.followMessageApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors) {
        const tmpFollowers = this.state.followersData.map((follower: any) => {
          if (follower.id === this.state.currentFollower) {
            follower.attributes.followed = true;
            follower.attributes.follower_count += 1;
          }
          return follower;
        });
        this.setState({
          followersData: tmpFollowers,
          currentFollower: 0
        });
        toast.success('Followed Successfully');
      } else {
        toast.error('Something went wrong!!');
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.unFollowMessageApi != null &&
      this.unFollowMessageApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors) {
        const tmpFollowers = this.state.followersData.map((follower: any) => {
          if (follower.id === this.state.currentFollower) {
            follower.attributes.followed = false;
            follower.attributes.follower_count -= 1;
          }
          return follower;
        });
        this.setState({
          followersData: tmpFollowers,
          currentFollower: 0
        });
        toast.error('Unfollowed Successfully');
      } else {
        toast.error('Something went wrong!!');
      }
    }

    // Customizable Area End
  }

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_profile_by_users`,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getSelfProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.selfProfileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getLabels(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_labels_by_users`,
    });

    this.labelsMessageId = getLabelsData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;
  }

  getFollowers() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_followers_by_users`,
    });

    this.followerMessageId = getLabelsData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
  }

  filterFollowers(filter: string) {
    this.setState({ selectedFilter: filter === '' ? 'All' : filter });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_followers_by_users?filter=${filter}`,
    });

    this.followerMessageId = getLabelsData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
  }

  followAccount() {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.currentFollower === 0 ? this.props.navigation.getParam("id", 0) : this.state.currentFollower
        }
      }
    };
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.followApi,
    });

    this.followMessageApi = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
  }

  unFollowAccount() {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.deleteMethod,
      url: `${configJSON.followApi}/${this.state.currentFollower === 0 ? this.props.navigation.getParam("id", 0) : this.state.currentFollower}`,
    });

    this.unFollowMessageApi = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
  }

  // Customizable Area Start
  // Customizable Area End
}
