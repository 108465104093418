import React from "react";
// Customizable Area Start
import {
  star,
  likes,
  trending_up,
  edit,
  editSolidDark,
  trashSolidIcon,
} from "./assets";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from "@material-ui/core/CircularProgress";
import TopHeaderWeb from "../../catalogue/src/TopHeader.web";
import FooterWeb from "../../catalogue/src/Footer.web";
import DesignsCollectionsGroupsList from "./CollectionView.web";
import DesignCardsResponsiveContainer from "./DesignCardsContainer.web";
import DezinerButton from "../../../components/src/Button.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
// Customizable Area End

import CollectionViewController, {
  Props,
} from "./CollectionViewByDesignerController.web";
import { DEFAULT_PROFILE_IMAGE } from "../../../components/src/defaults";

const Spinner = () => (<div
  style={{
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "40vh",
  }}
>
  <CircularProgress />
</div>);

class CollectionViewByDesigner extends CollectionViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const CardContainer = (props: any) => {
      return (
        <>
          {props?.data.attributes.designs.data.map(
            (item: any, index: number) => {
              return (
                <div className="card" key={index} >
                  <div className="card-image-div" onClick={() => {
                    this.props.navigation.navigate('DesignView', { designId: item.id, })
                  }}>
                    <img
                      className="card-image"
                      src={item.attributes.image_url}
                    />
                    <div className="card-image-overlay" />
                    <button
                      onClick={() => this.onTrendingButtonClick(item)}
                      className="card-image-button card-image-trending-button"
                    >
                      <img src={trending_up} />
                    </button>
                    <button
                      onClick={() => this.onEditButtonClick(item)}
                      className="card-image-button card-image-edit-button"
                    >
                      <img src={edit} />
                    </button>
                  </div>
                  <div className="card-details">
                    <div>
                      <b>{item.attributes.title}</b>
                    </div>
                    <div className="card-icons">
                      <span className="card-icons-container">
                        <img className="card-icons-icon" src={star} />
                        {item.attributes.rating_count}
                      </span>
                      <span className="card-icons-container">
                        <img className="card-icons-icon" src={likes} />
                        {item.attributes.like_count}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      );
    };

    const { name, design_count } = this.state.designerDesigns.attributes;
    const {
      first_name,
      profile_thumbnail_url,
      id: designerId,
      color,
    } = this.state.designerDesigns?.attributes?.account?.data?.attributes ?? {};

    const { classes } = this.props as any;

    let designs = this.state.designerDesigns?.attributes?.designs?.data ?? [];


    return (
      <div>


        <main className={classes.main}>
          <VerticalSpacer />

          {
            this.state.loadingDesigns ?
              <Spinner />
              :
              <>
                <Grid
                  className={classes.designerHeaderContainer}
                  container justifyContent="space-between" alignItems="flex-start">
                  <Grid item xs={12} sm={6} container direction="column">

                    <Typography className={classes.title}>
                      {name}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      {`${design_count} Design`}
                    </Typography>

                    <Grid container alignItems="center">
                      {
                        profile_thumbnail_url ?
                          <img className={classes.profileImg} src={profile_thumbnail_url} alt={first_name} />
                          :
                          color ?
                            <div
                              className={classes.profileImg}
                              style={{
                                background: color,
                              }} />
                            :
                            <img className={classes.profileImg} src={DEFAULT_PROFILE_IMAGE} alt={first_name} />
                      }
                      <Typography className={classes.firstName}>
                        {first_name}
                      </Typography>
                    </Grid>

                  </Grid>
                  <Grid item xs={12} sm={6} container
                    justifyContent="flex-end"
                    className={classes.buttonsContainer}
                  >

                    <DezinerButton variant="outlined" color="primary" className={classes.iconButton}>
                      <img className={classes.iconImg} src={trashSolidIcon} alt="delete" />
                    </DezinerButton>
                    <DezinerButton variant="outlined" color="primary" className={classes.iconButton}>
                      <img className={classes.iconImg} src={editSolidDark} alt="edit" />
                    </DezinerButton>
                    <DezinerButton
                      onClick={() => this.props.navigation.navigate('Catalogue', { 
                      designerId 
                    })}
                      className={classes.createButton}
                      variant="outlined"
                      color="primary">
                      {'Create Collection'}
                    </DezinerButton>

                  </Grid>
                </Grid>

                <VerticalSpacer value={40} />

                <DesignCardsResponsiveContainer
                  designs={designs}
                  onClick={(item) => {
                    this.props.navigation.navigate('DesignView', {
                      designId: item.id,
                    });
                  }}
                  onTrend={() => { }}
                  onEdit={(item) => {
                    this.props.navigation.navigate('DesignerDesignEdit', {
                      designId: item.id,
                    });
                  }}
                  onPlaceholderClick={this.addDesign}
                />
              </>
          }


          {
            this.state.loadingCollections ?
              <Spinner />
              :
              <>
                <VerticalSpacer value={100} />

                <Divider className={classes.divider} />

                <VerticalSpacer value={30} />

                <Typography className={classes.moreCollections}>
                  {'More Collections'}
                </Typography>

                <VerticalSpacer value={30} />


                <DesignsCollectionsGroupsList
                  collection={this.state.arrCollection}
                  onClick={(collectionID: number) => {
                    this.props.navigation.navigate("CollectionViewByDesigner", {
                      collectionId: collectionID,
                      labelId: this.props.navigation.getParam("labelId", 0),
                    });
                  }}
                />
              </>
          }


        </main>


      </div>
    )
  }
}

// Customizable Area Start
const styles: any = {
  moreCollections: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  divider: {
    width: "100%", background: "gray", height: "1px"
  },
  main: {
    padding: '40px 20px',
    maxWidth: '1200px',
    margin: '0 auto'
  },
  designerHeaderContainer: {
  },
  iconButton: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
    padding: '0'
  },
  iconImg: {
    width: '20px',
    maxWidth: '20px',
  },
  buttonsContainer: {
    '&>*': {
      margin: '4px'
    }
  },
  title: {
    color: "#000002", fontSize: "1.7rem", fontWeight: "bold"
  },
  subtitle: {
    fontSize: "1.1rem",
    fontWeight: 500,
    margin: "2px 0 30px",
    color: "#4a4a4a"
  },
  firstName: {
    fontSize: "1.1rem", fontWeight: "bold", padding: "0 10px"
  },
  createButton: {
    padding: '5px 10px'
  },
  profileImg: {
    width: '50px',
    height: '50px',
    borderRadius: '40%',
  }
}
// Customizable Area End
export default withStyles(styles)(CollectionViewByDesigner);
// Customizable Area End
