import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import AddClubMemberController, { Props } from "./AddClubMemberController.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import HeaderWeb from "./Header.web";
import "./styles.css";
import FooterWeb from "../../catalogue/src/Footer.web";
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import DezinerButton from "../../../components/src/Button.web";
import MembersContributionTableWithShares, { MembersContributionTable } from "./MembersContributionTable.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import DezinerTextField from "../../../components/src/TextField.web";
import { a11yProps, DezinerTab, DezinerTabPanel, DezinerTabs } from "./Tabs.web";



class AddClubMember extends AddClubMemberController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>


        <Dialog
          open={this.state.deletePopup}
          onClose={this.handleClickDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Member</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure, you want to remove{" "}
              <b>{this.state.deleteMember}</b> from this club?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickDeleteClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleClickDeleteMember}
              color="primary"
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>




        {/* @ts-ignore */}
        {/*<HeaderWeb navigation={this.props.navigation} />*/}



        <div style={{ padding: '50px 0' }}>
          {
            this.state.loading ?
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress color="primary" />
              </Grid>
              :
              <Grid container>
                <Grid item sm={2}></Grid>
                <Grid item xs={12} sm>


                  <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center"
                    style={{
                      borderBottom: '1px solid #ddd'
                    }}
                  >
                    <GroupAndClubHorizontalCard
                      item={this.state.currentClub}
                      hideButton
                      hideMembers
                      hideBorder
                    />
                    <DezinerTabs
                      value={this.state.currentTab}
                      onChange={this.handleChange}
                      style={{ backgroundColor: "white", color: "black" }}
                    >
                      <DezinerTab label="Member" {...a11yProps(0)} />
                      <DezinerTab label="Role" {...a11yProps(1)} />
                    </DezinerTabs>
                  </Grid>

                  <DezinerTabPanel value={this.state.currentTab} index={0}>

                    <VerticalSpacer />

                    <Grid container justifyContent="space-between" alignItems="center">

                      <Grid item xs={4} container alignItems="center">
                        <FormControl style={{ width: '100%', position: 'relative', top: `${this.state.role ? '0' : '-10'}px` }} error={this.state.memberError}>
                          {
                            this.state.memberId ?
                              null
                              :
                              <InputLabel id="add-member-label" style={{ position: 'relative', left: '20px', top: '5px' }}>
                                {'Add Member'}
                              </InputLabel>
                          }

                          <Select
                            style={{
                              maxHeight: '40px'
                            }}
                            variant="outlined"
                            labelId="add-member-label"
                            error={this.state.memberError}
                            // @ts-ignore
                            helperText={this.state.memberHelperText}
                            value={this.state.memberId}
                            // @ts-ignore
                            onChange={(e) => this.setState({ memberId: e.target.value })}
                          >
                            {this.state.memberList.length > 0 ? this.state.memberList.map((memberItem: any) => (
                              <MenuItem value={memberItem.id}>{memberItem.attributes.first_name}</MenuItem>
                            )) : null}
                          </Select>
                          <FormHelperText>{this.state.memberHelperText}</FormHelperText>
                        </FormControl>

                      </Grid>
                      <Grid item xs={3} container alignItems="center">
                        <FormControl style={{ width: '100%', position: 'relative', top: `${this.state.role ? '0' : '-10'}px` }} error={this.state.roleError}>
                          {
                            this.state.role ?
                              null
                              :
                              <InputLabel id="member-role-label" style={{ position: 'relative', left: '10px', top: '5px' }}>
                                {'Enter member role'}
                              </InputLabel>
                          }

                          <Select
                            style={{
                              maxHeight: '40px'
                            }}
                            variant="outlined"
                            labelId="member-role-label"
                            error={this.state.roleError}
                            // @ts-ignore
                            helperText={this.state.roleHelperText}
                            value={this.state.role}
                            // @ts-ignore
                            onChange={(e) => this.setState({ role: e.target.value })}
                          >
                            {this.state.userRoles.length > 0 ? this.state.userRoles.map((memberItem: any) => (
                              memberItem !== 'Owner' ?
                                <MenuItem value={memberItem}>{memberItem}</MenuItem> :
                                null
                            )) : null}
                          </Select>
                          <FormHelperText>{this.state.roleHelperText}</FormHelperText>
                        </FormControl>

                      </Grid>
                      <Grid item xs={2} container alignItems="center" wrap="nowrap">
                        <Typography style={{
                          color: 'gray',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          paddingRight: '5px'
                        }}>
                          {'Contribution %'}
                        </Typography>

                        <DezinerTextField
                          fullWidth
                          type="number"
                          error={this.state.contributionError}
                          helperText={this.state.contributionHelperText}
                          variant="outlined"
                          // @ts-ignore
                          max={100}
                          value={this.state.contribution}
                          // @ts-ignore
                          onChange={(e) => this.setState({ contribution: e.target.value })}
                          style={{ minWidth: "50px", maxWidth: '50px' }}
                        />
                      </Grid>
                      <Grid item xs={1} alignItems="center">

                        <DezinerButton
                          variant="contained"
                          color="primary"
                          onClick={() => this.addMember()}
                        >
                          {'Add'}
                        </DezinerButton>
                      </Grid>

                    </Grid>

                    <VerticalSpacer />

                    <MembersContributionTable
                      members={this.state.clubMembers}
                      currentRole={this.state.currentUserRoleClub}
                      isEditable={false}
                      isEqual={false}
                      revenu={{}}
                      setRevenu={() => { }}
                      onDelete={({ memberId, memberName }) => {
                        this.setState({ deleteId: memberId, deleteMember: memberName }, () => {
                          this.handleClickDeleteMember();
                        })
                      }}
                      isViewOnly={true}
                    />

                    <VerticalSpacer />
                    <Grid container justifyContent="flex-end">
                      {
                        (
                          this.state.currentUserRoleClub === 'Admin' ||
                          this.state.currentUserRoleClub === 'Owner'
                        ) ? (
                          <DezinerButton
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              this.setState({ currentTab: 1 })
                            }}
                          >
                            {'Update'}
                          </DezinerButton>
                        )
                          :
                          null
                      }
                    </Grid>

                  </DezinerTabPanel>
                  <DezinerTabPanel value={this.state.currentTab} index={1}>

                    <MembersContributionTableWithShares
                      members={this.state.clubMembers}
                      onDelete={({ memberName, memberId }) => {
                        this.setState({
                          deleteMember: memberName,
                          deleteId: memberId,
                          deleteType: "remove_member",
                          deletePopup: true,
                        })
                      }}
                      onAdd={() => {
                        this.setState({ currentTab: 0 })
                      }}
                      onSave={(revenu) => {
                        console.log({ revenu });
                      }}
                      currentRole={this.state.currentUserRoleClub}
                    />

                  </DezinerTabPanel>

                </Grid>
                <Grid item sm={2}></Grid>
              </Grid>
          }
        </div>

{/*
        <FooterWeb
          navigation={this.props.navigation}
        />
*/}
      </>
    );
  }
}

const useStyles = {

};

export default withStyles(useStyles)(AddClubMember);
