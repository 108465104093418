import React, { ReactNode, useState } from 'react';
import MaterialTextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


interface ExtraProps {
  autoHeight?: boolean,
  label?: string,
  centerLabel?: boolean,
  centerText?: boolean,
  lightGrayBorder?: boolean,
}

const useInputClasses = (props: ExtraProps) => makeStyles({
  root: {
    margin: '5px 0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: props.lightGrayBorder ? '#e1e1e1' : 'gray',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiInputBase-root': {
      maxHeight: props.autoHeight ? 'unset' : '30px',
      borderRadius: '6px',
    }
  }
})



export default function DezinerTextField(props: React.ComponentProps<typeof MaterialTextField> & ExtraProps) {

  const {
    label,
    centerLabel,
    autoHeight,
    centerText,
    lightGrayBorder,
    ...otherProps
  } = props;
  const classes = useInputClasses(props)();

  const [showPassword, setShowPassword] = useState(false);
  const isPasswordInput = Boolean(otherProps.type == 'password');

  return (
    <>
      {
        label ?
          <DezinerLabel centerLabel={centerLabel}>{label}</DezinerLabel>
          :
          null
      }

      <MaterialTextField
        classes={{ ...classes, ...props.classes }}
        {...otherProps}
        inputProps={{
          ...otherProps.inputProps,
          style: {
            textAlign: centerText ? 'center' : 'unset',
            ...otherProps.inputProps?.style,
          }
        }}
        type={isPasswordInput ? showPassword ? "text" : "password" : otherProps.type}
        InputProps={{
          endAdornment: isPasswordInput ? <IconButton size="small" onClick={() => setShowPassword(x => !x)}>
            {
              !showPassword ?
                <VisibilityIcon fontSize="small" />
                :
                <VisibilityOffIcon fontSize="small" />
            }
          </IconButton>
            :
            null,
          ...otherProps.InputProps
        }}

      />
    </>
  );
}


const useLabelStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    display: 'block'
  },
  centerLabel: {
    textAlign: 'center'
  }
});

export function DezinerLabel({ children, centerLabel, className }: { children: ReactNode, centerLabel?: boolean, className?: any, }) {

  const classes = useLabelStyles();

  return (
    <Typography className={clsx(classes.root, className, {
      [classes.centerLabel]: centerLabel
    })}>
      {children}
    </Typography>
  )
}
