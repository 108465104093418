import { DezinerActionCreator } from "../store";

export const PIN_COLLECTION = 'PIN_COLLECTION';

export interface PinCollectionAction {
  type: typeof PIN_COLLECTION,
  collectionId: number,
}

export const pinCollection: DezinerActionCreator<PinCollectionAction> =
  (params) => ({
    type: PIN_COLLECTION,
    ...params,
  });

///////// 

export const UNPIN_COLLECTION = 'UNPIN_COLLECTION';

export interface UnpinCollectionAction {
  type: typeof UNPIN_COLLECTION,
  collectionId: number,
}

export const unpinCollection: DezinerActionCreator<UnpinCollectionAction> =
  (params) => ({
    type: UNPIN_COLLECTION,
    ...params,
  });

