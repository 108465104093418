import React from 'react';
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DezinerButton from "../../../components/src/Button.web";
import PropTypes from 'prop-types';
import MaterialTabs from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import clsx from 'clsx';
import {
  privacyIcon,
  localUploadIcon,
  uploadToCloudIcon,
  headerIcon,
  settingsGearIcon,
  urlLinkIcon,
  tagIcon,
  imageExtensionIcon,
  videoExtensionIcon,
  descriptionIcon,
} from "./assets";


export const Tabs = withStyles({
  root: {
    borderRight: '1px solid #efeff1',
    '& .MuiTabs-indicator': {
      background: "#f1f1f1",
      width: "100%",
      borderTopLeftRadius: "40px",
      borderBottomLeftRadius: "40px",
      zIndex: -1
    }
  }
})(MaterialTabs);

export const Tab = withStyles({
  root: {
    padding: "20px 10px 20px 50px",
    fontSize: "1.3rem",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    textTransform: "none",
    alignSelf: "flex-end",
    minWidth: "420px",
    '&:hover': {
      color: 'black',
      opacity: 1,
    },
    '&$selected': {
      color: 'black',
      fontWeight: 'bold',
    },
    '&:focus': {
      color: 'black',
    },
  }
})(MaterialTab);

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ flexGrow: 1, maxWidth: '600px' }}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useMainMediaPlaceHolderStyles = makeStyles({
  mainCloudImg: {
    width: '180px',
  },
  dragText1: {
    color: "black", fontSize: "2rem", fontWeight: "bold"
  },
  dragText2: {
    color: "#606062", fontSize: "1.5rem", fontWeight: "bold"
  },
  browseBtn: {
    fontWeight: 'bold',
  },
  dragText3: {
    color: "#bbbbbb", fontSize: "0.9rem"
  },
  dragText4: {
    color: "black", fontSize: "1.1rem",
    maxWidth: 'max-content',
  },
  dragText5: {
    color: '#9e9ca7',
    maxWidth: 'max-content',
  },
  dragIconExt: {
    width: '40px',
  },
});


export function MainMediaPlaceholder() {

  const classes = useMainMediaPlaceHolderStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{
        height: "100%",
        justifyContent: "space-between",
        padding: "20px 0",
      }}
    >

      <span />

      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <img
          src={uploadToCloudIcon}
          alt="upload"
          className={clsx("gray-svg", classes.mainCloudImg)}
        />

        <Typography className={classes.dragText1}>
          {'Drag and Drop an image'}
        </Typography>


        <Grid container alignItems="center" justifyContent="center">
          <Typography className={classes.dragText2}>
            {'or'}
          </Typography>
          <DezinerButton
            color="primary" variant="text" className={classes.browseBtn}>
            {'Browse'}
          </DezinerButton>
          <Typography className={classes.dragText2}>
            {'to choose a file'}
          </Typography>
        </Grid>

        <Typography className={classes.dragText3}>
          {'1600x1200 or larger recommended. Max 10MB each (20MB for videos)'}
        </Typography>
      </Grid>


      <Grid container justifyContent="center">
        <Grid item xs={5} container justifyContent="flex-end">
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs={3} container justifyContent="center" alignItems="center"> 
              <img src={imageExtensionIcon} className={clsx(classes.dragIconExt, 'gray-svg')} alt="image" /> 
            </Grid>
            <Grid item xs={8} container direction="column">
              <Typography className={classes.dragText4}>{'High resolutions images'}</Typography>
              <Typography className={classes.dragText5}>{'PNG, JPG, GIF'}</Typography>
            </Grid>
          </Grid>
        </Grid>
          <Divider orientation="vertical" />
        <Grid item xs={5} container justifyContent="flex-start">
          <Grid container>
            <Grid item xs={3} container justifyContent="center" alignItems="center"> <img src={videoExtensionIcon} className={clsx(classes.dragIconExt, 'gray-svg')} alt="video" /> </Grid>
            <Grid item xs container direction="column">
              <Typography className={classes.dragText4}>{'Videos'}</Typography>
              <Typography className={classes.dragText5}>{'MP4, <60 secs'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


    </Grid>
  );
}

const useSubDesignStyles = makeStyles({
  subDesignImgContainer: {
    position: 'relative',
    margin: '0 10px 0 0'
  },
  subDesignImg: {
    borderRadius: '10px',
    width: '100px',
    height: '75px',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  subDesignImgIcon: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    cursor: 'pointer',
    zIndex: 2,
  },
});

export function SubDesign({
  data,
  clickHandler,
  removeHandler,
  type,
}: {
  data: string,
  clickHandler: () => void,
  removeHandler: () => void,
  type: string,
}) {

  const classes = useSubDesignStyles();

  return (
    <div className={classes.subDesignImgContainer}>
      {
        type?.startsWith('image') ?
          <img
            src={data}
            className={classes.subDesignImg}
            onClick={clickHandler}
          />
          :
          type?.startsWith('video') ?
            <video
              className={classes.subDesignImg}
              onClick={clickHandler}
            >
              <source src={data} />
            </video>
            :
            null
      }

      <IconButton size="small"
        onClick={removeHandler}
        className={classes.subDesignImgIcon}>
        <CancelIcon fontSize="small" />
      </IconButton>

    </div>
  );
}


const useUploadSourceFilesPlaceholderStyles = makeStyles({

  dragAndDropContainer: {
    borderRadius: '10px',
    border: '1px dashed #c1c1c1',
    padding: '40px',
    background: '#f6f6f6',
  },
  dragAndDropText: {
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  cloudIcon: {
    width: '100px',
  },
});

export function UploadSourceFilesPlaceholder() {

  const classes = useUploadSourceFilesPlaceholderStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.dragAndDropContainer}
    >

      <img
        src={uploadToCloudIcon}
        alt="upload"
        className={clsx("gray-svg", classes.cloudIcon)}
      />

      <Typography className={classes.dragAndDropText}>
        {'Drag and Drop files here'}
      </Typography>

      <VerticalSpacer />
      <Typography className={classes.dragAndDropText}>
        {'OR'}
      </Typography>
      <VerticalSpacer />

      <DezinerButton
        color="primary"
        variant="contained"
      >
        {'Browse Files'}
      </DezinerButton>

    </Grid>
  );
}


const useUploadedSourceFilesStyles =  makeStyles({
  uploadedItem: {
    margin: '10px 0',
    background: 'white',
    borderRadius: '10px',
    transition: 'all 0.2s ease-in-out',
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 15px #dfdfdf'
    },
  },
  uploadedItemIcon: {
    width: '35px',
  },
  uploadedItemFileSize: {
    fontSize: '0.8rem',
    color: '#8f8f8f'
  },
  uploadedItemFileName: {
    fontSize: '1.2rem',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
});

export function UploadedSourceFile({
  title,
  iconImage,
  name,
  size,
  removeHandler
}: {
  title: string,
  iconImage: any,
  name: string,
  size: string,
  removeHandler: () => void,
}) {

  const classes = useUploadedSourceFilesStyles();

  return (
    <Grid
      container
      className={classes.uploadedItem}
      title={title}
    >

      <Grid item xs={2} container justifyContent="center" alignItems="center">
        <img
          title={title}
          src={iconImage}
          className={classes.uploadedItemIcon}
        />
      </Grid>

      <Grid item xs container direction="column">
        <Typography className={classes.uploadedItemFileName}>
          {name}
        </Typography>
        <Typography className={classes.uploadedItemFileSize}>
          {`${size}B`}
        </Typography>
      </Grid>

      <Grid item xs={2} container justifyContent="center" alignItems="center">
        <IconButton
          title="delete"
          onClick={removeHandler}>
          <CancelIcon />
        </IconButton>
      </Grid>

    </Grid>
  );
}


