import React from "react";
import UserEditPasswordController, {
  Props,
} from "./UserEditPasswordController.web";
// import "./styles.css";
import ChangePasswordForm from "./DezinerChangePasswordForm.web";
import { errorMerger } from "./DesignerEditPasswordsPageController.web";
import { useUpdatePassword } from "../../../components/src/hooks";



function ChangeUserPasswordForm() {

  const { mutate: updatePassword, isLoading, error, isError } = useUpdatePassword();

  let formErrors = typeof error == 'string' ?
    { old_password: error }
    :
    error?.reduce(errorMerger, {})

  return (
    <ChangePasswordForm
      loading={isLoading}
      onSubmit={({ oldPassword, newPassword }) => {
        updatePassword({ oldPassword, newPassword });
      }}
      errors={isError ? formErrors : {}}
    />
  );
}



class UserEditPassword extends UserEditPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      <ChangeUserPasswordForm />
    );
  }
}


export default UserEditPassword;
