import React from 'react';
import { Design } from '../../../components/src/api-types';
import DesignCard, { AddDesignCardPlaceholder } from './DesignCard.web';
import ResponsiveCardsContainer from "./ResponsiveCardsContainer.web";


function DesignCardsResponsiveContainer({
  designs, onClick, onTrend, onEdit,
  includeAddDesignPlaceholder,
  onPlaceholderClick,
}: {
  designs: Design[],
  onClick: (item: Design) => void,
  onTrend: (item: Design) => void,
  onEdit: (item: Design) => void,
  includeAddDesignPlaceholder?: boolean,
  onPlaceholderClick?: () => void,
}) {



  return (
    <ResponsiveCardsContainer>
      {({ itemWidth, itemHeight }) => {


        return (
          <>
            {
              includeAddDesignPlaceholder ?
                <AddDesignCardPlaceholder onClick={onPlaceholderClick || (() => { })} height={itemHeight} />
                :
                null
            }
            {
              designs
                ?.map((item) =>
                (<DesignCard
                  key={item?.id}
                  imgHeight={itemHeight}
                  item={item}
                  onClick={() => onClick(item)}
                  onTrendingClick={() => onTrend(item)}
                  onEditClick={() => onEdit(item)}
                />
                ))
            }
          </>
        );

      }}
    </ResponsiveCardsContainer>
  );
}

export default DesignCardsResponsiveContainer;
