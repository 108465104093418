import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown, { Options } from 'react-markdown';
import remarkGfm from 'remark-gfm'

const useDezinerMarkdownH1Styles = makeStyles({
  root: {
    fontSize: '1.3rem',
    display: 'inline-block',
    border: "6px solid transparent",
    borderImage: "linear-gradient(168deg, black , white)",
    borderImageSlice: "1",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
  },
});

function DezinerMarkdownH1(props: any) {
  const classes = useDezinerMarkdownH1Styles();
  return (
    <h1
      {...props}
      className={classes.root}
    />
  );
}

////////////////

const useDezinerThStyles = makeStyles({
  root: {
    padding: '20px 10px',
    borderBottom: '1px solid #dbdbdb',
    borderRight: '1px solid #dbdbdb',
    background: '#eee',
  }
});

function DezinerTh(props: any) {
  const classes = useDezinerThStyles();
  return (
    <th
      {...props}
      className={classes.root}
    />
  );
}

////////////// 

const useDezinerTdStyles = makeStyles({
  root: {
    padding: '10px',
    borderBottom: '1px solid #dbdbdb',
    borderRight: '1px solid #dbdbdb',
  },
});

function DezinerTd(props: any) {
  const classes = useDezinerTdStyles();

  return (
    <td
      {...props}
      className={classes.root}
    />
  );
}

////////////// 

const useDezinerTableStyles = makeStyles({
  root: {
    border: '1px solid #dbdbdb',
    borderRadius: '6px',
    borderSpacing: 0,
    '& th:first-of-type': {
      borderTopLeftRadius: '6px',
    },
    '& th:last-of-type': {
      borderTopRightRadius: '6px',
      borderRight: '0px solid black !important',
    },
    '& tr:last-of-type td': {
      borderBottom: 'none !important',
    },
    '& tr td:last-of-type': {
      borderRight: 'none !important',
    },
  },
});
function DezinerTable(props: any) {
  const classes = useDezinerTableStyles();
  return (
    <table
      {...props}
      className={classes.root}
    />
  );
}
////////////////


export const dezinerMdComponents: Options['components'] = {
  h1: DezinerMarkdownH1,
  td: DezinerTd,
  th: DezinerTh,
  table: DezinerTable,
}

export function DezinerMarkDown({ children }: { children: string }) {
  return (
    <ReactMarkdown
      components={dezinerMdComponents}
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  );
}
