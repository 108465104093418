import React from 'react';
import DesignCardWithProfile from '../../blocks/landingpage/src/DesignCardLandingPage.web';
import ResponsiveCardsContainer from '../../blocks/landingpage/src/ResponsiveCardsContainer.web';
import { LandingPageDesign } from './api-types';

export default function DesignCardsWithProfilesResponsiveContainer(props: {
  navigate: (url: string) => void,
  data: LandingPageDesign[],
  onShareClick: (item: any) => void,
  onInspireClick: (item: any) => void,
  onSaveClick: (item: any) => void,
}) {

  const { navigate } = props;
  const isLoggedIn = Boolean(window.localStorage.getItem('token') && window.localStorage.getItem('cuid'));
  const isUser = Boolean((window.localStorage.getItem('currentUserRole')?.toLowerCase() ?? '') == 'user');

  const designCardAccounNameClickHandler = (item: LandingPageDesign) => {
    let accountType = item.attributes.account_type;

    switch (accountType) {
      case "Designer": {
        navigate(`DesignerHome/${item.attributes.account_id}`);
        break;
      }
      case "User": {
        //navigate(`UserProfileByOthers/${item.attributes.account_id}`);
        break;
      }
      case "Club": {
        //navigate(`ClubProfileByUser/${item.attributes.account_id}`);
        break;
      }
      case "Group": {

        break;
      }
    }
  }


  const designCardClickHandler = (item: any) => {

    //const isPrivate = item.attributes.privacy === "Private";
    //const accountType = item.attributes.account_type;

    if (isLoggedIn) {

      navigate(`DesignView/${item.id}`);
      /*
            if (isPrivate) {
              navigate(`DesignBuy/${item.id}`);
            } else {
              switch (accountType) {
                case "Designer": {
                  navigate(`DesignViewByUser/${item.id}`);
                  break;
                }
                case "Club": {
                  //navigate(`ClubDesignViewByUser/${item.id}`);
                  break;
                }
                case "Group": {
                  //navigate(`GroupDesignViewByUser/${item.id}`);
                  break;
                }
              }
    }
              */
    }
  }



  return (
    <div style={{
      padding: '60px 50px',
    }}>
      <ResponsiveCardsContainer>
        {
          ({ itemHeight, itemWidth }) =>
            props.data.map((item: any) => (
              <DesignCardWithProfile
                key={item?.id}
                imgHeight={itemHeight}
                item={item}
                onAccountNameClick={isLoggedIn ? designCardAccounNameClickHandler : () => { }}
                onClick={isLoggedIn ? designCardClickHandler : () => { }}
                onShareClick={props.onShareClick}
                onInspireClick={props.onInspireClick}
                onSaveClick={props.onSaveClick}
              />
            ))
        }
      </ResponsiveCardsContainer>
    </div>
  );
};
