import React from 'react';
import { useComments, usePostComment, usePostReply } from './hooks';
import Typography from '@material-ui/core/Typography';
import CommentInput from '../../blocks/landingpage/src/CommentInput.web';
import DezinerSpinner from './DezinerSpinner.web';
import { DesignComment } from './api-types';
import Comment from '../../blocks/landingpage/src/Comment.web';



export default function DesignCommentsSection({ designId }: { designId: number }) {

  const commentsQuery = useComments({ designId });
  const { mutate: postComment, isLoading: postCommentLoading } = usePostComment({ designId });

  return (
    <>

      <Typography style={{
        fontWeight: 'bold',
        fontSize: '1.5rem'
      }}>
        {'Comments'}
      </Typography>

      <CommentInput
        onComment={({ commentText, base64Image, base64Audio }) => {
          postComment({
            designId,
            commentAttachement: base64Image,
            commentAudio: base64Audio,
            commentText,
          });
        }}
      />

      {
        commentsQuery?.isLoading || postCommentLoading ?
          <DezinerSpinner noHeight />
          :
          null
      }

      {
        (commentsQuery?.data?.data ?? [])?.map((comment) =>
          <DesignSingleComment
            key={comment.id}
            comment={comment}
            designId={designId}
          />
        )
      }
    </>
  );
}

function DesignSingleComment({
  comment,
  designId,
}: {
  comment: DesignComment,
  designId: number,
}) {

  const { mutate: postReply, isLoading } = usePostReply({
    designId,
    parentCommentId: comment.attributes.id,
  });

  return (
    <Comment
      key={comment.id}
      loading={isLoading}
      comment={comment?.attributes}
      onReply={({ replyText, parentCommentId, base64Audio, base64Image }) => {
        postReply({
          parentCommentId,
          replyAudio: base64Audio,
          reply: replyText,
          replyAttachment: base64Image
        });
      }}
    />
  )
}
