import React from 'react';
import { dezinerKnotLogo } from './assets';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DezinerButton from '../../../components/src/Button.web';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  title: {
    fontSize: "2rem",
    color: "#767676",
    fontWeight: "bold",
    textAlign: "center"
  },
  logo: {
    margin: '40px 0',
  },
  btn: {
    fontSize: '1.4rem',
    padding: '10px 82px'
  }

});


export default function EmailIsActivatedPage(props: any) {

  const classes = useStyles();
  console.log({ props });

  return (
    <div className={classes.root}>

      <Typography variant="h1" className={classes.title}>
        {'Your Email has been activated successfully'}
      </Typography>

      <img
        src={dezinerKnotLogo}
        alt="Deziner Knot"
        className={classes.logo}
      />

      <DezinerButton
        color="primary"
        variant="contained"
        className={classes.btn}
        onClick={() => {
          props.navigation.navigate('EmailAccountLoginBlock');
        }}
      >
        {'Go to Login Page'}
      </DezinerButton>

    </div>
  );
}
