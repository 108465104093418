import React from 'react';
import { useSelector } from 'react-redux';
import LabelListing, { LabelListingProps } from '../../blocks/catalogue/src/LabelListing.web';
import {
  design as designsIcon,
  editProfileIcon,
  accountSettingsIcon,
  passwordIcon,
  about as aboutIcon,
  logoutIcon,
} from '../../blocks/landingpage/src/assets';
import DezinerChangeCoverButton from './DezinerChangeCoverButton.web';
import DezinerHeader from './DezinerHeader.web';
import HeaderControls from './DezinerHeaderControls.web';
import DezinerTopNav from './DezinerTopNav.web';
import { useMyProfile } from './hooks';
import { RootState } from './store/store';

interface Props {
  children: any,
  navigation: any,
}

export default function UserProfileLayout(props: Props) {

  const { navigate } = props.navigation;

  const { pathname }: any = useSelector((state: RootState) => state.router.location);

  const profileQuery = useMyProfile();

  const profileAttributes = profileQuery?.data?.data?.attributes;

  const listOptions: LabelListingProps['list'] = [
    {
      id: 1,
      label: 'Designs',
      onClick: () => navigate('UserDesignList'),
      active: pathname.includes('UserDesignList'),
      icon: designsIcon,
    },
    {
      id: 2,
      label: 'Edit Profile',
      onClick: () => navigate('UserEditProfile'),
      active: pathname.includes('UserEditProfile'),
      icon: editProfileIcon,
    },
    {
      id: 3,
      label: 'Account Settings',
      onClick: () => navigate('UserEditAccount'),
      active: pathname.includes('UserEditAccount'),
      icon: accountSettingsIcon,
    },
    {
      id: 4,
      label: 'Password',
      onClick: () => navigate('UserEditPassword'),
      active: pathname.includes('UserEditPassword'),
      icon: passwordIcon,
    },
    {
      id: 5,
      label: 'About',
      onClick: () => navigate('UserEditAbout'),
      active: pathname.includes('UserEditAbout'),
      icon: aboutIcon,
    },
    {
      id: 6,
      label: 'Logout',
      onClick: () => {
        window.localStorage.clear();
        navigate('EmailAccountLoginBlock')
      },
      active: pathname.includes('EmailAccountLoginBlock'),
      icon: logoutIcon,
    },
  ];


  return (
    <div>


      <DezinerHeader

        vertical
        fallbackColor={profileQuery.data?.data?.attributes?.color ?? ''}
        onClick={() => { }}
        title={profileAttributes?.first_name ?? ''}
        subTitle={profileAttributes?.profile_info ?? ''}
        profileImageURL={profileAttributes?.profile_thumbnail_url ?? ''}
        coverImageURL={profileAttributes?.cover ?? ''}
        loading={profileQuery?.isFetching}
      />

      <HeaderControls>

        <HeaderControls.SideSection flexStart>
          <DezinerChangeCoverButton />
        </HeaderControls.SideSection>

        <HeaderControls.MiddleSection>
          <LabelListing list={listOptions} />
        </HeaderControls.MiddleSection>

      </HeaderControls>



      {props?.children}

    </div>
  );
}
