import React, { useState, useRef, useEffect } from "react";
// Customizable Area Start
import { withStyles ,makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ChatIcon from "@material-ui/icons/Chat";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Tooltip from "@material-ui/core/Tooltip";

import { Divider, Popover as MaterialPopover, Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';

import { logo, trending_up } from "./assets";


// import "./style.css";
import "./TopHeader.web.css";
// Customizable Area End



const Popover = withStyles({
  root: {

  },
  paper: {

  },
})(MaterialPopover);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: '#363636',
    '&:focus': {
      backgroundColor: '#ddd',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#363636'
      },
    },
  },
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));















function UserAvatar({
  navigation,
  image,
  color,
  onPromoteProfile,
  currentRole,
  clubName,
  groupName,
}: {
  navigation: any,
  image: any,
  color: any,
  onPromoteProfile: any,
  currentRole: any,
  clubName: any,
  groupName: any,
}) {

  const [popoverElement, setPopoverElement] = React.useState<HTMLButtonElement | null>(null);

  function handlePopoverClick(event: React.MouseEvent<HTMLButtonElement>) {
    setPopoverElement(event.currentTarget);
  };

  function handlePopoverClose() {
    setPopoverElement(null);
  };

  const isPopoverOpen = Boolean(popoverElement);
  const popoverId = isPopoverOpen ? 'avatar-popover' : undefined;


  const logoutClickHandler = (e: any) => {
    e.preventDefault();
    window.localStorage.clear();
    navigation.navigate("EmailAccountLoginBlock");
  };

  function promoteClickHandler() {
    if (clubName)
      onPromoteProfile(image, "Club");
    else if (groupName)
      onPromoteProfile(image, "Group");
    else
      onPromoteProfile(image, "Designer");
  }

  const noGroup = groupName === "";
  const noClub = clubName === "";
  const noImage = image === "";


  return (
    <div>
      <button onClick={handlePopoverClick} className="menu-trigger">
        {
          noImage ? (
            <div
              style={{
                height: "35px",
                width: "35px",
                background: color,
                borderRadius: "50%",
              }}
            />
          ) : (
              <img
                style={{
                  height: "35px",
                  width: "35px",
                }}
                src={image}
                alt="userProfileImage"
              />
            )}
      </button>
      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledMenu
          id={popoverId}
          anchorEl={popoverElement}
          keepMounted
          open={isPopoverOpen}
          onClose={handlePopoverClose}
        >


          {/*noClub && noGroup && (
            <StyledMenuItem>
              <ListItemText
                onClick={() => {
                  navigation.navigate('DesignerEditProfile');
                }}
                primary="Profile" />
            </StyledMenuItem>
          )*/}



          {noClub && noGroup && (
            <StyledMenuItem>
              <ListItemText
                onClick={() => {
                  navigation.navigate('DesignerEditProfile');
                }}
                primary={`Edit ${noGroup && noClub ? 'Profile' : currentRole}`} />
            </StyledMenuItem>
          )}


          <Divider style={{ margin: '0' }} variant="inset" component="li" />

          <StyledMenuItem onClick={promoteClickHandler}>
            <ListItemIcon style={{ minWidth: '32px' }}>
              <img
                className="icon-img"
                alt="Promote collection"
                src={trending_up} />
            </ListItemIcon>
            <ListItemText
              primary={
                clubName || groupName
                  ? `Promote ${clubName ? "Club" : "Group"}`
                  : "Promote Profile"

              } />
          </StyledMenuItem>

          <Divider style={{ margin: '0' }} variant="inset" component="li" />

          <StyledMenuItem onClick={logoutClickHandler}>
            <ListItemText
              primary={`Logout`} />
          </StyledMenuItem>


        </StyledMenu>


      </Popover>

    </div>
  );

}





const TopHeader = (props: any) => {
  const { classes, userProfileImg, removedAdmin } = props;
  const [showSearch, setShowSearch] = useState(false);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [filterBtn, setFilterBtn] = useState({
    1: "",
    2: "",
    3: "",
  });

  let tmpRole = localStorage.getItem("currentDesignerPage")
    ? localStorage.getItem("currentDesignerPage")
    : "Myself";
  if (props.clubName !== "" || props.groupName !== "") {
    tmpRole = props.clubName !== "" ? "Club" : "Group";
  }
  const [currentRole, setCurrentRole] = useState(tmpRole);
  const navigateToHome = () => {
    if (props.type === "club") {
      return props.navigation.navigate("DesignClubPageView");
    }
    if (
      localStorage.getItem("currentUserRole") === "Designer" ||
      props.userRole === "Designer"
    ) {
      return props.navigation.navigate("DesignerHome");
    } else if (
      localStorage.getItem("currentUserRole") === "User" ||
      props.userRole === "User"
    ) {
      return props.navigation.navigate("UserHome");
    }
  };

  const navigateToNotification = () => {
    return props.navigation.navigate("NotificationsWeb");
  };
  return (
    <>
      <nav className={classes.navbar}>
        <img
          src={logo}
          style={{ cursor: "pointer" }}
          onClick={navigateToHome}
          alt="logo"
          className={classes.logo}
        />
        <div className={classes.rightBar}>
          <SearchIcon
            style={{ color: "white" }}
            onClick={() => setShowSearch(true)}
          />
          <NotificationsIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={navigateToNotification}
          />

          {removedAdmin ? (
            <Tooltip
              title="Admin Left from group"
              open={removedAdmin}
              arrow={true}
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
            >
              <ChatIcon style={{ color: "white" }} />
            </Tooltip>
          ) : (
              <ChatIcon style={{ color: "white" }} />
            )}

          <FormControl>
            <Select
              value={currentRole}
              className="topHeaderSelect"
              style={{ backgroundColor: "black", color: "white" }}
              id="topHeaderSelect"
            // onChange={handleChange}
            >
              <MenuItem
                value="Myself"
                onClick={() => {
                  if (localStorage.getItem("currentUserRole") == "Designer") {
                    props.navigation.navigate("DesignerHome");
                  } else {
                    props.navigation.navigate("UserHome");
                  }
                }}
              >
                Myself
              </MenuItem>
              <MenuItem
                value="Club"
                onClick={() => props.navigation.navigate("DesignClubPageView")}
              >
                {props.clubName === "" ? "Club" : props.clubName}
              </MenuItem>
              <MenuItem
                value="Group"
                onClick={() =>
                  props.navigation.navigate("DesignerGroupPageView")
                }
              >
                {props.groupName === "" ? "Group" : props.groupName}
              </MenuItem>
            </Select>
          </FormControl>

          <UserAvatar
            navigation={props.navigation}
            image={userProfileImg}
            color={props.profileColor}
            onPromoteProfile={props.onPromoteProfile}
            currentRole={currentRole}
            clubName={props.clubName}
            groupName={props.groupName}
          />
        </div>
      </nav>
      <div
        style={{
          position: "absolute",
          display: showSearch ? "block" : "none",
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.7)",
          justifyContent: "center",
          paddingTop: "15%",
          zIndex: 1,
        }}
      >
        <div
          style={{
            backgroundColor: showAdvanceSearch ? "white" : "",
            width: "53vw",
            margin: "0 auto",
            justifyContent: "center",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              color: "white",
              backgroundColor: showAdvanceSearch
                ? "white"
                : "rgba(0, 0, 0, 0.6)",
              height: "60px",
              // width: '50vw',
              borderRadius: "20px",
              border: "none",
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              gridTemplateColumns: "0.1fr 0.8fr 0.1fr",
              padding: "0px 20px",
              margin: "0 auto",
            }}
          >
            <SearchIcon
              style={{ color: showAdvanceSearch ? "black" : "white" }}
            />
            <input
              placeholder="Search"
              style={{
                color: showAdvanceSearch ? "black" : "white",
                backgroundColor: "transparent",
                border: "0px",
              }}
            />
            <CloseIcon
              style={{ color: showAdvanceSearch ? "black" : "white" }}
            />
          </div>
          <p
            style={{
              textAlign: "center",
              color: showAdvanceSearch ? "black" : "white",
              marginTop: "5px",
              cursor: "pointer",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setShowAdvanceSearch(!showAdvanceSearch)}
          >
            Advance Search Options
            <span>
              {showAdvanceSearch ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </span>
          </p>
          <div
            style={{
              display: showAdvanceSearch ? "block" : "none",
              marginTop: "10px",
              padding: "15px",
            }}
          >
            <p>Search By</p>
            <div>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor:
                    filterBtn[1] === "Designer" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 1: "Designer" })}
              >
                Designer
              </Button>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor: filterBtn[1] === "Group" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 1: "Group" })}
              >
                Group
              </Button>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor: filterBtn[1] === "Club" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 1: "Club" })}
              >
                Club
              </Button>
            </div>
            <p>Category of</p>
            <div>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor:
                    filterBtn[2] === "Fashion" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 2: "Fashion" })}
              >
                Fashion
              </Button>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor:
                    filterBtn[2] === "Jewellery" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 2: "Jewellery" })}
              >
                Jewellery
              </Button>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor: filterBtn[2] === "Decore" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 2: "Decore" })}
              >
                Decore
              </Button>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor: filterBtn[2] === "Art" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 2: "Art" })}
              >
                Art
              </Button>
            </div>
            <p>Design Charge for</p>
            <div>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor: filterBtn[3] === "Free" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 3: "Free" })}
              >
                Free
              </Button>
              <Button
                variant="outlined"
                style={{
                  fontWeight: "bold",
                  backgroundColor: filterBtn[3] === "Paid" ? "lightGrey" : "",
                }}
                onClick={() => setFilterBtn({ ...filterBtn, 3: "Paid" })}
              >
                Paid
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles: any = {
  // tooltip css start
  customTooltip: {
    backgroundColor: "white",
    color: "black",
    border: "1px solid white",
    padding: "10px",
    fontSize: "16px",
  },
  customArrow: {
    color: "white",
    border: "none",
  },
  // tooltip css end
  navbar: {
    height: "70px",
    background: "black",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    verticalAlign: "middle",
  },
  logo: {
    height: "50px",
    width: "150px",
    "@media (max-width: 767px)": {
      width: "120px",
      height: "auto",
    },
  },
  rightBar: {
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: "0.06",
    "@media (max-width: 767px)": {
      paddingRight: "0",
    },
  },
  navIcon: {
    width: "25px",
    height: "25px",
  },
  userIcon: {
    height: "50px",
    width: "50px",
    borderRadius: "40%",
  },
  menuOptions: {
    backgroundColor: "white",
    width: "150px",
    padding: "20px",
    color: "black",
  },
};

export default withStyles(useStyles)(TopHeader);
