import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {
  plusIcon,
  likeGray,
  shareArrowIcon,
  inspireGray,
  starGray,
} from "./assets";
import UserDesignListController, {
  Props,
} from "./UserDesignListController.web";
// import "./styles.css";
import {  DesignCategory, UserDesignsFilter } from "../../../components/src/api-types";
import ResponsiveCardsContainer from "./ResponsiveCardsContainer.web";
import { DesignCardWithProfileVariant } from "./DesignCardLandingPage.web";
import DezinerSpinner from "../../../components/src/DezinerSpinner.web";
import DezinerButtonGroup from '../../../components/src/DesignsButtonGroupFilters.web';
import DezinerDropdown from "../../../components/src/DezinerDropdown.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import { useDispatch } from "react-redux";
import { navigateTo } from "../../../components/src/store/navigation/navigation.action";
import { useUserDesigns } from '../../../components/src/hooks';

const iconsMapper: any = {
  saved: plusIcon,
  liked: likeGray,
  rated: starGray,
  inspired: inspireGray,
  shared: shareArrowIcon,
}

class UserDesignList extends UserDesignListController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      <>

        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="body1" style={{
            fontSize: '2rem',
            color: '#4a4a4a',
            padding: '100px 0',
          }}>
            {'Something exciting coming up 🎉'}
          </Typography>
        </Grid>
        {/*
        <UsersDesignsWithCategories />
*/}
      </>
    );
  }
}


function UsersDesignsWithCategories() {

  const [filter, setFilter] = useState<UserDesignsFilter>('all');
  const [category, setCategory] = useState<DesignCategory>('all');

  const dispatch = useDispatch();

  const buttonsGrouppOptions = [
    'Saved', 'Liked', 'Shared', 'Inspired', 'Rated', 'All'
  ].map(option => ({
    title: option,
    active: option.toLowerCase() == category,
    key: option.toLowerCase(),
    icon: iconsMapper?.[option.toLowerCase()] ?? null,
  }));

  const designsQuery = useUserDesigns({ filter, category });


  return (
    <div style={{ padding: '20px 30px' }}>

      <Grid
        container
        justifyContent="center"
        style={{
          padding: '30px 0'
        }}
      >

        <DezinerButtonGroup
          grayColors
          noBorders
          options={buttonsGrouppOptions}
          onClick={(category) => {
            setCategory(category as any);
            setFilter('all');
          }}
        />

      </Grid>

      <Grid container justifyContent="space-between">

        <Grid item xs={6} container alignItems="center">
          {
            iconsMapper?.[category] ?
              <img
                src={iconsMapper?.[category]}
                alt={category}
                style={{ marginRight: '5px', maxWidth: '25px', }}
              />
              :
              null
          }
          <Typography style={{ fontWeight: 'bold' }}>
            {`${category.toUpperCase()} DESIGNS`}
          </Typography>
        </Grid>

        <DezinerDropdown
          position="right"
          options={category == 'all' ? [
            {
              key: 'all',
              title: `All (${designsQuery?.data?.meta?.all_designs_count ?? 0})`
            },
            {
              key: 'saved_design',
              title: `Saved Design (${designsQuery?.data?.meta?.save_designs_count ?? 0})`,
            },
            {
              key: 'uploaded_designs',
              title: `Uploaded Designs (${designsQuery?.data?.meta?.uploaded_designs_count ?? 0})`
            }
          ] : [
              {
                key: 'all',
                title: `All (${designsQuery?.data?.meta?.all_designs ?? 0})`,
              },
              {
                key: 'users',
                title: `Users (${designsQuery?.data?.meta?.users ?? 0})`,
              },
              {
                key: 'groups',
                title: `Groups (${designsQuery?.data?.meta?.groups ?? 0})`,
              },
              {
                key: 'clubs',
                title: `Clubs (${designsQuery?.data?.meta?.clubs ?? 0})`,
              },
              {
                key: 'designers',
                title: `Designers (${designsQuery?.data?.meta?.designers ?? 0})`,
              },
            ]}
          selectedOption={filter}
          onClick={(filterName) => {
            setFilter(filterName as any);
          }}
          title={"All"}
        />

      </Grid>

      <VerticalSpacer />

      {
        designsQuery.isFetching ?
          <DezinerSpinner />
          :
          <ResponsiveCardsContainer>
            {
              ({ itemHeight, itemWidth }) =>
                designsQuery.data?.data?.map((item: any) => (
                  <DesignCardWithProfileVariant
                    key={item?.id}
                    imgHeight={itemHeight}
                    item={item}
                    onClick={(item) => {
                      dispatch(navigateTo({
                        url: `DesignViewByUser/${item.id}`,
                      }));
                    }}
                    onSaveClick={() => { }}
                    onShareClick={() => { }}
                    onInspireClick={() => { }}
                    onAccountNameClick={() => { }}
                  />
                ))
            }
          </ResponsiveCardsContainer>
      }

      <VerticalSpacer />
      <VerticalSpacer />
    </div>
  );
}




export default UserDesignList;
