import React, { ReactNode, useEffect, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

declare var window: any;

function itemsPerScreenWidth(screenWidth: number) {
  // if (screenWidth > 2000)
  //   return 6;
  //if (screenWidth > 1300)
   // return 5;
  if (screenWidth > 900)
    return 4;
  else if (screenWidth >= 750)
    return 3;
  else if (screenWidth >= 470)
    return 2;
  return 1;
}

const CONTAINER_PADDING = 60;
const ITEM_MARGIN = 0;
const ASPECT_RATIO = 387 / 292;

const useStyles = makeStyles({
  root: {
    display: "grid",
    justifyContent: "center",
    justifyItems: "stretch",
    gridGap: "20px",
    maxWidth: '100%',
    width: '100%',
    gridRowGap: '40px',
  }
});

export default function ResponsiveCardsContainer({ aspectRatio, children }: {
  children: (innerProps: { itemWidth: number, itemHeight: number }) => ReactNode,
  aspectRatio?: number,
}) {

  const containerRef = useRef<any>(null);
  const [screenWidth, setScreenWidth] = useState(1000)
  const classes = useStyles();

  const _ASPECT_RATIO_ = aspectRatio || ASPECT_RATIO;

  useEffect(() => {

    const resizeObserver = new window.ResizeObserver((entries: any) => {
      for (const entry of entries) {
        let screenWidth = entry.contentRect.width;
        if (containerRef && containerRef.current && containerRef.current?.offsetWidth) {
          let containerWidth = containerRef.current?.offsetWidth;
          setScreenWidth(containerWidth - 60);
        }
        else
          setScreenWidth(screenWidth - 60);
      }
    });

    resizeObserver.observe(window.document.body);
  }, [containerRef]);

  let itemWidth = Math.floor(screenWidth / itemsPerScreenWidth(screenWidth)) - (ITEM_MARGIN * 2);
  let itemHeight = itemWidth * 1 / _ASPECT_RATIO_;


  return (
    <div
      ref={containerRef}
      className={classes.root}
      style={{
        gridTemplateColumns: `repeat(${itemsPerScreenWidth(screenWidth)}, minmax(${itemWidth}px, 1fr))`,
      }}
    >
      {children({ itemWidth, itemHeight })}
    </div>
  );
}
