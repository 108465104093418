import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Chip from "@material-ui/core/Chip";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DesignerGroupManageController, {
  Props
} from "./DesignerGroupManageController.web";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import HeaderWeb from "./Header.web";
import "./styles.css";
import FooterWeb from "../../catalogue/src/Footer.web";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import MembersContributionTableWithShares from "./MembersContributionTable.web";
import DesignerProfileWithOptions from "./DesignerProfileWithOptions.web";
import AddIcon from '@material-ui/icons/Add';
import DezinerButton from "../../../components/src/Button.web";
import { a11yProps, DezinerTab, DezinerTabPanel, DezinerTabs } from "./Tabs.web";


// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         // <Box p={3}>
//         <Typography>{children}</Typography>
//         // </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`
//   };
// }

class DesignerGroupManage extends DesignerGroupManageController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>


        <Dialog
          open={this.state.deletePopup}
          onClose={this.handleClickDeleteClose}
          aria-labelledby="remove member"
        >
          <DialogTitle>{'Delete Member'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Are you sure, you want to remove `}
              <b>{this.state.deleteMember}</b>
              {`from this group?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickDeleteClose} color="primary">
              {'Cancel'}
            </Button>
            <Button
              onClick={this.handleClickDeleteMember}
              color="primary"
              autoFocus
            >
              {'Remove'}
            </Button>
          </DialogActions>
        </Dialog>



        {/*@ts-ignore */}
        {/*<HeaderWeb navigation={this.props.navigation} />*/}



        <div style={{ padding: '50px 0' }}>
          {
            this.state.loading ?
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress color="primary" />
              </Grid>
              :
              <Grid container>
                <Grid item sm={2}></Grid>
                <Grid item xs={12} sm>


                  <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center"
                    style={{
                      borderBottom: '1px solid #ddd'
                    }}
                  >
                    <GroupAndClubHorizontalCard
                      item={this.state.currentClub}
                      hideButton
                      hideMembers
                      hideBorder
                    />
                    <DezinerTabs
                      value={this.state.currentTab}
                      onChange={this.handleChange}
                      style={{ backgroundColor: "white", color: "black" }}
                    >
                      <DezinerTab label="Member" {...a11yProps(0)} />
                      <DezinerTab label="Role" {...a11yProps(1)} />
                    </DezinerTabs>
                  </Grid>

                  <DezinerTabPanel value={this.state.currentTab} index={0}>

                    <Grid container style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))' }}>
                      {
                        this.state.clubMembers.map((member: any) => (
                          <div key={member?.id} style={{ padding: '30px 0', }}>
                            <DesignerProfileWithOptions
                              currentRole={this.state.currentUserRoleClub}
                              member={member}
                              onDelete={({ deleteType, memberId, memberName }) => {
                                this.setState({
                                  deleteMember: memberName,
                                  deleteType: deleteType,
                                  deleteId: memberId,
                                  deletePopup: true
                                })
                              }}
                              addAsAdmin={({ memberId }) => {
                                this.addAsAdmin({ memberId });
                              }}
                            />
                          </div>
                        ))
                      }
                    </Grid>


                    <Grid container justifyContent="center">
                      {
                        (
                          this.state.currentUserRoleClub === 'Admin' ||
                          this.state.currentUserRoleClub === 'Owner'
                        ) ? (
                          <DezinerButton
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              this.props.navigation.navigate("AddGroupMember", {
                                id: this.props.navigation.getParam("id", 0)
                              })
                            }
                            startIcon={
                              <AddIcon />
                            }
                          >
                            {'Add Member'}
                          </DezinerButton>
                        )
                          :
                          null
                      }
                    </Grid>

                  </DezinerTabPanel>
                  <DezinerTabPanel value={this.state.currentTab} index={1}>

                    <MembersContributionTableWithShares
                      members={this.state.clubMembers}
                      onDelete={({ memberName, memberId }) => {
                        this.setState({
                          deleteMember: memberName,
                          deleteId: memberId,
                          deleteType: "remove_member",
                          deletePopup: true,
                        })
                      }}
                      onAdd={() => this.props.navigation.navigate('AddGroupMember', {
                        id: this.props.navigation.getParam('id', 0)
                      })}
                      onSave={(revenu) => {
                        console.log({ revenu });
                      }}
                      currentRole={this.state.currentUserRoleClub}
                    />

                  </DezinerTabPanel>

                </Grid>
                <Grid item sm={2}></Grid>
              </Grid>
          }
        </div>



        {/*this.state.loading ? (
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px"
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div className="main-container">
            <React.Fragment>
              <Dialog
                open={this.state.deletePopup}
                onClose={this.handleClickDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Delete Member</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure, you want to remove{" "}
                    <b>{this.state.deleteMember}</b> from this Group?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClickDeleteClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={this.handleClickDeleteMember}
                    color="primary"
                    autoFocus
                  >
                    Remove
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="userData">
                <div />
                <div
                  className="userClubData"
                  style={{
                    borderBottom: "1px solid black",
                    paddingBottom: "20px"
                  }}
                >
                  <div className="userClubImg">
                    <img
                      src={this.state.currentClub.attributes.image_url}
                      alt={this.state.currentClub.attributes.name}
                    />
                  </div>
                  <div className="userClubName">
                    <h4>
                      <b>{this.state.currentClub.attributes.name}</b>
                    </h4>
                  </div>
                  <div className="ClubManageBtn">
                    <AppBar position="static">
                      <Tabs
                        value={this.state.currentTab}
                        onChange={this.handleChange}
                        style={{ backgroundColor: "white", color: "black" }}
                      >
                        <Tab label="Member" {...a11yProps(0)} />
                        <Tab label="Revenue" {...a11yProps(1)} />
                      </Tabs>
                    </AppBar>
                  </div>
                </div>
                <div />
              </div>
              <TabPanel value={this.state.currentTab} index={0}>
                <div className="userData">
                  <div />
                  <div className="ClubMembersList">
                    {Object.keys(this.state.clubMembers).length > 0
                      ? this.state.clubMembers.map((member, index) => (
                          <div className="MemberData">
                            {member.attributes.role !== 'Owner' && (
                              <>
                              {(
                                (
                                  this.state.currentUserRoleClub === 'Admin' ||
                                  this.state.currentUserRoleClub === 'Owner'
                                ) &&
                                (
                                  this.state.adminCounter > 1 ||
                                  this.state.ownerCounter > 0
                                )
                              ) && (
                                <>
                                  <IconButton
                                    aria-label="more"
                                    aria-controls={`long-menu-${index}`}
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                      this.handleClick(e, index)
                                    }}
                                    style={{ position: "absolute", right: 0 }}
                                  >
                                    <MoreHorizIcon fontSize="small" />
                                  </IconButton>
                                  <Menu
                                    id={`long-menu-${index}`}
                                    anchorEl={this.state.anchorEl[index]}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl[index])}
                                    onClose={() => this.handleClose(index)}
                                    PaperProps={{
                                      style: {
                                        maxHeight: 40 * 4.5,
                                        width: "20ch"
                                      }
                                    }}
                                  >
                                    {(member.attributes.role === 'Admin' &&
                                      (this.state.ownerCounter !== 0 ||
                                      this.state.adminCounter > 1)) && (
                                      <MenuItem
                                        key={`RemoveAsAdmin-${index}`}
                                        onClick={() => {
                                          this.setState({
                                            deleteMember:
                                              member.attributes.first_name,
                                            deleteType: "remove_as_admin",
                                            deleteId: member.attributes.account_id,
                                            deletePopup: true
                                          });
                                        }}
                                      >
                                        { localStorage.getItem('cuid') === member.attributes.account_id.toString() ? 'Leave as admin' : 'Remove as admin' }
                                      </MenuItem>
                                    )}
                                    {(member.attributes.role !== 'Admin' && member.attributes.role !== 'Owner') ?
                                      ( <MenuItem
                                          key={`AddAsAdmin-${index}`}
                                          onClick={() => {
                                            this.setState({ memberId: member.attributes.account_id }, () => {
                                              this.addAsAdmin();
                                            });
                                          }}
                                        >
                                          Add as admin
                                        </MenuItem>
                                      ): null
                                    }
                                    {member.attributes.role === 'Owner' ||
                                    (
                                      this.state.ownerCounter === 0 ||
                                      (member.attributes.role === 'Admin' &&
                                      this.state.adminCounter > 1)
                                    ) ?
                                    null :
                                    (
                                      <MenuItem
                                        key={`Remove-${index}`}
                                        onClick={() => {
                                          this.setState({
                                            deleteMember: member.attributes.first_name,
                                            deleteType: "remove_member",
                                            deleteId: member.attributes.account_id,
                                            deletePopup: true
                                          });
                                        }}
                                      >
                                        { localStorage.getItem('cuid') === member.attributes.account_id.toString() ? 'Exit' : 'Remove' }
                                      </MenuItem>
                                    )}
                                  </Menu>
                                </>
                              )}
                              {(
                                this.state.currentUserRoleClub === 'Pattern designer' &&
                                member.attributes.account_id.toString() === localStorage.getItem('cuid')
                              ) && (
                                <>
                                  <IconButton
                                    aria-label="more"
                                    aria-controls={`long-menu-${index}`}
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                      this.handleClick(e, index)
                                    }}
                                    style={{ position: "absolute", right: 0 }}
                                  >
                                    <MoreHorizIcon fontSize="small" />
                                  </IconButton>
                                  <Menu
                                    id={`long-menu-${index}`}
                                    anchorEl={this.state.anchorEl[index]}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl[index])}
                                    onClose={() => this.handleClose(index)}
                                    PaperProps={{
                                      style: {
                                        maxHeight: 40 * 4.5,
                                        width: "20ch"
                                      }
                                    }}
                                  >
                                    <MenuItem
                                      key={`Remove-${index}`}
                                      onClick={() => {
                                        this.setState({
                                          deleteMember: member.attributes.first_name,
                                          deleteType: "remove_member",
                                          deleteId: member.attributes.account_id,
                                          deletePopup: true
                                        });
                                      }}
                                    >
                                      { localStorage.getItem('cuid') === member.attributes.account_id.toString() ? 'Exit' : 'Remove' }
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                              </>
                            )}
                            <img src={
                              member.attributes.profile_url === '' ?
                              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" :
                              member.attributes.profile_url
                            } />
                            <p style={{
                              overflowWrap: 'anywhere',
                              maxWidth: 'fit-content',
                              minWidth: '100%',
                              textAlign: 'center',
                            }}>{member.attributes.first_name}</p>
                            <span>{member.attributes.role}</span>
                          </div>
                        ))
                      : null}
                  </div>
                  <div />
                </div>
                <div className="userData">
                  <div />
                  <div style={{ textAlign: "center" }}>
                    {
                      (this.state.currentUserRoleClub === 'Admin' ||
                      this.state.currentUserRoleClub === 'Owner') && (
                        <Button
                          variant="outlined"
                          onClick={() =>
                            this.props.navigation.navigate("AddGroupMember", {
                              id: this.props.navigation.getParam("id", 0)
                            })
                          }
                        >
                          Add Member
                        </Button>
                      )
                    }
                  </div>
                  <div />
                </div>
              </TabPanel>
              <TabPanel value={this.state.currentTab} index={1}>
                <div className="userData">
                  <div />
                  <div>
                    <div
                      style={{
                        display: this.state.editableRevenue ? 'block': 'none',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          value={this.state.equalContri}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const totalMembers = Object.keys(this.state.clubMembers).length;
                              const eachRevenue = (100/totalMembers).toFixed(2);
                              let tmpRevenues = {};
                              Object.keys(this.state.clubMembers).map((key) => {
                                tmpRevenues = {
                                  ...tmpRevenues,
                                  [this.state.clubMembers[key].id]: eachRevenue
                                }
                              });
                              this.setState({ revenues: tmpRevenues });
                            }
                            this.setState({ equalContri: e.target.checked });
                            return;
                          }}
                        />
                        Equal Contribution
                      </label>
                    </div>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Members</TableCell>
                            <TableCell align="center">Role</TableCell>
                            <TableCell align="center">Revenue%</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.clubMembers.length > 0
                            ? this.state.clubMembers.map((member) => (
                                <TableRow key={member.attributes.account_id}>
                                  <TableCell component="th" scope="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        columnGap: '20px'
                                      }}
                                    >
                                      <img
                                        src={
                                          member.attributes.profile_url === "" ?
                                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" :
                                            member.attributes.profile_url
                                        }
                                        style={{
                                          height: "70px",
                                          width: "70px",
                                          borderRadius: "40px",
                                        }}
                                      />
                                      <span>
                                        <b>{member.attributes.first_name}</b>
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    {member.attributes.role}
                                  </TableCell>
                                  <TableCell align="center">
                                    {this.state.editableRevenue ? (
                                      <div style={{ display: 'grid', gridTemplateColumns: '0.9fr 0.1fr' }}>
                                        <input
                                          disabled={this.state.equalContri}
                                          max={100}
                                          onChange={(e) => this.setState({
                                            revenues: {
                                              ...this.state.revenues,
                                              [member.id]: e.target.value
                                            }
                                          })}
                                          value={
                                            this.state.revenues[member.id] === '' ?
                                            0 :
                                            this.state.revenues[member.id]
                                          }
                                          type="number"
                                          step={0.01}
                                          />
                                        <span>%</span>
                                      </div>
                                    ) : null}
                                    {!this.state.editableRevenue ? (
                                      <p>{this.state.revenues[member.id].length <= 0 ? '0' : this.state.revenues[member.id]}%</p>
                                    ) : null}
                                  </TableCell>
                                  <TableCell align="center">
                                    {(
                                      this.state.currentUserRoleClub === 'Admin' ||
                                      this.state.currentUserRoleClub === 'Owner'
                                    ) && member.attributes.role !== 'Owner' &&
                                    (
                                      this.state.ownerCounter !== 0 ||
                                      (
                                        member.attributes.role === 'Admin' &&
                                        this.state.adminCounter > 1
                                      ) || (
                                        member.attributes.role === 'Pattern designer'
                                      )
                                    ) && (
                                      <Button
                                        onClick={() => {
                                          this.setState({ deleteId: member.attributes.account_id }, () => {
                                            this.handleClickDeleteMember();
                                          })
                                        }}
                                      >
                                        {
                                          localStorage.getItem('cuid') === member.attributes.account_id.toString() ?
                                          'Exit' :
                                          'Remove'
                                        }
                                      </Button>
                                    )}
                                    {this.state.currentUserRoleClub === 'Pattern designer' && 
                                    localStorage.getItem('cuid') === member.attributes.account_id.toString() && (
                                      <Button
                                        onClick={() => {
                                          this.setState({ deleteId: member.attributes.account_id }, () => {
                                            this.handleClickDeleteMember();
                                          })
                                        }}
                                      >
                                        Exit
                                      </Button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {(
                      this.state.currentUserRoleClub === 'Admin' ||
                      this.state.currentUserRoleClub === 'Owner'
                    ) && (
                      <>
                        <p
                          style={{
                            display: this.state.errRevenue ? 'block': 'none',
                            color: 'red',
                            fontWeight: 'bold',
                            textAlign: 'right'
                          }}>The total contribution cannot be greater than 100%. Please revise the revenue percentage and update</p>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '15px'
                          }}>
                          <Button
                            onClick={() => this.props.navigation.navigate('AddGroupMember',{
                              id: this.props.navigation.getParam('id', 0)
                            })}
                            variant='outlined'
                          >
                            Add Member
                          </Button>
                          <Button
                            onClick={() =>
                              this.state.editableRevenue ?
                              this.updateRevenue() :
                              this.setState({ editableRevenue: true })
                            }
                            variant='contained'
                            style={{ backgroundColor: 'black', color: 'white' }}
                          >
                            {this.state.editableRevenue ? 'Update' : 'Edit'}
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div />
                </div>
              </TabPanel>
            </React.Fragment>
          </div>
                          )*/}

{/*
        <FooterWeb
          navigation={this.props.navigation}
        />
*/}
      </>
    );
  }
}

const useStyles = {
  clubSearch: {
    display: "grid",
    gridTemplateColumns: "0.75fr 0.25fr",
    padding: "10px 0px",
    marginBottom: "15px",
    columnGap: "15px"
  }
};

export default withStyles(useStyles)(DesignerGroupManage);
