import { useQueryClient } from "@tanstack/react-query";
import { DesignComment } from "../api-types";
import { commentsMessages } from "../messages-generator";
import { commentsKeys } from "../query-keys";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";


export function useComments({ designId }: { designId: number }) {

  return useDezinerQuery<{ data: DesignComment[] }>({
    message: commentsMessages.fetchComments({ designId }),
    queryKey: commentsKeys.fetchComments({ designId }),
  });
}

export function usePostComment({ designId }: { designId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof commentsMessages.postComment>[0]>({
    messageGenerator: commentsMessages.postComment,
    queryKey: commentsKeys.postComment({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(commentsKeys.fetchComments({ designId }));
      },
    }
  });
}

export function usePostReply({ designId, parentCommentId }: { designId: number, parentCommentId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof commentsMessages.postReplyComment>[0]>({
    messageGenerator: commentsMessages.postReplyComment,
    queryKey: commentsKeys.postReplyComment({ designId, parentCommentId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(commentsKeys.fetchComments({ designId }));
      },
    },
  });
}

