import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { navigateTo } from '../store/navigation/navigation.action';
import { useMyProfile } from './profile';

export function useNavigate() {

  const dispatch = useDispatch();
  const navigate = (url: string) => dispatch(navigateTo({ url }));

  return navigate;
}

export function useIsMyProfile({ id }: { id: number }) {
  const token = useDecodedToken();
  return Boolean(token.id == id);
}

export function useIsDesigner() {

  const profileQuery = useMyProfile();

  return Boolean(profileQuery?.data?.data?.attributes?.role?.toLowerCase() == 'designer');
}

export function useIsUser() {
  const profileQuery = useMyProfile();

  return Boolean((profileQuery?.data?.data?.attributes?.role?.toLowerCase() ?? '') == 'user');
}

export function useIsLoggedIn() {
  const { id } = useDecodedToken();
  const profileQuery = useMyProfile();
  return Boolean(!profileQuery.isError && id);
}

export function useDecodedToken(): { id: number, exp: number } {
  try {
    const token = localStorage.getItem('token') || '';
    if (!token) throw 'No Token';

    return jwtDecode(token);
  } catch (error) {
    console.error(error);
    return { id: 0, exp: 0 }
  }
}
