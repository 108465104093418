import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DialogContentText from "@material-ui/core/DialogContentText";
import Box from "@material-ui/core/Box";
import PeopleIcon from "@material-ui/icons/People";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Person from "@material-ui/icons/Person";
import Slider from "@material-ui/core/Slider";
import Modal from "@material-ui/core/Modal";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Edit, ChevronLeft, Close as CloseIcon } from "@material-ui/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
import "./ClubProfileByUser.web.css";
// Customizable Area End

import ClubProfileByUserController, { Props } from "./ClubProfileByUserController.web";
import ClubHeader from "./ClubHeader.web";
import Footer from "../../catalogue/src/Footer.web";
import CollectionListing from "../../catalogue/src/CollectionListing.web";
import SubHeaderWeb from "../../landingpage/src/SubHeader.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import GroupIcon from "@material-ui/icons/Group";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DesignerPromotePopUpWeb from "../../landingpage/src/DesignerPromotePopUp.web";
import CreateLabelDialog from "../../landingpage/src/CreateLabelDialog.web";

class ClubProfileByUser extends ClubProfileByUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <>
        <ClubHeader
          profileData={this.state.profileData}
          clubData={this.state.clubData}
          handleOpen={this.handleOpen}
          arrLabels={this.state.arrLabels}
          currentLabel={this.state.currentLabel}
          navigation={this.props.navigation}
          changeLabel={this.changeLabel}
          openMemberPopup={() => this.setState({ showMemberPopup: true })}
          aboutPage={false}
          onPromoteProfile={this.promoteProfile}
        />
        <div className="main-container">
          <SubHeaderWeb
            title=""
            icon=""
            arrLabels={this.state.arrLabels}
            currentLabel={this.state.currentLabel}
            navigation={this.props.navigation}
            changeLabel={this.changeLabel}
            aboutPage={false}
          />
          <div className="collectionComponent">
            {!this.state.loading ? (
              Object.keys(this.state.arrCollection).length > 0 ? (
                Object.keys(this.state.arrCollection).map((key) => {
                  return (
                    <CollectionListing
                      collection={this.state.arrCollection[key].attributes}
                      createPost={this.createPost}
                      navigation={this.props.navigation}
                      pinCollection={(collectionId: number, flag: boolean) => this.pinCollection(collectionId, flag)}
                    />
                  );
                })
              ) : (
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2>No Data</h2>
                </div>
              )
            ) : (
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </div>

          <CreateLabelDialog
            open={this.state.popupStatus}
            onClose={this.handleClose}
            onCreate={() => this.createLabel()}
          />

        
          {/* <Dialog open={this.state.popupStatus} onClose={this.handleClose} aria-labelledby="club-members">
            <DialogTitle id="club-members"><span className="groupIcon">{this.state.clubData.name}</span>{this.state.clubData.name}</DialogTitle>
            <DialogContent>
              <div className="popupSubTitle">
                <div><GroupIcon /> Members</div>
                <div><AddIcon /> Add Member</div>
              </div>
              <Divider component="li" />

            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" className="customButton">
                Cancel
              </Button>
              <Button onClick={() => this.createLabel()} color="primary">
                Create
              </Button>
            </DialogActions>
          </Dialog> */}
          <Dialog
            open={this.state.deletePopup}
            onClose={this.handleClickDeleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete Member</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure, you want to remove{" "}
                <b>{this.state.deleteMember}</b> from this club?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClickDeleteClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={this.handleClickDeleteMember}
                color="primary"
                autoFocus
              >
                Remove
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            scroll="paper"
            open={this.state.showMemberPopup}
            onClose={() => this.setState({ showMemberPopup: false })}
            aria-labelledby="Cform-dialog-title"
          >
            <Box className="Cdialog_wrapper">
              <Box className="Cdialog_main_box">
                <Box className="Cdialog_box">
                  <Typography variant="h5">
                    {Object.keys(this.state.clubData).length > 0
                      ? this.state.clubData.name.substring(0, 3)
                      : ""}
                  </Typography>
                </Box>
                <DialogTitle id="Cform-dialog-title">
                  {this.state.clubData.name}
                  <CloseIcon
                    className="Cclose_icon"
                    onClick={() => this.setState({ showMemberPopup: false })}
                  />
                </DialogTitle>
              </Box>
              <DialogContent style={{ overflow: 'scroll' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography className="Cmember_title">
                    <PeopleIcon style={{ marginRight: "10px" }} />
                    Members
                  </Typography>
                </div>
                <hr />
                <div style={{ overflow: 'scroll', maxHeight:'40vh' }}>
                  {this.state.clubMembers.length > 0 ? this.state.clubMembers.map((val: any, index: number) => (
                    <Box className="Cdialog_content_main" key={val.id}>
                      <div className="Cdialog_content_left">
                        <img
                          src={val.attributes.profile_url === '' ?
                            'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' :
                            val.attributes.profile_url
                          }
                          alt="image"
                        />
                        <div>
                          <Typography id="Cmember_name"
                            style={{ overflowWrap: 'anywhere' }}
                          >{val.attributes.first_name}</Typography>
                          <Typography id="Cmember_designation">
                            {val.attributes.user_type}
                          </Typography>
                        </div>
                      </div>
                      {/* <div className="Cdialog_content_right">
                        {this.user_tpye}
                      </div> */}
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px'
                      }}>
                        {val.attributes.user_type === 'Admin' ? (
                          <button className="adminLabel">
                            <span>
                              <Person />
                            </span>
                            <span>Club Admin</span>
                          </button>
                        ) : null}
                      </div>
                    </Box>
                  )) : null}
                </div>
              </DialogContent>
            </Box>
          </Dialog>
        </div>
        <Footer
          navigation={this.props.navigation}
        />
        {this.state.modalOpen && (
          <DesignerPromotePopUpWeb
            designerId={this.state.designerId}
            currentImage = {this.state.currentImage}
            modalOpen = {this.state.modalOpen}
            handleModalClose = {this.handleModalClose}
            navigation={this.props.navigation}
            promoteType={this.state.promoteType}
            promoteCollectionId = {0}
            id={""}
            designId={0}
          />
        )}
      </>
    );
  }
}

// Customizable Area Start

const useStyles: any = {
  formControl: {
    margin: "10px",
    minWidth: 120,
  },
  modal__step3__form: {
    display: "flex",
    flexDirection: "column",
  },
  modal__step3__cardNumber: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    "& label": {
      marginBottom: "5px",
      fontWeight: "bold",
    },
    "& input": {
      padding: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      width: "100%",
      "&:focus": {
        outline: "none",
        border: "1px solid #000",
      },
    },
  },
  modal__step3__cardDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",

    "& div ": {
      width: "40%",
      display: "flex",
      flexDirection: "column",
      "& label": {
        marginBottom: "5px",
        fontWeight: "bold",
      },
      "& input": {
        padding: "5px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        width: "100%",
        "&:focus": {
          outline: "none",
          border: "1px solid #000",
        },
      },
    },
  },
  modal__step3__price: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& span": {
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
  input__validation: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "5px",
  },
};

export default withStyles(useStyles)(ClubProfileByUser);
// Customizable Area End
