import { SET_LABELS, SetLabelsAction, FetchLabelsAction, FETCH_LABELS, ChangeLabelAction, CHANGE_LABEL } from './labels.action';
import { produce } from 'immer';
import { Label } from '../../api-types';

interface LabelsStateByType {
  [id: string]: Label[],
}

export interface LabelsState {
  designerLabels: LabelsStateByType,
  userLabels: LabelsStateByType,
  clubLabels: LabelsStateByType,
  groupLabels: LabelsStateByType,

  loading: boolean,

  currentDesignerLabel: number,
  currentGroupLabel: number,
  currentClubLabel: number,
  currentUserLabel: number,
}

const initState: LabelsState = {
  designerLabels: {},
  userLabels: {},
  clubLabels: {},
  groupLabels: {},
  loading: false,
  currentDesignerLabel: 0,
  currentClubLabel: 0,
  currentUserLabel: 0,
  currentGroupLabel: 0,
}

export type LabelsActions = SetLabelsAction | FetchLabelsAction | ChangeLabelAction;

export const labelsReducer = produce((state: LabelsState, action: LabelsActions) => {

  switch (action.type) {

    case FETCH_LABELS: {
      state.loading = true;
      break;
    }

    case SET_LABELS: {
      const { designerId, userId, groupId, clubId } = action;

      if (designerId)
        state.designerLabels[designerId] = action.labels;
      else if (userId)
        state.userLabels[userId] = action.labels;
      else if (groupId)
        state.groupLabels[groupId] = action.labels;
      else if (clubId)
        state.clubLabels[clubId] = action.labels;

      state.loading = false;
      break;
    }

    case CHANGE_LABEL: {
      const { type, ...payload } = action;

      return { ...state, ...payload };
    }
  }
}, initState);
