import React from 'react';
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { withRouter } from 'react-router-dom';

export const RouterContext = React.createContext<any>({});


export const RouterContextProvider = (withRouter as any)(function _RouterContextProvider(props: any) {


  return (
    <RouterContext.Provider value={{ ...props.match.params }}>
      {props?.children}
    </RouterContext.Provider>
  );
});



export function useParams() {

  let params = React.useContext(RouterContext);

  return params || {};
}

export function appendToken(headers: any, token?: string) {
  if (headers && !headers?.['token']) {
    let _token = token || localStorage.getItem('token') || '';
    if (_token)
      headers['token'] = _token;
  }

  return headers;
}
export function appendJsonContentType(headers: any) {
  if (headers && !headers?.['Content-Type'])
    headers['Content-Type'] = 'application/json';

  return headers;
}

export function extractAPIResponse(message: Message): {
  messageId: string | undefined,
  responseJson: any | undefined,
  errors: any | undefined
} {

  try {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let apiError = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const errors = apiError || responseJson?.error || responseJson?.errors || undefined;

      let messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      return {
        responseJson: errors ? undefined : responseJson,
        errors,
        messageId
      }
    }

    return {
      responseJson: undefined,
      errors: undefined,
      messageId: undefined,
    }
  } catch (error) {
    console.error(error);

    return {
      responseJson: undefined,
      errors: undefined,
      messageId: undefined,
    }
  }
}








interface MessageHandlersParams {
  message?: Message,
  handlers: {
    [id: string]: {
      onSuccess?: (response: any) => void,
      onError?: (errors: any) => void,
      afterAll?: (response: any) => void,
    }
  }
}

export function defineMessageHandlers(params: MessageHandlersParams) {


  const { handlers, message } = params;

  if (!message) return;

  const { responseJson, errors, messageId } = extractAPIResponse(message);

  if (messageId && handlers[messageId]) {

    const { onSuccess, onError, afterAll } = handlers[messageId];

    if (errors && onError)
      onError(errors);
    else if (responseJson && onSuccess)
      onSuccess(responseJson);

    if (afterAll)
      afterAll(responseJson)
  }
}




export function extractRedirectionInfo() {

  let redirectUrl = localStorage.getItem("redirectUrl") || "";
  let redirectParams = localStorage.getItem("redirectParams") || "";

  try {
    redirectParams = redirectParams ?
      JSON.parse(redirectParams)
      :
      {};
  } catch (error) {
    console.error(error);
  }

  localStorage.setItem("redirectUrl", "");
  localStorage.setItem("redirectParams", "");

  return [redirectUrl, redirectParams];
}


export async function urlToBase64(url: string) {
  return new Promise((resolve) => {

    let xhr = new XMLHttpRequest();

    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}


export function statisticsFormatter(value: number, digits: number = 2) {

  const statsFormats = [
    { value: 1, symbol: " " },
    { value: 1e3, symbol: " k" },
    { value: 1e6, symbol: " M" },
    { value: 1e9, symbol: " G" },
    { value: 1e12, symbol: " T" },
    { value: 1e15, symbol: " P" },
    { value: 1e18, symbol: " E" }
  ];
  const extraNumbersRegExp = /\.0+$|(\.[0-9]*[1-9])0+$/;

  var formatterItem = statsFormats
    .slice()
    .reverse()
    .find(item => value >= item.value);

  return formatterItem ?
    (value / formatterItem.value)
      .toFixed(digits)
      .replace(extraNumbersRegExp, "$1") + formatterItem.symbol
    :
    "0";
}


export async function encodeFile(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onloadend = function() {
      let imagebase64 = reader.result;
      resolve(imagebase64 as string);
    };
    reader.readAsDataURL(file);
  });
}

