import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useProfile } from './hooks';
import { DEFAULT_PROFILE_IMAGE } from './defaults';
import { PostedBy, Profile } from './api-types';
import clsx from 'clsx';

const useProfileAvatarStyles = makeStyles({
  avatar: {
    width: '80px',
    height: '80px',
    borderRadius: '40%',
    objectFit: 'cover',
  },
  smallAvatar: {
    width: '35px',
    height: '35px',
  },
});

export default function ProfileAvatar({ id }: { id?: number }) {

  const profileQuery = useProfile({ id: id || 0 }, { enabled: Boolean(id) });

  return (
    <ProfileAvatarUI
      profile={profileQuery?.data?.data}
    />
  );
}

export function ProfileAvatarUI({ profile, size, }: { profile?: Profile | PostedBy, size?: "small" }) {

  const profileAttributes = profile?.attributes;

  const classes = useProfileAvatarStyles();

  const profileImage = (profile as Profile)?.attributes?.profile_thumbnail_url ||
    (profile as PostedBy)?.attributes?.profile_url;

  if (profileImage) {
    return (<img
      className={clsx(classes.avatar, {
        [classes.smallAvatar]: size == "small"
      })}
      src={profileImage}
      alt={profileAttributes?.first_name}
    />);
  }

  if (profileAttributes?.color) {
    return (<div
      className={clsx(classes.avatar, {
        [classes.smallAvatar]: size == "small"
      })}
      style={{
        backgroundColor: profileAttributes?.color,
      }}
    />);
  }

  return (<img
    className={clsx(classes.avatar, {
      [classes.smallAvatar]: size == "small"
    })}
    src={DEFAULT_PROFILE_IMAGE}
    alt={profileAttributes?.first_name}
  />);
}

