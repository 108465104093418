import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import CloseIcon from '@material-ui/icons/Close';


const useDialogStyles = makeStyles({
	paper: {
		borderRadius: '10px',
	}
});


const useStyles = makeStyles({

	imageContainer: {
		background: "#e7e7e7",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderTopRightRadius: "10px",
		borderTopLeftRadius: "10px"
	},
	image: {
		maxWidth: "80%",
		borderRadius: "10px",
		position: "relative",
		top: "-50px",
		boxShadow: "4px 4px 4px #5e5e5e"
	},
	roundedImage: {
		borderRadius: '40%',
	},
	contentContainer: {
		maxWidth: "80%",
		margin: "auto",
		padding: "20px 0",
		'& > *': {
			margin: '25px 0',
		}
	},
	copyContainer: {
		border: "1px solid #e2e3e5",
		display: "flex",
		padding: "0",
		justifyContent: "space-between",
		borderRadius: "5px",
		alignItems: "center"
	},
	copyBtn: {
		background: "#e2e3e5",
		borderTopLeftRadius: "0",
		borderBottomLeftRadius: "0",
		fontWeight: "bold",
		padding: "10px 20px",
		cursor: "pointer"
	},
	copyLink: {
		maxWidth: "260px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		margin: "0",
		padding: "4px 0 4px 10px",
		color: "#85868a",
		fontWeight: 600
	},
	centerText: {
		textAlign: 'center',
	},
	text1: {
		color: "#2e2f31",
		fontWeight: "bold",
		fontSize: "1.6rem"
	},
	text2: {
		color: "#75797c",
		fontWeight: 600,
		fontSize: "1.1rem"
	},
	iconButton: {
		background: '#e1e1e1'
	},
	closeBtn: {
		position: 'fixed',
		top: '3vh',
		right: '3vw',
	},
	closeIcon: {
		color: 'white'
	}
});

const ShareDesignDialog = ({
	open,
	designLink,
	shareLink,
	onClose,
	onCopy,
	rounded,
}: {
	open: boolean,
	designLink: string,
	shareLink: string,
	onClose: () => void,
	onCopy: () => void,
	rounded?: boolean,
}) => {

	const classes = useStyles();
	const dialogClasses = useDialogStyles();

	function copyHandler() {
		navigator.clipboard.writeText(shareLink);
		onCopy();
		setTimeout(() => {
			onClose();
		}, 50);
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xs"
			classes={dialogClasses}
		>

			<div className={classes.imageContainer}>
				<img
					className={clsx(classes.image, {
						[classes.roundedImage]: rounded
					})}
					src={designLink}
				/>
			</div>

			<IconButton className={classes.closeBtn} onClick={onClose}>
				<CloseIcon className={classes.closeIcon} fontSize="large" />
			</IconButton>

			<DialogContent className={classes.contentContainer}>

				<Typography variant='body1' className={clsx(classes.centerText, classes.text1)}>
					{'Share this with your social Community'}
				</Typography>

				<Grid container justifyContent='center'>
					{/* <Grid item xs={2} container justifyContent="center">
						<a
							target="_blank"
							href={`#`}
						>
							<IconButton className={classes.iconButton}>
								{'D'}
							</IconButton>
						</a>
					</Grid> */}
					<Grid item xs={2} container justifyContent="center">
						<a
							target="_blank"
							href={`https://pinterest.com/pin/create/link/?url=${shareLink}&media_url=${designLink}`}
						>
							<IconButton className={classes.iconButton}>
								<PinterestIcon />
							</IconButton>
						</a>
					</Grid>
					<Grid item xs={2} container justifyContent="center">
						<a
							target="_blank"
							href={`https://twitter.com/share?url=${shareLink}`}
						>
							<IconButton className={classes.iconButton}>
								<TwitterIcon />
							</IconButton>
						</a>
					</Grid>
					<Grid item xs={2} container justifyContent="center">
						<a
							target="_blank"
							href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
						>
							<IconButton className={classes.iconButton}>
								<FacebookIcon />
							</IconButton>
						</a>
					</Grid>
					<Grid item xs={2} container justifyContent="center">
						<a target="_blank" href="#">
							<IconButton className={classes.iconButton}>
								<InstagramIcon />
							</IconButton>
						</a>
					</Grid>
				</Grid>


				<Typography variant="body2" className={clsx(classes.centerText, classes.text2)}>
					{'or Copy Link'}
				</Typography>

				<div className={classes.copyContainer}>
					<Typography className={classes.copyLink}>
						{shareLink}
					</Typography>
					<Button className={classes.copyBtn} onClick={copyHandler}>
						{'Copy'}
					</Button>
				</div>

			</DialogContent>

		</Dialog>
	);
}

export default ShareDesignDialog;
