import { QueryObserverOptions, useQueryClient } from "@tanstack/react-query";
import { APIData, CollectionHome, Design, DesignCategory, DezinerShare, LandingPageResponse, Profile, SourceFile, UserDesignsFilter } from "../api-types";
import { designsMessages } from "../messages-generator";
import { designsKeys, profileKeys } from "../query-keys";
import { useDezinerInfiniteQuery, useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";
import { useIsMyProfile } from "./utils";


export function useBoughtDesigns() {

  return useDezinerQuery({
    message: designsMessages.fetchBoughtDesigns(),
    queryKey: designsKeys.fetchBoughtDesigns(),
  });
}


export function useCreateBuyIntent(
  params: Omit<Parameters<typeof designsMessages.createPaymentIntent>[0], "price">
) {

  return useDezinerMutation<Parameters<typeof designsMessages.createPaymentIntent>[0]>({
    messageGenerator: designsMessages.createPaymentIntent,
    queryKey: designsKeys.createPaymentIntent(params),
  });

}

export function useInspireDesign({ designId }: { designId: number }) {

  const queryClient = useQueryClient();
  const designerId = useDesignerIdByDesignFromQueryClient({ designId });

  return useDezinerMutation<Parameters<typeof designsMessages.inspireDesign>[0]>({
    messageGenerator: designsMessages.inspireDesign,
    queryKey: designsKeys.inspireDesign({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId }));
        queryClient.invalidateQueries(designsKeys.fetchInspiredUsers({ designId }));

        if (designerId)
          queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }))
      }
    }
  });
}

function useDesignerIdByDesignFromQueryClient({ designId }: { designId: number }) {

  const queryClient = useQueryClient();

  const data: any = queryClient.getQueryData(designsKeys.fetchSingleDesign({ designId }))
  const design = data?.design?.data as Design;
  const designerId = design?.attributes?.designer?.data?.attributes?.id ?? 0;

  return designerId;
}


export function useLikeDesign({ designId }: { designId: number }) {

  const queryClient = useQueryClient();

  const designerId = useDesignerIdByDesignFromQueryClient({ designId });

  return useDezinerMutation<Parameters<typeof designsMessages.likeDesign>[0]>({
    messageGenerator: designsMessages.likeDesign,
    queryKey: designsKeys.likeDesign({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId }));

        if (designerId)
          queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }));
      }
    }
  });
}


export function useSourceFiles({ designId }: { designId: number }, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: SourceFile[], design_url: string }>({
    message: designsMessages.fetchSourceFiles({ designId }),
    queryKey: designsKeys.fetchSourceFiles({ designId }),
    options,
  });
}


export function useUpdateDesign({ designId, labelId, designerId }: { designId: number, labelId: number, designerId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designsMessages.updateDesign>[0]>({
    messageGenerator: designsMessages.updateDesign,
    queryKey: designsKeys.updateDesign({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(designsKeys.fetchCollctions({ labelId, designerId }));
      }
    }
  });
}


export function useUploadDesign({ collectionId, labelId, designerId }: { collectionId: number, labelId: number, designerId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designsMessages.uploadDesign>[0]>({
    messageGenerator: designsMessages.uploadDesign,
    queryKey: designsKeys.uploadDesign({ labelId, collectionId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(designsKeys.fetchCollctions({ labelId, designerId }));
      }
    }
  });
}


export function useRateDesign({ designId }: { designId: number }) {

  const queryClient = useQueryClient();

  const designerId = useDesignerIdByDesignFromQueryClient({ designId });

  return useDezinerMutation<Parameters<typeof designsMessages.rateDesign>[0]>({
    messageGenerator: designsMessages.rateDesign,
    queryKey: designsKeys.rateDesign({ designId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(designsKeys.fetchSingleDesign({ designId }));

        if (designerId)
          queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }));
      },
    },
  });
}

export function useInspiredUsers({ designId }: { designId: number }) {

  return useDezinerQuery<{ data: Profile[] }>({
    queryKey: designsKeys.fetchInspiredUsers({ designId }),
    message: designsMessages.fetchInspiredUsers({ designId }),
  });
}


export function useMoreDesigns({ designId }: { designId: number }) {

  return useDezinerQuery<{ data: Design[] }>({
    queryKey: designsKeys.fetchMoreDesigns({ designId }),
    message: designsMessages.fetchMoreDesigns({ designId }),
  });
}


export function useDesignShareLinks({ designId }: { designId: number }) {

  return useDezinerQuery<{ data: DezinerShare, meta: { message: string, share_url: string } }>({
    message: designsMessages.fetchShareLinks({ designId }),
    queryKey: designsKeys.fetchShareLinks({ designId }),
  });
}




export function useSingleDesign({ designId }: { designId: number }) {

  return useDezinerQuery<{ design: APIData<Design> }>({
    message: designsMessages.fetchSingleDesign({ designId }),
    queryKey: designsKeys.fetchSingleDesign({ designId }),
    options: {
      enabled: Boolean(designId)
    }
  });
}


export function useLandingPageData({
  selectedCategories,
  selectedFilter,
}: {
  selectedCategories: number[],
  selectedFilter: string,
}) {

  return useDezinerInfiniteQuery<LandingPageResponse>({
    messageGenerator: designsMessages.fetchLandingPageDesigns,
    queryKey: designsKeys.fetchLandingPageDesigns({ selectedCategories, filter: selectedFilter }),
    defaultPageParam: {
      selectedCategories, filter: selectedFilter, page: 1
    },
    options: {
      getNextPageParam: (lastPage: any, pages: any[]) => {

        if (((lastPage as LandingPageResponse)?.data?.designes?.data?.length ?? 0) < 16)
          return undefined;

        return {
          selectedCategories, filter: selectedFilter, page: pages.length + 1
        }
      }
    }
  });
}

export function useDesignerCollectionsByUser({
  designerId, labelId, activeCategory,
}: { designerId: number, labelId: number, activeCategory: DesignCategory },
  options?: QueryObserverOptions) {


  return useDezinerQuery<{ data: CollectionHome[], meta: any }>({
    message: designsMessages.fetchDesignerCollectionsByUser({
      designerId,
      labelId,
    }),
    queryKey: designsKeys.fetchDesignerCollectionsByUser({ designerId, labelId }),
    options: {
      enabled: (options?.enabled ?? true) && Boolean(designerId) && activeCategory == 'collections',
      ...options,
    }
  });

}

export function useDesignerDesignsByUser({
  designerId,
  labelId,
  category,
}: {
  designerId: number,
  labelId: number,
  category: DesignCategory,
}, options?: QueryObserverOptions) {



  const categoryToEndpointMapper: {
    [key in DesignCategory]: string
  } = {
    'collections': '',
    'designs': 'other_user_all_designs',
    'liked': 'other_user_liked_designs',
    'promoted': 'other_user_promoted_designs',
    'rated': 'other_user_rated_designs',
    'inspired': 'other_user_inspired_designs',
    'free': 'other_user_free_designs',
    'paid': 'other_user_paid_designs',
    'all': '',
    saved: '',
    shared: ''
  }


  let endpoint = categoryToEndpointMapper?.[category] ?? '';


  return useDezinerQuery<{ data: Design[], meta: any }>({
    message: designsMessages.fetchDesignerDesignsByUser({
      endpoint,
      designerId,
      labelId,
    }),
    queryKey: designsKeys.fetchDesignerDesignsByUser({ designerId, labelId, category }),
    options: {
      ...options,
      enabled: (options?.enabled ?? true) && Boolean(designerId) && category != 'collections',
    }
  });
}



export function useUserDesigns({
  filter,
  category,
}: {
  filter: UserDesignsFilter,
  category: DesignCategory
}) {

  return useDezinerQuery<{ data: Design[], meta: any }>({
    message: designsMessages.fetchUsersDesigns({ filter, category }),
    queryKey: designsKeys.fetchUserDesigns({ category, filter }),
    options: {
      staleTime: 1000 * 60 * 10,
    }
  });

}

export function useDesigns({
  category,
  labelId,
  designerId,
}: {
  category: DesignCategory,
  labelId: number,
  designerId: number,
}) {

  const isMyProfile = useIsMyProfile({ id: designerId });

  const designsQuery = useDesignerDesigns({ category, labelId, designerId }, {
    enabled: isMyProfile
  });

  const designsByOthers = useDesignerDesignsByUser({ designerId, labelId, category }, {
    enabled: !isMyProfile
  });

  return isMyProfile ? designsQuery : designsByOthers;
}

export function useDesignerDesigns({
  category,
  labelId,
  designerId,
}: {
  category: DesignCategory,
  labelId: number,
  designerId: number,
}, options?: QueryObserverOptions) {


  const categoryToEndpointMapper: {
    [key in DesignCategory]: string
  } = {
    'collections': '',
    'designs': '',
    'liked': 'like_designs',
    'promoted': 'promoted_designs',
    'rated': 'raterd_designs',
    'inspired': 'inspired_designs',
    'free': 'free_designs',
    'paid': 'paid_designs',
    'all': '',
    saved: '',
    shared: ''
  }


  return useDezinerQuery<{ data: Design[] }>({
    queryKey: designsKeys.fetchDesigns({ category, labelId, designerId }),
    message: designsMessages.fetchDesignerDesigns({
      endpoint: categoryToEndpointMapper[category],
      url: category == 'designs' ? 'bx_block_attachment/designs' : '',
      labelId,
    }),
    options: {
      ...options,
      enabled: (options?.enabled ?? true) && category != 'collections',
    },
  });
}

export function useCollections({
  category,
  labelId,
  designerId,
}: {
  category: DesignCategory,
  labelId: number,
  designerId: number,
}, options?: QueryObserverOptions) {

  const isMyProfile = useIsMyProfile({ id: designerId });

  const collectionsQuery = useDesignerCollections({ designerId, category, labelId }, {
    ...options,
    enabled: (options?.enabled ?? true) && isMyProfile
  });

  const collectionsByUserQuery = useDesignerCollectionsByUser({
    designerId, activeCategory: category, labelId
  }, {
    ...options,
    enabled: (options?.enabled ?? true) && !isMyProfile
  });

  return isMyProfile ? collectionsQuery : collectionsByUserQuery;
}


export function useDesignerCollections({
  category,
  labelId,
  designerId,
}: {
  category: DesignCategory,
  labelId: number,
  designerId: number,
}, options?: QueryObserverOptions) {

  return useDezinerQuery<{ data: CollectionHome[] }>({
    queryKey: designsKeys.fetchCollctions({ designerId, labelId }),
    message: designsMessages.fetchDesignerCollections({
      labelId
    }),
    options: {
      enabled: (options?.enabled ?? true) && category == 'collections',
      ...options
    },
  });

}
