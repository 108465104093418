import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Divider from '@material-ui/core/Divider';
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import Slider from "@material-ui/core/Slider";
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import {
  Reply,
  TrendingUp,
  Info,
  Favorite,
  WbIncandescent,
  Visibility,
  Add,
  Star,
  ChatBubble,
  ChevronLeft,
  Close as CloseIcon,
  Edit,
} from "@material-ui/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
// Customizable Area End

import UserUploadedDesignViewController, {
  Props,
} from "./UserUploadedDesignViewController.web";
import Footer from "../../catalogue/src/Footer.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import TopHeaderUserWeb from "./TopHeaderUser.web";
import "./UserUploadedDesignView.css";
import { toast } from "react-toastify";

class UserUploadedDesignView extends UserUploadedDesignViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <>
        <TopHeaderUserWeb
          navigation={this.props.navigation}
          userProfileImg={this.state.profileImgUrl}
          clubName=''
          groupName=''
        />
        <div className="main-container">
          {!this.state.loading ? (
            <>
              <div className="userData">
                <div />
                <div className="userDataInfo">
                  <div className="userDataImg">
                    <img
                      src={
                        this.state.designData.designer.data.attributes
                          .profile_url === ""
                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                          : this.state.designData.designer.data.attributes
                              .profile_url
                      }
                      alt={
                        this.state.designData.designer.data.attributes
                          .first_name
                      }
                    />
                  </div>
                  <div className="userDataName">
                    <h4>
                      <b>{this.state.designData.title}</b>
                    </h4>
                    <p>
                      {
                        this.state.designData.designer.data.attributes
                          .first_name
                      }
                    </p>
                  </div>
                </div>
                <div />
              </div>
              <div className="designerDesign">
                <div className="designerDesignOptions">
                  <Tooltip title="Share" arrow placement="left">
                    <Button onClick={() => this.shareDesign()}>
                      <Reply style={{ transform: "scaleX(-1)" }} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Info" arrow placement="left">
                    <Button>
                      <Info />
                    </Button>
                  </Tooltip>
                </div>
                <div className="designerDesignMainImage">
                  {this.state.currentImageType === 'image' ? (
                    <img src={this.state.currentImage} />
                  ) : (
                    <video
                      autoPlay
                      controls
                    >
                      <source src={this.state.currentImage} />
                    </video>
                  )}
                </div>
                <div className="desingerDesignSubImage">
                  {this.state.designImages.length > 0
                    ? this.state.designImages.map((img: any, index: number) => {
                        const currentClass =
                          index === 0 ? "subImages active" : "subImages";
                        return (
                          img.type.includes("image/") ? (
                            <img
                              src={img.url}
                              className={currentClass}
                              onClick={(e) => this.handleImageClick(e, 'image', img.url)}
                            />
                          ) : (
                            <video
                              className={currentClass}
                              onClick={(e) => this.handleImageClick(e, 'video', img.url)}
                            >
                              <source src={img.url} type={img.type} />
                            </video>
                          )
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="designerDesingData">
                <div />
                <div className="designerDesignDataAttr">
                  <p>
                    <WbIncandescent />{" "}
                    <span>{this.state.designData.inspired_count}</span>
                  </p>
                  <p>
                    <ChatBubble />{" "}
                    <span>{this.state.designData.comment_count}</span>
                  </p>
                  <p>
                    <Favorite /> <span>{this.state.designData.like_count}</span>
                  </p>
                  <p>
                    <Visibility />{" "}
                    <span>{this.state.designData.view_count}</span>
                  </p>
                  <p>
                    <Add /> <span>0</span>
                  </p>
                  <p>
                    <Star /> <span>{this.state.designData.rating_count}</span>
                  </p>
                  <Button variant="outlined">Edit</Button>
                  <Button variant="outlined">Delete</Button>
                </div>
                <div />
              </div>
              <div className="designDescription">
                <div />
                <p>{this.state.designData.description}</p>
                <div />
              </div>
              <div className="designDescription">
                <div />
                <div className="designerData">
                  <img
                    src={
                        this.state.designData.designer.data.attributes.profile_url === ""
                        ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                        : this.state.designData.designer.data.attributes.profile_url
                    }
                  />
                  <p>
                    {this.state.designData.designer.data.attributes.first_name}
                    <br />
                    <span>{this.state.designData.designer.data.attributes.role}</span>
                  </p>
                </div>
                <div />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
        <Footer
          navigation={this.props.navigation}
        />
        <div
          className="promotePopup"
          style={{ display: this.state.shareDialogStatus ? "flex" : "none" }}
        >
          <span
            className="promotePopupCloseIcon"
            onClick={() => this.setState({ shareDialogStatus: false })}
          >
            <CloseIcon />
          </span>
          <div className="promoteContainer">
            <div className="promoteTitle imgtitle">
              <img src={this.state.shareDesignLink} />
            </div>
            <>
              <div className="promoteMainContainer">
                <p className="containerTitle">Share this with your social Community</p>
                <div className="">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <a
                      target="_blank"
                      href="#"
                      className="shareSocialIcons">D</a>
                    <a
                      target="_blank"
                      href={`https://pinterest.com/pin/create/link/?url=${this.state.shareLink}&media_uri=${
                        this.state.shareLink
                      }`}
                      className="shareSocialIcons">
                        <PinterestIcon />
                    </a>
                    <a
                      target="_blank"
                      href={`https://twitter.com/share?url=${this.state.shareLink}`}
                      className="shareSocialIcons">
                        <TwitterIcon />
                    </a>
                    <a
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${this.state.shareLink}`}
                      className="shareSocialIcons"
                    >
                      <FacebookIcon />
                    </a>
                    <a target="_blank" href="#" className="shareSocialIcons">
                      <InstagramIcon />
                    </a>
                  </div>
                  <div style={{ textAlign: 'center', margin:'20px auto' }}>
                    Or Copy link
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <input
                      disabled
                      value={this.state.shareLink}
                      style={{ padding: '10px', paddingRight: '100px', width: '200px' }}
                    />
                    <button
                      style={{
                        marginLeft: '-100px',
                        width: '100px',
                        padding: '10px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(this.state.shareLink);
                        toast.success("Link Copied");
                      }}
                    >Copy</button>
                  </div>
                </div>
              </div>
              </>
          </div>
        </div>
      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = {
  formControl: {
    margin: "10px",
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: "20px",
  },
};
export default withStyles(useStyles)(UserUploadedDesignView);
// Customizable Area End
