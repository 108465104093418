import React from 'react';
import DesignerEditPasswordsPageController, { errorMerger, Props } from './DesignerEditPasswordsPageController.web';
import Alert from '@material-ui/lab/Alert';
import ChangePasswordForm from './DezinerChangePasswordForm.web';
import { useMyProfile, useUpdatePassword } from '../../../components/src/hooks';


class DesignerEditPasswordsPage extends DesignerEditPasswordsPageController {

  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {


    return <ChangePasswordFormContainer />
  }
}


function ChangePasswordFormContainer() {

  const profileQuery = useMyProfile();
  const usedOauthToLogin = Boolean((profileQuery?.data?.data?.attributes?.type ?? '') == "SocialAccount");

  const { mutate: changePassword, isLoading, error: responseError } = useUpdatePassword();

  let errors = {}
  if (typeof responseError == 'string') {
    errors = { old_password: responseError }
  } else if (Array.isArray(responseError)) {
    errors = responseError.reduce(errorMerger, {});
  }


  return (
    <div>
      {
        usedOauthToLogin ?
          <Alert severity="warning" style={{ maxWidth: '400px', margin: '40px auto' }}>
            {'You are logged in using Google/Microsoft, you can\'t set or change passwords here, you need to check your social account for that.'}
          </Alert>
          :
          <ChangePasswordForm
            onSubmit={changePassword}
            loading={isLoading}
            errors={errors}
          />
      }
    </div>
  )

}


export default DesignerEditPasswordsPage;
