// @ts-nocheck
import React from "react";
// Customizable Area Start
import "./UserProfileByOthers.web.css";

// Customizable Area End

import UserProfileByOthersController, {
  Props,
} from "./UserProfileByOthersController.web";
import Footer from "../../catalogue/src/Footer.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import HeaderUser from "./HeaderUser.web";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import MenuItem from "@material-ui/core/MenuItem";
import { design, about, plus, likes, share, lightbulb, star } from "./assets";
import { wishlist } from "./assets";

class UserProfileByOthers extends UserProfileByOthersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { activeFilterId, loading, screenNumber } = this.state;
    const CardContainer = (props: any) => {
      console.log('spec_props', props)
      return (
        <>
          {props.data.map((item: any, index: number) => {
            console.log(item);
            const role = item.attributes.designer.data.attributes.role;
            let role_design;
            if (role === "Club") {
              role_design = "card-role-club";
            } else if (role === "Designer") {
              role_design = "card-role-designer";
            } else if (role === "Group") {
              role_design = "card-role-group";
            }

            return (
              <div className="card" key={index}>
                <div
                  className="card-image-div"
                  onClick={() => {
                    this.props.navigation.navigate("DesignViewByUser", {
                      id: item.attributes.id
                    })
                  }}
                >
                  <img className="card-image" src={item.attributes.image_url} />
                  <div className="card-image-overlay" />
                  <span className="card-image-text">
                    {item.attributes.title}
                  </span>
                  <button
                    onClick={() => this.onTrendingButtonClick(item)}
                    className="card-image-button card-image-impression-button"
                  >
                    <img src={lightbulb} />
                  </button>
                  <button
                    onClick={() => this.onEditButtonClick(item)}
                    className="card-image-button card-image-plus-button"
                  >
                    <img src={plus} />
                  </button>
                  <button
                    onClick={() => this.onEditButtonClick(item)}
                    className="card-image-button card-image-share-button"
                  >
                    <img src={share} />
                  </button>
                </div>
                <div className="card-details">
                  <div className="card-details-designer-info">
                    <img
                      src={
                        item.attributes.designer.data.attributes
                          .profile_thumbnail_url
                      }
                    />
                    <span>
                      {item.attributes.designer.data.attributes.first_name}
                    </span>
                    <span className={role_design} style={{ fontSize: "12px" }}>
                      <b>{item.attributes.designer.data.attributes.role}</b>
                    </span>
                  </div>
                  <div className="card-icons">
                    <span className="card-icons-container">
                      <img className="card-icons-icon" src={star} />
                      {item.attributes.rating_count}
                    </span>
                    <span className="card-icons-container">
                      <img className="card-icons-icon" src={likes} />
                      {item.attributes.like_count}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    };
    const CardContainerCollection = (props: any) => {
      if (props.data.length === 0) return null;
      return (
        <>
          <div className={classes.card__container}>
            {props.data.map((collection) => {
              return (
                <div className={classes.collection__container}>
                  <h4>
                    {collection.attributes.name}(
                    {collection.attributes.design_count})
                  </h4>
                  <div className={classes.collection__design__container}>
                    {collection.attributes.designs.data.map((design) => {
                      // design.attributes.design_type = "Club";
                      return (
                        <div className={classes.designs}>
                          <img
                            src={design.attributes.image_url}
                            className={classes.designs__image}
                            onClick={() => {
                              this.props.navigation.navigate("DesignViewByUser", {
                                id: design.id
                              })
                            }}
                          />
                          <div className={classes.designs__details}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  border: "none",
                                  borderRadius: "40%",
                                  backgroundColor: `${design.attributes.designer.data.attributes
                                    .color
                                    }`,
                                  marginRight: "5px",
                                }}
                              />
                              <span>{design.attributes.designer_name}</span>
                            </div>
                            {design.attributes.design_type === "User" ||
                              design.attributes.design_type === "Designer" ? (
                              <span>
                                <b>{design.attributes.design_type}</b>
                              </span>
                            ) : (
                              <span
                                style={
                                  design.attributes.design_type === "Group"
                                    ? {
                                      color: "black",
                                      border: "1px solid black",
                                      backgroundColor: "white",
                                      padding: "3px 8px",
                                    }
                                    : {
                                      color: "white",
                                      backgroundColor: "black",
                                      border: "none",
                                      padding: "3px 8px",
                                    }
                                }
                              >
                                <b>{design.attributes.design_type}</b>
                              </span>
                            )}
                            <div className={classes.designs__ratings}>
                              <div>
                                <img src={star} />
                                {design.attributes.rating_count}
                              </div>
                              <div>
                                <img src={wishlist} />
                                {design.attributes.like_count}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    };

    const FilterArea = () => {
      return (
        <div className="filter">
          {filters.map((item) => {
            return (
              <div
                onClick={() => {
                  this.setState({
                    activeFilterId: item.id,
                    activeSubFilter: "All",
                  });
                  this.setSubFilters(item.id);
                  this.getDesigns(item.id, "All");
                }}
                className={
                  activeFilterId === item.id
                    ? "filter-item filter-item-active"
                    : "filter-item"
                }
                key={item.id}
              >
                {item.icon !== null ? (
                  <img src={item.icon} alt={item.name} />
                ) : null}
                <p>{item.name}</p>
              </div>
            );
          })}
        </div>
      );
    };

    const SubFilters = (props: any) => {
      return (
        <div className="sub-filter">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.activeSubFilter}
            label="activeSubFilter"
            onChange={(event: any) => {
              this.setState({ activeSubFilter: event.target.value });
              this.getDesigns(this.state.activeFilterId, event.target.value);
            }}
          >
            {Object.keys(props.subFilters).map((key: any) => (
              <MenuItem
                value={props.subFilters[key]} key={key}
                disabled={Object.keys(this.state.meta).length > 0 && key in this.state.meta && this.state.meta[key] === 0}
              >
                {props.subFilters[key]}{" "}{Object.keys(this.state.meta).length > 0 && key in this.state.meta ? `(${this.state.meta[key]})` : ''}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    };

    const LeftSubHeader = () => {
      let icon: any, title: any;
      const { activeFilterId } = this.state;
      switch (activeFilterId) {
        case 6:
          icon = design;
          title = "Designs";
          break;
        case 5:
          icon = star;
          title = "Rated Designs";
          break;
        case 4:
          icon = lightbulb;
          title = "Inspired Designs";
          break;
        case 3:
          icon = share;
          title = "Shared Designs";
          break;
        case 2:
          icon = likes;
          title = "Liked Designs";
          break;
        case 1:
          icon = likes;
          title = "Saved Designs";
          break;
      }
      return (
        <div className="design-area-header-left">
          <img src={icon} />
          <p>{title}</p>
        </div>
      );
    };

    const DesignArea = (props: any) => {
      return (
        <div className="design-area">
          <div className="design-area-header">
            <LeftSubHeader />
            <div className="design-area-header-left">
              <SubFilters subFilters={this.state.subFilters} />
            </div>
          </div>
          {this.state.activeFilterId === 1 ? (
            <div style={{
              width: '100%'
            }}>
              <CardContainerCollection
                navigation={props.navigation}
                data={props.designs}
              />
            </div>
          ) : (
            <div className="card-container">
              <CardContainer data={props.designs} />
            </div>
          )}
        </div>
      );
    };

    const { classes } = this.props;

    return (
      <>
        <HeaderUser
          currentLabel={1}
          navigation={this.props.navigation}
          profileData={this.state.profileData}
          arrLabels={labels}
          changeScreenNumber={this.changeScreenNumber}
        />
        {!loading ? (
          <>
            {screenNumber === 1 ? (
              <main>
                <FilterArea />
                <DesignArea designs={this.state.designs} />
              </main>
            ) : null}
            {screenNumber === 2 ? (
              <main>
                <div className="main-container" style={{ width: '100%' }}>
                  <div className={classes.clubAbout}>
                    <div />
                    <div>
                      <h3>
                        <b>About</b>
                      </h3>
                      <div className={classes.mainSection}>
                        <div>
                          <div
                            style={{
                              marginBottom: "15px",
                              border: "1px solid white"
                            }}
                            className={classes.sectionContainer2}
                          >
                            <p className={classes.subTitle}>
                              <span>Bio</span>
                            </p>
                            <p>{this.state.profileDataForAbout.attributes.bio}</p>
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              columnGap: "10px",
                              marginBottom: "15px",
                              backgroundColor: "lightgrey",
                              color: "black",
                              width: "100%",
                              padding: "5px 10px",
                              justifyContent: "space-around",
                              borderRadius: "5px",
                            }}
                          >
                            <p style={{ display: "flex", alignItems: "center" }}>
                              {" "}
                              <AddIcon style={{ marginRight: "8px" }} />
                              {this.state.profileDataForAbout.attributes.follower_count}{" "}
                              followers{" "}
                            </p>
                            <p style={{ display: "flex", alignItems: "center" }}>
                              {" "}
                              <CheckIcon style={{ marginRight: "8px" }} />
                              {this.state.profileDataForAbout.attributes.following_count}{" "}
                              following{" "}
                            </p>
                          </div>
                          <div
                            style={{
                              marginBottom: "15px",
                              backgroundColor: "lightgrey",
                              color: "black",
                              width: "100%",
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                          >
                            <p style={{ display: "flex", alignItems: "center" }}>
                              {" "}
                              <LocationOnIcon style={{ marginRight: "8px" }} />
                              {this.state.profileDataForAbout.attributes.location}{" "}
                            </p>
                            <p style={{ display: "flex", alignItems: "center" }}>
                              {" "}
                              <PersonIcon style={{ marginRight: "8px" }} /> Member since
                              {" "}{this.state.profileDataForAbout.attributes.created_at}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div />
                  </div>
                </div>


              </main>
            ) : null}
          </>
        ) : (
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Footer
          navigation={this.props.navigation}
        />
      </>
    );
  }
}

const useStyles = {
  clubAbout: {
    display: "grid",
    gridTemplateColumns: "0.2fr auto 0.2fr",
    padding: "10px 0px",
    marginBottom: "15px",
    columnGap: "15px",
  },
  mainSection: {
    display: "grid",
    gridTemplateColumns: "0.6fr 0.3fr",
    justifyContent: "space-between",
  },
  subTitle: {
    fontWeight: "bold",
    color: "darkgrey",
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
  },
  skillList: {
    display: "flex",
    columnGap: "10px",
    "& > span": {
      padding: "5px 10px",
      backgroundColor: "lightgrey",
      color: "black",
      borderRadius: "5px",
    },
  },
  listSelection: {
    display: "flex",
    columnGap: "10px",
    flexWrap: "wrap",
    "& > p": {
      padding: "5px 10px",
      backgroundColor: "lightgrey",
      color: "black",
      borderRadius: "5px",
    },
  },
  memberList: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: '0.5fr 0.5fr',
    rowGap: '30px',
    flexWrap: "wrap",
    alignItems: "center",
    "& > div > img": {
      height: "50px",
      width: "50px",
      borderRadius: "50%",
    },
  },
  sectionContainer2: {
    cursor: "pointer",
    "&:hover": {
      borderColor: "lightgrey !important",
    },
    "&:hover .edit-button": {
      display: "block !important",
    },
  },
  rightBlock2: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "black",
    },
    "&:hover .edit-button": {
      display: "block !important",
    },
  },
  card__container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    marginLeft: "30px",
    marginBottom: "30px",
  },
  collection__container: {
    display: "flex",
    flexDirection: "column",
  },
  collection__design__container: {
    display: "flex",
    flexDirection: "row",
    gap: "25px",
    flexWrap: 'wrap'
  },
  add__design: {
    width: "300px",
    height: "200px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  designs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    fontSize: "12px",
    fontWeight: "bold",
  },
  designs__image: {
    width: "300px",
    height: "200px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  designs__details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "5px",
  },
  designs__ratings: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#ccc",
    "& div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "10px",
      "& img": {
        width: "20px",
        height: "20px",
        marginRight: "5px",
      },
    },
  },
  save__design: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& button": {
      backgroundColor: "black",
      color: "white",
      padding: "10px",
      borderRadius: "5px",
      fontSize: "14px",
      cursor: "pointer",
      border: "none",
      width: "20%",
    },
  },
}

export default withStyles(useStyles)(UserProfileByOthers);

const labels = [
  {
    id: 1,
    name: "Designs",
    icon: design,
  },
  {
    id: 2,
    name: "About",
    icon: about,
  },
];

const filters = [
  {
    id: 1,
    name: "Saved",
    icon: plus,
    onClickFunctionCallName: "saved",
  },
  {
    id: 2,
    name: "Liked",
    icon: likes,
    onClickFunctionCallName: "liked",
  },
  {
    id: 3,
    name: "Shared",
    icon: share,
    onClickFunctionCallName: "shared",
  },
  {
    id: 4,
    name: "Inspired",
    icon: lightbulb,
    onClickFunctionCallName: "inspired",
  },
  {
    id: 5,
    name: "Rated",
    icon: star,
    onClickFunctionCallName: "rated",
  },
  {
    id: 6,
    name: "All",
    icon: null,
    onClickFunctionCallName: "all",
  },
];
