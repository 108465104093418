import { CollectionHome, Design, DesignCategory } from "../../api-types";
import { DezinerActionCreator } from "../store";

export type DesignsOwner = 'users' | 'groups' | 'clubs' | 'designers';

interface DesignsActionsProperties {

  designsType: DesignsOwner,
  category: DesignCategory,

  clubId?: string,
  groupId?: string,
  designerId?: string,
  userId?: string,
  labelId?: string | 'all',

  filter?: 'all' | 'Saved_design' | 'Uploaded_designs',
}


/////////////

export const FETCH_DESIGNS = 'FETCH_DESIGNS';

export interface FetchDesignAction extends DesignsActionsProperties {
  type: typeof FETCH_DESIGNS,
}

export const fetchDesigns: DezinerActionCreator<FetchDesignAction> =
  (params) => ({
    type: FETCH_DESIGNS,
    ...params,
  });

//////////// 

export const SET_DESIGNS = 'SET_DESIGNS';

export interface SetDesignAction extends DesignsActionsProperties {
  type: typeof SET_DESIGNS,
  designs: (Design | CollectionHome)[],
}

export const setDesigns: DezinerActionCreator<SetDesignAction> =
  (params) => ({
    type: SET_DESIGNS,
    ...params,
  });

//////////

export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';

export interface SetActiveCategoryAction {
  type: typeof SET_ACTIVE_CATEGORY,
  category: DesignCategory,
  designsOwner?: DesignsOwner,
}

export const setActiveCategory: DezinerActionCreator<SetActiveCategoryAction> =
  (params) => ({
    type: SET_ACTIVE_CATEGORY,
    ...params,
  });

