import React, { CSSProperties, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


type DezinerMediaProps = React.HTMLProps<HTMLImageElement> & React.HTMLProps<HTMLVideoElement> & {
  watermarkURL?: string,
  watermarkWidth?: number,
  watermarkHeight?: number,
  containerClassName?: string,
  containerStyles?: CSSProperties,
  mediaType?: 'image' | 'video',
};

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'inline-block',
  },
  watermark: {
    opacity: 0.2,
    // @ts-ignore
    objectFit: 'unset!important',
    zIndex: 1,
  },
  watermarkContainer: {
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  watermarkContainerForVideos: {
    height: '90%',
  },
  img: {
    verticalAlign: 'middle',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export default function DezinerMedia(props: DezinerMediaProps) {

  const {
    watermarkURL,
    watermarkWidth,
    watermarkHeight,
    containerClassName,
    containerStyles,
    mediaType,
    ...mediaProps
  } = props;


  const classes = useStyles();

  const [offsetWidth, setOffsetWidth] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);

  function onLoad({ target }: any) {

    setOffsetWidth(target.offsetWidth);
    setOffsetHeight(target.offsetHeight);
  }


  let _width = watermarkWidth && offsetWidth ? `${Math.min(offsetWidth, watermarkWidth * offsetWidth)}px` : 'auto';
  let _height = watermarkHeight && offsetHeight ? `${Math.min(offsetHeight, watermarkHeight * offsetHeight)}px` : 'auto';


  return (
    <span
      style={containerStyles}
      className={clsx(classes.root, containerClassName)}>
      {
        watermarkURL ?
          <span className={clsx(classes.watermarkContainer, {
            [classes.watermarkContainerForVideos]: mediaType == 'video',
          })}>
            <img
              src={watermarkURL}
              style={{
                width: _width,
                height: _height,
                maxWidth: '100%',
                maxHeight: '100%',
                minWidth: _width,
                minHeight: _height,
              }}
              className={classes.watermark} />
          </span>
          :
          null
      }
      {
        mediaType == 'video' ?
          <video
            {...mediaProps}
            crossOrigin={props.crossOrigin as any}
            onLoadedData={onLoad}
            className={clsx(classes.img, mediaProps.className)}
            autoPlay
            muted={true}
          >
            <source src={mediaProps?.src} type="video/mp4" />
          </video>
          :
          <img
            {...mediaProps}
            crossOrigin={props.crossOrigin as any}
            onLoad={onLoad}
            className={clsx(classes.img, mediaProps.className)}
          />
      }
    </span>
  );
}
