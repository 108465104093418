import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DezinerButton from './Button.web';

export default function DeleteMemberDialog({
  open,
  onClose,
  memberName,
  onAccept,
}: {
  open: boolean,
  onClose: () => void,
  memberName: string,
  onAccept: () => void,
}) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="remove member"
    >
      <DialogTitle>{'Delete Member'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure, you want to remove `}
          <b>{memberName}</b>
          {`from this club?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DezinerButton onClick={onClose} color="primary">
          {'Cancel'}
        </DezinerButton>
        <DezinerButton
          onClick={onAccept}
          color="primary"
          autoFocus
        >
          {'Remove'}
        </DezinerButton>
      </DialogActions>
    </Dialog>
  );
}
