import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

export default function DezinerSpinner({noHeight}:{noHeight?:boolean}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: noHeight? '10px' : "200px",
      }}
    >
      <CircularProgress />
    </div>
  );
}
