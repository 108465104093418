import reduxDezinerMiddlewareWeb from "../redux-deziner-middleware.web";
import { put } from 'redux-saga/effects';
import { FetchProfileAction, setProfile } from './profile.action';
import { takeHttpResponse } from "../effects/effect-utils";

const configJson = require('../../config.js');


export function* fetchProfileSaga(action: FetchProfileAction) {
  try {

    const sentMessageId = reduxDezinerMiddlewareWeb.sendHttpRequest({
      header: {},
      url: configJson.fetchProfileAPI,
      httpMethod: configJson.GetMethod,
    });
    const response = yield takeHttpResponse(sentMessageId);
console.log({sentMessageId,response});

    if (response) {
      yield put(setProfile({ profile: response?.data }));
    }

  } catch (error) {
    console.error(error);
  }
}
