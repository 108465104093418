import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormikTextInput from '../../../components/src/FormikTextField.web';
import DezinerButton from '../../../components/src/Button.web';
import Grid from '@material-ui/core/Grid';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { encodeFile } from '../../../components/src/utils';


const initDefaultValues = {
  name: '',
  bio: '',
  location: '',
  profileImage: '',
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  bio: yup.string().required(),
  location: yup.string().required(),
});


const useStyles = makeStyles({
  profileImage: {
    width: '100px',
    height: '100px',
    borderRadius: '40%',
    objectFit: 'cover',
  },
  profileImagePlaceholder: {
    width: '100px',
    height: '100px',
    borderRadius: '40%',
    background: 'lightgray'
  },
  container: {
    margin: '20px auto',
    width: '400px'
  },
});



export default function DesignerEditProfileForm({
  onSubmit,
  initialValues,
  onProfileImageUpload,
  submitLoading,
  uploadLoading,
}: {
  onSubmit: (values: Omit<typeof initDefaultValues, 'profileImage'>) => void,
  initialValues: typeof initDefaultValues,
  onProfileImageUpload: (values: { profileImage: string }) => void,
  submitLoading?: boolean,
  uploadLoading?: boolean,
}) {

  const classes = useStyles();
  const [profileImage, setProfileImage] = useState('');
  const fileRef = useRef<any>();


  return (
    <div>


      <Formik
        initialValues={{ ...initDefaultValues, ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        {
          formik => <Form translate={""}>

            <Grid direction='column' alignItems="center" className={classes.container}>




              <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>

                {
                  initialValues.profileImage || profileImage ?
                    <img
                      className={classes.profileImage}
                      src={profileImage || initialValues.profileImage}
                      alt="profile image"
                    />
                    :
                    <div className={classes.profileImagePlaceholder} />
                }


                <Grid container direction="column" alignItems="center" style={{ width: 'auto' }}>
                  <DezinerButton
                    size="small"
                    color='inherit'
                    variant='outlined'
                    onClick={() => {
                      if (fileRef && fileRef.current)
                        fileRef.current.click();
                    }}
                  >
                    {'Choose File'}
                  </DezinerButton>

                  <Typography style={{ color: '#BABBBD', fontWeight: 600, fontSize: '1rem', marginTop: '10px' }}>
                    {'JPG, GIF, or PNG'}
                  </Typography>
                </Grid>


                <input
                  ref={fileRef}
                  hidden
                  type="file"
                  accept='image/png, image/jpg, image/jpeg, image/gif'
                  onChange={async (event) => {
                    let file = event.target?.files?.[0];
                    let base64: any = await encodeFile(file);

                    setProfileImage(base64);
                  }}
                />

                <DezinerButton
                  variant='contained'
                  color='primary'
                  disabled={uploadLoading}
                  onClick={() => {
                    if (profileImage)
                      onProfileImageUpload({ profileImage });
                  }}
                >
                  {'Upload Now'}
                  {
                    uploadLoading ?
                      <CircularProgress size={20} style={{ margin: '0 5px' }} color='inherit' />
                      :
                      null
                  }
                </DezinerButton>

              </Grid>

              {/* {
                formik.errors.profileImage ?
                  <DezinerAlert>
                    {formik.errors.profileImage}
                  </DezinerAlert>
                  :
                  null
              } */}

              <VerticalSpacer />

              <FormikTextInput
                fullWidth
                variant='outlined'
                name="name"
                label="Name"
                placeholder='Arunkumar'
              />

              <VerticalSpacer />

              <FormikTextInput
                fullWidth
                variant='outlined'
                name="location"
                label="Location"
                placeholder='Erode'
              />

              <VerticalSpacer />


              <FormikTextInput
                fullWidth
                variant='outlined'
                name="bio"
                label="Bio"
                multiline
                autoHeight
                minRows={4}
              />

              <VerticalSpacer />

              <Grid container justifyContent='flex-end'>
                <DezinerButton
                  color="primary"
                  variant='contained'
                  type='submit'
                  disabled={submitLoading}
                >
                  {'Save Profile'}
                  {
                    submitLoading ?
                      <CircularProgress size={20} style={{ margin: '0 5px' }} color='inherit' />
                      :
                      null
                  }
                </DezinerButton>
              </Grid>

            </Grid>

          </Form>
        }
      </Formik>

    </div>
  );
}
