import React, { useEffect, useRef, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  cameraGray,
} from "./assets";
import MaterialAccordion from '@material-ui/core/Accordion';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import FormikTextInput from '../../../components/src/FormikTextField.web';
import Button from '../../../components/src/Button.web';
import { VerticalSpacer } from '../../../components/src/Spacer.web';

const AccordionSummary = withStyles({
  root: {
    position: 'relative',
    right: '-80px',
  }
})(MaterialAccordionSummary);


const AVAILABLE_PROFILE_COLORS = ['#ececee', '#fcb2b3', '#c6b1fe', '#fcc6a2', '#9adba5'];

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    background: 'transparent',
    '&:before': {
      background: 'none'
    },
    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
      padding: 0,
    },
    '& .MuiIconButton-root': {
      marginRight: '5px'
    }
  }
})(MaterialAccordion);


function encodeImageFileAsURL(file: any) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onloadend = function() {
      let imagebase64 = reader.result;
      resolve(imagebase64);
    };
    reader.readAsDataURL(file);
  });
}

const SPECIAL_CHAR_REG_EXP = /^[!@#$%^&*()_-]+$/g;
const NUMBERS_REG_EXP = /^\d+$/g;


const addInfoFormInitValues = {
  location: '',
  color: '',
  image: '',
}

const addInfoFormValidationSchema = yup.object().shape({
  location: yup.string()
    .test('without special characters', 'Please Do not include special chars', (value) => {
      if (!value) return true;
      return !SPECIAL_CHAR_REG_EXP.test(value);
    })
    .test('without numbers', 'Please Do not include numbers', (value) => {
      if (!value) return true;
      return !NUMBERS_REG_EXP.test(value);
    })
    .required(),

  image: yup.string(),

  color: yup.string().when('image', {
    is: image => !image?.length,
    then: yup.string().required('Please choose a color'),
    otherwise: yup.string(),
  }),

});



function ColorCircle({ color, onClick, active }: {
  color: string,
  onClick: (color: string) => void,
  active?: boolean,
}) {

  return (
    <div
      onClick={() => onClick(color)}
      style={{
        background: color,
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        margin: '5px',
        border: active ? '2px solid gray' : 'none',
        cursor: 'pointer'
      }}
    />
  );
}


const useStyles = makeStyles({
  formRoot: {
    padding: '10px', width: '400px', maxWidth: '90%', margin: '0 auto'
  },
});

interface AddInfoFormProps {
  onSubmit: (values: typeof addInfoFormInitValues) => void,
  onSkip: () => void,

}

export default function AddInfoForm({
  onSubmit,
  onSkip,
}: AddInfoFormProps) {

  const [isValidImage, setIsValidImage] = useState(true);
  const classes = useStyles();
  const profileImageRef = useRef<any>();


  return (
    <div className={classes.formRoot} style={{ width: '345px' }}>
      <Formik
        initialValues={addInfoFormInitValues}
        validationSchema={addInfoFormValidationSchema}
        onSubmit={(values) => {
          if (isValidImage)
            onSubmit(values);
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {
          formik => (
            <Form translate="yes">

              <Grid direction="column" container justifyContent="center" alignItems="center">
                <Typography variant="h1" style={{
                  fontSize: '1.8rem',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
                  gutterBottom
                >
                  {'Welcome! Let\'s create your profile'}
                </Typography>
                <Typography variant="caption" style={{ color: '#a9a9a9', textAlign: 'center' }}>
                  {'Let others to know you better! You can do it later'}
                </Typography>
              </Grid>


              <VerticalSpacer />
              <VerticalSpacer />

              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Typography variant="body1" style={{
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}>
                  {'Add an avatar'}
                </Typography>

                <VerticalSpacer />


                {
                  formik.values.image ?
                    <img
                      src={formik.values.image}
                      alt="profile picture"
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%'
                      }}
                    />
                    :
                    <div style={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '50%',
                      border: '1px dashed gray',
                      background: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                      onClick={() => {
                        if (profileImageRef && profileImageRef.current) {
                          profileImageRef.current.click();
                        }
                      }}
                    >
                      <img src={cameraGray} alt="camera" />
                    </div>
                }

                <VerticalSpacer />

                <Grid direction="column" container alignItems="center" justifyContent="center">

                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      if (profileImageRef && profileImageRef.current) {
                        profileImageRef.current.click();
                      }
                    }}>
                    {'Choose image'}
                  </Button>
                  <VerticalSpacer />
                  {
                    formik.values.image ?
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => {
                          formik.setFieldValue('image', '');
                        }}
                      >
                        {'Delete Image'}
                      </Button>
                      :
                      null
                  }
                </Grid>

                {
                  !isValidImage ?
                    <Alert severity="error">
                      {'Please upload an image with size less smaller than 1 MB'}
                    </Alert>
                    :
                    null
                }

                <VerticalSpacer />

                {
                  !formik.values.image ?
                    <>
                      <Typography variant="caption">
                        {'Or choose one of our defaults'}
                      </Typography>
                      <VerticalSpacer />
                      <Grid container>
                        {
                          AVAILABLE_PROFILE_COLORS.map(color => (
                            <Grid
                              key={color}
                              item
                              xs
                            >
                              <ColorCircle
                                active={formik.values.color == color}
                                color={color}
                                onClick={(color) => {
                                  formik.setFieldValue('color', color);
                                }}
                              />
                            </Grid>
                          ))
                        }
                      </Grid>
                      <VerticalSpacer />
                    </>
                    :
                    null
                }


                <div
                  style={{ alignSelf: 'stretch' }}
                >
                  {
                    formik.errors?.color ?
                      <Alert severity="error">
                        {formik.errors?.color}
                      </Alert>
                      :
                      null
                  }

                  <VerticalSpacer />

                  <FormikTextInput
                    name="location"
                    label="Location"
                    variant="standard"
                    fullWidth
                    placeholder="Enter your location"
                    centerLabel
                    centerText
                  />
                </div>


                <input
                  ref={profileImageRef}
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  onChange={(event) => {
                    let file = event.target?.files?.[0];
                    let size = file?.size;
                    const MAX_FILE_SIZE_IN_MB = 1;

                    if (size && (size / (1024 ** 2) > MAX_FILE_SIZE_IN_MB)) {
                      setIsValidImage(false);
                    } else {
                      setIsValidImage(true);
                    }
                    encodeImageFileAsURL(file)
                      .then(result => {
                        formik.setFieldValue('image', result);
                      })
                  }}
                  onClick={(event) => {
                    // @ts-ignore
                    event.target.value = null;
                  }}
                />

                <VerticalSpacer />

              </Grid>

              <Grid container justifyContent="space-between">
                <Grid item sm={5} xs={12}>
                  <Button
                    rounded
                    color="default"
                    variant="contained"
                    onClick={onSkip}
                  >
                    {'Skip'}
                  </Button>
                </Grid>
                <Grid item sm={5} xs={12} container justifyContent="flex-end">
                  <Button
                    rounded
                    color="primary"
                    type="submit"
                    variant="contained">
                    {'Next'}
                  </Button>
                </Grid>
              </Grid>

            </Form>
          )}
      </Formik>
    </div>
  );
}
