import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import { apiCall } from "../../../components/src/Common";
import { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { getNumberFormatSettings } from "react-native-localize";
import { AuthResponse } from 'msal';
import { extractRedirectionInfo } from '../../../components/src/utils';
// Customizable Area End
export const configJSON = require("./config");






export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  loginLoading: boolean,

  otp: string;
  sendOtpLoading: boolean,

  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  mobileNumber: string;
  errors: any;
  mobileNumberError: string;
  otpError: string;
  seconds: number;
  timerValue: number;
  progress: number;
  disableResendButton: boolean;
  timerVisible: boolean;
  OTPSent: boolean;

  step: number,
  profilePictureFile: string,
  location: string,
  color: string,
  isSkippedClicked: boolean,
  createAccountLoading2: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  classes: any;

  // Customizable Area End
}

const OTP_TIME = 60;

export default class EmailAccountLoginControllerWeb extends BlockComponent<
  Props,
  S,
  SS
  > {
  // Customizable Area Start

  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  postApiId: any;
  loginGoogleAccountId: any;
  loginMicrosoftAccountId: any;
  apiVerificationIdCUS: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  countDownId: number;
  createAccountViaGoogleId: string;
  createAccountViaMicrosoftId: string;
  updateProfileMsgId: string;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
    // this.onSignupSubmit = this.onSignupSubmit.bind(this);
    this.onGetOtpSubmit = this.onGetOtpSubmit.bind(this);
    this.otpVerification = this.otpVerification.bind(this);
    this.onGoogleLoginSuccess = this.onGoogleLoginSuccess.bind(this);
    this.onGoogleLoginFailure = this.onGoogleLoginFailure.bind(this);
    this.microsoftAuthHandler = this.microsoftAuthHandler.bind(this);
    // this.timer = this.timer.bind(this);
    // this.decrementSeconds = this.decrementSeconds.bind(this);
    this.countdownTimer = this.countdownTimer.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      loginLoading: false,

      otp: "",
      sendOtpLoading: false,

      enablePasswordField: true,
      checkedRememberMe: false,
      mobileNumber: "",
      errors: {
        email: "",
        password: "",
      },
      mobileNumberError: "",
      otpError: "",
      seconds: OTP_TIME,
      timerValue: OTP_TIME,
      progress: 100,
      disableResendButton: false,
      timerVisible: false,
      OTPSent: false,

      step: 1,
      color: '',
      location: '',
      isSkippedClicked: false,
      profilePictureFile: '',
      createAccountLoading2: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.countDownId = 0;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (prevState.seconds !== this.state.seconds && this.state.seconds === 0) {
      this.setState({
        seconds: OTP_TIME,
        timerValue: OTP_TIME,
        progress: 100,
        disableResendButton: false,
        timerVisible: false,
      });
      clearInterval(this.countDownId);
    }
  }

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));
      const password = message.getData(getName(MessageEnum.LoginPassword));
      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );
      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });
        //@ts-ignore
        this.txtInputEmailProps.value = userName;
        // @ts-ignore
        this.txtInputPasswordProps.value = password;
        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.validationApiCallId) {
          if (!responseJson.errors) {

            const role = responseJson.data.attributes.role;

            localStorage.setItem("role", role);
            localStorage.setItem("currentUserRole", role);

            const [redirectUrl, redirectParams] = extractRedirectionInfo();

            if (redirectUrl) {
              this.props.navigation.navigate(redirectUrl, redirectParams);
            } else {
              if (role == "Designer")
                this.props.navigation.navigate(`DesignerHome`, { designerId: responseJson.data.attributes.id });
              else
                this.props.navigation.navigate("UserHome");
            }

          } else {
            localStorage.clear();
          }
        } else if (apiRequestCallId === this.apiEmailLoginCallId) {

          this.setState({ loginLoading: false });

          if (responseJson && responseJson.meta && responseJson.meta.token) {

            console.log(responseJson);

            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();

            localStorage.setItem("token", responseJson.meta.token);
            localStorage.setItem("cuid", responseJson.data.id);

            const role = responseJson.data.attributes.role;

            localStorage.setItem("role", role);
            localStorage.setItem("currentUserRole", role);


            const [redirectUrl, redirectParams] = extractRedirectionInfo();

            if (redirectUrl) {
              this.props.navigation.navigate(redirectUrl, redirectParams);
            } else {
              if (role == "Designer")
                this.props.navigation.navigate(`DesignerHome`, { designerId: responseJson.data.attributes.id });
              else
                this.props.navigation.navigate("UserHome");
            }

          } else {
            //Check Error Response
            const errors = {
              email: responseJson?.errors?.[0]?.failed_login ?? 'wrong email or password',
            };
            this.setState({
              errors: errors,
            });
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.postApiId) {

          this.setState({ sendOtpLoading: false });
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            console.log(responseJson);
            localStorage.setItem("otpToken", responseJson.meta.token);
            // this.timer();
            // this.decrementSeconds();
            this.countdownTimer();
            this.setState({
              disableResendButton: true,
              timerVisible: true,
              OTPSent: true,
            });
          } else {
            this.setState({
              mobileNumberError: "Mobile number not registered",
            });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiVerificationIdCUS) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            console.log(responseJson);
            localStorage.setItem("token", responseJson.meta.token);
            localStorage.setItem("cuid", responseJson.data.id);
            window.sessionStorage.setItem("isLoggedIn", "true");
            const role = responseJson.data.attributes.role;

            const [redirectUrl, redirectParams] = extractRedirectionInfo();

            if (redirectUrl) {
              this.props.navigation.navigate(redirectUrl, redirectParams);
            } else {
              if (role == "Designer")
                this.props.navigation.navigate(`DesignerHome`, { designerId: responseJson.data.attributes.id });
              else
                this.props.navigation.navigate("UserHome");
            }

          }
          this.setState({
            otpError: "Please enter correct OTP number",
          });
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId == this.updateProfileMsgId) {

          this.setState({ createAccountLoading2: false });

          if (responseJson != null) {
            if (!responseJson.errors) {
              localStorage.setItem("cuid", responseJson?.account?.data?.id);
              const role = responseJson?.account?.data?.attributes?.role;
              localStorage.setItem("role", role);
              localStorage.setItem("currentUserRole", role);

              if (role == "Designer")
                this.props.navigation.navigate(`DesignerHome`, { designerId: responseJson.data.attributes.id });
              else
                this.props.navigation.navigate("UserHome");

            } else {
              console.log(responseJson);
            }
          }


        }
        else if (apiRequestCallId === this.loginGoogleAccountId) {

          if (responseJson && !responseJson.error && !responseJson.errors) {

            localStorage.setItem("token", responseJson.meta.token);
            localStorage.setItem("cuid", responseJson.data.id);
            const role = responseJson.data.attributes.role;


            const [redirectUrl, redirectParams] = extractRedirectionInfo();

            if (redirectUrl) {
              this.props.navigation.navigate(redirectUrl, redirectParams);
            } else {
              if (role == "Designer")
                this.props.navigation.navigate(`DesignerHome`, { designerId: responseJson.data.attributes.id });
              else
                this.props.navigation.navigate("UserHome");
            }

          } else if (responseJson.errors) {

            const { signInPayload } = message.getData(
              getName(MessageEnum.NavigationPropsMessage)
            );
            if (signInPayload)
              this.signupViaGoogle(signInPayload);
          }
        }
        else if (
          apiRequestCallId == this.createAccountViaGoogleId
          ||
          apiRequestCallId == this.createAccountViaMicrosoftId
        ) {

          let errors = responseJson.errors || responseJson.error;
          if (responseJson && !errors) {
            this.setState({ step: 2 });

            let token = responseJson?.meta?.token;
            let cuid = responseJson?.data?.id;

            if (token) localStorage.setItem('token', token);
            if (cuid) localStorage.setItem('cuid', cuid);

          } else {
            console.error(errors);
          }

        }
        else if (apiRequestCallId == this.loginMicrosoftAccountId) {

          if (responseJson && !responseJson.error && !responseJson.errors) {

            this.props.navigation.navigate(`DesignerHome`, { designerId: responseJson.data.attributes.id });

            localStorage.setItem("token", responseJson.meta.token);
            localStorage.setItem("cuid", responseJson.data.id);

            const role = responseJson.data.attributes.role;

            const [redirectUrl, redirectParams] = extractRedirectionInfo();

            if (redirectUrl) {
              this.props.navigation.navigate(redirectUrl, redirectParams);
            } else {
              if (role == "Designer")
                this.props.navigation.navigate(`DesignerHome`, { designerId: responseJson.data.attributes.id });
              else
                this.props.navigation.navigate("UserHome");
            }

          } else if (responseJson.errors) {

            const { signInPayload } = message.getData(
              getName(MessageEnum.NavigationPropsMessage)
            );
            if (signInPayload)
              this.signupViaMicrosoft(signInPayload);
          }

        }

      }
    }
    // Customizable Area End
  }

  // ====================================KANISHK=====================================

  microsoftAuthHandler = (err: any, res: AuthResponse, msal: any) => {
    this.setState({
      errors: {}
    })
    console.log(res);
    const data = {
      data: {
        attributes: {
          email: res?.account?.userName ?? '',
          unique_auth_id: res?.accessToken,
          type: "microsoft",
        },
      },
    };

    console.log(data);
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginMicrosoftAccountId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleSignInEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {
        signInPayload: {
          email: res.account.userName,
          unique_auth_id: res.accessToken,
          token: res.accessToken,
          type: "microsoft",
          name: res.account.name || res.account.userName
        }
      }
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  signupViaMicrosoft = ({ email, unique_auth_id, type, name, token }: any) => {

    const data = {
      "data": {
        attributes: {
          first_name: name,
          user_name: "",
          email: email,
          unique_auth_id,
          full_phone_number: ""
        },
        type
      }
    }

    const header = {
      "Content-Type": configJSON.signup1ApiContentType,
      token
    };

    const httpBody = data;

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.signup1ApiMethod,
      url: configJSON.signup1Api,
    });

    this.createAccountViaMicrosoftId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
  }

  signupViaGoogle = ({ email, unique_auth_id, type, name, token }: any) => {

    const data = {
      "data": {
        attributes: {
          first_name: name,
          user_name: "",
          email: email,
          unique_auth_id,
          full_phone_number: ""
        },
        type
      }
    }

    const header = {
      "Content-Type": configJSON.signup1ApiContentType,
      token
    };

    const httpBody = data;

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.signup1ApiMethod,
      url: configJSON.signup1Api,
    });

    this.createAccountViaGoogleId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
  }


  onGoogleLoginSuccess = (res: GoogleLoginResponse) => {
    this.setState({
      errors: {}
    })
    console.log(res);
    const data = {
      data: {
        attributes: {
          email: res.profileObj.email,
          unique_auth_id: res.googleId,
          type: "google",
        },
      },
    };

    console.log(data);
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginGoogleAccountId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleSignInEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {
        signInPayload: {
          email: res.profileObj.email,
          unique_auth_id: res.googleId,
          type: "google",
          name: res.profileObj.name,
          token: res.tokenId
        }
      }
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onGoogleLoginFailure = (res: any) => {
    console.log(
      "🚀 ~ file: EmailAccountLoginController.web.tsx ~ line 355 ~ res",
      res
    );
    console.log(res);
  };

  /**
  * @deprecated
  */
  validate = () => {
    this.emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const { email, password } = this.state;
    const errors = {
      password: "",
      email: "",
    };
    let flag = false;
    if (password.length === 0) {
      errors.password = "Please enter your password";
      flag = true;
    } else if (password.length < 8) {
      (errors.password = "Password should be more than 8 characters"),
        (flag = true);
    }
    if (email.length === 0) {
      errors.email = "Please enter your email";
      flag = true;
    } else if (!this.emailReg.test(this.state.email)) {
      errors.email = "Please enter valid email";
      flag = true;
    }
    this.setState({
      errors: errors,
    });
    return flag;
  };

  onLoginSubmit = ({ email, password }: any) => {
    this.setState({ loginLoading: true });
    const data = {
      data: {
        attributes: {
          login_id: email,
          password: password.trim(),
        },
      },
    };
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginApiEndPointCustomed
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );


    console.log("REQUEST: ", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  otpVerification = (e: any) => {
    e.preventDefault();
    this.setState({
      otpError: "",
    });
    const { otp } = this.state;
    if (otp.length === 0) return;

    const data = {
      data: {
        attributes: {
          pin: otp,
          token: localStorage.getItem("otpToken"),
        },
      },
    };

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerificationIdCUS = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiVerificationEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onGetOtpSubmit = ({ phone }: { phone: number }) => {

    this.setState({
      sendOtpLoading: true,
      otpError: "",
      mobileNumberError: "",
    });

    let mobileNumber = String(phone);
    const fullMobileNumber = "91" + mobileNumber;

    const data = {
      data: {
        attributes: {
          full_phone_number: fullMobileNumber, //Add With country Code
        },
      },
    };

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postApiId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiOTPEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // timer() {
  //   setInterval(() => {
  //     console.log('yo timer ')
  //     this.setState(
  //       {
  //         timerValue: this.state.timerValue - 1,
  //       },
  //       () => {
  //         if (this.state.timerValue < 0) {
  //           seconds = 30;
  //           this.setState({
  //             timerValue: 30,
  //             progress: 100,
  //             seconds: 30,
  //             disableResendButton: false,
  //           });
  //           clearInterval(this.countDownId);
  //         }
  //       }
  //     );
  //   }, 30000);
  // }

  // decrementSeconds() {
  //   this.countDownId = window.setInterval(() => {
  //     seconds = seconds - 1;
  //     console.log("🚀 ~ file: EmailAccountLoginController.web.tsx ~ line 539 ~ this.countDownId=window.setInterval ~ seconds", seconds)

  //     if (seconds < 0) {
  //       seconds = 30;
  //       console.log('its here')
  //       this.setState({
  //         timerValue: 30,
  //         progress: 100,
  //         seconds: 30,
  //         disableResendButton: false,
  //       });
  //       clearInterval(this.countDownId);
  //     }
  //     this.setState({
  //       seconds: seconds,
  //       progress: this.state.progress - 100 / 30,
  //     });
  //   }, 1000);
  // }

  countdownTimer() {
    this.countDownId = window.setInterval(() => {
      let { seconds, progress } = this.state;
      this.setState({
        seconds: seconds - 1,
        progress: progress - 100 / OTP_TIME,
      });
    }, 1000);
  }

  // ====================================EXISTING=====================================

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  callGetValidationApi() {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.getTokenValidationApi,
    });

    this.validationApiCallId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  }

  onFinishSetup = ({ loginAs }: any) => {
    this.setState({ createAccountLoading2: true });

    const { profilePictureFile, location, color } = this.state;

    let role;
    // Hard-Coded -> Change in Future
    if (loginAs === "designer") {
      role = 1;
    } else {
      role = 2;
    }
    let profileFinal;

    if (profilePictureFile) {
      profileFinal = {
        data: profilePictureFile,
      };
    } else {
      profileFinal = "";
    }
    let data;
    if (this.state.isSkippedClicked === true) {
      data = {
        data: {
          attributes: {
            role_id: role,
            location: "",
            color: "",
            profile: "",
          },
        },
      }
    } else {
      data = {
        data: {
          attributes: {
            role_id: role,
            location: location,
            color: color,
            profile: profileFinal,
          },
        },
      };
    }
    const header = {
      "Content-Type": configJSON.signup2ApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = data;

    const updateProfile = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.signup2ApiMethod,
      url: configJSON.signup2Api,
    });

    this.updateProfileMsgId = updateProfile.messageId;
    runEngine.sendMessage(updateProfile.id, updateProfile);
  }


}
