import React from 'react';
import MaterialButton from "@material-ui/core/Button";
import clsx from 'clsx';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from '@material-ui/core/styles';



const useDezinerButtonsGroupStyles = makeStyles({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
  },
  grayRoot: {
    background: '#ECEDEF',
  },
  rootWithoutBoders: {
    '& button:not(:first-child):not(:last-child)': {
      borderRight: 'none',
      borderLeft: 'none',
    },
    '& button:first-of-type': {
      borderRight: 'none',
    },
    '& button:last-of-type': {
      borderLeft: 'none',
    },
  },
  button: {
    textTransform: 'none',
    minWidth: '90px',
    fontWeight: 600,
    fontSize: '1rem',
    borderWidth: '2px',
    boxShadow: 'none',
  },
  grayButton: {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
});


export default function DezinerButtonGroup({
  options,
  onClick,
  noBorders,
  grayColors,
}: {
  options: { key: string, title: string, icon?: any, active: boolean, }[],
  onClick: (key: string) => void,
  noBorders?: boolean,
  grayColors?: boolean,
}) {

  const classes = useDezinerButtonsGroupStyles();

  return (
    <ButtonGroup
      color="primary"
      className={clsx(classes.root, {
        [classes.rootWithoutBoders]: noBorders,
        [classes.grayRoot]: grayColors,
      })}
      aria-label="Filter">
      {
        options.map(({ key, title, active, icon }) => (
          <MaterialButton
            aria-current={active ? "true" : "false"}
            className={clsx(classes.button, {
              [classes.grayButton]: grayColors
            })}
            style={{
              color: active ? 'white' : '#767779',
            }}
            key={key}
            variant={active ? "contained" : "outlined"}
            onClick={() => {
              onClick(key);
            }}
            startIcon={!icon ? null :
              <img
                src={icon}
                className={clsx({
                  'gray-svg': !active,
                  'white-svg': active,
                })}
              />}
          >
            {title}
          </MaterialButton>
        ))
      }
    </ButtonGroup>
  );
}



