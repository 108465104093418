import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Formik, Form } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import FormikTextInput from '../../../components/src/FormikTextField.web';
import TextInput from '../../../components/src/TextField.web';
import Button from '../../../components/src/Button.web';
import { VerticalSpacer } from '../../../components/src/Spacer.web';

import EmailAccountLoginControllerWeb, {
  Props,
} from "./EmailAccountLoginController.web";
import RegisterationLayout from "../../email-account-registration/src/RegisterationLayout.web";

const loginValidationSchema = yup.object().shape({
  phone: yup.string()
    .required('Please insert Phone Number')
    .matches(/^[0-9]+$/, "Phone Number must be only digits")
    .min(10, 'Phone Number must be exactly 10 digits')
    .max(10, 'Phone Number must be exactly 10 digits'),
});

const loginFormInitValues = {
  phone: '',
}

class EmailAccountLoginViaOtpBlock extends EmailAccountLoginControllerWeb {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props as any;

    return (
      <RegisterationLayout>
        <div className={classes.loginFormRoot}>

          <Formik
            initialValues={loginFormInitValues as any}
            validationSchema={loginValidationSchema}
            onSubmit={this.onGetOtpSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <Form translate="yes">

              <Grid container justifyContent="center">
                <Typography variant="h1" className={classes.formTitle}>
                  {'SIGN IN VIA OTP'}
                </Typography>
              </Grid>

              <VerticalSpacer />
              <VerticalSpacer />

              <FormikTextInput
                name="phone"
                fullWidth
                variant="outlined"
                label={"Mobile no."}
                disabled={this.state.OTPSent || this.state.sendOtpLoading}
                type="number"
                onKeyDown={(event) => event.key === "e" && event.preventDefault()}
              />
              {
                this.state.mobileNumberError ?
                  <Alert severity="error">
                    {this.state.mobileNumberError}
                  </Alert>
                  :
                  null
              }

              <VerticalSpacer />

              <Grid container justifyContent="center">
                <Button
                  disabled={this.state.OTPSent || this.state.sendOtpLoading}
                  rounded
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {'Get OTP'}
                  {
                    this.state.sendOtpLoading && !this.state.OTPSent ?
                      <CircularProgress style={{ margin: '0 5px' }} color="inherit" size={21} />
                      :
                      null
                  }
                </Button>
              </Grid>

              <VerticalSpacer />


              <TextInput
                name="otp"
                fullWidth
                variant="outlined"
                label={"OTP"}
                type="number"
                disabled={!this.state.OTPSent}
                onChange={(event) => this.setState({ otp: event.target.value })}
                onKeyDown={(event) => event.key === "e" && event.preventDefault()}
                value={this.state.otp}
              />


              {
                this.state.OTPSent ?
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs>
                      {
                        this.state.timerVisible ?
                          <div style={{ position: 'relative', top: '6px' }}>
                            <LinearProgress
                              variant="determinate"
                              value={this.state.progress}
                            />
                            <Typography variant="caption">
                              {`${this.state.seconds}s remaining`}
                            </Typography>
                          </div>
                          :
                          null
                      }
                    </Grid>
                    <Grid item xs container justifyContent="flex-end">
                      <Button
                        disabled={this.state.sendOtpLoading || this.state.timerVisible}
                        color="primary"
                        type="submit"
                        variant="text"
                        bold
                        style={{ padding: '0' }}
                      >
                        {'Resend OTP'}
                        {
                          this.state.sendOtpLoading ?
                            <CircularProgress style={{ margin: '0 5px' }} color="inherit" size={10} />
                            :
                            null
                        }
                      </Button>
                    </Grid>
                  </Grid>
                  :
                  null
              }

              {
                this.state.otpError ?
                  <>
                    <VerticalSpacer />
                    <Alert severity="error">
                      {this.state.otpError}
                    </Alert>
                  </>
                  :
                  null
              }

              <VerticalSpacer />

              <Grid container justifyContent="center">
                <Button
                  disabled={this.state.loginLoading || !this.state.otp}
                  rounded
                  color="primary"
                  variant="contained"
                  onClick={this.otpVerification}
                >
                  {'Sign In'}
                  {
                    this.state.loginLoading ?
                      <CircularProgress style={{ margin: '0 5px' }} color="inherit" size={21} />
                      :
                      null
                  }
                </Button>
              </Grid>

              <VerticalSpacer />

              <Grid container justifyContent="center">
                <Button bold color="secondary" variant="text"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.navigation.navigate(
                      "EmailAccountLoginBlockWeb"
                    );
                  }}
                >
                  {'Login via email'}
                </Button>
              </Grid>

              <VerticalSpacer />

              <Grid container justifyContent="center" alignItems="center">
                <Typography style={{ fontWeight: 'bold' }}>
                  {'Not a member?'}
                </Typography>
                <Button bold color="secondary" variant="text"
                  onClick={(e: any) => {
                    e.preventDefault();
                    this.props.navigation.navigate("EmailAccountRegistrationWeb");
                  }}
                >
                  {'Sign Up Now'}
                </Button>
              </Grid>

            </Form>
          </Formik>

        </div>
      </RegisterationLayout>
    );
  }
}

const useStyles: any = {
  loginFormRoot: {
    padding: '10px', width: '400px', maxWidth: '90%', margin: '0 auto'
  },
  formTitle: {
    fontWeight: 900, fontSize: '1.3rem'
  },
};

export default withStyles(useStyles)(EmailAccountLoginViaOtpBlock);
