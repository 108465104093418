import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import DezinerTextField from "../../../components/src/TextField.web";
import DezinerButton from "../../../components/src/Button.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";



const PaperTableContainer = withStyles({
	root: {
		boxShadow: "none",
		border: "1px solid #ebebeb",
		borderRadius: "0"
	}
})(Paper);






export default function MembersContributionTableWithShares({
	members,
	currentRole,
	onAdd,
	onSave,
	onDelete,
}: {
	members: any[],
	currentRole: string,
	onAdd: () => void,
	onSave: (revenu: { [id: number]: number }) => void,
	onDelete: (params: { memberId: number, memberName: string }) => void,
}) {

	const [revenu, setRevenu] = useState<{ [id: string]: number }>({});
	const [isEqual, setIsEqual] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [revenuError, setRevenuError] = useState('');

	useEffect(() => {
		setRevenu(members.reduce((all, member) => {
			return ({
				...all,
				[member.id]: Number(member?.attributes?.contribution?.replace('%', '') ?? '0')
			})
		}, {}))
	}, [members])


	function isValidSum() {
		let sum = Object.values(revenu).map(Number).reduce((x, y) => x + y, 0);
		return (sum).toFixed(2) == (100).toFixed(2);
	}


	return (
		<div >

			{
				isEditable ?
					<>
						<VerticalSpacer />
						<Grid container justifyContent="flex-end">

							<FormControlLabel
								control={<Checkbox
									checked={isEqual}
									color="primary"
									onChange={(e) => {

										if (e.target.checked) {
											const memberShare = (100 / members.length);
											setRevenu(oldRevenu => {
												let copy: any = { ...oldRevenu };
												Object.keys(copy).forEach(key => {
													copy[key] = memberShare
												});
												return copy;
											})
										}

										setIsEqual(e.target.checked);
										return;
									}}
									name="Equal Contribution"
								/>}
								label="Equal Contribution"
							/>

						</Grid>
					</>
					:
					null
			}

			<VerticalSpacer />


			<MembersContributionTable
				members={members}
				currentRole={currentRole}
				isEditable={isEditable}
				isEqual={isEqual}
				revenu={revenu}
				setRevenu={setRevenu}
				onDelete={onDelete}
				isViewOnly={false}
			/>

			{
				(
					currentRole == 'Admin' ||
					currentRole == 'Owner'
				) ? (
					<>


						{
							revenuError ?
								<>
									<VerticalSpacer />
									<Alert
										severity="error"
										style={{
											fontWeight: 'bold',
											textAlign: 'right'
										}}>
										{revenuError}
									</Alert>
								</>
								:
								null
						}

						<VerticalSpacer />

						<Grid container justifyContent="space-between" alignItems="center">

							<DezinerButton
								color="primary"
								onClick={onAdd}
								variant='outlined'
							>
								{'Add Member'}
							</DezinerButton>

							<DezinerButton
								onClick={() => {

									if (isEditable) {
										if (isValidSum()) {
											onSave(revenu);
											setRevenuError('');
											setIsEditable(false);
										} else {
											setRevenuError('Please revise work contribution % and update');
										}
									} else {
										setIsEditable(true)
									}
								}}
								variant='contained'
								color="primary"
							>
								{isEditable ? 'Update' : 'Edit'}
							</DezinerButton>

						</Grid>
					</>
				)
					:
					null
			}

		</div >
	)
}


export function MembersContributionTable({
	members,
	currentRole,
	isEditable,
	isEqual,
	revenu,
	setRevenu,
	onDelete,
	isViewOnly,
}: {
	members: any[],
	currentRole: string,
	isEditable: boolean,
	isEqual: boolean,
	revenu: { [id: number]: number },
	setRevenu: any,
	onDelete: (params: { memberId: number, memberName: string }) => void,
	isViewOnly: boolean,
}) {



	return (
		<TableContainer component={PaperTableContainer}>
			<Table aria-label="simple table">
				<TableHead style={{ background: '#ecedef' }}>
					<TableRow>
						<TableCell style={{ fontWeight: 'bold', color: '#424345', fontSize: '1.2rem' }}>{'Members'}</TableCell>
						<TableCell style={{ fontWeight: 'bold', color: '#424345', fontSize: '1.2rem' }}>{'Role'}</TableCell>
						<TableCell style={{ fontWeight: 'bold', color: '#424345', fontSize: '1.2rem' }} align="center">{'Work Contribution%'}</TableCell>
						<TableCell style={{ fontWeight: 'bold', color: '#424345', fontSize: '1.2rem' }} align="center">{'Action'}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						members?.map((member: any) => {

							const isMyProfile = member.attributes.account_id.toString() === localStorage.getItem('cuid');

							const iHaveOwnerRole = currentRole === 'Owner';
							const iHaveAdminRole = currentRole === 'Admin';

							const memberHasOwnerRole = member?.attributes?.user_type === 'Owner';

							return (
								<TableRow key={member.attributes.account_id}>
									<TableCell component="td" scope="row">
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<img
												src={
													member.attributes.profile_url === "" ?
														"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" :
														member.attributes.profile_url
												}
												style={{
													height: "50px",
													width: "50px",
													borderRadius: "40%",
												}}
											/>
											<Typography style={{ color: '#666769', fontWeight: 'bold', padding: '0 10px' }}>
												{member.attributes.first_name}
											</Typography>
										</div>
									</TableCell>
									<TableCell>
										<Typography style={{ color: '#99999b', fontWeight: 'bold' }}>
											{member?.attributes?.user_type}
										</Typography>
									</TableCell>
									<TableCell align="center">
										{
											!isViewOnly && isEditable ? (
												<div style={{ maxWidth: '90px', margin: 'auto' }}>
													<DezinerTextField
														variant="outlined"
														disabled={isEqual}
														type="number"
														InputProps={{
															inputProps: {
																min: 0,
																max: 100,
																step: 0.01,
															},
															endAdornment: <Typography>{'%'}</Typography>
														}}
														onChange={e => {
															let value = e.target.value;
															setRevenu((oldRevenu: any) => ({
																...oldRevenu,
																[member.id]: Number(value)
															}))
														}}
														value={
															Number(revenu[member.id])
														}
													/>
												</div>
											) :
												<Typography style={{
													color: '#99999b',
													fontWeight: 'bold',
													display: "block",
													width: "55px",
													// width: String(revenu[member.id]).length > 4 ? "auto" : "55px", 
													margin: "auto",
													textAlign: 'left'
												}}>
													{
														isViewOnly ?
															`${member?.attributes?.contribution ? member?.attributes?.contribution :  '0%'}`
															:
															`${revenu?.[member.id] ?? 0}%`
													}
												</Typography>
										}
									</TableCell>
									<TableCell align="center">
										<DezinerButton
											color="primary"
											variant="text"
											onClick={() => {
												onDelete({
													memberName: member.attributes.first_name,
													memberId: member.attributes.account_id,
												})
											}}
											style={{
												display: !memberHasOwnerRole && (iHaveAdminRole || iHaveOwnerRole || isMyProfile) ? 'block' : 'none',
												color: '#99999b',
												fontWeight: 'bold'
											}}
										>
											{
												isMyProfile ? "Exit" : "Remove"
											}
										</DezinerButton>
									</TableCell>
								</TableRow>
							)
						})
					}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
