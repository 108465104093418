import React from "react";
// Customizable Area Start
import "../../catalogue/src/style.css";
import "./DesignerFollowerPageByUser.web.css";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DoneIcon from "@material-ui/icons/Done";
import Select from "@material-ui/core/Select";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// Customizable Area End

import DesignerFollowerPageByUserController, {
  Props
} from "./DesignerFollowerPageByUserController.web";
import HeaderWeb from "../../catalogue/src/Header.web";
import Footer from "../../catalogue/src/Footer.web";
import CircularProgress from "@material-ui/core/CircularProgress";

class DesignerFollowerPageByUser extends DesignerFollowerPageByUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1
    };
    return (
      <>
        <HeaderWeb
          profileData={this.state.profileData}
          arrLabels={this.state.arrLabels}
          currentLabel={this.state.currentLabel}
          navigation={this.props.navigation}
          selfProfileData={this.state.selfProfileData}
        />
        <div className="main-container">
          <div className="followers_dropdown">
            <FormControl variant="outlined">
              <Select value={this.state.selectedFilter}>
                <MenuItem value="All" onClick={() => this.filterFollowers('')}>10 Followers</MenuItem>
                <MenuItem value="Designer" onClick={() => this.filterFollowers('Designer')}>Designer (04)</MenuItem>
                <MenuItem value="Group" onClick={() => this.filterFollowers('Group')}>Group (02)</MenuItem>
                <MenuItem value="Club" onClick={() => this.filterFollowers('Club')}>Club (02)</MenuItem>
                <MenuItem value="User" onClick={() => this.filterFollowers('User')}>User (02)</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="followers_div">
            <Grid container spacing={1}>
              {this.state.followersData.map((follower: any) => (
                <Grid item xs={12} sm={6} md={6} key={follower.id}>
                  <div className="followers_sub_div">
                    <div className="left_div">
                      <img className="profile_pic" src={
                        follower.attributes.profile_url === '' ?
                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" :
                        follower.attributes.profile_url
                      } />
                    </div>
                    <div className="right_div">
                      <h4>
                        {follower.attributes.first_name}
                        <span>
                          <MoreHorizIcon fontSize="small" id="horizonIcon" />
                        </span>
                      </h4>
                      {follower.attributes.role == "club" && (
                        <span id="club_role">{follower.attributes.role}</span>
                      )}
                      {follower.attributes.role == "group" && (
                        <span id="grp_role">{follower.attributes.role}</span>
                      )}
                      {(follower.attributes.role == "User" ||
                        follower.attributes.role == "Designer") && (
                        <span id="role">{follower.attributes.role}</span>
                      )}

                      <span id="city">
                        <FiberManualRecordIcon
                          style={{
                            fontSize: "x-small",
                            marginRight: "3%",
                            color: "black"
                          }}
                        />
                        {follower.attributes.location}
                      </span>
                      <p id="follower_bio">{follower.attributes.bio}</p>
                      <div className="buttons_div">
                        {follower.attributes.followed ? (
                          <button
                            id="following"
                            onClick={() => {
                              this.setState({
                                currentFollower: follower.id
                              }, () => {
                                this.unFollowAccount();
                              })
                            }}
                          >
                            <DoneIcon id="done_icon" />
                            Following
                          </button>
                        ) : (
                          <button
                            id="not_following"
                            onClick={() => {
                              this.setState({
                                currentFollower: follower.id
                              }, () => {
                                this.followAccount();
                              })
                            }}
                          >+ Follow</button>
                        )}

                        {follower.attributes.subscribed ? (
                          <button id="subscribed">
                            <DoneIcon id="done_icon" />
                            Subscribed
                          </button>
                        ) : (
                          <button id="not_subscribed">Subscribe</button>
                        )}
                        <div className="follower_count">
                          <p>{follower.attributes.follower_count}</p>
                          <p>Followers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <Footer
          navigation={this.props.navigation}
        />
      </>
    );
  }
}

// Customizable Area Start
export default DesignerFollowerPageByUser;
// Customizable Area End
