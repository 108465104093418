import React, { useState } from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
// import StarIcon from '@material-ui/icons/Star';
import { UserBackground } from "./assets";
import PeopleIcon from "@material-ui/icons/People";
import StarIcon from "@material-ui/icons/Star";
import { designs, col, inspire, promote, wishlist } from "./assets";
// import './style.css';
// Customizable Area End
import TopHeaderWeb from "../../catalogue/src/TopHeader.web";
import TopHeaderUserWeb from "../../landingpage/src/TopHeaderUser.web";
import ChangeCoverWeb from "../../catalogue/src/ChangeCover.web";
import LabelListing, { LabelListingProps } from "../../catalogue/src/LabelListing.web";
import { Label } from "../../../components/src/api-types";

const ClubHeader = (props: any) => {

  const location = window.location.href.split("/");


  const groupPage = location[3].toLowerCase().includes('group') ? true : false;

  // const currentScreen = location[location.length - 1];
  const { classes, profileData, clubData } = props;

  let userBg =
    clubData.cover !== "" ? `url(${clubData.cover})` : '';

  let allowChange = true;

  const currentUserRole = localStorage.getItem('currentUserRole');
  if (currentUserRole === 'User') {
    allowChange = false;
  } else {

  }

  const [clubBg, setClubBg] = useState(`url(${clubData.cover_url})`);
  const topHeaderImage = clubData.image_url === '' ?
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' :
    clubData.image_url;
  const selfUserProfileImg = profileData.profile_url === '' ?
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' :
    profileData.profile_url;

  const encodeImageFileAsURL = (element: any) => {
    return new Promise((resolve, reject) => {
      var file = element.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      reader.readAsDataURL(file);
    });
  };


  let list: LabelListingProps['list'] = [];
  try {

    let labelsList: LabelListingProps['list'] = (props.arrLabels as Label[]).map((item) => ({
      id: Number(item?.id ?? 0),
      label: item?.attributes?.name ?? '',
      active: item.id == props.currentLabel,
      onClick: (id: number) => {
        if (props.aboutPage) {
          localStorage.setItem(
            "changeFilterLabel",
            String(id)
          );
          if (currentUserRole === 'User') {
            props.navigation.navigate(groupPage ? "GroupProfileByUser" : "ClubProfileByUser", {
              id: props.navigation.getParam("id", ""),
            });
          } else {
            props.navigation.navigate(groupPage ? "GroupHomePage" : "ClubHomePage", {
              id: props.navigation.getParam("id", ""),
            });
          }
        } else {
          props.changeLabel(id);
        }
      }
    }));

    let createGroupItem: LabelListingProps['list'][number] = {
      id: -1,
      label: '+',
      hide: currentUserRole === 'User',
      pin: 'start',
      onClick(id) {
        props.navigation.navigate(groupPage ? "GroupCreateCollection" : "ClubCreateCollection", {
          id: props.navigation.getParam("id", "")
        });
      }
    }

    let handleOpenItem: LabelListingProps['list'][number] = {
      id: list.length + 1,
      label: '+',
      pin: 'end',
      hide: currentUserRole === 'User',
      onClick: props.handleOpen
    }

    list = [createGroupItem, ...labelsList, handleOpenItem];

  } catch (error) {
    console.error(error);

  }


  return (
    <div>
      {currentUserRole !== 'User' ?
        (
          <TopHeaderWeb
            type={groupPage ? "group" : "club"}
            navigation={props.navigation}
            userProfileImg={topHeaderImage}
            onPromoteProfile={props.onPromoteProfile}
            clubName={groupPage ? "" : clubData.name}
            groupName={groupPage ? clubData.name : ""}
          />
        ) : (
          <TopHeaderUserWeb
            navigation={props.navigation}
            userProfileImg={selfUserProfileImg}
            clubName=''
            groupName=''
          />
        )}
      <div
        className={classes.headerCover}
        style={{
          backgroundImage: clubBg,
          backgroundSize: "cover",
        }}
      >
        <div className={classes.userBgImg}>
          <div className={classes.userData}>
            <div className={classes.userImg}>
              <img
                src={clubData.image_url}
                alt="userDefaultImage"
                className={classes.userImage}
              />
            </div>
            <div className={classes.userDetail}>
              <h3 className={classes.userName}>{clubData.name}</h3>
              <span className={classes.userRole}>{clubData.description}</span>
              <div className={classes.userFollowers}>
                <span className={classes.subInfo}>
                  {clubData.follower_count} followers
                </span>
                <span>{clubData.following_count} following</span>
              </div>
            </div>
          </div>
          <div className={classes.userActions}>
            <span className={classes.userActionSpan}>
              <span className={classes.iconSpan}>
                <img src={col} />
              </span>
              <span className={classes.numberSpan}>
                {clubData.totel_collections}
              </span>
            </span>
            <span className={classes.userActionSpan}>
              <span className={classes.iconSpan}>
                <img src={designs} />
              </span>
              <span className={classes.numberSpan}>
                {clubData.totel_designs}
              </span>
            </span>
            <span className={classes.userActionSpan}>
              <span className={classes.iconSpan}>
                <img src={wishlist} />
              </span>
              <span className={classes.numberSpan}>
                {clubData.totel_like_designs}
              </span>
            </span>
            <span className={classes.userActionSpan}>
              <span className={classes.iconSpan}>
                <img src={promote} />
              </span>
              <span className={classes.numberSpan}>
                {clubData.totel_promoted_designs}
              </span>
            </span>
            <span className={classes.userActionSpan}>
              <span className={classes.iconSpan}>
                <img src={inspire} />
              </span>
              <span className={classes.numberSpan}>
                {clubData.totel_inspired_designs}
              </span>
            </span>
            <span className={classes.userActionSpan}>
              <span className={classes.iconSpan}>
                <StarIcon />
              </span>
              <span className={classes.numberSpan}>
                {clubData.totel_rated_designs}
              </span>
            </span>
            <span className={classes.userActionSpan}>
              <span className={classes.iconSpan}><b>FD:</b></span>
              <span className={classes.numberSpan}>
                {clubData.totel_free_designs}
              </span>
            </span>
            <span
              className={`${classes.userActionSpan} ${classes.userActionSpanLast
                }`}
            >
              <span className={classes.iconSpan}><b>PD:</b></span>
              <span className={classes.numberSpan}>
                {clubData.totel_paid_designs}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className={classes.top__container}>
        <div
          style={{
            display: 'flex',
            columnGap: '20px'
          }}
        >
          {allowChange && (
            <ChangeCoverWeb
              changeCurrentBg={(bgImg: any) => setClubBg(bgImg)}
              id={props.navigation.getParam("id", 0)}
              type="club"
            />
          )}

          <div
            className={classes.upload__bg__image}
            style={{
              backgroundColor: props.aboutPage ? "black" : "white",
              color: props.aboutPage ? "white" : "black",
            }}
            onClick={() => {
              if (!props.aboutPage) {
                props.navigation.navigate(groupPage ? "GroupAboutPage" : "ClubAboutPage", {
                  id: props.navigation.getParam("id", ""),
                })
              }
            }}
          >
            <span style={{ marginRight: "5px" }}> About </span>{" "}
          </div>

        </div>

        <LabelListing list={list} />

        <div className={classes.rightDivBtns}>
          <button
            className={classes.memberBtn}
            onClick={props.openMemberPopup}
          >
            <PeopleIcon className={classes.icons} />
            Members
          </button>
        </div>
      </div>
    </div>
  );
};

const useStyles: any = {
  navbar: {
    height: "70px",
    background: "black",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    verticalAlign: "middle",
  },
  logo: {
    height: "50px",
    width: "150px",
  },
  rightBar: {
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: "0.06",
  },
  navIcon: {
    width: "25px",
    height: "25px",
  },
  headerCover: {
    width: "100%",
    minHeight: "250px",
    // backgroundColor: 'lightblue',
    justifyContent: "center",
  },
  userBgImg: {
    backgroundImage: `url(${UserBackground})`,
    padding: "5% 0",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  userData: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
  },
  userImage: {
    height: "100px",
    width: "100px",
    borderRadius: "40%",
  },
  userDetail: {
    margin: "0px 40px",
    justifyContent: "top",
  },
  userName: {
    margin: "10px 0",
    color: "white",
    backgroundColor: "black",
    padding: "5px 10px",
    borderRadius: "20px",
  },
  userRole: {
    margin: "10px 0",
  },
  userFollowers: {
    margin: "10px 0",
  },
  subInfo: {
    marginRight: "10px",
  },
  userActions: {
    textAlign: "center",
    marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto auto auto auto",
    justifyContent: "center",
  },
  userActionSpan: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    borderRight: "1px solid black",
    cursor: "pointer",
  },
  userActionSpanLast: {
    borderRight: "none",
  },
  iconSpan: {
    marginRight: "10px",
    "& img": {
      width: "15px",
      height: "15px",
    },
  },
  userIcon: {
    height: "50px",
    width: "50px",
    borderRadius: "40%",
  },
  labels: {
    overflow: "auto",
    width: "500px",
    whiteSpace: "nowrap",
  },
  icons: {
    float: "left",
    marginTop: "-1px",
    marginRight: "7px",
    fontSize: "20px",
  },
  topContainer: {
    marginTop: "-20px",
  },
  memberBtn: {
    background: "white",
    padding: "10px",
    border: "1px solid #e8e8e8",
    fontWeight: "700",
    marginRight: "10px",
    cursor: "pointer",
  },
  Btn: {
    background: "black",
    padding: "7px 10px",
    color: "white",
    fontWeight: "700",
    borderRadius: "10px",
    marginRight: "10px",
    border: "none",
    cursor: "pointer",
  },
  rightDivBtns: {
    justifyContent: 'center',
    display: 'flex',
    columnGap: '20px'
    // position: "absolute",
    // right: "30px",
    // ["@media(max-width:767px)"]: {
    //   top: "408px",
    // },
  },
  collLabel: {
    ["@media(max-width:767px)"]: {
      padding: "7px 10px !important",
    },
  },
  GroupuserName: {
    margin: "10px 0",
    border: "1px solid gray",
    borderRadius: "25px",
    padding: "9px",
    textAlign: "center",
  },
  ClubuserName: {
    margin: "10px 0",
    borderRadius: "25px",
    padding: "9px",
    textAlign: "center",
    background: "black",
    color: "white",
  },
  top__container: {
    position: "relative",
    display: "grid",
    padding: "0px 20px",
    gridTemplateColumns: "0.55fr 0.9fr 0.55fr",
    alignItems: "center",
    alignContent: "center",
    marginTop: "-20px",
    marginBottom: "40px",
  },
  upload__bg__image: {
    top: "0",
    left: "30px",
    display: "flex",
    // position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    background: "white",
    fontWeight: "600",
    cursor: "pointer",
  },
  label__listing: {
    display: "flex",
    justifyContent: "center",

    "& span": {
      display: "inline-block",
      padding: "10px 20px",
      border: "1px solid black",
      borderCollapse: "collapsed",
      cursor: "pointer",
      backgroundColor: "white",
    },
  },
};

export default withStyles(useStyles)(ClubHeader);
