// @ts-nocheck
import React from "react";
// Customizable Area Start
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import FooterWeb from "../../catalogue/src/Footer.web";
import { darkLogo } from "./assets";
// Customizable Area End

import ArchiveDesignsController, {
  Props,
} from "./ArchiveDesignsController.web";

class ArchiveDesigns extends ArchiveDesignsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const CardContainer = (props: any) => {
      return <></>;
    };

    const Header = () => {
      return (
        <div className={classes.header}>
          <img src={darkLogo} alt="logo" />
          <div>

          </div>
        </div>
      )
    }
    return (
      <div className ={classes.container}>
        <header>
          <Header />
        </header>
        <main className = {classes.main}>
          <div className = {classes.main__header}>
            <h4>
              ARCHIVE DESIGNS
            </h4>
            <div className = {classes.main__functional__buttons}>
              <div className = {classes.main__checkbox}>
                <input type='checkbox' onChange={() => {
                  console.log('hey')
                }} />
                <label>Select</label>
              </div>
              <div className = {classes.main__checkbox}>
                <input type='checkbox' onChange={() => {
                  console.log('hey')
                }} />
                <label>Select all</label>
              </div>
              <button className = {classes.main__archive__button}>
                Restore All
              </button>
              <button className = {classes.main__archive__button}>
                Empty Archive
              </button>
            </div>
          </div>

        </main>
        <footer><FooterWeb
          navigation={this.props.navigation}
        /></footer>
      </div>
    );
  }
}


// Customizable Area Start
const useStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "80px",
    backgroundColor: "black",
    color: "white",
  },
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  main__header:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "90%",
    marginTop: "20px",
  },
  main__functional__buttons:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "12px",
    fontWeight: "bold",
  },
  main__checkbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
  },
  main__archive__button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
    backgroundColor: "white",
    color: "grey"
  }
};
// Customizable Area End
export default withStyles(useStyles)(ArchiveDesigns);
// Customizable Area End
