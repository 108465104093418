import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
import "./DesignerHome.web.css";

// Customizable Area End

import DesignerProfileByUserController, { Props } from "./DesignerProfileByUserController.web";
import Header from "../../catalogue/src/Header.web";
import Footer from "../../catalogue/src/Footer.web";
import CollectionListing from "../../catalogue/src/CollectionListing.web";
import SubHeaderWeb from "./SubHeader.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import DesignerPromotePopUpWeb from "./DesignerPromotePopUp.web";
import {
  logo,
  designLogo,
  star,
  likes,
  lightbulb,
  trending_up,
  edit,
  love,
} from "./assets";
import DesignsCollectionsGroupsList from "./CollectionView.web";
import CreateLabelDialog from "./CreateLabelDialog.web";
import DezinerSpinner from "../../../components/src/DezinerSpinner.web";
import DesignCardsResponsiveContainer from "./DesignCardsContainer.web";
import { CollectionHomeAttributes, Design } from "../../../components/src/api-types";

function NoData() {
  return (
    <div>
      {'No data'}
    </div>
  );
}

class DesignerProfileByUser extends DesignerProfileByUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
    const { classes } = this.props;
    const {
      changeLabel,
      activeCategory,
      designsData,
      arrLabels,
      loadingDesignData,
      currentLabel,
      displayCollectionsAsList,
    } = this.props.layoutProps;


    return (
      <>
        {/*
        <Header
          profileData={this.state.profileData}
          selfProfileImage={this.state.selfProfileData}
          selfProfileData={this.state.selfProfileData}
          handleOpen={this.handleOpen}
          arrLabels={this.state.arrLabels}
          currentLabel={this.state.currentLabel}
          navigation={this.props.navigation}
          changeLabel={this.changeLabel}
          onPromoteProfile={this.promoteProfile}
          aboutPage={false}
          userRole={"User"}
          setWhichScreen={this.setWhichScreen}
        />
          */}




        <div className="collection-view-container">

          <SubHeaderWeb
            title={activeCategory?.toUpperCase()}
            icon={''}
            arrLabels={arrLabels}
            currentLabel={currentLabel}
            navigation={this.props.navigation}
            changeLabel={changeLabel}
            aboutPage={false}
          />


          {
            loadingDesignData ?
              <DezinerSpinner />
              :
              activeCategory == 'collections' ?
                (designsData?.length ?? 0) > 0 ?

                  displayCollectionsAsList ?
                    designsData?.map(({ id, attributes }) => (
                      <CollectionListing
                        key={id}
                        promoteCollection={this.promoteProfile}
                        collection={attributes as CollectionHomeAttributes}
                        createPost={this.createPost}
                        navigation={this.props.navigation}
                        pinCollection={(collectionId: number, flag: boolean) => this.pinCollection(collectionId, flag)}
                      />
                    ))
                    :
                    <DesignsCollectionsGroupsList
                      collection={designsData as any}
                      onClick={(id: any) =>
                        this.props.navigation.navigate("CollectionViewByDesigner", {
                          collectionId: id,
                          labelId: currentLabel,
                        })}
                    />
                  :
                  <NoData />
                :
                designsData?.length ?
                  <DesignCardsResponsiveContainer
                    designs={(designsData ?? []) as Design[]}
                    onClick={(item) => {
                      this.props.navigation.navigate("DesignerDesignView", {
                        id: item?.id,
                      });
                    }}
                    onTrend={() => { }}
                    onEdit={(item) => {
                      this.props.navigation.navigate("DesignerDesignEdit", {
                        designId: item?.id,
                      });
                    }}
                  />
                  :
                  <NoData />
          }
        </div>












        {this.state.modalOpen && (
          <DesignerPromotePopUpWeb
            designerId={this.state.designerId}
            currentImage={this.state.profileData.profile_url}
            modalOpen={this.state.modalOpen}
            handleModalClose={this.handleModalClose}
            navigation={this.props.navigation}
            id={""}
            promoteType="Designer"
            promoteCollectionId={0}
            designId={0}
          />
        )}
      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = {
  formControl: {
    margin: "10px",
    minWidth: 120,
  },
  modal__step3__form: {
    display: "flex",
    flexDirection: "column",
  },
  modal__step3__cardNumber: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    "& label": {
      marginBottom: "5px",
      fontWeight: "bold",
    },
    "& input": {
      padding: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      width: "100%",
      "&:focus": {
        outline: "none",
        border: "1px solid #000",
      },
    },
  },
  modal__step3__cardDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",

    "& div ": {
      width: "40%",
      display: "flex",
      flexDirection: "column",
      "& label": {
        marginBottom: "5px",
        fontWeight: "bold",
      },
      "& input": {
        padding: "5px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        width: "100%",
        "&:focus": {
          outline: "none",
          border: "1px solid #000",
        },
      },
    },
  },
  modal__step3__price: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& span": {
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
  input__validation: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "5px",
  },
};
export default withStyles(useStyles)(DesignerProfileByUser);
// Customizable Area End
