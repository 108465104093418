import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  totalDesigns: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FooterController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTotalDesignsCount: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getTotalCount = this.getTotalCount.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      totalDesigns: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getTotalCount();
  }

  getTotalCount() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.totalDesigns,
    });

    this.getTotalDesignsCount = getLabelsData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // LABEL LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTotalDesignsCount != null &&
      this.getTotalDesignsCount ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ totalDesigns: responseJson.design_count });
        // if (responseJson.data.length > 0) {
        //   this.setState({ currentLabel: responseJson.data[0].id });
        // }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
  }

  // Customizable Area End
}
