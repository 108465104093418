import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useParams } from './utils';
import { useCreateCollection, useLabels } from './hooks';
import { RootState } from './store/store';
import DezinerButton from './Button.web';
import DezinerAlert from './Alert.web';
import FormikTextInput from './FormikTextField.web';


const createCollectionValidationSchema = yup.object().shape({
  collectionName: yup.string().required(),
  isPrivate: yup.bool(),
});

export default function CreateCollectionForm() {


  const params = useParams();
  const designerId = parseInt(params.designerId);

  const { label_id }: { label_id?: string } = useSelector((state: RootState) => state.router.location.query);
  const labelId = parseInt(label_id || '0');

  const labelsQuery = useLabels({ designerId });
  const hasLabels = Boolean(labelsQuery?.data?.data?.length ?? 0);

  const { mutate: createCollection } = useCreateCollection({
    labelId,
    designerId,
  });


  return (
    <Formik
      initialValues={{ collectionName: '', isPrivate: false }}
      validationSchema={createCollectionValidationSchema}
      onSubmit={(values, helpers) => {
        createCollection({
          ...values,
          labelId,
        });
        helpers.resetForm();
      }}
    >
      {
        formik => <Form translate="">

          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{
              padding: '40px 0'
            }}
          >
            <Grid
              item
              xs={6}
              container
              direction="column"
            >
              <Grid item container alignItems="center">
                <FormikTextInput
                  name="collectionName"
                  placeholder="Your collection name"
                  variant="outlined"
                  autoHeight
                  InputProps={{
                    style: {
                      maxHeight: '40px',
                    }
                  }}
                />
                <DezinerButton
                  color="primary"
                  variant="contained"
                  style={{ margin: '0 10px' }}
                  type="submit"
                >
                  {'Create'}
                </DezinerButton>
              </Grid>


              {
                !labelsQuery.isLoading && !hasLabels ?
                  <DezinerAlert>
                    {'You need to add a new Label first in order to create a new collection'}
                  </DezinerAlert>
                  :
                  null
              }

            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <FormControlLabel
                labelPlacement="end"
                control={<Checkbox
                  name="isPrivate"
                  onChange={(e) => formik.setFieldValue('isPrivate', e.target.checked)}
                  checked={formik.values.isPrivate}
                  color="primary"
                />}
                label="Keep this collection private"
              />
            </Grid>
          </Grid>

        </Form>
      }
    </Formik>
  );
}

