import React from "react";
// Customizable Area Start
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  star,
  lightbulb,
  trending_up,
  edit,
  love,
} from "./assets";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
// Customizable Area End
import DesignerHomeController, { Props } from "./DesignerHomeController.web";
import { DezinerCollectionList } from "../../../components/src/DezinerCollectionList.web";
import DesignCardsResponsiveContainer from "./DesignCardsContainer.web";
import DesignsCollectionsGroupsList from "./CollectionView.web";
import { DesignerHomeLayoutProps } from "../../../components/src/DesignerHomeLayout.web";
import { useParams } from "../../../components/src/utils";
import { CollectionHome, Design } from "../../../components/src/api-types";
import DezinerSpinner from "../../../components/src/DezinerSpinner.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import { useIsDesigner, useIsMyProfile, useNavigate } from "../../../components/src/hooks";

const categoryLogoMapper: any = {
  collections: '',
  designs: edit,
  liked: love,
  promoted: trending_up,
  inspired: lightbulb,
  rated: star,
}

export default class DesignerHome extends DesignerHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      <>
        <DesignerHomeContent {...this.props} />
      </>
    );
  }
}


function NoData() {

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>No Data</h2>
    </div>
  );
}



function LabelsDropdown({
  currentLabel,
  labels,
  onChange,
}: {
  currentLabel: number,
  labels: any[],
  onChange: (label: number) => void,
}) {
  return (
    <div>

      <FormControl variant="outlined">
        <Select style={{ maxHeight: '40px' }} value={currentLabel}>
          {
            labels?.map((item: any) =>
            (<MenuItem
              key={item.id}
              value={item.id}
              className={
                item.id == currentLabel
                  ? "active-label"
                  : ""
              }
              onClick={() => onChange(item.id)}
            >
              {item?.attributes?.name}
            </MenuItem>))
          }
        </Select>
      </FormControl>

    </div>
  );
}

//export default withStyles(useStyles)(DesignerHome);
function DesignerHomeContent(props: { layoutProps: DesignerHomeLayoutProps }) {

  const params = useParams();
  const categoryQueryParam = params?.category;
  const designerId = parseInt(params.designerId);

  const displayCollectionsAsList = !categoryQueryParam;

  const navigate = useNavigate();

  const {
    pinCollection,
    designs,
    category,
    loading,
    labelId,
  } = props?.layoutProps ?? {};

  const hasDesigns = (designs?.length ?? 0) > 0

  const isMyProfile = useIsMyProfile({ id: designerId });
  const isDesigner = useIsDesigner();

  const showCollectionOptions = isDesigner && isMyProfile;


  const CollectionList = (
    <div>
      {
        hasDesigns ?
          (designs as CollectionHome[])?.map((collection) => (
            <DezinerCollectionList
              hideCollectionOptions={!showCollectionOptions}
              onDesignClick={(design: any) => {
                navigate(`DesignView/${design.id}`);
              }}
              key={collection.id}
              onPromote={({ collectionId }) => {
                //this.promoteCollection('', collectionId)
              }}
              collection={collection}
              onPin={() => {
                pinCollection({
                  collectionId: collection.attributes.id,
                  pin: !collection.attributes.pined
                })
              }}
              onCreate={() => { }}
            />
          ))
          :
          <NoData />
      }
    </div>
  );

  const DesignsAndCollections = (
    category == 'collections' ?
      hasDesigns ?
        <DesignsCollectionsGroupsList
          collection={designs as any}
          onClick={(id: any) => navigate(`collection/${id}?label_id=${labelId}`)}
        />
        :
        <NoData />
      :
      hasDesigns ?
        <DesignCardsResponsiveContainer
          designs={(designs) as Design[]}
          onClick={(item) => {
            navigate(`DesignView/${item.id}`);
          }}
          onTrend={() => { }}
          onEdit={(item) => {
            navigate(`DesignerDesignEdit/${item.id}`);
          }}
        />
        :
        <NoData />
  );



  if (loading)
    return <DezinerSpinner />


  return (
    <div
      style={{ padding: '20px 20px 80px' }}
    >

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >

        <Grid xs={6} item container
          justifyContent="flex-start"
          alignItems="center"
        >
          {
            categoryLogoMapper?.[categoryQueryParam] ?
              <img
                style={{ maxWidth: '20px', marginRight: '10px', }}
                src={categoryLogoMapper?.[categoryQueryParam]}
                alt={categoryQueryParam}
              />
              :
              null
          }
          <Typography style={{ fontWeight: 'bold' }}>
            {categoryQueryParam?.toUpperCase()}
          </Typography>
        </Grid>

        <LabelsDropdown
          labels={props.layoutProps.labels}
          currentLabel={props.layoutProps.labelId}
          onChange={(labelId) => {
            props.layoutProps.changeLabel(labelId)
          }}
        />
      </Grid>

      <VerticalSpacer />

      {
        displayCollectionsAsList ?
          CollectionList
          :
          DesignsAndCollections
      }
    </div>
  );

}


