import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profileData: any;
  profileDataForAbout: any;
  loading: boolean;
  activeFilterId: number;
  activeSubFilter: string;
  subFilters: any;
  designs: any;
  screenNumber: number;
  userAbout: string;
  aboutEditable: boolean;
  meta: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserProfileByOthersController extends BlockComponent<
  Props,
  S,
  SS
> {
  profileMessageId: string;
  getDesignsId: string;
  getUserAboutId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.setSubFilters = this.setSubFilters.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      profileData: {},
      profileDataForAbout: {},
      loading: true,
      activeFilterId: 6,
      activeSubFilter: "All",
      designs: [],
      subFilters: [],
      screenNumber: 1,
      userAbout: "",
      aboutEditable: false,
      meta: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const id = this.props.navigation.getParam("id", "");
    this.getProfile();
    this.setSubFilters(6);
    this.getDesigns(6, "All");
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // SELF PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const date = responseJson.data.attributes.created_at.split('T')[0];
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        responseJson.data.attributes.created_at = [day, month, year].join('-');
        this.setState({
          profileData: responseJson.data.attributes,
          profileDataForAbout: responseJson.data,
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // GET DESIGNS ACCORDING TO FILTER API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDesignsId != null &&
      this.getDesignsId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        console.log(
          "🚀 ~ file: UserProfileByOthersController.web.tsx ~ line 130 ~ receive ~ responseJson",
          responseJson
        );
        this.setState({
          designs: responseJson.data,
          meta: 'meta' in responseJson ? responseJson.meta : {},
          loading: false,
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getUserAboutId != null &&
      this.getUserAboutId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          userAbout: responseJson.data.attributes.about,
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  // getUserAbout = () => {
  //   const header = {
  //     "Content-Type": configJSON.exampleApiContentType,
  //     token: localStorage.getItem("token"),
  //   };

  //   const httpBody = {};
  //   const apiUrl = configJSON.otherUserAboutApi + "/42" + "/other_user_about";
  //   const getUserAboutData = apiCall({
  //     header: header,
  //     httpBody: httpBody,
  //     httpMethod: configJSON.validationApiMethodType,
  //     url: apiUrl,
  //   });

  //   this.getUserAboutId = getUserAboutData.messageId;
  //   runEngine.sendMessage(getUserAboutData.id, getUserAboutData);
  //   return true;
  // };

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const id = this.props.navigation.getParam("id", "");
    const apiUrl = configJSON.otherUserAboutApi + "/" + id + "/other_user_about";
    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  setSubFilters(itemId: number): void {
    const subFilters =
      itemId === 6 || itemId === 1
        ? {
          all_designs_count: 'All',
          uploaded_designs_count: 'Uploaded designs',
          save_designs_count: 'Saved design',
        }
        : {
          all_designs: 'All',
          clubs: 'Club',
          groups: 'Group',
          designers: 'Designer'
        };

    this.setState({
      subFilters: subFilters,
    });
  }

  getFilteredApiUrlForDesigns(userId: number, filterId: number): string {
    let apiUrl = configJSON.otherUserDesignsApi + "/" + userId;
    switch (filterId) {
      case 6:
        apiUrl += "/other_user_all_designs";
        break;
      case 5:
        apiUrl += "/other_user_rated_designs";
        break;
      case 4:
        apiUrl += "/other_user_inspired_designs";
        break;
      case 3:
        apiUrl += "/other_user_shared_designs";
        break;
      case 2:
        apiUrl += "/other_user_liked_designs";
        break;
      case 1:
        apiUrl += "/other_user_saved_designs";
        break;
      default:
        break;
    }
    return apiUrl;
  }

  getSubFilteredApiUrlForDesigns(filterId: number, subFilter: string): string {
    if (filterId === 1) {
      return "";
    } else if (filterId === 6 && subFilter === "Uploaded Designs") {
      return `?filter=Uploaded_designs`;
    } else if (filterId === 6 && subFilter === "Saved Designs") {
      return `?filter=Saved_designs`;
    } else if (subFilter === "All") {
      return "";
    } else {
      return `?filter=${subFilter.replace(' ','_')}`;
    }
  }

  getDesigns(filterDesigns: number, subFilter: string): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    let userId = this.props.navigation.getParam("id", "");
    let apiUrl = this.getFilteredApiUrlForDesigns(userId, filterDesigns);
    apiUrl += this.getSubFilteredApiUrlForDesigns(filterDesigns, subFilter);
    console.log(
      "🚀 ~ file: UserProfileByOthersController.web.tsx ~ line 257 ~ apiUrl",
      apiUrl
    );

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.getDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  onTrendingButtonClick = (id: number) => {
    console.log("Working");
  };
  onShareButtonClick = (id: number) => {
    console.log("Working");
  };
  onEditButtonClick = (id: number) => {
    console.log("Working");
  };

  changeScreenNumber = (id: number) => {
    this.setState({
      screenNumber: id,
    });
  };

  // Customizable Area End
}
