import React, { useEffect, useState } from 'react';
import DesignViewPageController, { Props } from "./DesignViewController.web";
import { useNavigate, useSingleDesign } from '../../../components/src/hooks';
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import { useParams } from '../../../components/src/utils';
import ProfileAvatar from '../../../components/src/ProfileAvatar.web';
import DesignTitle from '../../../components/src/DesignTitle.web';
import DesignActionsButtons from '../../../components/src/DesignActions.web';
import DesignMetaButtons from '../../../components/src/DesignMetaButtons.web';
import DesignMainMedia from '../../../components/src/DesignMainMedia.web';
import DesignControls from '../../../components/src/DesignControls.web';
import DesignDescription from '../../../components/src/DesignDescription.web';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import DesignCommentsSection from '../../../components/src/DesignComments.web';
import OwnerVerticalAvatar from '../../../components/src/DesignVerticalAvatar.web';
import MoreDesignsSection from '../../../components/src/MoreDesignsSection.web';
import DesignSubDesignsCards from '../../../components/src/DesignSubDesigns.web';
import { PaymentStatusDialog } from './DesignBuyContent.web';
import { useSelector } from 'react-redux';
import { RootState } from '../../../components/src/store/store';


export default class DesignViewPage extends DesignViewPageController {

  constructor(props: Props) {
    super(props);
  }

  render() {

    return (<DesignViewPageContent />);
  }
}

//////////////////

function PaymentStatusDialogContainer() {


  const [paymentSubmitted, setPaymentSubmitted] = useState(false);


  const queryParams = useSelector((state: RootState) => state.router.location.query);

  const redirect_status = queryParams.redirect_status;
  const query_payment_intent_client_secret = queryParams.payment_intent_client_secret;
  const query_payment_intent = queryParams.payment_intent;

  const params = useParams();
  const designId = parseInt(params?.designId ?? 0);
  const navigate = useNavigate();

  const paymentHasSucceed = redirect_status == 'succeeded' &&
    query_payment_intent == sessionStorage.getItem('payment_intent_id') &&
    query_payment_intent_client_secret == sessionStorage.getItem('client_secret')

  useEffect(() => {
    if (paymentHasSucceed)
      setPaymentSubmitted(true);
  }, [paymentHasSucceed]);

  return (
    <PaymentStatusDialog
      open={paymentSubmitted}
      onClose={() => {
        setPaymentSubmitted(false);
        sessionStorage.clear();
        navigate(`/DesignView/${designId}`);
      }}
      severity={'success'}
      title={'Payment succeed'}
      message={`Thank you. \n\n You have successfully bought this design. \n\n You can click on download button to download all the source files of your new bought design.`}
    />
  );
}

function DesignViewPageContent() {

  const params = useParams();
  const designId = parseInt(params.designId);

  const designQuery = useSingleDesign({ designId });

  const designAttributes = designQuery?.data?.design?.data?.attributes;
  const designOwnerId = designAttributes?.designer?.data?.attributes?.id ?? 0;

  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);


  return (
    <>
      <PaymentStatusDialogContainer />
      <div style={{ maxWidth: '1200px', margin: '50px auto', padding: '0 10px' }}>

        <Grid container >

          <Grid item container xs={12}>
            <Grid item sm={2}></Grid>
            <Grid item sm={8} xs={12}>

              <Grid container>
                <Grid item sm xs={12} container >
                  <Grid>
                    <ProfileAvatar
                      id={designOwnerId}
                    />
                  </Grid>
                  <Grid item xs container alignItems="center" style={{ paddingLeft: '10px' }}>
                    <DesignTitle
                      profileId={designOwnerId}
                      designId={designAttributes?.id ?? 0}
                    />
                  </Grid>
                </Grid>
                <Grid item
                  sm={7}
                  xs={12}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ padding: '0 20px' }}
                >
                  <DesignActionsButtons designId={designId} />
                </Grid>
              </Grid>

            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>

          <Grid item xs={12}>
            <VerticalSpacer />
          </Grid>


          <Grid item container xs={12} >
            <Grid
              item
              xs={12}
              sm={2}
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ padding: '0 10px' }}
            >
              <DesignMetaButtons designId={designId} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <DesignMainMedia
                designId={designId}
                selectedMediaIndex={selectedMediaIndex}
              />
            </Grid>
            <Grid item xs={12} sm={2}
              container
              style={{ paddingLeft: '10px' }}
              alignItems="center"
              justifyContent="flex-start"
            >
              <DesignSubDesignsCards
                selectedDesignIndex={selectedMediaIndex}
                designId={designId}
                onClick={setSelectedMediaIndex}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} >
            <Grid item sm={2}></Grid>
            <Grid item sm={8} xs={12} style={{ padding: '8px' }}>
              <DesignControls designId={designId} />
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>

          <Grid item container xs={12} >
            <Grid item sm={2}></Grid>
            <Grid item sm={8} xs={12} style={{ padding: '8px' }}>
              <DesignDescription designId={designId} />
            </Grid>
            <Grid item sm={2}></Grid>
          </Grid>

        </Grid>




        <Grid item container xs={12}>
          <Grid item sm={2}></Grid>
          <Grid item sm={8} xs={12}>

            <VerticalSpacer />
            <Divider />
            <VerticalSpacer />

            <DesignCommentsSection designId={designId} />

            <OwnerVerticalAvatar designId={designId} />


          </Grid>
          <Grid item sm={2}></Grid>
        </Grid>

        <MoreDesignsSection designId={designId} />

      </div>
    </>
  );

}

