import React, { useState } from 'react';
import { Category } from './api-types';
import { filterListWhite, filterListBlack } from '../../blocks/landingpage/src/assets';
import clsx from 'clsx';
import DezinerButton from './Button.web';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MaterialPopover from "@material-ui/core/Popover";
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import MaterialFormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialListItem from '@material-ui/core/ListItem';
import MaterialListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

const Popover = withStyles({
  paper: {
    borderRadius: '10px',
  },
})(MaterialPopover);

const ListItem = withStyles({
  root: {
    padding: '1px 6px',
  }
})(MaterialListItem);


const ListItemText = withStyles({
  root: {
    padding: '4px 2px',
    '&>*': {
      fontSize: '0.9rem',
      color: '#767779',
      '&:hover': {
        color: '#929395'
      }
    }
  }
})(MaterialListItemText);


const FormControlLabel = withStyles({
  root: {
    // border: '1px solid green',
    marginLeft: '10px',
    '&>.MuiTypography-root': {
      whiteSpace: 'nowrap',
      maxWidth: '170px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '0.8rem',
      color: '#767779',
      '&:hover': {
        color: '#929395'
      }
    }
  }
})(MaterialFormControlLabel);



function CollapseList({ title, list }: {
  title: string,
  list: {
    title: string,
    isChecked: boolean,
    onCheck: (value: boolean, listTitle: string, title: string) => void
  }[]
}) {

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={title?.toLowerCase() ?? ''} style={{ textTransform: 'capitalize' }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            list.map((item) => (
              <ListItem button>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.isChecked}
                      onChange={(event) => {
                        item.onCheck(event.target.checked, item.title, title)
                      }}
                      name={item.title}
                      color="primary"
                      size="small"
                    />
                  }
                  label={item?.title?.toLowerCase() ?? ''}
                  style={{ textTransform: 'capitalize' }}
                />

              </ListItem>
            ))
          }

        </List>
      </Collapse>
    </>
  );
}


const useStyles = makeStyles({
  filterButton: {
    '&:hover': {
      background: 'black',
      color: 'white',
    },
  },
  filterButtonActive: {
    background: 'black',
    color: 'white',
  },
});

export default function CategoriesFilterButton({
  onToggle,
  selectedCategoriesIds,
  categories,
  onClear,
}: {
  onToggle: (id: number) => void,
  selectedCategoriesIds: number[],
  categories: Category[],
  onClear: () => void,
}) {

  const [enter, setEnter] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filters' : undefined;


  return (
    <div>

      <Grid container alignItems="center">

        {
          selectedCategoriesIds?.length ?
            <DezinerButton
              style={{ margin: '0 5px' }}
              startIcon={<CloseIcon />}
              onClick={() => onClear()}>
              {'clear'}
            </DezinerButton>
            :
            null
        }
        <DezinerButton
          onMouseEnter={() => setEnter(true)}
          onMouseLeave={() => setEnter(false)}
          style={{
            fontSize: '0.9rem',
            padding: '6px 12px',
          }}
          className={clsx(classes.filterButton, {
            [classes.filterButtonActive]: Boolean(selectedCategoriesIds.length),
          })}
          aria-describedby={id}
          variant="outlined"
          color="primary"
          onClick={handleClick}
        >
          <img
            src={enter || Boolean(selectedCategoriesIds.length) ? filterListWhite : filterListBlack}
            alt="filters"
            style={{ maxWidth: '20px', margin: '0 4px' }}
          />
          {'Filters'}
        </DezinerButton>


        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}

        >
          <div
            style={{
              padding: '8px',
              maxHeight: '300px',
              overflowY: 'auto'
            }}
          >
            {
              categories
                ?.map(category => category.attributes)
                ?.map((category) => (
                  <CollapseList
                    key={category.id}
                    title={category.name}
                    list={category?.sub_categories?.data?.map((subCategory) => (
                      {
                        title: subCategory?.attributes?.name,
                        isChecked: selectedCategoriesIds.includes(subCategory?.attributes?.id),
                        onCheck: () => { onToggle(subCategory.attributes.id) },
                      }))
                    }
                  />
                ))
            }

          </div>
        </Popover>
      </Grid>

    </div>
  )
}
