import React from "react";
// Customizable Area Start
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
// Customizable Area End

import DesignBuyController, {
  Props,
} from "./DesignBuyController.web";
import DesignBuyPageContent from "./DesignBuyContent.web";

class DesignBuy extends DesignBuyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <DesignBuyPageContent />
      </>
    );
  }
}


// Customizable Area Start
export default DesignBuy;
// Customizable Area End
