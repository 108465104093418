import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import MaterialSlider from "@material-ui/core/Slider";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import DialogActions from "@material-ui/core/DialogActions";
import MaterialDialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MaterialTextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import { ChevronLeft, Close as CloseIcon, Edit } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./DesignerHome.web.css";

// Customizable Area End

import DesignerPromotePopUpController, {
  Props,
} from "./DesignerPromotePopUpController.web";

const TextField = withStyles({
  root: {
    '& > *': {
      maxHeight: '40px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#7c7d7f',
      },
      '&:hover fieldset': {
        borderColor: '#7c7d7f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#black',
      },
    },
  }
})(MaterialTextField);

const Slider = withStyles({
  root: {
    color: '#323335',
    height: 8,
    display: 'block',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '8px solid #08080a',
    marginTop: -8,
    marginLeft: -12,
  },
  active: {},
  valueLabel: {
    whiteSpace: 'nowrap',
    left: 'calc(-50% - 5px)',
    top: -28,
    '& *': {
      background: 'transparent',
      color: '#767676',
    },
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'gray',
  },
  markLabel: {
    '&[data-index="0"]': {
      top: '40px !important',
      left: "10% !important",
      width: "200px",
      transform: "translateX(-20%)",
      whiteSpace: "nowrap",
      color: '#767676',
    },
    '&[data-index="1"]': {
      top: '40px !important',
      left: "90% !important",
      width: "200px",
      textAlign: "right",
      transform: "translateX(-80%)",
      whiteSpace: "nowrap",
      color: '#767676',
    },
  },
  track: {
    height: 8,
    borderRadius: 0,
  },
  rail: {
    height: 8,
    borderRadius: 0,
  },
})(MaterialSlider);

const Dialog = withStyles({
  paper: {
    borderRadius: '10px',
  }
})(MaterialDialog);



class DesignerPromotePopUp extends DesignerPromotePopUpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;


    const Step1 = (
      <>
        <Typography variant="body1" className={classes.stepTitle}>
          {'Budget & Duration'}
        </Typography>

        <div className={classes.sliderSpace} />

        <Slider
          defaultValue={this.state.impressions}
          min={10000}
          max={100000}
          valueLabelFormat={this.valuetext}
          onChange={this.handleSliderChange}
          aria-labelledby="impressions"
          step={10000}
          marks={this.marks}
          valueLabelDisplay="on"
        />

        <div className={classes.sliderSpace} />

        <Slider
          defaultValue={this.state.duration}
          min={1}
          max={12}
          valueLabelFormat={this.valuetextMonth}
          onChange={this.handleMonthChange}
          aria-labelledby="duration"
          step={1}
          valueLabelDisplay="on"
          marks={this.marksMonth}
        />

        <div className={classes.sliderSpace} />

        <Grid container justifyContent="space-between">
          <Grid item xs={9}>
            <Typography className={classes.calculatedPhrase}>
              {`${this.state.impressions} total impressions over ${this.state.duration} Month`}
            </Typography>
          </Grid>

          <Grid item xs={3} container justifyContent="flex-end">
            <Typography className={classes.totalPhrase}>
              <b>{`Rs. ${this.state.totalAmount}`}</b>
            </Typography>
          </Grid>
        </Grid>
      </>
    );

    const Step2 = (<>
      <Typography variant="body1" className={classes.stepTitle}>
        {'Review Your Boost'}
      </Typography>

      {
        this.state.editable ? (
          <div className={classes.flexBetween}>
            <div className="formLabel">
              <Typography>{'Button Link'}</Typography>
              <FormControl className={classes.formControl}>
                <Select
                  value={this.state.promoteType}
                  onChange={(e) => this.handlePromoteType(e)}
                >
                  <MenuItem value="Design">{'Design'}</MenuItem>
                  <MenuItem value="Collection">{'Collection'}</MenuItem>
                  <MenuItem value="Designer">{'Designer'}</MenuItem>
                  <MenuItem value="Group/Club">{'Group/Club'}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        ) : (
            <div className={classes.flexBetween}>
              <div>
                <Typography className={classes.titleText}>{'Button Link'}</Typography>
                <Typography className={classes.subTitleText}>{this.state.promoteType}</Typography>
              </div>
            </div>
          )
      }

      <div className={classes.flexBetween}>
        <div>
          <Typography className={classes.titleText}>{'Total views'}</Typography>
          <Typography className={classes.subTitleText}>{this.state.impressions}</Typography>
        </div>
        <IconButton>
          <Edit onClick={() => this.setState({ step: 1 })} />
        </IconButton>
      </div>

      <div className={classes.flexBetween}>
        <div>
          <Typography className={classes.titleText}>{'Total Price'}</Typography>
          <Typography className={classes.subTitleText}>{`Rs. ${this.state.totalAmount}`}</Typography>
        </div>
      </div>

    </>);


    const Step3 = (
      <div className={classes.step3Container}>

        <Typography variant="body1" className={classes.stepTitle}>
          {'Payment Details'}
        </Typography>


        <div className={classes.formItem}>
          <label>{'Card Number'}</label>
          <TextField
            variant="outlined"
            value={this.state.cardNumber}
            placeholder="0000 0000 0000 0000"
            type="text"
            onChange={this.handleCardNumberInputChange}
            inputProps={{
              maxLength: 16,
              minLength: 16,
            }}
          />
          {
            this.state.validationStep3Errors.cardNumber ?
              <Alert severity="error">
                {this.state.validationStep3Errors.cardNumber}
              </Alert>
              :
              null
          }
        </div>

        <div className={classes.flexBetween}>

          <div className={classes.formItem}>
            <label>{'Expiration Date'}</label>
            <TextField
              value={this.state.expirationDate}
              placeholder="MM/YY"
              type="text"
              variant="outlined"
              onChange={this.handleExpirationDateInputChange}
              inputProps={{
                maxLength: 5,
                minLength: 5,
              }}
            />
            {
              this.state.validationStep3Errors.expirationDate ?
                <Alert severity="error">
                  {this.state.validationStep3Errors.expirationDate}
                </Alert>
                :
                null
            }
          </div>

          <div className={classes.horizontalSpace} />

          <div className={classes.formItem}>
            <label>{'CVV'}</label>
            <TextField
              value={this.state.cvv}
              onChange={this.handleCVVInputChange}
              placeholder="123"
              variant="outlined"
              type="text"
              inputProps={{
                maxLength: 4,
                minLength: 3,
              }}
            />
            {
              this.state.validationStep3Errors.cvv ?
                <Alert severity="error">
                  {this.state.validationStep3Errors.cvv}
                </Alert>
                :
                null
            }
          </div>

        </div>

        <Divider />

        <div>
          <div className={classes.flexBetween}>
            <Typography className={classes.titleText}>{'Subtotal'}</Typography>
            <Typography
              className={classes.subTitleText}
            >
              {`Rs. ${this.state.totalAmount}`}
            </Typography>
          </div>

          <div className={classes.flexBetween}>
            <Typography className={classes.titleText}>{'Total'}</Typography>
            <Typography className={classes.titleText}>{`Rs. ${this.state.totalAmount}`}</Typography>
          </div>
        </div>

      </div>);

    const renderCurrentStep = (step: number) => {
      switch (step) {
        case 1: return Step1;
        case 2: return Step2;
        case 3: return Step3;
      }
    }

    return (
      <>

        <Dialog
          open={this.props.modalOpen}
          fullWidth
          maxWidth="xs"
        >


          <div className={classes.imageContainer}>
            {
              this.props.currentMediaType?.includes('video/') ?
                <video className={classes.image}>
                  <source src={this.props.currentImage}  />
                </video>
                :
                <img className={classes.image} src={this.props.currentImage} />
            }
          </div>

          <IconButton
            className={classes.closeBtn}
            onClick={() => {
              this.props.handleModalClose();
            }}
          >
            <CloseIcon className={classes.closeIcon} fontSize="large" />
          </IconButton>

          <DialogContent className={classes.contentContainer}>

            {renderCurrentStep(this.state.step)}

            <DialogActions className={classes.dialogActions}>

              <Button className={classes.prevBtn} variant="outlined" onClick={this.backButton}>
                <ChevronLeft fontSize="large" />
              </Button>

              <Button
                className={classes.nextBtn}
                variant="contained"
                onClick={this.nextButton}
              >
                {this.state.step === 3 ? (
                  this.state.loadingApi ? (
                    <CircularProgress />
                  ) : "Finish"
                ) : "Next"}
              </Button>
            </DialogActions>

          </DialogContent>

        </Dialog>

        {/* <Modal open={this.props.modalOpen}>
          <div
            className="promotePopup"
            style={{ display: this.props.modalOpen ? "flex" : "none" }}
          >
            <span
              className="promotePopupCloseIcon"
              onClick={() => {
                this.props.handleModalClose();
              }}
            >
              <CloseIcon />
            </span>
            <div className="promoteContainer">
              <div
                className="promoteTitle"
              >
                <img
                  style={{
                    width: this.props.promoteType === 'Design' ? "300px" : "150px",
                    height: this.props.promoteType === 'Design' ? "100%" : "150px",
                    borderRadius: this.props.promoteType === 'Design' ? '0' : '50%'
                  }}
                  src={this.props.currentImage}
                />
              </div>
              {this.state.step === 1 ? (
                <>
                  <div className="promoteMainContainer">
                    <p className="containerTitle">Budget & Duration</p>
                    <Slider
                      defaultValue={this.state.impressions}
                      min={10000}
                      max={100000}
                      getAriaValueText={this.valuetext}
                      onChange={this.handleSliderChange}
                      aria-labelledby="discrete-slider-custom"
                      step={10000}
                      valueLabelDisplay="auto"
                      marks={this.marks}
                    />
                    <Slider
                      defaultValue={this.state.duration}
                      min={1}
                      max={12}
                      getAriaValueText={this.valuetextMonth}
                      onChange={this.handleMonthChange}
                      aria-labelledby="discrete-slider-custom"
                      step={1}
                      valueLabelDisplay="auto"
                      marks={this.marksMonth}
                    />
                  </div>
                  <div className="promoteSummary">
                    <p>
                      {this.state.impressions} total impressions over{" "}
                      {this.state.duration} Month
                    </p>
                    <p>
                      <b>Rs. {this.state.totalAmount}</b>
                    </p>
                  </div>
                </>
              ) : null}
              {this.state.step === 2 ? (
                <>
                  <div className="promoteMainContainer">
                    <p className="containerTitle">Review Your Boost</p>
                    {this.state.editable ? (
                      <div className="containerFormControl">
                        <div className="formLabel">
                          <p>Button Link</p>
                          <FormControl className={classes.formControl}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={this.state.promoteType}
                              onChange={(e) => this.handlePromoteType(e)}
                            >
                              <MenuItem value="Design">Design</MenuItem>
                              <MenuItem value="Collection">Collection</MenuItem>
                              <MenuItem value="Designer">Designer</MenuItem>
                              <MenuItem value="Group/Club">Group/Club</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    ) : (
                      <div className="containerFormControl">
                        <div className="formLabel">
                          <p>Button Link</p>
                          <span>{this.state.promoteType}</span>
                        </div>
                      </div>
                    )}
                    <div className="containerFormControl">
                      <div className="formLabel">
                        <p>Total views</p>
                        <span>{this.state.impressions}</span>
                      </div>
                      <Edit onClick={() => this.setState({ step: 1 })} />
                    </div>
                    <div className="containerFormControl">
                      <div className="formLabel">
                        <p>Total Price</p>
                        <span>Rs. {this.state.totalAmount}</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {this.state.step === 3 ? (
                <>
                  <div className="promoteMainContainer">
                    <p className="containerTitle">Payment Details</p>
                    <div className={classes.modal__step3__form}>
                      <div className={classes.modal__step3__cardNumber}>
                        <label>Card Number</label>
                        <input
                          value={this.state.cardNumber}
                          placeholder="0000 0000 0000 0000"
                          type="text"
                          onChange={(e) => {
                            this.handleCardNumberInputChange(e);
                          }}
                          maxLength={16}
                          minLength={16}
                        />
                        <p className={classes.input__validation}>
                          {this.state.validationStep3Errors.cardNumber}
                        </p>
                      </div>
                      <div className={classes.modal__step3__cardDetails}>
                        <div>
                          <label>Expiration Date</label>
                          <input
                            value={this.state.expirationDate}
                            placeholder="MM/YY"
                            type="text"
                            onChange={(e) => {
                              this.handleExpirationDateInputChange(e);
                            }}
                            maxLength={5}
                            minLength={5}
                          />
                          <p className={classes.input__validation}>
                            {this.state.validationStep3Errors.expirationDate}
                          </p>
                        </div>
                        <div>
                          <label>CVV</label>
                          <input
                            value={this.state.cvv}
                            onChange={(e) => {
                              this.handleCVVInputChange(e);
                            }}
                            placeholder="123"
                            type="text"
                            maxLength={4}
                            minLength={3}
                          />
                          <p className={classes.input__validation}>
                            {this.state.validationStep3Errors.cvv}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: "3px solid #e6e6e6",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      />
                      <div className={classes.modal__step3__price}>
                        <span>Subtotal</span>
                        <span
                          style={{
                            color: "grey",
                          }}
                        >
                          {this.state.totalAmount}
                        </span>
                      </div>
                      <div className={classes.modal__step3__price}>
                        <span>Total</span>
                        <span>{this.state.totalAmount}</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="promoteFooter">
                <Button variant="outlined" onClick={this.backButton}>
                  <ChevronLeft />
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  variant="contained"
                  onClick={this.nextButton}
                >
                  {this.state.step === 3 ? (
                    this.state.loadingApi ? (
                      <CircularProgress />
                    ) : "Finish"
                  ) : "Next"}
                </Button>
              </div>
            </div>
          </div>
        </Modal> */}
      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = ({

  imageContainer: {
    background: "#e7e7e7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px"
  },
  image: {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    borderRadius: "40%",
    position: "relative",
    top: "-50px",
    boxShadow: "4px 4px 4px #5e5e5e",
    maxHeight: '200px',
  },
  contentContainer: {
    padding: "20px 40px",
    overflowX: 'hidden',
    '& > *': {
      margin: '20px 0',
    }
  },

  centerText: {
    textAlign: 'center',
  },
  iconButton: {
    background: '#e1e1e1'
  },
  closeBtn: {
    position: 'fixed',
    top: '3vh',
    right: '3vw',
  },
  closeIcon: {
    color: 'white'
  },
  stepTitle: {
    textAlign: 'center',
    margin: "0",
    fontSize: "1.4rem",
    fontWeight: 900
  },
  sliderSpace: {
    height: '8px'
  },
  calculatedPhrase: {
    color: '#767676',
    fontWeight: 700, fontSize: "0.9rem"
  },
  totalPhrase: {
    color: 'black',
  },
  nextBtn: {
    backgroundColor: "black",
    color: "white",
    textTransform: 'none',
    borderRadius: '10px',
    minWidth: '45px',
    minHeight: '45px',
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: "black",
    }
  },
  prevBtn: {
    backgroundColor: "#e2e3e5",
    color: "black",
    borderRadius: '10px',
    minWidth: '45px',
    minHeight: '45px',
    maxWidth: '45px',
    maxHeight: '45px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0'
  },
  titleText: {
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: 800,
  },
  subTitleText: {
    color: '#7c7d7f',
    fontSize: '1rem',
    fontWeight: 800,
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',

    '& label': {
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 800,
      marginBottom: '10px'
    }
  },
  horizontalSpace: {
    width: '40px'
  },
  step3Container: {
    '&>*': {
      margin: '20px 0'
    },
  }
});
/*
const useStyles: any = {
  formControl: {
    margin: "10px",
    minWidth: 120,
  },
  modal__step3__form: {
    display: "flex",
    flexDirection: "column",
  },
  modal__step3__cardNumber: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    "& label": {
      marginBottom: "5px",
      fontWeight: "bold",
    },
    "& input": {
      padding: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      width: "100%",
      "&:focus": {
        outline: "none",
        border: "1px solid #000",
      },
    },
  },
  modal__step3__cardDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",

    "& div ": {
      width: "40%",
      display: "flex",
      flexDirection: "column",
      "& label": {
        marginBottom: "5px",
        fontWeight: "bold",
      },
      "& input": {
        padding: "5px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        width: "100%",
        "&:focus": {
          outline: "none",
          border: "1px solid #000",
        },
      },
    },
  },
  modal__step3__price: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& span": {
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
  input__validation: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "5px",
  },
};
*/
export default withStyles(useStyles)(DesignerPromotePopUp);
// Customizable Area End
