import { Profile } from '../../api-types';
import { SET_PROFILE, SetProfileAction, FetchProfileAction, FETCH_PROFILE } from './profile.action';
import { produce } from 'immer';

export interface ProfileState {
  profile: Partial<Profile>,
  loading: boolean,
}

const initState: ProfileState = {
  profile: {},
  loading: false,
}

export type ProfileActions = SetProfileAction | FetchProfileAction;

export const profileReducer = produce((state: ProfileState, action: ProfileActions) => {

  switch (action.type) {

    case FETCH_PROFILE: {
      state.loading = true;
      break;
    }

    case SET_PROFILE: {
      state.profile = action.profile;
      state.loading = false;
      break;
    }
  }
}, initState);
