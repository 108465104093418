import React from "react";
// Customizable Area Start
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
import "./DesignerHomeDesign.web.css";
import { withStyles } from '@material-ui/core/styles';
import {
  star,
  lightbulb,
  trending_up,
  edit,
  love,
} from "./assets";
// Customizable Area End

import DesignsCollectionsGroupsList from "./CollectionView.web";
import SubHeaderWeb from "./SubHeader.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import './DesignerCollectionView.web.css';
import DesignCardsResponsiveContainer from "./DesignCardsContainer.web";
import DesignerCollectionViewController, { Props } from './DesignerCollectionViewController.web';
import { Design } from "../../../components/src/api-types";
import { DesignerCollectionListContainer, DesignerDesignDataByCategory, DesignerDezinerHeader, DesignerDezinerTopNav, DesignerLabelListing } from "../../../components/src/ReduxContainers.web";

export let designCategoryToURLMap: any = {
  "collections": "CollectionView",
  "designs": "null",
  "liked": "LIKED",
  "promoted": "PROMOTED",
  "inspired": "INSPIRED",
  "rated": "RATED",
  "free": "FREE",
  "paid": "PAID",
}


export let urlToDesignCategoryMap: any = {
  "CollectionView": "collections",
  "null": "designs",
  "LIKED": "liked",
  "PROMOTED": "promoted",
  "INSPIRED": "inspired",
  "RATED": "rated",
  "FREE": "free",
  "PAID": "paid",
}


class DesignerCollectionView extends DesignerCollectionViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
/*
    const {
      designsData,
      loadingDesignData,
      arrLabels,
      changeLabel,
      currentLabel,
      activeCategory,
    } = this.props.layoutProps || {};

    const inCollectionView = activeCategory == 'collections';

    let subScreenTitle = "", subScreenLogo = null;

    switch (activeCategory) {

      case "designs": {
        subScreenTitle = "DESIGNS"
        subScreenLogo = edit;
        break;
      }
      case "liked": {
        subScreenTitle = "LIKED DESIGNS"
        subScreenLogo = love;
        break;
      }
      case "promoted": {
        subScreenTitle = "PROMOTED DESIGNS"
        subScreenLogo = trending_up;
        break;
      }
      case "inspired": {
        subScreenTitle = "INSPIRED DESIGNS"
        subScreenLogo = lightbulb;
        break;
      }
      case "rated": {
        subScreenTitle = "RATED DESIGNS"
        subScreenLogo = star;
        break;
      }
      case "free": {
        subScreenTitle = "FREE DESIGNS"
        subScreenLogo = null;
        break;
      }
      case "paid": {
        subScreenTitle = "PAID DESIGNS"
        subScreenLogo = null;
        break;
      }
    }

*/


    return (
      <div>
        {/*
        <div style={{ border: '1px solid red' }}>
          <DesignerDezinerTopNav />
          <DesignerDezinerHeader />
          <DesignerLabelListing />
          <DesignerDesignDataByCategory />
        </div>

        <div className="collection-view-container">

          <SubHeaderWeb
            title={inCollectionView ? "COLLECTIONS" : subScreenTitle}
            icon={inCollectionView ? "" : subScreenLogo}
            arrLabels={arrLabels}
            currentLabel={currentLabel}
            navigation={this.props.navigation}
            changeLabel={changeLabel}
            aboutPage={false}
          />
*/}


          {/*
            loadingDesignData ?
              <Spinner />
              :
              inCollectionView ?
                (designsData?.collections?.length ?? 0) > 0 ?
                  <DesignsCollectionsGroupsList
                    collection={designsData.collections}
                    onClick={(id: any) =>
                      this.props.navigation.navigate("CollectionViewByDesigner", {
                        collectionId: id,
                        labelId: currentLabel,
                      })}
                  />
                  :
                  <NoData />
                :
                designsData?.[activeCategory]?.length ?
                  <DesignCardsResponsiveContainer
                    designs={(designsData?.[activeCategory] ?? []) as Design[]}
                    onClick={(item) => {
                      this.props.navigation.navigate("DesignerDesignView", {
                        id: item?.id,
                      });
                    }}
                    onTrend={() => { }}
                    onEdit={(item) => {
                      this.props.navigation.navigate("DesignerDesignEdit", {
                        designId: item?.id,
                      });
                    }}
                  />
                  :
                  <NoData />
</div>
          */}
      </div>
    )
  }
}






///////////////////

function Spinner() {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: '40px 0',
        minHeight: '30vh',
      }}
    >
      <CircularProgress />
    </div>
  );
}

function NoData() {

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>No Data</h2>
    </div>
  );
}

const styles: any = {

  headerFloatingBtn: {
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
  },
}

// Customizable Area Start
export default withStyles(styles)(DesignerCollectionView);
// Customizable Area End
