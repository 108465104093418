import React, { useEffect, useMemo, useState } from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
// import StarIcon from '@material-ui/icons/Star';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Tooltip from "@material-ui/core/Tooltip";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ChatIcon from "@material-ui/icons/Chat";
import SearchIcon from "@material-ui/icons/Search";
import { UserBackground, designs, aboutIcon } from "./assets";
import {
  col,
  wishlist,
  promote,
  inspireHeader,
  starHeader,
  colBlack,
  promoteBlack,
  designsBlack,
  likes,
  star,
} from "../../landingpage/src/assets";
import StarIcon from "@material-ui/icons/Star";
import "./style.css";
import clsx from 'clsx';
// Customizable Area End
import { getCountry } from "react-native-localize";
import TopHeaderWeb from "./TopHeader.web";
import TopHeaderUserWeb from "../../landingpage/src/TopHeaderUser.web";
import UserHeaderActionWeb from "./UserHeaderAction.web";
import ChangeCoverWeb from "./ChangeCover.web";
import LabelListing, { LabelListingProps } from "./LabelListing.web";
import { Label } from "../../../components/src/api-types";

const Header = (props: any) => {
  const { classes, profileData } = props;
  const [loading, setLoading] = useState(true);
  const [currentBg, setCurrentBg] = useState("");

  const location = window.location.href.split("/");
  const currentScreen = location[location.length - 1];

  const [currentPath, setCurrentPath] = useState(currentScreen);

  let userBg = '';
  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      setLoading(false);
      setCurrentBg(`url(${profileData.cover})`);
    }
  }, [profileData]);

  let userProfileImg = profileData.profile_url;
  const [currentRole, setCurrentRole] = useState("Myself");
  const encodeImageFileAsURL = (element: any) => {
    return new Promise((resolve, reject) => {
      var file = element.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      reader.readAsDataURL(file);
    });
  };

  const customNavigation = (path: string) => {
    props.navigation.navigate(path);
  };



  function iconClickHandler(screenName: string) {
    return function clickHandler() {
      setCurrentPath(screenName);

      if (
        currentScreen === "DesignerHome" ||
        props.aboutPage ||
        currentScreen === "Catalogue"
      ) {
        if (localStorage.getItem("currentUserRole") === "User") {
          props.navigation.navigate("DesignerProfileByUser", {
            id: props.navigation.getParam("id", 0),
            screenState: screenName,
          });
        } else {
          props.navigation.navigate("DesignerCollectionView", {
            screenState: screenName,
          });
        }
      } else {
        props.setWhichScreen(screenName);
      }
    }
  }


  const designerLinks = useMemo(() => generateDesignerLinks(profileData), [profileData]);

  let list: LabelListingProps['list'] = [];
  try {
    const labelsList = props.arrLabels?.length ? (props?.arrLabels as Label[])?.map((item) => ({
      id: Number(item.id),
      label: item.attributes.name,
      active: item.id == props.currentLabel,
      onClick: (id: number) => {
        localStorage.setItem(
          "changeFilterLabel",
          String(id)
        );
        if (props.aboutPage) {
          localStorage.setItem(
            "changeFilterLabel",
            String(id)
          );
          if (localStorage.getItem("currentUserRole") === "User") {
            props.navigation.navigate("DesignerProfileByUser", {
              id: props.navigation.getParam("id", 0),
              screenState: "CollectionView",
            });
          } else {
            props.navigation.navigate("DesignerHome");
          }
        } else {
          if (localStorage.getItem('currentUserRole') === 'User') {
            localStorage.setItem(
              "changeFilterLabel",
              String(id)
            );
            props.navigation.navigate("DesignerProfileByUser", {
              id: props.navigation.getParam("id", 0),
              screenState: "CollectionView",
            });
          } else {
            props.changeLabel(id);
          }
        }
        return;
      }
    }))
      :
      [];

    const addCollectionItem: LabelListingProps['list'][number] = {
      id: -1,
      label: '+',
      onClick: () => props.navigation.navigate("Catalogue"),
      hide: localStorage.getItem("currentUserRole") === "User",
      pin: 'start'
    }

    const addLabelItem: LabelListingProps['list'][number] = {
      id: labelsList.length + 1,
      label: '+',
      onClick: props.handleOpen,
      hide: localStorage.getItem("currentUserRole") === "User",
      pin: 'end'
    }

    list = [addCollectionItem, ...labelsList, addLabelItem]
  } catch (error) {
    console.error(error)
  }




  if (loading)
    return null;

  return (
    <div>
      {localStorage.getItem("currentUserRole") === "User" ? (
        <TopHeaderUserWeb
          navigation={props.navigation}
          userProfileImg={'selfProfileData' in props ? props.selfProfileData.profile_url : userProfileImg}
          userRole={'User'}
          profileColor={'selfProfileData' in props ? props.selfProfileData.color : profileData.color}
          clubName=""
          groupName=""
        />
      ) : (
        <TopHeaderWeb
          userRole={props.userRole}
          navigation={props.navigation}
          userProfileImg={userProfileImg}
          onPromoteProfile={props.onPromoteProfile}
          profileColor={profileData.color}
          clubName=""
          groupName=""
        />
      )}


      <div className={classes.headerCover} style={{ background: currentBg }}>
        <div className={classes.userBgImg}>

          <div className={classes.userData}>
            <div className={classes.userImg}>
              {userProfileImg === "" ? (
                <div
                  className={classes.userImage}
                  style={{ background: profileData?.color ?? '#e9e9e9' }}
                />
              ) : (
                <img
                  src={userProfileImg}
                  alt="userDefaultImage"
                  className={classes.userImage}
                />
              )}
            </div>

            <div className={classes.userDetail}>
              <h3 className={classes.userName}>{profileData.first_name}</h3>

              <span className={classes.userRole}>
                {profileData.profile_info}
              </span>

              <div className={classes.userFollowers}>
                <span
                  className={classes.subInfo}
                  onClick={() => {
                    props.navigation.navigate("DesignerFollowerPageByUser", {
                      id: props.navigation.getParam("id", 0),
                      screenState: "CollectionView",
                    });
                  }}
                >
                  {profileData.follower_count} followers
                </span>
                <span>{profileData.following_count} following</span>
              </div>

            </div>

          </div>

          <div className={`${classes.userActions} manualUserAction`}>
            {
              designerLinks.map(({ screenName, icon, value, text, }, index) => (
                <div
                  key={screenName + value}
                  className={`${classes.userActionSpan} ${designerLinks.length - 1 == index ? classes.userActionSpanLast : ''} col-30 col-50 col-60`}
                  onClick={iconClickHandler(screenName)}
                >
                  <div className={clsx(classes.iconSpan, {
                    [classes.activeIconSpan]: currentPath == screenName,
                    [classes.activeNumberSpan]: !icon && currentPath == screenName,
                  })}>
                    {
                      icon ?
                        <img className="icon-sm-display" src={icon} />
                        :
                        text ? <b>{text}</b> : null
                    }
                  </div>
                  <div className={clsx(classes.numberSpan, {
                    [classes.activeNumberSpan]: currentPath == screenName
                  })}>
                    {value}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <div className={`${classes.top__container} UserTopHeader`}>
        <div
          style={{
            display: "flex",
            columnGap: "20px",
          }}
        >
          {localStorage.getItem("currentUserRole") !== "User" && (
            <ChangeCoverWeb
              changeCurrentBg={(bgImg: any) => setCurrentBg(bgImg)}
              id={0}
              type="designer"
            />
            // <>
            //   <label
            //     htmlFor="upload__bg__image"
            //     className={classes.upload__bg__image}
            //   >
            //     <span style={{ marginRight: "5px" }}> Change Cover </span>{" "}
            //     <CameraAltIcon />
            //   </label>
            //   <input
            //     style={{
            //       display: "none",
            //     }}
            //     type="file"
            //     id="upload__bg__image"
            //     onChange={(e) => {
            //       e.preventDefault();
            //       encodeImageFileAsURL(e.target).then((res: any) => {
            //         userBg = `url(${res})`;
            //         setCurrentBg(userBg);
            //         if ("changeCover" in props) {
            //           props.changeCover(res);
            //         }
            //       });
            //     }}
            //   />
            // </>
          )}
          <div
            // className="upload-bg-img"
            className={classes.upload__bg__image}
            style={{
              // left: "200px",
              // padding: "15px",
              backgroundColor: props.aboutPage ? "black" : "white",
              color: props.aboutPage ? "white" : "black",
            }}
            onClick={() => {
              const navId =
                props.navigation.getParam("id", 0) === 0
                  ? localStorage.getItem("cuid")
                  : props.navigation.getParam("id", 0);
              props.navigation.navigate("DesignerAboutPage", {
                id: navId,
              });
            }}
          >
            <img
              className={`about-icon ${props.aboutPage ? "black" : "white"}`}
              src={aboutIcon}
              alt=""
            />
            <span style={{ marginRight: "5px" }}> About </span>
          </div>
        </div>

        <LabelListing list={list} />
      
        {localStorage.getItem("currentUserRole") === "User" && (
          <UserHeaderActionWeb
            following={profileData.followed}
            subscribed={profileData.subscribed}
            followerCount={profileData.follower_count}
            accountId={'id' in profileData ? profileData.id : props.navigation.getParam("id", 0)}
            followAction=''
            unFollowAction=''
            unSubscribeAction=''
            subscribeAction=''
          />
        )}
      </div>
    </div>
  )
};


function generateDesignerLinks(profileData: any) {

  return [
    {
      screenName: "CollectionView",
      icon: col,
      value: profileData.totel_collections,
    },
    {
      screenName: "null",
      icon: designs,
      value: profileData.totel_designs,
    },
    {
      screenName: "LIKED",
      icon: wishlist,
      value: profileData.totel_like_designs,
    },
    {
      screenName: "PROMOTED",
      icon: promote,
      value: profileData.totel_promoted_designs,
    },
    {
      screenName: "INSPIRED",
      icon: inspireHeader,
      value: profileData.totel_inspired_designs,
    },
    {
      screenName: "RATED",
      icon: starHeader,
      value: profileData.totel_rated_designs,
    },
    {
      screenName: "FREE",
      text: "FD:",
      value: profileData.totel_free_designs,
    },
    {
      screenName: "PAID",
      text: "PD:",
      value: profileData.totel_paid_designs,
    },
  ]
}


const useStyles: any = {
  navbar: {
    height: "70px",
    background: "black",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    verticalAlign: "middle",
  },
  logo: {
    height: "50px",
    width: "150px",
    "@media (max-width: 767px)": {
      width: "120px",
      height: "auto",
    },
  },
  rightBar: {
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: "0.06",
  },
  navIcon: {
    width: "25px",
    height: "25px",
  },
  headerCover: {
    width: "100%",
    height: "360px",
    justifyContent: "center",
    backgroundSize: 'cover !important'
  },
  userBgImg: {
    backgroundImage: `url(${UserBackground})`,
    padding: "50px 0",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "@media (max-width: 767px)": {
      gridTemplateColumns: "repeat( auto-fit, minmax(80px, 1fr) )",
    },
  },
  userData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  userImage: {
    height: "100px",
    width: "100px",
    borderRadius: "40%",
    objectFit: 'cover',
  },
  userDetail: {
    margin: "0px 40px",
    justifyContent: "top",
  },
  userName: {
    margin: "10px 0",
    fontSize: '1.8rem',
    fontWeight: '700',
  },
  userRole: {
    margin: "10px 0",
    fontSize: '1rem',
    fontWeight: '600',
  },
  userFollowers: {
    margin: "10px 0",
    fontWeight: '500',
    '&>*':{
      whiteSpace: 'nowrap'
    }
  },
  subInfo: {
    marginRight: "10px",
  },
  userActions: {
    textAlign: "center",
    marginTop: "30px",
    display: "inline-flex",
    flexFlow: "row wrap",
    boxSizing: "border-box",
    width: "100%",
    gridTemplateColumns: "auto auto auto auto auto auto auto auto",
    justifyContent: "center",
  },
  userActionSpan: {
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    borderRight: "1px solid black",
    cursor: "pointer",
    color: "#C0C0C0",
    marginTop: "8px",
  },
  userActionSpanLast: {
    borderRight: "none",
  },
  iconSpan: {
    marginRight: "10px",
    display: "flex",
    "& img": {
      filter:
        "invert(86%) sepia(0%) saturate(494%) hue-rotate(153deg) brightness(95%) contrast(81%)",
      opacity: 0.5,
    },
  },
  activeIconSpan: {
    "& img": {
      filter: "brightness(0)",
      opacity: 1,
    },

  },
  numberSpan: {

  },
  activeNumberSpan: {
    color: 'black',
    fontWeight: '600'
  },
  userIcon: {
    height: "50px",
    width: "50px",
    borderRadius: "40%",
  },
  top__container: {
    position: "relative",
    display: "grid",
    width: "100%",
    justifyContent: "space-around",
    flexFlow: "row wrap",
    gridTemplateColumns: "0.55fr 0.9fr 0.55fr",
    gridGap: '8px',
    padding: "0px 20px",
    alignItems: "center",
    alignContent: "center",
    marginTop: "-30px",
    marginBottom: "40px",
    "@media (max-width: 767px)": {
      display: "inline-flex",
      justifyContent: "center",
      
    },
  },
  upload__bg__image: {
    // top: "0",
    // left: "30px",
    display: "flex",
    // position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    background: "white",
    fontWeight: "700",
    cursor: "pointer",
    border: "none",
    boxShadow: "1px 1px 10px rgba(156, 156, 156, 20%)",
    borderRadius: "2px",
  },
  label__listing: {
    display: "flex",
    justifyContent: "center",

    "& span": {
      display: "inline-block",
      padding: "10px 20px",
      border: "1px solid black",
      borderCollapse: "collapsed",
      cursor: "pointer",
      backgroundColor: "white",
    },
  },
  floatingRightBtn: {
    padding: "5px 15px",
    marginRight: "20px",
    border: "1px solid lightgrey",
    borderRadius: "10px",
    textAlign: "center",
    cursor: "pointer",
  },
};

export default withStyles(useStyles)(Header);
