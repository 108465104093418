import React from "react";
// Customizable Area Start
import { withStyles, } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
// Customizable Area End
import ClubHomePageController, { Props } from "./ClubHomePageController.web";
import CollectionListing from "../../catalogue/src/CollectionListing.web";
import SubHeaderWeb from "../../landingpage/src/SubHeader.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import DesignerPromotePopUpWeb from "../../landingpage/src/DesignerPromotePopUp.web";
import DesignCardsResponsiveContainer from "../../landingpage/src/DesignCardsContainer.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import { DezinerCollectionList } from "../../../components/src/DezinerCollectionList.web";



class ClubHomePage extends ClubHomePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { clubData } = this.state;

    // this property are passed down from the shared ClubLayout
    const {
      activeCategory, arrLabels, changeLabel, currentLabel,
      loadingDesignData,
      designsData
    } = this.props.layoutProps || {};


    return (
      <>


        <div className="main-container">

          <VerticalSpacer />

          <SubHeaderWeb
            title=""
            icon=""
            arrLabels={arrLabels}
            currentLabel={currentLabel}
            navigation={this.props.navigation}
            changeLabel={changeLabel}
            aboutPage={false}
          />


          <div style={{ margin: '30px 0' }}>

            <Typography style={{ fontSize: '1.4rem', fontWeight: 'bold', marginBottom: '20px' }}>
              {activeCategory.toUpperCase()}
            </Typography>

            {
              loadingDesignData ?
                <Grid container justifyContent="center">
                  <CircularProgress size={30} color="primary" />
                </Grid>
                :
                activeCategory == 'collections' ?

                  designsData.collections?.length ?
                    designsData.collections?.map((item) => (
                      <DezinerCollectionList
                        key={item.id}
                        onPin={() => { this.pinCollection(item) }}
                        onCreate={() => { }}
                        onPromote={() => { }}
                        collection={item}
                      />
                    ))
                    :
                    <Typography>
                      {'No Data'}
                    </Typography>
                  :
                  designsData[activeCategory]?.length ?
                    <DesignCardsResponsiveContainer
                      designs={designsData?.[activeCategory]??[]}
                      onClick={(item) => {
                        this.props.navigation.navigate("DesignerDesignView", {
                          id: item?.id,
                        });
                      }}
                      onTrend={() => { }}
                      onEdit={(item) => {
                        this.props.navigation.navigate("DesignerDesignEdit", {
                          designId: item?.id
                        });
                      }}
                    />
                    :
                    <Typography>
                      {'No Data'}
                    </Typography>
            }

          </div>


        </div>


        {this.state.modalOpen && (
          <DesignerPromotePopUpWeb
            designerId={this.state.designerId}
            currentImage={clubData?.image_url ?? ''}
            modalOpen={this.state.modalOpen}
            handleModalClose={this.handleModalClose}
            navigation={this.props.navigation}
            promoteType={this.state.promoteType}
            id={""}
            designId={0}
            promoteCollectionId={0}
          />
        )}
      </>
    );
  }
}

// Customizable Area Start

const useStyles: any = {

};


export default withStyles(useStyles)(ClubHomePage);
// Customizable Area End
