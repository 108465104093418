import React, { useEffect, useState, useRef } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DesignListingCollection from "./DesignerListingCollection.web";
import { withStyles } from "@material-ui/core/styles";

import { pin, promote } from "../../landingpage/src/assets";
import { Popover as MaterialPopover, Typography, Menu, MenuItem, ListItemText as MaterialListItemText, ListItemIcon as MaterialListItemIcon, Divider } from '@material-ui/core';
import './CollectionListing.web.css';
import { Collection, CollectionHome } from "../../../components/src/api-types";

const Popover = withStyles({
  paper: {
    display: 'none !important'
  }
})(MaterialPopover);


const ListItemIcon = withStyles({
  root: {
    '& img': {
      opacity: 0.5,
    }
  }
})(MaterialListItemIcon);


const ListItemText = withStyles({
  root: {
    '&>span': {
      fontSize: '0.8rem',
      color: '#8a8a8a',
    }
  }
})(MaterialListItemText);

const StyledMenuItem = withStyles({
  root: {
    padding: '4px 35px 4px 15px',
    '&:focus': {
      backgroundColor: '#f1f1f1',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      },
    },
  },
})(MenuItem);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));







const CollectionListing = (props: {
    collection:CollectionHome['attributes'],
    createPost?: any,
    navigation?: any,
    promoteCollection?: any,
    pinCollection?: any,
    allowAddition?: boolean,
}) => {

  const {
    collection,
    createPost,
    navigation,
    promoteCollection,
    allowAddition,
    pinCollection,
  } = props;

  const [popoverElement, setPopoverElement] = React.useState<HTMLButtonElement | null>(null);

  function handlePopoverClick(event: React.MouseEvent<HTMLButtonElement>) {
    setPopoverElement(event.currentTarget);
  };

  function handlePopoverClose() {
    setPopoverElement(null);
  };

  const isPopoverOpen = Boolean(popoverElement);
  const popoverId = isPopoverOpen ? 'collection-popover' : undefined;


  return (
    <div style={{}}>
      {
        collection ?
          <div>

            <div className="collection-title-container">
              <Typography variant="body1">
                <span>
                  <b>{collection?.name}</b>
                </span>
                <sub>
                  {`(${collection?.design_count > 0 ? 1 : 0}/${collection?.design_count})`}
                </sub>
              </Typography>

              <span
                onClick={handlePopoverClick as any}
                className="collection-title-icon-container"
              >
                <MoreHorizIcon
                  fontSize="small"
                />
              </span>
            </div>

            <Popover
              id={popoverId}
              open={isPopoverOpen}
              anchorEl={popoverElement}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <StyledMenu
                id={popoverId}
                anchorEl={popoverElement}
                keepMounted
                open={isPopoverOpen}
                onClose={handlePopoverClose}
              >
                <StyledMenuItem
                  onClick={() => {
                    const collectionId = collection.id;
                    promoteCollection('', collectionId)
                  }}>
                  <ListItemIcon style={{ minWidth: '32px' }}>
                    <img
                      className="icon-img"
                      alt="Promote collection"
                      src={promote} />
                  </ListItemIcon>
                  <ListItemText primary="Promote Collection" />
                </StyledMenuItem>
                <Divider style={{ margin: '4px 0' }} />
                <StyledMenuItem onClick={() => {
                      if ('pinCollection' in props) {
                        props.pinCollection(collection.id, collection.pined);
                        collection.pined = !collection.pined;
                      }
                    }}>
                  <ListItemIcon
                    style={{ minWidth: '32px' }}
                  >
                    <img
                      className="icon-img"
                      alt="unpin"
                      src={pin} />
                  </ListItemIcon>
                  <ListItemText primary={collection.pined ? 'Unpin' : 'Pin'} />
                </StyledMenuItem>
              </StyledMenu>


            </Popover>

          </div>
          :
          null
      }
      <DesignListingCollection
        designs={collection?.designs?.data ?? []}
        collectionId={collection?.id ?? 0}
        createPost={createPost}
        navigation={navigation}
        allowAddition={allowAddition}
      />
    </div>
  );
};


export default CollectionListing;
