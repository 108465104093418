import React  from "react";
import LandingPageContent from "../../../components/src/LandingPageContent.web";
// Customizable Area Start
import LandingPageController, {
  Props,
} from "./LandingPageController.web";
// Customizable Area End


class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <LandingPageContent />
    );
  }
}



// Customizable Area End
export default LandingPageWeb;
// Customizable Area End
