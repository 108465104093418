import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import MaterialPopover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import MaterialListItem from '@material-ui/core/ListItem';
import MaterialListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DezinerButton from './Button.web';

const Popover = withStyles({
  paper: {
    borderRadius: '10px',
  },
})(MaterialPopover);

const ListItem = withStyles({
  root: {
    padding: '1px 6px',
  }
})(MaterialListItem);

const ListItemText = withStyles({
  root: {
    padding: '4px 2px',
    '&>*': {
      fontSize: '0.9rem',
      color: '#767779',
      '&:hover': {
        color: '#929395'
      }
    }
  }
})(MaterialListItemText);




export default function DezinerDropdown({
  options,
  onClick,
  selectedOption,
  title,
  position,
}: {
  title: string,
  options: { key: string, title: string }[],
  selectedOption: string,
  onClick: (option: string) => void,
  position?: 'left' | 'right',
}) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'sort' : undefined;


  return (
    <>
      <DezinerButton
        aria-describedby={id}
        aria-label={title}
        style={{
          fontWeight: 700,
          fontSize: '0.9rem',
          color: '#767779',
          padding: '6px 12px',
        }}
        variant="outlined"
        color="default"
        onClick={handleClick}>
        {
          selectedOption ?
            options?.find(item => item.key == selectedOption)?.title
            :
            title
        }
        {
          !open ?
            <ExpandMore style={{ color: "#767779" }} />
            :
            <ExpandLess style={{ color: "#767779" }} />
        }
      </DezinerButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position || 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: position || 'right',
        }}

      >
        <div
          style={{
            padding: '10px 0px',
          }}
        >
          <List component="div" disablePadding>
            {
              options.map((item, index) => (
                <div key={item.key}>
                  <ListItem button onClick={() => onClick(item.key)}>
                    <ListItemText>
                      <Typography
                        style={{
                          fontSize: '0.9rem',
                          padding: '0 10px',
                          fontWeight: item.key == selectedOption ? 'bold' : 'unset'
                        }}>
                        {item.title}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {
                    index < 3 ?
                      <Divider />
                      :
                      null
                  }
                </div>
              ))
            }

          </List>

        </div>
      </Popover>
    </>
  )
}
