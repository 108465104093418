import { put, select } from "redux-saga/effects";
import { fetchDesigns } from "../designs/designs.action";
import { takeHttpResponse } from "../effects/effect-utils";
import reduxDezinerMiddlewareWeb from "../redux-deziner-middleware.web";
import { RootState } from "../store";
import { PinCollectionAction, UnpinCollectionAction } from "./pin.action";

const configJson = require('../../config.js');

export function* pinCollectionSaga({ collectionId }: PinCollectionAction) {
  try {
    const body = {
      data: {
        attributes: {
          collection_id: collectionId
        }
      }
    }

    const sentMessageId = reduxDezinerMiddlewareWeb.sendHttpRequest({
      header: {},
      url: configJson.pinCollectionAPI,
      httpMethod: configJson.PostMethod,
      httpBody: body,
    });


    const response = yield takeHttpResponse(sentMessageId);

    if (response) {

      const state: RootState = yield select();

      yield put(fetchDesigns({
        category: state.designs.activeCategory,
        designsType: 'designers',
        designerId: state.profile.profile.id,
        labelId: String(state.labels.currentDesignerLabel),
      }));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* unpinCollectionSaga({ collectionId }: UnpinCollectionAction) {
  try {

    const body = {
      data: {
        attributes: {
          collection_id: collectionId
        }
      }
    }

    const sentMessageId = reduxDezinerMiddlewareWeb.sendHttpRequest({
      header: {},
      url: configJson.unpinCollectionAPI,
      httpMethod: configJson.PostMethod,
      httpBody: body,
    });


    const response = yield takeHttpResponse(sentMessageId);

    if (response) {

      const state: RootState = yield select();

      yield put(fetchDesigns({
        category: state.designs.activeCategory,
        designsType: 'designers',
        designerId: state.profile.profile.id,
        labelId: String(state.labels.currentDesignerLabel),
      }));
    }
  } catch (error) {
    console.error(error);
  }
}
