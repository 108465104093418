import React from 'react';
import TextField from './TextField.web';
import { useField } from 'formik';
import DezinerAlert from './Alert.web';




export default function FormikTextInput(formikTextInputProps: React.ComponentProps<typeof TextField> & { hideErrors?: boolean }) {

  const { hideErrors, ...props } = formikTextInputProps;

  const inputName = props?.name ?? '';

  const [inputProps, meta] = useField(inputName);


  if (!inputName)
    return null;

  return (
    <>
      <TextField
        {...props}
        {...inputProps}
      />

      {
        meta?.error && !hideErrors ?
          <DezinerAlert>
            {meta.error}
          </DezinerAlert>
          :
          null
      }
    </>
  );
}


