import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { DesignAttributes } from '../../../components/src/api-types';




export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  categories: any[],
  watermarks: any[],
  publishLoading: boolean,
  designData: Partial<DesignAttributes>,
  loading: boolean,
  sourceFiles: any[],
}

interface SS { }

export default class DesignerDesignEditPageController extends BlockComponent<
  Props,
  S,
  SS
  >  {

  fetchWatermarksMsgId: string;
  fetchCategoriesMsgId: string;
  fetchDesignMsgId: string;
  updateDesignMsgId: string;
  fetchSourceFilesMsgId: string;


  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.updateDesign = this.updateDesign.bind(this);
    this.updateDesignHandler = this.updateDesignHandler.bind(this);

    this.fetchDesign = this.fetchDesign.bind(this);
    this.fetchDesignHandler = this.fetchDesignHandler.bind(this);

    this.fetchWatermarksHandler = this.fetchWatermarksHandler.bind(this);
    this.fetchWatermarks = this.fetchWatermarks.bind(this);

    this.fetchCategories = this.fetchCategories.bind(this);
    this.fetchCategoriesHandler = this.fetchCategoriesHandler.bind(this);

    this.fetchSourceFiles = this.fetchSourceFiles.bind(this);
    this.fetchSourceFilesHandler = this.fetchSourceFilesHandler.bind(this);

    this.state = {
      categories: [],
      watermarks: [],
      publishLoading: false,
      designData: {},
      loading: false,
      sourceFiles: [],
    }

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.fetchCategories();
    this.fetchWatermarks();
    this.fetchDesign();
    this.fetchSourceFiles();
  }

  fetchSourceFilesHandler(response: any, errors: any) {
    if (errors) {

    } else {
      console.log({ response });
      this.setState({ sourceFiles: response.data });
    }
  }

  updateDesignHandler(response: any, errors: any) {

    this.setState({ publishLoading: false });

    if (errors) {
      this.parseApiCatchErrorResponse(errors);
    } else {

      this.props.navigation.navigate("DesignerHome",{ designerId: this.state.designData?.designer?.data?.attributes?.id });
      /*
            const type = this.props.navigation.getParam("type");
            if (type === 'designer') {
              this.props.navigation.navigate("Catalogue");
            } else if (type === 'club') {
              this.props.navigation.navigate("ClubCreateCollection", {
                id: this.props.navigation.getParam("clubId", "")
              });
            } else if (type === 'group') {
              this.props.navigation.navigate("GroupCreateCollection", {
                id: this.props.navigation.getParam("clubId", "")
              });
            }
      */
    }
  }

  fetchDesignHandler(response: any, errors: any) {

    this.setState({ loading: false });

    if (errors) {

    } else {

      this.setState({
        designData: response.design.data.attributes,
      })
    }
  }

  fetchCategoriesHandler(responseJson: any, errors: any) {
    if (errors) {

      this.parseApiCatchErrorResponse(errors);
    } else {

      const categories = responseJson.data.map((category: any) => {
        const subCategories = category.attributes.sub_categories.data.map((subCategory: any) => {
          return {
            value: subCategory.attributes.id,
            label: subCategory.attributes.name,
          };
        })
        return {
          label: category.attributes.name,
          options: subCategories,
        }
      })
      this.setState({ categories });
    }
  }

  fetchWatermarksHandler(responseJson: any, errors: any) {

    if (errors) {
      this.parseApiCatchErrorResponse(errors);
    } else {
      this.setState({ watermarks: responseJson.data });
    }
  }


  async receive(from: string, message: Message) {


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let msgId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      //let errors = responseJson.error || responseJson.errors;

      let errors = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (msgId) {
        case this.updateDesignMsgId: {
          this.updateDesignHandler(responseJson, errors);
          break;
        }
        case this.fetchDesignMsgId: {
          this.fetchDesignHandler(responseJson, errors);
          break;
        }
        case this.fetchCategoriesMsgId: {
          this.fetchCategoriesHandler(responseJson, errors);
          break;
        }
        case this.fetchWatermarksMsgId: {
          this.fetchWatermarksHandler(responseJson, errors);
          break;
        }
        case this.fetchSourceFilesMsgId: {
          this.fetchSourceFilesHandler(responseJson, errors);
          break;
        }
      }
    }

  }


  updateDesign(payload: any) {

    const designId = this.props.navigation.getParam("designId", "");


    const data = payload;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDesignMsgId = requestMessage.messageId;

    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.publishDesignApi}/${designId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  fetchWatermarks() {
    const token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchWatermarksMsgId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.watermarksApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    console.log("REQUEST: ", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  fetchCategories() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.getCategoriesApi,
    });

    this.fetchCategoriesMsgId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchDesign(): boolean {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {};
    const designId = this.props.navigation.getParam("designId", "");

    let url = `${configJSON.designApi}/${designId}`;

    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });

    this.fetchDesignMsgId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  }

  fetchSourceFiles() {

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {};
    const designId = this.props.navigation.getParam("designId", "");

    let url = configJSON.fetchSourceFilesAPI.replace(':id', designId);

    const sourceFilesCall = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.fetchSourceFilesMethod,
      url: url,
    });

    this.fetchSourceFilesMsgId = sourceFilesCall.messageId;
    runEngine.sendMessage(sourceFilesCall.id, sourceFilesCall);

  }
}
