import React from "react";
// Customizable Area Start
import CreateGroupForm from "./CreateGroupForm.web";
import LogoTitleHeader from "./LogoTitleHeader.web";
// Customizable Area End

import CreateGroupController, { Props } from "./CreateGroupController.web";

class CreateGroup extends CreateGroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {

    const listOfCategories = this.state.categories
      ?.map((item: any) => (
        item.options.map((option: any) => ({ ...option, parentLabel: item.label }))
      ))
      ?.flat();


    return (
      <>
        <LogoTitleHeader
          title="Create your group"
          onLogoClick={() => {
            this.props.navigation.navigate("DesignerGroupPageView");
          }}
        />

        <CreateGroupForm
          categories={listOfCategories}
          members={this.state.members}
          onSubmit={(values) => {
            this.createClub({
              name: values.groupName,
              description: values.groupDescription,
              image: values.profileImage,
              cover: values.coverImage,
              account_ids: values.groupMembers.map((x: any) => ({ account_id: x.value })),
              groups_sub_categories_attributes: values.categories.map((x: any) => ({ sub_category_id: x.value })),
            })
          }}
          loading={this.state.submitLoading}
        />
      </>
    );


  }
}

// Customizable Area Start
// Customizable Area End
export default CreateGroup;
// Customizable Area End
