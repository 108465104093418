import React from 'react';
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useRef } from "react";
import DezinerButton from "./Button.web";
import { encodeFile } from "./utils";
import { makeStyles } from '@material-ui/core/styles';
import { useUpdateCoverImage } from './hooks';


const useStyles = makeStyles({
  headerFloatingBtn: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
    '&:hover': {
      background: 'black',
      color: 'white',
    },
  },
});



export default function DezinerChangeCoverButton() {

  const inputFileRef = useRef<any>();
  const classes = useStyles();


  const { mutate: changeCover } = useUpdateCoverImage();


  return (
    <div>
      <input
        ref={inputFileRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={async (event) => {

          let file = event?.target?.files?.[0];
          if (file)
            changeCover({ image: await encodeFile(file) });

        }} />

      <DezinerButton
        variant="contained"
        onClick={() => {
          if (inputFileRef && inputFileRef.current) {
            inputFileRef.current.click();
          }
        }}
        startIcon={<CameraAltIcon />}
        className={classes.headerFloatingBtn}
      >
        {'Change Cover'}
      </DezinerButton>
    </div>
  );
}
