import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';


const useHeaderControlsStyles = makeStyles({
  headerControls: {
    padding: '0 30px',
    position: 'relative',
    top: '-35px',
    display: 'grid',
    gridTemplateColumns: '25% 50% 25%',
    alignItems: 'center',
    marginBottom: '-35px',
    ['@media screen and (max-width: 768px)']: {
      gridTemplateColumns: '100%',
    }
  },
  oneSectionHeadersControls: {
    gridTemplateColumns: '100%',
  },
  sideSection: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
});

export default function HeaderControls({ children }: { children: any }) {

  const classes = useHeaderControlsStyles();

  return (
    <div className={clsx(classes.headerControls, {
      [classes.oneSectionHeadersControls]: React.Children.count(children) == 1
    })}>
      {
        React.Children.map(children, (child) =>
          React.cloneElement(child, {}),
        )
      }
    </div>
  )
}

HeaderControls.SideSection = function SideSection({ children, flexStart, flexEnd }: { children: ReactNode, flexStart?: boolean, flexEnd?: boolean }) {

  const classes = useHeaderControlsStyles();

  return (
    <div className={clsx(classes.sideSection, {
      [classes.flexStart]: flexStart,
      [classes.flexEnd]: flexEnd,
    })}>
      {children}
    </div>
  );
}

HeaderControls.MiddleSection = function MiddleSection({ children }: any) {

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Grid>
  );
}

