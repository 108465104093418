import React from 'react';
import {
  trending_up,
  likeGray,
  starGray,
  inspireGray,
  editSolidDark,
} from "./assets";
import { makeStyles } from '@material-ui/core/styles';
import { plusIcon } from './assets';
import Typography from '@material-ui/core/Typography';
import DezinerMedia from '../../../components/src/DezinerImage.web';
import { Design, DesignAttributes } from '../../../components/src/api-types';
import { useIsMyProfile } from '../../../components/src/hooks';



const useStyles = makeStyles({
  designCard: {
    borderRadius: "5%",
    cursor: "pointer",
    transition: "all 0.5 ease-in-out",
  },
  designCardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    borderRadius: "5%",
    position: "relative",
    "& video": {
      objectFit: "cover",
      maxWidth: "100%",
      height: "182px",
      borderRadius: "5%"
    },
    "& img": {
      objectFit: "cover",
      maxWidth: "100%",
      height: "182px",
      borderRadius: "5%"
    },
    "&:hover>div": { opacity: 1 },
  },
  designCardOverlay: {
    borderRadius: "5%",
    position: "absolute",
    zIndex: 2,
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "#0000005e",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    opacity: 0,
    "& button": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      background: "white",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": { background: "#ddd" },
      "&  img": {
        maxWidth: "20px",
        width: "20px",
        height: "20px"
      },
    },
  },
  designCardDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    "& b": { textTransform: "capitalize" },
  },
  designCardIcons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "&>div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 5px",
      "&>span": { padding: "0 4px", color: "#929292" },
    },
    "& img": { maxWidth: "1rem" }
  },
  placeholderDiv: {
    background: "#d8d8d8",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column'
  },
  addIcon: {
    width: '40px !important',
    maxWidth: '40px !important',
    objectFit: 'contain',
    height: 'auto !important'
  },
  addText: {
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
});


export function AddDesignCardPlaceholder({
  onClick,
  height,
}: {
  onClick: () => void,
  height?: number
}) {


  const classes = useStyles();

  return (
    <div
      className={classes.designCard}
      onClick={onClick}
    >
      <div className={classes.designCardContent}>
        <div className={classes.placeholderDiv} style={{ height: `${height || 150}px` }}>

          <img src={plusIcon} alt="add" className={classes.addIcon} />
          <Typography className={classes.addText}>
            {'Add Designs'}
          </Typography>

        </div>
      </div>
      <div className={classes.designCardDetails}>
      </div>
    </div>
  );
}


export interface DesignCardProps {
  item: Design,
  onClick: any,
  onTrendingClick: (item: Design) => void,
  onEditClick: (item: Design) => void,
  imgHeight: number,
}

export default function DesignCard(props: DesignCardProps) {

  const designOwnerId = props?.item?.attributes?.designer?.data?.attributes?.id ?? 0;
  const isMyProfile = useIsMyProfile({ id: designOwnerId });


  return (
    <DesignCardUI
      {...props}
      hidePromote={!isMyProfile}
      hideEdit={!isMyProfile}
    />
  );
}

export function DesignCardUI(props: DesignCardProps & {
  hideEdit?: boolean,
  hidePromote?: boolean,
}) {

  const {
    item,
    onClick,
    onTrendingClick,
    onEditClick,
    imgHeight,
    hideEdit,
    hidePromote,
  } = props;

  const classes = useStyles();


  return (
    <div
      className={classes.designCard}
      onClick={onClick}
    >
      <div className={classes.designCardContent}>

        <DezinerMedia
          mediaType={item?.attributes?.main_image_type?.includes('video') ? 'video' : 'image'}
          style={{ height: `${imgHeight}px` }}
          src={item?.attributes?.image_url}
          watermarkURL={item?.attributes?.watermark_url}
          watermarkWidth={parseFloat(item?.attributes?.width ?? 100)}
          watermarkHeight={parseFloat(item?.attributes?.height ?? 100)}
        />

        <div className={classes.designCardOverlay}>
          {
            hidePromote ? <span /> :
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onTrendingClick(item)
                }}
              >
                <img src={trending_up} alt="promote" />
              </button>
          }
          {
            hideEdit ? <span /> :
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onEditClick(item);
                }}
              >
                <img src={editSolidDark} alt="edit" />
              </button>
          }
        </div>
      </div>
      <div className={classes.designCardDetails}>
        <div>
          <b>{item?.attributes?.title}</b>
        </div>
        <div className={classes.designCardIcons}>
          <div>
            <img alt="inspiring" src={inspireGray} />
            <span> {item?.attributes?.inspired_count} </span>
          </div>
          <div>
            <img alt="rating" src={starGray} />
            <span> {item?.attributes?.rating_count} </span>
          </div>
          <div>
            <img alt="liked" src={likeGray} />
            <span> {item?.attributes?.like_count} </span>
          </div>
        </div>
      </div>
    </div>
  );
}
