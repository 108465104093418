import reduxDezinerMiddlewareWeb from "../redux-deziner-middleware.web";
import { put } from 'redux-saga/effects';
import { FetchLabelsAction, setLabels } from './labels.action';
import { takeHttpResponse } from "../effects/effect-utils";

const configJson = require('../../config.js');


export function* fetchLabelsSaga(action: FetchLabelsAction) {
  try {

    const sentMessageId = reduxDezinerMiddlewareWeb.sendHttpRequest({
      header: {},
      url: configJson.fetchLabelsAPI,
      httpMethod: configJson.GetMethod,
    });

    const response = yield takeHttpResponse(sentMessageId);

    const { type, ...payload } = action;

    if (response) {
      yield put(setLabels({ labels: response?.data, ...payload }));

    }

  } catch (error) {
    console.error(error);
  } finally {
    console.log('should stop loading state');
  }
}
