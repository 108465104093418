import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { ClubMember, GroupMember } from "../../../components/src/api-types";




export default function DesignerProfileWithOptions({
  member,
  currentRole,
  onDelete,
  addAsAdmin,
  horizontal,
  imageSize,
}: {
  member: ClubMember | GroupMember,
  currentRole: string,
  onDelete: (params: {
    memberId: number,
    memberName: string,
    deleteType: "remove_as_admin" | "remove_member"
  }) => void,
  addAsAdmin: (params: {
    memberId: number,
  }) => void,
  horizontal?: boolean,
  imageSize?: number,
}) {

  /**
   * Menu Rules:
   * 
   * if I'm a pattern designer
   *  for each member I can see
   *    - if it's myselft: I can exit the group/club
   *    - if it's anything else I can do nothing
   * 
   * If I'm an admin/owner
   *   for each member I can see
   *     - if it's a pattern designer: I can (Add him as an admin), (remove from group/club)
   *     - if it's an owner: I can do nothing
   *     - if it's an admin: I can (remove him [that include removing myself])
   */


  const [anchorEl, setAnchorEl] = useState<any>();

  const isMyProfile = member.attributes.account_id.toString() === localStorage.getItem('cuid');

  const iHaveOwnerRole = currentRole === 'Owner';
  const iHaveAdminRole = currentRole === 'Admin';
  const iHavePatterDesignerRole = currentRole === 'Pattern designer';

  // clubs has "user_type" and groups has "role"
  let userType = (member as ClubMember)?.attributes?.user_type || (member as GroupMember)?.attributes?.role;

  const memberHasOwnerRole = userType === 'Owner';
  const memberHasAdminRole = userType === 'Admin';
  //const memberHasPatterDesignerRole = userType === 'Pattern designer';

  const hasMenu = !memberHasOwnerRole && (iHavePatterDesignerRole ? isMyProfile : true);


  let menuOptions: any[] = [];



  if (
    !memberHasOwnerRole &&
    (iHaveAdminRole || iHaveOwnerRole)
  ) {

    if (!memberHasAdminRole) {
      menuOptions = menuOptions.concat({
        title: 'Add as admin',
        onClick: () => {
          addAsAdmin({
            memberId: member.attributes.account_id,
          });
        }
      });
    } else {
      menuOptions = menuOptions.concat({
        title:
          isMyProfile ? 'Leave as admin' : 'Remove as admin',
        onClick: () => {
          onDelete({
            memberId: member.attributes.account_id,
            memberName: member.attributes.first_name,
            deleteType: "remove_as_admin"
          });
        }
      })
    }
  }


  menuOptions = menuOptions.concat({
    title: isMyProfile ? 'Exit' : 'Remove',
    onClick: () => {
      onDelete({
        memberId: member.attributes.account_id,
        memberName: member.attributes.first_name,
        //deleteType: iHaveAdminRole ? "remove_as_admin" : "remove_member"
        deleteType: "remove_member"
      });
    }
  })


  const menu = (
    <>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
        style={{ position: 'absolute', right: 0, top: '-10px' }}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: 40 * 4.5,
            width: "20ch"
          }
        }}
      >
        {
          menuOptions?.map(({ title, onClick }) => (
            <MenuItem
              onClick={onClick}
            >
              {title}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );


  return (
    <Grid
      container
      direction={horizontal ? "row" : "column"}
      alignItems="center"
      style={{
        position: 'relative',
      }}
      wrap={horizontal ? "nowrap" : "wrap"}
    >

      {
        hasMenu ? menu : null
      }

      <img
        src={
          member?.attributes?.profile_url === '' ?
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" :
            member?.attributes?.profile_url
        }
        alt={member?.attributes?.first_name}
        style={{
          width: `${imageSize || 100}px`,
          height: `${imageSize || 100}px`,
          borderRadius: '40%',
          border: `4px solid ${userType == "Pattern designer" ? '#dcdddd' : 'black'}`
        }}
      />

      <Grid container direction="column" alignItems="flex-start" style={{ paddingLeft: horizontal ? '20px' : '0px' }}>
        <Typography
          style={{
            textAlign: "center",
            fontSize: "1.3rem",
            color: "#656565",
            fontWeight: "bold",
            marginTop: "10px",
            whiteSpace: 'nowrap',
          }}>
          {member?.attributes?.first_name}
        </Typography>
        <Typography
          style={{
            color: "#989898", fontSize: "1rem", margin: "0", whiteSpace: 'nowrap',
          }}
        >
          {userType}
        </Typography>
      </Grid>

    </Grid>
  )
}

