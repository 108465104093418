import { DesignerHomeLayoutProps } from "../../../components/src/DesignerHomeLayout.web";
import { BlockComponent } from "../../../framework/src/BlockComponent"
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  layoutProps: DesignerHomeLayoutProps
}
interface S { }
interface SS { }


export default class DesignerCollectionViewController extends BlockComponent<Props, S, SS>{

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {

    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {

  }


}
