import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { dezinerKnotLogo } from '../../landingpage/src/assets';
import DesignerDesignUploadController, {
  Props,
} from "./DesignerDesignUploadController.web";
import UploadDesignForm from "./UploadDesignForm.web";
import { useCategories, useDecodedToken, useNavigate, useUploadDesign, useWatermarks, } from "../../../components/src/hooks";
import { UploadDesignPayload } from '../../../components/src/messages-generator/designs';
import { AppBar, Toolbar, Typography } from '@material-ui/core';


export function DesignUploadHeader({ headerText, onLogoClick, }: { headerText?: string, onLogoClick?: () => void, }) {

  const navigate = useNavigate();

  return (
    <AppBar position="static" color="primary">
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <img
          src={dezinerKnotLogo}
          alt="Deziner Knot"
          className="white-svg"
          style={{
            maxWidth: '160px',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (onLogoClick) onLogoClick();
            else
              navigate(`LandingPage`);
          }}
        />


        <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
          {headerText || 'Publish Your Design'}
        </Typography>

        <div style={{ width: '160px' }} />

      </Toolbar>
    </AppBar>
  );
}


class DesignerDesignUpload extends DesignerDesignUploadController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    const { classes } = this.props as any;

    const labelId = this.props.navigation.getParam("labelId", "");
    const collectionId = this.props.navigation.getParam("collectionId", "");
    const clubId = this.props.navigation.getParam("clubId", "");
    const type = this.props.navigation.getParam("type", "");


    return (
      <div className={classes.root}>

        <DesignUploadHeader headerText={'Publish Your Design'} />


        <UploadDesignFormContainer
          labelId={Number(labelId)}
          collectionId={Number(collectionId)}
          clubId={Number(clubId)}
          type={type}
        />

      </div>
    );
  }
}


function UploadDesignFormContainer({
  labelId,
  collectionId,
  clubId,
  type,
}: {
  labelId: number,
  collectionId: number,
  clubId: number,
  type: string,
}) {

  const { id: designerId } = useDecodedToken();

  const { mutate: uploadDesign, isLoading: loading } = useUploadDesign({
    labelId,
    collectionId,
    designerId,
  });

  const categoriesQuery = useCategories();
  const watermarksQuery = useWatermarks();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const categories = categoriesQuery?.data?.data?.map((category) => {

    const subCategories = category.attributes.sub_categories.data.map((subCategory) => {
      return {
        value: subCategory.attributes.id,
        label: subCategory.attributes.name,
      };
    })

    return {
      label: category.attributes.name,
      options: subCategories,
    }
  }) ?? [];


  return (
    <UploadDesignForm
      categories={categories}
      watermarks={watermarksQuery?.data?.data ?? []}
      onSubmit={(values) => {

        setIsLoading(true);

        const {
          title,
          tags,
          description,
          price,
          privacy,
          media,
          watermarkId,
          isThumbnail,
          descriptionAudio,
          waterMarkWidth,
          waterMarkHeight,
          sourceLink,
          sourceFiles,
          timePeriod,
          priceConditions,
        } = values;


        const sub_design = media.slice(1).map(({ data }) => ({ data }));

        const newTags = tags.map((tag) => {
          return { sub_category_id: tag.value };
        });

        const watermark_id_number = watermarkId;

        let clubOnlyParams = {
          club_id: clubId,
        }

        let groupOnlyParams = {
          group_id: clubId,
        }

        let payload: UploadDesignPayload = {
          data: {
            attributes: {
              design_type: type == 'designer' ? "Designer" : type == 'group' ? 'Group' : 'Club',
              collection_id: collectionId,
              watermark_id: watermark_id_number,
              label_id: labelId,
              title: title,
              designs_sub_categories_attributes: newTags,
              privacy: privacy,
              description: description,
              price: price.toString(),
              width: waterMarkWidth,
              height: waterMarkHeight,
              is_thumbnail: isThumbnail,
              image: {
                data: media[0]?.data,
              },
              sub_designs: sub_design,
              audios: descriptionAudio ? [{
                data: descriptionAudio
              }] : [],
              design_url: sourceLink,
              source_designs_attributes: sourceFiles?.map((item) => ({
                data: item.data,
                type: item.design_type.replace('image/', '').replace('video/', ''),
                name: item.name,
                file_size: item.file_size / 1024,
              })) ?? [],
              time_period_type: timePeriod,
              design_prices_attributes: priceConditions?.map(({ year, month, price }) => ({ year, month, amount: price })) ?? [],
              ...(type == 'club' ? clubOnlyParams : {}),
              ...(type == 'group' ? groupOnlyParams : {}),
            },
          },
        };

        uploadDesign({ payload }, {
          onSuccess: () => {
            navigate(`Catalogue/${designerId}?label_id=${labelId}`);
          },
          onSettled: () => {
            setIsLoading(false);
          }
        });
      }}
      loading={isLoading || loading}
    />

  );

}


const useStyles: any = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    width: "100%",
  },
  header: {
    width: "100%",
    height: "70px",
    color: "white",
    backgroundColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 20px",
    flexDirecton: "row",
  },
  header__logo: {
    display: "flex",
    alignItems: "center",
  },
  header__logo__image: {
    width: "150px",
    height: "50px",
    cursor: "pointer",
  },
  header__text: {
    marginRight: "250px",
  },
};

export default withStyles(useStyles)(DesignerDesignUpload);
