// @ts-nocheck
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Divider from "@material-ui/core/Divider";
import {
  star,
  likes,
} from "./assets";
import UserProfileByOthersRatedController, {
  Props,
} from "./UserProfileByOthersRatedController.web";
import HeaderWeb from "./Header.web";
// import "./styles.css";
import FooterWeb from "../../catalogue/src/Footer.web";
import HeaderUser from "./HeaderUser.web";
import { toast } from "react-toastify";

class UserProfileByOthersRated extends UserProfileByOthersRatedController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const CardContainer = (props: any) => {
      return (
        <>
          {props.data.map((item: any, index: number) => {
            return (
              <div
                className="card"
                key={index}
                onClick={() => props.navigation.navigate("UserUploadedDesignView", {
                  id: item.id
                })}
              >
                <div className="card-image-div">
                  <img className="card-image" src={item.attributes.image_url} />
                  <div className="card-image-overlay" />
                  <span className="card-image-text">{item.attributes.title}</span>
                </div>
                <div className="card-details">
                  <div className="card-details-designer-info">
                    <img
                      src={
                        item.attributes.designer.data.attributes
                          .profile_thumbnail_url
                          ? item.attributes.designer.data.attributes
                              .profile_thumbnail_url
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                      }
                    />
                    <span>
                      {item.attributes.designer.data.attributes.first_name}
                    </span>
                    <span style={{ fontSize: "12px" }}>
                      <b>{item.attributes.design_type}</b>
                    </span>
                  </div>
                  <div className="card-icons">
                    <span className="card-icons-container">
                      <img className="card-icons-icon" src={star} />
                      {item.attributes.rating_count}
                    </span>
                    <span className="card-icons-container">
                      <img className="card-icons-icon" src={likes} />
                      {item.attributes.like_count}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    };
    const FiltersButton = (props: any) => {
      return (
        <button
          className={
            this.state.filter === `${props.filterName}`
              ? "main-header-community-button-selected"
              : "main-header-community-button"
          }
          onClick={() => this.changeCategory(props.filterName)}
        >
          {props.filterName}
        </button>
      );
    };
    return (
      <>
        {this.state.loading ? (
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <HeaderUser
              currentLabel={7}
              navigation={this.props.navigation}
              profileData={this.state.profileData.attributes}
              arrLabels={this.state.arrLabels}
            />
            <div className="main-container">
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px'
              }}>
                <div className="main-header-community">
                  <FiltersButton filterName={"Saved"} />
                  <FiltersButton filterName={"Liked"} />
                  <FiltersButton filterName={"Shared"} />
                  <FiltersButton filterName={"Inspired"} />
                  <FiltersButton filterName={"Rated"} />
                  <FiltersButton filterName={"All"} />
                </div>
              </div>
              <div className="main-sub-header">
                <div className="main-filters">
                  <div>
                    <p>
                      <b style={{ textTransform: 'uppercase' }}>{this.state.filter === 'All' ? '' : this.state.filter} DESIGNS</b>
                    </p>
                  </div>
                  <div className="main-header-filters">
                    <select
                      className="main-header-select"
                      id="dropdown"
                      value={this.state.subFilter}
                      onChange={(this.handleSubFilterInput)}
                    >
                      {Object.keys(this.state.arrSubFilters[this.state.filter]).map((key) => {
                        const item = this.state.arrSubFilters[this.state.filter][key];
                        return (
                          <option key={item} value={item === 'All' ? '' : item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto auto',
                columnGap: '15px'
              }}>
                <CardContainer
                  navigation={this.props.navigation}
                  data={this.state.designsData}
                />
              </div>
            </div>
            <FooterWeb
              navigation={this.props.navigation}
            />
          </>
        )}
      </>
    );
  }
}

const useStyles = {
  clubAbout: {
    display: "grid",
    gridTemplateColumns: "0.2fr auto 0.2fr",
    padding: "10px 0px",
    marginBottom: "15px",
    columnGap: "15px",
  },
  userClubName: {
    display: "grid",
    justifyContent: "center",
  },
};

export default withStyles(useStyles)(UserProfileByOthersRated);
