import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { ClubLayoutProps } from "../../../components/src/ClubLayout.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  layoutProps: ClubLayoutProps,
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  newCollectionName: string;
  privateCollection: boolean;
  currentLabel: number;
  arrLabels: any;
  arrCollection: any;
  profileData: any;
  popupStatus: boolean;
  newLabel: string;
  loading: boolean;
  clubData: any;
  flag: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  labelsMessageId: string;
  collectionMessageId: string;
  profileMessageId: string;
  newCollectionMessageId: string;
  clubMessageId: string;
  pinMessageId: string;
  newLabelMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onLabelNameChange = this.onLabelNameChange.bind(this);
    this.createPost = this.createPost.bind(this);
    this.changeLabel = this.changeLabel.bind(this);
    this.pinCollection = this.pinCollection.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      newCollectionName: "",
      privateCollection: false,
      currentLabel: 4,
      arrLabels: {},
      arrCollection: {},
      profileData: {},
      popupStatus: false,
      newLabel: "",
      loading: false,
      clubData: {},
      flag: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const DesignPublish = window.sessionStorage.getItem("DesignPublish");
    if (DesignPublish === "true") {
      toast.success("Design Published Successfull");
      window.sessionStorage.removeItem("DesignPublish");
    }
    this.getProfile();
    this.getClubData();
    this.getLabels();
    this.getCollections(0);
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // LABEL LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.labelsMessageId != null &&
      this.labelsMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ arrLabels: responseJson.data });
        if (responseJson.data.length > 0) {
          this.setState({ currentLabel: responseJson.data[0].id });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.clubMessageId != null &&
      this.clubMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ clubData: responseJson.data.attributes });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // COLLECTION LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.collectionMessageId != null &&
      this.collectionMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ arrCollection: responseJson.data });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading: false });
    }

    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ profileData: responseJson.data.attributes });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // ADD NEW COLLECTION API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newCollectionMessageId != null &&
      this.newCollectionMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        let arrCollectionData = [];
        arrCollectionData.push(responseJson.data);
        arrCollectionData = arrCollectionData.concat(this.state.arrCollection);
        this.setState({ arrCollection: arrCollectionData, 
          newCollectionName: "",
        });
        toast.success("Collection Created Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // ADD NEW LABEL API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newLabelMessageId != null &&
      this.newLabelMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelData = this.state.arrLabels;
        arrLabelData.push(responseJson.data);
        this.setState({ arrLabels: arrLabelData });
        this.setState({ currentLabel: arrLabelData[0].id });
        this.handleClose();
        toast.success("Label Created Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.pinMessageId != null &&
      this.pinMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const msg = this.state.flag ? "Pinned Successfully" : "Unpinned Successfully";
        toast.success(msg);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading:false });
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getClubData(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const apiUrl =
      configJSON.getClubApi + "/" + this.props.navigation.getParam("id", "");

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.clubMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  getLabels(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const clubId = this.props.navigation.getParam("id", "");
    const apiUrl = `${configJSON.getClubLabelsApi}?club_id=${clubId}`;
    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.labelsMessageId = getLabelsData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;
  }

  getCollections(labelId: number): boolean {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const clubId = this.props.navigation.getParam("id", "");
    const httpBody = {};
    let apiUrl = `${configJSON.getClubCollections}?club_id=${clubId}`;
    if (labelId > 0) {
      apiUrl = `${
        configJSON.getClubCollections
      }?club_id=${clubId}&label_id=${labelId}`;
    }
    const getCollectionData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.collectionMessageId = getCollectionData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getCollectionData.id, getCollectionData);
    return true;
  }

  createCollection(): boolean {
    if (this.state.newCollectionName === "") {
      alert("Please enter name of collection");
      return false;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          name: this.state.newCollectionName,
          label_id: this.state.currentLabel,
          privacy: this.state.privateCollection ? "Private" : "Public",
          club_id: this.props.navigation.getParam("id", ""),
          collection_type: "Club",
        },
      },
    };
    // const httpBody = {};
    const addNewCollection = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.addCollectionApi,
    });

    this.newCollectionMessageId = addNewCollection.messageId;
    runEngine.sendMessage(addNewCollection.id, addNewCollection);
    return true;
  }

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  handleOpen(): void {
    this.setState({ popupStatus: true });
  }

  handleClose(): void {
    this.setState({ popupStatus: false });
  }

  createLabel(): boolean {
    if (this.state.newLabel === "") {
      alert("Please enter name of Label");
      return false;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          name: this.state.newLabel,
          club_id: this.props.navigation.getParam("id", ""),
          label_type: "Club",
        },
      },
    };
    // const httpBody = {};
    const addNewLabel = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.createClubLabelApi,
    });

    this.newLabelMessageId = addNewLabel.messageId;
    runEngine.sendMessage(addNewLabel.id, addNewLabel);
    return true;
  }

  onLabelNameChange(e: any): void {
    this.setState({ newLabel: e.target.value });
  }

  createPost(collectionId: number): any {
    this.props.navigation.navigate("DesignerDesignUpload", {
      collectionId: collectionId,
      labelId: this.props.layoutProps.currentLabel,
      type: "club",
      clubId: this.props.navigation.getParam("id", ""),
    });
  }

  changeLabel(labelId: number): any {
    this.setState({ currentLabel: labelId });
    this.getCollections(labelId);
  }

  pinCollection(collectionId: number, flag: boolean): any {
    console.log('spec_props_checKed2', {collectionId,flag});
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {
      data: {
        attributes: {
          collection_id: collectionId
        }
      }
    };

    this.setState({
      flag: flag
    });
    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: flag ? configJSON.pinCollection : configJSON.unPinCollection,
    });

    this.pinMessageId = getLabelsData.messageId;
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;    
  }

  // Customizable Area End
}
