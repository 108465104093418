import { apiCall } from "../Common";
import { appendJsonContentType, appendToken } from "../utils";


const configJSON = require('../config');


export const designerMessages = {

  unfollowDesigner({ designerId }: { designerId: number }) {

    const url = `${configJSON.followDesignerAPI}/${designerId}`;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.DeleteMethod,
      url: url,
    });
  },

  followDesigner({ designerId }: { designerId: number }) {

    const url = configJSON.followDesignerAPI;

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {
        "data": {
          "attributes": {
            "account_id": designerId
          }
        }
      },
      httpMethod: configJSON.PostMethod,
      url: url,
    });
  },

}
