import React, { useEffect, useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@material-ui/core';
import DezinerButton from './Button.web';
import { promote } from '../../blocks/landingpage/src/assets';
import { useIsDesigner, useIsMyProfile, useIsUser, useLikeDesign, useNavigate, useSingleDesign, useSourceFiles } from './hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { saveIcon, likes } from '../../blocks/landingpage/src/assets';
import clsx from 'clsx';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import DezinerDropdown from './DezinerDropdown.web';
import { VerticalSpacer } from './Spacer.web';
import { DesignPriceItem } from './api-types';

const OptionsDialog = withStyles({
  paper: {
    borderRadius: '20px',
  },
})(Dialog);

const useStyles = makeStyles({
  floatButton: {
    boxShadow: '0 0 5px #cecaca',
    padding: '6px 18px',
    border: 'none !important',
    marginLeft: '15px',
  },
  icon: {
    maxWidth: '20px',
  },
});

const useBuyDialogStyles = makeStyles({
  button: {
    borderRadius: '0px',
    padding: '10px 20px !important',
    fontSize: '1.2rem',
    border: `3px solid lightgray`,
    color: 'gray',

  },
  activeButton: {
    border: `3px solid black`,
    color: 'black',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    flexWrap: 'wrap',
    '& > *': {
      margin: '5px',
    }
  },
});

function calculateLimitedPeriodString(targetPriceItem?: DesignPriceItem, _andString?: string) {
  const yearsCount = targetPriceItem?.year;
  const yearString = yearsCount ? `${yearsCount} Year(s)` : '';

  const monthsCount = targetPriceItem?.month
  const monthString = monthsCount ? `${monthsCount} Month(s)` : ''

  const andString = monthsCount && yearsCount ? (_andString || '&') : ''

  return `${yearString} ${andString} ${monthString}`;
}


function BuyDialogOptionsDialog({
  open,
  onClose,
  designId,
}: {
  open: boolean,
  onClose: () => void,
  designId: number,
}) {

  const classes = useBuyDialogStyles();

  const designQuery = useSingleDesign({ designId });
  const designerAttributes = designQuery?.data?.design?.data?.attributes;
  const [selectedOption, setSelectedOptions] = useState(0);
  const [isLifetime, setIsLifetime] = useState(false);
  const navigate = useNavigate();

  const selectedPriceItem = designerAttributes?.design_prices?.find(item => item.id == selectedOption);


  useEffect(function selectDefualtOption() {
    setSelectedOptions(designerAttributes?.design_prices?.[0]?.id ?? 0);
  }, [designerAttributes]);


  return (
    <OptionsDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
            {designerAttributes?.title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>

        <div className={classes.buttonsContainer}>
          <DezinerButton
            variant={'outlined'}
            onClick={() => setIsLifetime(true)}
            color="primary"
            startIcon={<CheckIcon />}
            className={clsx(classes.button, {
              [classes.activeButton]: isLifetime
            })}
          >
            {'Lifetime'}
          </DezinerButton>

          <DezinerButton
            variant={'outlined'}
            onClick={() => setIsLifetime(false)}
            color="primary"
            startIcon={<CheckIcon />}
            disabled={!designerAttributes?.design_prices?.length}
            className={clsx(classes.button, {
              [classes.activeButton]: !isLifetime
            })}
          >
            {'Limited Period'}
          </DezinerButton>
        </div>

        <VerticalSpacer />

        {
          !isLifetime && designerAttributes?.design_prices?.length ?
            <Grid container justifyContent="center">
              <DezinerDropdown
                selectedOption={String(selectedOption)}
                onClick={(v) => setSelectedOptions(parseInt(v))}
                options={designerAttributes?.design_prices
                  ?.map(item => ({
                    key: String(item.id),
                    title: calculateLimitedPeriodString(item)
                  })) ?? []
                }
                title=""
              />
            </Grid>
            :
            null
        }

        <VerticalSpacer />

        <Grid container justifyContent="center">
          <Typography style={{ fontSize: '1.2rem', }}>
            {`Buy with `}
            <span style={{
              fontWeight: 'bold',
              color: 'black',
              padding: '0 5px',
            }}>
              {'₹ '}
              {isLifetime ? (designerAttributes?.price ?? 0) : (selectedPriceItem?.amount ?? 0)}
            </span>
            {`for `}
            {
              isLifetime ?
                'Lifetime'
                :
                calculateLimitedPeriodString(selectedPriceItem, 'and')
            }
          </Typography>
        </Grid>

        <VerticalSpacer />

        <hr style={{ borderColor: '#f1f1f1' }} />

      </DialogContent>

      <DialogActions style={{ justifyContent: 'center', padding: '30px 0', }}>
        <DezinerButton
          size="large"
          variant="contained"
          color="primary"
          style={{ padding: '16px 32px', fontSize: '1.1rem' }}
          onClick={() => {
            if (isLifetime)
              navigate(`/DesignBuy/${designId}`);
            else
              navigate(`/DesignBuy/${designId}?price_id=${selectedOption}`);
          }}>
          {'Continue to Buy'}
        </DezinerButton>
      </DialogActions>

    </OptionsDialog>
  );
}

function LikeButton({
  show,
  onClick,
  liked,
}: {
  show: boolean,
  onClick: () => void,
  liked?: boolean,
}) {

  const classes = useStyles();

  if (!show)
    return null;

  return (
    <DezinerButton
      color="primary"
      variant="contained"
      className={classes.floatButton}
      startIcon={<img src={likes} className={clsx('white-svg', classes.icon)} />}
      onClick={onClick}
    >
      {liked ? 'Liked' : 'Like'}
    </DezinerButton>
  );
}

function PromoteButton() {

  return (
    <DezinerButton
      color="primary"
      variant="contained"
      startIcon={<img
        src={promote}
        alt="promote"
        className="white-svg"
        style={{ maxWidth: '20px', marginRight: '10px' }}
      />}
    >
      {'Promote'}
    </DezinerButton>
  );
}


function SaveButton({
  hide,
}: {
  hide: boolean,
}) {

  const classes = useStyles();

  if (hide)
    return null;

  return (
    <DezinerButton
      color="primary"
      variant="outlined"
      className={classes.floatButton}
      startIcon={<img src={saveIcon} className={classes.icon} />}
    >
      {'Save'}
    </DezinerButton>
  );
}

function DesignBuyButton({
  hide,
  isBought,
  designId,
}: {
  hide: boolean,
  isBought: boolean,
  designId: number,
}) {

  const [buyOptionsDialogOpen, setBuyOptionsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const designQuery = useSingleDesign({ designId });

  const sourceFilesQuery = useSourceFiles({ designId }, {
    enabled: isBought,
  });

  const sourceFiles = sourceFilesQuery?.data?.data ?? [];


  if (hide)
    return null;

  if (isBought)
    return (
      <DezinerButton
        color="primary"
        variant="contained"
        className={classes.floatButton}
        startIcon={<GetAppIcon />}
        onClick={async () => {
          setIsLoading(true);
          await Promise.all(sourceFiles.filter(file => file?.attributes?.data).map(file => (
            downloadFile({
              fileName: file?.attributes?.name,
              base64: file?.attributes?.data,
            }))));
          setIsLoading(false);
        }}
        loading={isLoading}
      >
        {`Download`}
      </DezinerButton>
    );


  return (
    <>
      <BuyDialogOptionsDialog
        open={buyOptionsDialogOpen}
        onClose={() => setBuyOptionsDialogOpen(false)}
        designId={designId}
      />
      <DezinerButton
        color="primary"
        variant="contained"
        className={classes.floatButton}
        startIcon={<LocalOfferIcon />}
        onClick={() => setBuyOptionsDialogOpen(true)}
      >
        {`Buy with ₹${designQuery?.data?.design?.data?.attributes?.price}`}
      </DezinerButton>
    </>
  );
}


function downloadFile({
  fileName,
  base64,
}: {
  fileName: string,
  base64: string,

}) {

  return fetch(base64)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(console.error);
}


export default function DesignActionsButtons({ designId }: { designId: number }) {


  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;
  const designerAttributes = designAttributes?.designer?.data?.attributes;

  const isMyProfile = useIsMyProfile({ id: designerAttributes?.id ?? 0 });

  const privacy = designAttributes?.privacy?.toLowerCase();
  const isPaid = privacy == 'private';

  const isUser = useIsUser();

  const isDesigner = useIsDesigner();

  const { mutate: likeDesign } = useLikeDesign({ designId });

  const [buyOptionsDialogOpen, setBuyOptionsDialogOpen] = useState(false);

  const isBought = Boolean(designAttributes?.buyed);



  return (
    <>
      <BuyDialogOptionsDialog
        open={buyOptionsDialogOpen}
        onClose={() => setBuyOptionsDialogOpen(false)}
        designId={designId}
      />

      <Grid container justifyContent="flex-end" alignItems="center">


        {
          isMyProfile ?
            <PromoteButton />
            :
            <>

              <DesignBuyButton
                hide={!(isUser && isPaid)}
                isBought={isBought}
                designId={designId}
              />

              <SaveButton
                hide={!isUser}
              />

            </>


        }

        <LikeButton
          show={isUser || (isDesigner && !isMyProfile)}
          onClick={() => likeDesign({ designId })}
          liked={designQuery?.data?.design?.data?.attributes?.liked}
        />


      </Grid>
    </>
  );
}
