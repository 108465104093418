import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  google,
} from "./assets";
import { Link } from 'react-router-dom';
import LinearProgress from "@material-ui/core/LinearProgress";
import GoogleLogin from "react-google-login";
import MicrosoftLogin from "./microsoft";
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormikTextInput from '../../../components/src/FormikTextField.web';
import TextInput from '../../../components/src/TextField.web';
import Button from '../../../components/src/Button.web';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import { passwordPolicyValidationSchema } from "../../../components/src/validation/password-validation";
const configJSON = require("../../social-media-account/src/config.js");


const signupFormInitValues = {
  name: '',
  username: '',
  email: '',
  phone: '',
  password: '',
  acceptTerms: false,
}

const signupFormValidationSchema = yup.object().shape({
  name: yup.string().required(),
  username: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string()
    //.required('Please insert Phone Number')
    .matches(/^[0-9]+$/, "Phone Number must be only digits")
    .min(10, 'Phone Number must be exactly 10 digits')
    .max(10, 'Phone Number must be exactly 10 digits'),
  password: passwordPolicyValidationSchema('password'),
  acceptTerms: yup.boolean().oneOf([true], 'Please accept our terms and conditions'),
});


const useStyles = makeStyles({
  formRoot: {
    padding: '10px', width: '400px', maxWidth: '90%', margin: '0 auto'
  },
  formTitle: {
    fontWeight: 900, fontSize: '1.3rem'
  },
  orText: {
    position: 'relative',
    fontSize: '1.2rem', fontWeight: 'bold',
    '&:before': {
      width: "144px",
      height: "1px",
      content: '""',
      display: "block",
      position: "absolute",
      background: "#c5c5c5",
      top: "50%",
      left: "32px"
    },
    '&:after': {
      width: "144px",
      height: "1px",
      content: '""',
      display: "block",
      position: "absolute",
      background: "#c5c5c5",
      top: "50%",
      right: "32px"
    },
  },
  checkMark: {
    padding: '0 5px'
  },
  circularProgress: {
    margin: '0 5px'
  },
  bold: {
    fontWeight: 'bold'
  },
  termsAndCondtionsText: {
    color: '#2681FD'
  },
  nameInputContainer: {
    paddingRight: '10px'
  }
});



const useOtpVerifyStyles = makeStyles({
  dialogActions: {
    justifyContent: 'space-between', padding: '20px 20px'
  },
  progressContainer: {
    position: 'relative', top: '6px', width: '200px'
  },
  circularProgress: {
    margin: '0 5px'
  },
});


interface SignupFormProps {
  loading: boolean,
  onSubmit: (
    values: typeof signupFormInitValues
  ) => void,
  microsoftAuthHandler: any,
  googleHandlers: {
    onSuccess: any,
    onFailure: any,
  },
  serverSideValidationsMessages: {
    user_name: string,
    email: string,
    full_phone_number: string,
    phoneOtpIsInvalid: string,
    otpError: string,
  },
  booleansState: {
    openOTPVerify: boolean,
    otpVerifyLoading: boolean,
    numberIsVerified: boolean,
    otpSent: boolean,
    otpSendLoading: boolean,
  },
  onOtpClose: any,
  onOtpSubmit: any,
  sendOtpCode: any,
  otpSeconds: number,
  openOTPVerify: any,
  signInHandler: any,
}



export default function SignupForm({
  onSubmit,
  microsoftAuthHandler,
  googleHandlers,
  loading,
  serverSideValidationsMessages,
  booleansState,
  onOtpClose,
  onOtpSubmit,
  sendOtpCode,
  otpSeconds,
  openOTPVerify,
  signInHandler,
}: SignupFormProps) {

  const classes = useStyles();

  const {
    otpVerifyLoading,
    numberIsVerified,
    otpSent,
    otpSendLoading,
  } = booleansState;

  const {
    user_name,
    email,
    full_phone_number,
    phoneOtpIsInvalid,
    otpError,
  } = serverSideValidationsMessages;



  return (
    <div className={classes.formRoot}>
      <Formik
        initialValues={signupFormInitValues}
        validationSchema={signupFormValidationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {
          formik => <Form translate="yes">

            <Grid container justifyContent="center">
              <Typography variant="h1" className={classes.formTitle}>
                {'SIGN UP'}
              </Typography>
            </Grid>

            <VerticalSpacer />
            <VerticalSpacer />

            <Grid container alignItems="center">
              <Grid item lg={6} sm={12} style={{ padding: '0 4px' }} container justifyContent="center">
                <GoogleLogin
                  clientId={configJSON.clientID}
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      color="primary"
                      variant="contained"
                      noWrap
                      style={{ maxWidth: '100%', maxHeight: '35px', fontSize: '13px' }}
                    >
                      <img src={google} alt="google" style={{ margin: '0 5px' }} />
                      {'Sign up with Google'}
                    </Button>
                  )}
                  onSuccess={googleHandlers.onSuccess}
                  onFailure={googleHandlers.onFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </Grid>
              <Hidden lgUp>
                <Grid item xs={12}><br /></Grid>
              </Hidden>
              <Grid item lg={6} sm={12} style={{ padding: '0 4px' }} container justifyContent="center">
                <MicrosoftLogin
                  buttonTheme="dark"
                  clientId="6044639f-c96f-4d99-b5dd-f9fd8660cc82"
                  redirectUri=""
                  authCallback={(err, data, msal) => {
                    microsoftAuthHandler(err, data, msal);
                  }}
                  label="Sign up with Microsoft"
                />
              </Grid>
            </Grid>

            <VerticalSpacer />
            <VerticalSpacer />

            <Grid container justifyContent="center">
              <Typography
                variant="body1"
                className={classes.orText}
              >
                {'or'}
              </Typography>
            </Grid>

            <VerticalSpacer />

            <Grid container justifyContent="space-between">
              <Grid item sm={6} xs={12} className={classes.nameInputContainer}>
                <FormikTextInput
                  name="name"
                  fullWidth
                  variant="outlined"
                  label={"Name*"}
                  disabled={loading}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <FormikTextInput
                  name="username"
                  fullWidth
                  variant="outlined"
                  label={"Username*"}
                  disabled={loading}
                />
                {
                  user_name ?
                    <Alert severity="error">
                      {user_name}
                    </Alert>
                    :
                    null
                }
              </Grid>
            </Grid>

            <VerticalSpacer />

            <FormikTextInput
              name="email"
              fullWidth
              variant="outlined"
              label={"Email*"}
              disabled={loading}
            />

            {
              email ?
                <Alert severity="error">
                  {email}
                </Alert>
                :
                null
            }
            <VerticalSpacer />

            <FormikTextInput
              name="phone"
              fullWidth
              variant="outlined"
              label={"Phone"}
              type="number"
              //disabled={loading || numberIsVerified}
              disabled={loading}
            />


          {/* stop for mvp

            <OTPVerifyDialog
              open={booleansState.openOTPVerify}
              onClose={onOtpClose}
              onSubmit={onOtpSubmit}
              error={otpError}
              loading={otpVerifyLoading}
              seconds={otpSeconds}
              onResend={() => {
                if (String(formik.values.phone).length == 10) {
                  sendOtpCode({ phone: formik.values.phone as any });
                }
              }}
            />

            <Grid container justifyContent="flex-end">
              {
                numberIsVerified ?
                  <Typography color="secondary" variant="body1">
                    {'Number verified'}
                    <span className={classes.checkMark}>&#10003;</span>
                  </Typography>
                  :
                  String(formik.values.phone).length == 10 ?
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      onClick={() => {

                        //if (otpSeconds == 0 && String(formik.values.phone).length == 10)
                        if (otpSeconds == 60 && String(formik.values.phone).length == 10)
                          sendOtpCode({ phone: formik.values.phone as any });

                        else if (!phoneOtpIsInvalid && otpSent && otpSeconds > 0)
                          openOTPVerify();
                      }}
                    >
                      {'verify number'}
                      {
                        otpSendLoading ?
                          <CircularProgress className={classes.circularProgress} color="inherit" size={21} />
                          :
                          null
                      }
                    </Button>
                    :
                    null
              }

            </Grid>
*/}

            {
              full_phone_number ?
                <Alert severity="error">
                  {full_phone_number}
                </Alert>
                :
                null
            }
            {
              phoneOtpIsInvalid ?
                <Alert severity="error">
                  {phoneOtpIsInvalid}
                </Alert>
                :
                null
            }
            {
              !numberIsVerified && formik.submitCount > 0 ?
                <Alert severity="error">
                  {'You need to verify your phone number'}
                </Alert>
                :
                null
            }

            <VerticalSpacer />

            <FormikTextInput
              name="password"
              fullWidth
              variant="outlined"
              label={"Password*"}
              type="password"
              placeholder="Password length should be 8 characters at least"
              disabled={loading}
            />

            <VerticalSpacer />

            <FormControlLabel
              control={<Checkbox
                color="primary"
                checked={formik.values.acceptTerms}
                onChange={(event) => {
                  formik.setFieldValue('acceptTerms', event.target.checked)
                }}
                name="acceptTerms"
              />}
              label={
                <Grid container alignItems="center">
                  <Typography variant="caption">
                    {"Creating an account means you're okay with our "}
                    <span>&nbsp;</span>
                    <Typography variant="caption" className={classes.termsAndCondtionsText}>
                      <Link to="/TermsOfService">{"Terms of Service"}</Link>
                    </Typography>,{" "}
                    <Typography variant="caption" className={classes.termsAndCondtionsText}>
                      <Link to="/PrivacyPolicy">{"Privacy Policy"}</Link>
                    </Typography>
                  </Typography>
                </Grid>
              }
            />

            {
              formik.errors?.acceptTerms ?
                <Alert severity="error">
                  {formik.errors?.acceptTerms}
                </Alert>
                :
                null
            }

            <VerticalSpacer />

            <Grid container justifyContent="center">
              <Button
                disabled={loading}
                rounded
                color="primary"
                type="submit"
                variant="contained">
                {'Create Account'}
                {
                  loading ?
                    <CircularProgress className={classes.circularProgress} color="inherit" size={21} />
                    :
                    null
                }
              </Button>
            </Grid>

            <VerticalSpacer />
            <VerticalSpacer />

            <Grid container justifyContent="center" alignItems="center">
              <Typography className={classes.bold}>
                {'Already a member?'}
              </Typography>
              <Button bold color="secondary" variant="text"
                onClick={(e: any) => {
                  e.preventDefault();
                  signInHandler();
                }}
              >
                {'Sign In'}
              </Button>
            </Grid>

          </Form>
        }
      </Formik>
    </div>
  )
}


const OTP_TIME = 60;

function OTPVerifyDialog({ open, onClose, onSubmit, error, loading, seconds, onResend }: {
  open: boolean,
  onClose: () => void,
  onSubmit: (otp: string) => void,
  error: string,
  loading: boolean,
  seconds: number,
  onResend: () => void,
}) {
  const classes = useOtpVerifyStyles();
  const [otp, setOtp] = useState('');

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle style={{ textAlign: 'center' }}>
        {'Enter the OTP we have sent and verify your mobile number'}
      </DialogTitle>
      <DialogContent>



        <Grid container direction="column" justifyContent="center" alignItems="center">
          <TextInput
            centerLabel
            centerText
            variant="standard"
            label="OTP*"
            placeholder="4 digits otp code"
            value={otp}
            onChange={event => {
              let value = event.target.value;
              if (value.length <= 4)
                setOtp(value);
            }}
            inputProps={{
              maxLength: 4
            }}
            onKeyDown={event => {
              if (event.key == 'Enter' && otp.length == 4) {
                onSubmit(otp);
              }
            }}
            disabled={!(seconds > 0)}
          />
        </Grid>

        {
          error ?
            <Alert severity="error">
              {error}
            </Alert>
            :
            null
        }

        {
          !(seconds > 0) ?
            <>
              <VerticalSpacer />
              <Grid container justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={(seconds > 0)}
                  onClick={onResend}>
                  {'Resend OTP'}
                  {
                    loading ?
                      <CircularProgress className={classes.circularProgress} color="inherit" size={21} />
                      :
                      null
                  }
                </Button>
              </Grid>
            </>
            :
            null
        }

      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <div className={classes.progressContainer}>
          <LinearProgress
            variant="determinate"
            value={(seconds * 100 / OTP_TIME)}
            color={seconds > 0 ? "primary" : "secondary"}
          />
          <Typography variant="caption">
            {`${seconds}s remaining`}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={!(seconds > 0)}
          onClick={() => {
            if (otp)
              onSubmit(otp);
          }}>
          {'Verify'}
          {
            loading ?
              <CircularProgress className={classes.circularProgress} color="inherit" size={21} />
              :
              null
          }
        </Button>

      </DialogActions>
    </Dialog>
  )
}
