import { useQueryClient } from "@tanstack/react-query";
import { designerMessages } from "../messages-generator";
import { designerKeys, profileKeys } from "../query-keys";
import { useDezinerMutation } from "../ReactQuery.web";


export function useFollowDesigner({ designerId, }: { designerId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designerMessages.followDesigner>[0]>({
    messageGenerator: designerMessages.followDesigner,
    queryKey: designerKeys.followDesigner({ designerId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }));
      }
    },
  });
}

export function useUnfollowDesigner({ designerId, }: { designerId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof designerMessages.followDesigner>[0]>({
    messageGenerator: designerMessages.unfollowDesigner,
    queryKey: designerKeys.unfollowDesigner({ designerId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(profileKeys.fetchProfileByUser({ id: designerId }));
      }
    },
  });
}
