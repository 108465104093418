import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { Profile } from "../../../components/src/api-types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  arrClubs: any;
  page: number;
  currentClub: any;
  anchorEl: any;
  profileData: Partial<Profile['attributes']>;
  currentPage: number;
  clubPagesCount: number;
  endPage: number;
  startPage: number;
  backupClubs: any;
  searchQuery: string,
  loading: boolean,
  profileLoading: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignerGroupPageViewController extends BlockComponent<
  Props,
  S,
  SS
  > {
  clubApiMessageId: string;
  profileMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.manageClub = this.manageClub.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangePagination = this.handleChangePagination.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      arrClubs: {},
      page: 1,
      currentClub: {},
      anchorEl: null,
      profileData: {},
      currentPage: 1,
      clubPagesCount: 0,
      endPage: 10,
      startPage: 0,
      backupClubs: {},
      searchQuery: '',
      loading: false,
      profileLoading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getClubs();
    this.getProfile();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.clubApiMessageId != null &&
      this.clubApiMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });

      if (responseJson && !responseJson.errors) {
        this.setState({
          arrClubs: responseJson.data,
          backupClubs: responseJson.data,
          clubPagesCount: Math.ceil(responseJson.data.length / 10)
        }, () => {
          const lastPage = 10;
          this.setState({
            endPage: lastPage > responseJson.data.length ? responseJson.data.length : lastPage
          });
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setState({ profileLoading: false });
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ profileData: responseJson.data.attributes });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // Customizable Area End
  }

  getClubs(): boolean {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.groupsList,
    });

    this.clubApiMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  manageClub(id: number): void {
    this.props.navigation.navigate("DesignerGroupManage", {
      id: id
    });
  }

  handleClick(event: any): void {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose(): void {
    this.setState({ anchorEl: null });
  };

  getProfile(): boolean {

    this.setState({ profileLoading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  handleChangePagination(event: any, value: number) {
    this.setState({
      currentPage: value,
    });
  }

  handleSearch(searchTerm: any) {
    if (searchTerm === '') {
      this.setState({
        arrClubs: this.state.backupClubs,
        backupClubs: this.state.backupClubs,
        clubPagesCount: Math.ceil(this.state.backupClubs.length / 10)
      }, () => {
        const lastPage = 10;
        this.setState({
          endPage: lastPage > this.state.backupClubs.length ? this.state.backupClubs.length : lastPage
        });
      });
    } else {
      const arrFilterClubs = this.state.backupClubs.filter((club: any) => club.attributes.name.toLowerCase().includes(searchTerm.toLowerCase()));
      this.setState({
        arrClubs: arrFilterClubs,
        clubPagesCount: Math.ceil(arrFilterClubs.length / 10)
      }, () => {
        const lastPage = 10;
        this.setState({
          endPage: lastPage > arrFilterClubs.length ? arrFilterClubs.length : lastPage
        });
      });
    }
  }


  // Customizable Area End
}
