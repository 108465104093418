import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  aboutClub: string;
  currentClub: number;
  newAbout: string;
  bioInEditMode: boolean;
  skillsInEditMode: boolean;
  skillsList: any;
  listOfSkills: any;
  currentLabel: number;
  arrLabels: any;
  profileData: any;
  popupStatus: boolean;
  newLabel: string;
  dialogStatus: boolean;
  selectedFilter: string;
  arrClubs: any;
  arrGroups: any;
  designerId: number;
  shareDialogStatus: boolean;
  socialInEditMode: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignerAboutPageController extends BlockComponent<
  Props,
  S,
  SS
  > {
  clubApiMessageId: string;
  newAboutMessageId: string;
  getProductApiCallId: any;
  labelsMessageId: string;
  collectionMessageId: string;
  profileMessageId: string;
  newCollectionMessageId: string;
  newLabelMessageId: string;
  skillsMessageId: string;
  socialMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.updateAbout = this.updateAbout.bind(this);
    this.addSkill = this.addSkill.bind(this);
    this.removeSkill = this.removeSkill.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onLabelNameChange = this.onLabelNameChange.bind(this);
    this.getSkills = this.getSkills.bind(this);
    this.keydownEvent = this.keydownEvent.bind(this);
    this.updateSocial = this.updateSocial.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      aboutClub: "",
      currentClub: this.props.navigation.getParam("id", ""),
      newAbout: "",
      bioInEditMode: false,
      skillsInEditMode: false,
      socialInEditMode: false,
      skillsList: [],
      listOfSkills: [],
      currentLabel: 0,
      arrLabels: [{
        id: 0,
        attributes: {
          name: 'All'
        }
      }],
      profileData: {},
      newLabel: "",
      selectedFilter: "All",
      popupStatus: false,
      dialogStatus: false,
      arrClubs: [],
      arrGroups: [],
      designerId: this.props.navigation.getParam("id", 0),
      shareDialogStatus: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    document.addEventListener("keydown", this.keydownEvent);
    this.getLabels();
    this.getSkills();
    this.getProfile();
  }

  componentWillUnmount = (): any => {
    document.removeEventListener('keydown', this.keydownEvent);
    return;
  }

  keydownEvent = (event: any) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      this.setState({ shareDialogStatus: false });
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.clubApiMessageId != null &&
    //   this.clubApiMessageId ===
    //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );
    //   if (responseJson && !responseJson.errors) {
    //     this.setState({ aboutClub: responseJson.data.attributes.about });
    //     this.setState({ newAbout: responseJson.data.attributes.about });
    //   } else {
    //     var errorReponse = message.getData(
    //       getName(MessageEnum.RestAPIResponceErrorMessage)
    //     );
    //   }
    // }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.skillsMessageId != null &&
      this.skillsMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const lists: any = [];
        if (responseJson.data.length > 0) {
          responseJson.data.map((item: any) => {
            lists.push(item.attributes);
          });
        }
        this.setState({ listOfSkills: lists });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(responseJson.errors[0].message);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newAboutMessageId != null &&
      this.newAboutMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        // this.setState({ aboutClub: responseJson.data.attributes.bio });
        // this.setState({ newAbout: responseJson.data.attributes.bio });
        toast.success("Updated Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        // toast.error(responseJson.errors[0].message);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.socialMessageId != null &&
      this.socialMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        // this.setState({ aboutClub: responseJson.data.attributes.bio });
        // this.setState({ newAbout: responseJson.data.attributes.bio });
        toast.success("Updated Successfully");

        this.getProfile();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        // toast.error(responseJson.errors[0].message);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const skillsListData: any = [];
        if (responseJson.data.attributes.skills.data.length > 0) {
          responseJson.data.attributes.skills.data.map((item: any) => {
            skillsListData.push(item.attributes);
          });
        }
        const date = responseJson.data.attributes.created_at.split('T')[0];
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        responseJson.data.attributes.created_at = [day, month, year].join('-');
        this.setState({
          profileData: responseJson.data.attributes,
          aboutClub: responseJson.data.attributes.bio,
          newAbout: responseJson.data.attributes.bio,
          arrClubs: responseJson.data.attributes.clubs.data,
          arrGroups: responseJson.data.attributes.groups.data,
          skillsList: skillsListData,
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // ADD NEW LABEL API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newLabelMessageId != null &&
      this.newLabelMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelData = this.state.arrLabels;
        arrLabelData.push(responseJson.data);
        this.setState({ arrLabels: arrLabelData });
        this.setState({ currentLabel: arrLabelData[0].id });
        this.handleClose();
        toast.success("Label Created Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // LABEL LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.labelsMessageId != null &&
      this.labelsMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelData = this.state.arrLabels;
        this.setState({ arrLabels: [...arrLabelData, ...responseJson.data] });
        // if (responseJson.data.length > 0) {
        //   this.setState({ currentLabel: responseJson.data[0].id });
        // }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // Customizable Area End
  }

  updateAbout() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const skillsLists: any = [];
    if (this.state.skillsList.length > 0) {
      this.state.skillsList.map((itemSkill: any) => {
        skillsLists.push({ skill_id: itemSkill.id });
      });
    }
    const httpBody = {
      data: {
        attributes: {
          bio: this.state.aboutClub,
          accounts_skills_attributes: skillsLists
        },
      },
    };
    const addNewAbout = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethodPut,
      url: configJSON.updateAbout,
    });

    this.newAboutMessageId = addNewAbout.messageId;
    runEngine.sendMessage(addNewAbout.id, addNewAbout);
    return true;
  }

  addSkill(skills: any) {
    this.setState({ skillsList: skills });
  }

  removeSkill(id: number) {
    const skills = this.state.skillsList.filter((item: any) => item.id !== id);
    this.setState({ skillsList: skills });
  }

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_profile_by_users`,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  handleOpen(): void {
    this.setState({ popupStatus: true });
  }

  handleClose(): void {
    this.setState({ popupStatus: false });
  }

  createLabel(): boolean {
    if (this.state.newLabel === "") {
      alert("Please enter name of Label");
      return false;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          name: this.state.newLabel,
        },
      },
    };
    // const httpBody = {};
    const addNewLabel = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.addNewLabel,
    });

    this.newLabelMessageId = addNewLabel.messageId;
    runEngine.sendMessage(addNewLabel.id, addNewLabel);
    return true;
  }

  onLabelNameChange(e: any): void {
    this.setState({ newLabel: e.target.value });
  }

  getLabels(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_labels_by_users`,
    });

    this.labelsMessageId = getLabelsData.messageId;
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;
  }

  getSkills() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.skillsList,
    });

    this.skillsMessageId = getLabelsData.messageId;
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
  }

  updateSocial({ twitter, facebook, instagram }: { twitter: string, facebook: string, instagram: string }) {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          facebook_url: facebook,
          twitter_url: twitter,
          instagram_url: instagram,
        },
      },
    };
    const addNewAbout = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethodPut,
      url: configJSON.updateSocialProfiles,
    });

    this.socialMessageId = addNewAbout.messageId;
    runEngine.sendMessage(addNewAbout.id, addNewAbout);
    return true;
  }

  // Customizable Area End
}
