import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DesignerClubManageController, {
  Props
} from "./DesignerClubManageController.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import HeaderWeb from "./Header.web";
import FooterWeb from "../../catalogue/src/Footer.web";
import GroupAndClubHorizontalCard from "./GroupAndClubHorizontalCard.web";
import MembersContributionTableWithShares from "./MembersContributionTable.web";
import DesignerProfileWithOptions from "./DesignerProfileWithOptions.web";
import "./styles.css";
import AddIcon from '@material-ui/icons/Add';
import DezinerButton from "../../../components/src/Button.web";
import { a11yProps, DezinerTab, DezinerTabPanel, DezinerTabs } from "./Tabs.web";
import DeleteMemberDialog from "../../../components/src/DeleteMemberDialog.web";





class DesignerClubManage extends DesignerClubManageController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;

    return (
      <>

        <DeleteMemberDialog
          open={this.state.deletePopup}
          onClose={this.handleClickDeleteClose}
          memberName={this.state.deleteMember}
          onAccept={this.handleClickDeleteMember}
        />




        {/* @ts-ignore */}
        {/* <HeaderWeb navigation={this.props.navigation} /> */}


        <div style={{ padding: '50px 0' }}>
          {
            this.state.loading ?
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress color="primary" />
              </Grid>
              :
              <Grid container>
                <Grid item sm={2}></Grid>
                <Grid item xs={12} sm>


                  <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center"
                    style={{
                      borderBottom: '1px solid #ddd'
                    }}
                  >
                    <GroupAndClubHorizontalCard
                      item={this.state.currentClub}
                      hideButton
                      hideMembers
                      hideBorder
                    />
                    <DezinerTabs
                      value={this.state.currentTab}
                      onChange={this.handleChange}
                      style={{ backgroundColor: "white", color: "black" }}
                    >
                      <DezinerTab label="Member" {...a11yProps(0)} />
                      <DezinerTab label="Role" {...a11yProps(1)} />
                    </DezinerTabs>
                  </Grid>

                  <DezinerTabPanel value={this.state.currentTab} index={0}>

                    <Grid container style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))' }}>
                      {
                        this.state.clubMembers.map((member: any) => (
                          <div key={member?.id} style={{ padding: '30px 0', }}>
                            <DesignerProfileWithOptions
                              currentRole={this.state.currentUserRoleClub}
                              member={member}
                              onDelete={({ deleteType, memberId, memberName }) => {
                                this.setState({
                                  deleteMember: memberName,
                                  deleteType: deleteType,
                                  deleteId: memberId,
                                  deletePopup: true
                                })
                              }}
                              addAsAdmin={({ memberId }) => {
                                this.addAsAdmin({ memberId });
                              }}
                            />
                          </div>
                        ))
                      }
                    </Grid>


                    <Grid container justifyContent="center">
                      {
                        (
                          this.state.currentUserRoleClub === 'Admin' ||
                          this.state.currentUserRoleClub === 'Owner'
                        ) ? (
                            <DezinerButton
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                this.props.navigation.navigate("AddClubMember", {
                                  id: this.props.navigation.getParam("id", 0)
                                })
                              }
                              startIcon={
                                <AddIcon />
                              }
                            >
                              {'Add Member'}
                            </DezinerButton>
                          )
                          :
                          null
                      }
                    </Grid>

                  </DezinerTabPanel>
                  <DezinerTabPanel value={this.state.currentTab} index={1}>

                    <MembersContributionTableWithShares
                      members={this.state.clubMembers}
                      onDelete={({ memberName, memberId }) => {
                        this.setState({
                          deleteMember: memberName,
                          deleteId: memberId,
                          deleteType: "remove_member",
                          deletePopup: true,
                        })
                      }}
                      onAdd={() => this.props.navigation.navigate('AddClubMember', {
                        id: this.props.navigation.getParam('id', 0)
                      })}
                      onSave={(revenu) => {
                        console.log({ revenu });
                      }}
                      currentRole={this.state.currentUserRoleClub}
                    />

                  </DezinerTabPanel>

                </Grid>
                <Grid item sm={2}></Grid>
              </Grid>
          }
        </div>


        {/*
        <FooterWeb
          navigation={this.props.navigation}
        />
*/}
      </>
    );
  }
}


const useStyles: any = {

};

export default withStyles(useStyles)(DesignerClubManage);
