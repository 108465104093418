import React from "react";
import UserEditAccountController, {
  Props,
} from "./UserEditAccountController.web";
import AccountSettingForm from "../../../components/src/AccountSettingsForm.web";

class UserEditAccount extends UserEditAccountController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
        <AccountSettingForm />
    );
  }
}

export default UserEditAccount;
