import { useQueryClient } from "@tanstack/react-query";
import { Collection } from "../api-types";
import { collectionsMessages } from "../messages-generator";
import { collectionsKeys, designsKeys } from "../query-keys";
import { useDezinerMutation, useDezinerQuery } from "../ReactQuery.web";


export function useCreateCollection({ labelId, designerId }: { labelId: number, designerId: number, }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof collectionsMessages.createCollection>[0]>({
    messageGenerator: collectionsMessages.createCollection,
    queryKey: collectionsKeys.createCollection({ labelId }),
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(collectionsKeys.fetchCollections({ designerId, labelId }));
      }
    }
  });
}


export function useSingleCollection({ collectionId }: { collectionId: number }) {


  return useDezinerQuery<{ data: Collection }>({
    queryKey: collectionsKeys.fetchSingleCollection({ collectionId }),
    message: collectionsMessages.fetchSingleCollection({ collectionId }),
  });
}


export function usePinCollection({ designerId, labelId }: { designerId: number, labelId: number }) {

  const queryClient = useQueryClient();

  return useDezinerMutation<Parameters<typeof collectionsMessages.pinCollection>[0]>({
    queryKey: collectionsKeys.pinCollection({ designerId }),
    messageGenerator: collectionsMessages.pinCollection,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(designsKeys.fetchCollctions({ designerId, labelId, }));
      }
    },
  });
}

