import { fork, takeEvery, take, select, put, takeLatest } from "redux-saga/effects";
import { fetchDesigns, FETCH_DESIGNS, SetActiveCategoryAction, SET_ACTIVE_CATEGORY } from "../designs/designs.action";
import { fetchDesignsSaga } from "../designs/designs.effect";
import { ChangeLabelAction, CHANGE_LABEL, FETCH_LABELS } from "../labels/labels.action";
import { fetchLabelsSaga } from "../labels/labels.effect";

import { push } from 'connected-react-router'

import { FETCH_PROFILE } from "../profile/profile.action";
import { fetchProfileSaga } from "../profile/profile.effect";
import { RootState } from "../store";
import { NAVIGATE_TO } from "../navigation/navigation.action";
import { navigateToSaga } from "../navigation/navigation.effec";
import { PIN_COLLECTION, UNPIN_COLLECTION } from "../collections/pin.action";
import { pinCollectionSaga, unpinCollectionSaga } from "../collections/pin.effect";



export function* rootSaga() {
  try {
    yield takeEvery(FETCH_PROFILE, fetchProfileSaga);

    yield takeEvery(FETCH_LABELS, fetchLabelsSaga);
    yield takeEvery(FETCH_DESIGNS, fetchDesignsSaga);

    yield takeEvery(NAVIGATE_TO, navigateToSaga);

    yield takeLatest([CHANGE_LABEL, SET_ACTIVE_CATEGORY], changeLabelsOrCategorySaga);

    yield takeEvery(PIN_COLLECTION, pinCollectionSaga);
    yield takeEvery(UNPIN_COLLECTION, unpinCollectionSaga);

  } catch (error) {
    console.error(error);
  }
}

function* changeLabelsOrCategorySaga(action: ChangeLabelAction | SetActiveCategoryAction) {
  try {
    const state: RootState = yield select();

    let designsOwner = action.designsOwner || 'designers';

    let currentUserId = String(state.profile?.profile?.id ?? '');



    if (designsOwner && currentUserId) {

      const payload: any = {}

      if (designsOwner == 'designers') {

        payload.designerId = currentUserId;
        payload.labelId = String(state.labels.currentDesignerLabel);

      } else if (designsOwner == 'users') {

        payload.userId = currentUserId;
        payload.labelId = String(state.labels.currentUserLabel);
      }


      yield put(fetchDesigns({
        category: state.designs.activeCategory,
        designsType: designsOwner,
        ...payload,
      }));

    }

  } catch (error) {
    console.error(error);
  }
}
