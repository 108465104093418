import React, { useState } from 'react';
import LabelListing, { LabelListingProps } from '../../blocks/catalogue/src/LabelListing.web';
import { CollectionHome, Design, DesignCategory, Profile } from './api-types';
import DezinerButton from './Button.web';
import DezinerHeader from './DezinerHeader.web';
import HeaderControls from './DezinerHeaderControls.web';
import { HorizontalSpacer } from './Spacer.web';
import { makeStyles } from '@material-ui/core/styles';
import { infoIcon } from '../../blocks/Groups/src/assets';
import { subscribeIcon } from '../../blocks/landingpage/src/assets';
import CreateLabelDialog from '../../blocks/landingpage/src/CreateLabelDialog.web';
import clsx from 'clsx';
import { useCreateLabel, useCreateCollection, useIsDesigner, useIsMyProfile, usePinCollection, useProfile, useDesigns, useCollections, useLabels, useIsUser, useFollowDesigner, useUnfollowDesigner, useNavigate } from './hooks';
import { RootState } from './store/store';
import { useSelector } from 'react-redux';
import DezinerChangeCoverButton from './DezinerChangeCoverButton.web';
import { useParams } from './utils';
import AddIcon from '@material-ui/icons/Add';
import * as R from 'ramda';


export interface DesignerHomeLayoutProps {
  category: DesignCategory,
  profile: Partial<Profile['attributes']>,
  labelId: number,
  labels: any[],
  designs: (Design | CollectionHome)[],
  loading: boolean,
  changeLabel: (labelId: number) => void,
  createCollection: (params: { collectionName: string, isPrivate: boolean, labelId: number }) => void,
  pinCollection: (params: { collectionId: number, pin: boolean }) => void,
  openAddLabelDialog: (error?: string) => void,
  displayCollectionsAsList: boolean,
}

const DEFAULT_PROFILE: Profile['attributes'] = {
  id: 0,
  first_name: '',
  user_name: '',
  email: '',
  full_phone_number: '',
  country_code: '',
  phone_number: '',
  location: '',
  activated: false,
  device_id: '',
  unique_auth_id: '',
  role: '',
  type: '',
  bio: '',
  about: '',
  profile_info: '',
  color: '',
  profile_url: '',
  profile_thumbnail_url: '',
  cover: '',
  facebook_url: '',
  twitter_url: '',
  instagram_url: '',
  user_name_days: 0,
  follower_count: 0,
  following_count: 0,
  totel_collections: 0,
  totel_designs: 0,
  totel_like_designs: 0,
  totel_promoted_designs: 0,
  totel_inspired_designs: 0,
  totel_rated_designs: 0,
  totel_free_designs: 0,
  totel_paid_designs: 0,
  followed: false,
  subscribed: false,
  kyc_updated: false,
  user_name_at: '',
  created_at: '',
  updated_at: '',
  clubs: { data: [] },
  groups: { data: [] },
  kyc_updated_at: '',
  skills: {
    data: []
  }
};



const useStyles = makeStyles({
  headerFloatingBtn: {
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
  },
  aboutButton: {
    '&:hover': {
      background: 'black',
      color: 'white',
    }
  },
  floatButtonActive: {
    background: 'black !important',
    color: 'white !important',
  },
});


const removeNilValues = R.filter(R.compose(R.not, R.isNil));

function defaultValuesTo<T>(defaultData: any): (p?: T) => T {
  // @ts-ignore 
  return R.compose(R.mergeRight(defaultData), removeNilValues, R.defaultTo({}))
}

const defaultProfile = defaultValuesTo<Profile['attributes']>(DEFAULT_PROFILE);

function useParsedQueryParams() {

  const params = useParams();
  const designerId = parseInt(params.designerId);
  const categoryQueryParam = params.category;
  const category = categoryQueryParam || 'collections';

  const { label_id }: { label_id?: string } = useSelector((state: RootState) => state.router.location.query);
  const labelId = parseInt(label_id || '0');

  return {
    labelId,
    category,
    designerId,
    categoryQueryParam,
  }
}

function useCalculatedLabels({
  allowAllLabel,
  changeLabel,
  inAboutPage,
  labelId,
  designerId,
  onCreate,
}: {
  allowAllLabel: boolean,
  changeLabel: (labelId: number) => void,
  inAboutPage: boolean,
  labelId: number,
  designerId: number,
  onCreate: () => void,
}) {

  const isMyProfile = useIsMyProfile({ id: designerId });
  const navigate = useNavigate();
  const labelsQuery = useLabels({ designerId });



  const firstLabelId = labelsQuery?.data?.data?.[0]?.id;
  const computedLabel = labelId || firstLabelId;
  const labelsParamsWithSaveDefault = computedLabel ? `?label_id=${computedLabel}` : '';

  let list: LabelListingProps['list'] = !allowAllLabel ? [] : [
    {
      id: 0,
      label: 'All',
      onClick: changeLabel,
      active: !inAboutPage && labelId == 0,
    }
  ];

  try {

    let labelsList: LabelListingProps['list'] = list.concat(labelsQuery?.data?.data?.map((item: any) => ({
      id: Number(item.id),
      label: item.attributes.name,
      hide: false,
      active: item.id == labelId,
      onClick: changeLabel
    })) ?? []);


    let createGroupItem: LabelListingProps['list'][number] = {
      id: -1,
      label: '+',
      hide: !isMyProfile,
      pin: 'start',
      onClick: () => {
        navigate(`Catalogue/${designerId}${labelsParamsWithSaveDefault}`);
      },
      alt: 'Create New Collection'
    }

    let handleOpenItem: LabelListingProps['list'][number] = {
      id: list.length + 1,
      label: '+',
      pin: 'end',
      hide: !isMyProfile,
      onClick: onCreate,
      alt: 'Create New Label'
    }

    list = [createGroupItem, ...labelsList, handleOpenItem];

  } catch (error) {
    console.error(error);
  }

  return list;
}


function DesignerHomeLayout({ children }: { children: any }) {


  const {
    labelId,
    category,
    designerId,
    categoryQueryParam,
  } = useParsedQueryParams();

  const classes = useStyles();

  const [addLabelDialogOpen, setAddLabelDialogOpen] = useState(false);

  const { mutate: createCollection } = useCreateCollection({ labelId, designerId });

  const displayCollectionsAsList = !categoryQueryParam;

  const isMyProfile = useIsMyProfile({ id: designerId });
  const isDesigner = useIsDesigner();

  const profileQuery = useProfile({ id: designerId });
  const profileData = defaultProfile(profileQuery?.data?.data?.attributes);

  const designsQuery = useDesigns({ category, labelId, designerId });
  const collectionsQuery = useCollections({ designerId, category, labelId });


  const navigate = useNavigate();


  const labelsQuery = useLabels({ designerId });

  const { mutate: pinCollection } = usePinCollection({ designerId, labelId: labelId });

  const { mutate: createLabel, error: createLabelError } = useCreateLabel({ designerId });
  const location: any = useSelector((state: RootState) => state.router.location);
  const pathname: string = location.pathname;

  const isUser = useIsUser();


  function changeLabel(labelId: number) {
    if (inCataloguePage)
      navigate(`Catalogue/${designerId}?label_id=${labelId}`);
    else {
      const categoryParam = categoryQueryParam ? `/${categoryQueryParam}` : '';
      navigate(`DesignerHome/${designerId}${categoryParam}?label_id=${labelId}`);
    }
  }

  const inCataloguePage = Boolean(pathname?.toLowerCase()?.includes('catalogue'));
  const inAboutPage = Boolean(pathname?.toLowerCase()?.includes('about'));
  const allowAllLabel = !inCataloguePage;


  const list = useCalculatedLabels({
    designerId,
    labelId,
    inAboutPage,
    changeLabel,
    allowAllLabel,
    onCreate: () => setAddLabelDialogOpen(true),
  });

  const { mutate: followDesigner } = useFollowDesigner({ designerId });
  const { mutate: unfollowDesigner } = useUnfollowDesigner({ designerId });

  const labelsParams = labelId ? `?label_id=${labelId}` : '';


  if (Object.is(NaN, designerId))
    return <div>{'There is no designer id'} </div>;


  return (
    <div>

      <CreateLabelDialog
        open={addLabelDialogOpen}
        onClose={() => {
          setAddLabelDialogOpen(false);
        }}
        onCreate={(labelName) => createLabel({ labelName }, {
          onSuccess: () => {
            setAddLabelDialogOpen(false);
          }
        })}
        error={createLabelError?.[0]?.message}
      />


      <DezinerHeader
        fallbackColor={profileData.color}
        onClick={(category) => {
          navigate(`DesignerHome/${designerId}/${category}${labelsParams}`);
        }}
        title={profileData.first_name}
        subTitle={profileData.profile_info}
        profileImageURL={profileData.profile_thumbnail_url}
        coverImageURL={profileData.cover}
        profileStatistics={[
          { name: 'collections', value: profileData.totel_collections, isActive: categoryQueryParam == 'collections' },
          { name: 'designs', value: profileData.totel_designs, isActive: categoryQueryParam == 'designs' },
          { name: 'liked', value: profileData.totel_like_designs, isActive: categoryQueryParam == 'liked' },
          { name: 'promoted', value: profileData.totel_promoted_designs, isActive: categoryQueryParam == 'promoted' },
          { name: 'inspired', value: profileData.totel_inspired_designs, isActive: categoryQueryParam == 'inspired' },
          { name: 'rated', value: profileData.totel_rated_designs, isActive: categoryQueryParam == 'rated' },
          { name: 'free', value: profileData.totel_free_designs, isActive: categoryQueryParam == 'free' },
          { name: 'paid', value: profileData.totel_paid_designs, isActive: categoryQueryParam == 'paid' },
          { name: 'following', value: profileData.following_count },
          { name: 'followers', value: profileData.follower_count },
        ]}
        loading={profileQuery?.isFetching}
      />

      <HeaderControls>
        <HeaderControls.SideSection flexStart>


          {
            isDesigner && isMyProfile ? (
              <DezinerChangeCoverButton />
            )
              :
              null
          }

          <HorizontalSpacer />

          <DezinerButton
            variant="contained"
            onClick={() => {
              navigate(`DesignerAboutPage/${designerId}`);
            }}
            startIcon={(isHovered: boolean) => <img src={infoIcon} className={clsx({ "white-svg": isHovered || inAboutPage })} />}
            className={clsx(classes.headerFloatingBtn, classes.aboutButton, { [classes.floatButtonActive]: inAboutPage })}
          >
            {'About'}
          </DezinerButton>


        </HeaderControls.SideSection>


        <HeaderControls.MiddleSection>
          <LabelListing list={list} />
        </HeaderControls.MiddleSection>

        <HeaderControls.SideSection flexEnd>


          {
            isUser ?
              <>
                <DezinerButton
                  variant="contained"
                  onClick={() => {
                    if (profileQuery?.data?.data?.attributes?.followed)
                      unfollowDesigner({ designerId });
                    else
                      followDesigner({ designerId });
                  }}
                  startIcon={<AddIcon />}
                  className={clsx(classes.headerFloatingBtn, classes.aboutButton, { [classes.floatButtonActive]: profileData.followed })}
                >
                  {'Follow'}
                </DezinerButton>

                <HorizontalSpacer />

                <DezinerButton
                  variant="contained"
                  onClick={() => {
                  }}
                  startIcon={(isHovered: boolean) => <img src={subscribeIcon} style={{ maxWidth: '20px' }} className={clsx({ "white-svg": isHovered })} />}
                  className={clsx(classes.headerFloatingBtn, classes.aboutButton, { [classes.floatButtonActive]: false })}
                >
                  {'Subscribe'}
                </DezinerButton>
              </>
              :
              null
          }

        </HeaderControls.SideSection>

      </HeaderControls>


      {React.cloneElement(children, {
        layoutProps: ({
          designs: category == "collections" ?
            (collectionsQuery?.data?.data ?? []) :
            (designsQuery?.data?.data ?? []),
          loading: designsQuery?.isFetching || collectionsQuery?.isFetching || false,
          profile: profileQuery?.data?.data?.attributes,
          labels: [
            allowAllLabel ? {
              "id": "0",
              "type": "labels",
              "attributes": {
                "id": 0,
                "name": "All",
                "club_id": null,
                "group_id": null,
                "label_type": null,
                "created_at": "2022-09-26T10:18:51.675Z",
                "updated_at": "2022-09-26T10:18:51.675Z"
              }
            } : undefined
          ]
            .filter(x => x)
            .concat(labelsQuery?.data?.data),
          labelId: labelId,
          changeLabel: changeLabel,
          category: category,
          createCollection: (params) => createCollection({
            ...params,
            labelId,
          }),
          pinCollection: pinCollection,
          openAddLabelDialog: () => {
            setAddLabelDialogOpen(true)
          },
          displayCollectionsAsList,
        } as DesignerHomeLayoutProps)
      })}


      {/* 

   <DesignerPromotePopUpWeb
          designerId={profileData.id}
          currentImage={profileData.profile_url }
          modalOpen={false}
          //modalOpen={promoteProfileDialogOpen}
          handleModalClose={() => this.setState({
            promoteProfileDialogOpen: false,
          })}
          navigation={this.props.navigation}
          id={""}
          promoteType="Designer"
          promoteCollectionId={0}
          designId={0}
        />

      */}

    </div>
  );


}



export default DesignerHomeLayout
