import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import "./style.css";
// Customizable Area End

import ChangeCoverController, {
  Props,
} from "./ChangeCoverController.web";
import CollectionListing from "./CollectionListing.web";
import DezinerButton from "../../../components/src/Button.web";

class ChangeCover extends ChangeCoverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    let userBg = '';
    const encodeImageFileAsURL = (element: any) => {
      return new Promise((resolve, reject) => {
        var file = element.files[0];
        var reader = new FileReader();
        reader.onloadend = function() {
          let imagebase64 = reader.result;
          resolve(imagebase64);
        };
        reader.readAsDataURL(file);
      });
    }
    const { classes } = this.props;
    return (
      <>

        <DezinerButton
          variant="contained"
          onClick={() => {
            if (this.inputFileRef && this.inputFileRef.current) {
              this.inputFileRef.current.click();
            }
          }}
          startIcon={<CameraAltIcon />}
          className={classes.headerFloatingBtn}
        >
          {'Change Cover'}
        </DezinerButton>

        {/*
        <label
          htmlFor="upload__bg__image"
          className={classes.upload__bg__image}
        >
          <span className={classes.btnText}> Change Cover </span>{" "}
          <CameraAltIcon />
        </label>
*/}
        <input
          ref={this.inputFileRef}
          style={{
            display: "none",
          }}
          type="file"
          accept="image/*"
          onChange={(e) => {
            e.preventDefault();
            encodeImageFileAsURL(e.target).then((res: any) => {
              userBg = `url(${res})`;
              this.setState({ coverImage: userBg });
              this.changeCover(res);
            });
          }}
        />
      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = {
  btnText: { marginRight: "5px", whiteSpace: 'nowrap' },
  headerFloatingBtn: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
    '&:hover': {
      background: 'black',
      color: 'white',
    },
  },

};
export default withStyles(useStyles)(ChangeCover);
// Customizable Area End
