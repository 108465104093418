// @ts-nocheck
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { logo, cloud, plusButton } from "./assets";
import { ToastContainer } from "react-toastify";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { UserBackground } from "../../catalogue/src/assets";
import HeaderUserController, { Props } from "./HeaderUserController.web";
import TopHeaderUserWeb from "./TopHeaderUser.web";
import ChangeCoverWeb from "../../catalogue/src/ChangeCover.web";
import LabelListing, { LabelListingProps } from "../../catalogue/src/LabelListing.web";

class HeaderUser extends HeaderUserController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, profileData } = this.props;
    const userBg =
      profileData.cover !== ""
        ? `url(${profileData.cover})`
        : '';
    const userProfileImg = profileData.profile_url;
    const selfUserProfileImg = this.state.selfProfileData.profile_url;

    const list: LabelListingProps['list'] = this.props.arrLabels
      .map(({ name, ...rest }) => ({ label: name, ...rest }))
      .map(item => ({
        ...item,
        onClick: this.changeLabel,
        active: item.id == this.state.currentLabel
      }));


    return (
      <div>
        <TopHeaderUserWeb
          navigation={this.props.navigation}
          userProfileImg={selfUserProfileImg}
          profileColor={this.state.selfProfileData.color}
          clubName=''
          groupName=''
        />
        <div className={classes.headerCover} style={{ background: this.state.coverImg }}>
          <div className={classes.userBgImg}>
            <div className={classes.userData}>
              <div className={classes.userImg}>
                {userProfileImg === '' ? (
                  <div
                    style={{ background: profileData.color }}
                    className={classes.userImage}
                  />
                ) : (
                  <img
                    src={userProfileImg}
                    alt="userDefaultImage"
                    className={classes.userImage}
                  />
                )}
              </div>
              <div className={classes.userDetail}>
                <h3 className={classes.userName}>{profileData.first_name}</h3>
                <span className={classes.userRole}>{profileData.role}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="top-container">
          <ChangeCoverWeb
            changeCurrentBg={(bgImg: any) => this.setState({ coverImg: bgImg })}
            id={0}
            type={'user'}
          />
          {/* <div className="upload-bg-img">
            <span style={{ marginRight: "5px" }}> Change Cover </span>{" "}
            <CameraAltIcon />
          </div> */}

          <LabelListing list={list} />

        </div>
      </div>
    );
  }
}

const useStyles = {
  navbar: {
    height: "70px",
    background: "black",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    verticalAlign: "middle",
  },
  logo: {
    height: "50px",
    width: "150px",
  },
  rightBar: {
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: "0.06",
  },
  navIcon: {
    width: "25px",
    height: "25px",
  },
  headerCover: {
    width: "100%",
    minHeight: "250px",
    // backgroundColor: 'lightblue',
    justifyContent: "center",
  },
  userBgImg: {
    backgroundImage: `url(${UserBackground})`,
    padding: "5% 0",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  userData: {
    display: "grid",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
  },
  userImage: {
    height: "100px",
    width: "100px",
    borderRadius: "40%",
  },
  userDetail: {
    margin: "0px 40px",
    textAlign: 'center'
  },
  userName: {
    margin: "10px 0",
    textAlign: "center",
    fontWeight: "bold",
  },
  userRole: {
    margin: "10px 0",
    textAlign: "center",
    textTransform: "uppercase",
  },
  userFollowers: {
    margin: "10px 0",
  },
  subInfo: {
    marginRight: "10px",
  },
  userActions: {
    textAlign: "center",
    marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto auto auto auto",
    justifyContent: "center",
  },
  userActionSpan: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    borderRight: "1px solid black",
    cursor: "pointer",
  },
  userActionSpanLast: {
    borderRight: "none",
  },
  iconSpan: {
    marginRight: "10px",
  },
  userIcon: {
    height: "50px",
    width: "50px",
    borderRadius: "40%",
  },
  userImg: {
    textAlign: "center",
  },
  arrLabelsIcons: {
    border: "none",
    height: "20px",
    width: "20px",
    marginRight: "5px",
  },
};

export default withStyles(useStyles)(HeaderUser);
