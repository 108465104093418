import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  profileImgUrl: string;
  designData: any;
  loading: boolean;
  currentImage: string;
  currentImageType: string;
  designImages: any;
  comment: string;
  currentDesign: number;
  comments: any;
  moreDesigns: any;
  dialogStatus: boolean;
  collections: Array<String>;
  selectedCollection: any;
  collectionForm: boolean;
  collectionName: string;
  privacy: boolean;
  showRating: boolean;
  userRating: number;
  inspireAccounts: any;
  profileRole: string;
  shareDialogStatus: boolean;
  shareLink: string;
  shareDesignLink: string;
  loadingShare: boolean;
  audioURL: string;
  isRecording: boolean;
  recorder: any;
  attachment: any;
  addReply: number;
  replyComment: string;
  replyCommentAudio: string;
  replyCommentAttachment: any;
  totalInspires: number;
  profileName: string;
  likesCount: number;
  savedCount: number;
  ratingCount: number;
  liked: boolean;
  saved: boolean;
  commentCount: number;
  mainImage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignViewByUserController extends BlockComponent<
  Props,
  S,
  SS
  > {
  profileMessageId: string;
  designDataMessageId: string;
  commentMessageId: string;
  collectionsMessageId: string;
  saveDesignMessageId: string;
  saveRatingMessageId: string;
  inspiredMessageId: string;
  likeDesignMessageId: string;
  shareDesignMsgApi: string;
  MediaRecorder: any;
  postReplyCommentMessageId: string;
  addInspiredMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.postComment = this.postComment.bind(this);
    this.viewDesign = this.viewDesign.bind(this);
    this.saveDesign = this.saveDesign.bind(this);
    this.saveInNewCollection = this.saveInNewCollection.bind(this);
    this.saveRating = this.saveRating.bind(this);
    this.likeDesign = this.likeDesign.bind(this);
    this.shareDesign = this.shareDesign.bind(this);
    this.handleData = this.handleData.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.postReplyComment = this.postReplyComment.bind(this);
    this.postInspire = this.postInspire.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      profileImgUrl:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      designData: {},
      loading: true,
      currentImage: "",
      currentImageType: "",
      designImages: [],
      comment: "",
      currentDesign: this.props.navigation.getParam("id", 0),
      comments: [],
      moreDesigns: [],
      dialogStatus: false,
      collections: [],
      selectedCollection: '',
      collectionForm: false,
      collectionName: '',
      privacy: false,
      showRating: false,
      userRating: 0,
      inspireAccounts: [],
      profileRole: "",
      shareDialogStatus: false,
      shareLink: '',
      shareDesignLink: '',
      loadingShare: false,
      audioURL: "",
      isRecording: false,
      recorder: null,
      attachment: null,
      addReply: 0,
      replyComment: '',
      replyCommentAudio: '',
      replyCommentAttachment: null,
      totalInspires: 0,
      profileName: "",
      likesCount: 0,
      savedCount: 0,
      ratingCount: 0,
      liked: false,
      saved: false,
      commentCount: 0,
      mainImage: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getProfile();
  }

  componentWillUnmount = (): any => {
    if (this.state.recorder) {
      this.state.recorder.removeEventListener("dataavailable", this.handleData);
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          profileImgUrl: responseJson.data.attributes.profile_url,
          profileRole: responseJson.data.attributes.role,
          profileName: responseJson.data.attributes.first_name,
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // DESIGN DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designDataMessageId != null &&
      this.designDataMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        // DESIGN DATA
        // CURRENT IMAGE
        // SUB IMAGES
        const arrImages = [];
        arrImages.push({
          url: responseJson.design.data.attributes.image_url,
          type: 'image/'
        });
        if (responseJson.design.data.attributes.sub_designs.length > 0) {
          responseJson.design.data.attributes.sub_designs.map((item: any) => {
            arrImages.push(item);
          });
        }

        // COMMENTS
        const arrComments: any = [];
        if (responseJson.design.data.attributes.comment_count > 0) {
          for (
            let i = 0;
            i < responseJson.design.data.attributes.comment_count;
            i++
          ) {
            // if (i >= 3) break;
            /*
                        if (i in responseJson.design.data.attributes.comments.data) {
                          arrComments.push(
                            responseJson.design.data.attributes.comments.data[i].attributes
                            );
                          }
            */
          }
        }

        // MORE DESIGNS
        const arrMoreDesigns: any = [];
/*
        if (
          "data" in responseJson.design.data.attributes.more_designs &&
          responseJson.design.data.attributes.more_designs.data.length > 0
        ) {
          for (
            let i = 0;
            i < responseJson.design.data.attributes.more_designs.data.length;
            i++
          ) {
            if (i >= 4) break;
            arrMoreDesigns.push(
              responseJson.design.data.attributes.more_designs.data[i]
                .attributes
            );
          }
        }
*/
        this.setState({
          designData: responseJson.design.data.attributes,
          currentImage: responseJson.design.data.attributes.image_url,
          currentImageType: "image",
          designImages: arrImages,
          comments: arrComments,
          commentCount: responseJson.design.data.attributes.comment_count,
          moreDesigns: arrMoreDesigns,
          likesCount: responseJson.design.data.attributes.like_count,
          savedCount: responseJson.design.data.attributes.save_designs_count,
          ratingCount: responseJson.design.data.attributes.rating_count,
          liked: responseJson.design.data.attributes.liked,
          saved: responseJson.design.data.attributes.saved,
          mainImage: responseJson.design.data.attributes.image_url
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading: false });
    }

    // POST COMMENT API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.commentMessageId != null &&
      this.commentMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ comments: [responseJson.data.attributes, ...this.state.comments] });
        this.setState({ comment: "", commentCount: this.state.commentCount + 1 });
        toast.success("Comment Posted Successfull");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(errorReponse);
      }
    }

    // POST REPLY COMMENT API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postReplyCommentMessageId != null &&
      this.postReplyCommentMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrComments: any = [];
        this.state.comments.map((item: any) => {
          if (item.id === this.state.addReply) {
            item.replies.data.push(responseJson.data);
            arrComments.push(item);
          } else {
            arrComments.push(item);
          }
        });
        this.setState({
          addReply: 0,
          replyComment: "",
          replyCommentAttachment: null,
          replyCommentAudio: "",
          comments: arrComments,
          commentCount: this.state.commentCount + 1
        });
        toast.success("Comment Posted Successfull");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error(errorReponse);
      }
    }

    // GET COLLECTIONS
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.collectionsMessageId != null &&
      this.collectionsMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ collections: responseJson.data });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveDesignMessageId != null &&
      this.saveDesignMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ dialogStatus: false });
      this.setState({ collectionForm: false });
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        // const designData = this.state.designData;
        // designData.saved = true;
        this.setState({
          saved: true,
          savedCount: this.state.savedCount + 1
        });
        toast.success("Saved Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        const msg = responseJson.errors[0].message;
        toast.error(msg);
      }
    }

    // Save Rating
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveRatingMessageId != null &&
      this.saveRatingMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ showRating: false });
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ ratingCount: responseJson.data.attributes.avg_rating });
        toast.success("Submitted Successfully");
      } else {
        var errorReponse: any = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        const msg = responseJson.errors[0].message;
        toast.error(msg);
      }
    }

    // GET INSPIRED ACCOUNT
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.inspiredMessageId != null &&
      this.inspiredMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          inspireAccounts: responseJson.data,
          totalInspires: responseJson.data.length,
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // GET INSPIRED ACCOUNT
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addInspiredMessageId != null &&
      this.addInspiredMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (responseJson.message === 'Inspired') {
          const cuid = localStorage.getItem("cuid");
          const dataInspiredAcc = [
            {
              id: cuid,
              attributes: {
                first_name: this.state.profileName,
                profile_url: this.state.profileImgUrl,
              }
            },
            ...this.state.inspireAccounts,
          ];
          this.setState({
            inspireAccounts: dataInspiredAcc,
            totalInspires: this.state.totalInspires + 1,
          });
        } else {
          const cuid = localStorage.getItem("cuid");
          const dataInspiredAcc = this.state.inspireAccounts.filter((item: any) => item.id != cuid);
          this.setState({
            inspireAccounts: dataInspiredAcc,
            totalInspires: this.state.totalInspires - 1,
          });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // LIKE DESIGN
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.likeDesignMessageId != null &&
      this.likeDesignMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const likeCount = responseJson.message === "Like" ? this.state.likesCount + 1 : this.state.likesCount - 1;
        this.setState({
          liked: responseJson.message === "Like" ? true : false,
          likesCount: likeCount
        });
        toast.success(`${responseJson.message} Successfully`);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        const msg = responseJson.errors[0].message;
        toast.error(msg);
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.shareDesignMsgApi != null &&
      this.shareDesignMsgApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          shareDesignLink: responseJson.data.attributes.attachment_url,
          shareLink: responseJson.meta.share_url,
          shareDialogStatus: true,
          loadingShare: false
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    this.getDesignData();
    this.getCollections();
    // this.getInspired();
    return true;
  }

  // Customizable Area Start
  getDesignData(): boolean {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    let url = `${configJSON.designApi}/${this.state.currentDesign}`;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });

    this.designDataMessageId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    this.getInspired();
    return true;
  }

  handleImageClick(e: any, type: string, url: string) {
    this.setState({
      currentImage: url,
      currentImageType: type
    });
    const collection: any = document.getElementsByClassName("subImages");
    for (let i = 0; i < collection.length; i++) {
      collection[i].classList.remove("active");
    }
    e.target.classList.add("active");
  }

  postComment() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          comment: this.state.comment,
          commentable_type: "BxBlockAttachment::Design",
          commentable_id: this.state.currentDesign,
          audios: this.state.audioURL !== '' ? [{ data: this.state.audioURL }] : [],
          attachments: this.state.attachment ? [{ data: this.state.attachment }] : []
        },
      },
    };

    const postComment = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.postComment,
    });

    this.commentMessageId = postComment.messageId;
    runEngine.sendMessage(postComment.id, postComment);
    return true;
  }

  viewDesign(id: number) {
    this.setState({ currentDesign: id }, () => this.getDesignData());
  }

  getCollections() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getCollectionsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.collectionsApi,
    });

    this.collectionsMessageId = getCollectionsData.messageId;
    runEngine.sendMessage(getCollectionsData.id, getCollectionsData);
    return true;
  }

  saveDesign() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          design_id: this.state.currentDesign,
          collection_id: this.state.selectedCollection,
          collection_name: ""
        }
      }
    };

    const saveDesignData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.saveDesign,
    });

    this.saveDesignMessageId = saveDesignData.messageId;
    runEngine.sendMessage(saveDesignData.id, saveDesignData);
    return true;
  }

  saveInNewCollection() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          design_id: this.state.currentDesign,
          collection_id: "",
          collection_name: this.state.collectionName,
          privacy: this.state.privacy ? "Private" : "Public"
        }
      }
    };

    const saveDesignData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.saveDesign,
    });

    this.saveDesignMessageId = saveDesignData.messageId;
    runEngine.sendMessage(saveDesignData.id, saveDesignData);
    return true;
  }

  saveRating() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          design_id: this.state.currentDesign,
          point: this.state.userRating
        }
      }
    };

    const submitRatingData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.submitRating,
    });

    runEngine.sendMessage(submitRatingData.id, submitRatingData);
    this.saveRatingMessageId = submitRatingData.messageId;
    return true;
  }

  getInspired() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    const url = `${configJSON.inspiredAccs}/${this.state.currentDesign}/inspire_accounts`;
    const inspiredData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });


    this.inspiredMessageId = inspiredData.messageId;
    runEngine.sendMessage(inspiredData.id, inspiredData);
    return true;
  }

  likeDesign() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          likeable_type: "BxBlockAttachment::Design",
          likeable_id: this.state.currentDesign
        }
      }
    };

    const submitRatingData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.likeDesign,
    });

    runEngine.sendMessage(submitRatingData.id, submitRatingData);
    this.likeDesignMessageId = submitRatingData.messageId;
    return true;
  }

  shareDesign() {
    this.setState({ loadingShare: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const designId = this.props.navigation.getParam("id", "");
    const httpBody = {
      data: {
        attributes: {
          sharable_type: "BxBlockAttachment::Design",
          sharable_id: designId
        }
      }
    };
    let url = configJSON.designSharingLink;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: url,
    });

    this.shareDesignMsgApi = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
  }

  handleData(e: any) {
    e.preventDefault();
    this.encodeAudioAsURL(e).then((res: any) => {
      // this.setState({ attachment: res });
      if (this.state.addReply !== 0) {
        this.setState({ replyCommentAudio: res });
      } else {
        this.setState({ audioURL: res });
      }
    });
  };

  startRecording(): void {
    this.setState({ isRecording: true });
    if (this.state.recorder === null) {
      this.requestRecorder()
        .then((res: any) => this.setState({ recorder: res }))
        .then(() => this.state.recorder.start())
        .then(() => this.state.recorder.addEventListener("dataavailable", this.handleData));
    } else {
      this.state.recorder.start();
    }
  };

  stopRecording(): void {
    this.setState({ isRecording: false });
    this.state.recorder.stop();
  };

  async requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    // @ts-ignore: Unreachable code error
    return new MediaRecorder(stream);
  }

  encodeImageFileAsURL(element: any) {
    return new Promise((resolve, reject) => {
      var file = element.files[0];
      if (file) {
        var reader = new FileReader();
        reader.onloadend = function() {
          let imagebase64 = reader.result;
          resolve(imagebase64);
        };
        reader.readAsDataURL(file);
      } else {
        reject(null);
      }
    });
  };

  encodeAudioAsURL(element: any) {
    return new Promise((resolve, reject) => {
      var file = element.data;
      if (file) {
        var reader = new FileReader();
        reader.onloadend = function() {
          let imagebase64 = reader.result;
          resolve(imagebase64);
        };
        reader.readAsDataURL(file);
      } else {
        reject(null);
      }
    });
  };

  postReplyComment() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          comment: this.state.replyComment,
          audios: this.state.replyCommentAudio !== '' ? [{ data: this.state.replyCommentAudio }] : [],
          attachments: this.state.replyCommentAttachment ? [{ data: this.state.replyCommentAttachment }] : []
        },
      },
    };

    const postComment = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.postComment}/${this.state.addReply}/reply`,
    });

    this.postReplyCommentMessageId = postComment.messageId;
    runEngine.sendMessage(postComment.id, postComment);
  }

  postInspire() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          design_id: this.state.currentDesign
        },
      },
    };

    const postComment = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.inspireAcc,
    });

    this.addInspiredMessageId = postComment.messageId;
    runEngine.sendMessage(postComment.id, postComment);
  }
  // Customizable Area End
}
