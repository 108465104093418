import React from "react";
// Customizable Area Start
import UserHomeController, { Props } from "./UserHomeController.web";
import LandingPageContent from "../../../components/src/LandingPageContent.web";
// Customizable Area End


class UserHome extends UserHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
     <LandingPageContent/> 
    );
  }
}




// Customizable Area End
export default UserHome;
// Customizable Area End
