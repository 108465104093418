import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
// Customizable Area End

import GroupCreateCollectionController, {
  Props,
} from "./GroupCreateCollectionController.web";
import ClubHeader from "../../Groups/src/ClubHeader.web";
import Footer from "./Footer.web";
import CollectionListing from "./CollectionListing.web";
import FooterWeb from "./Footer.web";
import CreateLabelDialog from "../../landingpage/src/CreateLabelDialog.web";

class GroupCreateCollection extends GroupCreateCollectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <ClubHeader
          profileData={this.state.profileData}
          clubData={this.state.clubData}
          handleOpen={this.handleOpen}
          arrLabels={this.state.arrLabels}
          currentLabel={this.state.currentLabel}
          navigation={this.props.navigation}
          changeLabel={this.changeLabel}
          clubId = {this.props.navigation.getParam("id", "")}
        />
        <div className="main-container">
          <div className='collectionCreationForm'>
            <div className='formIputLeft'>
              <input type="text" value={this.state.newCollectionName} placeholder="Your collection name" onChange={(e) => this.setState({ newCollectionName: e.target.value })} name="collectionName" className='inputField' />
              <Button variant="contained" className='customButton' onClick={() => this.createCollection()}>Create</Button>
            </div>
            <div className='formInputRight'>
            <FormControlLabel
              labelPlacement="end"
              control={<Checkbox name="private" color="primary" />}
              label="Keep this collection private"
              onChange={(e, checked) => this.setState({ privateCollection: checked })}
            />
            </div>
          </div>
          <div className="collectionComponent">
          {!this.state.loading ?
            Object.keys(this.state.arrCollection).length > 0 ? (
              Object.keys(this.state.arrCollection).map((key) => {
                return <CollectionListing
                  collection={this.state.arrCollection[key].attributes}
                  createPost={this.createPost}
                  navigation={this.props.navigation}
                  allowAddition
                  pinCollection={(collectionId: number, flag: boolean) => this.pinCollection(collectionId, flag)}
                />
              })
            ) :
            (
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h2>No Data</h2>
              </div>  
            ): (
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            )}
          </div>

          <CreateLabelDialog
            open={this.state.popupStatus}
            onClose={this.handleClose}
            onCreate={() => this.createLabel()}
          />

        </div>
        <Footer
          navigation={this.props.navigation}
        />
      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = {};
export default withStyles(useStyles)(GroupCreateCollection);
// Customizable Area End
