import React, { useState, useRef, useEffect } from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ChatIcon from "@material-ui/icons/Chat";
import SearchIcon from "@material-ui/icons/Search";
import { darkLogo } from "./assets";
import "./TopHeaderUser.web.css";
// Customizable Area End
import { getCountry } from "react-native-localize";

const DropdownMenu = (props: any) => {
  const dropdownRef = useRef<HTMLInputElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const onClick = () => props.navigation.navigate("UserEditProfile");

  return (
    <div className="menu-container">
      <button onClick={onClick} className="menu-trigger">
        {props.image === '' ? (
          <div
            style={{
              background: props.profileColor,
              height: "50px",
              width: "50px",
              borderRadius: "40%"
            }}
          />
        ) : (
          <img
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "40%"
            }}
            src={props.image}
            alt="userProfileImage"
          />
        )}
      </button>
    </div>
  );
};

const TopHeaderUser = (props: any) => {
  const { classes, userProfileImg } = props;
  const userImg = userProfileImg;
  const tmpRole = localStorage.getItem("currentDesignerPage")
    ? localStorage.getItem("currentDesignerPage")
    : "Myself";
  const [currentRole, setCurrentRole] = useState(tmpRole);
  const navigateToHome = () => {
    if (localStorage.getItem('currentUserRole') === 'Designer' || props.userRole === "Designer") {
      return props.navigation.navigate("DesignerHome");
    } else {
      return props.navigation.navigate("UserHome");
    }
  };
  return (
    <nav className={classes.navbar}>
      <img
        src={darkLogo}
        style={{ cursor: "pointer" }}
        onClick={navigateToHome}
        alt="logo"
        className={classes.logo}
      />
      <div className={classes.rightBar}>
        <SearchIcon style={{ color: "white" }} />
        <NotificationsIcon style={{ color: "white" }} />
        <ChatIcon style={{ color: "white" }} />
        {/* <img src={userProfileImg} alt="userProfileImage" className={classes.userIcon}/> */}
        <DropdownMenu navigation={props.navigation} image={userImg} profileColor={props.profileColor} />
      </div>
    </nav>
  );
};

const useStyles: any = {
  navbar: {
    height: "70px",
    background: "black",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    verticalAlign: "middle"
  },
  logo: {
    height: "50px",
    width: "150px"
  },
  rightBar: {
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: "0.06"
  },
  navIcon: {
    width: "25px",
    height: "25px"
  },
  userIcon: {
    height: "50px",
    width: "50px",
    borderRadius: "40%"
  }
};

export default withStyles(useStyles)(TopHeaderUser);
