
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  profileImgUrl: string;
  designData: any;
  loading: boolean;
  currentImage: string;
  currentImageType: string;
  designImages: any;
  dialogStatus: boolean;
  impressions: number;
  duration: number;
  step: number;
  shareDialogStatus: boolean;
  shareLink: string;
  shareDesignLink: string;
  loadingShare: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClubDesignView extends BlockComponent<Props, S, SS> {
  profileMessageId: string;
  designDataMessageId: string;
  shareDesignMsgApi: string;
  marks: any = [
    {
      value: 1000,
      label: '1,000'
    },
    {
      value: 100000,
      label: '1,00,000 impressions'
    }
  ];
  marksMonth: any = [
    {
      value: 1,
      label: '1 Month'
    },
    {
      value: 12,
      label: '12 Months'
    }
  ];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.promoteDesign = this.promoteDesign.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.backButton = this.backButton.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.shareDesign = this.shareDesign.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      profileImgUrl: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
      designData: {},
      loading: true,
      currentImage: '',
      currentImageType: '',
      designImages: [],
      dialogStatus: false,
      impressions: 1000,
      duration: 1,
      step: 1,
      shareDialogStatus: false,
      shareLink: '',
      shareDesignLink: '',
      loadingShare: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getProfile();
    this.getDesignData();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // PROFILE DATA API RESPONSE
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ profileImgUrl: responseJson.data.attributes.profile_url });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // DESIGN DATA API RESPONSE
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designDataMessageId != null &&
      this.designDataMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ designData: responseJson.design.data.attributes });
        this.setState({ currentImage: responseJson.design.data.attributes.image_url, currentImageType: 'image' });
        const arrImages = [];
        arrImages.push({
          url: responseJson.design.data.attributes.image_url,
          type: 'image/'
        });
        if(responseJson.design.data.attributes.sub_designs.length > 0){
          responseJson.design.data.attributes.sub_designs.map((item: any) => {
            arrImages.push(item);
          });
        }
        this.setState({ designImages: arrImages });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading: false });
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.shareDesignMsgApi != null &&
      this.shareDesignMsgApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          shareDesignLink: responseJson.data.attributes.attachment_url,
          shareLink: responseJson.meta.share_url,
          shareDialogStatus: true,
          loadingShare: false
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  // Customizable Area Start
  getDesignData(): boolean {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {};
    const designId = this.props.navigation.getParam("id" , "");
    let url = `${configJSON.designApi}/${designId}`;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: url,
    });

    this.designDataMessageId = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    return true;
  }

  handleImageClick(e: any, type: string, url: string) {
    this.setState({
      currentImage: url,
      currentImageType: type
    });
    const collection:any = document.getElementsByClassName("subImages");
    for (let i = 0; i < collection.length; i++) {
      collection[i].classList.remove("active");
    }
    e.target.classList.add("active");
  }

  promoteDesign(img: string) {
    this.setState({ currentImage: img });
    this.setState({ dialogStatus: true });
  }

  closeDialog() {
    this.setState({ dialogStatus: false });
  }

  valuetext(value: any) {
    return `${value}`;
  }
  valuetextMonth(value: any) {
    return `${value} Month`;
  }

  handleSliderChange(event: any, newValue: any) {
    this.setState({ impressions: newValue });
  };

  handleMonthChange(event: any, newValue: any) {
    this.setState({ duration: newValue });
  };

  backButton() {
    if(this.state.step === 1) {
      this.setState({ dialogStatus: false });
    } else {
      this.setState({ step: 1 });
    }
  }

  nextButton() {
    if(this.state.step === 1) {
      this.setState({ step: 2 });
    } else {
      this.setState({ dialogStatus: false });
    }
  }

  shareDesign() {
    this.setState({ loadingShare: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const designId = this.props.navigation.getParam("id" , "");
    const httpBody = {
      data: {
        attributes: {
          sharable_type: "BxBlockAttachment::Design",
          sharable_id: designId
        }
      }
    };
    let url = configJSON.designSharingLink;
    const getDesignDataApi = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: url,
    });

    this.shareDesignMsgApi = getDesignDataApi.messageId;
    runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
  }

  // Customizable Area End
}
