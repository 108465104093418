import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import React from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  changeCurrentBg: any;
  id: any;
  type: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  coverImage: any;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ChangeCoverController extends BlockComponent<Props, S, SS> {
  changeCoverMessageId: string;

  inputFileRef: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.changeCover = this.changeCover.bind(this);

    this.inputFileRef = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      coverImage: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      // this.props.navigation.addListener("willFocus", () => {
      //   this.getToken();
      // });
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.changeCoverMessageId != null &&
      this.changeCoverMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success("Cover Updated Successfully");


        this.props.changeCurrentBg(this.state.coverImage);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    

    // Customizable Area End
  }

  // Customizable Area Start
  changeCover = (cover: any) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          cover: {
            data: cover
          },
        },
      },
    };
    // const httpBody = {};
    let url = configJSON.changeCoverDesigner;
    if (this.props.type === 'club') {
      url = `${configJSON.getClubApi}/${this.props.id}/change_cover`;
    } else if (this.props.type === 'group') {
      url = `${configJSON.groupApi}/${this.props.id}/change_cover`;
    }
    const addNewCollection = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.putMethod,
      url: url,
    });

    this.changeCoverMessageId = addNewCollection.messageId;
    runEngine.sendMessage(addNewCollection.id, addNewCollection);
    return true;
  }

  // Customizable Area End
}
