import React, { useState } from "react";
// Customizable Area Start
import "../../catalogue/src/style.css";
import "./CommunityForum.web.css";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import ForumSingleController, { Props } from "./ForumSingleController.web";
import CommentInput from "../../landingpage/src/CommentInput.web";
import Comment from "../../landingpage/src/Comment.web";
import Divider from '@material-ui/core/Divider';
import { DezinerLabel } from "../../../components/src/TextField.web";
import DezinerSpinner from "../../../components/src/DezinerSpinner.web";
import NeedLoginDialog from "../../landingpage/src/NeedLoginDialog.web";
import { useParams } from "../../../components/src/utils";
import { useForumsComments, useIsLoggedIn, useNavigate, usePostCommentForum, usePostReplyForum, useSingleForum } from "../../../components/src/hooks";
import { ForumCardContainer } from "./CommunityForum.web";
import { DesignComment } from "../../../components/src/api-types";
// Customizable Area End



class ForumSingle extends ForumSingleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      <ForumSingleContent />
    );
  }
}



const useStyles = makeStyles({
  root: {
    maxWidth: '1100px',
    margin: '40px auto',
    padding: '0 20px',
    width: '100%',
    ['@media screen and (max-width: 768px)']: {
      padding: '0 5px',
    }
  }
});

function ForumSingleContent() {

  const [needLoginOpen, setNeedLoginOpen] = useState(false);
  const params = useParams();
  const forumId = parseInt(params.id);

  const navigate = useNavigate();
  const forumQuery = useSingleForum({ id: forumId });

  const isLoggedIn = useIsLoggedIn();
  const comments = useForumsComments({ id: forumId });

  const { mutate: postComment } = usePostCommentForum({ forumId });

  const classes = useStyles();


  return (
    <>

      <NeedLoginDialog
        open={needLoginOpen}
        onClose={() => {
          setNeedLoginOpen(false);
        }}
        onLogin={() => {
          localStorage.setItem('redirectUrl', `ForumSingle`);
          localStorage.setItem('redirectParams', JSON.stringify({ id: forumId }))
          navigate('EmailAccountLoginBlock');
        }}
      />


      {
        forumQuery?.isLoading ?
          <DezinerSpinner />
          :
          <div className={classes.root}>

            <Grid container justifyContent="space-between" alignItems="flex-start">

              <Grid item sm={7} xs={12}>
                <ForumCardContainer
                  singlePageMode
                  forum={forumQuery?.data?.forum?.data}
                  onAnonymousInteraction={() => setNeedLoginOpen(true)}
                />
              </Grid>
              <Grid item sm={4} xs={12}>

                <DezinerLabel>
                  {'Comments'}
                </DezinerLabel>

                <CommentInput
                  onComment={({ commentText, base64Image, base64Audio, }) => {
                    if (isLoggedIn)
                      postComment({ commentText, forumId, audio: base64Audio, attachement: base64Image })
                    else
                      setNeedLoginOpen(true);
                  }}
                />

                <Divider />


                {
                  comments?.data?.data?.map((comment) =>
                    <CommentContainer
                      comment={comment}
                      forumId={forumId}
                      onAnonymousInteraction={() => setNeedLoginOpen(true)}
                    />
                  ) ?? []
                }

              </Grid>
            </Grid>
          </div>
      }

    </>
  );
}

function CommentContainer({
  comment,
  forumId,
  onAnonymousInteraction,
}: {
  comment: DesignComment,
  forumId: number,
  onAnonymousInteraction: () => void,
}) {

  const isLoggedIn = useIsLoggedIn();

  const { mutate: postReply } = usePostReplyForum({
    forumId,
    parentCommentId: comment.attributes.id,
  });

  return (
    <Comment
      replyPlaceholder="Write reply"
      key={comment.id}
      comment={comment.attributes}
      onReply={({ replyText, parentCommentId, base64Audio, base64Image, }) => {
        if (isLoggedIn)
          postReply({
            parentCommentId,
            replyText,
            audio: base64Audio,
            attachement: base64Image,
          });
        else
          onAnonymousInteraction();
      }}
    />
  );
}


// Customizable Area Start
export default ForumSingle;
// Customizable Area End
