import { DezinerActionCreator } from "../store";
import { UIState } from "./ui.reducer";

export const SET_CREATE_DIALOG_STATE = 'SET_CREATE_DIALOG_STATE';

export type SetCreateDialogStateAction = UIState['dialogs']['createLabelDialog'] & {
  type: typeof SET_CREATE_DIALOG_STATE
}

export const setCreateDialogState: DezinerActionCreator<SetCreateDialogStateAction> =
  (params) => ({
    type: SET_CREATE_DIALOG_STATE,
    ...params,
  });

///////////

