import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { appendJsonContentType, appendToken, defineMessageHandlers } from "../../../components/src/utils";
// Customizable Area Start
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  commentData: any;
  profileData: any;
  isReply: boolean;
  selectedReply: number;
  currentForum: any;
  isLoggedIn: any;
  needLoginOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ForumSingleController extends BlockComponent<
  Props,
  S,
  SS
  > {
  profileMessageId: string;
  forumDataMessageId: string;
  commentsMessageId: string;
  addCommentMessageId: string;
  addReplyMessageId: string;
  likePostMessageId: string;
  dislikePostMessageId: string;
  postCommentMessageId: string;
  postReplyCommentMessageId: string;


  currentForumId: string;

  constructor(props: Props) {
    super(props);

    this.currentForumId = this.props.navigation.getParam("id", 0);

    this.receive = this.receive.bind(this);
    this.likePost = this.likePost.bind(this);
    this.dislikePost = this.dislikePost.bind(this);
    this.postComment = this.postComment.bind(this);
    this.postReplyComment = this.postReplyComment.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loading: false,
      commentData: [],
      isReply: false,
      selectedReply: 0,
      profileData: {},
      currentForum: {},
      isLoggedIn: localStorage.getItem("role"),
      needLoginOpen: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    if (this.state.isLoggedIn) {
      this.fetchProfile();
    }
    this.getSingleForum();
    this.getComments();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start


    defineMessageHandlers({
      message,
      handlers: {
        [this.profileMessageId]: {
          onSuccess: (responseJson) => {
            this.setState({ profileData: responseJson.data.attributes });
          },
          onError: () => { },
        },
        [this.forumDataMessageId]: {
          onSuccess: (responseJson) => {
            this.setState({ loading: false });
            this.setState({
              currentForum: responseJson.forum.data.attributes,
            });

          },
          onError: () => { },
        },
        [this.commentsMessageId]: {
          onSuccess: (responseJson) => {
            this.setState({ commentData: responseJson.data });
          },
          onError: () => { },
        },
        [this.likePostMessageId]: {
          onSuccess: (responseJson) => {
            this.getSingleForum(true);
          },
          onError: () => { },
        },
        [this.dislikePostMessageId]: {
          onSuccess: (responseJson) => {
            this.getSingleForum(true);
          },
          onError: () => { },
        },
        [this.postCommentMessageId]: {
          onSuccess: (responseJson) => {
            this.setState({
              commentData: [...this.state.commentData, responseJson.data],
            });
          },
          onError: () => { },
        },
        [this.postReplyCommentMessageId]: {
          onSuccess: (responseJson) => {

            console.log({
              commentId: this.state.selectedReply,
              responseJson,
              commentDate: this.state.commentData
            })

            this.setState(oldState => ({
              ...oldState,
              commentData: oldState.commentData.slice()
                .map((comment: any) => {
                  if (comment.id == oldState.selectedReply) {
                    comment.attributes.replies.data = [...comment.attributes.replies.data, responseJson.data];
                  }
                  return comment;
                }),
            }));
          },
          onError: () => { },
        },
      }
    });


    // Customizable Area End
  }

  // Customizable Area Start

  fetchProfile() {

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.profileMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getSingleForum(preventLoading?: boolean) {

    if (!preventLoading)
      this.setState({ loading: true });

    const message = apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.forums}/${this.currentForumId}`,
    });

    this.forumDataMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getComments(): void {

    const message = apiCall({
      header: appendJsonContentType({}),
      httpBody: {},
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.forums}/${this.currentForumId}/forum_comments`,
    });

    this.commentsMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  likePost() {
    if (!this.state.isLoggedIn) {
      this.setState({ needLoginOpen: true });
      return;
    }

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.saveForum}/${this.currentForumId}/like`,
    });

    this.likePostMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  dislikePost() {
    if (!this.state.isLoggedIn) {
      this.setState({ needLoginOpen: true });
      return;
    }

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.saveForum}/${this.currentForumId}/unlike`,
    });

    this.dislikePostMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  postComment({ commentText }: { commentText: string }): void {
    if (!this.state.isLoggedIn) {
      this.setState({ needLoginOpen: true });
      return;
    }

    const httpBody = {
      data: {
        attributes: {
          comment: commentText,
          commentable_type: "BxBlockCommunityforum::Forum", // This is forum type.
          commentable_id: this.currentForumId,
          audios: [],
          attachments: []
        }
      }
    };

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.postCommentApi}`,
    });

    this.postCommentMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  postReplyComment({ parentCommentId, replyText }: { parentCommentId: number, replyText: string }): void {
    if (!this.state.isLoggedIn) {
      this.setState({ needLoginOpen: true });
      return;
    }

    const httpBody = {
      data: {
        attributes: {
          comment: replyText,
          audios: [],
          attachments: []
        }
      }
    };

    const message = apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.postCommentApi}/${parentCommentId}/reply`,
    });

    this.postReplyCommentMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  // Customizable Area End
}
