import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import DezinerChip from '../../../components/src/Chip.web';
import moment from 'moment';
import DezinerButton from '../../../components/src/Button.web';

const useDialogStyles = makeStyles({
    paper: {
        borderRadius: '10px',
    }
});

const useStyles = makeStyles({
    iconButton: {
        background: '#e1e1e1'
    },
    closeBtn: {
        position: 'fixed',
        top: '3vh',
        right: '3vw',
    },
    closeIcon: {
        color: 'white'
    },
    contentContainer: {
        margin: "0 auto",
        maxWidth: "90%",
        width: "100%",
        padding: "30px 0",
        '& > *': {
            margin: '15px 0',
        }
    },
    titleText: {
        color: 'black',
        fontSize: '1.6rem',
        fontWeight: 800,
        margin: 0,
    },
    subTitleText: {
        color: '#7c7d7f',
        fontSize: '1rem',
        margin: 0,
    },
});


const DeleteDialog = ({
    open,
    onClose,
    onAccept,
    title,
    subtitle,
}: {
    open: boolean,
    onClose: () => void,
    onAccept: () => void,
    title?: string,
    subtitle?: string,
}) => {

    const dialogClasses = useDialogStyles();
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
            classes={dialogClasses}
        >

            <div>
                <DialogTitle>
                    <Typography className={classes.titleText}>
                        {title || 'Are you sure you want to delete this?'}
                    </Typography>
                </DialogTitle>
                <IconButton className={classes.closeBtn} onClick={onClose}>
                    <CloseIcon className={classes.closeIcon} fontSize="large" />
                </IconButton>
            </div>

            <DialogContent className={classes.contentContainer}>

                <Typography variant='body1' className={classes.subTitleText}>
                    {subtitle || 'Please make sure you want to delete this because it\'s undoable.'}
                </Typography>


            </DialogContent>
            <DialogActions>
                <DezinerButton
                    variant='text'
                    onClick={onClose}
                    color="primary"
                >
                    {'Cancel'}
                </DezinerButton>
                <DezinerButton
                    variant='contained'
                    onClick={onAccept}
                    color="primary"
                >
                    {'Delete'}
                </DezinerButton>
            </DialogActions>
        </Dialog>
    )
}


export default DeleteDialog;