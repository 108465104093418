Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Groups";
exports.labelBodyText = "Groups Body";
exports.profileApi = 'account_block/accounts/profile';
exports.clubs = 'bx_block_groups/clubs';
exports.designApi = 'bx_block_attachment/designs';
exports.clubDetailApi = 'bx_block_groups/clubs';
exports.updateClubAboutApi = 'bx_block_groups/clubs';
exports.getClubDetails = 'bx_block_groups/clubs/';
exports.exampleAPiMethodPut = "PUT";
exports.deleteMember = 'bx_block_groups/clubs';
exports.deleteMemberRequestType = 'DELETE';
exports.addMember = 'bx_block_groups/clubs/';
exports.designerList = 'bx_block_groups/clubs/designers_list';
exports.clubRoles = 'bx_block_groups/clubs/club_roles';
exports.getClubApi = 'bx_block_groups/clubs'
exports.getClubLabelsApi = 'bx_block_categories/labels'
exports.createClubLabelApi = 'bx_block_categories/labels'
exports.getClubCollections = 'account_block/collections';
exports.inspiredAccs = "bx_block_attachment/designs";
exports.addAsAdmin = "bx_block_groups/clubs/"
exports.createClubApi = 'bx_block_groups/clubs'
exports.getMembers = 'bx_block_groups/clubs/designers_list'
exports.getCategories = 'bx_block_categories/categories'
exports.skillsList = "bx_block_categories/skills";
exports.createGroupApi = 'bx_block_groups/groups';
exports.groupsList = 'bx_block_groups/groups';
exports.getGroupLabelsApi = 'bx_block_categories/labels';
exports.btnExampleTitle = "CLICK ME";
exports.pinCollection = "bx_block_cfpindesigns2/pins";
exports.unPinCollection = "bx_block_cfpindesigns2/pins/unpin";

// Customizable Area End
