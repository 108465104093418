import React, { useState } from 'react';
import { CollectionHome, Design, DesignCategory } from './api-types';
import DezinerButton from './Button.web';
import DezinerHeader from './DezinerHeader.web';
import HeaderControls from './DezinerHeaderControls.web';
import { infoIcon } from '../../blocks/Groups/src/assets';
import { makeStyles } from '@material-ui/core/styles';
import LabelListing, { LabelListingProps } from '../../blocks/catalogue/src/LabelListing.web';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { navigateTo } from './store/navigation/navigation.action';
import { useDesignerCollectionsByUser, useDesignerDesignsByUser, useDesignerProfileByUser, useLabelsByOther, } from './hooks';

const configJSON = require('./config');


export type DesignerProfileByUserLayoutProps = {
  displayCollectionsAsList: boolean,
  currentLabel: number,
  arrLabels: any[],
  activeCategory: DesignCategory,
  changeLabel: (labelId: number) => void,
  designsData: (Design | CollectionHome)[]
  loadingDesignData: boolean,
}


const styles: any = {
  headerFloatingBtn: {
    fontWeight: 'bold',
    background: 'white',
    boxShadow: '0 2px 10px 0px #c9c9c9 !important',
    borderRadius: '4px',
  },
};

const useStyles = makeStyles(styles);



const categoryToEndpointMapper: {
  [key in DesignCategory]: string
} = {
  'collections': '',
  'designs': 'other_user_all_designs',
  'liked': 'other_user_liked_designs',
  'promoted': 'other_user_promoted_designs',
  'rated': 'other_user_rated_designs',
  'inspired': 'other_user_inspired_designs',
  'free': 'other_user_free_designs',
  'paid': 'other_user_paid_designs',
  'all': '',
  saved: '',
  shared: ''
}




function _DesignerProfileByUserLayout(props: any) {

  const { children, ...otherProps } = props;
  const designerId = otherProps?.match?.params?.id;

  const [activeCategory, setActiveCategory] = useState<DesignCategory>('collections');
  const [currentLabel, setCurrentLabel] = useState<number>(0);
  const [displayCollectionsAsList, setDisplayCollectionsAsList] = useState<boolean>(true);

  const classes = useStyles();
  const dispatch = useDispatch();

  const collectionsQuery = useDesignerCollectionsByUser({
    activeCategory,
    labelId: currentLabel,
    designerId,
  });

  const designsQuery = useDesignerDesignsByUser({
    designerId,
    labelId: currentLabel,
    category:activeCategory,
  });

  const designerProfileQuery = useDesignerProfileByUser({ id: designerId });
  const designerProfileData = designerProfileQuery?.data?.data?.attributes;

  const designerLabelsQuery = useLabelsByOther({ designerId });


  let list: LabelListingProps['list'] = [
    {
      id: 0,
      label: 'All',
      onClick: setCurrentLabel,
      alt: 'All',
      active: currentLabel == 0,
    }
  ];

  try {

    list = list.concat(designerLabelsQuery?.data?.data?.map((item) => ({
      id: Number(item.id),
      label: item.attributes.name,
      hide: false,
      active: item.id == currentLabel,
      onClick: setCurrentLabel
    })) ?? []);

  } catch (error) {
    console.error(error);
  }


  return (
    <div>


      <DezinerHeader
        fallbackColor={designerProfileData?.color ?? ''}
        onClick={(category) => {
          setActiveCategory(category);
          setDisplayCollectionsAsList(false);
        }}
        title={designerProfileData?.first_name ?? ''}
        subTitle={designerProfileData?.profile_info ?? ''}
        profileImageURL={designerProfileData?.profile_thumbnail_url ?? ''}
        coverImageURL={designerProfileData?.cover ?? ''}
        profileStatistics={[
          { name: 'collections', value: designerProfileData?.totel_collections ?? 0, isActive: !displayCollectionsAsList && activeCategory == 'collections' },
          { name: 'designs', value: designerProfileData?.totel_designs ?? 0, isActive: activeCategory == 'designs' },
          { name: 'liked', value: designerProfileData?.totel_like_designs ?? 0, isActive: activeCategory == 'liked' },
          { name: 'promoted', value: designerProfileData?.totel_promoted_designs ?? 0, isActive: activeCategory == 'promoted' },
          { name: 'inspired', value: designerProfileData?.totel_inspired_designs ?? 0, isActive: activeCategory == 'inspired' },
          { name: 'rated', value: designerProfileData?.totel_rated_designs ?? 0, isActive: activeCategory == 'rated' },
          { name: 'free', value: designerProfileData?.totel_free_designs ?? 0, isActive: activeCategory == 'free' },
          { name: 'paid', value: designerProfileData?.totel_paid_designs ?? 0, isActive: activeCategory == 'paid' },
          { name: 'following', value: designerProfileData?.following_count ?? 0 },
          { name: 'followers', value: designerProfileData?.follower_count ?? 0 },
        ]}
        loading={designerProfileQuery?.isLoading}
      />

      <HeaderControls>
        <HeaderControls.SideSection flexStart>



          <DezinerButton
            variant="contained"
            onClick={() => {
              dispatch(navigateTo({ url: `DesignerAboutPage/${designerId}` }));
            }}
            startIcon={<img src={infoIcon} />}
            className={classes.headerFloatingBtn}
          >
            {'About'}
          </DezinerButton>


        </HeaderControls.SideSection>


        <HeaderControls.MiddleSection>
          <LabelListing list={list} />
        </HeaderControls.MiddleSection>



      </HeaderControls>

      {
        React.cloneElement(
          children,
          {
            layoutProps: {
              activeCategory: activeCategory,
              changeLabel: setCurrentLabel,
              currentLabel,
              displayCollectionsAsList,

              designsData: activeCategory == 'collections' ?
                collectionsQuery?.data?.data ?? []
                : designsQuery?.data?.data ?? [],
              arrLabels: designerLabelsQuery?.data?.data ?? [],

              loadingDesignData: activeCategory == 'collections' ?
                collectionsQuery?.isFetching ?? false
                :
                designsQuery?.isFetching ?? false,
            } as DesignerProfileByUserLayoutProps
          })
      }

    </div>
  );
}

// @ts-ignore
const DesignerProfileByUserLayout = (withRouter as any)(_DesignerProfileByUserLayout);

export default DesignerProfileByUserLayout;
