import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
//   navigation: any;
//   id: string;
  // Customizable Area Start
  classes: any;
  followerCount: number,
  following: boolean,
  subscribed: boolean,
  accountId: number,
  followAction: any;
  unFollowAction: any;
  subscribeAction: any;
  unSubscribeAction: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
    followerCount: number,
    following: boolean,
    subscribed: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserHeaderActionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  followMessageApi: string;
  unFollowMessageApi: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.followAccount = this.followAccount.bind(this);
    this.unFollowAccount = this.unFollowAccount.bind(this);
    // this.subscribeAccount = this.subscribeAccount.bind(this);
    // this.unSubscribeAccount = this.unSubscribeAccount.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
        followerCount: this.props.followerCount,
        following: this.props.following,
        subscribed: this.props.subscribed
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.followMessageApi != null &&
      this.followMessageApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors) {
        this.setState({
          following: true,
          followerCount: this.state.followerCount+1
        });
        toast.success('Followed Successfully');
      } else {
        toast.error('Something went wrong!!');
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.unFollowMessageApi != null &&
      this.unFollowMessageApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors) {
        this.setState({
          following: false,
          followerCount: this.state.followerCount-1
        });
        toast.error('Unfollowed Successfully');
      } else {
        toast.error('Something went wrong!!');
      }
    }

    // Customizable Area End
  }

  followAccount() {
    if (this.props.followAction) {
      this.props.followAction();
    } else {
      const token = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token"),
      };
  
      const httpBody = {
        data: {
          attributes: {
            account_id: this.props.accountId
          }
        }
      };
      const getDesignDataApi = apiCall({
        header: header,
        httpBody: httpBody,
        httpMethod: configJSON.exampleAPiMethod,
        url: configJSON.followApi,
      });
  
      this.followMessageApi = getDesignDataApi.messageId;
      runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    }
  }

  unFollowAccount() {
    if (this.props.unFollowAction) {
      this.props.unFollowAction();
    } else {
      const token = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token"),
      };

      const httpBody = {};
      const getDesignDataApi = apiCall({
        header: header,
        httpBody: httpBody,
        httpMethod: configJSON.deleteMethod,
        url: `${configJSON.followApi}/${this.props.accountId}`,
      });

      this.unFollowMessageApi = getDesignDataApi.messageId;
      runEngine.sendMessage(getDesignDataApi.id, getDesignDataApi);
    }
  }

  // subscribeAccount() {

  // }

  // unSubscribeAccount() {

  // }

  // Customizable Area Start
  // Customizable Area End
}
