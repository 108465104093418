import React from 'react';
import { connect } from 'react-redux';
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { apiCall, IApiCall } from '../Common';
import { appendJsonContentType, appendToken, extractAPIResponse } from '../utils';
import { saveHttpResponse } from './http/http-response.action';
import { withRouter } from 'react-router-dom';


interface Props { children: any, saveHttpResponse: typeof saveHttpResponse }
interface S { }
interface SS { }

//class ReduxDezinerMiddleware extends BlockComponent<Props, S, SS>{
class ReduxDezinerMiddleware extends React.Component<Props, S, SS>{

  subScribedMessages: any;

  constructor(props: any) {

    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as any, this.subScribedMessages);
  }

  receive(from: string, message: Message) {

    if (!message) return;

    const { responseJson, errors, messageId } = extractAPIResponse(message);

    if (messageId) {
      // save http response 
      this.props.saveHttpResponse({ message, response: responseJson });
    }
  }

  static sendHttpRequest(httpParams: IApiCall) {

    const message = apiCall({
      ...httpParams,
      header: appendToken(appendJsonContentType(httpParams.header)),
    });

    runEngine.sendMessage(message.id, message);
    return message.messageId;
  }


  render() {
    return (<>{this.props.children}</>);
  }

}

export default (connect(
  null,
  {
    saveHttpResponse
  }
)(ReduxDezinerMiddleware));

