import React, { useState } from "react"
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import DezinerAudio from "../../../components/src/DezinerAudio.web";
import { DesignReplyAttributes } from "../../../components/src/api-types";
import { DEFAULT_PROFILE_IMAGE } from "../../../components/src/defaults";





const useReplyStyles = makeStyles({
  root: {
    padding: '20px 0',
  },
  replyProfilePicture: {
    width: '59px',
    height: '59px',
    borderRadius: '40%',
    marginRight: '20px',
    objectFit: 'cover',
  },
  firstName: {
    color: '#515151',
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  duration: {
    color: '#979797',
    fontWeight: 700,
    fontSize: '0.8rem',
  },
  dot: {
    width: '6px',
    height: '6px',
    background: '#515151',
    margin: '0 10px 0 20px',
    borderRadius: '50%'
  },
  commentText: {
    color: '#515151',
    fontWeight: 600,
    fontSize: '0.9rem',
    padding: '10px 0',
    maxWidth: '90%',
  },
  attachementsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fileIcon: {
    transform: 'rotate(45deg)',
    color: '#515151',
  },
  oneAttachementText: {
    color: '#979797',
    fontWeight: 700,
    fontSize: '0.8rem',
  },
  iconContainer: {
    padding: '0 10px', display: 'flex', alignItems: 'center', textDecoration: 'none',
  },
});


export default function Reply({ reply }: { reply: DesignReplyAttributes }) {


  const classes = useReplyStyles();
  const duration = reply?.created_at ?
    moment.duration(moment().diff(moment(reply.created_at))).humanize()
    :
    '';


  const profilePicture = (
    reply.repled_by.data?.attributes?.profile_url ?
      <img
        src={reply.repled_by.data.attributes.profile_url}
        alt={reply.repled_by.data.attributes.first_name}
        className={classes.replyProfilePicture}
      />
      :
      reply.repled_by.data?.attributes?.color ?
        <div
          className={classes.replyProfilePicture}
          style={{ background: reply.repled_by.data?.attributes?.color }}
        />
        :
        <img
          src={DEFAULT_PROFILE_IMAGE}
          alt={reply.repled_by.data.attributes.first_name}
          className={classes.replyProfilePicture}
        />
  );

  const commentHeader = (
    <Grid container>

      <Hidden mdUp>
        <Grid item xs={'auto'}>{profilePicture}</Grid>
      </Hidden>

      <Grid item xs>
        <Grid container alignItems="center">

          <Grid item sm={'auto'} xs={12}>
            <Typography className={classes.firstName}>
              {reply.repled_by.data.attributes.first_name}
            </Typography>
          </Grid>

          <Hidden xsDown>
            <div className={classes.dot} />
          </Hidden>

          <Grid item sm={'auto'} xs={12}>
            <Typography className={classes.duration}>
              {duration ? `${duration} ago` : ''}
            </Typography>
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  );


  const replyBody = (
    <div>

      {commentHeader}

      <Typography className={classes.commentText}>
        {reply.comment}
      </Typography>

      <div className={classes.attachementsContainer}>
        {
          reply.audio_urls?.[0]?.url ?
            <DezinerAudio
              src={reply.audio_urls?.[0]?.url}
            />
            :
            null
        }
        {
          reply?.attachments_url?.[0]?.url ?
            <a
              target="_blank"
              href={reply?.attachments_url?.[0]?.url}
              className={classes.iconContainer}
            >
              <IconButton>
                <AttachFileIcon className={classes.fileIcon} />
              </IconButton>
              <Typography className={classes.oneAttachementText}>
                {'1 attachement'}
              </Typography>
            </a>
            :
            null
        }
      </div>

    </div>
  )


  return (
    <div className={classes.root}>

      <Grid container>
        <Hidden smDown>
          <Grid item sm={'auto'} xs={12} justifyContent="center" >{profilePicture}</Grid>
        </Hidden>

        <Grid item sm xs>
          {replyBody}
        </Grid>
      </Grid>

    </div>
  );
}
