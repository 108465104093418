import React from "react";
// Customizable Area Start
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Rating from "@material-ui/lab/Rating";
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import {
  Reply,
  Info,
  Favorite,
  WbIncandescent,
  Visibility,
  Add,
  Star,
  ChatBubble,
  Close as CloseIcon,
  Save
} from "@material-ui/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../catalogue/src/style.css";
// Customizable Area End

import GroupDesignViewByDesignerController, {
  Props
} from "./GroupDesignViewByDesignerController.web";
import Footer from "../../catalogue/src/Footer.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import TopHeaderWeb from "../../catalogue/src/TopHeader.web";
import { toast } from "react-toastify";

class GroupDesignViewByDesigner extends GroupDesignViewByDesignerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <TopHeaderWeb
          navigation={this.props.navigation}
          userRole={this.state.profileRole}
          userProfileImg={this.state.profileImgUrl}
          clubName=''
          groupName=''
        />
        <div className="main-container">
          {!this.state.loading ? (
            <>
              <div className="userData">
                <div />
                <div className="userDataInfo">
                  <div className="userDataImg">
                    <img
                      src={
                        this.state.designData.designer.data.attributes
                          .profile_url === ""
                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                          : this.state.designData.designer.data.attributes
                              .profile_url
                      }
                      alt={
                        this.state.designData.designer.data.attributes
                          .first_name
                      }
                    />
                  </div>
                  <div className="userDataName">
                    <h4
                      style={{
                        margin: 0
                      }}
                    >
                      <b>{this.state.designData.title}</b>
                    </h4>
                    <span>
                      {
                        this.state.designData.designer.data.attributes
                          .first_name
                      }
                      <ul
                        style={{
                          display: "inline-flex",
                          columnGap: "20px",
                          paddingLeft: "25px"
                        }}
                      >
                        <li>Follow</li>
                        <li>Subscribe</li>
                      </ul>
                    </span>
                  </div>
                  <div className="DesignPromoteBtn">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                          this.state.designData.saved
                          ? null
                          : this.setState({ dialogStatus: true })
                        }
                    }
                    >
                      <Save />{" "}
                      <span style={{ marginLeft: "10px" }}>
                        {this.state.designData.saved ? "Saved" : "Save"}
                      </span>
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "black", color: "white" }}
                      onClick={this.likeDesign}
                    >
                      <Favorite />{" "}
                      <span style={{ marginLeft: "10px" }}>
                        {this.state.designData.liked ? "Liked" : "Like"}
                      </span>
                    </Button>
                  </div>
                </div>
                <div />
              </div>
              <div className="designerDesign">
                <div className="designerDesignOptions">
                  <Tooltip title="Share" arrow placement="left">
                    <Button onClick={() => this.shareDesign()}>
                      <Reply style={{ transform: "scaleX(-1)" }} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Info" arrow placement="left">
                    <Button>
                      <Info />
                    </Button>
                  </Tooltip>
                </div>
                <div className="designerDesignMainImage">
                  <img src={this.state.currentImage} />
                </div>
                <div className="desingerDesignSubImage">
                  {this.state.designImages.length > 0
                    ? this.state.designImages.map((img, index) => {
                        const currentClass =
                          index === 0 ? "subImages active" : "subImages";
                        return (
                          <img
                            key={index}
                            src={img}
                            className={currentClass}
                            onClick={e => this.handleImageClick(e)}
                          />
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="designerDesingData">
                <div />
                <div className="DesignDataAttr">
                  <div
                    className={
                      this.state.designData.inspired_count > 0
                        ? "DesignerInspired"
                        : ""
                    }
                  >
                    <WbIncandescent />{" "}
                    <span>{this.state.designData.inspired_count}</span>
                    <ul className="DesignerInspiredul">
                      {this.state.inspireAccounts.length > 0
                        ? this.state.inspireAccounts.map((item: any, index: any) => (
                            <li key={index}>
                              <img
                                src={item.attributes.profile_url}
                                alt="profileImg"
                              />
                              <span>{item.attributes.first_name}</span>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                  <p>
                    <ChatBubble />{" "}
                    <span>{this.state.designData.comment_count}</span>
                  </p>
                  <p>
                    <Favorite /> <span>{this.state.designData.like_count}</span>
                  </p>
                  <p>
                    <Visibility />{" "}
                    <span>{this.state.designData.view_count}</span>
                  </p>
                  <p>
                    <Add /> <span>0</span>
                  </p>
                  <p
                    onClick={() => this.setState({ showRating: true })}
                    style={{ cursor: "pointer" }}
                  >
                    <Star /> <span>{this.state.designData.rating_count}</span>
                  </p>
                </div>
                <div />
              </div>
              <div className="designDescription2">
                <div />
                <p>{this.state.designData.description}</p>
                <div />
              </div>
              <div className="designDescription2">
                <div />
                <div className="postComment">
                  <h3>
                    <b>Comments</b>
                  </h3>
                  <TextField
                    id="outlined-multiline-static"
                    // label="Comment"
                    multiline
                    rows={6}
                    fullWidth
                    variant="outlined"
                    value={this.state.comment}
                    placeholder="Write your comment..."
                    onChange={e => this.setState({ comment: e.target.value })}
                  />
                  <div className="submitPost">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => this.setState({ comment: "" })}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={this.postComment}
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Post
                    </Button>
                  </div>
                </div>
                <div />
              </div>
              <div className="designDescription2">
                <div />
                <div className="commentListing">
                  {this.state.comments.length > 0 ? (
                    <>
                      {this.state.comments.map((item: any) => (
                        <>
                          <div
                            className="userDataInfo"
                            style={{
                              gridTemplateColumns: "0.1fr auto 0.2fr",
                              margin: "20px 0"
                            }}
                          >
                            <div className="userDataImg">
                              <img
                                src={
                                  item.comment_by.data.attributes.profile_url
                                }
                              />
                            </div>
                            <div
                              className="userDataName"
                              style={{
                                fontWeight: 500
                              }}
                            >
                              <span style={{ marginBottom: "10px" }}>
                                <b>
                                  {item.comment_by.data.attributes.first_name}
                                </b>
                              </span>
                              <br />
                              <span>{item.comment}</span>
                            </div>
                            <div className="DesignPromoteBtn">
                              <Button size="small">Reply</Button>
                              <Button size="small">Like</Button>
                            </div>
                          </div>
                          <Divider />
                        </>
                      ))}
                      <p>View All Comments</p>
                    </>
                  ) : null}
                </div>
                <div />
              </div>
              <div className="designDescription2">
                <div />
                <div className="designerData">
                  <img
                    src={
                      this.state.designData.designer.data.attributes
                        .profile_url === ""
                        ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                        : this.state.designData.designer.data.attributes
                            .profile_url
                    }
                  />
                  <p>
                    {this.state.designData.designer.data.attributes.first_name}
                    <br />
                    <span>Designer</span>
                    <br />
                    <br />
                    <Button variant="outlined">Subscribe</Button>
                  </p>
                </div>
                <div />
              </div>
              {this.state.moreDesigns.length > 0 ? (
                <div className="moreDesigns">
                  <div className="heading">
                    <p>
                      More by{" "}
                      {
                        this.state.designData.designer.data.attributes
                          .first_name
                      }
                    </p>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "black", color: "white" }}
                      onClick={() =>
                        this.props.navigation.navigate("DesignerProfileByUser")
                      }
                    >
                      View Profile
                    </Button>
                  </div>
                  <div className="designsList">
                    {this.state.moreDesigns.map((item: any) => (
                      <div
                        className="moreDesignData"
                        onClick={() => this.viewDesign(item.id)}
                      >
                        <img src={item.image_url} />
                        <div>
                          <p>{item.title}</p>
                          <p>
                            <span>
                              <WbIncandescent /> {item.inspired_count}
                            </span>
                            <span>
                              <Star /> {item.rating_count}
                            </span>
                            <span>
                              <Favorite /> {item.like_count}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px"
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
        <Footer
          navigation={this.props.navigation}
        />
        <div
          className="promotePopup"
          style={{ display: this.state.dialogStatus ? "flex" : "none" }}
        >
          <span
            className="promotePopupCloseIcon"
            onClick={() =>
              this.setState({ dialogStatus: false, collectionForm: false })
            }
          >
            <CloseIcon />
          </span>
          <div className="promoteContainer">
            <div className="promoteTitle imgtitle">
              <img src={this.state.currentImage} />
            </div>
            <div className="promoteMainContainer">
              <h3 className="containerTitle">
                Add this design to a collection
              </h3>
              <FormControl fullWidth>
                <InputLabel id="select-collection">
                  Select Collection
                </InputLabel>
                <Select
                  labelId="select-collection"
                  id="collections"
                  value={this.state.selectedCollection}
                  label="Select"
                  onChange={(e: any) =>
                    this.setState({ selectedCollection: e.target.value })
                  }
                >
                  {this.state.collections.length > 0
                    ? this.state.collections.map((item: any) => (
                        <MenuItem value={item.attributes.id}>{`${
                          item.attributes.name
                        } (${item.attributes.privacy})`}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
            <div
              className="promoteFooter"
              style={{
                justifyContent: "end"
              }}
            >
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  display: this.state.collectionForm ? "none" : "block"
                }}
                variant="contained"
                onClick={this.saveDesign}
              >
                Save
              </Button>
            </div>
            <Divider />
            <div style={{ marginTop: "20px" }}>
              <Button
                style={{
                  backgroundColor: "lightgrey",
                  color: "black",
                  float: "right",
                  display: this.state.collectionForm ? "none" : "block"
                }}
                onClick={() => this.setState({ collectionForm: true })}
                variant="contained"
              >
                Create Collection
              </Button>
              <div
                style={{
                  display: this.state.collectionForm ? "block" : "none",
                  marginTop: "20px"
                }}
              >
                <TextField
                  id="collectionName"
                  label="Collection"
                  fullWidth
                  variant="outlined"
                  value={this.state.collectionName}
                  placeholder="Enter Collection Name"
                  onChange={e =>
                    this.setState({ collectionName: e.target.value })
                  }
                />
                <div
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px"
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.privacy}
                          onChange={e =>
                            this.setState({ privacy: e.target.checked })
                          }
                        />
                      }
                      label="keep this collection private"
                    />
                  </FormGroup>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    variant="contained"
                    onClick={this.saveInNewCollection}
                  >
                    Create and Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="promotePopup"
          style={{ display: this.state.showRating ? "flex" : "none" }}
        >
          <span
            className="promotePopupCloseIcon"
            onClick={() => this.setState({ showRating: false })}
          >
            <CloseIcon />
          </span>
          <div
            className="promoteContainer"
            style={{
              borderRadius: "20px",
              textAlign: "center",
              width: "25vw",
              padding: "0"
            }}
          >
            <h3
              className="PopupTitle"
              style={{
                borderBottom: "1px solid lightgrey",
                paddingBottom: "15px"
              }}
            >
              Rate the Design
            </h3>
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                display: "grid",
                justifyContent: "center",
                rowGap: "15px"
              }}
            >
              <Rating
                name="design_rating"
                size="large"
                precision={0.5}
                onChange={(e: any) =>
                  this.setState({ userRating: e.target.value })
                }
              />
              <sub
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "darkgrey"
                }}
              >
                Tab a Star to Rate
              </sub>
            </div>
            <div
              style={{ width: "100%", display: "flex", alignItems: "stretch" }}
            >
              <Button
                variant="outlined"
                size="small"
                style={{ width: "100%", borderRadius: "0" }}
                onClick={() => this.setState({ showRating: false })}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "100%", borderRadius: "0" }}
                onClick={this.saveRating}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        <div
          className="promotePopup"
          style={{ display: this.state.shareDialogStatus ? "flex" : "none" }}
        >
          <span
            className="promotePopupCloseIcon"
            onClick={() => this.setState({ shareDialogStatus: false })}
          >
            <CloseIcon />
          </span>
          <div className="promoteContainer">
            <div className="promoteTitle imgtitle">
              <img src={this.state.shareDesignLink} />
            </div>
            <>
              <div className="promoteMainContainer">
                <p className="containerTitle">Share this with your social Community</p>
                <div className="">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <a
                      target="_blank"
                      href="#"
                      className="shareSocialIcons">D</a>
                    <a
                      target="_blank"
                      href={`https://pinterest.com/pin/create/link/?url=${this.state.shareLink}&media_url=${this.state.shareDesignLink}`}
                      className="shareSocialIcons">
                        <PinterestIcon />
                    </a>
                    <a
                      target="_blank"
                      href={`https://twitter.com/share?url=${this.state.shareLink}`}
                      className="shareSocialIcons">
                        <TwitterIcon />
                    </a>
                    <a
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${this.state.shareLink}`}
                      className="shareSocialIcons"
                    >
                      <FacebookIcon />
                    </a>
                    <a target="_blank" href="#" className="shareSocialIcons">
                      <InstagramIcon />
                    </a>
                  </div>
                  <div style={{ textAlign: 'center', margin:'20px auto' }}>
                    Or Copy link
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <input
                      disabled
                      value={this.state.shareLink}
                      style={{ padding: '10px', paddingRight: '100px', width: '200px' }}
                    />
                    <button
                      style={{
                        marginLeft: '-100px',
                        width: '100px',
                        padding: '10px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(this.state.shareLink);
                        toast.success("Link Copied");
                      }}
                    >Copy</button>
                  </div>
                </div>
              </div>
              </>
          </div>
        </div>
      </>
    );
  }
}

// Customizable Area Start
const useStyles: any = {};
export default withStyles(useStyles)(GroupDesignViewByDesigner);
// Customizable Area End
