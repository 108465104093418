import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import DezinerButton from "./Button.web";

const useEditableAreaOnHoverStyles = makeStyles({
  textAreaContainer: {
    position: 'relative',
    borderRadius: '6px',
    padding: '10px',
    '&:hover': {
      border: '1px solid #e3e3e3 !important',
    },
    '&:hover button': {
      display: 'block !important'
    }
  },
  textAreaContainerActive: {
    border: '1px solid #e3e3e3',
  },
  noBorder: {
    '&:hover': {
      border: '0px solid black !important',
    },
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  textAreaBtn: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    color: '#767779',
    display: 'none',
  },
  textAreaBtnActive: {
    display: 'block',
  },
});

export function EditableAreaOnHover({
  children,
  title,
  allowEdit,
  onSave,
  saveButtonTitle,
  editButtonTitle,
  className,
}: {
  children: any
  title: string,
  allowEdit: boolean,
  onSave: () => void,
  saveButtonTitle?: string,
  editButtonTitle?: string,
  className?: string,
}) {
  const [editMode, setEditMode] = useState(false);

  const classes = useEditableAreaOnHoverStyles();

  return (
    <div
      className={clsx(classes.textAreaContainer, className, {
        [classes.textAreaContainerActive]: editMode,
        [classes.noBorder]: !allowEdit
      })}
    >
      <Typography variant="body1" className={classes.sectionTitle}>
        {title}
      </Typography>

      {
        allowEdit ?
          <DezinerButton
            onClick={() => {
              if (editMode) {
                onSave();
              }
              setEditMode(x => !x);
            }}
            color="default"
            variant="text"
            className={clsx(classes.textAreaBtn, {
              [classes.textAreaBtnActive]: editMode
            })}
          >
            {editMode ? (saveButtonTitle || 'Save') : (editButtonTitle || 'Edit')}
          </DezinerButton>
          :
          null
      }

      {
        React.Children.map(children, child =>
          React.cloneElement(child, { editMode }),
        )
      }

    </div>
  )
}

export function EditableAreaOnHoverActive({ editMode, children }: { editMode?: boolean, children?: any }) {
  return editMode ? children : null
}

export function EditableAreaOnHoverInActive({ editMode, children }: { editMode?: boolean, children?: any }) {
  return editMode ? null : children
}


