import { makeStyles } from '@material-ui/core/styles';

export const useUploadDesignFormStyles = makeStyles({
  video: {
    width: "100%",
    maxHeight: "100%",
    objectFit: "cover",
    height: "100%"
  },
  dropzoneArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "500px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    position: 'relative',
    overflow: 'hidden'
  },
  watermarkOverlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '20px',
    zIndex: 4,
  },
  watermarkOverlayForVideos: {

    height: 'calc(100% - 130px)',// because we want to still have the video controls clickable
  },
  mainImg: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    objectFit: "cover"
  },
  subDesignAddContainer: {
    borderRadius: '10px',
    background: '#efeff1',
    width: '100px',
    height: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  watermarkImg: {
    width: '75px',
    height: '50px',
    objectFit: 'contain',
    border: '1px solid #efeff1',
    borderRadius: '10px',
    marginRight: '10px',
  },
  noWatermark: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '11px'
  },
  watermarkImgSelected: {
    border: '1px solid black',
  },
  titleText: {
    fontSize: '1.8rem',
  },
  sectionTitleText: {
    fontSize: '1.2rem',
    color: '#999',
  },
  smallIcon: {
    maxWidth: '18px',
    marginRight: '5px',
  },
  sectionTitleText2: {
    fontSize: '1.3rem',
    color: '#333',
  },
  priceInputLabel: {
    margin: '0 10px',
    color: '#898989',
    fontWeight: 'bold',
  },
  yearMonthInput: {
    background: 'white',
    maxWidth: '50px'
  },
  footerContainer: {
    background: '#f6f6f8'
  },
  footer: {
    padding: '20px',
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
  },
  formContentContainer: {
    padding: '40px', maxWidth: '1200px', margin: '0 auto',
    width: '100%',
  },
  recordIconBtn: {
    position: 'relative'
  },
  recordIcon: {
    color: 'red',
  },
  pulse: {
    margin: "0px",
    display: "block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: "rgba(221, 15, 15, 0.6)",
    cursor: "pointer",
    boxShadow: "0 0 0 rgba(221, 15, 15, 0.6)",
    animation: "pulse 2s infinite",
    position: 'absolute',
    top: 0,
    right: '2px'
  },

  rndBorder1: {
    position: 'absolute',
    top: '-2px',
    right: '-2px',
    width: '50px',
    height: '50px',
    border: '6px solid white',
    borderStyle: 'solid solid none none'
  },
  rndBorder2: {
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    width: '50px',
    height: '50px',
    border: '6px solid white',
    borderStyle: 'solid none none solid'
  },
  rndBorder3: {
    position: 'absolute',
    bottom: '-2px',
    right: '-2px',
    width: '50px',
    height: '50px',
    border: '6px solid white',
    borderStyle: 'none solid solid none'
  },
  rndBorder4: {
    position: 'absolute',
    bottom: '-2px',
    left: '-2px',
    width: '50px',
    height: '50px',
    border: '6px solid white',
    borderStyle: 'none none solid solid'
  },
});

