
import { Message } from "../../../framework/src/Message";
import { apiCall } from "../Common";
import { appendJsonContentType, appendToken } from "../utils";

const configJSON = require('../config');
const configJson = configJSON;

export const collectionsMessages = {

  fetchSingleCollection({ collectionId }: { collectionId: number }) {

    return apiCall({
      header: appendToken(appendJsonContentType({})),
      httpBody: {},
      httpMethod: configJSON.GetMethod,
      url: `${configJSON.fetchCollectionAPI}/${collectionId}`,
    });

  },


  createCollection({ collectionName, isPrivate, labelId }: { collectionName: string, isPrivate: boolean, labelId: number }) {

    const httpBody = {
      data: {
        attributes: {
          name: collectionName,
          label_id: labelId,
          privacy: isPrivate ? 'Private' : 'Public',
        }
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJson.PostMethod,
      url: configJson.createCollectionAPI,
    });

  },

  pinCollection({ collectionId, pin }: { collectionId: number, pin: boolean }) {

    const httpBody = {
      data: {
        attributes: {
          collection_id: collectionId
        }
      }
    };

    return apiCall({
      header: appendJsonContentType(appendToken({})),
      httpBody: httpBody,
      httpMethod: configJson.PostMethod,
      url: pin ? configJson.pinCollectionAPI : configJson.unpinCollectionAPI,
    });

  },
}
