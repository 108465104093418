import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  currentLabel: number;
  arrLabels: any;
  profileData: any;
  changeScreenNumber: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  currentLabel: number;
  selfProfileData: any;
  coverImg: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  selfProfileMessageId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.changeLabel = this.changeLabel.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      currentLabel: this.props.currentLabel,
      selfProfileData: {},
      coverImg: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getSelfProfile();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.selfProfileMessageId != null &&
      this.selfProfileMessageId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          selfProfileData: responseJson.data.attributes,
          coverImg: `url(${responseJson.data.attributes.cover})`
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // Customizable Area End
  }

  getSelfProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.selfProfileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  changeLabel(id: number): void {
    const location = window.location.href.split("/");
    const currentScreen = location[location.length - 2];
    console.log(currentScreen);
    if (currentScreen === "UserProfileByOthers") {
      if (id !== this.state.currentLabel) {
        if (id === 1) {
          this.props.changeScreenNumber(1);
        }
        if (id === 2) {
          this.props.changeScreenNumber(2);
        }
      }
      this.setState({
        currentLabel: id,
      });
      return;
    }
    if (id !== this.state.currentLabel) {
      if (id === 6) {
        localStorage.clear();
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else if (id === 2) {
        this.props.navigation.navigate("UserEditProfile");
      } else if (id === 3) {
        this.props.navigation.navigate("UserEditAccount");
      } else if (id === 1) {
        this.props.navigation.navigate("UserDesignList", {
          filterName: "All"
        });
      } else if (id === 4) {
        this.props.navigation.navigate("UserEditPassword");
      } else if (id === 5) {
        this.props.navigation.navigate("UserEditAbout");
      }
      this.setState({ currentLabel: id });
    }
  }

  // Customizable Area End
}
