import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_PROFILE_IMAGE } from '../../../components/src/defaults';

const useDesignerProfileStyles = makeStyles({
  container: {
    maxWidth: '110px',
    padding: '2px',
    border: '1px solid #a9a9a9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '40%',
    margin: '0 15px',
    cursor: 'pointer',
    '&:hover': {
      background: 'black'
    }
  },
  img: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '40%',
  }
});

export default function DesignerProfileImage({ img, name, onClick, fallbackColor }: {
  img: string, name: string, onClick: () => void,
  fallbackColor?: string,
}) {

  const classes = useDesignerProfileStyles();

  return (
    <div onClick={onClick}
      className={classes.container}
    >
      {
        img ?
          <img
            className={classes.img}
            src={img}
            alt={name || 'designer'} />
          :
          fallbackColor ?
            <div
              className={classes.img}
              style={{
                background: fallbackColor
              }}
            />
            :
            <img
              className={classes.img}
              src={DEFAULT_PROFILE_IMAGE}
              alt={name || 'designer'} />
      }

    </div>
  )
}
