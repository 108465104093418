import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  currentClub: any;
  anchorEl: any;
  loading: boolean;
  currentTab: number;
  deletePopup: boolean;
  deleteMember: string;
  deleteType: string;
  deleteId: number;
  clubMembers: any;
  editableRevenue: boolean;
  errRevenue: boolean;
  revenues: any;
  equalContri: boolean;
  memberId: number;
  currentMemberId: any;
  adminCounter: number;
  ownerCounter: number;
  currentUserRoleClub: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignerGroupManageController extends BlockComponent<
  Props,
  S,
  SS
> {
  clubApiMessageId: string;
  removeMessageId: string;
  clubMemberApi: string;
  addMemberMsgId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickDeleteOpen = this.handleClickDeleteOpen.bind(this);
    this.handleClickDeleteClose = this.handleClickDeleteClose.bind(this);
    this.handleClickDeleteMember = this.handleClickDeleteMember.bind(this);
    this.updateRevenue = this.updateRevenue.bind(this);
    this.addAsAdmin = this.addAsAdmin.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      currentClub: {},
      anchorEl: {},
      loading: true,
      currentTab: 0,
      deletePopup: false,
      deleteMember: "",
      deleteType: "",
      deleteId: 0,
      clubMembers: {},
      editableRevenue: false,
      errRevenue: false,
      revenues: {},
      equalContri: false,
      memberId: 0,
      currentMemberId: 0,
      adminCounter: 0,
      ownerCounter: 0,
      currentUserRoleClub: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.setState({ currentMemberId: localStorage.getItem('cuid') })
    this.getClubs();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.clubApiMessageId != null &&
      this.clubApiMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ currentClub: responseJson.data });
        this.getMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.clubMemberApi != null &&
      this.clubMemberApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({ clubMembers: responseJson.data });
        responseJson.data.map((member: any) => {
          if (member.attributes.role === 'Owner') {
            this.setState({ ownerCounter: this.state.ownerCounter + 1 });
          } else if (member.attributes.role === 'Admin') {
            this.setState({ adminCounter: this.state.adminCounter + 1 });
          } else {}
          if (member.attributes.account_id.toString() === localStorage.getItem('cuid')) {
            this.setState({ currentUserRoleClub: member.attributes.role });
          }
        });
        if (Object.keys(responseJson.data).length > 0) {
          Object.keys(responseJson.data).map((key, index) => {
            this.setState({
              anchorEl: { ...this.state.anchorEl, [index]: null },
              revenues: {
                ...this.state.revenues,
                [responseJson.data[key].id]: responseJson.data[key].attributes.contribution !== '' ?
                  responseJson.data[key].attributes.contribution.replace('%', '') :
                  0
              }
            })
          });
          this.setState({ loading: false });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }


    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeMessageId != null &&
      this.removeMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success('Removed Successfully');
        this.setState({ loading: true });
        this.getMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error('Removed Unsuccessfully');
      }
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addMemberMsgId != null &&
      this.addMemberMsgId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        toast.success('Added as Admin Successfully');
        this.getMembers();
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        toast.error('Something went wrong!');
      }
    }


    // Customizable Area End
  }

  getClubs(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.groupsList}/${this.props.navigation.getParam("id", 0)}`,
    });

    this.clubApiMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  getMembers(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.groupsList}/${this.props.navigation.getParam("id", 0)}/group_members`,
    });

    this.clubMemberApi = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    return true;
  }

  handleClick(event: any, index: number): void {
    let newEl = {};
    Object.keys(this.state.anchorEl).map((key) => {
      if (key === index.toString()) {
        this.state.anchorEl[key] = event.currentTarget;
      }
      newEl = {...newEl, [key]: this.state.anchorEl[key]};
    });
    this.setState({ anchorEl: newEl });
  };

  handleClose(index: number): void{
    let newEl = {};
    Object.keys(this.state.anchorEl).map((key) => {
      if (key === index.toString()) {
        this.state.anchorEl[key] = null;
      }
      newEl = {...newEl, [key]: this.state.anchorEl[key]};
    });
    this.setState({ anchorEl: newEl });
  };

  handleChange(event:any, newValue: number) {
    this.setState({ currentTab: newValue });
  };

  handleClickDeleteOpen(): void {
    this.setState({ deletePopup: true });
  };

  handleClickDeleteClose(): void {
    this.setState({ deletePopup: false });
  };

  handleClickDeleteMember(): void {
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: localStorage.getItem("token"),
    };

    const httpBody = {
        data: {
            attributes: {
                account_id: this.state.deleteId
            }
        }
    };

    const getClubData = apiCall({
        header: header,
        httpBody: httpBody,
        httpMethod: this.state.deleteType === 'remove_member' ? configJSON.deleteMemberRequestType : configJSON.exampleAPiMethod,
        url: `${configJSON.groupsList}/${this.props.navigation.getParam("id", 0)}/${this.state.deleteType}`,
    });

    this.removeMessageId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
    this.setState({ deletePopup: false });
  }

  updateRevenue(): void {
    let totalRevenue = 0;
    Object.keys(this.state.revenues).map((key) => {
      totalRevenue += parseFloat(this.state.revenues[key]);
    });
    totalRevenue = parseFloat(totalRevenue.toFixed(2));
    if (totalRevenue != 100) {
      this.setState({ errRevenue: true });
    } else {
      this.setState({ editableRevenue: false, errRevenue: false });
    }
  }

  addAsAdmin({memberId}:{memberId:number}): void{
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {
      data: {
        attributes: {
          account_id: memberId
        }
      }
    };

    const getClubData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: `${configJSON.groupsList}/${this.props.navigation.getParam("id", 0)}/add_as_admin`,
    });

    this.addMemberMsgId = getClubData.messageId;
    runEngine.sendMessage(getClubData.id, getClubData);
  }

  // Customizable Area End
}
