import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import DezinerChip from '../../../components/src/Chip.web';
import moment from 'moment';
import { Design } from '../../../components/src/api-types';

const useDialogStyles = makeStyles({
  paper: {
    borderRadius: '10px',
  }
});

const useStyles = makeStyles({
  imageContainer: {
    background: "#e7e7e7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px"
  },
  image: {
    maxWidth: "80%",
    borderRadius: "10px",
    position: "relative",
    top: "-50px",
    boxShadow: "4px 4px 4px #5e5e5e"
  },
  contentContainer: {
    margin: "0 auto",
    maxWidth: "80%",
    width: "100%",
    padding: "30px 0",
    '& > *': {
      margin: '15px 0',
    }
  },
  titleText: {
    color: 'black',
    fontSize: '1.6rem',
    fontWeight: 800,
    margin: 0,
  },
  subTitleText: {
    color: '#7c7d7f',
    fontSize: '1rem',
    margin: 0,
  },
  iconButton: {
    background: '#e1e1e1'
  },
  closeBtn: {
    position: 'fixed',
    top: '3vh',
    right: '3vw',
  },
  closeIcon: {
    color: 'white'
  },
  gridTitle: {
    color: '#5c5c5c',
    fontSize: '1.2rem',
  },
  gridSubtitle: {
    color: '#303030',
    fontSize: '1.3rem',
  },
  tagsContainer: {
    display: 'flex',
    justifyItems: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  tag: {
    background: 'white',
    border: '1px solid #e8e8e8'
  },
  noTags: {
    color: '#939393'
  }
});


const InfoDialog = ({
  open,
  onClose,
  data,
  currentImageUrl,
}: {
  open: boolean,
  onClose: () => void,
  data: Design['attributes'] | undefined,
  currentImageUrl: string,
}) => {

  const dialogClasses = useDialogStyles();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      classes={dialogClasses}
    >

      <div className={classes.imageContainer}>
        <img className={classes.image} src={currentImageUrl || data?.image_url} />
      </div>

      <IconButton className={classes.closeBtn} onClick={onClose}>
        <CloseIcon className={classes.closeIcon} fontSize="large" />
      </IconButton>

      <DialogContent className={classes.contentContainer}>


        <Typography variant='h4' className={classes.titleText}>
          {'Design Details'}
        </Typography>
        <Typography variant='body1' className={classes.subTitleText}>
          {`Posted on ${moment(data?.created_at).format('MMM DD, YYYY')}`}
        </Typography>


        <Grid container>
          {
            [
              ['Views', data?.view_count],
              ['Saved', data?.save_designs_count],
              ['Likes', data?.like_count],
              ['Comments', data?.comment_count],
            ].map(([title, value]) => (
              <Grid
                key={title}
                item xs={3} container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                <Typography variant='body2' className={classes.gridTitle}>
                  {title}
                </Typography>
                <Typography variant='body1' className={classes.gridSubtitle}>
                  {value}
                </Typography>
              </Grid>
            ))
          }
        </Grid>

        <VerticalSpacer />

        <div>
          <Typography variant='body2' className={classes.gridTitle}>
            {'Tags'}
          </Typography>

          {
            data?.sub_categories?.length ? null
              :
              <Typography className={classes.noTags}>
                {'No Tags'}
              </Typography>
          }

          <div className={classes.tagsContainer}>
            {
              data
                ?.sub_categories
                ?.map((item: any) => item?.name)
                ?.map((tag: string) => (
                  <DezinerChip className={classes.tag} key={tag} label={tag} />
                ))
            }
          </div>
        </div>


      </DialogContent>
    </Dialog>
  )
}


export default InfoDialog;
