import React, { useState } from "react"
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CommentInput from './CommentInput.web';
import Reply from './Reply.web';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import DezinerAudio from "../../../components/src/DezinerAudio.web";
import { DesignComment } from "../../../components/src/api-types";
import { DEFAULT_PROFILE_IMAGE } from "../../../components/src/defaults";
import DezinerButton from "../../../components/src/Button.web";
import DezinerSpinner from "../../../components/src/DezinerSpinner.web";







interface onReplyParams {
  replyText: string,
  parentCommentId: number,
  base64Image: string
  base64Audio: string
}

const useCommentStyles = makeStyles({
  blackHoverButton: {
    textTransform: 'none',
    fontWeight: 700,
    color: '#979797',
    '&:hover': {
      color: '#515151',
    }
  },
  profilePicture: {
    width: '75px',
    height: '75px',
    borderRadius: '40%',
    marginRight: '20px',
    objectFit: 'cover',
  },
  commentsButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstName: {
    color: '#515151',
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  duration: {
    color: '#979797',
    fontWeight: 700,
    fontSize: '0.8rem',
  },
  dot: {
    width: '6px',
    height: '6px',
    background: '#515151',
    margin: '0 10px 0 20px',
    borderRadius: '50%'
  },
  commentText: {
    color: '#515151',
    fontWeight: 600,
    fontSize: '0.9rem',
    padding: '10px 0',
    maxWidth: '80%',
  },
  attachementsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fileIcon: {
    transform: 'rotate(45deg)',
    color: '#515151',
  },
  oneAttachementText: {
    color: '#979797',
    fontWeight: 700,
    fontSize: '0.8rem',
  },
  iconContainer: {
    padding: '0 10px',
    display: 'flex', alignItems: 'center',
    textDecoration: 'none',
  },
  repliesContainer: {
    paddingLeft: '20px'
  },
  commentRootContainer: {
    padding: '30px 0',
    margin: '5px 0',
    borderBottom: '1px solid #ddd'
  },
  breakWords: {
    overflow: "hidden",
    wordBreak: "break-all",
    whiteSpace: "normal"
  },
});




export default function Comment({
  comment,
  onReply,
  hideFileAttachements,
  hideVoiceRecorder,
  loading,
  replyPlaceholder,
}: {
  comment: DesignComment['attributes'],
  onReply: (params: onReplyParams) => void,
  hideVoiceRecorder?: boolean,
  hideFileAttachements?: boolean,
  loading?: boolean,
  replyPlaceholder?: string,
}) {

  const classes = useCommentStyles();
  const [showReply, setShowReply] = useState(false);
  function replyShowToggler() {
    setShowReply(x => !x);
  }

  const duration = comment?.created_at ?
    moment.duration(moment().diff(moment(comment?.created_at))).humanize()
    :
    '';

  const profilePicture = (
    comment.comment_by.data.attributes.profile_url ?
      <img
        src={comment.comment_by.data.attributes.profile_url}
        alt={comment.comment_by.data.attributes.first_name}
        className={classes.profilePicture}
      />
      :
      comment.comment_by.data.attributes.color ?
        <div
          style={{ background: comment.comment_by.data.attributes.color }}
          className={classes.profilePicture} />
        :
        <img
          src={DEFAULT_PROFILE_IMAGE}
          alt={comment.comment_by.data.attributes.first_name}
          className={classes.profilePicture}
        />
  );

  const commentButtons = (
    <div className={classes.commentsButtonsContainer}>
      <DezinerButton
        variant="text"
        className={classes.blackHoverButton}
        onClick={replyShowToggler}>
        {showReply ? 'Cancel' : 'Reply'}
      </DezinerButton>
      <DezinerButton
        variant="text"
        className={classes.blackHoverButton}>
        {'Like'}
      </DezinerButton>
    </div>
  );

  const commentHeader = (
    <Grid container>

      <Hidden mdUp>
        <Grid item xs={'auto'} >{profilePicture}</Grid>
      </Hidden>

      <Grid item xs>
        <Grid container alignItems="center">

          <Grid item sm={'auto'} xs={12}>
            <Typography className={classes.firstName}>
              {comment.comment_by.data.attributes.first_name}
            </Typography>
          </Grid>

          <Hidden xsDown>
            <div className={classes.dot} />
          </Hidden>

          <Grid item sm={'auto'} xs={12}>
            <Typography className={classes.duration}>
              {duration ? `${duration} ago` : ''}
            </Typography>
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  );

  const attachements = (
    <div className={classes.attachementsContainer}>
      {
        comment.audio_urls?.[0]?.url ?
          <DezinerAudio
            src={comment.audio_urls?.[0]?.url}
          />
          :
          null
      }
      {
        comment?.attachments_url?.[0]?.url ?
          <a
            target="_blank"
            href={comment?.attachments_url?.[0]?.url}
            className={classes.iconContainer}
          >
            <IconButton>
              <AttachFileIcon className={classes.fileIcon} />
            </IconButton>
            <Typography className={classes.oneAttachementText}>
              {'1 attachement'}
            </Typography>
          </a>
          :
          null
      }
    </div>
  );

  const replyInput = (
    <>
      {
        showReply ?
          <CommentInput
            placeholder={replyPlaceholder}
            hideFileAttachements={hideFileAttachements}
            hideVoiceRecorder={hideVoiceRecorder}
            singleLine={true}
            onComment={({ commentText, base64Image, base64Audio }) => {
              onReply({
                replyText: commentText,
                parentCommentId: comment.id,
                base64Image,
                base64Audio
              });
            }}
          />
          :
          null
      }
    </>
  );

  const commentText = (
    <Typography className={classes.commentText}>
      {comment.comment}
    </Typography>
  );

  const replies = (
    <div className={classes.repliesContainer}>
      {
        comment?.replies?.data?.length ?
          comment?.replies?.data?.map((replyItem: any) => (
            <Reply
              key={replyItem.id}
              reply={replyItem.attributes as any}
            />
          ))
          :
          null
      }
    </div>
  );


  const body = (
    <Grid container>

      <Grid item xs={8} sm={7} className={classes.breakWords}>{commentText}</Grid>
      <Grid item xs={4} sm={5} container justifyContent="flex-end" alignItems="flex-start"
      >
        {commentButtons}
      </Grid>

      <Grid item xs={12}>{attachements}</Grid>
      <Grid item xs={12}>{replyInput}</Grid>
      <Grid item xs={12}>{replies}</Grid>
    </Grid>
  );

  return (
    <div className={classes.commentRootContainer}>

      <Grid container>
        <Hidden smDown>
          <Grid item sm={'auto'} xs={12} justifyContent="center" >{profilePicture}</Grid>
        </Hidden>

        <Grid item sm xs>
          {commentHeader}
          {body}
        </Grid>
      </Grid>

      {
        loading ?
          <DezinerSpinner noHeight />
          :
          null
      }
    </div>
  );
}
