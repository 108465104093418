import React, { useRef, useState } from 'react';
import CreateIcon from "@material-ui/icons/Create";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormikTextInput from '../../../components/src/FormikTextField.web';
import { DezinerLabel } from '../../../components/src/TextField.web';
import DezinerButton from '../../../components/src/Button.web';
import { HorizontalSpacer, VerticalSpacer } from '../../../components/src/Spacer.web';
import CloseIcon from '@material-ui/icons/Close'
import DezinerCard from '../../../components/src/Card.web';


async function encodeImageFileAsURL(file: any) {
  return new Promise((resolve, reject) => {
    if (file) {
      var reader = new FileReader();
      reader.onloadend = function() {
        let imagebase64 = reader.result;
        resolve(imagebase64);
      };
      reader.readAsDataURL(file);
    } else {
      reject(null);
    }
  });
};


const initValues = {
  title: '',
  description: '',
  media: '',
}


const validationSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
  media: yup.string(),
});



export default function CreateForumForm({
  loading,
  onSubmit,
  onCancel,
  initialValues,
}: {
  loading: boolean,
  onSubmit: (values: typeof initValues) => void,
  onCancel: () => void,
  initialValues?: { title: string, description: string, media: string },
}) {


  const fileRef = useRef<any>();
  const [fileName, setFileName] = useState('');

  return (
    <DezinerCard style={{ maxWidth: '800px', margin: '40px auto', padding: '20px 40px' }}>
      <Formik
        initialValues={Object.assign({}, initValues, initialValues)}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {
          formik => <Form translate={''}>

            <input
              type="file"
              hidden
              ref={fileRef}
              onChange={(e) => {
                e.preventDefault();
                let file = e.target?.files?.[0];
                setFileName(file?.name ?? '');
                encodeImageFileAsURL(file).then(
                  (res: any) => {
                    formik.setFieldValue('media', res)
                  }
                );
              }}
              accept="image/*, video/*"
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>

                <FormikTextInput
                  label={"Title"}
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="title"
                  lightGrayBorder
                />

                <VerticalSpacer />

                <Grid container alignItems='center'>
                  <DezinerLabel>{'Media'}</DezinerLabel>
                  <DezinerButton
                    variant="contained"
                    size='small'
                    style={{ margin: '0 15px' }}
                    onClick={() => {
                      if (fileRef && fileRef.current) {
                        fileRef.current.click();
                      }
                    }}
                  >
                    {'Choose File'}
                  </DezinerButton>
                  {
                    formik.values.media ?
                      <Grid container alignItems='center'>
                        <Typography>
                          {fileName || '1 File attached'}
                        </Typography>
                        <IconButton onClick={() => {
                          formik.setFieldValue('media', '');
                        }}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      </Grid>
                      :
                      null
                  }

                </Grid>

              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormikTextInput
                  label={"Description"}
                  multiline
                  minRows={6}
                  variant="outlined"
                  name="description"
                  fullWidth
                  autoHeight
                  lightGrayBorder
                />
              </Grid>
            </Grid>

            <Divider id="divider" />

            <Grid container justifyContent='center' alignItems='center'>
              <DezinerButton
                rounded
                variant="contained"
                onClick={() => {
                  formik.resetForm();
                  onCancel();
                }}
                style={{
                  minWidth: '140px'
                }}
              >
                {'Cancel'}
              </DezinerButton>

              <HorizontalSpacer />

              <DezinerButton
                variant="contained"
                rounded
                color="primary"
                type="submit"
                style={{
                  minWidth: '140px'
                }}
                disabled={loading}
              >
                {'Save'}
                {
                  loading ?
                    <CircularProgress style={{ margin: '0 10px' }} size={20} color="inherit" />
                    :
                    null
                }
              </DezinerButton>
            </Grid>


          </Form>
        }
      </Formik >
    </DezinerCard>
  );
}
