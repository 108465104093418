import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
export interface IApiCall {
    header: any;
    httpBody?: any;
    url: string;
    httpMethod: string;
    params?: {[key:string]: string | number | boolean},
}

const hasSearchParams = (url:string) => /.*\?.*=.*/g.test(url);

export function apiCall({ header, httpBody, url, httpMethod, params }: IApiCall) {

    let urlParams =  new URLSearchParams();
    let urlParamsString = '';

    if(params){
      Object.entries(params)
      ?.forEach(([key,value]) => urlParams.append(key,String(value)))

      urlParamsString = decodeURIComponent(urlParams.toString());
      urlParamsString = urlParamsString && hasSearchParams(url) ? `&${urlParamsString}` : `?${urlParamsString}`;
    }

    let requestURL = `${url}${urlParamsString}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    if (httpBody && Object.keys(httpBody).length > 0) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      httpMethod
    );
    return requestMessage;
}
