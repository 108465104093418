import { take } from "redux-saga/effects";
import { extractAPIResponse } from "../../utils";
import { SAVE_HTTP_RESPONSE } from "../http/http-response.action";

export function* takeHttpResponse(messageId: string) {
  try {
    let isMyMessage = false;

    while (!isMyMessage) {

      const { message } = yield take(SAVE_HTTP_RESPONSE);
      const result = extractAPIResponse(message);

      if (result.errors) {
        throw result.errors;
      }

      isMyMessage = result.messageId == messageId;

      if (isMyMessage)
        return result.responseJson;
    }
  } catch (error) {
    throw error;
  }
}
