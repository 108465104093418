import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { DesignerHomeLayoutProps } from "../../../components/src/DesignerHomeLayout.web";
import { defineMessageHandlers } from "../../../components/src/utils";
import { CollectionHome } from "../../../components/src/api-types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  layoutProps: DesignerHomeLayoutProps,
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  newCollectionName: string;
  privateCollection: boolean;
  currentLabel: number;
  arrLabels: any;
  arrCollection: any;
  profileData: any;
  popupStatus: boolean;
  newLabel: string;
  loading: boolean;
  flag: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {

  getProductApiCallId: any;
  pinMessageId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.pinCollection = this.pinCollection.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      newCollectionName: '',
      privateCollection: false,
      currentLabel: -1,
      arrLabels: {},
      arrCollection: {},
      profileData: {},
      popupStatus: false,
      newLabel: '',
      loading: false,
      flag: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const DesignPublish = window.sessionStorage.getItem("DesignPublish");
    if (DesignPublish === "true") {
      toast.success("Design Published Successfull");
      window.sessionStorage.removeItem("DesignPublish");
    }

  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start

    defineMessageHandlers({
      message,
      handlers: {
        [this.pinMessageId]: {
          onSuccess: (responseJson) => {
            const msg = this.state.flag ? "Pinned Successfully" : "Unpinned Successfully";
            toast.success(msg);
          },
          afterAll: () => {
            this.setState({ loading: false });
          }
        }
      }
    });

    // Customizable Area End
  }


  createPost = (collectionId: number) => {
    this.props.navigation.navigate("DesignerDesignUpload", {
      collectionId: collectionId,
      labelId: this.props.layoutProps.labelId,
      type: "designer",
      clubId: 0
    });
  }


  // Customizable Area Start
  pinCollection(collection: CollectionHome): any {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {
      data: {
        attributes: {
          collection_id: collection.attributes.id
        }
      }
    };

    const pinCollectionReq = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: !collection.attributes.pined ? configJSON.pinCollection : configJSON.unPinCollection,
    });

    this.pinMessageId = pinCollectionReq.messageId;
    // alert(this.pinMessageId);
    runEngine.sendMessage(pinCollectionReq.id, pinCollectionReq);
    return true;
  }

  // Customizable Area End
}
