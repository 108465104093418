import React, { useEffect, useRef, useState } from 'react';
import { trending_up } from "./assets";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import DezinerMedia from '../../../components/src/DezinerImage.web';
import { Collection } from '../../../components/src/api-types';
import { useIsMyProfile } from '../../../components/src/hooks';

const ASPECT_RATIO = 280 / 200;


const useStyles = makeStyles({
  designCollectionGroup: {
    maxWidth: "450px",
    display: "grid",
    gridTemplateAreas:
      "'main main main'\n 'left center right'\n 'text text text'",
    columnGap: "5px",
    rowGap: "8px",
    padding: "10px",
    width: "100%",
    position: "relative",
    cursor: "pointer",
    '&:hover .overlay': {
      opacity: 1,
    }
  },
  designCollectionMainImageContainer: {
    background: "lightgrey",
    height: "200px",
    textAlign: "center",
    width: "200px",
    minWidth: "100%",
    fontWeight: "bold",
    margin: "0 auto",
    cursor: "pointer",
    gridArea: "main",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    '&:hover': {
      cursor: "pointer"
    },
  },
  designCollectionMainImage: {
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    objectFit: "cover",
  },
  designCollectionMainImageOverlay: {
    gridRow: "1/2",
    gridColumn: "1/4",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.326)",
    opacity: 0,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "20px",
    zIndex: 10,
    "& button": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      background: "white",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    "& button:hover": { background: "#ddd" },
    "& button img": {
      maxWidth: "20px",
      width: "20px",
      height: "20px"
    },
  },
  collectionSubImage1: {
    gridArea: "left",
    borderBottomLeftRadius: "15px"
  },
  collectionSubImage2: { gridArea: "center" },
  collectionSubImage3: {
    gridArea: "right",
    borderBottomRightRadius: "15px"
  },
  groupTitle: {
    textAlign: "center",
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  collectionCounter: {
    textAlign: "center",
    fontSize: "0.8rem",
    color: "#bfbfbf"
  },
  groupText: { gridArea: "text" },
  mainImage: {
    '& img': {
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      height: "100%",
      width: "100%"
    }
  },
  collectionSubImage: {
    background: "lightgrey",
    height: "60px",
    textAlign: "center",
    width: "40px",
    minWidth: "100%",
    fontWeight: "bold",
    margin: "0 auto",
    cursor: "pointer",
    objectFit: "cover",
    // height: "100%", 
    // width: "100%" 
  }
});

interface DesignCollectionGroupCardProps {
  id: number,
  attributes: Collection['attributes'],
  onClick: () => void,
}


export default function DesignCollectionGroupCard(props: DesignCollectionGroupCardProps) {

  const ownerId = props?.attributes?.account?.data?.attributes?.id ?? 0;
  const isMyProfile = useIsMyProfile({ id: ownerId });

  return (
    <DesignCollectionGroupCardUI
      {...props}
      hidePromote={!isMyProfile}
    />
  );
}

function DesignCollectionGroupCardUI(props: DesignCollectionGroupCardProps & { hidePromote?: boolean }) {

  const { id, attributes, onClick, hidePromote, } = props;

  const [cardWidth, setCardWidth] = useState(window.innerWidth);
  const containerRef = useRef<any>(null);

  function updateCardWidth() {
    let items: any = document.getElementsByClassName('design-collection-group');
    let itemWidth = items?.[0]?.offsetWidth ?? 250;

    if (containerRef && containerRef.current && containerRef.current.offsetWidth) {
      let containerWidth = containerRef.current.offsetWidth;
      setCardWidth(containerWidth);
    } else if (itemWidth) {
      setCardWidth(itemWidth);
    }
  }

  useEffect(function preserveCardWidth() {
    updateCardWidth();
    window.addEventListener("resize", updateCardWidth);

    return () => window.removeEventListener("resize", updateCardWidth);
  }, [attributes, containerRef]);



  const classes = useStyles();
  const mainImageHeight = Math.max(250, cardWidth * 1 / ASPECT_RATIO);
  const subImageHeight = Math.max(50, (cardWidth / 3.5) * 1 / ASPECT_RATIO);


  return (
    <div
      className={clsx('design-collection-group', classes.designCollectionGroup)}
      onClick={() => onClick()}
      key={id}
      ref={containerRef}
    >

      {
        attributes.designs?.data?.[0]?.attributes?.image_url ?
          <>

            <DezinerMedia
              mediaType={attributes.designs?.data?.[0]?.attributes?.main_image_type?.includes('video/') ? 'video' : 'image'}
              watermarkURL={attributes?.designs?.data?.[0]?.attributes?.watermark_url}
              watermarkWidth={Number(attributes?.designs?.data?.[0]?.attributes?.width)}
              watermarkHeight={Number(attributes?.designs?.data?.[0]?.attributes?.height)}
              containerStyles={{ height: `${mainImageHeight}px` }}
              containerClassName={classes.designCollectionMainImageContainer}
              className={classes.designCollectionMainImage}
              src={attributes.designs?.data?.[0]?.attributes?.image_url}
              alt={`${attributes.name}-image-main`}
            />

            <div
              className={clsx('overlay', classes.designCollectionMainImageOverlay)}
            >
              {
                hidePromote ? <span /> :
                  <button

                    onClick={(event) => {
                      console.log({ event });
                      event.stopPropagation();
                    }}
                  >
                    <img src={trending_up} alt="promote" />
                  </button>
              }
            </div>
          </>
          :
          <div
            style={{ height: `${mainImageHeight}px` }}
            className={classes.designCollectionMainImageContainer}
          />
      }



      {
        [1, 2, 3]
          .map(index => ({
            src: attributes.designs?.data?.[index]?.attributes?.image_url ?? '',
            type: attributes.designs?.data?.[index]?.attributes?.main_image_type ?? '',
            watermark_url: attributes.designs?.data?.[index]?.attributes?.watermark_url ?? '',
            width: attributes?.designs?.data?.[index]?.attributes?.width ?? 50,
            height: attributes?.designs?.data?.[index]?.attributes?.height ?? 50,
          }))
          ?.map(({ src, type, watermark_url, width, height }: any, index) => (
            <>
              {src ?
                <DezinerMedia
                  mediaType={type?.includes('video') ? 'video' : 'image'}
                  className={clsx(classes.collectionSubImage, {
                    [classes.collectionSubImage1]: index + 1 == 1,
                    [classes.collectionSubImage2]: index + 1 == 2,
                    [classes.collectionSubImage3]: index + 1 == 3,
                  })}
                  src={src}
                  alt={`${attributes.name}-image-${index}`}
                  style={{ height: `${subImageHeight}px` }}
                  containerStyles={{ height: `${subImageHeight}px` }}
                  watermarkURL={watermark_url}
                  watermarkWidth={width}
                  watermarkHeight={height}
                />
                :
                <div
                  className={clsx(classes.collectionSubImage, {
                    [classes.collectionSubImage1]: index + 1 == 1,
                    [classes.collectionSubImage2]: index + 1 == 2,
                    [classes.collectionSubImage3]: index + 1 == 3,
                  })}
                  style={{ height: `${subImageHeight}px` }}
                />
              }
            </>
          ))
      }

      <div className={classes.groupText}>
        <div className={classes.groupTitle}>
          {attributes.name}
        </div>
        <div className={classes.collectionCounter}>
          {`${attributes.design_count} Designs`}
        </div>
      </div>

    </div>
  );
}

