import React, { useMemo, useState } from "react";
// Customizable Area Start
import "../../catalogue/src/style.css";
import "./CommunityForum.web.css";
import { chat } from "./assets";
import CreateIcon from "@material-ui/icons/Create";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from '@material-ui/core/styles';
import CommunityForumController, {
  Props,
} from "./CommunityForumController.web";
import CommunityForumTopHeaderWeb from "./CommunityForumTopHeader.web";
import ForumCard from "./ForumCard.web";
import DezinerButton from "../../../components/src/Button.web";
import CreateForumForm from "./CreateForumForm.web";
import { VerticalSpacer } from "../../../components/src/Spacer.web";
import NeedLoginDialog from "../../landingpage/src/NeedLoginDialog.web";
import DezinerSpinner from "../../../components/src/DezinerSpinner.web";
import { makeStyles } from '@material-ui/core/styles';
import { useForums, useNavigate, useCreateForum, useLikeForum, useDislikeForum, useIsLoggedIn } from "../../../components/src/hooks";
import { ForumTopNav } from "../../../components/src/DezinerTopNav.web";
import { Forum } from "../../../components/src/api-types";
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Customizable Area End


const useStyles = makeStyles({
  root: {
    background: '#f5f6f8'
  },
  contentContainer: {
    margin: '20px auto',
    maxWidth: '1100px',
    padding: '0 20px',
  },
  gridContainer: {
    minHeight: '50vh',
    display: 'block',
    columnCount: 2,
    columnGap: '20px',
    ['@media screen and (max-width: 768px)']: {
      columnCount: 1,

    }
  },

});


function CommunityForumContent() {

  const classes = useStyles();

  const [showForm, setShowForm] = useState(false);
  const [needLoginOpen, setNeedLoginOpen] = useState(false);

  const navigate = useNavigate();

  const forumsQuery = useForums();
  const { mutate: createForum, isLoading: createForumLoading } = useCreateForum();

  const isLoggedIn = useIsLoggedIn();

  const weightSum = (weight: number, forum: Forum) => {
    return weight + (forum.attributes.media ? 2 : 1)
  }

  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const [forums1, forums2] = useMemo(() => {

    if (isSmallScreen) return [[], []];

    return forumsQuery?.data?.data?.reduce((result: [Forum[], Forum[]], forum) => {

      const [_forums1, _forums2]: [Forum[], Forum[]] = result;
      const _forums1Weight = _forums1.reduce(weightSum, 0)
      const _forums2Weight = _forums2.reduce(weightSum, 0)

      if (_forums1Weight <= _forums2Weight) {
        return [_forums1.concat(forum), _forums2];
      }
      else {
        return [_forums1, _forums2.concat(forum)];
      }

    }, [[], []]) ?? [[], []];


  }, [isSmallScreen, forumsQuery?.data?.data]);

  const discussionIcon = (
    <Grid item sm={4} xs={6} container alignItems="center" wrap="nowrap">
      <img src={chat} id="chat_icon" />
      <Typography style={{ margin: '0 10px', fontSize: '1.2rem', fontWeight: 'bold' }}>
        {'Discussions'}
      </Typography>
    </Grid>
  );

  const startNewTopic = (
    <Grid item sm={4} xs={12} container justifyContent="center">
      <DezinerButton
        onClick={() => {
          if (isLoggedIn)
            setShowForm(true)
          else
            setNeedLoginOpen(true);
        }}
        color="primary"
        variant="contained"
        rounded
        startIcon={
          <CreateIcon />
        }
      >
        {'Start a new topic'}
      </DezinerButton>
    </Grid>
  );


  const sortByDropdown = (
    <Grid item sm={4} xs={6} container justifyContent="flex-end">
      <FormControl variant="outlined" >
        <Select value="Sort by : Popular" style={{ maxHeight: '30px' }}>
          <MenuItem value="Sort by : Popular">
            {'Sort by : Popular'}
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );



  return (
    <div>

      <NeedLoginDialog
        open={needLoginOpen}
        onClose={() => {
          setNeedLoginOpen(false)
        }}
        onLogin={() => {
          localStorage.setItem('redirectUrl', 'CommunityForum');
          navigate('EmailAccountLoginBlock');
        }}
      />


      <div className={classes.contentContainer}>

        <VerticalSpacer />
        <VerticalSpacer />

        {/* Forum subheader start */}
        <Grid
          direction={isSmallScreen ? 'column' : 'row'}
          container justifyContent="space-between" alignItems="center">

          {
            isSmallScreen ?
              <>
                <Grid item container justifyContent="space-between" alignItems="center">
                  {discussionIcon}
                  {sortByDropdown}
                </Grid>
                <VerticalSpacer />
                {startNewTopic}
              </>
              :
              <>
                {discussionIcon}
                {startNewTopic}
                {sortByDropdown}
              </>
          }

        </Grid>

        <VerticalSpacer />


        {
          showForm ?
            <CreateForumForm
              onSubmit={(values) => {
                createForum(values, {
                  onSuccess: () => {
                    setShowForm(false);
                  }
                })
              }}
              onCancel={() => {
                setShowForm(false);
              }}
              loading={createForumLoading}
            />
            :
            <VerticalSpacer />
        }

        {
          forumsQuery?.isLoading ?
            <DezinerSpinner />
            :
            null
        }

        <div style={{
          minHeight: '50vh',
          display: 'grid',
          gridTemplateColumns: isSmallScreen ? '100%' : '48% 48%',
          justifyContent: 'space-between',
        }}>

          {isSmallScreen ?
            <div>
              {
                forumsQuery?.data?.data?.map((forum) => (
                  <ForumCardContainer
                    key={forum.id}
                    forum={forum}
                    onAnonymousInteraction={() => setNeedLoginOpen(true)}
                  />
                )) ?? []
              }
            </div>
            :
            <>
              <div>
                {
                  forums1?.map((forum) => (
                    <ForumCardContainer
                      key={forum.id}
                      forum={forum}
                      onAnonymousInteraction={() => setNeedLoginOpen(true)}
                    />
                  )) ?? []
                }
              </div>
              <div>
                {
                  forums2?.map((forum) => (
                    <ForumCardContainer
                      key={forum.id}
                      forum={forum}
                      onAnonymousInteraction={() => setNeedLoginOpen(true)}
                    />
                  )) ?? []
                }
              </div>
            </>
          }
        </div>

        {/*
        <div
          className={classes.gridContainer}
        >
          {
            forumsQuery?.data?.data?.map((forum) => (
              <ForumCardContainer
                key={forum.id}
                forum={forum}
                onAnonymousInteraction={() => setNeedLoginOpen(true)}
              />
            )) ?? []
          }
        </div>
*/}

      </div>
    </div>
  );
}

export function ForumCardContainer({
  forum, onAnonymousInteraction, singlePageMode
}: {
  forum?: Forum, onAnonymousInteraction: () => void, singlePageMode?: boolean
}) {

  const { mutate: likeForum } = useLikeForum({ id: forum?.attributes?.id ?? 0 });
  const { mutate: dislikeForum } = useDislikeForum({ id: forum?.attributes?.id ?? 0 });

  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  return (
    <ForumCard
      forum={forum}
      singlePageMode={singlePageMode}
      onClick={() => {
        navigate(`ForumSingle/${forum?.attributes?.id}`)
      }}
      likeHandler={() => {
        if (isLoggedIn)
          likeForum({ id: forum?.attributes?.id ?? 0 })
        else
          onAnonymousInteraction()
      }}
      dislikeHandler={() => {
        if (isLoggedIn)
          dislikeForum({ id: forum?.attributes?.id ?? 0 })
        else
          onAnonymousInteraction()
      }}
    />
  );
}
class CommunityForum extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const { classes }: any = this.props;

    return (
      <div className={classes.root}>

        <CommunityForumContent />
      </div>
    );
  }
}


const styles: any = {
  root: {
    background: '#f5f6f8'
  },
  contentContainer: {
    margin: '20px auto',
    maxWidth: '1100px',
    padding: '0 20px',
  },
  gridContainer: {
    minHeight: '50vh',
    display: 'block',
    columnCount: 2,
    columnGap: '20px',
    ['@media screen and (max-width: 768px)']: {
      columnCount: 1,

    }
  },
}

// Customizable Area Start
export default withStyles(styles)(CommunityForum);
// Customizable Area End
