import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSingleDesign, useSourceFiles } from './hooks';
import DezinerAudio from './DezinerAudio.web';
import { VerticalSpacer } from './Spacer.web';

export default function DesignDescription({ designId }: { designId: number }) {

  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;

  const isBought = Boolean(designAttributes?.buyed ?? false);
  const sourceFilesQuery = useSourceFiles({ designId }, {
    enabled: isBought,
  });

  const designUrl = sourceFilesQuery?.data?.design_url ?? '';


  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item sm={6} xs={12}>
          <Typography>{designAttributes?.description}</Typography>
        </Grid>
        {
          designAttributes?.audio_urls?.[0]?.url ?
            <Grid item sm={6} xs={12} container justifyContent="flex-end">
              <DezinerAudio src={designAttributes?.audio_urls?.[0]?.url} />
            </Grid>
            :
            null
        }
      </Grid>
      {
        designUrl ?
          <>
            <VerticalSpacer />
            <Typography style={{ fontSize: '1.1rem' }}>
              <a href={designUrl} target="_blank">{designUrl}</a>
            </Typography>
          </>
          :
          null
      }
    </>
  );
}
