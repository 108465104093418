import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { useSingleDesign } from './hooks';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProfileAvatar from './ProfileAvatar.web';

const useOwnerVerticalAvatarStyles = makeStyles({
  root: {
    margin: '30px 0',
    '&:before': {
      content: '""',
      width: "100%",
      height: "2px",
      backgroundColor: "#e2e2e2",
      display: "block",
      position: "relative",
      top: "50px",
      zIndex: -1,
      margin: "auto"
    }
  },
});

export default function OwnerVerticalAvatar({ designId }: { designId: number }) {

  const classes = useOwnerVerticalAvatarStyles();

  const designQuery = useSingleDesign({ designId });
  const designAttributes = designQuery?.data?.design?.data?.attributes;
  const designerAttributes = designAttributes?.designer?.data?.attributes;


  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={12} item container justifyContent="center">
          <ProfileAvatar id={designerAttributes?.id} />
        </Grid>

        <Grid xs={12} item container justifyContent="center">
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {designerAttributes?.first_name ?? ''}
          </Typography>
        </Grid>

        <Grid xs={12} item container justifyContent="center">
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            {'Designer'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
