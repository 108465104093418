
import React from 'react';
import DezinerTopNav from './DezinerTopNav.web';
import { useDecodedToken, useIsLoggedIn, useIsUser, useNavigate, useProfile } from './hooks';

const configJSON = require('./config.js');



export default function TopNavLayout(props: { children: any }) {


  const { id: designerId } = useDecodedToken();

  const isUser = useIsUser();
  const isLoggedIn = useIsLoggedIn();

  const profileQuery = useProfile({ id: designerId });

  const navigate = useNavigate();
  const profileData = profileQuery?.data?.data?.attributes;

  return (
    <div>

      <DezinerTopNav
        hideAvatarMenu={isUser}
        role={isUser ? "User" : "Designer"}
        isLoggedIn={isLoggedIn}
        onSignInAsDesigner={() => navigate("EmailAccountLoginBlock")}
        onSignInAsUser={() => navigate("EmailAccountLoginBlock")}
        profileImage={profileData?.profile_thumbnail_url ?? ''}
        color={profileData?.color ?? ''}
        designerInfo={{
          logoutHandler: () => {
            window.localStorage.clear();
            navigate("EmailAccountLoginBlock");
          },
          promoteHandler: () => {
            //this.promoteProfile(profileData?.profile_url ?? '');
          },
          editProfileHandler: () => navigate('DesignerEditProfile'),
          groupClickHandler: () => navigate("DesignerGroupPageView"),
          clubClickHandler: () => navigate("DesignClubPageView"),
          onMySelfClickHandler: () => {
            if (designerId)
              navigate(`DesignerHome/${designerId}`);
          }
        }}
        onLogoClick={() => navigate("LandingPage")}
        onNotificationClick={() => navigate("NotificationsWeb")}
        userInfo={{
          onProfileClick: () => navigate('UserEditProfile'),
        }}
      />



      {props?.children}

    </div>
  );
}

