// App.js - WEB
import React, { Component, ReactNode } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import './assets/css/global.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider as ReduxProvider } from 'react-redux';
import ReduxDezinerMiddleware from '../../components/src/store/redux-deziner-middleware.web';

import { ConnectedRouter } from 'connected-react-router'
import { dezinerHistory, dezinerReduxStore } from '../../components/src/store/store';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import PushNotifications from '../../blocks/PushNotifications/src/Pushnotifications';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import Videos4 from '../../blocks/Videos4/src/Videos4';
import CfWatermarkImages2 from '../../blocks/CfWatermarkImages2/src/CfWatermarkImages2';
import Groups from '../../blocks/Groups/src/Groups';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
// import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import CfPinDesigns2 from '../../blocks/CfPinDesigns2/src/CfPinDesigns2';
import Comments from '../../blocks/Comments/src/Comments';
import CustomAdvertisements from '../../blocks/CustomAdvertisements/src/CustomAdvertisements';
import CfMicrosoftLogin3 from '../../blocks/CfMicrosoftLogin3/src/CfMicrosoftLogin3';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import RecommendationEngine4 from '../../blocks/RecommendationEngine4/src/RecommendationEngine4';
import Favourites from '../../blocks/Favourites/src/Favourites';
import HelpCentre from '../../blocks/helpcentre/src/HelpCentre';
import HelpCentreQA from '../../blocks/helpcentre/src/HelpCentreQA';
import HelpCentreSub from '../../blocks/helpcentre/src/HelpCentreSub';
import Download from '../../blocks/Download/src/Download';
import Share from '../../blocks/Share/src/Share';
import Notifications from '../../blocks/Notifications/src/Notifications';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import PhotoLibrary3 from '../../blocks/PhotoLibrary3/src/PhotoLibrary3';
import LiveChat2 from '../../blocks/LiveChat2/src/LiveChat2';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import CommunityForum from '../../blocks/CommunityForum/src/CommunityForum.web';
import LoyaltySystem2 from '../../blocks/LoyaltySystem2/src/LoyaltySystem2';
import Wishlist2 from '../../blocks/Wishlist2/src/Wishlist2';
import Analytics3 from '../../blocks/Analytics3/src/Analytics3';
// import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import CfThirdPartyPaymentGateway4 from '../../blocks/CfThirdPartyPaymentGateway4/src/CfThirdPartyPaymentGateway4';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import TwilioIntegration2 from '../../blocks/TwilioIntegration2/src/TwilioIntegration2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Settings2 from '../../blocks/Settings2/src/Settings2';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
//import LandingPage from '../../blocks/landingpage/src/LandingPage';
import Followers from '../../blocks/Followers/src/Followers';
import CfScreenshotRestriction23 from '../../blocks/CfScreenshotRestriction23/src/CfScreenshotRestriction23';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import EmailAccountLoginBlockWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import LandingPageWeb from '../../blocks/landingpage/src/LandingPage.web';
import EmailAccountLoginViaOtpBlock from '../../blocks/email-account-login/src/EmailAccountLoginViaOtpBlock.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPasswordWeb.web';
import DesignerDesignUpload from '../../blocks/catalogue/src/DesignerDesignUpload';
import CatalogueWeb from '../../blocks/catalogue/src/Catalogue.web';
import DesignerHome from '../../blocks/landingpage/src/DesignerHome.web';
import DesignerCollectionView from '../../blocks/landingpage/src/DesignerCollectionView.web';
import DesignerDesignView from '../../blocks/landingpage/src/DesignerDesignView.web';
import DesignViewByUser from '../../blocks/landingpage/src/DesignViewByUser.web';
import { ToastContainer, toast } from 'react-toastify';
import CollectionViewByDesigner from '../../blocks/landingpage/src/CollectionViewByDesigner.web';
import UserHome from '../../blocks/landingpage/src/UserHome.web';
import DesignClubPageView from '../../blocks/Groups/src/DesignClubPageView.web';
import ClubDesignView from '../../blocks/Groups/src/ClubDesignView.web';
import ClubAboutPage from '../../blocks/Groups/src/ClubAboutPage.web';
import DesignerClubManage from '../../blocks/Groups/src/DesignerClubManage.web';
import CreateGroup from '../../blocks/Groups/src/CreateGroup.web';
import CreateClub from '../../blocks/Groups/src/CreateClub.web';
import DesignerProfileByUser from '../../blocks/landingpage/src/DesignerProfileByUser.web';
import AddClubMember from '../../blocks/Groups/src/AddClubMember.web';
import GroupProfileByUser from '../../blocks/Groups/src/GroupProfileByUser.web';
import ClubProfileByUser from '../../blocks/Groups/src/ClubProfileByUser.web';
import DesignerFollowerPageByUser from '../../blocks/Followers/src/DesignerFollowerPageByUser.web';
import ClubHomePage from '../../blocks/Groups/src/ClubHomePage.web';
import ForumSingle from '../../blocks/CommunityForum/src/ForumSingle.web';
import UserEditProfile from '../../blocks/landingpage/src/UserEditProfile.web';
import UserProfileByOthers from '../../blocks/landingpage/src/UserProfileByOthers.web';
import UserEditAccount from '../../blocks/landingpage/src/UserEditAccount.web';
import UserDesignList from '../../blocks/landingpage/src/UserDesignList.web';
import UserEditAbout from '../../blocks/landingpage/src/UserEditAbout.web';
import UserEditPassword from '../../blocks/landingpage/src/UserEditPassword.web';
import UserProfileByOthersRated from '../../blocks/landingpage/src/UserProfileByOthersRated.web';
import UserUploadedDesignView from '../../blocks/landingpage/src/UserUploadedDesignView.web';
import DesignerGroupPageView from '../../blocks/Groups/src/DesignerGroupPageView.web';
import GroupHomePage from '../../blocks/Groups/src/GroupHomePage.web';
import DesignerGroupManage from '../../blocks/Groups/src/DesignerGroupManage.web';
import GroupDesignViewByDesigner from '../../blocks/Groups/src/GroupDesignViewByDesigner.web';
import GroupDesignViewByUser from '../../blocks/Groups/src/GroupDesignViewByUser.web';
import ClubDesignViewByUser from '../../blocks/Groups/src/ClubDesignViewByUser.web';
import ClubDesignViewByDesigner from '../../blocks/Groups/src/ClubDesignViewByDesigner.web';
import DesignBuy from '../../blocks/landingpage/src/DesignBuy.web';
import AddGroupMember from '../../blocks/Groups/src/AddGroupMember.web';
import UserProfileCollection from '../../blocks/landingpage/src/UserProfileCollection.web';
import GroupAboutPage from '../../blocks/Groups/src/GroupAboutPage.web.tsx';
import DesignerAboutPage from '../../blocks/landingpage/src/DesignerAboutPage.web.tsx';
import NotificationsWeb from '../../blocks/Notifications/src/NotificationsWeb.web';
import ClubCreateCollection from '../../blocks/catalogue/src/ClubCreateCollection.web';
import GroupCreateCollection from '../../blocks/catalogue/src/GroupCreateCollection.web';
import ArchiveDesigns from '../../blocks/ContentManagement/src/ArchiveDesigns.web';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import DesignerEditProfile from '../../blocks/landingpage/src/DesignerEditProfilePage.web';
// const token = localStorage.getItem("token");
import DesignerEditSocialProfiles from '../../blocks/landingpage/src/DesignerEditSocialProfiles.web';
import DesignerEditPasswords from '../../blocks/landingpage/src/DesignerEditPasswordsPage.web';
import DesignerAccountSettingsPage from '../../blocks/landingpage/src/DesignerAccountSettingsPage.web';
import EmailIsActivatedPage from '../../blocks/landingpage/src/EmailIsActivatedPage.web';
import TermsOfServicePage from '../../blocks/email-account-registration/src/TermsOfService.web';
import PrivacyPolicyPage from '../../blocks/email-account-registration/src/PrivacyPolicy.web';
import DesignerDesignEdit from '../../blocks/catalogue/src/DesignerDesignEditPage.web';
import DesignerProfileLayout from '../../components/src/DesignerProfileLayout.web';
import FooterWeb from '../../blocks/catalogue/src/Footer.web';
import DesignerTopNavLayout from '../../components/src/DesignerTopNavLayout.web';
import ClubLayout from '../../components/src/ClubLayout.web';
import DesignerHomeLayout from '../../components/src/DesignerHomeLayout.web';
import DesignerProfileByUserLayout from '../../components/src/DesignerProfileByUserLayout.web';
import FAQPage from '../../blocks/landingpage/src/FAQPage.web';
import CodeOfConductPage from '../../blocks/landingpage/src/CodeOfConductPage.web';
import CookiePolicyPage from '../../blocks/landingpage/src/CookiePolicyPage.web';
import CopyrightPolicyPage from '../../blocks/landingpage/src/CopyrightPolicyPage.web';
import ScrollToTop from '../../components/src/ScrollToTop.web';
import VerifyEmailPage from '../../blocks/email-account-login/src/VerifyEmailPage.web';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import UserProfileLayout from '../../components/src/UserProfileLayout.web';
import { RouterContextProvider, useParams } from '../../components/src/utils';
import DesignViewPage from '../../blocks/landingpage/src/DesignView.web';
import CollectionPage from '../../blocks/landingpage/src/CollectionPage.web';
import { useDecodedToken, useIsMyProfile, useNavigate, useSingleDesign } from '../../components/src/hooks';
import { ForumTopNav } from '../../components/src/DezinerTopNav.web';
import ForumEditPage from '../../blocks/CommunityForum/src/ForumEdit.web';


const dezinerQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000 * 60,   // 10 minutes 
    }
  }
});


function ForumTopNavLayout(props) {
  const { children, ...otherProps } = props;
  return (
    <>
      <ForumTopNav {...otherProps} />
      {children}
    </>
  );
}

function FooterLayout(props) {
  const { children, ...otherProps } = props;
  return (
    <>
      {children}
      <FooterWeb {...otherProps} />
    </>
  );
}

function IsMyProfileGuard({ children }) {

  const params = useParams();
  const navigate = useNavigate();

  const isMyProfile = useIsMyProfile({ id: params.designerId });

  if (!isMyProfile) {
    navigate('EmailAccountLoginBlock');
  }

  return (<>{children}</>);
}

function LoginGuard({ children, ...props }) {

  let token = localStorage.getItem('token');

  if (!token) {
    props.navigation.navigate('EmailAccountLoginBlock');
  }

  return (<>{children}</>);
}

function DesignOwnerOnlyCanAccessGuard({ children, ...props }) {

  const { id: userId } = useDecodedToken();

  const params = useParams();
  const designId = parseInt(params.designId || '0');

  const singleDesignQuery = useSingleDesign({ designId });
  const ownerId = singleDesignQuery?.data?.design?.data?.attributes?.designer?.data?.attributes?.id ?? 0;

  const isTheOwner = singleDesignQuery?.isSuccess ? Boolean(ownerId == userId) : true;

  if (!designId || !userId || !isTheOwner) {
    props.navigation.navigate('EmailAccountLoginBlock');
  }

  return (<>{children}</>);
}



const makeWrapper = (component, wrapper) =>
  (props) => React.createElement(wrapper, props, React.createElement(component, props));

function DezinerPageWrapper({ page, layouts, guards, providers }) {

  let pageGuards = guards || [];
  let pageLayouts = layouts || [];
  let pageProviders = providers || [];

  let wrappers = pageLayouts.concat(pageGuards, pageProviders);

  const pageComponent = (props) => React.createElement(page, props);

  return wrappers.reduce(makeWrapper, pageComponent);
}
/*
function ComponentLayoutWrapper({ layout, component, }) {
  return (props) => (
    React.createElement(layout, props, React.createElement(component, props))
  );
}
*/


const routeMap = {
  Collection: {
    path: '/collection/:collectionId',
    component: DezinerPageWrapper({
      page: CollectionPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
      guards: [],
      providers: [RouterContextProvider],
    }),
  },
  DesignView: {
    path: '/DesignView/:designId',
    component: DezinerPageWrapper({
      page: DesignViewPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
      guards: [],
      providers: [RouterContextProvider],
    })
  },
  VerifyEmailPage: {
    path: '/verify',
    component: DezinerPageWrapper({
      page: VerifyEmailPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
      guards: [],
    }),
  },
  DesignerEditProfile: {
    component: DezinerPageWrapper({
      page: DesignerEditProfile,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerEditProfile'
  },
  DesignerAccountSettings: {
    component: DezinerPageWrapper({
      page: DesignerAccountSettingsPage,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerAccountSettings'
  },
  DesignerGroupPageView: {
    component: DezinerPageWrapper({
      page: DesignerGroupPageView,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerGroupPageView'
  },
  DesignerEditPasswords: {
    component: DezinerPageWrapper({
      page: DesignerEditPasswords,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerEditPasswords'
  },
  DesignerEditSocialProfiles: {
    component: DezinerPageWrapper({
      page: DesignerEditSocialProfiles,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerEditSocialProfiles',
  },
  DesignClubPageView: {
    component: DezinerPageWrapper({
      page: DesignClubPageView,
      layouts: [DesignerProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignClubPageView'
  },

  DesignerDesignEdit: {
    component: DezinerPageWrapper({
      page: DesignerDesignEdit,
      guards: [LoginGuard, DesignOwnerOnlyCanAccessGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerDesignEdit/:designId/:type?/:clubOrGroupId?'
  },
  TermsOfService: {
    component: DezinerPageWrapper({
      page: TermsOfServicePage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/TermsOfService'
  },
  FAQ: {
    component: DezinerPageWrapper({
      page: FAQPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/FAQ'
  },
  EmailIsActivated: {
    component: EmailIsActivatedPage,
    path: '/EmailIsActivated'
  },
  Followers: {
    component: Followers,
    path: '/Followers'
  },
  CfScreenshotRestriction23: {
    component: CfScreenshotRestriction23,
    path: '/CfScreenshotRestriction23'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },

  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  LandingPage: {
    component: DezinerPageWrapper({
      page: LandingPageWeb,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/LandingPage',
    exact: true
  },
  LandingPageWeb: {
    component: LandingPageWeb,
    path: '/LandingPageWeb',
    exact: true
  },

  Catalogue: {
    component: DezinerPageWrapper({
      page: CatalogueWeb,
      layouts: [DesignerHomeLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard, IsMyProfileGuard],
      providers: [RouterContextProvider],
    }),
    path: '/Catalogue/:designerId?'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  PushNotifications: {
    component: PushNotifications,
    path: '/PushNotifications'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
  Videos4: {
    component: Videos4,
    path: '/Videos4'
  },
  CfWatermarkImages2: {
    component: CfWatermarkImages2,
    path: '/CfWatermarkImages2'
  },
  Groups: {
    component: Groups,
    path: '/Groups'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  AddReview: {
    component: AddReview,
    path: '/AddReview'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  // SocialMediaAccountLoginScreen: {
  //   component: SocialMediaAccountLoginScreen,
  //   path: '/SocialMediaAccountLoginScreen'
  // },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  CfPinDesigns2: {
    component: CfPinDesigns2,
    path: '/CfPinDesigns2'
  },
  Comments: {
    component: Comments,
    path: '/Comments'
  },
  CustomAdvertisements: {
    component: CustomAdvertisements,
    path: '/CustomAdvertisements'
  },
  CfMicrosoftLogin3: {
    component: CfMicrosoftLogin3,
    path: '/CfMicrosoftLogin3'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlockWeb,
    path: '/EmailAccountLoginBlock',
    exact: true
  },
  EmailAccountLoginBlockWeb: {
    component: EmailAccountLoginBlockWeb,
    path: '/EmailAccountLoginBlockWeb',
    exact: true
  },
  /*EmailAccountLoginViaOtpBlock: {
    component: EmailAccountLoginViaOtpBlock,
    path: '/EmailAccountLoginViaOtpBlock',
    exact: true
  },*/
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
    exact: true
  },
  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: '/EmailAccountRegistrationWeb',
    exact: true
  },
  RecommendationEngine4: {
    component: RecommendationEngine4,
    path: '/RecommendationEngine4'
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: '/HelpCentreQA'
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: '/HelpCentreSub'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/ForgotPassword'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: '/PhotoLibrary3'
  },
  LiveChat2: {
    component: LiveChat2,
    path: '/LiveChat2'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  CommunityForum: {
    component: DezinerPageWrapper({
      page: CommunityForum,
      layouts: [ForumTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/CommunityForum'
  },
  LoyaltySystem2: {
    component: LoyaltySystem2,
    path: '/LoyaltySystem2'
  },
  Wishlist2: {
    component: Wishlist2,
    path: '/Wishlist2'
  },
  Analytics3: {
    component: Analytics3,
    path: '/Analytics3'
  },
  // SocialMediaAccountRegistrationScreen: {
  //   component: SocialMediaAccountRegistrationScreen,
  //   path: '/SocialMediaAccountRegistrationScreen'
  // },
  CfThirdPartyPaymentGateway4: {
    component: CfThirdPartyPaymentGateway4,
    path: '/CfThirdPartyPaymentGateway4'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  TwilioIntegration2: {
    component: TwilioIntegration2,
    path: '/TwilioIntegration2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },

  Home: {
    component: DezinerPageWrapper({
      page: LandingPageWeb,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  DesignerDesignUpload: {
    component: DezinerPageWrapper({
      page: DesignerDesignUpload,
      guards: [],
    }),
    path: '/DesignerDesignUpload/:labelId/:collectionId/:type/:clubId'
  },
  DesignerHome: {
    component: DezinerPageWrapper({
      page: DesignerHome,
      layouts: [DesignerHomeLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerHome/:designerId?/:category?'
  },
  DesignerCollectionView: {
    component: DezinerPageWrapper({
      page: DesignerCollectionView,
      layouts: [FooterLayout, DesignerHomeLayout],
      guards: [LoginGuard]
    }),
    path: '/DesignerCollectionView/:screenState'
  },
  DesignerDesignView: {
    component: DezinerPageWrapper({
      page: DesignerDesignView,
      layouts: [DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/DesignerDesignView/:id/:type?/:clubOrGroupId?'
  },

  DesignViewByUser: {
    component: DezinerPageWrapper({
      page: DesignViewByUser,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/DesignViewByUser/:id'
  },
  CollectionViewByDesigner: {
    component: DezinerPageWrapper({
      page: CollectionViewByDesigner,
      layouts: [DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/CollectionViewByDesigner/:labelId/:collectionId'
  },
  UserHome: {
    component: DezinerPageWrapper({
      page: UserHome,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/UserHome'
  },
  ClubDesignView: {
    component: ClubDesignView,
    path: '/ClubDesignView/:id'
  },
  ClubAboutPage: {
    component: DezinerPageWrapper({
      page: ClubAboutPage,
      layouts: [ClubLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/ClubAboutPage/:id'
  },
  CreateGroup: {
    component: CreateGroup,
    path: '/CreateGroup/:id'
  },
  CreateClub: {
    component: CreateClub,
    path: '/CreateClub/:id'
  },
  ClubHomePage: {
    component: DezinerPageWrapper({
      page: ClubHomePage,
      layouts: [ClubLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/ClubHomePage/:id'
  },
  DesignerClubManage: {
    component: DezinerPageWrapper({
      page: DesignerClubManage,
      layouts: [DesignerProfileLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/DesignerClubManage/:id'
  },
  DesignerProfileByUser: {
    component: DezinerPageWrapper({
      page: DesignerProfileByUser,
      layouts: [DesignerProfileByUserLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/DesignerProfileByUser/:id/:screenState?'
  },
  AddClubMember: {
    component: DezinerPageWrapper({
      page: AddClubMember,
      layouts: [DesignerProfileLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/AddClubMember/:id'
  },
  GroupProfileByUser: {
    component: GroupProfileByUser,
    path: '/GroupProfileByUser/:id'
  },
  ClubProfileByUser: {
    component: ClubProfileByUser,
    path: '/ClubProfileByUser/:id'
  },
  DesignerFollowerPageByUser: {
    component: DesignerFollowerPageByUser,
    path: '/DesignerFollowerPageByUser/:id'
  },
  ForumSingle: {
    component: DezinerPageWrapper({
      page: ForumSingle,
      layouts: [ForumTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/ForumSingle/:id'
  },
  ForumEdit: {
    component: DezinerPageWrapper({
      page: ForumEditPage,
      layouts: [ForumTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/ForumEdit/:forumId'
  },
  UserEditProfile: {
    component: DezinerPageWrapper({
      page: UserEditProfile,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [],
    }),
    path: '/UserEditProfile'
  },
  UserProfileByOthers: {
    component: UserProfileByOthers,
    path: '/UserProfileByOthers/:id'
  },
  UserEditAccount: {
    component: DezinerPageWrapper({
      page: UserEditAccount,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/UserEditAccount'
  },
  UserDesignList: {
    component: DezinerPageWrapper({
      page: UserDesignList,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/UserDesignList/:filterName?'
  },
  UserEditAbout: {
    component: DezinerPageWrapper({
      page: UserEditAbout,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      providers: [RouterContextProvider],
    }),
    path: '/UserEditAbout'
  },
  UserEditPassword: {
    component: DezinerPageWrapper({
      page: UserEditPassword,
      layouts: [UserProfileLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/UserEditPassword'
  },
  UserProfileByOthersRated: {
    component: UserProfileByOthersRated,
    path: '/UserProfileByOthersRated/:id'
  },
  UserUploadedDesignView: {
    component: UserUploadedDesignView,
    path: '/UserUploadedDesignView/:id'
  },
  GroupHomePage: {
    component: GroupHomePage,
    path: '/GroupHomePage/:id'
  },
  DesignerGroupManage: {
    component: DezinerPageWrapper({
      page: DesignerGroupManage,
      layouts: [DesignerProfileLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/DesignerGroupManage/:id'
  },
  GroupDesignViewByDesigner: {
    component: GroupDesignViewByDesigner,
    path: '/GroupDesignViewByDesigner/:id'
  },
  GroupDesignViewByUser: {
    component: GroupDesignViewByUser,
    path: '/GroupDesignViewByUser/:id'
  },
  ClubDesignViewByUser: {
    component: ClubDesignViewByUser,
    path: '/ClubDesignViewByUser/:id'
  },
  ClubDesignViewByDesigner: {
    component: ClubDesignViewByDesigner,
    path: '/ClubDesignViewByDesigner/:id'
  },
  DesignBuy: {
    component: DezinerPageWrapper({
      page: DesignBuy,
      layouts: [],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignBuy/:designId'
  },
  AddGroupMember: {
    component: DezinerPageWrapper({
      page: AddGroupMember,
      layouts: [DesignerProfileLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/AddGroupMember/:id'
  },
  UserProfileCollection: {
    component: UserProfileCollection,
    path: '/UserProfileCollection'
  },
  GroupAboutPage: {
    component: GroupAboutPage,
    path: '/GroupAboutPage/:id'
  },
  DesignerAboutPage: {
    component: DezinerPageWrapper({
      page: DesignerAboutPage,
      layouts: [DesignerHomeLayout, DesignerTopNavLayout, FooterLayout],
      guards: [LoginGuard],
      providers: [RouterContextProvider],
    }),
    path: '/DesignerAboutPage/:designerId?'
  },
  NotificationsWeb: {
    component: NotificationsWeb,
    path: '/NotificationsWeb'
  },
  ClubCreateCollection: {
    component: DezinerPageWrapper({
      page: ClubCreateCollection,
      layouts: [ClubLayout, FooterLayout],
      guards: [LoginGuard],
    }),
    path: '/ClubCreateCollection/:id'
  },
  GroupCreateCollection: {
    component: GroupCreateCollection,
    path: '/GroupCreateCollection/:id'
  },
  ArchiveDesigns: {
    component: ArchiveDesigns,
    path: '/ArchiveDesigns'
  },
  PrivacyPolicy: {
    component: DezinerPageWrapper({
      page: PrivacyPolicyPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/PrivacyPolicy'
  },
  CodeOfConduct: {
    component: DezinerPageWrapper({
      page: CodeOfConductPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/CodeOfConduct'
  },
  CopyrightPolicy: {
    component: DezinerPageWrapper({
      page: CopyrightPolicyPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/CopyrightPolicy'
  },
  CookiePolicy: {
    component: DezinerPageWrapper({
      page: CookiePolicyPage,
      layouts: [DesignerTopNavLayout, FooterLayout],
    }),
    path: '/CookiePolicy'
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

const MaterialUITheme = createTheme({
  typography: {
    fontFamily: 'Calibri'
  },
  palette: {
    primary: {
      main: '#000'
    },
    secondary: {
      main: '#2681FD'
    },
  },
  overrides: {
    MuiButton: {
      color: '#18181A',
      backgroundColor: '#E2E3E5',
    }
  }
});


class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <ThemeProvider theme={MaterialUITheme}>
        <View style={{ height: 'auto' }}>

          <QueryClientProvider client={dezinerQueryClient}>
            <ReactQueryDevtools />
            <ReduxProvider store={dezinerReduxStore}>
              <ConnectedRouter history={dezinerHistory}>
                <>
                  <ScrollToTop />
                  <ReduxDezinerMiddleware />

                  {WebRoutesGenerator({ routeMap })}
                </>
              </ConnectedRouter>
            </ReduxProvider>
          </QueryClientProvider>

          <ModalContainer />
          {/* <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </View>
      </ThemeProvider>
    );
  }
}

export default App;
