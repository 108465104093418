import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DesignerEditProfilePageController, { Props } from './DesignerEditProfilePageController.web';
import DesignerEditProfileForm from './DesignerEditProfileForm.web';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { DezinerLabel } from '../../../components/src/TextField.web';
import DezinerButton from '../../../components/src/Button.web';
import { withStyles } from '@material-ui/core/styles';
import { VerticalSpacer } from '../../../components/src/Spacer.web';
import { UpdateBankAccountDialog } from './UpdateBankAccountDialog.web';
import { UpdateEKYCDialog } from './UpdateEKYCDialog.web';
import { useAadhaarVerify, useDecodedToken, useEditProfile, useMyProfile, useSubmitBankAccount, useUploadProfileImage, useValidateAadhaarOtp } from '../../../components/src/hooks';






class DesignerEditProfilePage extends DesignerEditProfilePageController {

  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {


    return (
      <EditProfilePageContent />
    )
  }
}


const styles: any = {
  container: {
    margin: '20px auto',
    width: '400px'
  },
}

const useStyles = makeStyles(styles);





function EditProfilePageContent() {

  const [updateEkycOpen, setUpdateEkycOpen] = useState(false);
  const [updateAccountOpen, setUpdateAccountOpen] = useState(false);

  const classes = useStyles();
  const { id } = useDecodedToken();

  const profileQuery = useMyProfile();
  const profileData = profileQuery?.data?.data?.attributes;
  const { mutate: uploadProfileImage, isLoading: uploadLoading } = useUploadProfileImage();
  const { mutate: updateProfile, isLoading: submitLoading } = useEditProfile();
  const { mutateAsync: onVerify, data: verifyResponse, error: verifyError } = useAadhaarVerify({ id });
  const { mutate: onValidateOtp } = useValidateAadhaarOtp({ id });

  const { mutate: submitBankAccount, isLoading: submitBankAccountLoading } = useSubmitBankAccount({ id });


  return (
    <div>

      <UpdateEKYCDialog
        open={updateEkycOpen}
        onClose={() => {
          setUpdateEkycOpen(false);
        }}
        onSubmit={(values) => {
          onValidateOtp({
            clientId: verifyResponse?.account?.data?.client_id,
            otp: values.otp
          });
        }}
        onVerify={async (value: any) => {
          try {
            await onVerify({ aadhaar_number: value })
            return true;
          } catch (error) {
            return false;
          }
        }}
        isInvalidOTP={Boolean(verifyError)}
      />

      <UpdateBankAccountDialog
        loading={submitBankAccountLoading}
        open={updateAccountOpen}
        onClose={() => {
          setUpdateAccountOpen(false);
        }}
        onSubmit={(values) => submitBankAccount({
          branch: values.branch,
          bank_name: values.bankName,
          ifsc_code: values.bankIFSC,
          account_name: values.bankAccountName,
          account_number: values.bankAccountNumber
        }, {
          onSuccess: () => {
            setUpdateEkycOpen(false);
          }
        })}
      />




      <DesignerEditProfileForm
        onProfileImageUpload={({ profileImage }) => {
          uploadProfileImage({ profileImage });
        }}
        onSubmit={(values) => {
          updateProfile({
            bio: values.bio,
            location: values.location,
            first_name: values.name
          })
        }}
        initialValues={{
          bio: profileData?.bio ?? '',
          name: profileData?.first_name ?? '',
          location: profileData?.location ?? '',
          profileImage: profileData?.profile_url ?? '',
        }}
        submitLoading={submitLoading}
        uploadLoading={uploadLoading}
      />


      <div className={classes.container}>

        <VerticalSpacer />
        <Divider />
        <VerticalSpacer />

        <Grid container justifyContent="space-between" alignItems="center">
          <DezinerLabel>
            {'Bank Account'}
          </DezinerLabel>

          <DezinerButton
            variant="contained"
            onClick={() => setUpdateAccountOpen(true)}
          >
            {'Update'}
          </DezinerButton>
        </Grid>


        <VerticalSpacer />
        <Divider />
        <VerticalSpacer />

        <Grid container justifyContent="space-between" alignItems="center">
          <DezinerLabel>
            {'E-KYC'}
          </DezinerLabel>

          <DezinerButton
            variant="contained"
            onClick={() => setUpdateEkycOpen(true)}
          >
            {'Update'}
          </DezinerButton>
        </Grid>

      </div>


    </div>
  );
}




export default withStyles(styles)(DesignerEditProfilePage);

