import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { dezinerMdComponents } from '../../../components/src/DezinerMarkdown.web';
import Grid from '@material-ui/core/Grid';

import { termsOfServiceContent } from './TermsOfServiceContent';

const useStyles = makeStyles({
  content: {
    maxWidth: '1000px', margin: '40px auto', padding: '20px',
    '&>*': {
      margin: '15px 0px',
    }
  },
  title: {
    fontSize: '4rem',
    textAlign: 'center',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.3rem',
    color: '#333333',
    textAlign: 'center',
  },
});

////////////////

export default function TermsOfServicePage() {

  const classes = useStyles();

  return (
    <div>

      <div className={classes.content}>

        <Grid container justifyContent="center">
          <Typography variant="h1" className={classes.title}>
            {'Terms of Service'}
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {'Read our terms below to learn more about your rights and responsibilities as a Deziner’s Knot user.'}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Typography>
            {'Updated June 17, 2022'}
          </Typography>
        </Grid>
        <hr />

        <ReactMarkdown components={dezinerMdComponents}>
          {termsOfServiceContent}
        </ReactMarkdown>

      </div>
    </div>
  );
}
