import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import DezinerSpinner from './DezinerSpinner.web';
import FormikTextInput from './FormikTextField.web';
import { VerticalSpacer } from './Spacer.web';
import { DezinerLabel } from './TextField.web';
import DezinerButton from './Button.web';
import { useDeleteAccount, useMyProfile, useUpdateUsernameAndEmail } from './hooks';


const useStyles = makeStyles({
  container: {
    margin: '50px auto',
    width: '400px'
  },
  usernameLink: {
    color: '#ababab', fontWeight: 'bold', fontSize: '0.9rem',
  },
  bold: {
    fontWeight: 'bold',
  },
  closeAccountText: {
    fontSize: '1.2rem',
  }
});

const accountSettingValidationSchema = yup.object().shape({
  username: yup.string().required(),
  email: yup.string().email().required(),
});




export default function AccountSettingForm() {

  const classes = useStyles();

  const { mutate: updateProfile, isLoading: updateProfileLoading } = useUpdateUsernameAndEmail();

  const { mutate: deleteProfile, isLoading: deleteProfileLoading, } = useDeleteAccount();

  const profileQuery = useMyProfile();

  const profileAttributes = profileQuery?.data?.data?.attributes;

  const disableUsernameEdit = profileAttributes?.updated_at &&
    (profileAttributes?.user_name_days ?? 0) < 15 ? true : false;



  if (profileQuery.isLoading)
    return <DezinerSpinner />;

  return (
    <Formik
      initialValues={{
        username: profileAttributes?.user_name ?? '',
        email: profileAttributes?.email ?? '',
      }}
      enableReinitialize
      onSubmit={({ username, email }) => {
        updateProfile({ username, email });
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={accountSettingValidationSchema}
    >
      {formik => <Form translate="">

        <Grid direction='column' alignItems="center" className={classes.container}>


          <FormikTextInput
            name="username"
            autoHeight
            label="Username"
            fullWidth
            disabled={disableUsernameEdit}
            variant="outlined"
          />

          <Typography className={classes.usernameLink}>
            {`Your Deziner's URL: https://dezinersknot.com/${formik?.values?.username}`}
          </Typography>

          <VerticalSpacer />

          <FormikTextInput
            autoHeight
            name="email"
            label="Email"
            fullWidth
            disabled
            variant="outlined"
          />


          <VerticalSpacer />

          <DezinerLabel><b>{'Account Changes'}</b></DezinerLabel>

          <VerticalSpacer />
          <Divider />
          <VerticalSpacer />

          <DezinerLabel className={classes.closeAccountText}>
            <b>{'Close Account'}</b>
          </DezinerLabel>

          <Grid
            container justifyContent="space-between" alignItems="center"
          >
            <Typography>
              {'Delete your account and account data'}
            </Typography>
            <DezinerButton
              variant="contained"
              onClick={() => {
                deleteProfile({});
              }}
              className={classes.bold}
              loading={deleteProfileLoading}
            >

              {"Close Account"}
            </DezinerButton>
          </Grid>

          <VerticalSpacer />

          <Divider />

          <VerticalSpacer />

          <Grid container justifyContent="flex-end">
            <DezinerButton
              variant="contained"
              color="primary"
              disabled={disableUsernameEdit}
              type="submit"
              className={classes.bold}
              loading={updateProfileLoading}
            >
              {"Save Changes"}
            </DezinerButton>
          </Grid>

        </Grid>

      </Form>}
    </Formik>
  );
}
