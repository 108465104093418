import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { toast } from "react-toastify";
import { DesignerProfileByUserLayoutProps } from '../../../components/src/DesignerProfileByUserLayout.web';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  layoutProps: DesignerProfileByUserLayoutProps,
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: any;
  token: string;
  newCollectionName: string;
  privateCollection: boolean;
  currentLabel: any;
  arrLabels: any;
  arrCollection: any;
  profileData: any;
  popupStatus: boolean;
  newLabel: string;
  loading: boolean;
  designerDesigns: any;
  designerScreen: string;
  selectedFilter: string;
  modalOpen: boolean;
  currentImage: string;
  designerId: number;
  selfProfileData: any;
  flag: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesingerProfileByUserController extends BlockComponent<
  Props,
  S,
  SS
  > {
  getProductApiCallId: any;
  labelsMessageId: string;
  collectionMessageId: string;
  profileMessageId: string;
  newCollectionMessageId: string;
  newLabelMessageId: string;
  designerDesignsId: string;
  designerLikedDesignsId: string;
  designerPromotedDesignsId: string;
  designerInspiredDesignsId: string;
  designerRatedDesignsId: string;
  designerFreeDesignsId: string;
  designerPaidDesignsId: string;
  selfProfileMessageId: string;
  pinMessageId: string;
  marks: any = [
    {
      value: 10000,
      label: "10,000",
    },
    {
      value: 100000,
      label: "1,00,000 impressions",
    },
  ];
  marksMonth: any = [
    {
      value: 1,
      label: "1 Month",
    },
    {
      value: 12,
      label: "12 Months",
    },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onLabelNameChange = this.onLabelNameChange.bind(this);
    this.createPost = this.createPost.bind(this);
    this.changeLabel = this.changeLabel.bind(this);
    this.goToCollectionView = this.goToCollectionView.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.onTrendingButtonClick = this.onTrendingButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.setWhichScreen = this.setWhichScreen.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.promoteProfile = this.promoteProfile.bind(this);
    this.pinCollection = this.pinCollection.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      newCollectionName: "",
      privateCollection: false,
      currentLabel: 0,
      arrLabels: [{
        id: 0,
        attributes: {
          name: 'All'
        }
      }],
      arrCollection: {},
      profileData: {},
      popupStatus: false,
      newLabel: "",
      loading: false,
      designerDesigns: [],
      designerScreen: "CollectionView",
      selectedFilter: "All",
      modalOpen: false,
      currentImage: "",
      designerId: this.props.navigation.getParam("id", 0),
      selfProfileData: {},
      flag: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const storageLocalId = localStorage.getItem("changeFilterLabel");
    if (storageLocalId) {
      this.setState({ currentLabel: storageLocalId });
    }
    const DesignPublish = window.sessionStorage.getItem("DesignPublish");
    if (DesignPublish === "true") {
      toast.success("Design Published Successfull");
      window.sessionStorage.removeItem("DesignPublish");
    }
    this.getLabels();
    // this.getCollections(0);
    const screen = this.props.navigation.getParam("screenState", "");
    const location = window.location.href.split("/");
    const currentScreen = location[location.length - 2];
    if (currentScreen === "DesignerProfileByUser") {
      if (screen === 'home' || screen === "CollectionView") {
        this.getCollections(0);
      } else {
        this.setWhichScreen(screen, storageLocalId ? storageLocalId : 0);
      }
    } else {
      this.setWhichScreen(screen, storageLocalId ? storageLocalId : 0);
    }
    this.getSelfProfile();
    this.getProfile();
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // LABEL LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.labelsMessageId != null &&
      this.labelsMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelData = this.state.arrLabels;
        this.setState({ arrLabels: [...arrLabelData, ...responseJson.data] });
        // const storageLocalId = localStorage.getItem("changeFilterLabel");
        // if (storageLocalId) {
        //   this.setState({ currentLabel: storageLocalId });
        // }
        // if (responseJson.data.length > 0) {
        //   this.setState({ currentLabel: responseJson.data[0].id });
        // }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // COLLECTION LISTING API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.collectionMessageId != null &&
      this.collectionMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          arrCollection: responseJson.data,
          loading: false,
          // designerScreen: "CollectionView",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading: false });
    }

    // GET DESIGNS API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designerDesignsId != null &&
      this.designerDesignsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          loading: false,
          designerDesigns: responseJson.data,
          // designerScreen: "designerhomedesign",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    // GET LIKED DESIGNS API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designerLikedDesignsId != null &&
      this.designerLikedDesignsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          loading: false,
          designerDesigns: responseJson.data,
          // designerScreen: "designerhomelikeddesign",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    // GET DESIGNS API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designerPromotedDesignsId != null &&
      this.designerPromotedDesignsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          loading: false,
          designerDesigns: responseJson.data,
          // designerScreen: "designerhomepromoteddesign",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    // GET DESIGNS API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designerInspiredDesignsId != null &&
      this.designerInspiredDesignsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          loading: false,
          designerDesigns: responseJson.data,
          // designerScreen: "designerhomeinspireddesign",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    // GET DESIGNS API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designerRatedDesignsId != null &&
      this.designerRatedDesignsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          loading: false,
          designerDesigns: responseJson.data,
          // designerScreen: "designerhomerateddesign",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    // GET DESIGNS API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designerFreeDesignsId != null &&
      this.designerFreeDesignsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          loading: false,
          designerDesigns: responseJson.data,
          // designerScreen: "designerhomefreedesign",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }
    // GET DESIGNS API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.designerPaidDesignsId != null &&
      this.designerPaidDesignsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          loading: false,
          designerDesigns: responseJson.data,
          // designerScreen: "designerhomepaiddesign",
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // PROFILE DATA API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.profileMessageId != null &&
      this.profileMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          profileData: responseJson.data.attributes
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.selfProfileMessageId != null &&
      this.selfProfileMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.setState({
          selfProfileData: responseJson.data.attributes
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }


    // ADD NEW COLLECTION API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newCollectionMessageId != null &&
      this.newCollectionMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        let arrCollectionData = [];
        arrCollectionData.push(responseJson.data);
        arrCollectionData = arrCollectionData.concat(this.state.arrCollection);
        this.setState({ arrCollection: arrCollectionData });
        toast.success("Collection Created Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    // ADD NEW LABEL API RESPONSE
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.newLabelMessageId != null &&
      this.newLabelMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const arrLabelData = this.state.arrLabels;
        arrLabelData.push(responseJson.data);
        this.setState({ arrLabels: arrLabelData });
        this.setState({ currentLabel: arrLabelData[0].id });
        this.handleClose();
        toast.success("Label Created Successfully");
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.pinMessageId != null &&
      this.pinMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        const msg = this.state.flag ? "Pinned Successfully" : "Unpinned Successfully";
        toast.success(msg);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
      }
      this.setState({ loading: false });
    }

    // Customizable Area End
  }

  // Customizable Area Start
  getLabels(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_labels_by_users`,
    });

    this.labelsMessageId = getLabelsData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;
  }

  getCollections(labelId: number): boolean {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};
    let apiUrl = `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_collections_by_users?label_id=${labelId}`;
    const getCollectionData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.collectionMessageId = getCollectionData.messageId;
    // alert(this.labelsMessageId);
    runEngine.sendMessage(getCollectionData.id, getCollectionData);
    return true;
  }

  createCollection(): boolean {
    if (this.state.newCollectionName === "") {
      alert("Please enter name of collection");
      return false;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          name: this.state.newCollectionName,
          label_id: this.state.currentLabel,
          privacy: this.state.privateCollection ? "Private" : "Public",
        },
      },
    };
    // const httpBody = {};
    const addNewCollection = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.addCollectionApi,
    });

    this.newCollectionMessageId = addNewCollection.messageId;
    runEngine.sendMessage(addNewCollection.id, addNewCollection);
    return true;
  }

  goToCollectionView(collectionID: any) {
    this.props.navigation.navigate("CollectionViewByUser", {
      collectionId: collectionID,
      labelId: this.state.currentLabel,
    });
  }

  getProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: `${configJSON.designerProfileApiUser}/${this.state.designerId}/designer_profile_by_users`,
    });

    this.profileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  handleOpen(): void {
    this.setState({ popupStatus: true });
  }

  handleClose(): void {
    this.setState({ popupStatus: false });
  }

  createLabel(): boolean {
    if (this.state.newLabel === "") {
      alert("Please enter name of Label");
      return false;
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {
      data: {
        attributes: {
          name: this.state.newLabel,
        },
      },
    };
    // const httpBody = {};
    const addNewLabel = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: configJSON.addNewLabel,
    });

    this.newLabelMessageId = addNewLabel.messageId;
    runEngine.sendMessage(addNewLabel.id, addNewLabel);
    return true;
  }

  onLabelNameChange(e: any): void {
    this.setState({ newLabel: e.target.value });
  }

  createPost(collectionId: number): any {
    this.props.navigation.navigate("DesignerDesignUpload", {
      collectionId: collectionId,
      labelId: this.state.currentLabel,
    });
  }

  changeLabel(labelId: number): any {
    this.setState({ currentLabel: labelId });
    this.setWhichScreen(this.state.designerScreen, labelId);
    // this.getCollections(labelId);
  }

  getDesigns(labelId: number): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const apiUrl = `${configJSON.designerProfileApiUser}/${this.state.designerId}/other_user_all_designs?label_id=${labelId}`;
    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.designerDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getLikedDesigns(labelId: number): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const apiUrl = `${configJSON.designerProfileApiUser}/${this.state.designerId}/other_user_liked_designs?filter=Designer&label_id=${labelId}`;
    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.designerLikedDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getPromotedDesigns(labelId: number): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const apiUrl = `${configJSON.designerProfileApiUser}/${this.state.designerId}/other_user_rated_designs?filter=Designer&label_id=${labelId}`;
    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.designerPromotedDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getInspiredDesigns(labelId: number): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const apiUrl = `${configJSON.designerProfileApiUser}/${this.state.designerId}/other_user_inspired_designs?filter=Designer&label_id=${labelId}`;
    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.designerInspiredDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getRatedDesigns(labelId: number): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};
    const apiUrl = `${configJSON.designerProfileApiUser}/${this.state.designerId}/other_user_rated_designs?filter=Designer&label_id=${labelId}`;
    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: apiUrl,
    });

    this.designerRatedDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getFreeDesigns(labelId: number): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.designerFreeDesigns,
    });

    this.designerFreeDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  getPaidDesigns(labelId: number): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };
    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.designerPaidDesigns,
    });

    this.designerPaidDesignsId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  handleFilterInput(event: any): boolean {
    let value = event.target.options;
    this.setState({
      selectedFilter: value,
    });
    return true;
  }

  onTrendingButtonClick(item: any) {
    console.log(item);
  }

  onEditButtonClick(item: any) {
    console.log(item);
  }

  setWhichScreen(screen: string, labelId: any): boolean {
    this.setState({ designerScreen: screen });
    let clabelId = labelId;
    if (!clabelId) {
      clabelId = this.state.currentLabel;
    }
    if (screen === "home" || screen === "CollectionView") {
      this.getCollections(clabelId);
      return true;
    }
    if (screen === "null") {
      this.getDesigns(clabelId);
      return true;
    }
    if (screen === "LIKED") {
      this.getLikedDesigns(clabelId);
      return true;
    }
    if (screen === "PROMOTED") {
      this.getPromotedDesigns(clabelId);
      return true;
    }
    if (screen === "INSPIRED") {
      this.getInspiredDesigns(clabelId);
      return true;
    }
    if (screen === "RATED") {
      this.getRatedDesigns(clabelId);
      return true;
    }
    if (screen === "FREE") {
      this.getFreeDesigns(clabelId);
      return true;
    }
    if (screen === "PAID") {
      this.getPaidDesigns(clabelId);
      return true;
    }
    return false;
  }

  handleModalOpen = () => {
    this.setState({
      modalOpen: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };
  promoteProfile(img: string) {
    this.setState({
      currentImage: img,
      modalOpen: true,
    });
  }

  getSelfProfile(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {};

    const getProfileData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.validationApiMethodType,
      url: configJSON.profileApi,
    });

    this.selfProfileMessageId = getProfileData.messageId;
    runEngine.sendMessage(getProfileData.id, getProfileData);
    return true;
  }

  pinCollection(collectionId: number, flag: boolean): any {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem('token')
    };

    const httpBody = {
      data: {
        attributes: {
          collection_id: collectionId
        }
      }
    };

    this.setState({
      flag: flag
    });
    const getLabelsData = apiCall({
      header: header,
      httpBody: httpBody,
      httpMethod: configJSON.exampleAPiMethod,
      url: flag ? configJSON.pinCollection : configJSON.unPinCollection,
    });

    this.pinMessageId = getLabelsData.messageId;
    runEngine.sendMessage(getLabelsData.id, getLabelsData);
    return true;
  }

  // Customizable Area End
}
