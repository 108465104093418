import React from 'react';
import MaterialTabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import MaterialTab from "@material-ui/core/Tab";
import { withStyles } from '@material-ui/core/styles';

export const DezinerTabs = withStyles({
	root: {
		borderBottom: '0px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: 'transparent',
	},
})(MaterialTabs);

export const DezinerTab = withStyles({
	root: {
		textTransform: 'none',
		fontSize: '1.2rem',
		minWidth: 'min-content',
		'&:hover': {
			color: 'black',
			opacity: 1,
		},
		'&$selected': {
			color: 'black',
			fontWeight: 'bold',
		},
		'&:focus': {
			color: 'black',
		},
	},
	selected: {},
})(MaterialTab);

export function DezinerTabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`club-tabpanel-${index}`}
			aria-labelledby={`club-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Typography>{children}</Typography>
			)}
		</div>
	);
}

export function a11yProps(index: any) {
	return {
		id: `club-tab-${index}`,
		"aria-controls": `club-tabpanel-${index}`
	};
}
