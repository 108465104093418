import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { DezinerMarkDown } from '../../../components/src/DezinerMarkdown.web';
import Grid from '@material-ui/core/Grid';
import { codeOfConductContent } from './CodeOfConductContent.web';

const useStyles = makeStyles({
  content: {
    maxWidth: '1000px', margin: '40px auto', padding: '20px',
    '&>*': {
      margin: '15px 0px',
    }
  },
  title: {
    fontSize: '4rem',
    textAlign: 'center',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.3rem',
    color: '#333333',
    textAlign: 'center',
  },
});



export default function CodeOfConductPage() {

  const classes = useStyles();

  return (
    <div>

     <div className={classes.content}>

        <Grid container justifyContent="center">
          <Typography variant="h1" className={classes.title}>
            {'Community Guidelines'}
          </Typography>
        </Grid>

        <Typography className={classes.subtitle}>
          {'Our Code of Conduct and your pledge to be an upstanding member of the Deziner’s Knot community.'}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Typography>
            {'Updated June 17, 2022'}
          </Typography>
        </Grid>


        <hr />

        <Typography className={classes.subtitle} style={{ fontWeight: 'bold' }}>
          {'Our Code of Conduct and your pledge to be an upstanding member of the Deziner’s Knot community.'}
        </Typography>


        <DezinerMarkDown>
          {codeOfConductContent}
        </DezinerMarkDown>

      </div> 

    </div>
  );
}
