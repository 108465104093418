import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../../components/src/Button.web';
import { HorizontalSpacer, VerticalSpacer } from '../../../components/src/Spacer.web';


const formInitValues = { loginAs: '' }

const loginTypeFormValidationSchema = yup.object().shape({
  loginAs: yup.string().required('Please choose login type'),
});

const useStyles = makeStyles({
  formRoot: {
    padding: '10px', width: '400px', maxWidth: '90%', margin: '0 auto'
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  subTitle: {
    color: '#a9a9a9', textAlign: 'center'
  },
  circularProgress: {
    margin: '0 5px'
  },
});


interface LoginTypeFormProps {
  onSubmit: (values: typeof formInitValues) => void,
  onBackHandler: () => void,
  loading: boolean,
}

export default function LoginTypeForm({
  onSubmit,
  onBackHandler,
  loading,
}: LoginTypeFormProps) {

  const classes = useStyles();

  return (
    <div className={classes.formRoot} >

      <Formik
        initialValues={formInitValues}
        validationSchema={loginTypeFormValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={({ loginAs }) => {
          onSubmit({ loginAs });
        }}
      >

        {formik => <Form translate="yes">

          <div>
            <Grid direction="column" container justifyContent="center" alignItems="center">
              <Typography
                variant="h1"
                className={classes.title}
                gutterBottom
              >
                {'Choose your login Type*'}
              </Typography>
              <Typography variant="caption" className={classes.subTitle}>
                {'Select the option that best describe you'}
              </Typography>
            </Grid>

            <VerticalSpacer />
            <VerticalSpacer />

            <Grid container justifyContent="center">
              <Button
                color="primary"
                variant={formik.values.loginAs == 'designer' ? "contained" : "outlined"}
                onClick={() => {
                  formik.setFieldValue('loginAs', 'designer');
                }}
              >
                {'Designer'}
              </Button>
              <HorizontalSpacer />
              <Button
                color="primary"
                onClick={() => {
                  formik.setFieldValue('loginAs', 'user');
                }}
                variant={formik.values.loginAs == 'user' ? "contained" : "outlined"}>
                {'User'}
              </Button>
            </Grid>

            <VerticalSpacer />

            {
              formik.errors?.loginAs ?
                <Alert severity="error">
                  {formik.errors?.loginAs}
                </Alert>
                :
                null
            }

            <VerticalSpacer />


            <Grid container justifyContent="center">
              <Button
                rounded
                color="default"
                variant="contained"
                onClick={onBackHandler}
              >
                {'Back'}
              </Button>
              <HorizontalSpacer />
              <Button
                rounded
                color="primary"
                type="submit"
                disabled={loading}
                variant="contained">
                {'Finish'}
                {
                  loading ?
                    <CircularProgress className={classes.circularProgress} color="inherit" size={21} />
                    :
                    null
                }
              </Button>
            </Grid>

          </div>
        </Form>
        }
      </Formik>
    </div>
  )
}
