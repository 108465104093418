import { DesignCategory, UserDesignsFilter } from "../api-types";
import { designsMessages } from "../messages-generator";

export const designsKeys = {

  fetchBoughtDesigns:
    () => ['fetch-bought-designs'] as const,

  createPaymentIntent:
    (params: Omit<Parameters<typeof designsMessages.createPaymentIntent>[0], "price">) => ['create-payment-intent', params] as const,

  likeDesign: (params: { designId: number }) => ['like-design', params] as const,
  inspireDesign: (params: { designId: number }) => ['inspire-design', params] as const,

  fetchSourceFiles: (params: { designId: number }) => ['source-files', params] as const,

  updateDesign: (params: { designId: number }) => ['update-design', params] as const,

  uploadDesign: (params: { labelId: number, collectionId: number }) => ['upload-design', params] as const,

  rateDesign: (params: { designId: number }) => ['rate-design', params] as const,
  fetchInspiredUsers: (params: { designId: number }) => ['inspired-users', params] as const,

  fetchShareLinks: (params: { designId: number }) => ['share-links', params] as const,
  fetchMoreDesigns: (params: { designId: number }) => ['more-designs', params] as const,

  fetchSingleDesign:
    (params: { designId: number }) => ['single-design', params] as const,

  fetchDesignerCollectionsByUser:
    (params: { designerId: number, labelId: number }) => ['collections-by-user', params] as const,

  fetchDesignerDesignsByUser:
    (params: { category: DesignCategory, designerId: number, labelId: number }) => ['designs-by-user', params] as const,

  fetchLandingPageDesigns:
    (params: Omit<Parameters<typeof designsMessages.fetchLandingPageDesigns>[0], 'page'>) =>
      ['landing-page-designs', { ...params }] as const,

  fetchUserDesigns:
    (params: { category: DesignCategory, filter: UserDesignsFilter }) =>
      ['user-designs', { ...params }] as const,

  fetchDesigns: (params: {
    category: DesignCategory,
    labelId: number,
    designerId: number,
  }) => ['designs', params] as const,

  fetchCollctions: (params: {
    labelId: number,
    designerId: number,
  }) => ['collections', params] as const,
}
