import { produce } from 'immer';
import { CollectionHome, Design, DesignCategory } from '../../api-types';
import { FetchDesignAction, FETCH_DESIGNS, SetActiveCategoryAction, SetDesignAction, SET_ACTIVE_CATEGORY, SET_DESIGNS } from './designs.action';

export type DesignsDataByCategory = {
  [key in DesignCategory]?: key extends "collections" ? CollectionHome[] : Design[]
}

interface DesignsDataByLabels {
  all: DesignsDataByCategory,
  [labelId: string]: DesignsDataByCategory
}

export interface DesignsState {
  users: { [userId: string]: DesignsDataByLabels },
  designers: { [designerId: string]: DesignsDataByLabels },
  clubs: { [clubId: string]: DesignsDataByLabels },
  groups: { [groupId: string]: DesignsDataByLabels },
  loading: boolean,
  activeCategory: DesignCategory,
}

const initState: DesignsState = {
  loading: false,
  users: {},
  designers: {},
  clubs: {},
  groups: {},
  activeCategory: 'collections',
}

export type DesignsActions = FetchDesignAction | SetDesignAction | SetActiveCategoryAction;

export const designsReducer = produce((state: DesignsState, action: DesignsActions) => {

  switch (action.type) {

    case SET_ACTIVE_CATEGORY: {
      state.activeCategory = action.category;
      break;
    }

    case FETCH_DESIGNS: {
      state.loading = true;
      break;
    }

    case SET_DESIGNS: {

      state.loading = false;

      const labelId = action.labelId;
      const category = action.category;
      const designs = action.designs;
      const designType = action.designsType;

      let id;

      switch (designType) {
        case 'users': { id = action.userId; break; }
        case 'designers': { id = action.designerId; break; }
        case 'groups': { id = action.groupId; break; }
        case 'clubs': { id = action.clubId; break; }
      }

      let _labelId = labelId || 'all';

      if (id) {
        if (!state[designType][id])
          state[designType][id] = { all: {} }

        if (!state[designType][id][_labelId])
          state[designType][id][_labelId] = {}

        state[designType][id][_labelId][category] = designs as any;
      }

    }

  }
}, initState);
