import React from "react";
import DesignerAccountSettingsPageController, {
  Props,
} from "./DesignerAccountSettingsPageController.web";

import AccountSettingForm from '../../../components/src/AccountSettingsForm.web';

class DesignerAccountSettings extends DesignerAccountSettingsPageController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      <>
        <AccountSettingForm />
      </>
    );
  }
}




export default DesignerAccountSettings;
