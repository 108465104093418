import React from "react";
// Customizable Area Start
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import './SubHeader.web.css';
import { Label } from "../../../components/src/api-types";
// Customizable Area End




function SubHeader({
  icon,
  title,
  currentLabel,
  arrLabels,
  navigation,
  aboutPage,
  changeLabel,
}: {
  arrLabels: Label[],
  currentLabel: number,
  icon: string,
  navigation: any,
  aboutPage: boolean,
  title: string,
  changeLabel: any,
}) {

  function onClick(item:any){
    if (aboutPage) {
      localStorage.setItem(
        "changeFilterLabel",
        String(item.id)
      );
      navigation.navigate("DesignerHome");
    } else {
      changeLabel(item.id);
    }
    return;
  }

  return (
    <div className="collection-view-header">
      <div>
        <span>
          {
            icon  ? (
              <img
                className="collection-view-header-icon"
                alt={title}
                src={icon}
              />
            )
              :
              null
          }
        </span>
        <span>
          <b>{title}</b>
        </span>
      </div>
      <FormControl variant="outlined">
        <Select style={{ maxHeight: '40px' }} value={currentLabel}>
          {
            arrLabels?.map((item: any) =>
            (<MenuItem
              key={item.id}
              value={item.id}
              className={
                item.id == currentLabel
                  ? "active-label"
                  : ""
              }
              onClick={() => onClick(item)}
            >
              {item?.attributes?.name}
            </MenuItem>))
          }
        </Select>
      </FormControl>
    </div>
  )
}

export default SubHeader;
